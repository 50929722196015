import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { CustomIonCheckboxEvent } from '../../../ionic/models/icustom-ion-input-event';
import { IPermissionChangeEmission } from '../../models/ipermission-change-emission';
import { Role } from '../../models/role';

@Component({
	selector: 'calao-role',
	templateUrl: './role.component.html',
	styleUrls: ['./role.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleComponent {

	@Output("onPermissionChanged") private readonly moPermissionChangedEmitter = new EventEmitter<IPermissionChangeEmission>();

	/** Rôle à afficher. */
	@Input() public role?: Role;
	@ObserveProperty<RoleComponent>({ sourcePropertyKey: "role" })
	public readonly observableRole = new ObservableProperty<Role>();

	/** `true` pour afficher les libellés au dessus des libellé des rôles et des checkbox de permission, sinon `false` par défaut. */
	@Input() public displayHeaderLabels?: boolean;

	constructor() { }

	public raisePermissionChanged(psRoleLabel: string, psPermissionLabel: string, poEvent: Event): void {
		this.moPermissionChangedEmitter.emit({
			roleLabel: psRoleLabel,
			permissionLabel: psPermissionLabel,
			value: (poEvent as CustomIonCheckboxEvent).detail.checked
		});
	}
}
