import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { EntityLink } from "@calaosoft/osapp-common/entities/models/entity-link";
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { EContextualPermission } from "@calaosoft/osapp-common/permissions/models/econtextual-permission";
import { IPermissionScope } from '@calaosoft/osapp-common/permissions/models/ipermission-scope';
import { IPermissionSet } from '@calaosoft/osapp-common/permissions/models/IPermissionSet';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { IDataSource } from "@calaosoft/osapp-common/store/models/IDataSource";
import { IStoreDocument } from "@calaosoft/osapp-common/store/models/istore-document";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { combineLatest, map, mergeMap, Observable, of, tap } from "rxjs";
import { ActivePageManager } from "../../../model/navigation/ActivePageManager";
import { EntityLinkService } from "../../../services/entityLink.service";
import { PatternResolverService } from "../../../services/pattern-resolver.service";
import { Store } from "../../../services/store.service";
import { DestroyableServiceBase } from "../../services/models/destroyable-service-base";
import { IPermissionContext } from "../models/ipermission-context";
import { IPermissionRule } from "../models/ipermission-rule";
import { EQueryType } from "../models/query/equery-type";
import { ILinksQuery } from "../models/query/ilinks-query";
import { IQuery } from "../models/query/iquery";

/** Permet de stocker la liste des entitiesId autorisé par PermissionRule  */
interface PermissionRulesCache {
	[key: string]: string[]
}

@Injectable({ providedIn: "root" })
export class PermissionRulesService extends DestroyableServiceBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "PRMR.S::";

	private permissionRulesCache: PermissionRulesCache = {};

	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter, () => true);

	//#endregion

	//#region PROPERTIES

	public readonly observableUpdatePermissionRule = new ObservableProperty<number>(0);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly isvcStore: Store,
		private readonly ioRouter: Router
	) {
		super();
	}

	public init(poPermissionsSet: IPermissionSet): void {
		this.permissionRulesCache = {};
		const laPermissionsRules: EContextualPermission[] = this.getAllPermissionsRuleFromPermissionSet(poPermissionsSet);
		laPermissionsRules.map((psPermission: EContextualPermission) =>
			this.getRuleDescriptor(this.extractRuleId(psPermission)).pipe(
				mergeMap((poPermissionRule: IPermissionRule) => this.getAutorizedEntitiesIdList(poPermissionRule)),
				tap((paAutorizedEntitiesId: string[]) => this.permissionRulesCache[psPermission] = paAutorizedEntitiesId),
				tap(() => this.observableUpdatePermissionRule.value = Date.now()),
				secure(this)
			).subscribe()
		);
	}

	public getAllPermissionsRuleFromPermissionSet(poPermissionsSet: IPermissionSet): EContextualPermission[] {
		const laPermissionsRulesNames: EContextualPermission[] = [];

		Object.values(poPermissionsSet).forEach((poPermissionScope: IPermissionScope) =>
			laPermissionsRulesNames.push(... this.innerGetAllPermissionsRuleFromPermission(poPermissionScope))
		);

		return ArrayHelper.unique(laPermissionsRulesNames);
	}

	private innerGetAllPermissionsRuleFromPermission(poPermissionScope: IPermissionScope): EContextualPermission[] {
		const laPermissionsRulesNames: EContextualPermission[] = [];
		Object.values(poPermissionScope).forEach((poPermission: IPermissionScope) => {
			if (typeof poPermission === "string")
				if ((poPermission as EContextualPermission).startsWith(EContextualPermission.rule))
					laPermissionsRulesNames.push(poPermission);

			if (typeof poPermission === "object")
				laPermissionsRulesNames.push(... this.innerGetAllPermissionsRuleFromPermission(poPermission));
		});

		return laPermissionsRulesNames;
	}

	public evaluateRule(pePermission: EContextualPermission, poContext?: IPermissionContext): boolean {
		const lsRuleId: string = this.extractRuleId(pePermission);
		if (StringHelper.isBlank(lsRuleId)) {
			console.error(`${PermissionRulesService.C_LOG_ID} Impossible de recuperer l'ID de la règle ${pePermission}`);
			return false;
		}
		const laAutorizedEntitiesId: string[] = this.permissionRulesCache[pePermission];
		if (!poContext) // Si il n'y a pas de contexte, on est dans un cas "global", comme accéder à l'onglet business.
			return laAutorizedEntitiesId.length !== 0;
		return laAutorizedEntitiesId.includes(poContext._id ?? "");
	}

	public getAutorizedEntitiesIdList(poPermissionRule: IPermissionRule): Observable<string[]> {
		return combineLatest(poPermissionRule.entityIds.map((poQuery: IQuery) => {
			switch (poQuery.type) {
				case EQueryType.links:
					return this.executeLinksQuery(poQuery as ILinksQuery);

				default: {
					console.error(`${PermissionRulesService.C_LOG_ID} Impossible de définir le type de la Query ${poQuery.name} de la règle ${poPermissionRule._id}`, poQuery);
					return of([]);
				}
			}
		})).pipe(
			map((paEntitiesId: string[][]) => paEntitiesId.flat()),
			secure(this)
		);
	}

	public extractRuleId(psRule: string): string {
		return StringHelper.isBlank(psRule) ? "" : psRule.substring(psRule.indexOf(':') + 1);
	}

	public getRuleDescriptor(psRuleId: string, poContext?: IPermissionContext): Observable<IPermissionRule> {
		const loDataSource: IDataSource<IStoreDocument> = {
			role: EDatabaseRole.workspace,
			viewParams: {
				key: `${EPrefix.permissionRule}${psRuleId}`,
				include_docs: true,
			}
		};

		return this.isvcStore.getOne<IPermissionRule>(loDataSource).pipe(
			tap((poPermissionRule: IPermissionRule) => this.isvcPatternResolver.resolveContextualPatterns<IPermissionRule>(poPermissionRule, poContext))
		);
	}

	//#region QUERY

	private executeLinksQuery(poLinksQuery: ILinksQuery): Observable<string[]> {
		return this.isvcEntityLink.getEntityLinks(poLinksQuery.params.source, poLinksQuery.params.targetsPrefix, poLinksQuery.params.type, true, this.moActivePageManager).pipe(
			map((paEntityLinks: EntityLink[]) => paEntityLinks.map((poEntityLink: EntityLink) =>
				this.isvcEntityLink.getLinkTargetIds([poLinksQuery.params.source], poEntityLink)
			).flat())
		);
	}

	//#endregion

}