import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';

export abstract class RoutingHelper {

	//#region METHODS

	/** Indique si les 2 routes sont égales en faisant abstraction du `/` de début et des `queryParams`.
	 * @param psRouteA
	 * @param psRouteB
	 * @returns
	 */
	public static routeEqual(psRouteA: string, psRouteB: string): boolean {
		return this.getRouteWithoutQueryParams(psRouteA) === this.getRouteWithoutQueryParams(psRouteB);
	}

	private static getRouteWithoutQueryParams(psRoute: string): string | undefined {
		return ArrayHelper.getLastElement(psRoute.match(/^\/?([\w*\/*]*)\??.*/));
	}

	//#endregion

}