import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Directory, Encoding, FileInfo, Filesystem, GetUriResult, ReaddirResult, RenameOptions, StatResult, WriteFileResult } from '@capacitor/filesystem';
import write_blob from "capacitor-blob-writer";
import { lastValueFrom } from 'rxjs';
import { EFileError } from '../models/efile-error';
import { FilesystemCopyError } from '../models/errors/filesystem-copy-error';
import { FilesystemCreateError } from '../models/errors/filesystem-create-error';
import { FilesystemErrorBase } from '../models/errors/filesystem-error-base';
import { FilesystemGetFileFromUriError } from '../models/errors/filesystem-get-file-from-uri-error';
import { FilesystemGetFileUriError } from '../models/errors/filesystem-get-file-uri-error';
import { FilesystemListDirectoryEntriesError } from '../models/errors/filesystem-list-directory-entries-error';
import { FilesystemReadFileAsTextError } from '../models/errors/filesystem-read-file-as-text-error';
import { FilesystemRemoveError } from '../models/errors/filesystem-remove-error';
import { FilesystemRenameError } from '../models/errors/filesystem-rename-error';
import { IReadFileResult } from '../models/iread-file-result';
import { IUri } from '../models/iuri';

@Injectable()
export class FilesystemService {

	//#region FIELDS

	private static readonly C_LOG_ID = "FS.S::";
	private static readonly C_URI_START = "file://";

	//#endregion

	//#region METHODS

	constructor(private readonly ioHttpClient: HttpClient) { }

	/** Récupère un fichier enregistré.
	 * @param psPath Chemin d'accès jusqu'au fichier qu'on veut récupérer : 'cheminVersFichier/nomFichier'
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 * @throws `FilesystemGetFileUriError` ou `FilesystemGetFileFromUriError` en cas d'erreur.
	 */
	public getFileAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<Blob> {
		return this.getFileUriAsync(psPath, peDirectory)
			.then((psUri: string) => this.getFileFromUriAsync(psUri));
	}

	/** Récupère un fichier depuis son identifiant de ressource unifrome (URI).
	 * @param psUri Identifiant de ressource uniforme du fichier à récupérer.
	 * @throws `FilesystemGetFileFromUriError` en cas d'erreur.
	 */
	public getFileFromUriAsync(psUri: string): Promise<Blob> {
		return lastValueFrom(
			this.ioHttpClient.get(
				psUri.startsWith("blob") ? psUri : Capacitor.convertFileSrc(psUri),
				{ responseType: "blob" }
			)
		)
			.catch((poError: any) => {
				throw new FilesystemGetFileFromUriError(poError, psUri);
			});
	}

	/** `true` si le fichier/répertoire pointé existe, `false` sinon.
	 * @param psPathOrUri Chemin vers le fichier/répertoire à vérifier ou son identifiant de ressource uniforme (URI).
	 * @param peDirectory Répertoire où se trouve le fichier/répertoire à vérifier, `External` par défaut, inutile si `psPathOrUri` est un URI.
	 */
	public async existsAsync(psPathOrUri: string, peDirectory: Directory = Directory.External): Promise<boolean> {
		try {
			console.debug(`${FilesystemService.C_LOG_ID}Checking if '${psPathOrUri}' exists...`);

			const lsUri: string = await this.getFileUriAsync(psPathOrUri, peDirectory);

			await Filesystem.stat({ path: lsUri });

			console.debug(`${FilesystemService.C_LOG_ID}'${psPathOrUri}' exists!`);

			return true;
		}
		catch (poError) {
			console.debug(`${FilesystemService.C_LOG_ID}File '${psPathOrUri}' does not exist`);
			return false;
		}
	}

	/** Lecture du contenu textuel d'un fichier.
	 * @param psPath Chemin d'accès au dossier qu'on veut lire, peut contenir le nom du fichier : 'cheminVersFichier/nomFichier'.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 * @throws `FilesystemReadFileAsTextError` en cas d'erreur.
	 */
	public async readFileAsTextAsync(
		psPath: string, peDirectory: Directory = Directory.External, peEncoding: Encoding = Encoding.UTF8
	): Promise<string> {

		try {
			const loResult: IReadFileResult = await Filesystem.readFile({
				path: psPath,
				directory: peDirectory,
				encoding: peEncoding
			});

			return loResult.data instanceof Blob ? loResult.data.text() : loResult.data;
		}
		catch (poError) {
			throw new FilesystemReadFileAsTextError(poError, psPath, peDirectory, peEncoding);
		}
	}

	/** Crée un dossier à un chemin spécifique.
	 * @param psPath Chemin d'accès au dossier qu'on veut créer : 'cheminVersFichier/nomFichier'.
	 * @param peRootDirectory Dossier racine dans lequel est stocké le fichier. `Directory.External` par défaut.
	 * @throws `FilesystemCreateError` en cas d'erreur.
	 */
	public async createDirectoryAsync(psPath: string, peRootDirectory: Directory = Directory.External): Promise<void> {

		if (await this.existsAsync(psPath, peRootDirectory))
			return; // Si le dossier existe déjà, rien à faire.
		else { // Sinon il faut le créer.
			try {
				await Filesystem.mkdir({ path: psPath, directory: peRootDirectory, recursive: true });
				console.debug(`${FilesystemService.C_LOG_ID}Directory '${psPath}' created !`);
			}
			catch (poError) {
				throw new FilesystemCreateError(poError, EFileError.other, psPath, peRootDirectory);
			}
		}
	}

	/** Crée un fichier à un chemin spécifique et retourne son identifiant de ressource unifrome (URI).
	 * @param psPath Chemin d'accès au dossier qu'on veut créer, peut contenir le nom du fichier : 'cheminVersFichier/nomFichier'.
	 * @param poData Données du fichier à créer, crée un fichier vide si non renseigné.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 * @param pbOverwriteIfExists Indique si on doit écraser le fichier s'il existe déjà, `false` par défaut.
	 * @throws `FilesystemCreateError` en cas d'erreur.
	 */
	public async createFileAsync(
		psPath: string,
		poData?: string | Blob | ArrayBuffer,
		peDirectory: Directory = Directory.External,
		pbOverwriteIfExists?: boolean
	): Promise<string> {

		if (await this.existsAsync(psPath, peDirectory) && !pbOverwriteIfExists) {
			throw new FilesystemCreateError(
				EFileError.fileAlreadyExist,
				EFileError.fileAlreadyExist,
				psPath,
				peDirectory
			);
		}

		try {
			const lsUri: string = await this.execCreateFileAsync(psPath, peDirectory, poData);
			console.debug(`${FilesystemService.C_LOG_ID}File '${psPath}' created !`);
			return lsUri;
		}
		catch (poError) {
			throw new FilesystemCreateError(poError, EFileError.other, psPath, peDirectory);
		}
	}

	/** Crée un fichier et retourne son identifiant de ressource unifrome (URI).
	 * @param psPath
	 * @param peDirectory
	 * @param poData
	 */
	private execCreateFileAsync(psPath: string, peDirectory: Directory, poData?: string | Blob | ArrayBuffer)
		: Promise<string> {

		if (!poData || typeof poData === "string") {
			return Filesystem.writeFile({
				data: poData as string ?? "",
				path: psPath,
				directory: peDirectory,
				recursive: true,
				encoding: Encoding.UTF8
			})
				.then((poResult: WriteFileResult) => poResult.uri);
		}
		else {
			return write_blob({
				blob: poData instanceof Blob ? poData : new Blob([poData]),
				path: psPath,
				directory: peDirectory,
				recursive: true
			});
		}
	}

	/** Liste le contenu d'un répertoire et renvoie cette liste.
	 * @param psPath Chemin d'accès jusqu'au répertoire dont il faut lister le contenu.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 * @throws `FilesystemListDirectoryEntriesError` en cas d'erreur.
	 */
	public async listDirectoryEntriesAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<FileInfo[]> {
		try {
			const loResult: ReaddirResult = await Filesystem.readdir({
				path: psPath.endsWith("/") ? psPath.slice(0, -1) : psPath,
				directory: peDirectory
			});
			console.debug(`${FilesystemService.C_LOG_ID}Content of directory '${psPath}' : `, loResult.files);
			return loResult.files;
		}
		catch (poError) {
			throw new FilesystemListDirectoryEntriesError(poError, psPath, peDirectory);
		}
	}

	/** Récupère le tableau des dossiers présents dans un répertoire.
	 * @param psPath Chemin d'accès jusqu'au répertoire dont il faut récupérer les dossiers.
	 * @param peDirectory Dossier dans lequel sont stockés les dossiers, `Directory.External` par défaut.
	 * @throws `FilesystemListDirectoryEntriesError` en cas d'erreur.
	 */
	public getDirectoriesInfoAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<FileInfo[]> {
		return this.listDirectoryEntriesAsync(psPath, peDirectory)
			.then((paResults: FileInfo[]) => paResults.filter((poResult: FileInfo) => poResult.type === "directory"));
	}

	/** Récupère le tableau des informations des fichiers présents dans un répertoire.
	 * @param psPath Chemin d'accès jusqu'au répertoire dont il faut récupérer les fichiers.
	 * @param peDirectory Dossier dans lequel sont stockés les fichiers, `Directory.External` par défaut.
	 * @throws `FilesystemListDirectoryEntriesError` en cas d'erreur.
	 */
	public getFilesInfoAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<FileInfo[]> {
		return this.listDirectoryEntriesAsync(psPath, peDirectory)
			.then((paResults: FileInfo[]) => paResults.filter((poResult: FileInfo) => poResult.type === "file"));
	}

	/** Supprime un répertoire et son contenu depuis son chemin ou son identifiant de ressource uniforme (URI).
	 * @param psPathOrUri Chemin d'accès jusqu'au répertoire qu'il faut supprimer ou son identifiant de ressource uniforme (URI).
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut, inutile si `psPathOrUri` est un URI.
	 * @throws `FilesystemGetFileUriError` ou `FilesystemRemoveError` en cas d'erreur.
	 */
	private async removeDirectoryAsync(psPathOrUri: string, peDirectory: Directory = Directory.External): Promise<void> {
		try {
			const lsUri: string = await this.getFileUriAsync(psPathOrUri, peDirectory);
			await Filesystem.rmdir({ path: lsUri, recursive: true });
			console.debug(`${FilesystemService.C_LOG_ID}Directory with uri '${lsUri}' removed.`);
		}
		catch (poError) {
			throw new FilesystemRemoveError(poError, psPathOrUri);
		}
	}

	/** Supprime un fichier depuis un chemin ou un identifiant de ressource uniforme (URI).
	 * @param psPathOrUri Chemin vers le fichier à supprimer ou son identifiant de ressource uniforme (URI).
	 * @param peDirectory Répertoire où se trouve le fichier à supprimer, `External` par défaut, inutile si `psPathOrUri` est un URI.
	 * @throws `FilesystemGetFileUriError` ou `FilesystemRemoveError` en cas d'erreur.
	 */
	public async removeFileAsync(psPathOrUri: string, peDirectory: Directory = Directory.External): Promise<void> {
		try {
			const lsUri: string = await this.getFileUriAsync(psPathOrUri, peDirectory);
			await Filesystem.deleteFile({ path: lsUri });
			console.debug(`${FilesystemService.C_LOG_ID}File with uri '${lsUri}' removed.`);
		}
		catch (poError) {
			if (poError.error?.message === "File does not exist") // Pas le choix, l'erreur n'a pas plus d'information.
				return undefined;

			throw new FilesystemRemoveError(poError, psPathOrUri);
		}
	}

	/** Supprime un dossier (et son contenu) ou un fichier.
	 * @param psPathOrUri Chemin d'accès jusqu'à la cible qu'on veut supprimer, peut être le chemin ou l'identifiant de ressource uniforme (URI).
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut. Non nécessaire si `psPathOrUri` est un URI.
	 * @throws `FilesystemGetFileUriError` ou `FilesystemRemoveError` en cas d'erreur.
	 */
	public removeAsync(psPathOrUri: string, peDirectory: Directory = Directory.External): Promise<void> {
		return this.isDirectoryAsync(psPathOrUri, peDirectory)
			.then((pbIsDirectory: boolean) => pbIsDirectory ?
				this.removeDirectoryAsync(psPathOrUri, peDirectory) : this.removeFileAsync(psPathOrUri, peDirectory)
			);
	}

	/** Retourne `true` si c'est un répertoire, `false` si c'est un fichier, ou une erreur.
	* @param psPath Chemin d'accès jusqu'au répertoire/fichier qu'on veut identifier, peut contenir le nom du répertoire/fichier : 'cheminVersFichier/nomFichier'.
	* @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	*/
	public isDirectoryAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<boolean> {
		return Filesystem.stat({ path: psPath, directory: peDirectory })
			.then((poResult: StatResult) => poResult.type === "directory")
			.catch(_ => false);
	}

	/** Copie un fichier dans un répertoire.
	 * @param poSourceUri Identifiant de ressource uniforme (URI) du fichier/dossier source qu'il faut copier.
	 * @param poDestinationUri Identifiant de ressource uniforme (URI) du fichier/dossier de destination où réaliser la copie.
	 * @throws `FilesystemGetFileUriError` ou `FilesystemRemoveError` ou `FilesystemCopyError` en cas d'erreur.
	 */
	public async copyAsync(poSourceUri: IUri, poDestinationUri: IUri): Promise<void> {
		try {
			if (await this.existsAsync(poDestinationUri.value))
				await this.removeAsync(poDestinationUri.value);

			await Filesystem.copy({ from: poSourceUri.value, to: poDestinationUri.value });
		}
		catch (poError) {
			if (poError instanceof FilesystemErrorBase)
				throw poError;
			else
				throw new FilesystemCopyError(poError, poSourceUri.value, poDestinationUri.value);
		}
	}

	/** Récupère l'identifiant de ressource uniforme (URI) vers un fichier (== chemin d'accès précis).
	 * @param psPath
	 * @param peDirectory
	 * @throws `FilesystemGetFileUriError` en cas d'erreur.
	 */
	public getFileUriAsync(psPath: string, peDirectory: Directory): Promise<string> {
		if (this.isUri(psPath))
			return Promise.resolve(psPath);
		else {
			return Filesystem.getUri({ path: "", directory: peDirectory })
				.then((poResult: GetUriResult) => `${poResult.uri}/${psPath}`)
				.catch((poError: any) => { throw new FilesystemGetFileUriError(poError, psPath, peDirectory); });
		}
	}

	private isUri(psPath: string): boolean {
		return psPath.startsWith(FilesystemService.C_URI_START);
	}

	/** Récupère la signature (nombre correspondant au type de fichier) d'une donnée.
	 * @param poBlob Donnée dont il faut récupérer la signature (nombre correspondant au type du fichier).
	 */
	public getMagicNumberAsync(poBlob: Blob): Promise<string> {
		return poBlob.slice(0, 4) // On a besoin que des 4 premiers morceaux pour déterminer le magicNumber.
			.arrayBuffer()
			.then((poResult: ArrayBuffer) => {
				const laHexParts: string[] = [];

				// Les nombre en hexadécimal doivent être sur 2 caractères, il faut donc ajouter un 0 au début si le nombre est inférieur à 10.
				// ex: 3 -> "3" donc il faut ajouter un 0 devant -> "03" ; 75 -> "4b" donc pas besoin de 0 devant.
				new Uint8Array(poResult)
					.forEach((pnByte: number) => laHexParts.push(pnByte.toString(16).padStart(2, "0")));

				return laHexParts.join("").toUpperCase();
			});
	}

	/** Renomme un fichier dans un répertoire, bien indiquer l'extension du fichier.
	 * @param psOldNameOrUri Nom du fichier ou URI à renommer.
	 * @param psNewNameOrUri Nouveau nom du fichier ou URI après renommage.
	 * @param peDirectory Répertoire où se trouve le fichier à renommer, `External` par défaut, inutile si `psOldNameOrUri` et `psNewNameOrUri` sont des URIs.
	 * @throws `FilesystemGetFileUriError` ou `FilesystemRenameError` en cas d'erreur.
	 */
	public async renameAsync(psOldNameOrUri: string, psNewNameOrUri: string, peDirectory: Directory = Directory.External): Promise<void> {
		const loRenameOptions: RenameOptions = {
			from: await this.getFileUriAsync(psOldNameOrUri, peDirectory),
			to: await this.getFileUriAsync(psNewNameOrUri, peDirectory)
		};

		try {
			await Filesystem.rename(loRenameOptions);
			console.debug(`${FilesystemService.C_LOG_ID}File renamed from '${loRenameOptions.from}' to '${loRenameOptions.to}'.`);
		}
		catch (poError) {
			throw new FilesystemRenameError(poError, loRenameOptions.from, loRenameOptions.to);
		}
	}

	//#endregion

}