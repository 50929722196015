import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { ComponentBase } from '../../helpers/ComponentBase';
import { LifeCycleObserverComponentBase } from '../../helpers/LifeCycleObserverComponentBase';
import { PageInfo } from '../../model/PageInfo';
import { EApplicationEventType } from '../../model/application/EApplicationEventType';
import { IApplicationEvent } from '../../model/application/IApplicationEvent';
import { ELifeCycleEvent } from '../../model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '../../model/lifeCycle/ILifeCycleEvent';
import { IWebviewParams } from '../../model/webview/IWebviewParams';
import { PatternResolverService } from '../../services/pattern-resolver.service';
import { DynamicPageComponent } from '../dynamicPage/dynamicPage.component';

@Component({
	selector: 'webView',
	templateUrl: './webview.component.html',
	styleUrls: ['./webview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebViewComponent extends LifeCycleObserverComponentBase implements AfterViewInit {

	//#region FIELDS

	@ViewChild("iframe", { read: ElementRef }) private readonly moIframe: ElementRef<HTMLIFrameElement> | undefined;

	//#endregion

	//#region PROPERTIES

	public readonly params: IWebviewParams;

	private mnName: number | undefined;
	public get name(): number | undefined { return this.mnName; }

	//#endregion

	//#region METHODS

	constructor(
		poParentPage: DynamicPageComponent<ComponentBase>,
		poActivatedRoute: ActivatedRoute,
		psvcPatternResolver: PatternResolverService
	) {
		super(poParentPage);

		const loPageInfo: PageInfo | undefined = poActivatedRoute.snapshot.data.pageInfo;
		if (loPageInfo?.params) {
			this.params = {
				...loPageInfo.params,
				source: this.cleanUrlParameters(psvcPatternResolver.replaceDynParams(loPageInfo.params.source))
			};
		}

		if (this.params.hasCache !== false)
			this.params.hasCache === true;

		this.mnName = this.params.hasCache ? Date.now() : undefined;
	}

	public override ngAfterViewInit(): void {
		if (this.moIframe) {
			this.moIframe.nativeElement.allowFullscreen = !!this.params.allowFullScreen;
			if (this.params?.sandboxOptions) {
				let lsSanboxOptions = "";
				this.params.sandboxOptions.forEach(psOption => lsSanboxOptions = lsSanboxOptions + " " + psOption);
				this.moIframe.nativeElement.sandbox.value = lsSanboxOptions;
			}
		}
		else
			console.error("WBVW.C::iframe not defined !");

		super.ngAfterViewInit();
	}

	protected override onLifeCycleEvent(poEvent: IApplicationEvent): void {
		if (poEvent.type === EApplicationEventType.LifeCycleEvent && (poEvent as ILifeCycleEvent).data.value === ELifeCycleEvent.viewWillLeave) {
			if (this.moIframe) { // Si on modifie la source, la vidéo se coupe (même si la nouvelle source est identique à l'ancienne).
				const lsIframeSrc: string = this.moIframe.nativeElement.src;
				this.moIframe.nativeElement.src = lsIframeSrc;
			}
		}
	}

	private cleanUrlParameters(psUrl: string): string {
		const loUrl = new URL(psUrl);
		const loParameters: URLSearchParams = loUrl.searchParams;

		loParameters.forEach((psValue: string, psKey: string) => {
			if (StringHelper.isBlank(psValue))
				loParameters.delete(psKey);
		});

		return loUrl.toString();
	}

	//#endregion

}