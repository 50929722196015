import { Inject, Injectable, Optional } from "@angular/core";
import { ConfigData } from "@calaosoft/osapp-common/config/models/ConfigData";
import { ELogActionId } from "@calaosoft/osapp-common/logging/models/ELogActionId";
import { PerformanceManager } from "@calaosoft/osapp-common/performance/PerformanceManager";
import { afterSubscribe } from "@calaosoft/osapp-common/rxjs/operators/after-subscribe";
import { tapError } from "@calaosoft/osapp-common/rxjs/operators/tap-error";
import { ProvideDatabaseError } from "@calaosoft/osapp-common/sqlite/errors/provide-database-error";
import { VersionNotFoundError } from "@calaosoft/osapp-common/sqlite/errors/version-not-found-error";
import { SqlFilesHelper } from "@calaosoft/osapp-common/sqlite/helpers/sql-files.helper";
import { EUpdateStatus } from "@calaosoft/osapp-common/sqlite/models/eupdate-status";
import { IProviderFilesOptions } from "@calaosoft/osapp-common/sqlite/models/iprovider-files-options";
import { SqlDataSource } from "@calaosoft/osapp-common/sqlite/models/sql-data-source";
import { SqlRequestResult } from "@calaosoft/osapp-common/sqlite/models/sql-request-result";
import { TSqliteExtension } from "@calaosoft/osapp-common/sqlite/models/tsqlite-extension";
import { UpdateEvent } from "@calaosoft/osapp-common/sqlite/models/update-event";
import { TTransferHeaders } from "@calaosoft/osapp-common/transfert/models/ttransfer-headers";
import { ArrayHelper } from "@calaosoft/osapp-common/utils/helpers/arrayHelper";
import { NumberHelper } from "@calaosoft/osapp-common/utils/helpers/numberHelper";
import { StringHelper } from "@calaosoft/osapp-common/utils/helpers/stringHelper";
import { Directory, FileInfo } from "@capacitor/filesystem";
import { Observable, Subject, defer, of } from "rxjs";
import { catchError, mergeMap, startWith, tap } from "rxjs/operators";
import { EXTENSIONS_AND_MIME_TYPES } from "../../../../helpers/fileHelper";
import { PlatformService } from "../../../../services/platform.service";
import { FilesystemService } from "../../../filesystem/services/filesystem.service";
import { LoggerService } from "../../../logger/services/logger.service";
import { OsappApiHelper } from "../../../osapp-api/helpers/osapp-api.helper";
import { TransfertService } from "../../../transfert/services/transfert.service";
import { ZipService } from "../../../zip/services/zip.service";
import { SqlAdapter } from "../../models/SqlAdapter";
import { SqlDatabaseProvider } from "../../models/SqlDatabaseProvider";
import { SqlRemoteProvider } from "./sql-remote-provider.service";

interface IPragmaCheck {
	readonly integrity_check: any;
}

@Injectable()
export class LocalDatabaseProviderService extends SqlDatabaseProvider {
	//#region FIELDS

	/** Caractère de séparation des noms de fichiers entre l'identifiant de BDD et la version. */
	private static readonly C_INTEGRITY_CHECK_REQUEST = "PRAGMA integrity_check;";
	private static readonly C_INTEGRITY_CHECK_OK_RESULT = "ok";

	//#endregion FIELDS

	//#region PROPERTIES

	private msSqliteDatabasesPath?: string;
	/** Récupère le chemin vers les bases de données sqlite sur l'app mobile, chaîne vide si on n'est pas sur l'app mobile. */
	public get mobileAppDatabasesPath(): string {
		if (this.msSqliteDatabasesPath === undefined) this.msSqliteDatabasesPath = this.getDatabaseLocation();

		return this.msSqliteDatabasesPath;
	}

	public static readonly mobileAppDatabasesDirectory = Directory.Library;

	//#endregion PROPERTIES

	//#region METHODS

	public constructor(
		private readonly isvcFileSystem: FilesystemService,
		private readonly isvcTransfer: TransfertService,
		private readonly isvcZip: ZipService,
		private readonly isvcPlatform: PlatformService,
		psvcAdapter: SqlAdapter<any>,
		psvcLogger: LoggerService,
		@Optional() @Inject(SqlRemoteProvider) paRemoteProviders?: SqlRemoteProvider[]
	) {
		super(psvcAdapter, psvcLogger, paRemoteProviders);
	}

	private getDatabaseLocation(): string {
		if (this.isvcPlatform.isMobileApp) {
			if (this.isvcPlatform.isAndroid) return "../databases/";
			else if (this.isvcPlatform.isIOS) return "LocalDatabase/";
		}

		return "";
	}

	public override async isDataSourceReadyAsync(poDataSource: SqlDataSource): Promise<boolean> {
		return (await this.getDatabaseFilesInfoAsync(poDataSource.databaseId, poDataSource.version)).length > 0;
	}

	protected override provideDatabase$(
		poDataSource: SqlDataSource,
		poHeaders?: TTransferHeaders
	): Observable<UpdateEvent> {
		return this.downloadZippedDatabase$(poDataSource, poHeaders).pipe(
			mergeMap((poEvent: UpdateEvent) => this.installDatabaseAsync(poDataSource, poEvent)),
			catchError((_) => {
				// Si le téléchargement ou l'installation par ".zip" a échoué, on retente avec le ".db".
				return this.downloadDatabase$(poDataSource, poHeaders).pipe(
					mergeMap((poEvent: UpdateEvent) => this.installDatabaseAsync(poDataSource, poEvent))
				);
			}),
			tapError((poError) =>
				this.logActionId(
					`Providing database '${poDataSource.databaseId}' in version '${poDataSource.version}' failed.`,
					ELogActionId.sqliteProvidingFailed,
					poError
				)
			)
		);
	}

	/** @override */
	public override getNumberOfDatabasesAsync(
		psDatabasePrefix: string,
		poOptions?: IProviderFilesOptions
	): Promise<number> {
		return this.getDatabaseFilesInfoAsync(psDatabasePrefix, undefined, poOptions).then(
			(paDatabaseFiles: FileInfo[]) => paDatabaseFiles.length
		);
	}

	/** @override */
	public override async getLastReadyAsync(psDatabaseId: string): Promise<SqlDataSource | undefined> {
		return this.getSortedDatabaseFilesInfoAsync(psDatabaseId).then((paDatabaseFiles: FileInfo[]) => {
			const loLastDatabase: FileInfo | undefined = ArrayHelper.getLastElement(paDatabaseFiles);

			return loLastDatabase
				? new SqlDataSource(
						psDatabaseId,
						this.getVersionFromFileName(loLastDatabase.name, psDatabaseId),
						loLastDatabase.name
				  )
				: undefined;
		});
	}

	/** @override */
	public override getDatabasesAsync(
		psDatabasePrefix: string,
		poOptions?: IProviderFilesOptions
	): Promise<SqlDataSource[]> {
		return this.getDatabaseFilesInfoAsync(psDatabasePrefix, undefined, poOptions).then((paDatabaseFiles: FileInfo[]) =>
			this.transformDatabaseFilesToSqlDataSources(paDatabaseFiles, psDatabasePrefix)
		);
	}

	/** @override */
	public override async getLastDownloadedDatabaseNameAsync(psDatabaseId: string): Promise<string> {
		const lnDatabaseVersion: number = await this.getLastVersionDownloadedAsync(psDatabaseId);

		return NumberHelper.isValidPositive(lnDatabaseVersion)
			? SqlFilesHelper.getFileName(psDatabaseId, lnDatabaseVersion)
			: "";
	}

	/** @override */
	public override async getLastVersionDownloadedAsync(psDatabaseId: string): Promise<number> {
		return this.getSortedDatabaseFilesInfoAsync(psDatabaseId).then((paDatabaseFiles: FileInfo[]) => {
			const loLastDatabase: FileInfo | undefined = ArrayHelper.getLastElement(paDatabaseFiles);

			return loLastDatabase ? this.getVersionFromFileName(loLastDatabase.name, psDatabaseId) : NaN;
		});
	}

	/** @override */
	public override databaseExistsAsync(
		psDatabaseVersion: string,
		psDatabaseId: string,
		poOptions?: IProviderFilesOptions
	): Promise<boolean> {
		if (StringHelper.isBlank(psDatabaseId)) {
			console.error(`${LocalDatabaseProviderService.C_LOG_ID}Database id '${psDatabaseId}' is not valid.`);
			return Promise.reject(new Error(`Checking whether the database exists failed because database id is not valid.`));
		}

		return this.isvcFileSystem.existsAsync(
			`${this.mobileAppDatabasesPath}${SqlFilesHelper.getFileName(psDatabaseId, +psDatabaseVersion, poOptions)}`,
			LocalDatabaseProviderService.mobileAppDatabasesDirectory
		);
	}

	/** @override */
	public override getLastUrl(psDatabasePrefix: string, psDatabaseId: string, pnVersion: number): string {
		if (StringHelper.isBlank(psDatabasePrefix)) {
			console.error(
				`${LocalDatabaseProviderService.C_LOG_ID}Database prefix '${psDatabasePrefix}' for database id '${psDatabaseId}' with version '${pnVersion}' is not valid.`
			);
			throw new Error(`Recovery of last database URL failed because database prefix is not valid.`);
		} else return this.getRemoteProvider(psDatabasePrefix).getUrl(psDatabaseId, 0); // Version 0 par défaut.
	}

	/** @override */
	protected override async execRemoveDatabaseAsync(psDatabaseId: string, pnVersion?: number): Promise<void> {
		// Si on a la version de renseignée, on supprime toutes les bases de données ayant le même id ET la même version (".zip" et ".db").
		if (pnVersion) {
			const laDatabaseFiles: FileInfo[] = await this.getDatabaseFilesInfoAsync(psDatabaseId, pnVersion, {
				includesNotUsableDatabases: true
			});

			for (let lnIndex = 0; lnIndex < laDatabaseFiles.length; ++lnIndex) {
				await this.isvcFileSystem.removeFileAsync(laDatabaseFiles[lnIndex].uri);
			}
		} else {
			// Si pas de version disponible, on supprime la bdd la plus récente (version la plus élevée, indépendamment de son extension).
			const laDatabaseFiles: FileInfo[] = await this.getSortedDatabaseFilesInfoAsync(psDatabaseId, undefined, {
				includesNotUsableDatabases: true
			});
			const loLastDatabaseFileInfo: FileInfo | undefined = ArrayHelper.getLastElement(laDatabaseFiles);

			if (loLastDatabaseFileInfo) await this.isvcFileSystem.removeFileAsync(loLastDatabaseFileInfo.uri);
		}
	}

	/** Contruit et retourne le chemin sur disque d'une base de données.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @param pnVersion Version de la base de données.
	 * @param poOptions Options pour la récupération.
	 */
	public async getDatabasePathAsync(
		psDatabaseId: string,
		pnVersion: number,
		poOptions?: IProviderFilesOptions
	): Promise<string> {
		let lsFolderPath: string;

		try {
			lsFolderPath = await this.isvcFileSystem.getFileUriAsync(
				this.mobileAppDatabasesPath,
				LocalDatabaseProviderService.mobileAppDatabasesDirectory
			);
		} catch (poError) {
			console.error(
				`${LocalDatabaseProviderService.C_LOG_ID}Can not get database path from dataSource '${psDatabaseId}', version '${pnVersion}'`,
				poError
			);
			throw poError;
		}

		const lsDatabaseName: string = SqlFilesHelper.getFileName(psDatabaseId, pnVersion, poOptions);

		return `${lsFolderPath}${lsDatabaseName}`;
	}

	/** Compare la version de deux bases de données.
	 * @param poFileA Première base de données.
	 * @param poFileB Seconde base de données.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	private compareFileVersion(poFileA: FileInfo, poFileB: FileInfo, psDatabaseId: string): number {
		return (
			this.getVersionFromFileName(poFileA.name, psDatabaseId) - this.getVersionFromFileName(poFileB.name, psDatabaseId)
		);
	}

	/** Retourne la version de la base de données à partir du nom de son fichier.
	 * @param psFileName Le nom du fichier de la base de données.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	private getVersionFromFileName(psFileName: string, psDatabaseId: string): number {
		if (StringHelper.isBlank(psDatabaseId)) throw new VersionNotFoundError(psFileName);

		if (StringHelper.isBlank(psFileName)) {
			console.error(
				`${LocalDatabaseProviderService.C_LOG_ID}Recovery of the database version from database file name failed because the database '${psDatabaseId}' with name '${psFileName}' is not valid.`
			);
			throw new Error(
				`Recovery of the database version from database file name failed because the database '${psDatabaseId}' with name '${psFileName}' is not valid.`
			);
		}

		const lsVersion: string = psFileName.match(this.getRegex(psDatabaseId))?.[2] ?? "";

		if (NumberHelper.isStringNumber(lsVersion)) return +lsVersion;
		else throw new VersionNotFoundError(psFileName);
	}

	/** Récupère les fichiers des bases de données qui correspondent aux informations données en paramètre.
	 * @param psDatabaseId Identifiant des bases de données à récupérer.
	 * @param pnVersion Version de la base de données à récupérer.
	 * @param poOptions Options pour la récupération.
	 */
	private getDatabaseFilesInfoAsync(
		psDatabaseId: string,
		pnVersion?: number,
		poOptions?: IProviderFilesOptions
	): Promise<FileInfo[]> {
		if (StringHelper.isBlank(psDatabaseId)) {
			console.error(
				`${LocalDatabaseProviderService.C_LOG_ID}Database id '${psDatabaseId}' (version '${pnVersion}') is not valid.`
			);
			return Promise.reject(
				new Error(
					`Recovery of database files has failed because database id '${psDatabaseId}' (version '${pnVersion}') is not valid.`
				)
			);
		} else {
			const loRegex: RegExp = this.getRegex(psDatabaseId, pnVersion);

			// On récupère tous les fichiers puis on filtre ceux qui ne commencent pas par le databaseId.
			return this.isvcFileSystem
				.getFilesInfoAsync(this.mobileAppDatabasesPath, LocalDatabaseProviderService.mobileAppDatabasesDirectory)
				.then((paDatabaseFilesInfo: FileInfo[]) => {
					// On filtre les fichiers en fonction de la regex du provider.
					const laFilteredDbFilesInfo: FileInfo[] = paDatabaseFilesInfo.filter((poDatabaseFileInfo: FileInfo) =>
						loRegex.test(poDatabaseFileInfo.name)
					);

					if (poOptions?.includesNotUsableDatabases) return laFilteredDbFilesInfo;
					else {
						const lsExtension: TSqliteExtension = poOptions?.includesZipOnly ? "zip" : "db";
						return laFilteredDbFilesInfo.filter((poDatabaseFile: FileInfo) =>
							poDatabaseFile.name.endsWith(lsExtension)
						);
					}
				});
		}
	}

	/** Récupère les fichiers des bases de données qui correspondent aux informations données en paramètre, triés par version croissante.
	 * @param psDatabaseId Identifiant des bases de données à récupérer.
	 * @param pnVersion Version de la base de données à récupérer.
	 * @param poOptions Options pour la récupération.
	 */
	private getSortedDatabaseFilesInfoAsync(
		psDatabaseId: string,
		pnVersion?: number,
		poOptions?: IProviderFilesOptions
	): Promise<FileInfo[]> {
		return this.getDatabaseFilesInfoAsync(psDatabaseId, pnVersion, poOptions).then((paDatabaseFiles: FileInfo[]) =>
			paDatabaseFiles.sort((poFileA: FileInfo, poFileB: FileInfo) =>
				this.compareFileVersion(poFileA, poFileB, psDatabaseId)
			)
		);
	}

	/** Télécharge une base de données zippée et l'enregistre sur disque une fois dézipée.
	 * @param poDataSource Base de données à télécharger.
	 * @param poHeaders En-tête HTTP.
	 */
	private downloadZippedDatabase$(poDataSource: SqlDataSource, poHeaders?: TTransferHeaders): Observable<UpdateEvent> {
		return defer(() =>
			this.getDatabasePathAsync(poDataSource.databaseId, poDataSource.version, { includesZipOnly: true })
		).pipe(
			tap((_) =>
				this.logActionId(
					`Download zipped database '${poDataSource.databaseId}' in version '${poDataSource.version}' starts.`,
					ELogActionId.sqliteDownloadStart
				)
			),
			mergeMap((psFilePath: string) => {
				if (poHeaders)
					// Si le header est présent, on surcharge son "accept" pour ne pas récupérer autre chose qu'un ".zip".
					poHeaders.accept = EXTENSIONS_AND_MIME_TYPES.zip.mimeType;

				return this.isvcTransfer.downloadAndSave$(
					poDataSource.path,
					psFilePath,
					poHeaders ?? this.getHttpHeaders(EXTENSIONS_AND_MIME_TYPES.zip.mimeType)
				);
			}),
			mergeMap((poUpdateEvent: UpdateEvent) => this.unzipDatabase$(poDataSource, poUpdateEvent))
		);
	}

	private unzipDatabase$(poDataSource: SqlDataSource, poUpdateEvent: UpdateEvent): Observable<UpdateEvent> {
		if (poUpdateEvent.state === EUpdateStatus.saved) {
			const loUpdateEventSubject = new Subject<UpdateEvent>();

			return loUpdateEventSubject.asObservable().pipe(
				startWith(new UpdateEvent(EUpdateStatus.unzipping, 0)),
				afterSubscribe(() => {
					this.extractAndRemoveZippedDatabaseAsync(poDataSource)
						.then(() => loUpdateEventSubject.next(new UpdateEvent(EUpdateStatus.saved, 100)))
						.finally(() => loUpdateEventSubject.complete());
				})
			);
		} else return of(poUpdateEvent);
	}

	private async extractAndRemoveZippedDatabaseAsync(poDataSource: SqlDataSource): Promise<void> {
		let loLastZippedDatabaseFileInfo: FileInfo | undefined;

		try {
			loLastZippedDatabaseFileInfo = await this.getLatestDatabaseFileInfoAsync(poDataSource.databaseId, {
				includesZipOnly: true
			});

			if (loLastZippedDatabaseFileInfo) {
				// On extrait la base de données de l'archive dans le dossier où se trouve l'archive.
				await this.isvcZip.extractAsync(
					loLastZippedDatabaseFileInfo.uri,
					loLastZippedDatabaseFileInfo.uri.replace(
						ArrayHelper.getLastElement(loLastZippedDatabaseFileInfo.uri.split("/")),
						""
					)
				);

				// Suppression de l'archive, maintenant qu'on a récupéré la base de données qui était contenue dedans.
				await this.isvcFileSystem.removeFileAsync(loLastZippedDatabaseFileInfo.uri);
			} else
				console.error(
					`${LocalDatabaseProviderService.C_LOG_ID}No latest database file info for database '${poDataSource.databaseId}', can not extract zipped database.`
				);
		} catch (poError) {
			console.error(
				`${LocalDatabaseProviderService.C_LOG_ID}Error when installing zipped database '${poDataSource.databaseId}' in version '${poDataSource.version}' :`,
				poError
			);

			if (loLastZippedDatabaseFileInfo) await this.isvcFileSystem.removeFileAsync(loLastZippedDatabaseFileInfo.uri);
		}
	}

	private installDatabaseAsync(poDataSource: SqlDataSource, poEvent: UpdateEvent): Promise<UpdateEvent> {
		// Quand le téléchargement (avec extraction ou non) est terminé, on doit vérifier l'intégrité des données.
		const loActionAsync: Promise<void> =
			poEvent.state === EUpdateStatus.saved ? this.controlDownloadedDatabaseAsync(poDataSource) : Promise.resolve();

		return loActionAsync.then(() => poEvent);
	}

	/** Contrôle que la nouvelle base de données téléchargée est valide, lève une erreur si ce n'est pas le cas.
	 * @param poDataSource Source de données à contrôler.
	 * @throws `ProvideDatabaseError` en cas d'échec de contrôle de la nouvelle base de données.
	 * @throws Autres erreurs.
	 */
	private async controlDownloadedDatabaseAsync(poDataSource: SqlDataSource): Promise<void> {
		const loDatabaseFileInfo: FileInfo | undefined = await this.getLatestDatabaseFileInfoAsync(poDataSource.databaseId);
		let loProvidedError: ProvideDatabaseError | undefined;

		if (loDatabaseFileInfo) {
			const lnVersion: number = this.getVersionFromFileName(loDatabaseFileInfo.name, poDataSource.databaseId);

			if (lnVersion !== poDataSource.version) {
				// La base de données téléchargée n'est pas correcte.
				this.logActionId(
					`Providing database '${poDataSource.databaseId}' in version '${poDataSource.version}' from file '${loDatabaseFileInfo.name}' in version '${lnVersion}' impossible.`,
					ELogActionId.sqliteProvidingFailed
				);
				loProvidedError = new ProvideDatabaseError(
					`Providing database '${poDataSource.databaseId}' in version '${poDataSource.version}' from file '${loDatabaseFileInfo.name}' in version '${lnVersion}' impossible.`
				);
			}
			// Si la vérification d'intégrité n'est pas bonne, on supprime la base de données car elle est corrompue.
			else if (!(await this.checksDatabaseIntegrityAsync(poDataSource.databaseId, loDatabaseFileInfo))) {
				this.logActionId(
					`Integrity of database '${poDataSource.databaseId}' in version '${poDataSource.version}' failed, removing database.`,
					ELogActionId.sqliteIntegrityFailed
				);
				await this.isvcFileSystem.removeFileAsync(loDatabaseFileInfo.uri);
				loProvidedError = new ProvideDatabaseError(
					`Integrity of database '${poDataSource.databaseId}' in version '${poDataSource.version}' failed.`
				);
			} else {
				// Cas de réussite, on crée juste un log action.
				this.logActionId(
					`Integrity of database '${poDataSource.databaseId}' in version '${poDataSource.version}' succeeded.`,
					ELogActionId.sqliteIntegritySuccess
				);
				this.logActionId(
					`Providing database '${poDataSource.databaseId}' in version '${poDataSource.version}' succeeded.`,
					ELogActionId.sqliteProvidingComplete
				);
			}
		} else {
			// Cas qui ne devrait pas arriver car si le téléchargement s'est fait, on peut récupérer les infos du fichier.
			this.logActionId(
				`No latest database file info for database '${poDataSource.databaseId}' in version '${poDataSource.version}', removing associated files if exist.`,
				ELogActionId.sqliteProvidingFailed
			);
			await this.removeDatabasesFromDataSourceAsync(poDataSource);
			loProvidedError = new ProvideDatabaseError(
				`No latest database file info for database '${poDataSource.databaseId}' in version '${poDataSource.version}'.`
			);
		}

		if (loProvidedError) throw loProvidedError;
	}

	/** Récupère les informations de la base de données la plus récente disponible localement à partir de son identifiant.
	 * @param psDatabaseId Identifiant de la base de données dont il faut récupérer les informations.
	 * @param poOptions Options pour la récupération.
	 */
	private getLatestDatabaseFileInfoAsync(
		psDatabaseId: string,
		poOptions?: IProviderFilesOptions
	): Promise<FileInfo | undefined> {
		return this.getSortedDatabaseFilesInfoAsync(psDatabaseId, undefined, poOptions).then(
			(paDatabaseFilesInfo: FileInfo[]) => ArrayHelper.getLastElement(paDatabaseFilesInfo)
		);
	}

	private async checksDatabaseIntegrityAsync(psDatabaseId: string, poLastDatabaseFileInfo: FileInfo): Promise<boolean> {
		const loNewDataSource = new SqlDataSource(
			psDatabaseId,
			this.getVersionFromFileName(poLastDatabaseFileInfo.name, psDatabaseId),
			poLastDatabaseFileInfo.name
		);
		const lfCloseDatabaseAsync: () => Promise<void> = () =>
			this.isvcAdapter.isOpened(loNewDataSource)
				? this.isvcAdapter.closeDatabaseFromDataSourceAsync(loNewDataSource)
				: Promise.resolve();

		try {
			await this.isvcAdapter.openAsync(loNewDataSource);

			const lsPartialDatabaseId: string | undefined = ArrayHelper.getFirstElement(psDatabaseId.split("-"));
			let lbResult: boolean;

			if (lsPartialDatabaseId)
				lbResult = await this.execIntegrityCheckAsync(psDatabaseId, lsPartialDatabaseId, loNewDataSource);
			else {
				console.warn(
					`${LocalDatabaseProviderService.C_LOG_ID}Can not get partial database id of '${psDatabaseId}' so can not check database integrity, considered ok.`
				);
				lbResult = true;
			}

			await lfCloseDatabaseAsync();

			return lbResult;
		} catch (poError) {
			console.error(
				`${LocalDatabaseProviderService.C_LOG_ID}Error when checking integrity for database '${psDatabaseId}':`,
				poError
			);

			await lfCloseDatabaseAsync();

			return false;
		}
		//! Pas de fermeture de la base dans un `finally` car cela bloque les vérifs d'intégrité suivantes.
	}

	private async execIntegrityCheckAsync(
		psDatabaseId: string,
		psPartialDatabaseId: string,
		poNewDataSource: SqlDataSource
	): Promise<boolean> {
		const loPerfManager = new PerformanceManager().markStart();
		const loPragmaCheckResult: SqlRequestResult<IPragmaCheck> = await this.isvcAdapter.requestAsync(
			poNewDataSource,
			LocalDatabaseProviderService.C_INTEGRITY_CHECK_REQUEST,
			[],
			psPartialDatabaseId
		);

		console.debug(
			`${LocalDatabaseProviderService.C_LOG_ID}Pragma integrity check for database '${psDatabaseId}' in version '${
				poNewDataSource.version
			}' in ${loPerfManager.markEnd().measure()}ms.`
		);

		return (
			loPragmaCheckResult.getFirstResult()?.integrity_check === LocalDatabaseProviderService.C_INTEGRITY_CHECK_OK_RESULT
		);
	}

	private downloadDatabase$(poDataSource: SqlDataSource, poHeaders?: TTransferHeaders): Observable<UpdateEvent> {
		return defer(() => this.getDatabasePathAsync(poDataSource.databaseId, poDataSource.version)).pipe(
			mergeMap((psFilePath: string) => {
				if (poHeaders)
					// Si le header est présent, on surcharge son "accept" pour ne pas récupérer autre chose qu'un ".db".
					poHeaders.accept = EXTENSIONS_AND_MIME_TYPES.db.mimeType;

				return this.isvcTransfer.downloadAndSave$(
					poDataSource.path,
					psFilePath,
					poHeaders ?? this.getHttpHeaders(EXTENSIONS_AND_MIME_TYPES.db.mimeType)
				);
			})
		);
	}

	private async removeDatabasesFromDataSourceAsync(poDataSource: SqlDataSource): Promise<void> {
		const laToRemoveFiles: FileInfo[] = await this.getDatabaseFilesInfoAsync(
			poDataSource.databaseId,
			poDataSource.version,
			{ includesNotUsableDatabases: true }
		);

		for (let lnIndex = 0; lnIndex < laToRemoveFiles.length; ++lnIndex) {
			await this.isvcFileSystem.removeFileAsync(laToRemoveFiles[lnIndex].uri);
		}
	}

	/** Retourne l'en-tête HTTP. */
	private getHttpHeaders(psMimeType: string): TTransferHeaders {
		const loHeaders: TTransferHeaders = {
			appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
			accept: psMimeType
		};

		if (!ConfigData.authentication.token) throw new Error("Unable to update sql database without token.");
		if (!ConfigData.environment.API_KEY) throw new Error("Unable to update sql database without api key.");

		loHeaders["token"] = ConfigData.authentication.token;
		loHeaders["api-key"] = ConfigData.environment.API_KEY;

		return loHeaders;
	}

	/** Affiche un `log action`.
	 * @param psMessage Message du log.
	 * @param peLogActionId Préfix du log.
	 * @param poError Erreur à affichier dans le log.
	 */
	private logActionId(psMessage: string, peLogActionId: ELogActionId, poError?: any): void {
		this.isvcLogger.action(LocalDatabaseProviderService.C_LOG_ID, psMessage, peLogActionId, undefined, poError);
	}

	/** Construit et retourne l'identifiant de la base de données grâce à son nom de fichier.
	 * @param psDatabasePrefix Préfix de la base de données.
	 * @param psFileName Nom du fichier de la base de données.
	 */
	private getDatabaseId(psDatabasePrefix: string, psFileName: string): string {
		if (StringHelper.isBlank(psDatabasePrefix)) {
			console.error(`${LocalDatabaseProviderService.C_LOG_ID}Database prefix '${psDatabasePrefix}' is not valid.`);
			throw new Error(`Recovery of database ID failed because database prefix is not valid.`);
		} else if (StringHelper.isBlank(psFileName)) {
			console.error(
				`${LocalDatabaseProviderService.C_LOG_ID}Database file name '${psFileName}' for database '${psDatabasePrefix}' is not valid.`
			);
			throw new Error(`Recovery of database ID failed because database file name is not valid.`);
		}

		const loRegex: RegExp = this.getRegex(psDatabasePrefix);
		const loRegexMatch: RegExpMatchArray | null = psFileName.match(loRegex);

		if (!loRegexMatch) {
			console.error(
				`${LocalDatabaseProviderService.C_LOG_ID}File name does not match database file name '${psDatabasePrefix}'.`
			);
			throw new Error(
				`Recovery of database ID failed, beacause file name does not match database file name '${psDatabasePrefix}'.`
			);
		} else return loRegexMatch[1];
	}

	/** Récupère une regex qui permet de déterminer si la chaîne testée est sous la forme `[nom bdd]-[version].[extension ('db' ou 'zip')]`.\
	 * L'utilisation de la regex donnera `null` si ça ne correspond pas, sinon un tableau avec les valeurs suivantes :
	 * - index 0 -> chaîne testée.
	 * - index 1 -> nom de la base de données (ce qu'il y avant le tiret qui précède la version).
	 * - index 2 -> version de la base de données.
	 * - index 3 -> extension du fichier.
	 * @param psDatabasePrefix Racine de la base de données (préfixe, id, ...).
	 * @param pnVersion Version de la base de données pour préciser la regex avec une version spécifique.
	 */
	private getRegex(psDatabasePrefix: string, pnVersion?: number): RegExp {
		return new RegExp(
			`(^${psDatabasePrefix}.*?)-(${NumberHelper.isValid(pnVersion) ? pnVersion.toString() : "\\d+"})\\.(${
				EXTENSIONS_AND_MIME_TYPES.db.key
			}|${EXTENSIONS_AND_MIME_TYPES.zip.key})$`
		);
	}

	private transformDatabaseFilesToSqlDataSources(
		paDatabaseFiles: FileInfo[],
		psDatabasePrefix: string
	): SqlDataSource[] {
		return paDatabaseFiles.map((poFile: FileInfo) => {
			return new SqlDataSource(
				this.getDatabaseId(psDatabasePrefix, poFile.name),
				this.getVersionFromFileName(poFile.name, psDatabasePrefix),
				poFile.name
			);
		});
	}

	//#endregion METHODS
}
