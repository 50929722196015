import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DateHelper } from "@calaosoft/osapp-common/dates/helpers/dateHelper";
import { ObservableArray } from "@calaosoft/osapp-common/observable/models/observable-array";
import { ObservableProperty } from "@calaosoft/osapp-common/observable/models/observable-property";
import { UserHelper } from "@calaosoft/osapp/helpers/user.helper";
import { UserData } from "@calaosoft/osapp/model/application/UserData";
import { FieldBase } from "@calaosoft/osapp/model/forms/FieldBase";
import { ActivePageManager } from "@calaosoft/osapp/model/navigation/ActivePageManager";
import { FormsService } from "@calaosoft/osapp/services/forms.service";
import { takeUntil, tap } from "rxjs";
import { C_NB_TODO_TASKS_IN_TODO_ITEMS } from "../../app/app.constants";
import { TaskOccurrence } from "../trade-events/models/task-occurrence";
import { TradeEventsService } from "../trade-events/services/trade-events.service";

@Component({
	selector: 'calao-todo-item-field',
	templateUrl: './todo-item-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoItemFieldComponent extends FieldBase implements OnInit {

	//#region FIELDS

	private readonly moActivePageManager = new ActivePageManager(this, this.isvcRouter);

	private readonly maParticipantIds: string[] = UserData.current?._id ? [UserHelper.getUserContactId()] : [];

	/** Date actuelle. */
	private readonly observableTodayDate = new ObservableProperty<Date>().bind(
		DateHelper.getDays$(),
		this
	);

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly observableUndatedTasksCounter = new ObservableProperty<number>(0);

	public readonly observableUndatedNbDiff = new ObservableProperty<number>();

	public readonly observableUndatedOccurrences = new ObservableArray<TaskOccurrence>();

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcTradeEvents: TradeEventsService,
		private readonly isvcRouter: Router,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.isvcTradeEvents.getUndatedOccurrences$(this.moActivePageManager, { participantIds: this.maParticipantIds })
			.pipe(
				tap((paOccurrences: TaskOccurrence[]) => {
					const lnOccurrencesCount: number = paOccurrences.length;
					this.observableUndatedTasksCounter.value = lnOccurrencesCount;
					const lnDiff = this.isvcTradeEvents.getTodoItemDiff(lnOccurrencesCount, C_NB_TODO_TASKS_IN_TODO_ITEMS); // On calcule la différence entre le total des tâches non datées et celles affichées dans l'item ToDo.
					this.observableUndatedNbDiff.value = (lnDiff > 0) ? lnDiff : 0;
					this.observableUndatedOccurrences.resetArray(this.isvcTradeEvents.prepareTodoItems(paOccurrences));
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	//#endregion
}