import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'calao-dashboard-list-header',
	templateUrl: './dashboard-list-header.component.html',
	styleUrls: ['./dashboard-list-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.width]": "'100%'"
	}
})
export class DashboardListHeaderComponent { }
