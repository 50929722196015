
import { Exclude, Expose } from '@calaosoft/osapp-common/class-transformer';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ResolveModel } from "@calaosoft/osapp-common/utils/decorators/resolve-model.decorator";
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { IRange } from '@calaosoft/osapp-common/utils/models/irange';
import { UserHelper } from '../../../helpers/user.helper';
import { EventOccurrenceAction } from './event-occurrence-action';
import { EventOccurrenceCriterion } from './event-occurrence-criterion';
import { IConstraintLabelParams } from './iconstraint-label-params';
import { IEventOccurrence } from './ievent-occurrence';
import { IEventOccurrenceConstraint } from './ievent-occurrence-constraint';

export class EventOccurrenceConstraint implements IEventOccurrenceConstraint {

	//#region PROPERTIES

	/** @implements */
	@ResolveModel(EventOccurrenceCriterion)
	public criteria: EventOccurrenceCriterion[] = [];

	@ResolveModel(EventOccurrenceAction)
	public actions: EventOccurrenceAction[] = [];

	/** @implements */
	public decription?: string;
	@Exclude()
	private msCreateUserContactId: string = UserHelper.getUserContactId();
	/** @implements */
	@Expose()
	public get createUserContactId(): string { return this.msCreateUserContactId; }
	public set createUserContactId(psCreateUserContactId: string) {
		if (psCreateUserContactId !== this.msCreateUserContactId)
			this.msCreateUserContactId = psCreateUserContactId;
	}

	@Exclude()
	private mdCreateDate: Date;
	/** @implements */
	@Expose()
	public get createDate(): Date { return this.mdCreateDate; }
	public set createDate(pdCreateDate: Date) {
		if (DateHelper.isDate(pdCreateDate) && pdCreateDate !== this.mdCreateDate)
			this.mdCreateDate = new Date(pdCreateDate);
	}

	/** @implements */
	public rev: string;

	//#endregion

	//#region METHODS

	constructor(poConstraint?: Partial<IEventOccurrenceConstraint>) {
		if (poConstraint)
			ObjectHelper.assign(this, poConstraint);
		this.mdCreateDate = new Date;
	}

	public getLabel(poParams: IConstraintLabelParams): string {
		return `Séance(s) ${this.getOccurrencesLabel(poParams)}: ${this.buildlabel(poParams)}`;
	};

	/** Indique si on peut appliquer la contrainte à l'occurrence.
	 * @param poEventOccurrence
	 */
	public match(poEventOccurrence: IEventOccurrence): boolean {
		return this.criteria.every((poOccurenceComparator: EventOccurrenceCriterion) => poOccurenceComparator.match(poEventOccurrence));
	}

	/** Action à effectuer sur l'occurrence.
	 * @param poEventOccurrence
	 */
	public execActions(poEventOccurrence: IEventOccurrence): void {
		this.actions.forEach((poEventOccurrenceAction: EventOccurrenceAction) => poEventOccurrenceAction.action(poEventOccurrence));
	}

	/** Construit la description de la contrainte. */
	public buildlabel(poParams?: IConstraintLabelParams): string {
		return "";
	}

	public getIntervenantsIds(): string[] {
		return [];
	}

	/** Récupère la description des occurrences. */
	private getOccurrencesLabel(poParams: IConstraintLabelParams): string {
		return `${this.criteria.map((poItem: EventOccurrenceCriterion) => poItem.getLabel(poParams)).join(" - ")} `;
	}

	public execActionsOnRange(poRange: IRange<Date>): IRange<Date> {
		let loRange: IRange<Date> = { ...poRange };
		this.actions.forEach((poAction: EventOccurrenceAction) => loRange = poAction.actionOnRange(loRange));
		return loRange;
	}

	public canMatchRange(poRange: IRange<Date>): boolean {
		return this.criteria.every((poCriterion: EventOccurrenceCriterion) => poCriterion.canMatchRange(poRange));
	}

	//#endregion

}
