import { ObjectHelper } from "../helpers/objectHelper";

export class NotImplementedError extends Error {

	constructor(psThingNotImplemented: string) {
		super(`'${psThingNotImplemented}' not implemented !`);
		// Nécessaire pour que le 'instanceof NotImplementedError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, NotImplementedError);
	}

}