import { ObjectHelper } from "@calaosoft/osapp-common/utils/helpers/objectHelper";
import { Directory, Encoding } from "@capacitor/filesystem";
import { EFileError } from "../efile-error";
import { FilesystemErrorBase } from "./filesystem-error-base";

export class FilesystemReadFileAsTextError extends FilesystemErrorBase {

	constructor(poError: any, psPath: string, peDirectory: Directory, peEncoding: Encoding) {
		let leErrorCode: EFileError;

		if (typeof poError.code === "number")
			leErrorCode = poError.code === 1 || poError.code === 13 ? EFileError.fileNotFound : EFileError.other;
		else
			leErrorCode = EFileError.noFileErrorCode;

		super(poError, `Can not read file as text from '${psPath}' in directory '${peDirectory}' with encoding '${peEncoding}', error code is '${leErrorCode}'.`);
		// Nécessaire pour que le 'instanceof FilesystemReadFileAsTextError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemReadFileAsTextError);
	}

}