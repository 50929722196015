import { ESyncType } from '@calaosoft/osapp-common/store/models/esync-type';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';

export class UnknownSyncTypeError extends Error {

	constructor(peSyncType: ESyncType) {
		super(`Le type de synchronisation des bases de données '${peSyncType}' est inconnu.`);
		// Nécessaire pour que le 'instanceof UnknownSyncTypeError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, UnknownSyncTypeError);
	}

}