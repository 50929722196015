import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ObserveArray } from '@calaosoft/osapp-common/observable/decorators/observe-array.decorator';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { DestroyableComponentBase } from "@calaosoft/osapp/modules/utils/components/destroyable-component-base";
import { ESectorPanelMode } from "../../models/esector-panel-mode";
import { ISectorPanelOptions } from "../../models/isector-panel";

@Component({
	selector: "sector-panel",
	templateUrl: "./sector-panel.component.html",
	styleUrls: ["./sector-panel.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorPanelComponent extends DestroyableComponentBase {
	//#region FIELDS

	private static readonly C_LOG_ID = "SECT-TP.C::";

	//#endregion FIELDS

	//#region PROPERTIES

	@Input() public sectorPanelMode?: ESectorPanelMode;
	@ObserveProperty<SectorPanelComponent>({ sourcePropertyKey: "sectorPanelMode" })
	public readonly currentMode = new ObservableProperty<ESectorPanelMode>();


	@Input() public sectorPanelOptions?: ISectorPanelOptions[];
	@ObserveArray<SectorPanelComponent>("sectorPanelOptions")
	public readonly observableOptions = new ObservableArray<ISectorPanelOptions>();

	public readonly isSectorPanelVisible = new ObservableProperty<boolean>(false);

	//#endregion PROPERTIES

	//#region METHODS

	constructor() {
		super();
	}

	/** Déplie le sectorpanel . */
	public toggleSectorPanelOn(): void {
		this.isSectorPanelVisible.value = true;
	}

	/** Replie le sectorpanel . */
	public toggleSectorPanelOff(): void {
		this.isSectorPanelVisible.value = false;
	}

	//#endregion METHODS
}
