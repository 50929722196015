import { ChangeDetectorRef, Component } from '@angular/core';
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { Observable, defer, firstValueFrom, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { PathHelper } from '../../../../../../helpers/path-helper';
import { GalleryService } from '../../../../../../services/gallery.service';
import { DocPickerComponent } from '../../../../../doc-explorer/components/doc-picker/doc-picker.component';
import { Document } from '../../../../../doc-explorer/models/document';
import { FormDocument } from '../../../../../doc-explorer/models/form-document';
import { DocExplorerDocumentsService } from '../../../../../doc-explorer/services/doc-explorer-documents.service';
import { DocumentStatusService } from '../../../../../doc-explorer/services/document-status.service';
import { EntitiesService } from '../../../../../entities/services/entities.service';
import { ModalService } from '../../../../../modal/services/modal.service';
import { IDeletableTag } from '../../../../../tags/models/ideletable-tag';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineListField } from '../inline-list-field-layout/models/iinline-list-field';
import { IInlineListFieldLayoutParams } from '../inline-list-field-layout/models/iinline-list-field-layout-params';
import { IDocumentsFieldParams } from './models/idocuments-field-params';

@Component({
	selector: 'calao-documents-field',
	templateUrl: './documents-field.component.html',
	styleUrls: ['./documents-field.component.scss'],
})
export class DocumentsFieldComponent extends FieldBase<string[]> implements IInlineListField {

	//#region PROPERTIES

	public readonly observableParams = new ObservableProperty<IDocumentsFieldParams>();

	public readonly observableModels = new ObservableArray<Document>([]);
	public readonly observableTags = new ObservableArray<IDeletableTag>([]);

	public layout: 'inline';
	public layoutParams: IInlineListFieldLayoutParams;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		private readonly isvcDocumentStatus: DocumentStatusService,
		private readonly isvcEntities: EntitiesService,
		private readonly isvcGallery: GalleryService,
		private readonly isvcModal: ModalService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		const loParams: IDocumentsFieldParams = this.to.data ?? {};

		if (loParams.readOnly === undefined)
			loParams.readOnly = this.to.readonly;

		this.layout = loParams.layout;
		this.layoutParams = loParams.layoutParams;
		this.observableParams.value = loParams;

		this.observableFieldValue.value$.pipe(
			switchMap((paIds: string[]) => this.isvcDocExplorerDocuments.getDocumentsByIds$(paIds)),
			tap((paDocuments: Document[]) => this.observableModels.resetArray(paDocuments)),
		).subscribe();

		this.observableModels.changes$.pipe(
			tap((paDocs: Document[]) => {
				this.observableTags.resetArray(paDocs.map((poDoc: Document) => {
					return {
						id: poDoc._id,
						label: poDoc.name,
						deletable: true
					}
				}))
			}),
			secure(this)
		).subscribe();
	}

	public onAddDocumentClickedAsync(): Promise<Document> {
		return firstValueFrom(this.pickDocument$());
	}

	@Queue<
		DocumentsFieldComponent,
		Parameters<DocumentsFieldComponent["pickDocument$"]>,
		ReturnType<DocumentsFieldComponent["pickDocument$"]>
	>({
		excludePendings: true
	})
	public pickDocument$(): Observable<Document> {
		const lsEntityId: string | undefined = this.observableParams.value.entityId;

		return defer(() => StringHelper.isBlank(lsEntityId) ? of(undefined) : this.isvcEntities.getModel$(lsEntityId)).pipe(
			take(1),
			mergeMap((poEntity?: Entity) => this.isvcModal.open<Document | undefined>({
				component: DocPickerComponent,
				componentProps: {
					rootPath: poEntity ? PathHelper.parsePath(ArrayHelper.getFirstElement(this.isvcEntities.getEntityDocumentPaths(poEntity))) : this.observableParams.value.rootPath
				}
			})),
			tap((poDocument?: Document) => {
				if (poDocument) {
					this.fieldValue = this.fieldValue ? [...this.fieldValue, poDocument._id] : [poDocument._id];
					this.observableModels.push(poDocument);
				}
			})
		);
	}

	public async onOpenDocumentAsync(poDocument: Document) {
		return firstValueFrom(this.openDocument$(poDocument).pipe(secure(this)));
	}

	@Queue<
		DocumentsFieldComponent,
		Parameters<DocumentsFieldComponent["openDocument$"]>,
		ReturnType<DocumentsFieldComponent["openDocument$"]>
	>({
		excludePendings: true
	})
	private openDocument$(poDocument: Document): Observable<boolean> {
		return defer(() => {
			if (poDocument instanceof FormDocument) {
				return this.isvcEntities.navigateToEntityViewAsync(
					poDocument
				);
			}
			else
				return this.isvcGallery.openFile(poDocument);
		}).pipe(
			mergeMap(() => this.isvcDocumentStatus.changeReadSatus$(poDocument, "read"))
		);
	}

	public removeDocument(psId: string): void {
		ArrayHelper.removeElement(this.fieldValue, psId);
		ArrayHelper.removeElementById(this.observableModels, psId);
	}

	//#endregion METHODS

}
