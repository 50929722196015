import { Injectable } from '@angular/core';
import { IConstructor } from "@calaosoft/osapp-common/utils/models/iconstructor";
import { ModalController } from '@ionic/angular';
import { ModalOptions, OverlayEventDetail } from '@ionic/core';
import { Observable, defer } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { EModalSize } from '../model/EModalSize';
import { ModalComponentsRegistry } from '../model/modal-components-registry';

@Injectable()
export class ModalService {

	//#region FIELDS

	private static readonly C_LOG_ID = "MDL.S::";

	//#endregion FIELDS

	//#region METHODS

	constructor(private readonly ioModalCtrl: ModalController) { }

	public open<T = any>(poOptions: ModalOptions, peModalSize?: EModalSize): Observable<T | undefined> {
		if (peModalSize)
			poOptions.cssClass = `${poOptions.cssClass ?? ""} ${peModalSize}`;

		if (typeof poOptions.component === "string") {
			const loConstructor: IConstructor | undefined = ModalComponentsRegistry.get(poOptions.component);

			if (loConstructor)
				poOptions.component = loConstructor;
			else
				console.error(`${ModalService.C_LOG_ID}Component '${poOptions.component}' not found, can not open modal`);
		}

		return defer(() => this.ioModalCtrl.create(poOptions))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				map((poResponse: OverlayEventDetail<T>) => poResponse.data)
			);
	}

	//#endregion

}