import { Exclude } from "@calaosoft/osapp-common/class-transformer";
import { Entity } from "@calaosoft/osapp-common/entities/models/entity";
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { IPicture } from "../picture/IPicture";
import { IContact } from "./IContact";
import { IGroup } from "./IGroup";

@ModelMatch((poGroup?: Entity) => IdHelper.hasPrefixId(poGroup?._id ?? "", EPrefix.group), Entity)
export class Group extends Entity implements IGroup {

	//#region PROPERTIES

	/** @implements */
	public description?: string;
	/** @implements */
	public name: string;
	/** @implements */
	public isUserGroup?: boolean;
	/** @implements */
	public color?: string;
	/** @implements */
	public picture?: IPicture;
	/** @implements */
	public roles?: string[];

	@Exclude({ toPlainOnly: true })
	public contacts?: IContact[];

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: Partial<IGroup>) {
		super();

		if (poData)
			ObjectHelper.assign(this, poData);
	}

	//#endregion METHODS
}