import { ArrayHelper } from "../../utils/helpers/arrayHelper";
import { NumberHelper } from "../../utils/helpers/numberHelper";
import { ObjectHelper } from "../../utils/helpers/objectHelper";
import { ESortOrder } from "../../utils/models/ESortOrder";
import { IKeyValue } from "../../utils/models/ikey-value";
import { IRange } from "../../utils/models/irange";
import { IBatchedRequest } from "../models/ibatched-request";
import { AND_REQUEST, COUNT_ALL_REQUEST, CREATE_INDEX_REQUEST, CREATE_TABLE_REQUEST, DELETE_REQUEST, DESC_REQUEST, DO_REQUEST, EXCLUDED_REQUEST, FROM_REQUEST, INSERT_REQUEST, IN_REQUEST, LIMIT_REQUEST, ON_CONFLICT_REQUEST, ON_REQUEST, ORDER_BY_REQUEST, SELECT_ALL_REQUEST, SELECT_REQUEST, SET_REQUEST, UPDATE_REQUEST, VALUES_REQUEST, WHERE_REQUEST } from "../sql.constants";
import { SqlHelper } from "./sql.helper";

export abstract class SqlRequestHelper {

	//#region FIELDS

	private static readonly C_MAX_VARIABLES = 999;

	//#endregion FIELDS

	//#region METHODS

	constructor() { }

	/** Retourne la requête permettant de récupérer tous les documents depuis une table.
	 * @param psTableName Nom de la table dans laquelle rechercher.
	 */
	public static selectAllFromTableRequest(psTableName: string): string {
		return `${SELECT_ALL_REQUEST} ${SqlHelper.sanitize(psTableName)}`;
	}

	/** Retourne la requête permettant de récupérer n'importe quel(s) document(s) depuis une table et qui satisfait une certaine condition.
	 * @param psKey Clé de l'objet pour vérifier la condition.
	 * @param pnNumberOfElements Nombre d'éléments qui doivent vérifier la condition (cas d'une récupération multiple).
	 */
	public static whereRequest<T>(psKey: keyof T, pnNumberOfElements: number = 1): string {
		if (pnNumberOfElements > 1)
			return `${WHERE_REQUEST} ${SqlHelper.sanitize(psKey as string)} ${this.getInRequest(pnNumberOfElements)}`;
		else
			return `${WHERE_REQUEST} ${SqlHelper.sanitize(psKey as string)} = ?`;
	}

	/** Retourne la requête permettant de récupérer n'importe quel(s) document(s) depuis une table et qui satisfait une certaine condition.
	 * @param psKey Clé de l'objet pour vérifier la condition.
	 * @param poRange Plage de clé à requêter.
	 */
	public static whereRangeRequest<T>(psKey: keyof T, poRange: IRange<string | number>): string {
		const lbHasFrom: boolean = ObjectHelper.isDefined(poRange.from);
		const lbHasTo: boolean = ObjectHelper.isDefined(poRange.to);
		if (lbHasTo || lbHasFrom) {
			let lsWhereCondition = "";
			if (lbHasFrom)
				lsWhereCondition += `${SqlHelper.sanitize(psKey as string)} >= ?`;
			if (lbHasTo)
				lsWhereCondition += `${lbHasFrom ? `${AND_REQUEST} ` : ""}${SqlHelper.sanitize(psKey as string)} <= ?`;

			return `${WHERE_REQUEST} ${lsWhereCondition}`
		}
		return "";
	}

	/** Retourne la requête permettant de ne récupérer qu'un certain nombre de résultats.
	 * @param pnLimit Nombre limite de résultats pour la requête.
	 */
	public static limitRequest(pnLimit?: number): string {
		return NumberHelper.isValidPositive(pnLimit) ? `${LIMIT_REQUEST} ${pnLimit}` : "";
	}

	/** Retourne une requête de type `IN (...)` qui représente les paramètres dynamiques de la requête.
	 * @param pnNumberOfParams Nombre de paramètres dynamiques.
	 * @example
	 * 2 ou plus -> IN (?, ?, ...)
	 * 1 ou moins -> IN (?)
	 */
	public static getInRequest(pnNumberOfParams: number): string {
		return `${IN_REQUEST} (${NumberHelper.isValidStrictPositive(pnNumberOfParams) ? new Array(pnNumberOfParams).fill("?").join(",") : "?"})`;
	}

	/** Retourne la requête permettant de récupérer un document spécifique d'une table à partir d'un identifiant `id`.
	 * @param psTableName Nom de la table dans laquelle requêter.
	 * @param poDocId Identifiant du document à récupérer.
	 * @returns
	 * - `DatabaseNotExistsError` si la base de données n'existe pas,
	 * - Document recherché, `undefined` si non trouvé,
	 * - Autre erreur quelconque.
	 */
	public static getById<T>(psTableName: string, poDocId: string | number): string {
		return this.getBy<T>(psTableName, "id" as keyof T, poDocId, 1);
	}

	/** Retourne la requête permettant de récupérer des documents spécifiques d'une table à partir de leurs identifiants `id`.
	 * @param psTableName Nom de la table dans laquelle requêter.
	 * @param paKeys Tableau des identifiants des documents à récupérer.
	 * @param pnLimit Nombre limite de résultats, aucune limite par défaut.
	 * @returns
	 * - `DatabaseNotExistsError` si la base de données n'existe pas,
	 * - Document recherché, `undefined` si non trouvé,
	 * - Autre erreur quelconque.
	 */
	public static getByKeys<T>(psTableName: string, poKey: keyof T, paKeys: Array<string | number>): IBatchedRequest<string | number>[] {
		return ArrayHelper.unflat(paKeys, SqlRequestHelper.C_MAX_VARIABLES).map((paIds: Array<string | number>) =>
			({ request: this.getBy<T>(psTableName, poKey, paIds), nbRows: paIds.length, values: paIds })
		);
	}

	/** Retourne la requête permettant de récupérer des documents spécifiques d'une table à partir de leurs identifiants `id`.
	 * @param psTableName Nom de la table dans laquelle requêter.
	 * @param paDocIds Tableau des identifiants des documents à récupérer.
	 * @param pnLimit Nombre limite de résultats, aucune limite par défaut.
	 * @returns
	 * - `DatabaseNotExistsError` si la base de données n'existe pas,
	 * - Document recherché, `undefined` si non trouvé,
	 * - Autre erreur quelconque.
	 */
	public static getByRange<T>(psTableName: string, poRange: IRange<string | number>, poKey: keyof T, pnLimit?: number): string {
		return `${this.selectAllFromTableRequest(psTableName)} ${this.whereRangeRequest(poKey, poRange)} ${this.limitRequest(pnLimit)}`
	}

	/** Retourne la requête permettant de récupérer tous les documents qui satisfont une condition.
	 * @param psTableName Nom de la table dans laquelle requêter.
	 * @param psKey Clé qui filtre les résultats (clé du where).
	 * @param poValue Valeur à satisfaire pour filtrer les résultats.
	 * @param pnLimit Nombre limite de résultats, aucune limite par défaut.
	 * @returns
	 * - `DatabaseNotExistsError` si la base de données n'existe pas,
	 * - Tableau des éléments de la table, tableau vide si aucun élément,
	 * - Autre erreur quelconque.
	 */
	public static getBy<T>(psTableName: string, psKey: keyof T, poValue: string | number, pnLimit?: number): string;
	/** Retourne la requête permettant de récupérer le premier document qui satisfait une condition, `undefined` si non trouvé.
	 * @param psTableName Nom de la table dans laquelle requêter.
	 * @param psKey Clé qui filtre les résultats (clé du where).
	 * @param poValue Valeur à satisfaire pour filtrer les résultats.
	 * @param pnLimit 1 seul résultat à retourner.
	 * @returns
	 * - `DatabaseNotExistsError` si la base de données n'existe pas,
	 * - Autre erreur quelconque.
	 */
	public static getBy<T>(psTableName: string, psKey: keyof T, poValue: string | number, pnLimit: 1): string;
	/** Retourne la requête permettant de récupérer tous les documents qui satisfont une condition.
	 * @param psTableName Nom de la table dans laquelle requêter.
	 * @param psKey Clé qui filtre les résultats (clé du where).
	 * @param paValues Tableau des valeurs à satisfaire pour filtrer les résultats.
	 * @param pnLimit Nombre limite de résultats, aucune limite par défaut.
	 * @returns
	 * - `DatabaseNotExistsError` si la base de données n'existe pas,
	 * - Tableau des éléments de la table, tableau vide si aucun élément,
	 * - Autre erreur quelconque.
	 */
	public static getBy<T>(psTableName: string, psKey: keyof T, paValues: Array<string | number>, pnLimit?: number): string;
	public static getBy<T>(psTableName: string, psKey: keyof T, poData: (string | number) | Array<string | number>, pnLimit?: number): string {
		const lnLength: number = poData instanceof Array ? poData.length : 1;
		return `${this.selectAllFromTableRequest(psTableName)} ${this.whereRequest(psKey, lnLength)} ${this.limitRequest(pnLimit)}`;
	}

	public static getInsertRequest<T>(psTableName: string, paKeys: (keyof T)[], paValues: Array<T>): IBatchedRequest<T>[] {
		const lsInsertValuesPattern: string = paKeys.map(() => "?").join();
		const laRequests: IBatchedRequest<T>[] = [];
		// Pour 1000 éléments avec 5 clés = 5000 clés
		// 5000 / 999 = 5,005 (nb batch EXACT, donc 5 plein et 1 partiel)
		// 1000 / 5 (arrondi du nombre de batch à l'inférieur) = 199,8 // nombre max d'éléments dans le batch
		const lnNbBatch: number = (paValues.length * paKeys.length) / SqlRequestHelper.C_MAX_VARIABLES;
		const laBatchedValues: T[][] = ArrayHelper.unflat(
			paValues,
			Math.floor(paValues.length / lnNbBatch)
		); // On vient créer des batch de requêtes pour ne pas dépasser le nombre de variables sql max.

		laBatchedValues.forEach((paBatchedValues: T[]) => {
			laRequests.push({
				request: this.getBatchInsertRequest<T>(psTableName, paKeys, paBatchedValues, lsInsertValuesPattern),
				nbRows: paBatchedValues.length,
				values: paBatchedValues
			});
		});

		return laRequests;
	}

	public static getUpdateByRequest<T>(psTableName: string, paKeys: (keyof T)[], psByKey: keyof T): string {
		return `${UPDATE_REQUEST} ${psTableName} ${SET_REQUEST} ${paKeys.map((psKey: keyof T) => `${psKey.toString()} = ?`)} ${this.whereRequest<T>(psByKey)}`;
	}

	private static getBatchInsertRequest<T>(
		psTableName: string,
		paKeys: (keyof T)[],
		paBatchedValues: T[],
		psInsertValuesPattern: string
	): string {
		return `${INSERT_REQUEST} ${SqlHelper.sanitize(psTableName)} \
(${paKeys.map((psKey: keyof T) => SqlHelper.sanitize(psKey.toString())).join()}) ${VALUES_REQUEST} \
${paBatchedValues.map(() => `(${psInsertValuesPattern})`).join()}`;
	}

	public static getOnConflictUpdateRequest<T>(paPrimaryKeys: (keyof T)[], paUpdatableKeys: (keyof T)[]): string {
		return `${ON_CONFLICT_REQUEST} \
(${paPrimaryKeys.map((psKey: keyof T) => SqlHelper.sanitize(psKey.toString())).join()}) \
${DO_REQUEST} ${UPDATE_REQUEST} ${SET_REQUEST} \
${paUpdatableKeys.map((psKey: keyof T) => {
			const lsKey: string = SqlHelper.sanitize(psKey.toString());
			return `${lsKey} = ${EXCLUDED_REQUEST}.${lsKey}`;
		}).join()}`;
	}

	public static getDeleteRequestByIds(psTableName: string, paDocIds: Array<string | number>): string {
		return `${DELETE_REQUEST} ${SqlHelper.sanitize(psTableName)} ${this.whereRequest("id", paDocIds.length)}`;
	}

	public static getDeleteRequestLessOrEqual<T>(psTableName: string, psKey: keyof T): string {
		return `${this.getDeleteRequest(psTableName)} \
${WHERE_REQUEST} ${SqlHelper.sanitize(psKey.toString())} <= ?`;
	}

	public static getDeleteRequest<T>(psTableName: string): string {
		return `${DELETE_REQUEST} ${SqlHelper.sanitize(psTableName)}`;
	}

	public static getCreateTableRequest<T>(
		psTableName: string,
		paPrimaryKeyColumns: (keyof T)[],
		paFields: IKeyValue<keyof T, string>[]
	): string {
		return `${CREATE_TABLE_REQUEST} ${psTableName} \
(${paFields.map((poField: IKeyValue<keyof T, string>) =>
			`${SqlHelper.sanitize(poField.key.toString())} ${SqlHelper.sanitize(poField.value)}`
		).join()}, \
PRIMARY KEY (${paPrimaryKeyColumns.map((psKey: keyof T) => SqlHelper.sanitize(psKey.toString())).join()}))`;
	}

	public static getCreateIndexRequest<T>(
		psIndexName: string,
		psTableName: string,
		paKeys: (keyof T)[]
	): string {
		return `${CREATE_INDEX_REQUEST} ${SqlHelper.sanitize(psIndexName)} \
${ON_REQUEST} ${SqlHelper.sanitize(psTableName)} \
(${paKeys.map((psKey: keyof T) => SqlHelper.sanitize(psKey.toString())).join()})`;
	}

	public static getOrderByRequest(psKey: string, peSortOrder: ESortOrder = ESortOrder.ascending): string {
		return `${ORDER_BY_REQUEST} ${SqlHelper.sanitize(psKey)} \
${peSortOrder === ESortOrder.descending ? ` ${DESC_REQUEST}` : ""}`;
	}

	public static getCountRequest(psTableName: string): string {
		return `${SELECT_REQUEST} ${COUNT_ALL_REQUEST} ${FROM_REQUEST} ${SqlHelper.sanitize(psTableName)}`;
	}

	//#endregion

}