import { Type } from '@angular/core';
import { RouteComponent } from '@calaosoft/osapp/model/RouteComponent';
import { ConfigOption, FieldType } from '@ngx-formly/core';
import { BusinessActivitiesSlideComponent } from '../modules/businesses/components/business-activities-slide/business-activities-slide.component';
import { TradeConversationTabComponent } from '../modules/businesses/components/conversation-tab/conversation-tab.component';
import { TradeConversationsListComponent } from '../modules/businesses/components/conversationsList/tradeConversationsList.component';
import { SignatureWrapperComponent } from '../modules/businesses/components/signature-wrapper/signature-wrapper.component';
import { ActivitySummaryFieldComponent } from '../modules/custom-field/activity-summary-field.component';
import { ActivityTileFieldComponent } from '../modules/custom-field/activity-tile-field.component';
import { LateItemFieldComponent } from '../modules/custom-field/late-item-field.component';
import { OpportunityTileFieldComponent } from '../modules/custom-field/opportunity-tile-field.component';
import { ScrollFieldComponent } from '../modules/custom-field/scroll-field.component';
import { TodoItemFieldComponent } from '../modules/custom-field/todo-item-field.component';
import { ConfidentialityPageComponent } from '../modules/mentionsLegales/confidentialityPage.component';
import { TermsOfSalesPageComponent } from '../modules/mentionsLegales/termesOfSalesPage.component';
export abstract class TradeComponentRegister {

	//#region FIELDS

	/** Tableau de tous les composants d'Tradeizy. */
	private static readonly maComponents: RouteComponent[] = [
		{ id: "confidentiality-page", componentType: ConfidentialityPageComponent },
		{ id: "terms-of-sales-page", componentType: TermsOfSalesPageComponent },
		{ id: "trade-conversations-list", componentType: TradeConversationsListComponent },
		{ id: "trade-conversations-tab", componentType: TradeConversationTabComponent },
		{ id: "trade-business-activities-slide", componentType: BusinessActivitiesSlideComponent },
	];

	//#endregion

	//#region METHODS

	/** Retourne tous les composants spécifiques d'Tradeizy, avec leurs id. */
	public static getTradeRouteComponents(): RouteComponent[] {
		return this.maComponents;
	}

	//#endregion

}

//#region FORMLY

/** Ce tableau contient l'ensemble des composants de liens customs. Ils seront inclus via ce tableau dans le module supérieur. */
export const C_FORMLY_CUSTOM_TRADE: Array<Type<FieldType>> = [
	ScrollFieldComponent,
	OpportunityTileFieldComponent,
	ActivityTileFieldComponent,
	ActivitySummaryFieldComponent,
	LateItemFieldComponent,
	TodoItemFieldComponent
]

/** Objet de configuration spécifiques à TRADE concernant les formulaires (champs, validateurs, ...). */
export const TRADE_FORMLY_CONFIG_OPTION: ConfigOption = {
	types: [
		{
			name: "opportunityTile",
			component: OpportunityTileFieldComponent
		},
		{
			name: "activityTile",
			component: ActivityTileFieldComponent
		},
		{
			name: "activitySummary",
			component: ActivitySummaryFieldComponent
		},
		{
			name: "lateItem",
			component: LateItemFieldComponent
		},
		{
			name: "todoItem",
			component: TodoItemFieldComponent
		},
		{
			name: "dragScroll",
			component: ScrollFieldComponent
		}
	],
	wrappers: [
		{
			name: "signature",
			component: SignatureWrapperComponent
		},
	],
	validators: [
	],
	validationMessages: [
	]
};

//#endregion