import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ILocalNotificationExtra } from "@calaosoft/osapp/model/notification/ilocal-notification-extra";
import { EReminderAlarmActionType } from "./ereminder-alarm-action-type";

export class ReminderAlarm {

	//#region PROPERTIES

	/** Date à laquelle le rappel devra être présenté à l'utilisateur */
	public get date(): Date {
		return DateHelper.addMinutes(this.referenceDate, (-1) * this.minutes);
	}

	/** Informations à ajouter à la notification OS */
	public get notificationExtra(): ILocalNotificationExtra {
		return { entityId: this.entityId, route: this.route, context: this.context };
	}

	//#endregion

	constructor(
		/** Entité sur laquelle porte le rappel */
		public entityId: string,
		/** Date de référence du rappel */
		public referenceDate: Date,
		/** Délai en minutes pour déclencher la notification AVANT la date de référence */
		public minutes: number,
		/** Titre de la notification */
		public title: string,
		/** Contenu de la noitification (optionnel) */
		public description?: string | undefined,
		/** Route vers laquelle diriger l'utilisateur à l'ouverture de la notification */
		public route?: string | undefined,
		/** Informations contextuelles supplémentaires sur l'entité */
		public context?: any | undefined,
		/** Type d'actions qui seront proposées par la notification */
		public actionType?: EReminderAlarmActionType | undefined
	) { }
}