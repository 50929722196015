import { ArrayHelper } from "../../utils/helpers/arrayHelper";
import { NumberHelper } from "../../utils/helpers/numberHelper";

export class SqlRequestResult<T> {

	//#region PROPERTIES

	private mnTime = 0;
	/** Temps d'exécution de la requête (en ms). */
	public get time(): number { return this.mnTime; }
	public set time(pnTime: number) {
		if (NumberHelper.isValid(pnTime))
			this.mnTime = pnTime;
	}

	private readonly maResults: T[] = [];
	/** Résultats de la requête. */
	public get results(): T[] { return this.maResults; }

	//#endregion PROPERTIES

	//#region METHODS

	public constructor() { }

	/** Premier résultat du tableau des résultats obtenus, `undefined` si pas de résultat. */
	public getFirstResult(): T | undefined {
		return ArrayHelper.getFirstElement(this.maResults);
	}

	public hasResults(): this is [T, ...T[]] {
		return ArrayHelper.hasElements(this.maResults);
	}

	//#endregion METHODS

}