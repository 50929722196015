import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { ContactNamePipe } from '@calaosoft/osapp/pipes/contactName.pipe';
import { IBusiness } from '../model/ibusiness';

@Pipe({ name: 'customerName' })
/** Utilisé uniquement dans la tournée.
 * @deprecated
 */
export class CustomerNamePipe extends ContactNamePipe implements PipeTransform {

	//#region METHODS

	constructor() {
		super();
	}

	/** Renvoie le nom complet du customer 'Prénom NOM' s'il est renseigné, 'Customer inconnu' sinon.
	 * @param poValue Customer dont il faut récupérer le nom complet.
	 */
	public override transform(poValue?: IBusiness): string {
		const lsValue: string | undefined = poValue?.name;
		return StringHelper.isBlank(lsValue) ? "Business inconnu" : lsValue;
	}

	//#endregion

}