import { Component, Input } from '@angular/core';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';

@Component({
	selector: 'calao-completion-bar',
	templateUrl: './completion-bar.component.html',
	styleUrls: ['./completion-bar.component.scss'],
})
export class CompletionBarComponent {

	//#region FIELDS

	private static readonly C_LOGGER = "CMPLTN-BAR.C::";

	/** Valeur du composant. */
	private msValue: number;

	//#endregion

	//#region PROPERTIES

	/** Valeur de la progression, incluse entre 0 et 1. */
	@Input()
	public set value(pnValue: number) {
		if (!NumberHelper.isValid(pnValue)) {
			console.warn(`${CompletionBarComponent.C_LOGGER}La valeur attendue doit être un nombre. Reçu "${pnValue}".`);
		} else if (pnValue < 0) {
			console.warn(`${CompletionBarComponent.C_LOGGER}La valeur attendue doit être supérieur ou égale à 0. Reçu "${pnValue}".`);
			this.msValue = 0;
		} else if (pnValue > 1) {
			console.warn(`${CompletionBarComponent.C_LOGGER}La valeur attendue doit être inférieur ou égale à 1. Reçu "${pnValue}".`);
			this.msValue = 1;
		} else {
			this.msValue = pnValue;
		}
		this.percentValue = this.msValue * 100;
	}

	public get value(): number { return this.msValue; }

	public percentValue: number;

	//#endregion

	//#region METHODS

	public constructor() { }

	//#endregion

}
