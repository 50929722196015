export enum EPrefix {
	unknown = "none",
	activity = "act_",
	calendar = "cal",
	component = "comp_",
	consent = "consent_",
	contact = "cont_",
	conversation = "conv_",
	conversationMeta = "convMeta_",
	conversationStatus = "convStatus_",
	/** Document représentant un document dms local. */
	dms = "doc_",
	/** Document représentant un document dms sur le serveur. */
	dmsDoc = "dmsDoc_",
	/** Marqueurs d'évènements utilisés par l'optimisation de tournée.  */
	tourEvent = "tourevt_",
	event = "evt_",
	eventParticipation = "evtPart_",
	group = "grp_",
	lastLocalToServerReplicationMode = "lastLTSRepMode_",
	link = "lnk_",
	local = "_local/",
	design = "_design/",
	message = "msg_",
	pending = "pen_",
	pendingDelete = "pen_del_",
	pendingDownload = "pen_dl_",
	pendingUpload = "pen_ul_",
	purchase = "pur_",
	task = "task_",
	user = "usr_",
	userSettings = "uset_",
	workspace = "ws_",
	workspaceConfig = "wsCfg_",
	documentStatus = "docStatus_",
	formDesc = "formDesc_",
	favorites = "favorites_",
	recents = "recents_",
	sync = "sync_",
	gdpr = "gdpr_",
	customer = "cust_",
	integrity = "intg_",
	site = "site_",
	prestation = "presta_",
	observation = "obs_",
	/** Préfixe d'un document de préférences utilisateur. */
	settings = "settings_",
	/** Document d'état pour un évènement de calendrier */
	eventState = "evtState_",
	eventOccurrenceDifferential = "evtOccDiff_",
	role = "role_",
	entityDesc = "entityDesc_",
	boardDesc = "boardDesc_",
	wsCfg = "wsCfg_",
	permissionRule = "permRule_",

	//#region Logistic

	/** Préfixe d'un conditionnement. */
	pack = "pack_",
	/** Préfixe d'un portant : `rack_` */
	rack = "rack_",
	/** Préfixe d'un document de bon de reprise sans le préfixe de tournée : `rct_`. */
	receipt = "rct_",
	/** Prefixe d'un document d'un rendez-vous */
	appoint = "appoint_",
	/** Prefixe d'un document de tournée. */
	tour = "tour_",
	/** Préfixe d'un motif. */
	reason = "reason_",
	/** Catégorie d'articles. */
	category = "cat_",
	/** Regroupement de plusieurs catégories d'articles. */
	categoryGroup = "catgroup_",

	//#endregion
}