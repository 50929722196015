import { Injectable } from '@angular/core';
import { IEntity } from '@calaosoft/osapp-common/entities/models/ientity';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { MetaEntityBuilderBase } from '@calaosoft/osapp/modules/dms/model/MetaEntityBuilderBase';
import { C_PREFIX_TOURNEE } from '../../../app/app.constants';

@Injectable()
export class MetaTourneeBuilder extends MetaEntityBuilderBase {

	//#region METHODS

	public override match(psDocumentId: string): boolean {
		return IdHelper.hasPrefixId(psDocumentId, C_PREFIX_TOURNEE);
	}

	public override getEntityContainerId(poEntity: IEntity): string {
		return this.isvcWorkspace.getWorkspaceIdFromDatabaseId(this.isvcWorkspace.getDefaultWorkspaceDatabaseId());
	}

	//#endregion

}
