<ng-container *ngTemplateOutlet="(canDisplayActivities$ | async) ? activities : noActivities"></ng-container>

<ng-template #activities>
	<calao-dashboard-list>
		<calao-dashboard-list-item *ngFor="let item of observableActivities.changes$ | async">
			<trade-event-item *ngIf="isEventOccurrence(item)" [item]="item"></trade-event-item>
			<trade-task-item *ngIf="isTaskOccurrence(item)" [taskOccurrence]="item"></trade-task-item>
			<trade-reminder-item *ngIf="isReminderOccurrence(item)" [item]="item"></trade-reminder-item>
		</calao-dashboard-list-item>
		<calao-dashboard-list-header>
			<ng-container *ngTemplateOutlet="header"></ng-container>
		</calao-dashboard-list-header>
	</calao-dashboard-list>
</ng-template>

<ng-template #noActivities>
	<calao-dashboard-list>
		<calao-dashboard-list-item>
			<ng-container *ngIf="observableIsLoading.value$ | async; else noActivitiesPlaceholder;"></ng-container>
		</calao-dashboard-list-item>
		<calao-dashboard-list-header>
			<ng-container *ngTemplateOutlet="header"></ng-container>
		</calao-dashboard-list-header>
	</calao-dashboard-list></ng-template>

<ng-template #header>
	<div *ngIf="canReadEvents" class="next-activities-label-wrapper">
		<ion-note>A venir</ion-note>
		<div class="day-activities-link" (click)="navigateToDayViewAsync()">
			<ion-label>Toute l'activité du jour</ion-label>
			<ion-icon name="chevron-forward"></ion-icon>
		</div>
	</div>
</ng-template>

<ng-template #noActivitiesPlaceholder>
	<div *ngIf="canReadEvents" class="no-activitites-wrapper">
		<ion-label class="no-activities-title">Aucune autre activité pour aujourd'hui.</ion-label>
		<ion-label>Organisez votre journée en créant une tâche, un évènement ou un rappel.</ion-label>
	</div>
</ng-template>