<ng-container *ngIf="observableIsInit.value$|async; else loadingSpinner;">
	<ng-container *ngIf="(observableFilteredEntries.changes$ | async) as documents ; else loadingSpinner;">
		<ng-container *ngIf="observableFormDefinition.value$ | async as observedDefinition">
			<calao-filter-bar #filterBar *ngIf="observedDefinition.search"
				[filterValues]="observableFilterValues.value$ | async" [nbTmpResults]="observableNbTmpResults.value$ | async"
				(onFilterValuesChange)="onFilterValuesChange($any($event))"
				(onTmpFilterValuesChange)="onTmpFilterValuesChange($any($event))">
				<calao-filter-bar-main-content>
					<calao-filter-bar-item key="text" *ngIf="observedDefinition?.search?.text">
						<ng-template let-observableValue="observableValue">
							<calao-filter-bar-search-input [placeholder]="observedDefinition.search.text.placeholder"
								[autofocus]="observedDefinition.search.text.autofocus" [value]="observableValue.value$ | async"
								[hasModal]="!!(observableHasModal.value$ | async)" [filterBadgeLabel]="nbFiltersLabel$ | async"
								(onValueChange)="observableValue.value = $event">
							</calao-filter-bar-search-input>
						</ng-template>
					</calao-filter-bar-item>
					<ng-container *ngIf="observableSortOptions.changes$ | async as observedSortOptions">
						<calao-filter-bar-tags *ngIf="observedSortOptions.length > 0">
							<calao-filter-bar-item key="sort">
								<ng-template let-observableValue="observableValue">
									<calao-sort-selector [options]="observedSortOptions"
										[by]="$any(observableValue.value$ | async)?.by ?? (observableSortKey.value$ | async)"
										[order]="$any(observableValue.value$ | async)?.order ?? (observableSortOrder.value$ | async)"
										(onSortChanged)="observableValue.value = $event"></calao-sort-selector>
								</ng-template>
							</calao-filter-bar-item>
						</calao-filter-bar-tags>
					</ng-container>
				</calao-filter-bar-main-content>
				<calao-filter-bar-modal-content *ngIf="observableHasModal.value$ | async as hasModal"
					[canValidate]="observableCanValidate.value$ | async">
					<calao-filter-bar-item key="text">
						<ng-template let-observableValue="observableValue">
							<calao-filter-bar-search-input placeholder="Rechercher parmi toutes les opportunités..."
								[value]="observableValue.value$ | async" [hasModal]="false"
								(onValueChange)="observableValue.value = $event">
							</calao-filter-bar-search-input>
						</ng-template>
					</calao-filter-bar-item>
					<ng-container *ngFor="let customCriterion of observedDefinition.search?.customCriteria">
						<calao-filter-bar-item *ngIf="customCriterion.type === EEntityEntriesListCustomCriteriaType.dateRange"
							key="{{ customCriterion.params.key }}" class="border">
							<ng-template let-observableValue="observableValue">
								<calao-filter-bar-item-title>{{ customCriterion.label }}
									<calao-filter-bar-item-reset>
										<ion-label class="label" (click)="observableValue.value = undefined">Effacer</ion-label>
									</calao-filter-bar-item-reset>
								</calao-filter-bar-item-title>
								<calao-date-range-picker class="margin-top-10" [max]="customCriterion.params.max"
									[model]="observableValue.value$ | async"
									(dateRangeChanged)="observableValue.value = $event; updateCanValidate($event)">
								</calao-date-range-picker>
							</ng-template>
						</calao-filter-bar-item>
						<!-- Filtre par plage de nombre -->
						<calao-filter-bar-item *ngIf="customCriterion.type === EEntityEntriesListCustomCriteriaType.numericRange"
							key="{{ customCriterion.params.key }}" class="border">
							<ng-template let-observableValue="observableValue">
								<calao-filter-bar-item-title>{{ customCriterion.label }}
									<calao-filter-bar-item-reset>
										<ion-label class="label" (click)="observableValue.value = undefined">Effacer</ion-label>
									</calao-filter-bar-item-reset>
								</calao-filter-bar-item-title>
								<calao-numeric-range-picker [display]="customCriterion.params.display"
									[minLabel]="customCriterion.params.min" [maxLabel]="customCriterion.params.max"
									[step]="customCriterion.params.step" [model]="observableValue.value$ | async"
									(onRangeChanged)="observableValue.value = $event"></calao-numeric-range-picker>
							</ng-template>
						</calao-filter-bar-item>
						<!-- Filtre par optionSubset -->
						<calao-filter-bar-item *ngIf="customCriterion.type === EEntityEntriesListCustomCriteriaType.optionSubset"
							key="{{ customCriterion.params.key }}" class="border">
							<ng-template let-observableValue="observableValue">
								<calao-filter-bar-item-title>
									{{ customCriterion.label }}
								</calao-filter-bar-item-title>
								<osapp-selector [multiple]="customCriterion.params.multiple" [displayMode]="selectorDisplayMode.list"
									[options]="customCriterion.params.options" [preselectedValues]="customCriterion.params.defaults"
									(selectionChanged)="observableValue.value = $event" [showItemBorder]="false" listDirection="row">
								</osapp-selector>
							</ng-template>
						</calao-filter-bar-item>
						<!-- Filtre par contactsSelection -->
						<calao-filter-bar-item
							*ngIf="customCriterion.type === EEntityEntriesListCustomCriteriaType.contactsSelection"
							key="{{ customCriterion.params.key }}"
							[defaultValue]="customCriterion.params.currentUserAsDefault ? [getUserContactId()] : []" class="border">
							<ng-template let-observableValue="observableValue">
								<calao-filter-bar-item-title>
									{{ customCriterion.label }}
								</calao-filter-bar-item-title>
								<contacts-list [params]="customCriterion.params.contactsSelectorParams"
									[model]="customCriterion.params.currentUserAsDefault ? [getUserContactId()] : []"
									(modelChange)="observableValue.value = $event">
								</contacts-list>
							</ng-template>
						</calao-filter-bar-item>
					</ng-container>
				</calao-filter-bar-modal-content>
			</calao-filter-bar>

			<ng-container [ngSwitch]="observedDefinition?.item?.component">
				<ng-container *ngSwitchCase="itemType.tab">
					<ng-container *ngIf="documents.length !== 0; else emptyScreen;">
						<calao-column-list *ngIf="observableGridParams.value$ | async as params" [items]="documents"
							[itemSize]="observableItemSize.value$ | async" [sortKey]="observableSortKey.value$ | async" detail>
							<ng-template let-item="$implicit" let-index="index">
								<calao-entity-entries-list-tab-item [item]="item" [itemSize]="observableItemSize.value$ | async"
									[detail]="itemHasDetail(item, observableHasDetailById.value$ | async, observableHasDetail.value$ | async)"
									[index]="index" [displayFields]="params.fields"
									(onDetailClicked)="onDetailClicked(item, $event.event)"
									(onItemClicked)="onItemClicked(item)"></calao-entity-entries-list-tab-item>
							</ng-template>
							<calao-column-list-header>
								<calao-column-list-header-column *ngFor="let col of params.fields" [key]="col.key"
									[ngClass]="col.cssClass" [size]="col.size" [label]="col.label" [hidden]="col.hidden"
									(onSortClicked)="orderOrReverse($any($event))"></calao-column-list-header-column>
							</calao-column-list-header>
						</calao-column-list>
					</ng-container>
				</ng-container>

				<ng-container *ngSwitchCase="itemType.list">
					<ng-container *ngIf="documents.length !== 0; else emptyScreen;">
						<calao-virtual-scroll *ngIf="observableListParams.value$ | async as params" [items]="documents" autosize
							transparent [itemSize]="100">
							<ng-template let-item="$implicit" let-index="index">
								<calao-entity-entries-list-list-item [index]="index" [item]="item" [params]="params"
									[detail]="itemHasDetail(item, observableHasDetailById.value$ | async, observableHasDetail.value$ | async)"
									(onDetailClicked)="onDetailClicked(item, $event.event)" (onItemClicked)="onItemClicked(item)">
								</calao-entity-entries-list-list-item>
							</ng-template>
						</calao-virtual-scroll>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>

<!-- Afficher s'il y a un chargement -->
<ng-template #loadingSpinner>
	<div class="full-width">
		<osapp-spinner>
		</osapp-spinner>
	</div>
</ng-template>

<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
<ng-template #emptyScreen>
	<div class="empty-screen flex-col">
		<span class="txt-message">
			<ion-note>{{(observableFormDefinition.value$ | async)?.emptyMessage ?? "Aucun résultat."}}</ion-note>
		</span>
	</div>
</ng-template>