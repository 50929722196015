import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigData } from "@calaosoft/osapp-common/config/models/ConfigData";
import { SqlRemoteProviderBase } from "@calaosoft/osapp-common/sqlite/classes/sql-remote-provider-base";
import { lastValueFrom } from "rxjs";
import { OsappApiHelper } from "../../../osapp-api/helpers/osapp-api.helper";

@Injectable()
export abstract class SqlRemoteProvider extends SqlRemoteProviderBase {

	//#region METHODS

	public constructor(protected readonly ioHttpClient: HttpClient) {
		super();
	}

	protected override getVersionResponseAsync<T extends { version: string; }>(psDatabaseId: string): Promise<T> {
		if (!ConfigData.authentication.token)
			console.error(`${this.C_LOG_ID}Token not defined !`);
		if (!ConfigData.environment.API_KEY)
			console.error(`${this.C_LOG_ID}ApiKey not defined !`);

		return super.getVersionResponseAsync(psDatabaseId);
	}

	protected override httpGetAsync<T extends { version: string; }>(psUrl: string): Promise<T> {
		return lastValueFrom(
			this.ioHttpClient.get(
				psUrl,
				{
					headers: new HttpHeaders({
						appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
						token: ConfigData.authentication.token ?? "",
						"api-key": ConfigData.environment.API_KEY ?? "",
						accept: "application/json"
					}),
					observe: "body",
					responseType: "json"
				}
			)
		) as Promise<T>;
	}

	//#endregion METHODS

}