import { Exclude } from "@calaosoft/osapp-common/class-transformer";
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { ContactHelper } from "@calaosoft/osapp/helpers/contactHelper";
import { IContact } from "@calaosoft/osapp/model/contacts/IContact";
import { BaseEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence";
import { IEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/ievent-occurrence";
import { Contact } from '@calaosoft/osapp/modules/contacts/models/contact';
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ETradeEventType } from "./etrade-event-type";

@ModelMatch((poOccurrence: IEventOccurrence) => poOccurrence.eventType === ETradeEventType.standard, BaseEventOccurrence)
export class EventOccurrence extends BaseEventOccurrence {

	//#region PROPERTIES

	@Exclude()
	public override readonly participantsLabel$: Observable<string> = this.getParticipantsLabel$();

	//#endregion PROPERTIES

	//#region METHODS

	protected override getParticipantsLabel$(): Observable<string> {
		return combineLatest([this.observableAuthorId.value$, this.observableParticipants.changes$]).pipe(
			map(([psAuthorId, paContacts]: [string, Contact[]]) => {
				const laContacts: IContact[] = [...paContacts];
				const lsAuthorContactId: string = IdHelper.buildId(EPrefix.contact, IdHelper.extractIdWithoutPrefix(psAuthorId, EPrefix.user));
				ArrayHelper.removeElementByFinder(laContacts, (poContact: IContact) => poContact._id === lsAuthorContactId);
				return laContacts;
			}),
			map((paContacts: IContact[]) => paContacts.map((poContact: IContact) => {
				return ContactHelper.isCurrentUser(poContact) ? EventOccurrence.C_CURRENT_USER_LABEL : ContactHelper.getCompleteFormattedName(poContact);
			}).join(", "))
		);
	}

	//#endregion METHODS

}