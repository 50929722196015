import { ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Navigation, Router, UrlSegment } from '@angular/router';
import { GuidHelper } from "@calaosoft/osapp-common/guid/helpers/guidHelper";
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { Observable, ReplaySubject, Subject, defer, fromEvent, of, timer } from 'rxjs';
import { filter, map, mergeMap, skipWhile, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { EnumHelper } from '../../helpers/enumHelper';
import { PageInfo } from '../../model/PageInfo';
import { EApplicationEventType } from '../../model/application/EApplicationEventType';
import { UserData } from '../../model/application/UserData';
import { EBarElementAction } from '../../model/barElement/EBarElementAction';
import { ELifeCycleEvent } from '../../model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '../../model/lifeCycle/ILifeCycleEvent';
import { LinkInfo } from '../../model/link/LinkInfo';
import { INavbarEvent } from '../../model/navbar/INavbarEvent';
import { IWorkspace } from '../../model/workspaces/IWorkspace';
import { RoutingHelper } from '../../modules/routing/helpers/routing.helper';
import { PageManagerService } from '../../modules/routing/services/pageManager.service';
import { Site } from '../../modules/sites/models/site';
import { ApplicationService } from '../../services/application.service';
import { ConversationService } from '../../services/conversation.service';
import { EntityLinkService } from '../../services/entityLink.service';
import { GlobalDataService } from '../../services/global-data.service';
import { WorkspaceService } from '../../services/workspace.service';
import { MenuPopoverComponent } from '../menu/menuPopover.component';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { EDynamicTitle } from './EDynamicTitle';
import { EPageStatus } from './EPageStatus';

/** Composant qui permet de créer une page basique à partir d'un composant. La page créée possède une NavBar dont les boutons sont donnés ici. */
@Component({
	selector: 'calao-dynamicPage',
	templateUrl: 'dynamicPage.component.html',
	styleUrls: ['./dynamicPage.component.scss']
})
export class DynamicPageComponent<T extends ComponentBase> extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	private static readonly C_MODEL_ATTRIBUTE = "model";
	private static readonly C_PARENT_ENTITY_ATTRIBUTE = "parentEntity";
	private static readonly C_IS_GUEST_ATTRIBUTE = "isGuest";

	private readonly moLifeCycleEventsSubject = new Subject<ILifeCycleEvent>();
	/** Sujet indiquant qu'une navigation a lieu. */
	private readonly moNavigatingSubject = new Subject<string>();
	/** Sujet indiquant si l'on peut naviguer. */
	private readonly moCanNavigateSubject = new Subject<boolean>();
	/** Sujet pour accès à la toolbar. */
	private readonly moToolbarSubject = new ReplaySubject<ToolbarComponent>(1);

	/** Composant inclus dans la page.*/
	private moSettingPopover: HTMLIonPopoverElement;
	private mePageStatus: EPageStatus = EPageStatus.none;

	//#endregion

	//#region PROPERTIES

	private moChildComponent: T;
	/** Le composant créé par le DynamicPage. */
	public get childComponent(): T { return this.moChildComponent; }

	/** Identifiant du composant pour la gestion des événements ionic. */
	public readonly id: string;
	/** Titre de la page. */
	private msTitle: string;
	public get title(): string {
		return this.msTitle;
	}
	public set title(psTitle: string) {
		console.debug(`DP.C::Changement du titre du header de la page: ${this.msTitle} => ${psTitle}.`);
		this.msTitle = psTitle;
		this.detectChanges();
	}

	/** Présence du header style tabBar. */
	public tabBarStyle = true;
	/** Présence du bouton sideMenu ou non. */
	public hasMenuButton = true;
	/** Présence du bouton home ou non. */
	public hasHomeButton = true;
	/** Présence du bouton settings ou non. */
	public hasSettingButton = true;
	/** Présence du bouton back */
	public hasBackButton = true;
	/** Présence du bouton d'état de la synchronisation */
	public hasSyncButton = false;
	/** Le titre est dans la toolbar */
	public hasToolbarTitle = true;
	/** Présence du bouton conversations. */
	public hasConversationsButton = false;
	/** Taille à partir de laquelle le bouton menu va disparaître. */
	public menuButtonDisappearAtWidthPx: number;
	/** Largeur à laquelle le bouton conversations disparaît, en px. */
	public conversationsButtonDisappearAtWidthPx?: number;
	/** Id CSS du ion-content */
	public cssId: string;
	/** La page est une modale */
	public isModal = true;
	public pageInfo: PageInfo;
	public customButtons: Array<LinkInfo> = [];
	public defaultHref?: string;
	/** `true` si la bouton des notifications doit être affiché, sinon `false`. Est à `false` par défaut. */
	public readonly observableHasNotificationsButton = new ObservableProperty<boolean>(false);

	private moHasMenuButton$: Observable<boolean>;
	public get hasMenuButton$(): Observable<boolean> {
		return this.moHasMenuButton$;
	};

	private moHasConversationsButton$: Observable<boolean>;
	public get hasConversationsButton$(): Observable<boolean> {
		return this.moHasConversationsButton$;
	};

	public get viewBackFrom$(): Observable<ILifeCycleEvent> { return this.getLifeCycleEventObservable(ELifeCycleEvent.viewBackFrom); }
	public get viewWillEnter$(): Observable<ILifeCycleEvent> { return this.getLifeCycleEventObservable(ELifeCycleEvent.viewWillEnter); }
	public get viewDidEnter$(): Observable<ILifeCycleEvent> { return this.getLifeCycleEventObservable(ELifeCycleEvent.viewDidEnter); }
	public get viewWillLeave$(): Observable<ILifeCycleEvent> { return this.getLifeCycleEventObservable(ELifeCycleEvent.viewWillLeave); }
	public get viewDidLeave$(): Observable<ILifeCycleEvent> { return this.getLifeCycleEventObservable(ELifeCycleEvent.viewDidLeave); }
	public get viewCanEnter$(): Observable<ILifeCycleEvent> { return this.getLifeCycleEventObservable(ELifeCycleEvent.viewCanEnter); }
	public get viewCanLeave$(): Observable<ILifeCycleEvent> { return this.getLifeCycleEventObservable(ELifeCycleEvent.viewCanLeave); }
	public get viewDestroy$(): Observable<ILifeCycleEvent> { return this.getLifeCycleEventObservable(ELifeCycleEvent.viewDestroy); }
	public get viewInit$(): Observable<ILifeCycleEvent> { return this.getLifeCycleEventObservable(ELifeCycleEvent.viewInit); }
	public get viewBackTo$(): Observable<ILifeCycleEvent> { return this.getLifeCycleEventObservable(ELifeCycleEvent.viewBackTo); }

	/** Permet de récupérer le container où doit s'insérer le composant. */
	@ViewChild("target", { read: ViewContainerRef, static: true }) public target: ViewContainerRef;

	private moToolbar: ToolbarComponent;
	public get toolbar(): ToolbarComponent { return this.moToolbar; }
	@ViewChild("toolbar")
	public set toolbar(poToolbar: ToolbarComponent) {
		if (poToolbar && poToolbar !== this.moToolbar)
			this.moToolbarSubject.next(this.moToolbar = poToolbar);
	}
	public get toolbar$(): Observable<ToolbarComponent> { return this.moToolbarSubject.asObservable(); }

	/** Bouton html `back` de la navbar. */
	@ViewChild("backButton", { read: ElementRef }) public backButtonElement: ElementRef<HTMLIonBackButtonElement>;

	public get canGoBack(): boolean {
		return !RoutingHelper.routeEqual(this.ioRouter.url, this.defaultHref ?? ApplicationService.C_HOME_ROUTE_URL);
	}

	public readonly nbOfUnreadConv$: Observable<number> = this.isvcGlobalData.getData(ConversationService.C_NB_OF_UNREAD_CONV_DATA_KEY);

	//#endregion

	//#region METHODS

	constructor(
		private ioComponentFactoryResolver: ComponentFactoryResolver,
		public isvcPageManager: PageManagerService,
		public ioPopoverCtrl: PopoverController,
		public ioModalCtrl: ModalController,
		private ioPlatform: Platform,
		private isvcEntityLink: EntityLinkService,
		private ioRoute: ActivatedRoute,
		private ioRouter: Router,
		private isvcWorkspace: WorkspaceService,
		private readonly isvcGlobalData: GlobalDataService,
		poChangeDetectorRef: ChangeDetectorRef) {

		super(poChangeDetectorRef);

		this.id = `${this.constructor["name"]}-${GuidHelper.newGuid()}`;
		this.isvcPageManager.subscribePageLifeCycleEvents(this.getLifeCycleObservable().pipe(takeUntil(this.destroyed$)));
	}

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		this.mePageStatus = EPageStatus.initializing;

		// Ajout de la page à la stack page des événements ionic.
		this.raiseLifeCycleEvent(ELifeCycleEvent.viewInit);

		//todo Dans un premier temps, on ne peut que ajouter des boutons grâce aux events.
		this.isvcPageManager.getNavbarEventAsObservable()
			.pipe(
				tap((poEvent: INavbarEvent) => this.onNavbarEvent(poEvent)),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.ioPlatform.backButton
			.pipe(
				filter(() => this.isActive()),
				// On lève l'événement 'viewBackFrom' si le bouton 'back' est cliqué depuis la page active.
				tap(_ => this.raiseLifeCycleEvent(ELifeCycleEvent.viewBackFrom)),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.init();
		this.createChildComponent();
	}

	private getLifeCycleEventObservable(peLifeCycleEvent: ELifeCycleEvent): Observable<ILifeCycleEvent> {
		return this.moLifeCycleEventsSubject.asObservable().pipe(filter((poLifeCycleEvent: ILifeCycleEvent) => poLifeCycleEvent.data.value === peLifeCycleEvent));
	}

	/** Endroit où se désabonner et se détacher des événements pour éviter des fuites mémoires, justa avant la destruction du composant. */
	public override ngOnDestroy(): void {
		super.ngOnDestroy();

		// Suppression de la page de la stack des événements ionic.
		this.raiseLifeCycleEvent(ELifeCycleEvent.viewDestroy);

		if (this.moSettingPopover)
			this.moSettingPopover.dismiss();

		this.moLifeCycleEventsSubject.complete();
		this.moNavigatingSubject.complete();
		this.moCanNavigateSubject.complete();
		this.moToolbarSubject.complete();
	}

	/** It’s fired when entering a page, before it becomes the active one.
	 * Use it for tasks you want to do every time you enter in the view (setting event listeners, updating a table, etc.).
	 * @see https://blog.ionicframework.com/navigating-lifecycle-events/
	 */
	public ionViewWillEnter(): void {

		this.raiseLifeCycleEvent(ELifeCycleEvent.viewWillEnter);
	}

	/** Fired when entering a page, after it becomes the active page. Quite similar to the previous one.
	 * @see https://blog.ionicframework.com/navigating-lifecycle-events/
	 */
	public ionViewDidEnter(): void {

		if (this.isInactive())
			this.raiseLifeCycleEvent(ELifeCycleEvent.viewBackTo);

		this.raiseLifeCycleEvent(ELifeCycleEvent.viewDidEnter);
	}

	/** Fired when you leave a page, before it stops being the active one.
	 * Use it for things you need to run every time you are leaving a page (deactivate event listeners, etc.).
	 * @see https://blog.ionicframework.com/navigating-lifecycle-events/
	 */
	public ionViewWillLeave(): void {
		this.raiseLifeCycleEvent(ELifeCycleEvent.viewWillLeave);
	}

	/** Fired when you leave a page, after it stops being the active one. Similar to the previous one.
	 * @see https://blog.ionicframework.com/navigating-lifecycle-events/
	 */
	public ionViewDidLeave(): void {
		this.raiseLifeCycleEvent(ELifeCycleEvent.viewDidLeave);
	}

	/** Envoie un événement de type lifeCycleEvent à tous les abonnés.
	 * @param peEvent Identifiant de l'événement.
	 */
	public raiseLifeCycleEvent(peEvent: ELifeCycleEvent): void {
		console.debug(`DP.C::${this.id}/${this.msInstanceId}\t${peEvent}\tRaising ${peEvent} event from ${this.msInstanceId}.`);

		this.updateActiveStatus(peEvent);

		const loEvent: ILifeCycleEvent = {
			type: EApplicationEventType.LifeCycleEvent,
			createDate: new Date(),
			data: {
				pageId: this.id,
				value: peEvent,
			}
		};

		this.moLifeCycleEventsSubject.next(loEvent);
	}

	// Navigation se fait automatiquement, on doit gérer le cycle de vie.
	protected onBackButtonClicked(): void {
		if (!this.isModal)
			this.raiseLifeCycleEvent(ELifeCycleEvent.viewBackFrom);
	}

	private updateActiveStatus(peEvent: ELifeCycleEvent): void {
		switch (peEvent) {

			case ELifeCycleEvent.viewDidEnter:
				this.mePageStatus = EPageStatus.active;
				break;

			case ELifeCycleEvent.viewDidLeave:
				this.mePageStatus = EPageStatus.inactive;
				break;
		}
	}

	/** Ferme la modale (si la page en est une). */
	public closeModal(): void {
		this.ioModalCtrl.dismiss();
	}

	/** Remplissage du container avec le composant. */
	private createChildComponent(): void {
		this.target.clear();

		const loComponentFactory: ComponentFactory<T> = this.ioComponentFactoryResolver.resolveComponentFactory(this.isvcPageManager.getRouteComponentFromId(this.pageInfo.componentName).componentType);

		this.moChildComponent = this.target.createComponent(loComponentFactory).instance;

		if (this.pageInfo.templateId) // Passage du templateId en attribut au composant.
			(this.moChildComponent as any).templateId = this.pageInfo.templateId;

		for (const lsKey in this.pageInfo.params) { // Passage des paramètres au composant en attribut.
			this.moChildComponent[lsKey] = this.pageInfo.params[lsKey];
		}

		this.ioRoute.snapshot.queryParamMap.keys.forEach((lsKey: string) => { // Passage des queryParams de la route au composant en attribut.
			this.moChildComponent[lsKey] = this.ioRoute.snapshot.queryParamMap.get(lsKey);
		});

		// Si un modèle a été chargé par la route, il est enregistré sur le composant.
		if (this.ioRoute.snapshot.data[DynamicPageComponent.C_MODEL_ATTRIBUTE]) {
			this.moChildComponent[DynamicPageComponent.C_MODEL_ATTRIBUTE] = this.ioRoute.snapshot.data[DynamicPageComponent.C_MODEL_ATTRIBUTE];
		}

		const loCurrentNav: Navigation | null = this.ioRouter.getCurrentNavigation();
		if (loCurrentNav && loCurrentNav.extras && loCurrentNav.extras.state && loCurrentNav.extras.state.parentModel) {
			// Si une entité parente a été chargée par la route, il est enregistré sur le composant.
			this.moChildComponent[DynamicPageComponent.C_PARENT_ENTITY_ATTRIBUTE] = loCurrentNav.extras.state.parentModel;
		}
	}

	/** Route vers la page d'accueil. */
	public goHome(): void {
		this.isvcPageManager.goHome();
	}

	/** Permet de faire un retour arrière comme avec le bouton `back` de la navbar. */
	public goBack(): void {
		if (this.backButtonElement)
			this.backButtonElement.nativeElement.click();
		else
			console.warn("DP.C:: Bouton back navbar non défini.");
	}

	/** Initialisation du composant. */
	private init(): void {
		const lsIsGuest: string | null = this.ioRoute.snapshot.queryParamMap.get(DynamicPageComponent.C_IS_GUEST_ATTRIBUTE);
		const lbIsGuest: boolean = !StringHelper.isBlank(lsIsGuest) && lsIsGuest.toLowerCase() === String(true);
		// const lsIsGuest: string = this.ioRoute.snapshot.queryParamMap.get('isGuest');

		if (!this.pageInfo) {
			this.pageInfo = this.ioRoute.snapshot.data.pageInfo;

			const laUrlSegments: UrlSegment[] = this.ioRoute.snapshot.url;

			if (!this.pageInfo) {
				this.pageInfo = new PageInfo({});
				console.error("DP.C:: moPageInfo is not set in Route data.");
			}

			// Récupère le nom du composant dans les paramètres de la route.
			// Pour des raisons de rétro-compatibilité, récupère le nom du composant dans les paramètres de l'URL, si pas de componentName dans le PageInfo.
			this.pageInfo.componentName = !StringHelper.isBlank(this.pageInfo.componentName) ? this.pageInfo.componentName : laUrlSegments[1].path;
		}

		this.initTile();
		this.tabBarStyle = this.pageInfo.tabBarStyle && !lbIsGuest;
		this.hasMenuButton = this.pageInfo.hasMenuButton && !lbIsGuest;
		this.hasHomeButton = this.pageInfo.hasHomeButton && !lbIsGuest && this.ioRouter.url.indexOf(ApplicationService.C_HOME_ROUTE_URL) === -1;
		this.hasSettingButton = this.pageInfo.hasSettingButton && !lbIsGuest;
		this.hasBackButton = this.pageInfo.hasBackButton && !lbIsGuest;
		this.hasSyncButton = this.pageInfo.hasSyncButton;
		this.cssId = this.pageInfo.cssId;
		this.isModal = this.pageInfo.isModal;
		this.defaultHref = this.pageInfo.defaultHref;
		this.hasConversationsButton = this.pageInfo.hasConversationsButton;
		this.observableHasNotificationsButton.value = this.pageInfo.hasNotificationsButton;
		this.menuButtonDisappearAtWidthPx = this.pageInfo.menuButtonDisappearAtWidthPx;
		this.conversationsButtonDisappearAtWidthPx = this.pageInfo.conversationsButtonDisappearAtWidthPx;

		this.moHasConversationsButton$ = fromEvent(window, "resize").pipe(startWith({}), map(() => window.innerWidth)).pipe(
			filter(() => NumberHelper.isValid(this.conversationsButtonDisappearAtWidthPx)),
			map((pnWindowWith: number) => pnWindowWith < this.conversationsButtonDisappearAtWidthPx),
			startWith(true)
		).pipe(map((pbCanDisplayMenuButton: boolean) => this.hasConversationsButton && pbCanDisplayMenuButton));

		this.moHasMenuButton$ = fromEvent(window, "resize").pipe(startWith({}), map(() => window.innerWidth)).pipe(
			filter(() => NumberHelper.isValid(this.menuButtonDisappearAtWidthPx)),
			map((pnWindowWith: number) => pnWindowWith < this.menuButtonDisappearAtWidthPx),
			startWith(true)
		).pipe(map((pbCanDisplayMenuButton: boolean) => this.hasMenuButton && pbCanDisplayMenuButton));
	}

	/** Initialise le titre de la page */
	private initTile(): void {
		this.title = "";

		if (EnumHelper.getValues(EDynamicTitle).includes(this.pageInfo.title)) {
			const leDynamicTitle: EDynamicTitle = EDynamicTitle[EnumHelper.getKey(EDynamicTitle, this.pageInfo.title)];

			switch (leDynamicTitle) {
				case EDynamicTitle.workspace:
					this.setWorkspaceTitle();
					break;
				case EDynamicTitle.site:
					this.setSiteTitle();
					break;
			}
		}
		else
			this.title = this.pageInfo.title;
	}

	/** Attribue le nom du workspace au titre le la page. */
	private setWorkspaceTitle(): void {
		this.isvcWorkspace.getWorkspaceDocument(ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds()))
			.pipe(
				tap((poWorkspace: IWorkspace) => this.title = poWorkspace.name),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	/** Attribue le nom du site au titre le la page. */
	private setSiteTitle(): void {
		let lsSiteId: string;

		this.isvcWorkspace.getWorkspaceDocument(ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds()))
			.pipe(
				tap((poWorkspace: IWorkspace) => lsSiteId = UserData.currentSite?._id ?? poWorkspace.defaultSiteId),
				mergeMap(() => !StringHelper.isBlank(lsSiteId) ? this.isvcWorkspace.getSite(lsSiteId) : of(undefined)),
				tap((poSite?: Site) => {
					this.title = poSite?.name;
					this.hasToolbarTitle = !poSite;
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	/** Ajout d'un bouton dans la navbar du DynamicPage.
	 * @param poNavbarEvent Événement de la navbar qu'il faut traiter.
	 */
	private onNavbarEvent(poNavbarEvent: INavbarEvent): void {
		if (poNavbarEvent.action === EBarElementAction.add && poNavbarEvent.links) {
			let lbShouldDisplayButton = true;

			// On vérifie si on doit ajouter les boutons que lors de l'affichage de pages spéciales.
			if (ArrayHelper.hasElements(poNavbarEvent.pages)) {
				// On la met à false vu qu'il y a le param pages qui indique qu'on veut afficher les boutons que sur certaines pages.
				lbShouldDisplayButton = false;

				for (let lnIndex = poNavbarEvent.pages.length - 1; lnIndex >= 0; --lnIndex) {
					if (poNavbarEvent.pages[lnIndex] === this.pageInfo.componentName) {
						lbShouldDisplayButton = true;
						break;
					}
				}
			}

			if (lbShouldDisplayButton) {
				for (let lnIndex = 0, lnLength = poNavbarEvent.links.length; lnIndex < lnLength; ++lnIndex) {
					ArrayHelper.removeElementById(this.customButtons, poNavbarEvent.links[lnIndex].id);
					this.customButtons.push(poNavbarEvent.links[lnIndex]);
				}

				this.detectChanges();
			}
		}

		if (poNavbarEvent.action === EBarElementAction.clear && poNavbarEvent.id) {
			ArrayHelper.removeElementById(this.customButtons, poNavbarEvent.id);
			this.detectChanges();
		}
	}

	/** Ouvre la popover de settings.
	 * @param poEvent Indique où l'événement s'est produit.
	 */
	public async openSettings(poEvent: any): Promise<void> {
		this.moSettingPopover = await this.ioPopoverCtrl.create({
			component: MenuPopoverComponent,
			event: poEvent
		});

		return await this.moSettingPopover.present();
	}

	public getLifeCycleObservable(): Observable<ILifeCycleEvent> {
		return this.moLifeCycleEventsSubject.asObservable();
	}

	/** La page est considérée comme active entre les événements viewDidEnter et viewDidLeave. */
	public isActive(): boolean {
		return this.mePageStatus === EPageStatus.active;
	}

	public isInactive(): boolean {
		return this.mePageStatus === EPageStatus.inactive;
	}

	public isInitializing(): boolean {
		return this.mePageStatus === EPageStatus.initializing;
	}

	/** Permet d'indiquer si l'on peut naviguer ou non.
	 * @param psNextUrl Url de la page voulu.
	 */
	public canNavigate(psNextUrl?: string): Observable<boolean> {
		const lnSubscribers: number = this.moNavigatingSubject.observers.length;

		if (lnSubscribers > 0) {
			return defer(() => of(this.moNavigatingSubject.next(psNextUrl)))
				.pipe(
					mergeMap(_ => this.moCanNavigateSubject.asObservable()),
					skipWhile((pbCanNavigate: boolean, pnIndex: number) => !(pnIndex >= (lnSubscribers - 1) || !pbCanNavigate)),
					take(1)
				);
		}
		else
			return of(true);
	}

	public getNavigatingObservable(): Observable<string> {
		return this.moNavigatingSubject.asObservable();
	}

	/** Permet de lever un évenement indiquant si l'on peut naviguer ou non.
	 * @param pbValidate Indique si l'on peut naviguer ou non.
	 */
	public allowNavigation(pbValidate: boolean): void {
		timer(0).pipe(tap(_ => this.moCanNavigateSubject.next(pbValidate)))
			.subscribe();
	}

	public routeToConversations(): void {
		this.ioRouter.navigate(["conversations"]);
	}

	public routeToNotifications(): void {
		this.ioRouter.navigate(["notifications"]);
	}

	//#endregion
}
