import { ObjectHelper } from "../helpers/objectHelper";

export class NotImplementedYetError extends Error {

	constructor(psThingNotImplementedYet: string) {
		super(`'${psThingNotImplementedYet}' not implemented yet !`);
		// Nécessaire pour que le 'instanceof NotImplementedYetError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, NotImplementedYetError);
	}

}