import { GuidHelper } from "../guid/helpers/guidHelper";
import { ArrayHelper } from "../utils/helpers/arrayHelper";


export class PerformanceManager {
	//#region FIELDS

	private readonly msStartMark: string = GuidHelper.newGuid();
	private readonly msEndMark: string = GuidHelper.newGuid();
	private readonly msMeasure: string = GuidHelper.newGuid();

	private moStartMark?: PerformanceMark;
	private moEndMark?: PerformanceMark;

	//#endregion

	//#region PROPERTIES

	public get startTime(): number {
		return this.moStartMark?.startTime ?? 0;
	}

	public get endTime(): number {
		return this.moEndMark?.startTime ?? 0;
	}

	//#endregion

	//#region METHODS

	/** Marque le début du timer de performance. */
	public markStart(): PerformanceManager {
		if (performance.mark)
			performance.mark(this.msStartMark);
		return this;
	}

	/** Marque la fin du timer de performance. */
	public markEnd(): PerformanceManager {
		if (performance.mark)
			performance.mark(this.msEndMark);
		return this;
	}

	/** Permet de mesurer le temps écoulé entre `markStart()` et `markEnd()`.
	 * @returns Durée en ms.
	 */
	public measure(): number {
		let lnMeasure = 0;
		try {
			if (performance.measure)
				performance.measure(this.msMeasure, this.msStartMark, this.msEndMark);

			if (performance.getEntriesByName)
				lnMeasure = ArrayHelper.getFirstElement(performance.getEntriesByName(this.msMeasure))?.duration;

			this.clearMesures();
		}
		catch (poError) {
			console.warn(`PERF:: Error during measure. Please use markStart(), markEnd() and measure() in this order.`, poError);
		}
		finally {
			return lnMeasure;
		}
	}

	/** Nettoie les marques de ce manager de performances. */
	public clear(): void {
		this.clearMesures();
		this.clearStart();
		this.clearEnd();
	}

	public clearEnd(): PerformanceManager {
		if (performance.clearMarks)
			performance.clearMarks(this.msEndMark);

		return this;
	}

	public clearStart(): PerformanceManager {
		if (performance.clearMarks)
			performance.clearMarks(this.msStartMark);

		this.moStartMark = undefined;

		return this;
	}

	private clearMesures(): PerformanceManager {
		if (performance.clearMeasures)
			performance.clearMeasures(this.msMeasure);

		this.moEndMark = undefined;

		return this;
	}

	//#endregion
}