import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { Observable } from 'rxjs';
import { BaseEventOccurrence } from '../../../../../calendar-events/models/base-event-occurrence';
import { CalendarEventsService } from '../../../../../calendar-events/services/calendar-events.service';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';

@Component({
	selector: 'calao-event-icon-field',
	templateUrl: './event-icon-field.component.html',
	styleUrls: ['./event-icon-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventIconFieldComponent extends FieldBase<string, BaseEventOccurrence> implements OnInit {

	//#region PROPERTIES

	public readonly fieldValue$: Observable<string> = this.observableFieldValue.value$.pipe(
		secure(this)
	);

	//#endregion PROPERTIES

	//#region PROPERTIES

	constructor(
		private readonly isvcCalendarEvents: CalendarEventsService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observableFieldValue.value = this.isvcCalendarEvents.getIconNameFromSubType(this.model.eventSubtype);
	}

	//#endregion PROPERTIES

}
