import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigData } from "@calaosoft/osapp-common/config/models/ConfigData";
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { AuthenticatedRequestOptionBuilder } from "@calaosoft/osapp/modules/api/models/authenticated-request-option-builder";
import { ILogSource } from "@calaosoft/osapp/modules/logger/models/ILogSource";
import { LoggerService } from "@calaosoft/osapp/modules/logger/services/logger.service";
import { Store } from "@calaosoft/osapp/services/store.service";
import { WorkspaceService } from "@calaosoft/osapp/services/workspace.service";
import { Observable, catchError, mergeMap, of } from "rxjs";
import { ISectorOptimizationInputDTO } from "../models/isector-optimization-input-dto";

@Injectable()
export class SectorOptimizeService implements ILogSource {


	public readonly logSourceId = "TRD.OPTI.SECT.S::";

	public constructor(
		private ioHttpClient: HttpClient,
		public readonly isvcLogger: LoggerService,
		private isvcStore: Store,
		private isvcWorkspace: WorkspaceService,
	) { }

	public optmizeSectors(poOptimizationInput: Observable<ISectorOptimizationInputDTO>, psApp: string): Observable<any> {
		return poOptimizationInput.pipe(
			mergeMap((poOptimizationInput: ISectorOptimizationInputDTO) => this.requestSectorsOptimizationAPI(poOptimizationInput, psApp))
		)

	}

	public requestSectorsOptimizationAPI(
		poData: ISectorOptimizationInputDTO,
		psApp: string
	): Observable<any> {
		const lsWorkspace: string | null = this.isvcWorkspace.getWorkspaceIdFromDatabaseId(
			ArrayHelper.getFirstElement(
				this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)
			)!
		);

		const lsUrl = `${ConfigData.environment.cloud_url}/api/apps/${psApp}/workspaces/${lsWorkspace}/entities/sectors/optimize`;

		return this.ioHttpClient
			.post(
				lsUrl,
				poData,
				AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
			)
			.pipe(
				catchError((poError: HttpErrorResponse): Observable<any> => {
					if (poError.status === 0) {
						return of({
							isError: true, // Utilisation d'un boolean pour indiquer qu'il y a une erreur
							message: 'Erreur réseau. Veuillez vérifier votre connexion.',
						});
					} else if (poError.status >= 500) {
						return of({
							isError: true,
							message: 'Erreur serveur. Veuillez réessayer plus tard.',
						});
					} else if (poError.status >= 400) {
						return of({
							isError: true,
							message: 'Erreur dans la requête. Veuillez vérifier les données envoyées.',
						});
					} else {
						return of({
							isError: true,
							message: `Erreur inattendue : ${poError.message}`,
						});
					}
				}))
	}



}