import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { IBlankInformationParams } from '../models/iblank-information-params';

@Component({
	selector: 'calao-blank-information',
	templateUrl: './blank-information.component.html',
	styleUrls: ['./blank-information.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlankInformationComponent implements IBlankInformationParams {

	//#region PROPERTIES

	private moIconProp: ObservableProperty<string>;
	/** Propriété observable indiquant l'icône à afficher, optionnelle. */
	public get iconProp(): ObservableProperty<string> { return this.moIconProp; }
	/** @implements */
	@Input() public set icon(poValue: string | ObservableProperty<string>) {
		if (poValue instanceof ObservableProperty)
			this.moIconProp = poValue;
		else {
			if (this.moIconProp)
				this.moIconProp.value = poValue;
			else
				this.moIconProp = new ObservableProperty(poValue);
		}
	}

	private moTitleProp?: ObservableProperty<string>;
	/** Propriété observable indiquant le titre à afficher, optionnelle. */
	public get titleProp(): ObservableProperty<string> | undefined { return this.moTitleProp; }
	/** @implements */
	@Input() public set title(poValue: string | ObservableProperty<string>) {
		if (poValue instanceof ObservableProperty)
			this.moTitleProp = poValue;
		else {
			if (this.moTitleProp)
				this.moTitleProp.value = poValue;
			else
				this.moTitleProp = new ObservableProperty(poValue);
		}
	}

	private moSubtitleProp: ObservableProperty<string>;
	/** Propriété observable indiquant le sous-titre à afficher, optionnelle. */
	public get subtitleProp(): ObservableProperty<string> { return this.moSubtitleProp; }
	/** @implements */
	@Input() public set subtitle(poValue: string | ObservableProperty<string>) {
		if (poValue instanceof ObservableProperty)
			this.moSubtitleProp = poValue;
		else {
			if (this.moSubtitleProp)
				this.moSubtitleProp.value = poValue;
			else
				this.moSubtitleProp = new ObservableProperty(poValue);
		}
	}

	//#endregion

	//#region METHODS

	constructor() { }

	//#endregion METHODS

}