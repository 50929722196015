import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpportunitiesService } from './services/opportunities.service';

@NgModule({
	imports: [
		CommonModule
	],
	providers: [OpportunitiesService]
})
export class OpportunitiesModule { }
