import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ESortOrder } from '@calaosoft/osapp-common/utils/models/ESortOrder';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DestroyableComponentBase } from '../../../../utils/components/destroyable-component-base';
import { IColumnListSortEvent } from '../../../models/icolumn-list-sort-event';
import { ColumnListHeaderComponent } from '../column-list-header/column-list-header.component';
import { ColumnListComponent } from '../column-list.component';

@Component({
	selector: 'calao-column-list-header-column',
	templateUrl: './column-list-header-column.component.html',
	styleUrls: ['./column-list-header-column.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnListHeaderColumnComponent<T> extends DestroyableComponentBase {

	//#region FIELDS

	/** Événement lors du tri. */
	@Output("onSortClicked") private readonly moSortClickedEvent = new EventEmitter<IColumnListSortEvent<T>>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Taille de la colonne. */
	@Input() public size?: number;
	@ObserveProperty<ColumnListHeaderColumnComponent<T>>({ sourcePropertyKey: "size" })
	public readonly observableSize = new ObservableProperty<number>();

	/** Classe css. */
	@Input() public cssClass?: string;
	@ObserveProperty<ColumnListHeaderColumnComponent<T>>({ sourcePropertyKey: "cssClass" })
	public readonly observableCssClass = new ObservableProperty<string>();

	/** Libellé de la colonne. */
	@Input() public label?: string;
	@ObserveProperty<ColumnListHeaderColumnComponent<T>>({ sourcePropertyKey: "label" })
	public readonly observableLabel = new ObservableProperty<string>();

	/** Clé de la colonne. */
	@Input() public key?: keyof T;
	@ObserveProperty<ColumnListHeaderColumnComponent<T>>({ sourcePropertyKey: "key" })
	public readonly observableKey = new ObservableProperty<keyof T>();

	/** Masque le composant. */
	@Input() public hidden?: boolean;
	@ObserveProperty<ColumnListHeaderColumnComponent<T>>({ sourcePropertyKey: "hidden" })
	public readonly observableHidden = new ObservableProperty<boolean>();

	public readonly observableIsSelected = new ObservableProperty<boolean>();
	public readonly observableSortOrder = new ObservableProperty<ESortOrder>(ESortOrder.descending);

	public readonly sortOrders = ESortOrder;

	public readonly size$: Observable<number> = this.getCalculatedSize$().pipe(secure(this));

	//#endregion PROPERTIES

	//#region METHODS

	constructor(private readonly ioParent: ColumnListComponent<T>, private readonly ioHeader: ColumnListHeaderComponent<T>) {
		super();
		this.observableIsSelected.bind(combineLatest([
			this.observableKey.value$,
			this.ioParent.observableSortKey.value$,
		]).pipe(
			map(([psKey, psSortKey]: [keyof T, keyof T]) => psKey === psSortKey),
			secure(this)
		), this);
	}

	private getCalculatedSize$(): Observable<number> {
		return combineLatest([
			this.ioHeader.observableColumnsNumber.value$,
			this.observableSize.value$,
			this.ioHeader.detail$
		]).pipe(
			map(([pnColumns, pnSize, pbDetail]: [number, number, boolean]) => {
				if (!pbDetail || pnColumns === 0)
					return pnSize;

				return pnSize - (this.ioParent.detailColumnSize / pnColumns);
			})
		);
	}

	public onOrderClicked(): void {
		this.ioParent.observableSortKey.value = this.key;
		this.moSortClickedEvent.emit({
			key: this.key,
			order: this.observableSortOrder.value = this.getSortOrder()
		});
	}

	private getSortOrder(): ESortOrder {
		return !this.observableIsSelected.value || this.observableSortOrder.value === ESortOrder.ascending ?
			ESortOrder.descending :
			ESortOrder.ascending;
	}

	//#endregion METHODS

}
