import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObserveArray } from '@calaosoft/osapp-common/observable/decorators/observe-array.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { tap } from 'rxjs/operators';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { IRole } from '../../models/irole';
import { PermissionsService } from '../../services/permissions.service';

@Component({
	selector: 'calao-roles-selector',
	templateUrl: './roles-selector.component.html',
	styleUrls: ['./roles-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesSelectorComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	@Output("onRolesSelectionChanged") private readonly moOnSelectionChanged = new EventEmitter<IRole[]>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Tableau des roles. */
	public readonly observableRoles = new ObservableArray<ISelectOption<IRole>>();
	/** Mode de sélection. */
	public readonly selectorDisplayMode = ESelectorDisplayMode;

	/** Tableau des rôles selectionnés. */
	@Input() public selectedRoles?: IRole[];
	@ObserveArray<RolesSelectorComponent>("selectedRoles")
	public readonly observableSelectedRoles = new ObservableArray<IRole>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(private readonly isvcPermissions: PermissionsService) {
		super();
	}

	public ngOnInit() {
		this.isvcPermissions.roles$.pipe(
			tap((paRoles: IRole[]) => {
				const laOptions: ISelectOption<IRole>[] = [];
				paRoles.forEach((poRole: IRole) => {
					laOptions.push({ label: poRole.label, value: poRole } as ISelectOption);
				});
				this.observableRoles.resetArray(laOptions);
			}),
			secure(this)
		).subscribe();
	}

	public onSelectionChanged(paSelection: IRole[]) {
		this.moOnSelectionChanged.emit(paSelection);
	}

	//#endregion METHODS

}