import { Entity } from "@calaosoft/osapp-common/entities/models/entity";
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { C_PREFIX_SECTORIZATION } from "../../../app/app.constants";
import { Sector } from "../sectors/models/sector";
import { ISectorization } from "./isectorization";

@ModelMatch((poSctrz?: ISectorization) =>
	IdHelper.hasPrefixId(poSctrz?._id ?? "", C_PREFIX_SECTORIZATION), Entity)
export class Sectorization extends Entity implements ISectorization {

	/** @implements */
	public title = "";

	/** Liste des secteurs liés à la sectorisation. */
	public linkedSectors: Sector[] | undefined;

	/** @implements */
	public startDate?: Date;

	/** @implements */
	public endDate?: Date;
}