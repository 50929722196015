import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EEntityLinkType } from '@calaosoft/osapp-common/entities/models/eentity-link-type';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { combineLatest, switchMap, tap } from 'rxjs';
import { UserHelper } from '../../../../helpers/user.helper';
import { EntityLinkService } from '../../../../services/entityLink.service';
import { IEntityDescriptor } from '../../../entities/models/ientity-descriptor';
import { EntitiesService } from '../../../entities/services/entities.service';
import { DashboardTileComponent } from '../dashboard-tile/dashboard-tile.component';

@Component({
	selector: 'calao-dashboard-entity-tile',
	templateUrl: '../dashboard-tile/dashboard-tile.component.html',
	styleUrls: ['../dashboard-tile/dashboard-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardEntityTileComponent extends DashboardTileComponent implements OnInit {

	//#region PROPERTIES

	/** Identificateur du descripteur d'entité. */
	@Input() public entityDescId?: string;
	@ObserveProperty<DashboardEntityTileComponent>({ sourcePropertyKey: "entityDescId" })
	public readonly observableEntityDescId = new ObservableProperty<string>();

	/** Indique si on doit se baser sur les liens. */
	@Input() public useLinks?: boolean;
	@ObserveProperty<DashboardEntityTileComponent>({ sourcePropertyKey: "useLinks" })
	public readonly observableUseLinks = new ObservableProperty<boolean>();

	/** Identificateur du descripteur d'entité. */
	@Input() public entityLinkType?: string;
	@ObserveProperty<DashboardEntityTileComponent>({ sourcePropertyKey: "entityLinkType" })
	public readonly observableEntityLinkType = new ObservableProperty<string>("");

	//#endregion PROPERTIES

	//#region METHODS


	constructor(
		private readonly isvcEntities: EntitiesService,
		private readonly isvcEntityLink: EntityLinkService,
	) {
		super();
	}

	public ngOnInit(): void {
		this.observableEntityDescId.value$.pipe(
			switchMap((psDescId: string) => combineLatest([
				this.isvcEntities.getDescriptor$(psDescId),
				this.observableUseLinks.value$,
				this.observableEntityLinkType.value$
			]).pipe(
				switchMap(([poDesc, pbUseLinks, peLinkType]: [IEntityDescriptor, boolean, EEntityLinkType | undefined]) => {
					if (pbUseLinks) {
						const leEntityPrefix: EPrefix = IdHelper.getPrefixFromId(poDesc.idPattern ?? "");
						return this.isvcEntityLink.countLinkedEntities$(UserHelper.getUserContactId(), [leEntityPrefix], peLinkType, true, undefined);
					}
					else
						return this.isvcEntities.countEntries$(this.isvcEntities.getDataSource(poDesc), undefined);
				})
			)),
			tap((paDocsLength: number) => this.observableTitle.value = paDocsLength.toString()),
			secure(this)
		).subscribe();
	}

	//#endregion METHODS

}
