import { AsyncPipe, CommonModule } from "@angular/common";
import { NgModule, Type } from "@angular/core";
import { FormsService } from "@calaosoft/osapp/services/forms.service";
import { IonicModule } from "@ionic/angular";
import { FormlyModule } from "@ngx-formly/core";
import { DragScrollComponent, DragScrollItemDirective } from "ngx-drag-scroll";
import { DashboardModule } from "../../../../../libs/osapp/src/modules/dashboard/dashboard.module";
import { C_FORMLY_CUSTOM_TRADE } from "../../model/TradeComponentRegister";
import { OpportunitiesModule } from "../opportunities/opportunities.module";
import { TradeEventsModule } from "../trade-events/trade-events.module";

const modules: Array<Type<any>> = [
	CommonModule,
	IonicModule,
	FormlyModule,
	AsyncPipe,
	DashboardModule,
	TradeEventsModule,
	OpportunitiesModule,
	DragScrollComponent,
	DragScrollItemDirective
];

const components: Array<Type<any>> = [
];

/** Module de gestion des formulaires. */
@NgModule({
	exports: components,
	providers: [FormsService],
	declarations: [C_FORMLY_CUSTOM_TRADE, ...components],
	imports: [...modules]
})
export class TradeFormModule { }