import { Observable, ReplaySubject } from "rxjs";

export abstract class Destroyable {

	//#region FIELDS

	private readonly moDestroySubject = new ReplaySubject<void>(1);

	//#endregion

	//#region PROPERTIES

	/** Flux qui notifie les abonnés lorsque la destruction de l'instance a eu lieu (méthode `destroy()` appelée). */
	public get destroyed$(): Observable<void> { return this.moDestroySubject.asObservable(); }

	private mbDestroyed = false;
	/** Indique si l'instance a été détruite. */
	public get destroyed(): boolean { return this.mbDestroyed; }

	//#endregion PROPERTIES

	//#region METHODS

	public destroy(): void {
		this.mbDestroyed = true;
		this.moDestroySubject.next(undefined);
		this.moDestroySubject.complete();
	}

	//#endregion METHODS

}