import { Injectable } from '@angular/core';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICall } from '../..//model/booking/ICall';
import { IBookingSession } from '../../model/booking/IBookingSession';
import { IStream } from '../../model/booking/IStream';
import { IUserAgent } from '../../model/booking/IUserAgent';
import { IContact } from '../../model/contacts/IContact';
import { JitsiService } from './jitsi/services/jitsi.service';
import { EVisioType } from './models/EVisio-type';

declare let apiRTC; // Indispensable pour utiliser l'apiRTC importé via script js.

interface Counter {
	min: number;
	sec: number;
}

@Injectable({
	providedIn: 'root'
})
export class LiveService {

	//#region PROPERTIES

	public isApiRTCInit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	//#endregion

	//#region FIELDS
	private static readonly C_LOG_ID = "LV.S::";

	private moUserAgent: IUserAgent;
	private moConnectedSession: IBookingSession;

	//#endregion

	//#region METHODS

	constructor(
		private readonly iscvJitsiService: JitsiService) { }

	public async initialize(psApiKey: string, poConnectedContact: IContact): Promise<IBookingSession> {
		this.moUserAgent = new apiRTC.UserAgent({
			uri: `apzkey:${psApiKey}`
		});

		this.moConnectedSession = await this.moUserAgent.register({
			id: poConnectedContact._id,
			userData: { 'username': `${poConnectedContact.firstName} ${poConnectedContact.lastName}` }
		});

		this.isApiRTCInit$.next(true);

		return this.moConnectedSession;
	}

	public getSession(): IBookingSession {
		return this.moConnectedSession;
	}

	/**
	 * Raccrocher un appel.
	 */
	public hangupCall(poCall: ICall): void {
		try {
			poCall?.hangUp();
		}
		catch (poError) {
			console.warn("LIVE.S:: Error during hangup", poError);
		}
	}

	/**
	 * Ajoute un stream dans une div.
	 * @param poStream le stream à ajouter.
	 * @param psDivId l'id de la div où sera ajouté le stream.
	 * @param psMediaEltId l'id que portera le stream dans le DOM.
	 * @param poStyle objet comportant du style à intégrer au css.
	 * @param pbIsMuted permet de muter ou non le stream.
	 */
	public addStreamInDiv(poStream: IStream, psDivId: string, psMediaEltId: string, poStyle: any, pbIsMuted: boolean): void {

		const lsStreamIsVideo: string = poStream.hasVideo();

		let mediaElt: HTMLMediaElement;

		if (lsStreamIsVideo === 'false') {
			mediaElt = document.createElement("audio");
		} else {
			mediaElt = document.createElement("video");
		}

		mediaElt.id = psMediaEltId;
		mediaElt.autoplay = true;
		mediaElt.muted = pbIsMuted;
		mediaElt.style.width = poStyle.width;
		mediaElt.style.height = poStyle.height;

		poStream.attachToElement(mediaElt);

		const loDivElement: HTMLElement | null = document.getElementById(psDivId);
		loDivElement ? loDivElement.appendChild(mediaElt) : null;

		const loPromise: Promise<void> = mediaElt.play();

		if (!!loPromise) {
			loPromise.then(() => {
				// Autoplay started!
				console.log('Autoplay started');
			}).catch(function (error) {
				// Autoplay was prevented.
				if (apiRTC.osName === "iOS") {
					console.info('iOS : Autoplay was prevented, activating touch event to start media play');
					console.error('WARNING : Audio autoplay was prevented by iOS, touch screen to activate audio');
				} else {
					console.error('Autoplay was prevented');
				}
			});
		}
	}

	/**
	 * Lance le timer et raccroche l'appel à la fin du temps.
	 * @param poCall l'appel en cours.
	 */
	public startTimer(poStartCounter: Counter, pnStartMinute: number): Observable<Counter> {
		if (!poStartCounter) {
			poStartCounter = this.generateCounter(pnStartMinute);
		}

		return interval(1000)
			.pipe(
				map(_ => {
					if (poStartCounter.sec === 0) {
						poStartCounter.min -= 1;
						poStartCounter.sec = 59;
					}
					else
						poStartCounter.sec -= 1;
					return poStartCounter;
				})
			);
	}

	/** Crée un compteur qui sera décrémenté.
	 * @param pdStartDate
	 */
	public generateCounter(pnStartMinute: number, pdStartDate?: Date): Counter {
		let lnMinutes = pnStartMinute;
		let lnSeconds = 0;

		if (pdStartDate) {
			const ldStartPlusLnMinutes: Date = DateHelper.addMinutes(pdStartDate, lnMinutes);
			const ldNow = Date.now();
			const lsRemainingSeconds: number = DateHelper.diffSeconds(ldStartPlusLnMinutes, ldNow);

			if (lsRemainingSeconds >= 0) {
				lnMinutes = Math.floor(lsRemainingSeconds / 60);
				lnSeconds = lsRemainingSeconds % 60;
			}
		}

		return { min: lnMinutes, sec: lnSeconds };
	}

	/** Afficher la modale de visio
 * @param psVisioType enum sur les moteurs de visio
 * @param psRoom nom de la salle de visio
 * @param psName nom de l'utilisateur dans la visio
 */
	public showVisioModalAsync(psVisioType: EVisioType, psRoom: string, psName?: string): Promise<void> {
		switch (psVisioType) {
			case EVisioType.Jitsi:
				return this.iscvJitsiService.showVisioModalAsync(psRoom, psName);
			default:
				console.warn(`${LiveService.C_LOG_ID}Visio server '${psVisioType}' not found.`);
				return Promise.resolve();
		}
	}
	//#endregion

}