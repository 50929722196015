import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, QueryList, forwardRef } from '@angular/core';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DestroyableComponentBase } from '../../../../utils/components/destroyable-component-base';
import { ColumnListHeaderColumnComponent } from '../column-list-header-column/column-list-header-column.component';
import { ColumnListComponent } from '../column-list.component';

@Component({
	selector: 'calao-column-list-header',
	templateUrl: './column-list-header.component.html',
	styleUrls: ['./column-list-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnListHeaderComponent<T> extends DestroyableComponentBase implements AfterContentInit {

	//#region PROPERTIES

	public readonly detail$: Observable<boolean> = this.ioParent.observableDetail.value$.pipe(secure(this));
	// forwardRef car problème de dépendance circulaire
	@ContentChildren(forwardRef(() => ColumnListHeaderColumnComponent<T>), { descendants: true })
	private moColumns: QueryList<ColumnListHeaderColumnComponent<T>>;

	/** Nombre de colonnes du header. */
	public readonly observableColumnsNumber = new ObservableProperty<number>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(private readonly ioParent: ColumnListComponent<T>) {
		super();
	}

	public ngAfterContentInit(): void {
		this.observableColumnsNumber.bind(
			this.moColumns.changes.pipe(
				startWith({}),
				map(() => this.moColumns.length)
			),
			this
		);
	}

	//#endregion METHODS

}
