import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from '@calaosoft/osapp-common/rxjs/operators/secure';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { switchMap, tap } from 'rxjs/operators';
import { EntityLinkService } from '../../../../../../services/entityLink.service';
import { IEntityDescriptor } from '../../../../../entities/models/ientity-descriptor';
import { EntitiesService } from '../../../../../entities/services/entities.service';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { ILinkedEntitiesFieldParams } from './models/ilinked-entities-field-params';

@Component({
	selector: 'calao-linked-entities-field',
	templateUrl: './linked-entities-field.component.html',
	styleUrls: ['./linked-entities-field.component.scss'],
})
export class LinkedEntitiesFieldComponent extends FieldBase<void> implements OnInit {

	//#region PROPERTIES

	public readonly observableParams = new ObservableProperty<ILinkedEntitiesFieldParams>();

	public readonly observableModels = new ObservableArray<Entity>([]);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcEntities: EntitiesService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observableParams.value = this.props.data;

		this.observableParams.value$.pipe(
			switchMap((poParams: ILinkedEntitiesFieldParams) => this.isvcEntities.getDescriptor$(poParams.entityDescId).pipe(
				switchMap((poDesc: IEntityDescriptor) => this.isvcEntityLink.currentEntity.getLinkedEntities$(poParams.linkTypes.target, IdHelper.getPrefixFromId(poDesc.idPattern))),
			)),
			tap((paLinkedEntities: Entity[]) => this.observableModels.resetArray(paLinkedEntities)),
			secure(this)
		).subscribe();
	}

	public getEntityName(poEntity: Entity): string {
		return this.isvcEntities.getEntityName(poEntity);
	}

	public navigateToEntity(poEntity: Entity): Promise<boolean> {
		return this.isvcEntities.navigateToEntityViewAsync(poEntity);
	}

	//#endregion METHODS

}
