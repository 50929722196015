<div class="container">
	<h2>Connecter Calaotrade à ...</h2>

	<ng-container *ngIf="hasSystemId('msgraph')">


		<div class="container-title-system-id">
			<h3>
				<ion-icon src="assets/img/microsoft-M-logo.svg"></ion-icon>
				Microsoft
			</h3>
			<ion-note>
				<ng-container *ngIf="getDisplayNameBySystemId('msgraph') as displayName">
					{{displayName}} <br>
				</ng-container>
				{{getEmailBySystemId('msgraph')}}
			</ion-note>
		</div>

		<div>
			<ng-container *ngIf="systemsIdsForMailbox.includes('msgraph')">
				<ng-container *ngTemplateOutlet="mailbox; context: {systemId: 'msgraph'}"></ng-container>
			</ng-container>

			<ng-container *ngIf="systemsIdsForCalendar.includes('msgraph')">
				<ng-container *ngTemplateOutlet="calendar; context: {systemId: 'msgraph'}"></ng-container>
			</ng-container>
		</div>


		<div class="buttons">
			<ion-button shape="round" (click)="onApplyButtonClick('msgraph')"
				*ngIf="!(observableIsLoading.value$|async)">Appliquer</ion-button>
			<ion-spinner *ngIf="(observableIsLoading.value$|async)" color="primary"></ion-spinner>
		</div>
	</ng-container>

	<ng-container *ngIf="hasSystemId('gcloud')">
		<div class="container-title-system-id">
			<h3>
				<ion-icon src="assets/img/google-G-logo.svg"> </ion-icon>
				Google
			</h3>
			<ion-note>
				<ng-container *ngIf="getDisplayNameBySystemId('gcloud') as displayName">
					{{displayName}} <br>
				</ng-container>
				{{getEmailBySystemId('gcloud')}}
			</ion-note>
		</div>

		<div>
			<ng-container *ngIf="systemsIdsForMailbox.includes('gcloud')">
				<ng-container *ngTemplateOutlet="mailbox; context: {systemId: 'gcloud'}"></ng-container>
			</ng-container>

			<ng-container *ngIf="systemsIdsForCalendar.includes('gcloud')">
				<ng-container *ngTemplateOutlet="calendar; context: {systemId: 'gcloud'}"></ng-container>
			</ng-container>
		</div>

		<div class="buttons">
			<ion-button shape="round" (click)="onApplyButtonClick('gcloud')"
				*ngIf="!(observableIsLoading.value$|async)">Appliquer</ion-button>
			<ion-spinner *ngIf="(observableIsLoading.value$|async)" color="primary"></ion-spinner>
		</div>
	</ng-container>

	<div class="buttons">
		<ion-button fill="clear" color="medium" shape="round" (click)="close()">
			Fermer
		</ion-button>
	</div>
</div>

<ng-template #loading>
	<div>
		<ion-spinner color="primary"></ion-spinner>
	</div>
</ng-template>

<ng-template #mailbox let-systemId="systemId">
	<div class="container-service-type">
		<ion-toggle [enableOnOffLabels]="true" [checked]="hasSubscribe$(systemId,'mailbox')|async"
			(ionChange)="onToggleChanged(systemId,'mailbox',$event.detail.checked)"></ion-toggle>
		<ion-icon name="mail-sharp"></ion-icon>
		Messagerie
		<ng-container
			*ngTemplateOutlet="(hasSubscribe$(systemId, 'mailbox') | async)? connectedIcon : disconnectedIcon"></ng-container>
	</div>
</ng-template>

<ng-template #calendar let-systemId="systemId">
	<div class="container-service-type">
		<ion-toggle [enableOnOffLabels]="true" [checked]="hasSubscribe$(systemId,'calendar')|async"
			(ionChange)="onToggleChanged(systemId,'calendar',$event.detail.checked)"></ion-toggle>
		<ion-icon name="Calendar"></ion-icon>
		Calendrier
		<ng-container
			*ngTemplateOutlet="(hasSubscribe$(systemId, 'calendar') | async)? connectedIcon : disconnectedIcon"></ng-container>
	</div>
</ng-template>

<ng-template #connectedIcon>
	<ion-icon src="assets/img/linked.svg" class="connected"></ion-icon>
</ng-template>

<ng-template #disconnectedIcon>
	<ion-icon src="assets/img/unlinked.svg" class="disconnected"></ion-icon>
</ng-template>