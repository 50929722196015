import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { classToPlain, plainToClass } from "@calaosoft/osapp-common/class-transformer";
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { IStoreDataResponse } from "@calaosoft/osapp/model/store/IStoreDataResponse";
import { ModalService } from "@calaosoft/osapp/modules/modal/services/modal.service";
import { DestroyableComponentBase } from "@calaosoft/osapp/modules/utils/components/destroyable-component-base";
import { ShowMessageParamsPopup } from "@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup";
import { UiMessageService } from "@calaosoft/osapp/services/uiMessage.service";
import { Observable, defer, from, map, of, take } from "rxjs";
import { filter, mergeMap, tap } from "rxjs/operators";
import { C_PREFIX_BUSINESS, C_PREFIX_SECTOR, C_PREFIX_SECTORIZATION } from "../../../../app/app.constants";
import { Business } from "../../../businesses/model/business";
import { ISectorBusinessSelector } from "../../components/isector-business-selector-modal";
import { SectorBusinessSelectorModalComponent } from "../../components/sector-business-selector-modal.component";
import { ISectorOptimizationInputDTO } from "../../models/isector-optimization-input-dto";
import { ISectorOptimizationOutputDTO } from "../../models/isector-optimization-output-dto";
import { Sectorization } from "../../models/sectorization";
import { Sector } from "../../sectors/models/sector";
import { SectorsService } from "../../sectors/services/sectors.service";
import { SectorizationsService } from "../../services/sectorizations.service";

export interface IChangeRows {
	sourceSector: ObservableProperty<Sector>;
	business: ObservableProperty<Business>;
	targetSector: ObservableProperty<Sector>;
}

@Component({
	selector: "business-distribution",
	templateUrl: "./business-distribution.page.html",
	styleUrls: ["./business-distribution.page.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessDistributionPage extends DestroyableComponentBase {

	//#region FIELDS

	private moSctrz: Sectorization;
	private maBiz: Business[] | undefined;
	private maSect: Sector[] | undefined;

	//#endregion FIELDS

	//#region PROPERTIES

	public changeRows: ObservableArray<IChangeRows> = new ObservableArray<IChangeRows>();

	/** Indique si l'on est en mode comparaison ou si l'on ajoute un changement. */
	public isChanging = true;

	public titleFirstGroup: ObservableProperty = new ObservableProperty<string>("");
	public sectorsFirstGroup: ObservableProperty = new ObservableProperty<Sector[]>([]);

	public titleSecondGroup: ObservableProperty = new ObservableProperty<string>("");
	public sectorsSecondGroup: ObservableProperty = new ObservableProperty<Sector[]>([]);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioActivatedRoute: ActivatedRoute,
		private readonly isvcSctrz: SectorizationsService,
		private readonly isvcModal: ModalService,
		private readonly isvcSectors: SectorsService
	) {
		super();

		this.ioActivatedRoute.queryParams.pipe(
			mergeMap((poQueryParams: Params) => this.ioActivatedRoute.params.pipe(
				mergeMap((poParams: Params) => this.isvcSctrz.getSectorization$(IdHelper.buildId(C_PREFIX_SECTORIZATION, poParams.entityGuid))
					.pipe(
						take(1),
						tap((poSctrz: Sectorization) => { if (poQueryParams[0] === "optimize") this.optmizeSectors(poSctrz) }),)
				),
				filter((poSctrz: Sectorization | undefined) => !!poSctrz),
				tap((poSctrz: Sectorization) => this.moSctrz = poSctrz),
				tap((poSctrz: Sectorization) => {
					this.titleFirstGroup.value = "Avant changements";
					this.titleSecondGroup.value = "Après changements";

					this.sectorsFirstGroup.value = this.isvcSctrz.createSectorWithBusinesses(poSctrz);

					this.maBiz = [];
					this.maSect = [];
					poSctrz.linkedSectors?.forEach((poSect: Sector) => {
						this.maSect?.push(poSect);
						poSect.linkedBusinesses?.forEach((poBiz: Business) => {
							this.maBiz?.push(poBiz);
						});
					});
				}),
			)),
			secure(this)
		).subscribe();
	}

	public optmizeSectorsClicked(): void {
		this.changeRows.clear();
		this.ioActivatedRoute.params.pipe(
			mergeMap(
				(poParams: Params) => this.isvcSctrz.getSectorization$(IdHelper.buildId(C_PREFIX_SECTORIZATION, poParams.entityGuid))
					.pipe(
						take(1),
						tap((poSctrz: Sectorization) => this.optmizeSectors(poSctrz)),
					)
			),
			secure(this)
		).subscribe()
	}

	public optmizeSectors(poSctrz: Sectorization): void {
		let loOptimizationInput: Observable<ISectorOptimizationInputDTO> | undefined = this.isvcSctrz.makeSectorOptimizationInput(poSctrz);
		if (loOptimizationInput)
			this.isvcSctrz.optimizeSectors(loOptimizationInput).pipe(
				take(1),
				map((poTourOptimized: any) => {
					if (poTourOptimized.isError) {
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
							header: "Optimisation Impossible",
							message: "",
						}))
						return false;
					}
					return plainToClass(ISectorOptimizationOutputDTO, poTourOptimized);
				}),
				filter((poOptiResult: boolean | ISectorOptimizationOutputDTO) => !!poOptiResult),
				tap((poSectorOptimizationOutput: ISectorOptimizationOutputDTO) => this.addChangesFromSectorOptimization(poSectorOptimizationOutput, poSctrz)),
				secure(this)
			).subscribe();
	}

	public addChangesFromSectorOptimization(poSectorOptimizationOutput: ISectorOptimizationOutputDTO, poSctrz: Sectorization) {
		Object.entries(poSectorOptimizationOutput).forEach(([psPoiSourceId, paPoiTargets]: [string, { poiTargetId: string; }[]]) => {
			paPoiTargets.forEach((poTarget: { poiTargetId: string }) => {
				const loChangeRow: IChangeRows = {
					sourceSector: new ObservableProperty(),
					business: new ObservableProperty(),
					targetSector: new ObservableProperty(),
				};
				let loSectorSource: Sector = poSctrz.linkedSectors?.find(poSect => poSect.linkedBusinesses?.map((poBiz: Business) => poBiz._id).includes(poTarget.poiTargetId))!
				if (loSectorSource._id !== psPoiSourceId) {
					loChangeRow.business.value = loSectorSource.linkedBusinesses?.find((poBiz: Business) => poBiz._id === poTarget.poiTargetId)!;
					loChangeRow.sourceSector.value = loSectorSource
					loChangeRow.targetSector.value = poSctrz.linkedSectors?.find((poSect: Sector) => poSect.pointOfContactIds?.length &&
						poSect.pointOfContactIds[0] === psPoiSourceId)!

					if (loChangeRow.targetSector.value && loChangeRow.sourceSector.value && loChangeRow.business.value)
						this.changeRows.push(loChangeRow);
				}
			});
		});
	}

	public addChange(): void {
		const loChangeRow: IChangeRows = {
			sourceSector: new ObservableProperty(),
			business: new ObservableProperty(),
			targetSector: new ObservableProperty(),
		};
		this.openSectSelectionModal(this.maSect?.filter((poSect: Sector) => !!poSect.linkedBusinesses?.length).map((poSect: Sector) => poSect._id))
			.pipe(
				filter((psId: string | undefined) => !!psId),
				mergeMap((psId: string) => {
					if (loChangeRow.sourceSector) {
						loChangeRow.sourceSector.value = this.maSect?.find((poSect: Sector) => poSect._id === psId)!;
						return this.openBizSelectionModal(loChangeRow.sourceSector.value.linkedBusinesses?.map((poBiz: Business) => poBiz._id));
					}
					else
						return of(undefined);
				}),
				filter((psId: string | undefined) => !!psId),
				mergeMap((psId: string) => {
					if (loChangeRow.business)
						loChangeRow.business.value = this.maBiz?.find((poBiz: Business) => poBiz._id === psId)!;
					return this.openSectSelectionModal(this.maSect?.filter((poSect: Sector) =>
						poSect._id !== loChangeRow.sourceSector?.value?._id).map((poSect: Sector) => poSect._id));
				}),
				filter((psId: string | undefined) => !!psId),
				tap((psId: string) => {
					if (loChangeRow.targetSector)
						loChangeRow.targetSector.value = this.maSect?.find((poSect: Sector) => poSect._id === psId)!;
					if (loChangeRow && this.changeRows)
						this.changeRows.push(loChangeRow);
				}),
				secure(this)
			).subscribe();
	}

	public openBizSelectionModal(paIds?: string[]): Observable<string | undefined> {
		const laIds: string[] = this.maBiz?.map((poBiz: Business) => poBiz._id).filter((psId: string) => paIds?.includes(psId)) ?? [];

		this.changeRows.forEach((poChangeRow: IChangeRows) => {
			laIds.forEach((psId: string) => {
				if (psId === poChangeRow.business.value!._id) {
					ArrayHelper.removeElement(laIds, psId)
				}
			});
		});

		const loModalOptions: ISectorBusinessSelector = {
			title: `Sélectionner un business`,
			listOfEntities: laIds,
			prefix: C_PREFIX_BUSINESS
		};

		return this.isvcModal.open<string | undefined>({ component: SectorBusinessSelectorModalComponent, componentProps: loModalOptions });
	}

	public openSectSelectionModal(paIds?: string[]): Observable<string | undefined> {
		const loModalOptions: ISectorBusinessSelector = {
			title: `Sélectionner un secteur`,
			listOfEntities: this.maSect?.map((poSect: Sector) => poSect._id).filter((psId: string) => paIds?.includes(psId)) ?? [],
			prefix: C_PREFIX_SECTOR
		};

		return this.isvcModal.open<string | undefined>({ component: SectorBusinessSelectorModalComponent, componentProps: loModalOptions });
	}


	public onSectorSelectorModalClicked(poChangeRow: IChangeRows): void {
		const laIds = this.maSect
			?.map((poSect: Sector) => poSect._id)
			.filter((psId: string) => psId !== poChangeRow.sourceSector.value!._id) ?? [];

		const loModalOptions: ISectorBusinessSelector = {
			title: `Sélectionner un secteur`,
			listOfEntities: laIds,
			prefix: C_PREFIX_SECTOR
		};

		this.isvcModal.open<string | undefined>({ component: SectorBusinessSelectorModalComponent, componentProps: loModalOptions })
			.pipe(
				tap((psId: string) => {
					if (poChangeRow.targetSector && psId)
						poChangeRow.targetSector.value = this.maSect?.find((poSect: Sector) => poSect._id === psId)!
				}),
				secure(this)
			).subscribe();
	}

	public onBizSelectorModalClicked(poChangeRow: IChangeRows): void {
		const laIds = this.maBiz?.map((poBiz: Business) => poBiz._id)
			.filter((psId: string) => poChangeRow.sourceSector.value!.linkedBusinesses!
				.map((poBiz: Business) => poBiz._id)
				.includes(psId))
			.filter((psId: string) => psId !== poChangeRow.business.value!._id) ?? [];

		this.changeRows.forEach((poChangeRow: IChangeRows) => {
			laIds.forEach((psId: string) => {
				if (psId === poChangeRow.business.value!._id) {
					ArrayHelper.removeElement(laIds, psId);
				}
			});
		});

		this.changeRows.forEach((poChangeRow: IChangeRows) => {
			laIds.filter((psId: string) => {
				psId !== poChangeRow.business.value!._id
			});
		});

		const loModalOptions: ISectorBusinessSelector = {
			title: `Sélectionner un business`,
			listOfEntities: laIds,
			prefix: C_PREFIX_BUSINESS
		};

		this.isvcModal.open<string | undefined>({ component: SectorBusinessSelectorModalComponent, componentProps: loModalOptions })
			.pipe(
				filter((poModalResult: string | undefined) => !!poModalResult),
				tap((psId: string) => {
					if (poChangeRow.business && psId && this.maBiz)
						poChangeRow.business.value = this.maBiz.find((poBiz: Business) => poBiz._id === psId)!;
				}),
				secure(this)
			).subscribe();
	}

	public switchToComparisonMode(): void {
		this.isChanging = !this.isChanging;

		let laSectors: Sector[] = []
		laSectors = this.makeGroupOfSectorsAfterchanges(this.maSect!, this.changeRows)
			.filter((poSect: Sector) => !!poSect.linkedBusinesses?.length);

		this.sectorsSecondGroup.value = laSectors;
	}

	public switchToChangingMode(): void {
		this.isChanging = !this.isChanging;
	}

	private makeGroupOfSectorsAfterchanges(paSectors: Sector[], paChangeRows: IChangeRows[]): Sector[] {
		let laSectors: Sector[] = plainToClass(Sector, classToPlain(paSectors)) as unknown as Array<Sector>;

		paChangeRows.forEach(change => {
			laSectors.find((poSect: Sector) => poSect._id === change.sourceSector.value!._id)!.linkedBusinesses = this.removeBusiness(
				laSectors.find((poSect: Sector) => poSect._id === change.sourceSector.value!._id)?.linkedBusinesses!,
				change.business?.value!);

			if (!laSectors.find((poSect: Sector) => poSect._id === change.targetSector.value!._id)?.linkedBusinesses?.includes(change.business?.value!))
				laSectors.find((poSect: Sector) => poSect._id === change.targetSector.value!._id)?.linkedBusinesses?.push(change.business?.value!);
		});
		return laSectors;
	}

	private removeBusiness(paBiz: Business[], poBiz: Business): Array<Business> {
		const lnIndex: number = paBiz.map((poBizz: Business) => poBizz._id).indexOf(poBiz._id);
		if (lnIndex > -1) {
			paBiz.splice(lnIndex, 1);
		}
		return paBiz;
	}

	public deleteChangeRow(poChangeRow: IChangeRows): void {
		this.changeRows.remove(poChangeRow);
	}

	public closeAndSaveChanges(): void {
		let paSectors: Sector[] = this.makeGroupOfSectorsAfterchanges(
			(this.moSctrz.linkedSectors ?? []), this.changeRows);

		defer(() => this.isvcSctrz.saveSectorizationAsync(this.moSctrz))
			.pipe(
				mergeMap((_: IStoreDataResponse) => from(paSectors ?? [])),
				mergeMap((poSector: Sector) => this.isvcSectors.saveSectorAsync(poSector)),
				take(1),
				tap(_ => {
					!(this.moSctrz._id && this.maBiz?.length) ||
						this.isvcSectors.navigateToSectorizationAsync(this.moSctrz._id)
				}),
				secure(this)
			).subscribe()
	}

	public close(): void {
		!(this.moSctrz._id && this.maBiz?.length) || this.isvcSectors.navigateToSectorizationAsync(this.moSctrz._id);
	}

	//#endregion METHODS

}