import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { IonicModule } from '@ionic/angular';
import { CalaoAvatarModule } from '../../modules/avatar/calao-avatar.module';
import { GesturesModule } from '../../modules/gestures/gestures.module';
import { LiveModule } from '../../modules/live';
import { ModalModule } from "../../modules/modal/modal.module";
import { SpinnerModule } from '../../modules/spinner/spinner.module';
import { TabsModule } from '../../modules/tabs/tabs.module';
import { VirtualScrollModule } from '../../modules/virtual-scroll/virtual-scroll.module';
import { PipeModule } from '../../pipes/pipes.module';
import { GalleryModule } from '../gallery/gallery.module';
import { SearchModule } from '../search/search.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { InteropModalComponent } from './interopModal/interop-modal.component';

const modules: (Type<any> | ModuleWithProviders<any>)[] = [
	IonicModule,
	CommonModule,
	ToolbarModule,
	PipeModule,
	CalaoAvatarModule,
	GalleryModule,
	FormsModule,
	SearchModule,
	MatBadgeModule,
	GesturesModule.forRoot({ longPress: { time: 1000 } }),
	SpinnerModule,
	TabsModule,
	VirtualScrollModule,
	LiveModule,
	ModalModule
];
const components: Type<any>[] = [
	InteropModalComponent
];
const providers: Provider[] = [];

/** Module de gestion des conversations. */
@NgModule({
	imports: modules,
	exports: components,
	declarations: components,
	providers: providers,
})
export class InteroperabiliteModule { }
