import { Expose } from 'class-transformer';
import { IConstructor } from '../models/iconstructor';
import { ModelResolver } from '../models/model-resolver';

/** Permet de résoudre automatiquement le type réel d'une donnée.
 *  ### Il faut gérer un constructeur sans paramètre.
 * @param pfBaseType
 */
export function ResolveModel(poBaseType: IConstructor): PropertyDecorator {
	return function (poTarget: Object, psPropertyKey: string, poDescriptor?: TypedPropertyDescriptor<any>) {
		Expose()(poTarget, psPropertyKey); // Par défaut expose la valeur car on la transforme en getter/setter

		poDescriptor = poDescriptor ?? Object.getOwnPropertyDescriptor(poTarget, psPropertyKey);
		const lfOriginalGet = poDescriptor?.get;
		const lfGet = function () {
			lfOriginalGet?.apply(this);
			return this[`#${psPropertyKey}`];
		};
		const lfOriginalSet = poDescriptor?.set;
		const lfSet = function (poNewVal: any) {
			let loValue: any;

			if (poNewVal instanceof Array)
				loValue = poNewVal.map((poVal: any) => ModelResolver.toClass(poBaseType, poVal));
			else
				loValue = ModelResolver.toClass(poBaseType, poNewVal);

			this[`#${psPropertyKey}`] = loValue;
			lfOriginalSet?.apply(this, [loValue]);
		};

		if (poDescriptor?.get)
			poDescriptor.get = lfGet;

		if (poDescriptor?.set)
			poDescriptor.set = lfSet;

		// On surcharge la propriété avec les méthodes get et set custom si ce n'était pas déjà une property, sinon le descriptor fera la surcharge.
		if (!lfOriginalSet && !lfOriginalGet)
			Object.defineProperty(poTarget, psPropertyKey, { get: lfGet, set: lfSet, configurable: true });

		return poDescriptor;
	};
};
