import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { Geolocation, Position } from '@capacitor/geolocation';
import { Layer, Map, MapOptions, Marker, Polygon, Popup, TileLayer, control, divIcon, icon, latLng, polygon, popup, tileLayer } from "leaflet";
import { Observable } from 'rxjs';
import { delay, map, mergeMap, tap } from 'rxjs/operators';
import { ICoordinates } from '../../../model/navigation/ICoordinates';
import { DestroyableComponentBase } from '../../utils/components/destroyable-component-base';
import { IMapButtonOptions } from '../models/imap-button-options';
import { IMapIconOptions } from '../models/imap-icon-options';
import { IMapOptions } from "../models/imap-options";
import { IPoiOptions } from "../models/ipoi-options";
import { IPolygonOptions } from '../models/ipolygon-options';

interface ITileProviderOptions {
	url: string;
	maxZoom: number;
	minZoom: number;
	subdomains?: string[]; // Utile pour google URL
}
@Component({
	selector: "calao-map",
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent extends DestroyableComponentBase {
	// TODO : https://stackoverflow.com/questions/42340067/angular-component-into-leaflet-popup

	//#region FIELDS

	private static readonly C_GMAPS_OPTIONS: ITileProviderOptions = {
		url: 'https://mt0.google.com/vt/lyrs=m&hl=fr&x={x}&y={y}&z={z}&s=Ga',
		maxZoom: 18,
		minZoom: 3,
		subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
	};

	private moCurrentPos?: Position;

	//#endregion FIELDS

	//#region PROPERTIES

	@Input() public options?: IMapOptions;

	@ObserveProperty<MapComponent>({ sourcePropertyKey: "options" })
	public readonly observableOptions = new ObservableProperty<IMapOptions>();

	public readonly leafletMapOptions = new ObservableProperty<MapOptions>();
	public readonly leafletLayerOptions = new ObservableArray<Layer>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly ioRouter: Router,
		private readonly ioElementRef: ElementRef,
	) {
		super();

		this.init$().pipe(secure(this)).subscribe();
	}

	/** Initialise le composant en récupérant puis orchestrant plusieurs étapes de configuration. */
	private init$(): Observable<void> {
		return this.observableOptions.value$
			.pipe(
				mergeMap(() => Geolocation.getCurrentPosition()),
				tap((poCurrPosition: Position) => this.moCurrentPos = poCurrPosition),
				delay(500),
				map(() => this.initCamera(this.getTileLayer())),
				map(() => !this.options?.youAreHereMarker ?? this.addYoureHereMarker()),
				map(() => this.addMarkers())
			);
	}

	/** Navigue vers la page renseignée dans les options s'il y en a une.
	 * @param poMapButton - Les options du bouton cliqué.
	 * @returns Une promesse qui se résout à `true` ou `false` selon l'action effectuée.
	 */
	public navigateAsync(poMapButton: IMapButtonOptions): Promise<boolean> {
		return poMapButton.routeTo ? this.ioRouter.navigate([poMapButton.routeTo]) : Promise.resolve(false);
	}

	/** Récupère les tuiles de la carte.
	 * @returns Les tuiles configurées.
	 */
	private getTileLayer(): TileLayer {
		return tileLayer(
			MapComponent.C_GMAPS_OPTIONS.url,
			{
				maxZoom: MapComponent.C_GMAPS_OPTIONS.maxZoom,
				minZoom: MapComponent.C_GMAPS_OPTIONS.minZoom
			}
		);
	}

	/** Ajoute les polygones à la carte et l'options de les masquer dans l'UI */
	private createPolygons(): Polygon[] {
		if (this.observableOptions.value) {
			return this.observableOptions.value.polygons.map((poPoly: IPolygonOptions) => {
				return this.createLeafletPolygons(poPoly);
			}) ?? [];
		}
		else
			return [];
	}

	/** Crée un polygone au format de `leaflet`.*/
	private createLeafletPolygons(poPoly: IPolygonOptions): Polygon {
		if (!poPoly.popup) {
			return this.createPolygon(poPoly);
		}
		else {
			const loPopup: Popup = popup().setContent(poPoly.popup.htmlText);
			return this.createPolygon(poPoly).on("popupopen", () => {
				if (poPoly.popup?.buttons) {
					Object.keys(poPoly.popup.buttons).forEach((psKey: string) => {
						this.ioElementRef.nativeElement
							.querySelector(psKey)
							.addEventListener("click", poPoly.popup?.buttons![psKey].onClick
							);
					});
				}
			})
				.on("popupclose", () => {
					if (poPoly.popup?.buttons) {
						Object.keys(poPoly.popup.buttons).forEach((psKey: string) => {
							const loHtmlKey = this.ioElementRef.nativeElement.querySelector(psKey);
							if (loHtmlKey)
								loHtmlKey.removeEventListener("click", poPoly.popup?.buttons![psKey].onClick
								);
						});
					}
				})
				.bindPopup(loPopup);
		}
	}

	private createPolygon(poPoly: IPolygonOptions): Polygon<any> {
		return polygon(
			poPoly.coordinates.map((poCoords: ICoordinates) => { return latLng(poCoords.latitude, poCoords.longitude); }),
			poPoly.options
		);
	}

	/** Ajoute les marqueurs à la carte.*/
	private addMarkers(): void {
		if (this.observableOptions.value) {
			this.observableOptions.value.pois
				.forEach((poPoi: IPoiOptions) => {
					this.leafletLayerOptions.push(poPoi.popup ?
						this.createMarkerWithPopup(poPoi) :
						this.createNewMarker(poPoi)
					);
				});
		}
	}

	/** Crée un marqueur avec une popup.
	 * @param poPoi - Les options du point d'intérêt.
	 * @returns Le marqueur avec la popup configurée.
	 */
	private createMarkerWithPopup(poPoi: IPoiOptions): Marker {
		const loPopup: Popup = popup().setContent(poPoi.popup?.htmlText ?? "<p> Le contenu de cette popup n'est pas spécifié </p>");
		if (poPoi.popup?.buttons) {
			return this.createNewMarker(poPoi)
				.on("popupopen", () => {
					if (poPoi.popup?.buttons) {
						Object.keys(poPoi.popup.buttons).forEach((psKey: string) => {
							this.ioElementRef.nativeElement
								.querySelector(psKey)
								.addEventListener("click", poPoi.popup?.buttons![psKey].onClick
								);
						});
					}
				})
				.on("popupclose", () => {
					if (poPoi.popup?.buttons) {
						Object.keys(poPoi.popup.buttons).forEach((psKey: string) => {
							const loHtmlKey = this.ioElementRef.nativeElement.querySelector(psKey);
							if (loHtmlKey)
								loHtmlKey.removeEventListener("click", poPoi.popup?.buttons![psKey].onClick
								);
						});
					}
				})
				.bindPopup(loPopup);
		}
		else
			return this.createNewMarker(poPoi).bindPopup(loPopup);
	}

	/** Crée un nouveau marqueur.
	 * @param poPoi - Les options du point d'intérêt.
	 * @returns Le marqueur configuré.
	 */
	private createNewMarker(poPoi: IPoiOptions): Marker {
		return new Marker(
			[poPoi.coordinates.latitude, poPoi.coordinates.longitude],
			{
				icon:
					divIcon({
						className: ".leaflet-div-icon",
						html: this.createIconHtml(poPoi.icon),
						iconSize: [poPoi.icon.iconSizePx.width, poPoi.icon.iconSizePx.height]
					})
			});
	}

	/** Crée le HTML nécessaire pour une icône Leaflet.
	 * @param  poIconOptions URL de l'image à utiliser pour l'icône.
	 * @returns  Chaîne HTML représentant l'icône stylisée pour Leaflet.
	 */
	private createIconHtml(poIconOptions: IMapIconOptions): string {
		const lnCoef = 0.70;
		const lsDivStyle = `background-color:transparent;border-color:transparent;display:flex;justify-content:center;align-items:center;width:${poIconOptions.iconSizePx.width}px;height:${poIconOptions.iconSizePx.height}px;background-color:white;border-radius:50%;`;
		const lsImgStyle = `width:${poIconOptions.iconSizePx.width * lnCoef}px;height:${poIconOptions.iconSizePx.height * lnCoef
			}px;color:#333;`;
		return `<div style=${lsDivStyle}><img src="${poIconOptions.iconUrl}" style=${lsImgStyle} /></div>`;
	}

	/** Ajoute un marqueur indiquant la position actuelle de l'utilisateur.	 */
	private addYoureHereMarker(): void {
		const lsIconUrl = `assets/img/current_position_logo.png`;
		if (this.moCurrentPos) {
			this.leafletLayerOptions.push(new Marker(
				// La lib est inversée dans l'ordre lat/lon pour certaines choses
				[this.moCurrentPos.coords.latitude, this.moCurrentPos.coords.longitude],
				{
					icon: icon({
						iconUrl: lsIconUrl,
						iconSize: [80, 40],
						iconAnchor: [40, 20]
					})
				}
			));
		}
	}

	/** Permet de supprimer l'attribution (drapeau de l'ukraine en bas a droite) */
	public onMapReady(poMap: Map): void {
		poMap.addControl(control.attribution({ position: 'bottomright', prefix: '' }));
	}

	/** Crée la vue de la carte avec les options spécifiées.
	 * @param poTiles - Les tuiles à afficher sur la carte.
	 */
	private initCamera(poTiles: TileLayer): void {
		if (this.observableOptions.value) {
			const loStartCoords: ICoordinates = this.observableOptions.value.start.center;
			this.leafletMapOptions.value = {
				center: latLng(loStartCoords.latitude, loStartCoords.longitude),
				layers: [poTiles, ...this.createPolygons()],
				zoom: this.observableOptions.value.start.zoom,
				attributionControl: false
			};
		}
	}

	//#endregion METHODS
}