import { Injectable } from '@angular/core';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { IRange } from '@calaosoft/osapp-common/utils/models/irange';
import { ActivePageManager } from '@calaosoft/osapp/model/navigation/ActivePageManager';
import { BaseEvent } from '@calaosoft/osapp/modules/calendar-events/models/base-event';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { IEventLinkedEntitiesFilterParams } from '@calaosoft/osapp/modules/calendar-events/models/ievent-linked-entities-filter-params';
import { IEventOccurrenceDateRange } from '@calaosoft/osapp/modules/calendar-events/models/ievent-occurrence-date-range';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ETradeEventType } from '../models/etrade-event-type';
import { IEventsFilterValues } from '../models/ievents-filter-values';
import { IFilterEventOccurrencesParams } from '../models/ifilter-event-occurrences-params';
import { ReminderOccurrence } from '../models/reminder-occurrence';
import { TaskOccurrence } from '../models/task-occurrence';
import { TradeEventsService } from './trade-events.service';

@Injectable()
export class TradeActivitiesService {

	//#region METHODS

	constructor(private readonly isvcTradeEvents: TradeEventsService) { }

	public getPendingActivity$(
		poRange: IEventOccurrenceDateRange,
		poActivePageManager: ActivePageManager
	): Observable<BaseEventOccurrence[]> {
		return this.getAllActivities$(poRange, poActivePageManager).pipe(
			switchMap((paOccurrences: BaseEventOccurrence[]) => {
				return combineLatest(paOccurrences.map((poOccurrence: BaseEventOccurrence) =>
					poOccurrence.isLate$.pipe(
						map((pbIsLate: boolean) => pbIsLate ? undefined : poOccurrence)
					)
				)).pipe(
					map((paFilteredOccurrences: (BaseEventOccurrence | undefined)[]) => ArrayHelper.getValidValues(paFilteredOccurrences))
				);
			})
		);
	}

	private getAllActivities$(
		poRange: IEventOccurrenceDateRange,
		poActivePageManager: ActivePageManager
	): Observable<BaseEventOccurrence[]> {
		return combineLatest([
			this.isvcTradeEvents.getPendingTasksOccurrences$(poActivePageManager, { dateRange: poRange }),
			this.isvcTradeEvents.getEventsOccurrences$(
				poActivePageManager,
				{
					dateRange: {
						from: !poRange.from ? new Date() : poRange.from,
						to: poRange.to
					},
					types: [ETradeEventType.standard]
				}
			),
			this.isvcTradeEvents.getPendingRemindersOccurrences$(poActivePageManager, { dateRange: poRange })
		]).pipe(
			map((paEvents: BaseEventOccurrence[][]) => {
				return ArrayHelper.flat(paEvents)
					.sort((poEventOccurrenceA: BaseEventOccurrence, poEventOccurrenceB: BaseEventOccurrence) =>
						DateHelper.compareTwoDates(poEventOccurrenceA.startDate, poEventOccurrenceB.startDate)
					);
			})
		);
	}

	public getDoneActivity$(poRange: IEventOccurrenceDateRange, poActivePageManager: ActivePageManager): Observable<BaseEventOccurrence[]> {
		return combineLatest([
			this.isvcTradeEvents.getDoneTasksOccurrences$(
				poActivePageManager,
				{ dateRange: poRange }
			),
			this.isvcTradeEvents.getLateEventOccurrences$(poActivePageManager, poRange),
			this.isvcTradeEvents.getDoneRemindersOccurrences$(poActivePageManager, { dateRange: poRange })
		]).pipe(
			map((paEvents: BaseEventOccurrence[][]) => ArrayHelper.flat(paEvents)
				.sort((poEventOccurrenceA: BaseEventOccurrence, poEventOccurrenceB: BaseEventOccurrence) => DateHelper.compareTwoDates(poEventOccurrenceB.startDate, poEventOccurrenceA.startDate)
				)
			)
		);
	}

	public filterEventOccurrences(
		paEventOccurrences: BaseEventOccurrence[],
		poFilterValues?: IFilterEventOccurrencesParams,
		pbSkipSort: boolean = false
	): BaseEventOccurrence[] {
		if (!poFilterValues)
			return paEventOccurrences;

		let laFilteredOccurrences: BaseEventOccurrence[] = paEventOccurrences.filter((poEventOccurrence: BaseEventOccurrence) =>
			this.isInEventType(poEventOccurrence, poFilterValues) &&
			this.isStartDateInRange(poEventOccurrence, poFilterValues) &&
			this.isInTitle(poEventOccurrence, poFilterValues) &&
			this.isInPriority(poEventOccurrence, poFilterValues) &&
			this.isInParticipantIds(poEventOccurrence, poFilterValues) &&
			this.isInPlace(poEventOccurrence, poFilterValues)
		);

		if (poFilterValues.deadlineDateRange)
			laFilteredOccurrences = this.filterOccurrencesByDeadlineRange(laFilteredOccurrences, poFilterValues.deadlineDateRange);

		laFilteredOccurrences = this.filterEventOccurrencesByBusinessIds(laFilteredOccurrences, poFilterValues?.linkedEntities)

		if (pbSkipSort)
			return laFilteredOccurrences;
		else {
			const laSortExcludedItems: BaseEventOccurrence[] = ArrayHelper.removeElementsByFinder(laFilteredOccurrences, (poOccurrence: BaseEventOccurrence) => {
				return poOccurrence instanceof ReminderOccurrence && !poOccurrence[poFilterValues.sort?.by as any];
			});
			ArrayHelper.dynamicSortMultiple(
				laFilteredOccurrences,
				ArrayHelper.unique([poFilterValues.sort?.by as any, "startDate"]),
				poFilterValues.sort?.order
			);

			return [...laFilteredOccurrences, ...laSortExcludedItems];
		}
	}

	public filterDelegatedEventOccurrences(paEventOccurrences: BaseEventOccurrence[], poFilterValues?: IEventsFilterValues, pbSkipSort: boolean = false): BaseEventOccurrence[] {
		if (!poFilterValues)
			return paEventOccurrences;

		let laFilteredOccurrences: BaseEventOccurrence[] = paEventOccurrences.filter((poEventOccurrence: BaseEventOccurrence) =>
			(this.isStartDateInRange(poEventOccurrence, poFilterValues) || this.isChangeDateInRange(poEventOccurrence, poFilterValues)) &&
			this.isInTitle(poEventOccurrence, poFilterValues) &&
			this.isInPriority(poEventOccurrence, poFilterValues) &&
			this.isInParticipantIds(poEventOccurrence, poFilterValues) &&
			this.isInPlace(poEventOccurrence, poFilterValues)
		);

		if (poFilterValues.deadlineDateRange)
			laFilteredOccurrences = this.filterOccurrencesByDeadlineRange(laFilteredOccurrences, poFilterValues.deadlineDateRange);

		laFilteredOccurrences = this.filterEventOccurrencesByBusinessIds(laFilteredOccurrences, poFilterValues?.linkedEntities)

		if (pbSkipSort)
			return laFilteredOccurrences;
		else {
			return ArrayHelper.dynamicSortMultiple(
				laFilteredOccurrences,
				ArrayHelper.unique([poFilterValues.sort?.by as any, "startDate"]),
				poFilterValues.sort?.order
			);
		}
	}

	public filterDoneEventOccurrences(paEventOccurrences: BaseEventOccurrence[], poFilterValues?: IEventsFilterValues, pbSkipSort: boolean = false): BaseEventOccurrence[] {
		if (!poFilterValues)
			return paEventOccurrences;

		let laFilteredOccurrences: BaseEventOccurrence[] = paEventOccurrences.filter((poEventOccurrence: BaseEventOccurrence) =>
			this.isInEventType(poEventOccurrence, poFilterValues) &&
			(this.isStartDateInRange(poEventOccurrence, poFilterValues) || this.isChangeDateInRange(poEventOccurrence, poFilterValues)) &&
			this.isInTitle(poEventOccurrence, poFilterValues) &&
			this.isInPriority(poEventOccurrence, poFilterValues) &&
			this.isInParticipantIds(poEventOccurrence, poFilterValues) &&
			this.isInPlace(poEventOccurrence, poFilterValues)
		);

		laFilteredOccurrences = this.filterEventOccurrencesByBusinessIds(laFilteredOccurrences, poFilterValues?.linkedEntities)

		if (pbSkipSort)
			return laFilteredOccurrences;
		else {
			return ArrayHelper.dynamicSortMultiple(
				laFilteredOccurrences,
				ArrayHelper.unique([poFilterValues.sort?.by as any, "startDate"]),
				poFilterValues.sort?.order
			);
		}
	}

	private isInPlace(poEventOccurrence: BaseEventOccurrence, poFilterValues: IFilterEventOccurrencesParams): boolean {
		return StringHelper.isBlank(poFilterValues.place) || !!poEventOccurrence.place?.toLowerCase().includes(poFilterValues.place?.toLowerCase() ?? "");
	}

	private isInParticipantIds(poEventOccurrence: BaseEventOccurrence, poFilterValues: IFilterEventOccurrencesParams): boolean {
		return (!ArrayHelper.hasElements(poFilterValues.participantIds) || poEventOccurrence.hasSomeParticipants(poFilterValues.participantIds));
	}

	private isInPriority(poEventOccurrence: BaseEventOccurrence, poFilterValues: IFilterEventOccurrencesParams): boolean {
		return (!(poEventOccurrence instanceof TaskOccurrence) || (poEventOccurrence.priority ?? 0) <= (poFilterValues.priority ?? NaN));
	}

	private isInTitle(poEventOccurrence: BaseEventOccurrence, poFilterValues: IFilterEventOccurrencesParams): boolean {
		return poEventOccurrence.title.toLowerCase().includes(poFilterValues.title?.toLowerCase() ?? "");
	}

	private isStartDateInRange(poEventOccurrence: BaseEventOccurrence, poFilterValues: IFilterEventOccurrencesParams): boolean {
		return DateHelper.areRangesOverlaping(
			poEventOccurrence instanceof TaskOccurrence ? { to: poFilterValues.dateRange?.to } : poFilterValues.dateRange,
			{ from: poEventOccurrence.startDate, to: poEventOccurrence.observableEndDate.value }
		);
	}

	private isChangeDateInRange(poEventOccurrence: BaseEventOccurrence, poFilterValues: IEventsFilterValues): boolean {
		if (poEventOccurrence.statusChangeDate)
			return DateHelper.isBetweenTwoDates(poEventOccurrence.statusChangeDate, poFilterValues.dateRange?.from, poFilterValues.dateRange?.to);
		else
			return false;
	}

	private filterOccurrencesByDeadlineRange(paEventOccurrences: BaseEventOccurrence[], poRange: IRange<Date>): BaseEventOccurrence[] {
		return paEventOccurrences.filter((poEventOccurrence: BaseEventOccurrence) => {
			if (poEventOccurrence instanceof TaskOccurrence && poEventOccurrence.deadline) {
				return DateHelper.isBetweenTwoDates(poEventOccurrence.deadline, poRange.from, poRange.to);
			}
			else
				return false;
		});
	}

	private filterEventOccurrencesByBusinessIds(paOccurrences: BaseEventOccurrence[], poLinkedEntitiesFilter?: IEventLinkedEntitiesFilterParams): BaseEventOccurrence[] {
		if (!ArrayHelper.hasElements(poLinkedEntitiesFilter?.ids))
			return paOccurrences;

		const laEvents: BaseEvent[] = ArrayHelper.unique(paOccurrences.map((poOccurence: BaseEventOccurrence) => poOccurence.event));
		const laOccurrences: BaseEventOccurrence[] = this.isvcTradeEvents.filterEventOccurrencesByEntityIds(laEvents, paOccurrences, poLinkedEntitiesFilter);

		return laOccurrences;
	}

	private isInEventType(poOccurrence: BaseEventOccurrence, poFilterValue: IFilterEventOccurrencesParams): boolean {
		if (!poFilterValue.eventType)
			return true;
		else
			return poOccurrence.eventType ? poFilterValue.eventType.includes(poOccurrence.eventType) : false;
	}

	//#endregion METHODS

}
