import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Screenshot } from '@awesome-cordova-plugins/screenshot/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { EPlatform } from '@calaosoft/osapp-common/applications/models/EPlatform';
import { IAppInfo } from '@calaosoft/osapp-common/applications/models/IAppInfo';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { EEnvironmentId } from '@calaosoft/osapp-common/environment/models/EEnvironmentId';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { Directory } from '@capacitor/filesystem';
import { defer, EMPTY, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo, mergeMap, tap, toArray } from 'rxjs/operators';
import { IAttachment } from '../model/mail/IAttachment';
import { IMailOptions } from '../model/mail/IMailOptions';
import { IMailReport } from '../model/mail/IMailReport';
import { IMailReportOptions } from '../model/mail/IMailReportOptions';
import { IUiResponse } from '../model/uiMessage/IUiResponse';
import { FilesystemService } from '../modules/filesystem/services/filesystem.service';
import { ILogEntry } from '../modules/logger/models/ILogEntry';
import { LoggerService } from '../modules/logger/services/logger.service';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { PlatformService } from './platform.service';
import { UiMessageService } from './uiMessage.service';

interface ILogFile {
	appId: string;
	deviceId: string;
	login: string;
	platform: string;
	logs: ReadonlyArray<ILogEntry>;
	environment: EEnvironmentId;
}
interface IURIResult {
	URI: string;
}

/** Service permettant d'envoyer des mails. Sur Android et iOS ouvre l'application par défaut permettant d'envoyer des mails. */
@Injectable({ providedIn: "root" })
export class MailService {

	//#region FIELDS

	/** Qualité en pourcent du screen. */
	private static readonly C_SCREENSHOT_QUALITY: number = 80;

	//#endregion

	//#region PROPERTIES

	//#endregion

	//#region METHODS

	constructor(
		/** Service pour envoyer des mails / sms ... */
		private ioSocialSharing: SocialSharing,
		/** Service pour prendre un screenshot. */
		private ioScreenshot: Screenshot,
		/** Service d'affichage des popups et toasts. */
		private isvcUiMessage: UiMessageService,
		/** Filtre de date. */
		private ioDatePipe: DatePipe,
		private isvcPlatform: PlatformService,
		private isvcLogger: LoggerService,
		private readonly isvcFilesystem: FilesystemService) {
	}

	/** Retourne `true` si on peut envoyer des mails. */
	public canSendMail(): Observable<boolean> {
		// Vérifie que le plugin SocialSharing accepte d'envoyer des mails et que le dossier de stockage pour les pièces-jointes est défini.
		return defer(() => ConfigData.appInfo.platform === EPlatform.ios ? Promise.resolve(true) : this.ioSocialSharing.canShareViaEmail());
	}

	/** Ouvre le logiciel de mail par défaut avec les infos passées en paramètre.
	 * @param psSubject Objet du mail.
	 * @param psBody Corps du mail (peut-être de l'html).
	 * @param poMailOptions Options à appliquer au mail.
	 */
	public sendMail(psSubject: string, psBody: string, poMailOptions: IMailOptions = {}): Observable<void> {
		if (!this.isvcPlatform.isMobileApp)
			return this.innerSendMailBrowser({ ...poMailOptions, subject: psSubject, body: psBody });

		else {
			return this.canSendMail()
				.pipe(
					mergeMap((pbCanSendMail: boolean) => {
						if (pbCanSendMail)
							return this.innerSendMail({ ...poMailOptions, subject: psSubject, body: psBody } as IMailReportOptions);
						else {
							this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Impossible d'envoyer un mail pour le moment.", header: "Erreur" }));
							return EMPTY;
						}
					})
				);
		}
	}

	private prepareMail(poOptions: IMailReportOptions): Observable<IMailReport> {
		if (!poOptions.attachments)
			poOptions.attachments = [];
		if (!poOptions.body)
			poOptions.body = "";

		const loMail: IMailReport = this.createMail(poOptions);

		return this.buildAttachments(loMail, poOptions.needLogFile)
			.pipe(
				// Gestion de la création des pièces-jointes.
				tap((paAttachments: Array<string>) => loMail.attachments = paAttachments),
				// Gestion des captures d'écran et de l'envoi.
				mergeMap(_ => poOptions.needScreenshot && this.isvcPlatform.isMobileApp ? this.displayFeedbackPopup(loMail) : of(undefined)),
				mapTo(loMail)
			);
	}

	private innerSendMailBrowser(poOptions: IMailReportOptions): Observable<void> {
		return this.prepareMail(poOptions)
			.pipe(
				map((poMail: IMailReport) => {
					let lsMailTo = `mailto:${poMail.to.join(",")}`;
					let lsSeparator = "?";

					if (ArrayHelper.hasElements(poMail.cc)) {
						lsMailTo += `${lsSeparator}cc=${poMail.cc.join(",")}`;
						lsSeparator = "&";
					}
					if (ArrayHelper.hasElements(poMail.bcc)) {
						lsMailTo += `${lsSeparator}bcc=${poMail.bcc.join(",")}`;
						lsSeparator = "&";
					}
					if (ArrayHelper.hasElements(poMail.attachments)) {
						lsMailTo += `${lsSeparator}attach=${poMail.attachments.join(",")}`;
						lsSeparator = "&";
					}
					if (!StringHelper.isBlank(poMail.subject)) {
						lsMailTo += `${lsSeparator}subject=${poMail.subject}`;
						lsSeparator = "&";
					}
					if (!StringHelper.isBlank(poMail.body)) {
						lsMailTo += `${lsSeparator}body=${poMail.body}`;
						lsSeparator = "&";
					}

					return this.openMailTo(lsMailTo);
				})
			);
	}

	private openMailTo(psMailTo: string): void {
		// On crée un balise <a> et on simule un clique pour activer le mailto.
		const loAnchorElement: HTMLAnchorElement = document.createElement("a");

		loAnchorElement.href = encodeURI(psMailTo);
		loAnchorElement.target = "_blank";
		loAnchorElement.click();

		loAnchorElement.remove();
	}

	private innerSendMail(poOptions: IMailReportOptions): Observable<void> {
		return this.prepareMail(poOptions)
			.pipe(
				catchError(poError => { console.error("MAIL.S::Erreur lors de la préparation du mail.", poError); return throwError(() => poError); }),
				mergeMap((poMail: IMailReport) =>
					this.send(poMail).pipe(
						// Suppression des pièces-jointes créés.
						mergeMap(_ => this.clearAttachments(poOptions.attachments))
					)
				)
			);
	}

	/** Crée un mail à envoyer.
	 * @param poOptions Options pour la création du mail.
	 */
	private createMail(poOptions: IMailReportOptions): IMailReport {
		return {
			to: poOptions.to,
			subject: poOptions.subject,
			body: poOptions.body ?? "",
			attachments: poOptions.attachments,
			cc: poOptions.cc || [],
			bcc: poOptions.bcc || []
		} as IMailReport;
	}

	/** Créé les pièces-jointes suivant l'interface `IAttachment` sur disque et retourne un tableau avec tous les string
	 * (les adresses des fichiers créés + ceux passés en paramètre).
	 * @param poOptions Options du mail.
	 * @param pbNeedLogFile Indique si il y a besoin de joindre un fichier de logs.
	 */
	private buildAttachments(poOptions: IMailReportOptions, pbNeedLogFile: boolean): Observable<Array<string>> {
		//! Attention, `forkJoin` ne se complète pas si le tableau est vide.
		const laAttachments$: Observable<string[]> = ArrayHelper.hasElements(poOptions.attachments) ?
			forkJoin(this.transformAttachmentsToStringsAsync(poOptions.attachments)) : of([]);

		return laAttachments$
			.pipe(
				mergeMap((paNewAttachments: Array<string>) => {
					if (pbNeedLogFile) // Si on veut un fichier de log, on l'écrit sur le disque et on l'ajoute aux tableaux des PJ.
						return this.addLogFileAsync().then((psLogFile: string) => {
							if (this.isvcPlatform.isMobileApp)
								return [psLogFile, ...paNewAttachments];

							// Dans le cas de la webapp, on ajoute le fichier de log directement dans le body plutôt qu'en pièce jointe.
							poOptions.body += "\n\n" + psLogFile;
							return paNewAttachments;
						});
					else
						return of(paNewAttachments); // Sinon, on retourne le tableau des PJ.
				})
			);
	}

	/** Transforme un tableau de pièces jointes (objets ou chaînes de caractères) en un tableau de flux de chaîne de caractères (observables de string).
	 * @param paAttachments Tableau des fichiers à joindre dans le mail.
	 */
	private transformAttachmentsToStringsAsync(paAttachments: Array<IAttachment | string>): Promise<string>[] {
		return paAttachments.map((poAttachment: IAttachment | string) => {
			if (typeof poAttachment === "string") // Si c'est déjà une chaîne de caractères.
				return Promise.resolve(poAttachment);
			else { // Sinon, il faut stringifier.
				const lsContent: string = typeof poAttachment.content === "string" ? poAttachment.content : JSON.stringify(poAttachment.content);
				// Si c'est un `IAttachment`, alors on enregistre le fichier, puis on retourne l'emplacement et nom de fichier.
				return this.writeAttachmentAsync(poAttachment.name, lsContent);
			}
		});
	}

	/** Écris dans un fichier différentes informations pour le retour utilisateur : `appId`, `deviceId`, `login`, `platform`.
	 * @returns Chemin vers le fichier.
	 */
	private addLogFileAsync(): Promise<string> {
		const loData: ILogFile = {
			appId: ConfigData.appInfo.appId,
			deviceId: ConfigData.appInfo.deviceId,
			login: ConfigData.authentication.credentials.login,
			platform: this.isvcPlatform.platform,
			logs: this.isvcPlatform.isMobileApp ? this.isvcLogger.getLast100LogEntries() : [],
			environment: ConfigData.environment.id
		};

		return this.writeAttachmentAsync(
			`logs_${ConfigData.appInfo.appId}_${this.ioDatePipe.transform(new Date(), "yyyyMMddhhmmss")}.json`,
			JSON.stringify(loData)
		);
	}

	/** Écrit une pièce jointe dans le système de fichier.
	 * @returns Adresse locale du fichier ajouté.
	 */
	private writeAttachmentAsync(psTitle: string, psContent: string): Promise<string> {
		if (!this.isvcPlatform.isMobileApp) // Dans le cas d'une webapp, on ne peut pas ajouter de pièce jointe, donc en renvoie le json stringifié.
			return Promise.resolve(JSON.stringify(psContent));
		return this.isvcFilesystem.createFileAsync(psTitle, psContent, Directory.Cache, true);
	}

	/** Affiche une popup à l'utilisateur pour savoir s'il veut joindre une capture écran ou non pour sa remarque.
	 * @param poMail Email à envoyer.
	 */
	private displayFeedbackPopup(poMail: IMailReport): Observable<void> {
		return this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				message: "Voulez-vous inclure une capture d'écran dans votre message ?",
				header: "Vos remarques",
				buttons: [
					{ text: "Annuler" },
					{ text: "Non", cssClass: "button-negative", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Oui", cssClass: "button-positive", handler: () => UiMessageService.getTruthyResponse() },
				]
			})
		)
			.pipe(mergeMap((poResponse: IUiResponse<boolean>) => this.manageFeedbackPopupResponse(poMail, poResponse)));
	}

	/** Gère la réponse de la popup :
	 * - si pas de réponse -> on annule.
	 * - si réponse négative -> on envoie le mail.
	 * - si réponse positive -> on joint une capture écran avant d'envoyer le mail.
	 * @param poMail Mail à envoyer.
	 * @param poResponse Réponse de la popup permettant de déterminer les actions à réaliser.
	 */
	private manageFeedbackPopupResponse(poMail: IMailReport, poResponse: IUiResponse<boolean>): Observable<void> {
		if (typeof poResponse.response === "boolean") { // Si on a une réponse, on continue l'envoi du mail.
			if (poResponse.response) { // Réponse positive, il faut ajouter une capture écran aux pièces jointes du mail.
				return this.takeScreenshot()
					.pipe(
						tap((poResult: IURIResult) => poMail.attachments.push(poResult.URI)),
						mapTo(undefined)
					);
			}
			else // Réponse négative, on envoie le mail tel quel, sans capture écran.
				return of(undefined);
		}
		else // Sinon, on annule l'envoi du mail.
			return EMPTY;
	}

	/** Prend une capture d'écran et l'ajoute au mail à envoyer. */
	private takeScreenshot(): Observable<IURIResult> {
		return from(this.ioScreenshot.URI(MailService.C_SCREENSHOT_QUALITY))
			.pipe(catchError(poError => { console.error("RPT.S:: Erreur lors de la capture écran : ", poError); return throwError(() => poError); }));
	}

	/** Envoie le mail.
	 * @param poEmail Email qu'il faut envoyer.
	 * @returns `OK` si tout s'est bien passé (résultat du plugin).
	 */
	private send(poEmail: IMailReport): Observable<string> {
		return from(this.ioSocialSharing.canShareViaEmail())
			.pipe(
				catchError(poError => { console.error("RPT.S:: poError canShareViaEmail() : ", poError); return throwError(() => poError); }),
				mergeMap((psResult: string) => { // psResult == psShareResult == "OK" si ça s'est bien passé.
					return from(this.ioSocialSharing.shareViaEmail(poEmail.body, poEmail.subject, poEmail.to, poEmail.cc, poEmail.bcc, poEmail.attachments))
						.pipe(mapTo(psResult));
				})
			);
	}

	/** Supprime les pièce-jointes créées avant l'envoie du mail (de type `IAttachment`). */
	private clearAttachments(paAttachments: Array<IAttachment | string>): Observable<void> {
		return from(
			paAttachments.filter((poAttachment: IAttachment | string) => typeof poAttachment !== "string")
		).pipe(
			mergeMap((poAttachment: IAttachment) => this.isvcFilesystem.removeAsync(poAttachment.name, Directory.Cache)),
			toArray(),
			mapTo(undefined)
		);
	}

	public generateBody(psBody?: string): string {
		const loAppInfo: IAppInfo = ConfigData.appInfo;
		const loInfo = {
			app: loAppInfo.appId,
			version: loAppInfo.appVersion,
			user: loAppInfo.login,
			environment: ConfigData.environment.id,
			platform: loAppInfo.platform
		};
		return `${psBody ?? ""}\n\r${JSON.stringify(loInfo)}`;
	}

	//#endregion
}