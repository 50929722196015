import { Injectable } from '@angular/core';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { CalendarNativeDateFormatter, DateFormatterParams } from 'angular-calendar';

@Injectable()
export class CustomCalendarDateFormatterService extends CalendarNativeDateFormatter {

	public override monthViewColumnHeader({ date }: DateFormatterParams): string {
		return DateHelper.transform(date, "EEEEE");
	}

	public override weekViewColumnHeader({ date }: DateFormatterParams): string {
		return DateHelper.transform(date, "EEEEE");
	}

	public override dayViewHour({ date, locale }: DateFormatterParams): string {
		return new Intl.DateTimeFormat(locale, { hour: 'numeric' }).format(date);
	}

}
