import { Injectable } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { AlertController, ToastController } from '@ionic/angular';
import { AlertButton, OverlayEventDetail } from '@ionic/core';
import { Observable, defer, firstValueFrom } from 'rxjs';
import { defaultIfEmpty, filter, map, mergeMap, tap } from 'rxjs/operators';
import { IUiResponse } from '../model/uiMessage/IUiResponse';
import { IUiMessageService } from './interfaces/IUiMessageService';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from './interfaces/ShowMessageParamsToast';
import { PlatformService } from './platform.service';

/** Service de gestion des popups et toasts à afficher. */
@Injectable({ providedIn: "root" })
export class UiMessageService implements IUiMessageService {

	//#region PROPERTIES

	public static readonly C_CANCEL_ROLE = "cancel";
	public static readonly C_BACKDROP_ROLE = "backdrop";

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des Alert. */
		public readonly ioAlertController: AlertController,
		/** Service de gestion des toasts. */
		private readonly ioToastController: ToastController,
		private readonly isvcPlatform: PlatformService
	) { }

	/** @implements */
	public showMessage(poMessageParams: ShowMessageParamsPopup | ShowMessageParamsToast): void {
		this.prepareParams(poMessageParams);

		if (poMessageParams instanceof ShowMessageParamsPopup)
			this.createPopupAsync(poMessageParams).then((poElement: HTMLIonAlertElement) => poElement.present());
		else
			this.createToastAsync(poMessageParams).then((poElement: HTMLIonToastElement) => poElement.present());
	}

	private prepareParams(poMessageParams: ShowMessageParamsPopup | ShowMessageParamsToast): void {
		if (!poMessageParams.buttons)
			poMessageParams.buttons = [];

		if (poMessageParams instanceof ShowMessageParamsPopup && !ArrayHelper.hasElements(poMessageParams.buttons))
			poMessageParams.buttons.push(UiMessageService.createOkButton());
	}

	/** @implements */
	public showAsyncMessage<T, V = any>(poMessageParams: ShowMessageParamsPopup | ShowMessageParamsToast): Observable<IUiResponse<T, V>> {
		this.prepareParams(poMessageParams);

		return defer(() => poMessageParams instanceof ShowMessageParamsPopup ?
			this.createPopupAsync(poMessageParams) : this.createToastAsync(poMessageParams)
		)
			.pipe(
				tap((poElement: HTMLIonAlertElement | HTMLIonToastElement) => poElement.present()),
				mergeMap((poElement: HTMLIonAlertElement | HTMLIonToastElement) => poElement.onDidDismiss()),
				filter((poResult: OverlayEventDetail) =>
					poResult.role !== UiMessageService.C_CANCEL_ROLE && poResult.role !== UiMessageService.C_BACKDROP_ROLE
				),
				map((poResult: OverlayEventDetail<IUiResponse<T, V> & { values: any }>): IUiResponse<T, V> =>
					(poResult.data ? { response: poResult.data.response, values: poResult.data.values } : {})
				)
			);
	}

	/** Retourne une réponse positive pour une popup. */
	public static getTruthyResponse(): IUiResponse<true> {
		return { response: true } as IUiResponse<true>;
	}

	/** Retourne une réponse négative pour une popup. */
	public static getFalsyResponse(): IUiResponse<false> {
		return { response: false } as IUiResponse<false>;
	}

	/** Retourne une réponse personnalisée.
	 * @param poResponse Réponse à retourner lors d'un clic sur l'élément.
	 */
	public static getCustomResponse<T>(poResponse: T): IUiResponse<T> {
		return { response: poResponse };
	}

	/** Crée et retourne un bouton "OK". */
	public static createOkButton(): AlertButton {
		return { text: "OK", cssClass: "button-positive" };
	}

	/** Crée et retourne une popup.
	 * @param poMessageParams Paramètres de la popup.
	 */
	public async createPopupAsync(poMessageParams: ShowMessageParamsPopup): Promise<HTMLIonAlertElement> {
		const loAlert: HTMLIonAlertElement = await this.ioAlertController.create(poMessageParams);
		const loBackButtonSubscription = this.isvcPlatform.getBackButtonSubscription(() => {
			if (loAlert.backdropDismiss)
				loAlert.dismiss();
		});

		loAlert.onDidDismiss().then(() => loBackButtonSubscription.unsubscribe());

		return loAlert;
	}

	/** Crée et retourne un toast.
	 * @param poMessageParams Paramètres du toast.
	 */
	public createToastAsync(poMessageParams: ShowMessageParamsToast): Promise<HTMLIonToastElement> {
		return this.ioToastController.create(poMessageParams);
	}

	/** Affiche une popup et retourne la réponse de l'utilisateur. */
	public showPopupMessageAsync<T, V = any>(poParams: ShowMessageParamsPopup): Promise<IUiResponse<T, V>> {
		return firstValueFrom(
			this.showAsyncMessage<T, V>(poParams).pipe(defaultIfEmpty({}))
		);
	}

	/** Affiche une popup. */
	public showPopupMessage(poParams: ShowMessageParamsPopup): void {
		return this.showMessage(poParams);
	}

	/** Affiche un toast et retourne la réponse de l'utilisateur. */
	public showToastMessageAsync<T, V>(poParams: ShowMessageParamsToast): Promise<IUiResponse<T, V>> {
		return firstValueFrom(
			this.showAsyncMessage<T, V>(poParams).pipe(defaultIfEmpty({}))
		);
	}

	/** Affiche un toast. */
	public showToastMessage(poParams: ShowMessageParamsToast): void {
		return this.showMessage(poParams);
	}

	//#endregion

}