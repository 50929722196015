import { ObjectHelper } from "../../../utils/helpers/objectHelper";

export class MoveToObservableArrayError extends Error {

	constructor(pnFromIndex: number, pnToIndex: number, pnArrayLength: number) {
		super(`Cannot move element from index ${pnFromIndex} to the index ${pnToIndex} in a array with ${pnArrayLength} elements.`);
		// Nécessaire pour que le 'instanceof MoveToObservableArrayError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, MoveToObservableArrayError);
	}

}