import { ClassConstructor, classToPlain, plainToClass } from 'class-transformer';
import { StoreHelper } from '../../store/helpers/store-helper';
import { ICacheData } from '../../store/models/icache-data';
import { IStoreDocument } from '../../store/models/istore-document';
import { ArrayHelper } from '../helpers/arrayHelper';
import { ObjectHelper } from '../helpers/objectHelper';
import { IConstructor } from './iconstructor';

interface ConstructorWithMatcher {
	match: (poData: any) => boolean;
	constructor: ClassConstructor<any>;
}

export abstract class ModelResolver {

	//#region FIELDS

	private static readonly C_EXCLUDED_PREFIXES: string[] = ["#"];

	/** Cache des classes triées par classe de base. */
	private static readonly moClassStore: Map<IConstructor<any>, ConstructorWithMatcher[]> = new Map<IConstructor<any>, ConstructorWithMatcher[]>();

	//#endregion

	//#region METHODS

	/** Ajoute une classe au cache.
	 * @param pfMatcher
	 * @param poConstructor
	 * @param poBaseType
	 */
	public static addClass<T, V extends T>(pfMatcher: (poData: V) => boolean, poConstructor: ClassConstructor<V>, poBaseType: IConstructor<T>): void {
		const laConstructorWithMatchers: ConstructorWithMatcher[] = this.moClassStore.get(poBaseType) ?? [];
		laConstructorWithMatchers.push({ constructor: poConstructor, match: pfMatcher });
		this.moClassStore.set(poBaseType, laConstructorWithMatchers);
	}

	/** Transforme la donnée en instance de classe. Si pas de type trouvé, utilise `poBaseType`.
	 * @param poData
	 * @param poBaseType
	 */
	public static toClass<T, V = any>(poBaseType: IConstructor<V>, poData?: T): V {
		let loConstructor: ClassConstructor<V>;

		if (poData) {
			const laCachedConstructors: ClassConstructor<V>[] = [];
			do { // On boucle pour être capable de gérer l'héritage sur plusieurs niveaux
				if (laCachedConstructors.includes(loConstructor)) // Si le constructeur se trouve dans le tableau de cache, alors on est dans le cas d'une boucle, donc on stop.
					break;
				else if (loConstructor)
					laCachedConstructors.push(loConstructor);

				loConstructor = ArrayHelper.getLastElement(this.moClassStore.get(ArrayHelper.getLastElement(laCachedConstructors) ?? poBaseType)
					?.filter((poConstructorWithMatcher: ConstructorWithMatcher) => poConstructorWithMatcher.match(poData)))
					?.constructor;
			}
			while (loConstructor);

			loConstructor = ArrayHelper.getLastElement(laCachedConstructors); // Le dernier constructeur du cache est le plus précis.
		}
		else
			return undefined;

		const loCacheData: ICacheData = StoreHelper.deleteDocumentCacheData(poData as IStoreDocument); // On supprime temporairement les cacheData pour pouvoir faire le plainToClass.
		let loResolvedData: V;

		if (loConstructor) {
			loResolvedData = poData instanceof loConstructor ?
				poData :
				plainToClass(loConstructor, poData, { exposeDefaultValues: true }); // exposeDefaultValues permet d'éviter l'écrasement de valeurs par défaut par undefined.
		}
		else {
			loResolvedData = (
				poData instanceof poBaseType ?
					poData :
					plainToClass(poBaseType as any, poData, { exposeDefaultValues: true })
			) as any as V;
		}

		if (loCacheData) { // On remet les cacheData pour le nouveau modèle et pour la donnée source
			StoreHelper.updateDocumentCacheData(loResolvedData as IStoreDocument, loCacheData);
			StoreHelper.updateDocumentCacheData(poData as IStoreDocument, loCacheData);
		}

		return loResolvedData;
	}

	/** Transforme une instance d'objet en objet JS pur.
	 * @param poDoc Objet qu'il faut transformer en objet JS pur.
	 */
	public static toPlain<T>(poDoc: T): T;
	/** @deprecated Transforme une instance d'objet en objet JS pur.
	 * @param poDoc Objet qu'il faut transformer en objet JS pur.
	 * @param pbForced On doit forcer la transformation en objet JS pur.
	 */
	public static toPlain<T>(poDoc: T, pbForced: true): T;
	public static toPlain<T>(poDoc: T, pbForced?: boolean): T {
		return ObjectHelper.isSerializable(poDoc) && !pbForced ? poDoc : classToPlain(poDoc, { excludePrefixes: ModelResolver.C_EXCLUDED_PREFIXES }) as T;
	}

	//#endregion

}