import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactHelper } from '../../../../helpers/contactHelper';
import { UserHelper } from '../../../../helpers/user.helper';
import { FieldBase } from '../../../forms/models/FieldBase';
import { FormsService } from '../../../forms/services/forms.service';
import { BaseEventOccurrence } from '../../models/base-event-occurrence';
import { EEventParticipationStatus } from '../../models/eevent-participation-status';
import { IEventParticipantStatus } from '../../models/ievent-participant-status';
import { CalendarEventsParticipationService } from '../../services/calendar-events-participation.service';

@Component({
	selector: 'calao-event-participants-field',
	templateUrl: './event-participants-field.component.html',
	styleUrls: ['./event-participants-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'",
		"[style.flex-direction]": "'column'"
	}
})
export class EventParticipantsFieldComponent extends FieldBase<IEventParticipantStatus[], BaseEventOccurrence> {

	//#region PROPERTIES

	public readonly organizerLabel$: Observable<string> = this.getEventParticipantStatusLabel$(
		(poEventParticipantStatus: IEventParticipantStatus) => poEventParticipantStatus.organizer
	).pipe(secure(this));

	public readonly acceptedLabel$: Observable<string> = this.getEventParticipantStatusLabel$(
		(poEventParticipantStatus: IEventParticipantStatus) => this.isAccepted(poEventParticipantStatus)
	).pipe(secure(this));

	public readonly acceptedLength$: Observable<string> = this.getEventParticipantStatusLengthLabel$(
		(poEventParticipantStatus: IEventParticipantStatus) => this.isAccepted(poEventParticipantStatus)
	).pipe(secure(this));

	public readonly rejectedLabel$: Observable<string> = this.getEventParticipantStatusLabel$(
		(poEventParticipantStatus: IEventParticipantStatus) => this.isRejected(poEventParticipantStatus)
	).pipe(secure(this));

	public readonly rejectedLength$: Observable<string> = this.getEventParticipantStatusLengthLabel$(
		(poEventParticipantStatus: IEventParticipantStatus) => this.isRejected(poEventParticipantStatus)
	).pipe(secure(this));

	public readonly waitingLabel$: Observable<string> = this.getEventParticipantStatusLabel$(
		(poEventParticipantStatus: IEventParticipantStatus) => this.isWaiting(poEventParticipantStatus)
	).pipe(secure(this));

	public readonly waitingLength$: Observable<string> = this.getEventParticipantStatusLengthLabel$(
		(poEventParticipantStatus: IEventParticipantStatus) => this.isWaiting(poEventParticipantStatus)
	).pipe(secure(this));

	public readonly userParticipantStatus$: Observable<IEventParticipantStatus> = this.getUserEventParticipantStatus$()
		.pipe(secure(this));

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcParticipation: CalendarEventsParticipationService,
		psvcForms: FormsService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetector);
	}

	private getEventParticipantStatusLabel$(pfFilter: (poEventParticipantStatus: IEventParticipantStatus) => boolean): Observable<string> {
		return this.getEventParticipantStatus$(pfFilter).pipe(map((paEventParticipantStatus: IEventParticipantStatus[]) =>
			paEventParticipantStatus
				.map((poEventParticipantStatus: IEventParticipantStatus) => ContactHelper.getCompleteFormattedName(poEventParticipantStatus.participant))
				.join(", ")
		));
	}

	private getEventParticipantStatusLengthLabel$(pfFilter: (poEventParticipantStatus: IEventParticipantStatus) => boolean): Observable<string> {
		return this.getEventParticipantStatus$(pfFilter).pipe(map((paEventParticipantStatus: IEventParticipantStatus[]) =>
			`${paEventParticipantStatus.length}`
		));
	}

	private getEventParticipantStatus$(pfFilter: (poEventParticipantStatus: IEventParticipantStatus) => boolean): Observable<IEventParticipantStatus[]> {
		return this.observableFieldValue.value$.pipe(map((paEventParticipantStatus: IEventParticipantStatus[]) =>
			paEventParticipantStatus.filter((poEventParticipantStatus: IEventParticipantStatus) => pfFilter(poEventParticipantStatus))
		));
	}

	private getUserEventParticipantStatus$(): Observable<IEventParticipantStatus> {
		return this.observableFieldValue.value$.pipe(map((paEventParticipantStatus: IEventParticipantStatus[]) => {
			const lsUserContactId: string = UserHelper.getUserContactId();
			return paEventParticipantStatus.find((poEventParticipantStatus: IEventParticipantStatus) => poEventParticipantStatus.participantId === lsUserContactId);
		}));
	}

	private isWaiting(poEventParticipantStatus: IEventParticipantStatus): boolean {
		return this.filterByStatus(poEventParticipantStatus, EEventParticipationStatus.waiting);
	}

	private filterByStatus(poEventParticipantStatus: IEventParticipantStatus, peStatus: EEventParticipationStatus): boolean {
		return !poEventParticipantStatus.organizer && poEventParticipantStatus.status === peStatus;
	}

	private isRejected(poEventParticipantStatus: IEventParticipantStatus): boolean {
		return this.filterByStatus(poEventParticipantStatus, EEventParticipationStatus.denied);
	}

	private isAccepted(poEventParticipantStatus: IEventParticipantStatus): boolean {
		return this.filterByStatus(poEventParticipantStatus, EEventParticipationStatus.accepted);
	}

	public accept(): void {
		this.acceptAsync();
	}

	private acceptAsync(): Promise<void> {
		return this.isvcParticipation.acceptAsync(this.model);
	}

	public reject(): void {
		this.rejectAsync();
	}

	private rejectAsync(): Promise<void> {
		return this.isvcParticipation.rejectAsync(this.model);
	}

	public requireResponse(poParticipantStatus: IEventParticipantStatus): boolean {
		return poParticipantStatus.status === EEventParticipationStatus.waiting && !poParticipantStatus.organizer;
	}

	//#endregion

}
