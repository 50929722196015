import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { Directory } from '@capacitor/filesystem';
import { EFileError } from '../efile-error';
import { FilesystemErrorBase } from './filesystem-error-base';

export class FilesystemCreateError extends FilesystemErrorBase {

	//#region PROPERTIES

	public readonly errorCode: EFileError;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poError: any, peErrorCode: EFileError, psPath: string, peDirectory: Directory) {
		super(poError, `Can not create from '${psPath}' in directory '${peDirectory}', error code is '${peErrorCode}'.`);
		// Nécessaire pour que le 'instanceof FilesystemCreateError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemCreateError);

		this.errorCode = peErrorCode;
	}

	//#endregion METHODS

}