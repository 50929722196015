import { ObjectHelper } from "@calaosoft/osapp-common/utils/helpers/objectHelper";
import { Directory } from "@capacitor/filesystem";
import { FilesystemErrorBase } from "./filesystem-error-base";

export class FilesystemGetFileUriError extends FilesystemErrorBase {

	constructor(poError: any, psPath: string, peDirectory: Directory) {
		super(poError, `Can not get file uri from '${psPath}' in directory '${peDirectory}'.`);
		// Nécessaire pour que le 'instanceof FilesystemGetFileUriError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemGetFileUriError);
	}

}