import { Type } from "@angular/core";
import { Exclude } from "@calaosoft/osapp-common/class-transformer";
import { DateHelper } from "@calaosoft/osapp-common/dates/helpers/dateHelper";
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { BaseEvent } from "@calaosoft/osapp/modules/calendar-events/models/base-event";
import { EventState } from "@calaosoft/osapp/modules/calendar-events/models/event-state";
import { IEvent } from "@calaosoft/osapp/modules/calendar-events/models/ievent";
import { ETaskStatus } from "./etask-status";
import { ETradeEventType } from "./etrade-event-type";
import { ITask } from "./itrade-task";
import { TaskOccurrence } from "./task-occurrence";

const C_TYPE = ETradeEventType.task;
@ModelMatch((poData: IEvent) => poData?.eventType === C_TYPE, BaseEvent)
export class Task extends BaseEvent<TaskOccurrence> implements ITask {

	//#region PROPERTIES

	@Exclude()
	protected override occurrenceType: Type<TaskOccurrence> = TaskOccurrence;
	public priority: number;
	public achievement: number;
	public override eventType: string = ETradeEventType.task;
	public override status: ETaskStatus = ETaskStatus.todo;
	public deadline?: string | Date;
	@Exclude()
	public override themeColor = "var(--ion-color-task)";

	//#endregion

	//#region METHODS

	/**
	 * Génère la prochaine occurrence de cette tâche, si elle existe.
	 * Pour les tâches, la prochaine occurrence est déterminée à partir des documents evtState présents en base.
	 * On recherche le document qui a été mis à jour le plus récemment et on vérifie son champ lastEndedDate.
	 * Si le champ est absent, alors la récurrence est terminée et aucune occurrence ne sera générée.
	 * @returns L'occurrence générée, ou undefined si aucune
	 */
	public override generateNextOccurrence(
		pdDate?: Date,
		psRev?: string
	): TaskOccurrence | undefined {
		const loLastState: EventState | undefined = this.getLastState();

		// Si un document d'état existe, on génère la prochaine occurrence à partir de celui-ci
		if (loLastState) {
			// Si la récurrence est terminée, le champ nextOccurrence de l'état le plus récent doit être undefined, et donc aucune occurrence ne sera générée ici.
			if (loLastState.status !== ETaskStatus.done)
				return super.generateNextOccurrence(loLastState.lastEndedDate ?? this.startDate, psRev);
			else
				return undefined;
		}
		// Sinon, c'est qu'il s'agit d'une création, on génère la première occurence à l'aide de la date de début
		else
			return this.generateOccurrence(this.startDate, psRev);

	}

	public override getRoute(pdDate: Date | undefined = this.startDate): string {
		if (!pdDate)
			return `agenda/todo/tasks/${IdHelper.getGuidFromId(this._id, EPrefix.event)}${this._rev ? `?rev=${this._rev}` : ""}`;

		return `agenda/tasks/${IdHelper.getGuidFromId(this._id, EPrefix.event)}/occurrences/${DateHelper.toUTCString(pdDate)}${this._rev ? `?rev=${this._rev}` : ""}`;
	}

	//#endregion
}