import { NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { StarRatingModule } from 'angular-star-rating';
import { ContactsModule as ContactsModuleB } from '../../../components/contacts/contacts.module';
import { DateModule } from '../../../components/date/date.module';
import { FilePickerModule } from '../../../components/filePicker/filePicker.module';
import { GalleryModule } from '../../../components/gallery/gallery.module';
import { LinkModule } from '../../../components/link/link.module';
import { LinkedItemsListModule } from '../../../components/linkedItemsList/linkedItemsList.module';
import { SharedComponentsModule } from '../../../components/sharedComponents.module';
import { CameraModule } from '../../../modules/camera/camera.module';
import { ColorsModule } from '../../../modules/colors/colors.module';
import { ConversationsModule } from '../../../modules/conversations/conversations.module';
import { DrawingModule } from '../../../modules/drawing/drawing.module';
import { SectorsModule } from '../../../modules/sectors/sectors.module';
import { SelectorModule } from '../../../modules/selector/selector.module';
import { BlankInformationModule } from '../../blank-information/blank-information.module';
import { ContactsModule } from '../../contacts/contacts.module';
import { DashboardModule } from '../../dashboard/dashboard.module';
import { EntitySelectorComponent } from '../../entities/components/entity-selector/entity-selector.component';
import { FilterModule } from '../../filter/filter.module';
import { GroupsModule } from '../../groups/groups.module';
import { HeaderModule } from '../../header';
import { ListsModule } from '../../lists/lists.module';
import { ModalModule } from '../../modal/modal.module';
import { PermissionsModule } from '../../permissions/permissions.module';
import { SliderModule } from '../../slider/slider.module';
import { TagsModule } from '../../tags/tags.module';
import { TourOptimizationModule } from '../../tour-optimization/tour-optimization.module';
import { FormsService } from '../services/forms.service';
import { NotificationAddModalComponent } from './components/customFields/event-notification-field/components/notification-add-modal/notification-add-modal.component';
import { GroupsFieldModalComponent } from './components/customFields/groups-field/groups-field-modal/groups-field-modal.component';
import { InlineFieldLayoutBottomContentComponent } from './components/customFields/inline-field-layout/inline-field-layout-bottom-content/inline-field-layout-bottom-content.component';
import { InlineFieldLayoutContentComponent } from './components/customFields/inline-field-layout/inline-field-layout-content/inline-field-layout-content.component';
import { InlineFieldLayoutComponent } from './components/customFields/inline-field-layout/inline-field-layout.component';
import { InlineListFieldLayoutComponent } from './components/customFields/inline-list-field-layout/inline-list-field-layout.component';
import { InlineReadonlyFieldLayoutContentComponent } from './components/customFields/inline-readonly-field-layout-content/inline-readonly-field-layout-content.component';
import { InlineReadonlyFieldLayoutComponent } from './components/customFields/inline-readonly-field-layout/inline-readonly-field-layout.component';
import { RadioAndCheckboxFieldModalComponent } from './components/customFields/radio-and-checkbox-field/radio-and-checkbox-field-modal/radio-and-checkbox-field-modal.component';
import { RadioAndCheckboxFieldComponent } from './components/customFields/radio-and-checkbox-field/radio-and-checkbox-field.component';
import { RecurrencesFieldModalContentComponent } from './components/customFields/recurrences-field/recurrences-field-modal-content/recurrences-field-modal-content.component';
import { SectorBusinessesListFieldComponent } from './components/customFields/sector-businesses-list/sector-businesses-list-field.component';
import { SectorBusinessesListComponent } from './components/customFields/sector-businesses-list/sector-businesses-list.component';
import { FormlyWrapperComponent } from './components/formly-wrapper/formly-wrapper.component';
import { InlineFieldLayoutTagsContentComponent } from './components/inline-field-layout/inline-field-layout-tags-content/inline-field-layout-tags-content.component';
import { EventOptmizedModalComponent } from './components/modals/event-optmized-input-modal.component';
import { C_FORMLY_CUSTOM, FormlyConfig } from './fields.config';

const modules: Array<Type<any>> = [
	SharedComponentsModule,
	LinkModule,
	GalleryModule,
	FilePickerModule,
	ContactsModule,
	ContactsModuleB,
	DateModule,
	LinkedItemsListModule,
	MatInputModule,
	MatChipsModule,
	ConversationsModule,
	CameraModule,
	ColorsModule,
	SectorsModule,
	SelectorModule,
	DrawingModule,
	ReactiveFormsModule,
	FormlyMaterialModule,
	PermissionsModule,
	ModalModule,
	HeaderModule,
	BlankInformationModule,
	SliderModule,
	GroupsModule,
	ListsModule,
	TourOptimizationModule,
	TagsModule,
	FilterModule,
	MatProgressBarModule,
	DashboardModule
];
const components: Array<Type<any>> = [
	FormlyWrapperComponent,
	EventOptmizedModalComponent,
	NotificationAddModalComponent,
	EntitySelectorComponent,
	SectorBusinessesListComponent,
	SectorBusinessesListFieldComponent,
	InlineFieldLayoutComponent,
	InlineFieldLayoutContentComponent,
	InlineFieldLayoutBottomContentComponent,
	InlineFieldLayoutTagsContentComponent,
	InlineListFieldLayoutComponent,
	RecurrencesFieldModalContentComponent,
	RadioAndCheckboxFieldComponent,
	RadioAndCheckboxFieldModalComponent,
	GroupsFieldModalComponent,
	InlineReadonlyFieldLayoutComponent,
	InlineReadonlyFieldLayoutContentComponent
];

/** Module de gestion des formulaires. */
@NgModule({
	exports: components,
	providers: [FormsService],
	declarations: [C_FORMLY_CUSTOM, ...components],
	imports: [...modules, FormlyModule.forChild(FormlyConfig), StarRatingModule.forRoot()]
})
export class FormModule { }