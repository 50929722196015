import { ObjectHelper } from "../../utils/helpers/objectHelper";

export class BadDatabaseIdError extends Error {

	constructor(psDatabaseId: string) {
		super(`L'identifiant de base de données "${psDatabaseId}" n'est pas un identifiant valide !`);
		// Nécessaire pour que le 'instanceof BadDatabaseIdError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, BadDatabaseIdError);
	}

}