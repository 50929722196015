import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { EDateTimePickerMode } from '@calaosoft/osapp-common/dates/models/EDateTimePickerMode';
import { ETimetablePattern } from '@calaosoft/osapp-common/dates/models/ETimetablePattern';
import { IDateTimePickerParams } from '@calaosoft/osapp-common/dates/models/IDateTimePickerParams';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { IonInput, ModalOptions } from '@ionic/angular';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, shareReplay, startWith, takeUntil, tap } from 'rxjs/operators';
import { ERecurrenceType } from '../../../../../calendar-events/models/erecurrence-type';
import { Recurrence } from '../../../../../calendar-events/models/recurrence';
import { CustomIonInputEvent, CustomIonSelectEvent, CustomIonToggleEvent } from '../../../../../ionic/models/icustom-ion-input-event';
import { EModalSize } from '../../../../../modal';
import { ModalService } from '../../../../../modal/services/modal.service';
import { ESelectorDisplayMode } from '../../../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../../../selector/selector/ISelectOption';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineFieldLayoutParams } from '../inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from '../inline-field-layout/models/iinlineField';
import { EEndDateMode } from './models/eend-date-mode';
import { IRecurrenceFieldModal } from './models/irecurrence-field-modal';
import { RecurrencesFieldModalContentComponent } from './recurrences-field-modal-content/recurrences-field-modal-content.component';

interface IRecurrencesParams extends IInlineField {
	startDateKey: string;
	readOnly?: boolean;
}

@Component({
	selector: 'calao-recurrences-field',
	templateUrl: './recurrences-field.component.html',
	styleUrls: ['./recurrences-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecurrencesFieldComponent extends FieldBase<Recurrence[]> implements OnInit, IInlineField {

	//#region FIELDS

	private static readonly C_EVERY_N_MIN = 1;

	@ViewChild("normal") private readonly moPopupTemplate: TemplateRef<any>;

	private readonly moObservableRecurrence = new ObservableProperty<Recurrence>().bind(this.observableFieldValue.value$.pipe(
		startWith([]), // On force pour avoir ensuite les valeurs par défaut
		map((paRecurrences: Recurrence[]) => ArrayHelper.getFirstElement(paRecurrences))
	), this);

	private moCache: Recurrence;

	//#endregion

	//#region PROPERTIES

	/** Style de layout à afficher (undefined par défaut). */
	@Input() public layout: "inline";
	@ObserveProperty<RecurrencesFieldComponent>({ sourcePropertyKey: "layout" })
	public readonly observableLayout = new ObservableProperty<"inline">();

	/** Paramètres d'affichage à passer au layout. */
	@Input() public layoutParams: IInlineFieldLayoutParams;
	@ObserveProperty<RecurrencesFieldComponent>({ sourcePropertyKey: "layoutParams" })
	public readonly observableLayoutParams = new ObservableProperty<IInlineFieldLayoutParams>();

	/** Masque le libellé du champs dans le layout si `true` et que le champs est renseigné. */
	@Input() public hideLabelWhenFilled: boolean;
	@ObserveProperty<RecurrencesFieldComponent>({ sourcePropertyKey: "hideLabelWhenFilled" })
	public readonly observableHideWhenFilled = new ObservableProperty<boolean>();

	/** Masque le libellé du champs. */
	public readonly observableHideLabel = new ObservableProperty<boolean>().bind(
		combineLatest([this.observableHideWhenFilled.value$, this.observableFieldValue.value$]).pipe(
			map(([pbHideLabelWhenFilled, paRecurrences]: [boolean, Recurrence[]]) => pbHideLabelWhenFilled && ArrayHelper.hasElements(paRecurrences))
		),
		this
	);

	public readonly recurrenceTypeSelectOptions: ISelectOption<ERecurrenceType>[] = [
		{
			label: "Semaine(s)",
			value: ERecurrenceType.weekly
		},
		{
			label: "Jour(s)",
			value: ERecurrenceType.daily
		},
		{
			label: "Mois",
			value: ERecurrenceType.monthly
		},
		{
			label: "An(s)",
			value: ERecurrenceType.yearly
		}
	];

	public readonly weekDaysSelectOptions: ISelectOption<number>[] = [
		{
			label: "L",
			value: 0
		},
		{
			label: "M",
			value: 1
		},
		{
			label: "M",
			value: 2
		},
		{
			label: "J",
			value: 3
		},
		{
			label: "V",
			value: 4
		},
		{
			label: "S",
			value: 5
		},
		{
			label: "D",
			value: 6
		},
	];

	public readonly endDateModes = EEndDateMode;

	public datePickerParams: IDateTimePickerParams = {
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MMMM_yyyy,
		hideIcon: true,
		label: "Le"
	};
	/** Mode de sélection. */
	public selectorDisplayMode = ESelectorDisplayMode;

	public readonly observableActivation = new ObservableProperty<boolean>(true).bind(this.moObservableRecurrence.value$.pipe(
		map((poRecurrence: Recurrence) => !!poRecurrence)
	), this);

	public readonly observableRecurrenceType = new ObservableProperty<ERecurrenceType>().bind(this.moObservableRecurrence.value$.pipe(
		map((poRecurrence: Recurrence) => poRecurrence?.type ?? ERecurrenceType.weekly)
	), this);

	public readonly observableEveryN = new ObservableProperty<number>().bind(this.moObservableRecurrence.value$.pipe(
		map((poRecurrence: Recurrence) => poRecurrence?.every ?? RecurrencesFieldComponent.C_EVERY_N_MIN)
	), this);

	public readonly observableWeekDays = new ObservableProperty<number[]>().bind(this.moObservableRecurrence.value$.pipe(
		map((poRecurrence: Recurrence) => poRecurrence?.weekDays ?? [this.getDay()])
	), this);

	public readonly observableStartDate = new ObservableProperty<Date>().bind(this.moObservableRecurrence.value$.pipe(
		map((poRecurrence: Recurrence) => poRecurrence?.startDate)
	), this);

	public readonly observableEndDate = new ObservableProperty<Date>().bind(this.moObservableRecurrence.value$.pipe(
		map((poRecurrence: Recurrence) => poRecurrence?.endDate ?? poRecurrence?.startDate ?? new Date)
	), this);

	public readonly observableEndDateMode = new ObservableProperty<EEndDateMode>().bind(this.moObservableRecurrence.value$.pipe(
		map((poRecurrence: Recurrence) => poRecurrence?.endDate ? EEndDateMode.end : EEndDateMode.noEnd)
	), this);

	public observableEndTimePickerParams = new ObservableProperty<IDateTimePickerParams>().bind(
		this.observableStartDate.value$.pipe(map((pdStartDate: Date) => ({ ...this.datePickerParams, min: pdStartDate?.toISOString() }))),
		this
	);

	public readonly observableReadonly = new ObservableProperty<boolean>;

	//#region READONLY

	public readonly observableEveryNLabel = new ObservableProperty<string>()
		.bind(combineLatest([this.observableRecurrenceType.value$, this.observableEveryN.value$]).pipe(
			map(() => this.moObservableRecurrence.value?.everyNLabel)
		), this);

	public readonly observableWeekDaysLabel = new ObservableProperty<string>()
		.bind(this.observableWeekDays.value$.pipe(
			map(() => this.moObservableRecurrence.value?.weekDaysShortLabel)
		), this);

	public readonly observableDatesLabel = new ObservableProperty<string>()
		.bind(combineLatest([
			this.observableStartDate.value$,
			this.observableEndDate.value$,
			this.observableEndDateMode.value$
		]).pipe(
			map(() => this.moObservableRecurrence.value?.datesLabel)
		), this);

	public readonly displayWeekDaySelector$: Observable<boolean> = this.observableRecurrenceType.value$.pipe(
		map((peRecurrenceType: ERecurrenceType) => peRecurrenceType !== ERecurrenceType.daily),
		takeUntil(this.destroyed$),
		shareReplay(1)
	);

	/** Libellé de la récurrence. */
	public readonly observableRecurrenceLabel = new ObservableProperty<string>().bind(
		this.observableFieldValue.value$.pipe(map(() => this.getRecurrenceLabel())),
		this
	);

	//#endregion READONLY

	//#endregion

	//#region METHODS

	public constructor(
		private readonly isvcModal: ModalService,
		protected override readonly isvcForms: FormsService
	) {
		super(isvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		const loParams: IRecurrencesParams = this.to.data;
		this.observableReadonly.value = !!loParams?.readOnly;
		this.observableLayout.value = loParams.layout;
		this.observableLayoutParams.value = loParams.layoutParams;
		this.observableHideWhenFilled.value = loParams.hideLabelWhenFilled;

		const loStartDateControl: AbstractControl<Date> = this.form.controls[loParams?.startDateKey];

		if (loStartDateControl) {
			loStartDateControl.valueChanges.pipe(
				startWith(loStartDateControl.value),
				filter((pdStartDate: Date) => DateHelper.isDate(pdStartDate)),
				map((pdValue: Date) => new Date(pdValue)),
				tap((pdStartDate: Date) => this.onStartDateChanged(pdStartDate)),
				takeUntil(this.destroyed$)
			).subscribe();
		}
	}

	public onRecurrenceTypeChanged(paRecurrenceTypes: ERecurrenceType[]): void {
		this.observableRecurrenceType.value = this.moObservableRecurrence.value.type = ArrayHelper.getFirstElement(paRecurrenceTypes);

		if (this.observableRecurrenceType.value === ERecurrenceType.daily)
			this.onWeekDaysChanged(this.weekDaysSelectOptions.map((poOption: ISelectOption<number>) => poOption.value));


		this.onValueChanged();
	}

	public onEveryChanged(poEvent: Event, poInput: IonInput): void {
		const loEvent: CustomIonInputEvent = poEvent as CustomIonInputEvent;
		const lnEvery: number = +loEvent.detail.value;
		this.observableEveryN.value = this.moObservableRecurrence.value.every =
			NumberHelper.isValidStrictPositive(lnEvery) ? lnEvery : RecurrencesFieldComponent.C_EVERY_N_MIN;

		if (loEvent.detail.value !== "") {
			poInput.value = this.observableEveryN.value;
			this.detectChanges();
		}

		this.onValueChanged();
	}

	public onWeekDaysChanged(paWeekDays: number[]): void {
		this.observableWeekDays.value = this.moObservableRecurrence.value.weekDays = paWeekDays;
		this.onValueChanged();
	}

	public onEndModeChanged(poEvent: Event): void {
		this.observableEndDateMode.value = (poEvent as CustomIonSelectEvent<EEndDateMode>).detail.value;

		if (this.observableEndDateMode.value === EEndDateMode.noEnd)
			this.observableEndDate.value = this.moObservableRecurrence.value.endDate = undefined;
		else
			this.observableEndDate.value = this.moObservableRecurrence.value.endDate = this.moObservableRecurrence.value.startDate ?? new Date;

		this.onValueChanged();
	}

	public onEndDateChanged(pdEndDate: Date): void {
		this.observableEndDate.value = this.moObservableRecurrence.value.endDate = pdEndDate;
		this.onValueChanged();
	}

	public onStartDateChanged(pdStartDate: Date): void {
		this.observableStartDate.value = pdStartDate;

		if (this.moObservableRecurrence.value)
			this.moObservableRecurrence.value.startDate = pdStartDate;

		if (this.moCache)
			this.moCache.startDate = pdStartDate;

		if (DateHelper.compareTwoDates(this.observableStartDate.value, this.observableEndDate.value) > 0)
			this.observableEndDate.value = this.observableStartDate.value;

		this.onValueChanged();
	}

	public onActivationChanged(poEvent: Event): void {
		if ((poEvent as CustomIonToggleEvent).detail.checked)
			this.activateReccurrence();
		else
			this.deactivateRecurrence();
	}

	private deactivateRecurrence(): void {
		this.moCache = this.moObservableRecurrence.value;
		this.fieldValue = [];
	}

	private activateReccurrence(): void {
		this.fieldValue = [this.moCache ?? new Recurrence({
			every: 1,
			type: ERecurrenceType.weekly,
			startDate: this.observableStartDate.value,
			weekDays: [this.getDay()]
		})];
		this.moCache = undefined;
	}

	private getDay(): number {
		const lnDay: number = new Date().getDay();

		if (lnDay > 0)
			return lnDay - 1;
		else
			return 6;
	}

	private onValueChanged(): void {
		this.formControl.patchValue(this.fieldValue); // On force le rafraîchissement du model
	}

	public onFieldClicked(): void {
		this.showModal$().subscribe();
	}

	@Queue<RecurrencesFieldComponent, Parameters<RecurrencesFieldComponent["showModal$"]>, ReturnType<RecurrencesFieldComponent["showModal$"]>>({
		excludePendings: true
	})
	private showModal$(): Observable<any> {
		const loModalOptions: ModalOptions = {
			component: RecurrencesFieldModalContentComponent,
			componentProps: {
				template: this.moPopupTemplate,
			} as IRecurrenceFieldModal,
			canDismiss: true,
			cssClass: "transparent"
		}

		if (!this.fieldValue)
			this.activateReccurrence();

		return this.isvcModal.open(loModalOptions, EModalSize.medium)
			.pipe(
				filter((poResult: any) => !poResult),
				tap(() => {
					this.observableRecurrenceLabel.value = this.getRecurrenceLabel();
				})
			);
	}

	private getRecurrenceLabel(): string {
		const loRecurrence: Recurrence | undefined = ArrayHelper.getFirstElement(this.observableFieldValue.value);
		let lsRecurrenceLabel: string;

		if (loRecurrence) {
			lsRecurrenceLabel = loRecurrence.label;
		}

		return lsRecurrenceLabel;
	}

	//#endregion

}
