import { Transform } from "@calaosoft/osapp-common/class-transformer";
import { PathHelper } from "../../../helpers/path-helper";
import { IDocumentFields } from "./idocument-fields";

export class DocumentFields implements IDocumentFields {

	//#region PROPERTIES

	/** @implements */
	public name: string;
	/** @implements */
	public authorId: string;
	/** @implements */
	public createDate: string;
	/** @implements */
	public displayDateField = "createDate";
	/** @implements */
	public get displayDate(): string | undefined { return this[this.displayDateField]; };
	/** @implements */
	@Transform(({ value }: { value: string[] }): string[] => value.map((poValue: string) => PathHelper.parsePath(poValue)) ?? [], { toClassOnly: true })
	@Transform(({ value }: { value: string[] }): string[] => value.map((poValue: string) => PathHelper.preparePath(poValue)) ?? [], { toPlainOnly: true })
	public paths: string[];

	//#endregion PROPERTIES

}
