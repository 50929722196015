import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ModelResolver } from '@calaosoft/osapp-common/utils/models/model-resolver';
import { Observable, Subject, take, tap } from 'rxjs';
import { EGalleryDisplayMode } from '../../../../../components/gallery/models/EGalleryDisplayMode';
import { IGalleryCommand } from '../../../../../model/gallery/IGalleryCommand';
import { IGalleryFile } from '../../../../../model/gallery/IGalleryFile';
import { GalleryFile } from '../../../../../model/gallery/gallery-file';
import { IDeletableTag } from '../../../../tags/models/ideletable-tag';
import { FieldBase } from '../../../models/FieldBase';
import { IGalleryFieldParams } from '../../../models/IGalleryFieldParams';
import { FormsService } from '../../../services/forms.service';
import { IInlineListField } from './inline-list-field-layout/models/iinline-list-field';
import { IInlineListFieldLayoutParams } from './inline-list-field-layout/models/iinline-list-field-layout-params';

@Component({
	selector: 'calao-gallery-field',
	templateUrl: './galleryField.component.html',
})
export class GalleryFieldComponent extends FieldBase<IGalleryFile[]> implements OnInit, OnDestroy, IInlineListField {

	//#region FIELDS

	private readonly moGalleryCommandSubject = new Subject<IGalleryCommand>();

	//#endregion

	//#region PROPERTIES

	public readonly galleryCommand$: Observable<IGalleryCommand>;
	public readonly observableTags = new ObservableArray<IDeletableTag>([]);

	/** Paramètres que l'on peut fournir à ce composant. */
	public params: IGalleryFieldParams & IInlineListField;
	public layout: 'inline';
	public layoutParams: IInlineListFieldLayoutParams;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService, poChangeDetector: ChangeDetectorRef) {
		super(psvcForms, poChangeDetector);
		this.galleryCommand$ = this.moGalleryCommandSubject.asObservable();
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;
		this.layout = this.params.layout;
		this.layoutParams = this.params.layoutParams;

		if (this.layout === "inline") {
			this.params.displayMode = EGalleryDisplayMode.add;
			this.params.filePickerVisible = false;
			this.params.imagePickerVisible = false;
			this.params.cameraButtonVisible = false;
		}

		this.observableFieldValue.value$.pipe(
			take(1),
			tap((paFiles?: IGalleryFile[]) => this.observableTags.resetArray(this.getTagsFromFiles(paFiles)))
		).subscribe();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moGalleryCommandSubject.complete();
	}

	public onFilesChanged(paFiles: IGalleryFile[]): void {
		this.fieldValue = paFiles.map((poFile: IGalleryFile) => ModelResolver.toClass(GalleryFile, poFile));
		this.observableTags.resetArray(this.getTagsFromFiles(paFiles));
		this.markAsDirty();
	}

	public removeFileFromModel(poTag: IDeletableTag): void {
		const laFiles: IGalleryFile[] = this.fieldValue.filter((poFile: IGalleryFile) => poFile.guid !== poTag.id);
		this.observableFieldValue.value = laFiles;
		this.observableTags.resetArray(this.getTagsFromFiles(laFiles));
	}

	public getFileIndex(psGuid: string, paTags: IDeletableTag[] = []): number {
		return paTags.findIndex((poTag: IDeletableTag) => poTag.id === psGuid);
	}

	private getTagsFromFiles(paFiles: IGalleryFile[] = []): IDeletableTag[] {
		return paFiles.map((poFile: IGalleryFile) => {
			return {
				id: poFile.guid,
				label: poFile.name,
				deletable: true
			}
		});
	}

	//#endregion
}