import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';

export class TokenExpiredError extends Error {

	constructor() {
		super(`Votre session est expirée Veuillez vous reconnecter.`);
		// Nécessaire pour que le 'instanceof TokenExpiredError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, TokenExpiredError);
	}

}