import { Injectable } from '@angular/core';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { EEntityLinkType } from '@calaosoft/osapp-common/entities/models/eentity-link-type';
import { EEntityLinkCacheData } from '@calaosoft/osapp-common/entities/models/EEntityLinkCacheData';
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { EntityLink } from '@calaosoft/osapp-common/entities/models/entity-link';
import { EntityLinkEntity } from '@calaosoft/osapp-common/entities/models/entity-link-entity';
import { IEntity } from '@calaosoft/osapp-common/entities/models/ientity';
import { IEntityLinkEntity } from '@calaosoft/osapp-common/entities/models/ientity-link-entity';
import { IEntityLinkRelationData } from '@calaosoft/osapp-common/entities/models/ientity-link-relation-data';
import { IEntityLinkCache } from '@calaosoft/osapp-common/entities/models/IEntityLinkCache';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { StoreHelper } from '@calaosoft/osapp-common/store/helpers/store-helper';
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { ICacheData } from '@calaosoft/osapp-common/store/models/icache-data';
import { IStoreDocument } from "@calaosoft/osapp-common/store/models/istore-document";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { MapHelper } from '@calaosoft/osapp-common/utils/helpers/mapHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { combineLatest, defer, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, map, mapTo, mergeMap, mergeMapTo, switchMap, tap } from 'rxjs/operators';
import { UserData } from '../model/application/UserData';
import { EBarElementAction } from '../model/barElement/EBarElementAction';
import { ELinkAction } from '../model/link/ELinkAction';
import { ELinkTemplate } from '../model/link/ELinkTemplate';
import { LinkInfo } from '../model/link/LinkInfo';
import { ILinkedItemsListParams } from '../model/linkedItemsList/ILinkedItemsListParams';
import { INavbarEvent } from '../model/navbar/INavbarEvent';
import { ActivePageManager } from '../model/navigation/ActivePageManager';
import { PageInfo } from '../model/PageInfo';
import { ICustomPouchError } from '../model/store/ICustomPouchError';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { PageManagerService } from '../modules/routing/services/pageManager.service';
import { IDataSourceRemoteChanges } from '../modules/store/model/IDataSourceRemoteChanges';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { Store } from './store.service';
import { UiMessageService } from './uiMessage.service';
import { WorkspaceService } from './workspace.service';

interface IParentality {
	parentId: string;
	parentLinkType: EEntityLinkType;
}

@Injectable({ providedIn: "root" })
export class EntityLinkService {

	//#region FIELDS

	/** Liste des liens associés */
	private static readonly C_LINKED_ITEMS_LIST_NAV_BUTTON_ID = "linkedItemsList";
	/** Identifiant du service pour les logs. */
	private static readonly C_LOG_ID = "EL.S::";
	/** Base de données relative pour les liens. */
	private static readonly C_RELATIVE_DATABASE_SOURCE = ".";
	private static readonly C_LINK_BY_ID_VIEW_NAME = "app_links-by-id/by-id";

	/** Tableau contenant les entités courantes. La dernière est la plus récente. */
	private maCurrentEntityStack: Entity[] = [];

	//#endregion

	//#region PROPERTIES

	public static readonly C_DEEPLINK_SUB_PATH_SEPARATOR = "/";

	/** Retourne l'entité courante. */
	public get currentEntity(): Entity { return ArrayHelper.getLastElement(this.maCurrentEntityStack); }

	public readonly observableLinkInfo = new ObservableProperty<LinkInfo>();

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en base de données. */
		private readonly isvcStore: Store,
		/** Service de gestion des pages. */
		private readonly isvcPageManager: PageManagerService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcUiMessage: UiMessageService
	) { }

	//#region Links

	/** Création de documents de liaison entre un sujet et un ensemble de cibles.
	 * Ex: on veut créer une conversation à partir d'un contact (la conversation sera liée au contact) : lnk_cont_guid-conv_guid.
	 * @param poSomething Source du lien, quelque chose va lui être lié.
	 * @param paSomethingElse Tableau des éléments qui vont être liées à la source.
	 */
	private createEntityLinks(poSomething: IEntity, paSomethingElse: IEntityLinkCache[]): Observable<Array<IStoreDataResponse | ICustomPouchError>> {
		const laItemLinks: EntityLink[] = paSomethingElse.map((poEntity: IEntityLinkCache) => {
			const loEntityLink: EntityLink = this.buildEntityLink(poSomething, poEntity.entity, poEntity.relationData);
			const loCacheData: ICacheData = {
				databaseId: this.isvcWorkspace.isDocumentFromWorkspace(poSomething) ?
					StoreHelper.getDatabaseIdFromCacheData(poSomething) : this.isvcWorkspace.getWorkspaceDatabaseIdFromDatabaseId(StoreHelper.getDatabaseIdFromCacheData(poEntity.entity))
			};

			StoreHelper.updateDocumentCacheData(loEntityLink, loCacheData);

			return loEntityLink;
		});

		return this.isvcStore.putMultipleDocuments(laItemLinks, undefined, true)
			.pipe(
				tap((paResults: IStoreDataResponse[]) => console.debug(`${EntityLinkService.C_LOG_ID}Création des documents de lien : '${paResults.every((poResult: IStoreDataResponse) => poResult.ok)}'.`)),
				catchError(poError => {
					console.error(`${EntityLinkService.C_LOG_ID}Erreur de création des liens (Avez-vous ajouté l'entité au config.ts de l'application ?) :`, poError);
					return throwError(() => poError);
				})
			);
	}

	public countLinkedEntities$(
		psItemId: string,
		paLinkedEntityPrefixes?: Array<EPrefix>,
		peEntityLinkType?: EEntityLinkType,
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<number> {
		return this.getEntityLinks(psItemId, paLinkedEntityPrefixes, peEntityLinkType, pbLive, poActivePageManager).pipe(map((paDocs: IStoreDocument[]) => paDocs.length));
	}

	/** Retourne l'ensemble des liens depuis les workspaces qui ont `psItemId` comme document lié.
	 * @param psItemId Identifiant de l'item pour lequel on veut tous les domaines associés.
	 * @param paLinkedEntityPrefixes Tableau des préfixes de liens d'entités qu'il faut récupérer, optionnel (les récupère tous dans ce cas).
	 * @param pbLive Indique si la requête est live.
	 * @param pbIncludeDeeplinks Indique si l'on doit inclure les deeplinks. Faux par défaut. (format `lnk_entity_a/sub/subGuid-entity_b`)
	 */
	public getEntityLinks(
		psItemId: string,
		paLinkedEntityPrefixes?: Array<EPrefix>,
		peEntityLinkType?: EEntityLinkType,
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Array<EntityLink>> {
		if (StringHelper.isBlank(psItemId) || ConfigData.disableLinks)
			return of([]);

		else
			return this.getEntityLinksFormMultipleItems$([psItemId], paLinkedEntityPrefixes, peEntityLinkType, pbLive, poActivePageManager);
	}

	/** Remplace les bases de données relatives d'une entité liée si elle en possède.
	 * @param poLink Lien d'entité dont il faut remplacer les bases de données relatives (s'il y en a).
	 */
	private replaceRelativeDatabaseSource(poLink: EntityLink): void;
	/** Remplace les bases de données relatives d'un tableau d'entités liées si elles en possèdent.
	 * @param paLinks Tableau des liens d'entité dont il faut remplacer les bases de données relatives (s'il y en a).
	 */
	private replaceRelativeDatabaseSource(paLinks: EntityLink[]): void;
	private replaceRelativeDatabaseSource(poData: EntityLink | EntityLink[]): void {
		// On initialise le tableau des liens en vérifiant que le paramètre est valide ou tableau vide par défaut.
		const laLinks: EntityLink[] = poData instanceof Array ? poData : (poData ? [poData] : []);

		laLinks.forEach((poLink: EntityLink) => {
			poLink.entities.forEach((poEntity: IEntityLinkEntity) => {
				if (poEntity.databaseId === EntityLinkService.C_RELATIVE_DATABASE_SOURCE)
					poEntity.databaseId = StoreHelper.getDatabaseIdFromCacheData(poLink);
			});
		});
	}

	/** Retourne une fonction permettant de filtrer les liens d'entités en fonction d'un tableau de préfixes.
	 * @param paLinkedEntityPrefixes Tableau des préfixes de liens d'entités qu'il faut récupérer, optionnel (les récupère tous dans ce cas).
	 */
	private filterEntityLinksFunction(
		paLinkedEntityPrefixes?: Array<EPrefix>,
		peEntityLinkType?: EEntityLinkType
	): ((poDoc: EntityLink) => boolean) | undefined {
		return ArrayHelper.hasElements(paLinkedEntityPrefixes) || peEntityLinkType ? (poDoc: EntityLink) =>
			poDoc.entities?.some((poEntity: EntityLinkEntity) =>
				(!peEntityLinkType || peEntityLinkType === (poEntity.type ?? EEntityLinkType.related)) &&
				(!ArrayHelper.hasElements(paLinkedEntityPrefixes) || paLinkedEntityPrefixes.some((pePrefix: EPrefix) =>
					IdHelper.hasPrefixId(poEntity.id, pePrefix)
				))
			) : undefined;
	}

	/** Permet de récupérer l'identifiant de la cible du lien.
	 * ### Attention, utiliser de préférence la méthode *getEntityLinkPartFromPrefix(entityLink, prefix?)* sauf si le lien est de type `lnk_a-a`.
	 * @param poItemLink Lien.
	 */
	public getLinkTargetIds(paSortedSourceIds: string[], poItemLink: EntityLink): string[] {
		const laIds: string[] = [];
		const lbIsBetweenSources: boolean = this.isLinkBetweenSourceEntities(paSortedSourceIds, poItemLink);

		poItemLink.entities.filter((poEntity: IEntityLinkEntity) => {
			if (lbIsBetweenSources || !ArrayHelper.binarySearch(paSortedSourceIds, poEntity.id))
				laIds.push(poEntity.id);
		});

		return laIds;
	}

	/** Supprime un lien.
	 * @param poLink lien a supprimer
	 * @param psDatabaseId base de donnée du lien, si le paramètre n'est pas fournit il sera pris dans le $cacheData
	 */
	public deleteItemLink(poLink: EntityLink, psDatabaseId?: string): Observable<IStoreDataResponse> {
		return this.isvcStore.delete(poLink, psDatabaseId)
			.pipe(
				tap(
					_ => console.debug(`${EntityLinkService.C_LOG_ID}Le lien: ${poLink._id} à été supprimé.`),
					poError => console.error(`${EntityLinkService.C_LOG_ID}Erreur pendant la suppression du lien: ${poLink._id}.`, poError)
				)
			);
	}

	/** Supprime tous les liens d'une entité en fonction d'un identifiant.
	 * @param psEntityId Identifiant de l'entité dont il faut supprimer tous les liens.
	 * @returns `true` si la suppression a réussi, `false` sinon.
	 */
	public deleteEntityLinksById(poData: string | string[]): Observable<boolean> {
		let loEntityLinks$: Observable<EntityLink[]>;

		if (poData instanceof Array)
			loEntityLinks$ = this.getEntityLinksFormMultipleItems$(poData);
		else {
			loEntityLinks$ = this.getEntityLinks(poData);
		}

		return loEntityLinks$
			.pipe(mergeMap((paResults: Array<EntityLink>) => this.isvcStore.deleteMultipleDocuments(paResults)));
	}

	//#endregion

	//#region Entity

	/** Lève un événement de portée application pour notifier un changement d'entité active.
	 * @param poOldEntity Ancienne entité
	 * @param poNewEntity Nouvelle entité
	 */
	private onCurrentEntityChanged(poOldEntity: IEntity, poNewEntity: IEntity): Observable<void> {
		return of(poNewEntity)
			.pipe(
				mergeMap((poEntity: IEntity) => {
					if (poEntity instanceof Entity)
						return of(poEntity.links);

					return !!poEntity ? this.getEntityLinks(poEntity._id) : of(null);
				}),
				catchError(poError => {
					console.error(`${EntityLinkService.C_LOG_ID}Erreur lors de la récupération des liens.`, poError);
					// En cas d'erreur on notifie l'événement sans entité liée.
					return this.updateCurrentEntityLinks(poOldEntity, poNewEntity).pipe(mergeMapTo(throwError(() => poError)));
				}),
				mergeMap((paResults: EntityLink[]) => this.updateCurrentEntityLinks(poOldEntity, poNewEntity, paResults))
			);
	}

	private updateCurrentEntityLinks(poOldEntity: IEntity, poNewEntity: IEntity, paLinkedEntities: EntityLink[] = []): Observable<void> {
		console.debug(
			`${EntityLinkService.C_LOG_ID}Current entity changed from ${poOldEntity != null ? poOldEntity._id : "none"} to ${poNewEntity != null ? poNewEntity._id : "none"}.\nNew entity:`,
			poNewEntity,
			"\nLinked entities :",
			paLinkedEntities
		);

		if (poNewEntity)
			return this.updateNavbar();
		else
			return of(this.removeLinkedEntitiesNavButton());
	}

	/** Fixe l'entité courante de l'application.
	 * @param poModel Modèle utilisé
	 * @param pbCheckModelDiffers Indique si on doit vérifier que le modèle est différent de l'actuel ou non, `true` par défaut.
	 */
	private setCurrentEntity(poModel: Entity, pbCheckModelDiffers: boolean = true): Observable<boolean> {
		if (!poModel || StringHelper.isBlank(poModel._id))
			return throwError(() => "No model was provided to set the current entity. Please use clearCurrentEntity() if you intend to reset it.");

		else if (UserData.current.isGuest) {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity will be not set because user is guest.`);
			return of(false);
		}

		const loCurrentEntity: Entity = this.currentEntity;

		// On s'assure qu'il ne s'agisse pas de la même entité avant de faire quoi que ce soit.
		if (!pbCheckModelDiffers || !loCurrentEntity || (loCurrentEntity && loCurrentEntity._id !== poModel._id)) {

			const loOldEntity: IEntity = loCurrentEntity;
			this.maCurrentEntityStack.push(poModel);
			return this.onCurrentEntityChanged(loOldEntity, this.currentEntity).pipe(mapTo(true));
		}
		else {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity was already set to the same model.`);
			return of(true);
		}
	}

	/** @implements */
	public trySetCurrentEntity(poModel: Entity): Observable<boolean> {
		return this.setCurrentEntity(poModel)
			.pipe(
				catchError((poError) => {
					console.warn(`${EntityLinkService.C_LOG_ID}Cannot set current entity for `, poModel, "Erreur :", poError);
					return of(false);
				})
			);
	}

	/** @implements */
	public clearCurrentEntity(psModelId: string): Observable<boolean> {
		const loOldEntity: IEntity = this.currentEntity;

		if (ArrayHelper.removeElementByFinder(this.maCurrentEntityStack, (poItem: IEntity) => poItem?._id === psModelId)) {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity was cleared.`);
			return this.onCurrentEntityChanged(loOldEntity, this.currentEntity).pipe(mapTo(true));
		}
		else {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity was already cleared.`);
			return of(false);
		}
	}

	/** Vérifie si le modèle est valide.
	 * @param poModel Modèle à vérifier
	 */
	public isValidEntityModel(poModel: IStoreDocument): boolean {
		return poModel && !StringHelper.isBlank(poModel._id);
	}

	//#endregion

	//#region EntityLinks

	/** Construit un lien avec les éléments source et cible.
	 * @param poCurrentEntity Entité courante.
	 * @param poEntity Entité cible.
	 * @param poEntityLinkRelationData Relation.
	 */
	private buildEntityLink(poCurrentEntity: IEntity, poEntity: IEntity, poEntityLinkRelationData?: IEntityLinkRelationData): EntityLink {
		// Construit le document lien.
		const lsCurrentEntityDatabaseId: string = StoreHelper.getDatabaseIdFromCacheData(poCurrentEntity, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)), false);
		return new EntityLink({
			entities: [
				new EntityLinkEntity({
					id: poCurrentEntity._id,
					databaseId: lsCurrentEntityDatabaseId,
					type: poEntityLinkRelationData?.[poCurrentEntity._id]
				}),
				new EntityLinkEntity({
					id: poEntity._id,
					databaseId: StoreHelper.getDatabaseIdFromCacheData(poEntity, lsCurrentEntityDatabaseId),
					type: poEntityLinkRelationData?.[poEntity._id]
				})
			]
		});
	}

	/** Marque des liens à ajouter dans les données de cache du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param paEntities Tableau des entités à ajouter.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToAdd(
		poModel: IStoreDocument,
		paEntities: Entity[],
		paEntityLinkRelationData?: IEntityLinkRelationData[],
		psSubPath?: string
	): void;
	/** Marque un lien à ajouter dans les données de cache du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param poEntity Entité à ajouter.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToAdd(
		poModel: IStoreDocument,
		poEntity: Entity,
		poEntityLinkRelationData?: IEntityLinkRelationData,
		psSubPath?: string
	): void;
	public cacheLinkToAdd(
		poModel: IStoreDocument,
		poEntityData: Entity | Entity[],
		poEntityLinkRelationData?: IEntityLinkRelationData | IEntityLinkRelationData[],
		psSubPath?: string
	): void {
		if (!(poEntityData instanceof Array))
			poEntityData = [poEntityData];

		const lbRelationDataInstanceofArray = poEntityLinkRelationData instanceof Array;

		poEntityData.forEach((poEntity: Entity, pnIndex: number) =>
			this.cacheLinkToAddRemoveInternal(
				poModel,
				EEntityLinkCacheData.Add,
				poEntity,
				lbRelationDataInstanceofArray ? poEntityLinkRelationData[pnIndex] : poEntityLinkRelationData,
				psSubPath
			)
		);
	}

	/** Méthode interne d'ajout d'un lien dans le cacheData.
	 * @param poModel Modèle à sauvegarder.
	 * @param poItemLink Lien à mettre à jour.
	 * @param peCacheDataState Action à réaliser sur le lien.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	private cacheLinkToAddRemoveInternal(
		poModel: IStoreDocument,
		peCacheDataState: EEntityLinkCacheData,
		poEntity: Entity,
		poEntityLinkRelationData?: IEntityLinkRelationData,
		psSubPath?: string
	): void {
		const loModelCacheData: ICacheData = StoreHelper.getDocumentCacheData(poModel);

		if (loModelCacheData?.links) {
			const lnIndex: number = loModelCacheData.links.findIndex((poEntityLinkCache: IEntityLinkCache) =>
				poEntityLinkCache.entity._id === poEntity._id &&
				psSubPath === poEntityLinkCache.targetSubPath &&
				(poEntityLinkRelationData?.[poEntity._id] ?? EEntityLinkType.related) === (poEntityLinkCache.relationData?.[poEntity._id] ?? EEntityLinkType.related)
			);

			if (lnIndex >= 0) { // Entité déjà présente dans la cacheData, il faut metttre à jour.
				// Si les états sont différents, c'est une annulation (add/remove disponibles => add + remove = annulation). Sinon, pas besoin de le réajouter.
				if (loModelCacheData.links[lnIndex].cacheDataState !== peCacheDataState)
					loModelCacheData.links.splice(lnIndex, 1);
			}
			else { // Entité non présente dans la cacheData, il faut l'ajouter.
				loModelCacheData.links.push({
					entity: poEntity,
					cacheDataState: peCacheDataState,
					targetSubPath: psSubPath,
					relationData: poEntityLinkRelationData
				});
			}
		}
		else {
			const loCacheData: ICacheData = {
				links: [{
					entity: poEntity,
					cacheDataState: peCacheDataState,
					targetSubPath: psSubPath,
					relationData: poEntityLinkRelationData
				}]
			};
			StoreHelper.updateDocumentCacheData(poModel, loCacheData);
		}
	}

	/** Marque des liens à supprimer dans le $cacheData du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param paEntity Entités à supprimer.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToRemove(
		poModel: IStoreDocument,
		paEntity: Entity[],
		paEntityLinkRelationData?: IEntityLinkRelationData[],
		psSubPath?: string
	): void;
	/** Marque un lien à supprimer dans le $cacheData du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param poEntity Entité à supprimer.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToRemove(
		poModel: IStoreDocument,
		poEntity: Entity,
		poEntityLinkRelationData?: IEntityLinkRelationData,
		psSubPath?: string
	): void;
	public cacheLinkToRemove(
		poModel: any,
		poEntityData: Entity | Entity[],
		poEntityLinkRelationData?: IEntityLinkRelationData | IEntityLinkRelationData[],
		psSubPath?: string
	): void {
		if (!(poEntityData instanceof Array))
			poEntityData = [poEntityData];

		const lbRelationDataInstanceofArray = poEntityLinkRelationData instanceof Array;

		poEntityData.forEach((poEntity: Entity, pnIndex: number) => this.cacheLinkToAddRemoveInternal(
			poModel,
			EEntityLinkCacheData.Remove,
			poEntity,
			lbRelationDataInstanceofArray ? poEntityLinkRelationData[pnIndex] : poEntityLinkRelationData,
			psSubPath
		));
	}

	/** Retourne la liste des liens à créer ou supprimer.
	 * @param poModel Modèle à enregistrer.
	 * @param peEntityLinkCacheData État du cacheData (ajout ou suppression).
	 */
	private getCachedEntities(poModel: IStoreDocument, peEntityLinkCacheData?: EEntityLinkCacheData): Array<IEntityLinkCache> {
		const loCacheData: ICacheData = StoreHelper.getDocumentCacheData(poModel);

		if (loCacheData?.links) {
			return loCacheData.links
				.filter((poEntityLink: IEntityLinkCache) => !peEntityLinkCacheData || poEntityLink.cacheDataState === peEntityLinkCacheData);
		}
		else
			return [];
	}

	/** Persistence de sauvegarde et de suppression des liens.
	 * @param poModel Modèle a sauvegarder.
	 */
	public saveEntityLinks<T extends Entity = Entity>(poData: T): Observable<boolean> {
		return forkJoin([this.saveEntityLinkAdded(poData), this.saveEntityLinkRemoved(poData)])
			.pipe(
				mergeMap((paResults: boolean[]) => this.updateNavbar().pipe(mapTo(paResults))),
				map((paResults: boolean[]) => {
					const lbResult: boolean = paResults.every((pbResult: boolean) => pbResult);
					if (lbResult)
						EntityLinkService.deleteLinksFromDocumentCacheData(poData);
					return lbResult;
				})
			);
	}

	/** Persistence des liens à ajouter.
	 * @param poModelEntity Entité du modèle.
	 */
	private saveEntityLinkAdded(poModelEntity: Entity): Observable<boolean> {
		const laCachedLinks: IEntityLinkCache[] = this.getCachedEntities(poModelEntity, EEntityLinkCacheData.Add);
		const laCachedLinksToAdd: IEntityLinkCache[] = [];

		laCachedLinks.forEach((poLinkToAddA: IEntityLinkCache) => {
			if (laCachedLinks.every((poLinkToAddB: IEntityLinkCache) => poLinkToAddA === poLinkToAddB ||
				!this.willBeLogicallyRelatedTo(poLinkToAddA.entity._id, poLinkToAddB)
			))
				laCachedLinksToAdd.push(poLinkToAddA);
		});

		if (!ArrayHelper.hasElements(laCachedLinksToAdd)) // Si tableau vide, alors succès.
			return of(true);

		else {
			return this.createEntityLinks(poModelEntity, laCachedLinksToAdd)
				.pipe(
					catchError(poError => { console.error(`${EntityLinkService.C_LOG_ID}Error create entityLinks`, poError); return of({ id: poModelEntity._id, ok: false } as IStoreDataResponse); }),
					map((paResults: Array<IStoreDataResponse | ICustomPouchError>) => {
						const laCorrectCreatedLinks: IStoreDataResponse[] = [];
						const laErrors: ICustomPouchError[] = [];

						paResults.forEach((poItem: IStoreDataResponse | ICustomPouchError) => {
							if ((poItem as IStoreDataResponse).ok)
								laCorrectCreatedLinks.push(poItem as IStoreDataResponse);
							else if ((poItem as ICustomPouchError).error)
								laErrors.push(poItem as ICustomPouchError);
						});

						if (laCorrectCreatedLinks.length === paResults.length)
							return true;
						else {
							if (ArrayHelper.hasElements(laErrors))
								console.error(`${EntityLinkService.C_LOG_ID}Erreur création des liens d'entités :`, laErrors);
							return false;
						}
					})
				);
		}
	}

	/** Vérifie si B donnera lieu à un lien logique vers A.
	 * @param poEntityLinkCacheA
	 * @param poEntityLinkCacheB
	 */
	private willBeLogicallyRelatedTo(
		psEntityAId: string,
		poEntityLinkCacheB: IEntityLinkCache
	): boolean {
		if (poEntityLinkCacheB.relationData[psEntityAId] === EEntityLinkType.child) {
			return poEntityLinkCacheB.entity.hasLinks(
				EEntityLinkType.related,
				psEntityAId
			);
		}
		else {
			return poEntityLinkCacheB.entity.hasLinks(
				EEntityLinkType.parent,
				psEntityAId
			);
		}
	}

	/** Persistence des liens à supprimer.
	 * @param poModelEntity Entité du modèle.
	 */
	private saveEntityLinkRemoved(poModelEntity: Entity): Observable<boolean> {
		const laCachedEntities: IEntityLinkCache[] = this.getCachedEntities(poModelEntity);
		const laLinksToRemove: EntityLink[] = [];
		poModelEntity.links.forEach((poLink: EntityLink) => {// Si le lien est logique on verifie qu'il n'y a pas déjà de lien existant, sinon on supprime le lien existant.
			if (
				this.hasDuplicatedLink(poModelEntity, poLink) ||
				this.linkCanBeReplacedByLogicalLink(poLink, poModelEntity, laCachedEntities) ||
				this.linkNeedToBeRemoved(laCachedEntities, poLink, poModelEntity)
			)
				laLinksToRemove.push(poLink);
		});

		if (ArrayHelper.hasElements(laLinksToRemove)) // Si on a des entités liées à supprimer on les supprime, sinon on a terminé.
			return this.isvcStore.deleteMultipleDocuments(laLinksToRemove);
		else
			return of(true);
	}

	private linkNeedToBeRemoved(
		paCachedEntities: IEntityLinkCache[],
		poLink: EntityLink,
		poModelEntity: Entity
	): boolean {
		return paCachedEntities.some((poCachedEntity: IEntityLinkCache) =>
			poCachedEntity.cacheDataState === EEntityLinkCacheData.Remove &&
			poLink.hasLink(
				poCachedEntity.relationData[poCachedEntity.entity._id] ?? EEntityLinkType.related,
				poCachedEntity.entity._id,
				poModelEntity._id
			)
		);
	}

	private hasDuplicatedLink(poModelEntity: Entity, poLink: EntityLink): boolean {
		return poLink.logical && !!poModelEntity.links.find((poLinkB: EntityLink) => poLinkB !== poLink &&
			ArrayHelper.areArraysEqual(
				poLink.entities,
				poLinkB.entities,
				(poEntityA: EntityLinkEntity, poEntityB: EntityLinkEntity) => poEntityA.equals(poEntityB)
			)
		);
	}

	private linkCanBeReplacedByLogicalLink(
		poLink: EntityLink,
		poModelEntity: Entity,
		paCachedEntities: IEntityLinkCache[]
	): boolean {
		return poLink.getTargetEntitiesBySourceId(poModelEntity._id).every((poEntityLinkEntity: EntityLinkEntity) => // Pour chaque entité cible du lien
			paCachedEntities.some((poCachedEntity: IEntityLinkCache) => // Pour chaque entité en cache
				poCachedEntity.cacheDataState === EEntityLinkCacheData.Add && // si c'est un ajout
				this.willBeLogicallyRelatedTo(poEntityLinkEntity.id, poCachedEntity)
			)
		);
	}

	/** Renvoie le préfixe d'une entité.
	 * @param psEntityId Identifiant de l'entité dont veut récupérer le préfixe.
	 */
	public getEntityPrefix(psEntityId: string): string {
		return psEntityId.substring(0, psEntityId.indexOf("_") + 1);
	}

	/** Supprime les liens du cacheData du document fourni.
	 * @param poDocument Document dont il faut supprimer les liens de son cacheData.
	 */
	public static deleteLinksFromDocumentCacheData(poDocument: IStoreDocument): void {
		const loDocumentCacheData: ICacheData = StoreHelper.getDocumentCacheData(poDocument);

		if (loDocumentCacheData)
			delete loDocumentCacheData.links;
	}

	/** Met à jour le nombre d'entités liées dans la barre de navigation.*/
	private updateNavbar(poEntity?: IEntity): Observable<void> {
		if (this.currentEntity || poEntity) { // Si une entité courante est définie, il faut aller chercher ses entités liées.
			return this.getLinkedEntities((poEntity ?? this.currentEntity)._id)
				.pipe(
					tap((paResults: IStoreDocument[]) => this.setNavbar(this.currentEntity, paResults)),
					mapTo(undefined)
				);
		}
		else // Sinon on ne met pas à jour la navbar.
			return of(undefined);
	}

	private setNavbar(poEntity: IEntity, paLinks: IStoreDocument[]): void {
		const loLinkInfo = new LinkInfo({
			meta: { schemaVersion: "2.0.0" },
			id: EntityLinkService.C_LINKED_ITEMS_LIST_NAV_BUTTON_ID,
			label: paLinks.length.toString(),
			templateId: ELinkTemplate.icon,
			params: { icon: "link" },
			action: ELinkAction.callback,
			actionParams: { function: () => this.routeToLinkedItemsList(poEntity) }
		});
		const loNavbarEvent: INavbarEvent = {
			id: EntityLinkService.C_LINKED_ITEMS_LIST_NAV_BUTTON_ID,
			action: EBarElementAction.add,
			links: [loLinkInfo]
		};

		this.observableLinkInfo.value = loLinkInfo;

		this.isvcPageManager.raiseNavbarEvent(loNavbarEvent);
	}

	/** Supprime le bouton d'ouverture de la popup des liens. */
	private removeLinkedEntitiesNavButton(): void {
		this.observableLinkInfo.value = undefined;

		this.isvcPageManager.raiseNavbarEvent({
			id: EntityLinkService.C_LINKED_ITEMS_LIST_NAV_BUTTON_ID,
			action: EBarElementAction.clear
		} as INavbarEvent);
	}

	/** Navigue vers la page de la liste des items liés.
	 * @param poEntity Entité courante.
	 */
	private routeToLinkedItemsList(poEntity: IEntity): void {
		const loPageInfo: PageInfo = new PageInfo({
			componentName: "linkedItemsList",
			isModal: true,
			title: "Informations liées",
			params: {
				params: {
					itemId: poEntity._id
				} as ILinkedItemsListParams
			}
		});

		this.isvcPageManager.routePageFromInfo(loPageInfo);
	}

	/** Marque les liens vers les entités à mettre à jour dans un modèle.
	 * @param poModel Modèle dont il faut mettre à jour les liens.
	 * @param paOldEntity Liste des entités préalables.
	 * @param paNewEntity Liste des entités sélectionnés.
	 */
	public updateCachedEntityLinks(
		poModel: IStoreDocument,
		paOldEntity: Array<Entity>,
		paNewEntity: Array<Entity>,
		poEntityLinkRelationData?: Map<string, IEntityLinkRelationData>
	): void {
		// Si une entité n'est pas dans l'ancienne liste mais dans la nouvelle alors il a été ajouté.
		const laAddLinks: Array<Entity> = paNewEntity.filter(
			(poNew: Entity) => paOldEntity.findIndex((poOld: Entity) => poOld._id === poNew._id) === -1
		);
		this.cacheLinkToAdd(
			poModel,
			laAddLinks,
			laAddLinks.map((poEntity: Entity) => poEntityLinkRelationData?.get(poEntity._id))
		);

		// Si une entité n'est pas dans la nouvelle liste mais dans la vieille alors il a été supprimé.
		const laRemoveLinks: Array<Entity> = paOldEntity.filter(
			(poOld: Entity) => paNewEntity.findIndex((poNew: Entity) => poOld._id === poNew._id) === -1
		);
		this.cacheLinkToRemove(
			poModel,
			laRemoveLinks,
			laRemoveLinks.map((poEntity: Entity) => poEntityLinkRelationData?.get(poEntity._id))
		);
	}

	/** Détermine si l'entité indiquée peut être supprimée.
	 * @param psEntityId Identifiant de l'entité qu'on veut supprimer.
	 */
	public ensureIsDeletableEntity(poEntity: IStoreDocument, paLinkedDocs: IStoreDocument[] = []): Observable<boolean> {
		return this.getEntityLinks(poEntity._id)
			.pipe(
				map((paLinks: EntityLink[]) => {
					if (ArrayHelper.hasElements(paLinks)) {
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
							message: `Cet élément est lié à ${paLinks.length} élément${paLinks.length > 1 ? "s" : ""}.`, header: "Suppression interdite"
						}));
						return false;
					}
					else
						return true;
				}) // TODO remplacer par une gestion avec type de relation
			);
	}

	/** Récupère les identifiants des entités liées à un identifiant.
	 * @param psItemId Identifiant de la données source des liens.
	 * @param paLinkedEntityPrefixes Préfixes des données liées.
	 * @param pbLive Indique si la requête doit être live ou non.
	 */
	public getLinkedEntityIds(psItemId: string, paLinkedEntityPrefixes?: Array<EPrefix>, peEntityLinkType?: EEntityLinkType, pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<string[]>;
	/** Récupère les identifiants des entités liées à un tableau d'identifiants.
	 * @param paItemIds Tableau des identifiants des données source des liens.
	 * @param paLinkedEntityPrefixes Préfixes des données liées.
	 * @param pbLive Indique si la requête doit être live ou non.
	 */
	public getLinkedEntityIds(paItemIds: string[], paLinkedEntityPrefixes?: Array<EPrefix>, peEntityLinkType?: EEntityLinkType, pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Map<string, string[]>>;
	public getLinkedEntityIds(poData: string | string[], paLinkedEntityPrefixes?: Array<EPrefix>, peEntityLinkType?: EEntityLinkType, pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<string[] | Map<string, string[]>> {
		if (poData instanceof Array)
			return this.getLinkedEntityIdsForMultipleItems(poData, paLinkedEntityPrefixes, peEntityLinkType, pbLive, poActivePageManager);
		else {
			return this.getEntityLinks(poData, paLinkedEntityPrefixes, peEntityLinkType, pbLive, poActivePageManager)
				.pipe(
					map((paEntityLinks: EntityLink[]) => paEntityLinks.map((poEntityLink: EntityLink) =>
						this.getLinkTargetIds([poData], poEntityLink)
					).flat())
				);
		}
	}

	private getLinkedEntityIdsForMultipleItems(
		paItemIds: string[],
		paLinkedEntityPrefixes: EPrefix[] = [],
		peEntityLinkType?: EEntityLinkType,
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, string[]>> {
		paItemIds.sort();
		return this.getEntityLinksFormMultipleItems$(paItemIds, paLinkedEntityPrefixes, peEntityLinkType, pbLive, poActivePageManager)
			.pipe(map((paEntityLinks: EntityLink[]) => this.groupTargetIdsBySourceId(paItemIds, paEntityLinks)));
	}

	public getEntityLinks$(
		paItemIds: string[],
		paLinkedEntityPrefixes: EPrefix[] = [],
		peEntityLinkType?: EEntityLinkType,
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, EntityLink[]>> {
		paItemIds.sort();
		return this.getEntityLinksFormMultipleItems$(paItemIds, paLinkedEntityPrefixes, peEntityLinkType, pbLive, poActivePageManager)
			.pipe(map((paEntityLinks: EntityLink[]) => this.groupLinksBySourceId(paItemIds, paEntityLinks)));
	}

	private getEntityLinksFormMultipleItems$(
		paItemIds: string[],
		paLinkedEntityPrefixes: EPrefix[] = [],
		peEntityLinkType?: EEntityLinkType,
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<EntityLink[]> {
		return this.buildGetEntityLinksObservable(
			paItemIds.filter((psId: string) => !StringHelper.isBlank(psId)),
			paLinkedEntityPrefixes,
			peEntityLinkType,
			pbLive,
			poActivePageManager
		);
	}

	private buildGetEntityLinksObservable(
		paIds: string[],
		paLinkedEntityPrefixes: EPrefix[],
		peEntityLinkType?: EEntityLinkType,
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager,
		paAlreadyRequestedEntityLinksIds: string[] = [],
		paResponseEntityLinks: Map<string, EntityLink> = new Map(),
		poParentalityRecord: Record<string, IParentality[]> = {}
	): Observable<EntityLink[]> {
		if (ConfigData.disableLinks || !ArrayHelper.hasElements(paIds))
			return of([]);

		return defer(() => {
			const loDataSource: IDataSourceRemoteChanges<EntityLink> = {
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				viewParams: {
					include_docs: true,
					keys: paIds
				},
				live: pbLive,
				remoteChanges: !!poActivePageManager,
				activePageManager: poActivePageManager,
				baseClass: EntityLink,
				viewName: EntityLinkService.C_LINK_BY_ID_VIEW_NAME
			};

			return this.isvcStore.get(loDataSource)
				.pipe(
					tap((paResults: EntityLink[]) => {
						this.replaceRelativeDatabaseSource(paResults);
					}),
					switchMap((paEntityLinks: EntityLink[]) => {
						const [laIdsToExtend, laNewEntityLinksIds]: [string[], string[]] = this.prepareLogicalLinksParams(
							paEntityLinks,
							paAlreadyRequestedEntityLinksIds,
							poParentalityRecord,
							paResponseEntityLinks
						);

						if (ArrayHelper.hasElements(laIdsToExtend)) {
							return this.buildGetEntityLinksObservable(
								laIdsToExtend,
								paLinkedEntityPrefixes,
								peEntityLinkType,
								pbLive,
								poActivePageManager,
								[...paAlreadyRequestedEntityLinksIds, ...laNewEntityLinksIds].sort(),
								paResponseEntityLinks,
								poParentalityRecord
							);
						}
						const lfFilterFunction: (poDoc: EntityLink) => boolean | undefined =
							this.filterEntityLinksFunction(paLinkedEntityPrefixes, peEntityLinkType);
						return of(lfFilterFunction ? MapHelper.valuesToArray(paResponseEntityLinks).filter(lfFilterFunction) : MapHelper.valuesToArray(paResponseEntityLinks));
					})
				);
		});
	}

	private prepareLogicalLinksParams(
		paEntityLinks: EntityLink[],
		paAlreadyRequestedEntityLinksIds: string[],
		poParentalityRecord: Record<string, IParentality[]>,
		paResponseEntityLinks: Map<string, EntityLink>
	): [string[], string[]] {
		const laIdsToExtend: string[] = [];
		const laNewEntityLinksIds: string[] = [];

		paEntityLinks.forEach((poEntityLink: EntityLink) => {
			if (!ArrayHelper.binarySearch(paAlreadyRequestedEntityLinksIds, poEntityLink._id)) {
				laNewEntityLinksIds.push(poEntityLink._id); // On ajoute uniquement si le lien n'est pas déjà présent

				const lsSourceKey: string | undefined = StoreHelper.getDocumentCacheData(poEntityLink)?.key;
				poEntityLink.entities.forEach((poEntityLinkEntity: EntityLinkEntity) => {
					const laParentalities: IParentality[] = poParentalityRecord[poEntityLinkEntity.id] ??
						(poParentalityRecord[poEntityLinkEntity.id] = []);

					if (lsSourceKey !== poEntityLinkEntity.id) {
						if (!laParentalities.some((poParentality: IParentality) => poParentality.parentId === lsSourceKey))
							laParentalities.push({ parentId: lsSourceKey, parentLinkType: poEntityLinkEntity.type });
						if (!ArrayHelper.hasElements(paAlreadyRequestedEntityLinksIds)) {
							paResponseEntityLinks.set(poEntityLink._id, poEntityLink); // Si on est sur un premier niveau on ajoute par défaut à la réponse en temps que lien physique
							if (poEntityLinkEntity.type === EEntityLinkType.related || poEntityLinkEntity.type === EEntityLinkType.child) // Si c'est un related ou child, alors on ajoute à la recherche de lien logique
								laIdsToExtend.push(poEntityLinkEntity.id);
						}
						else { // Sinon on est sur du niveau logique
							const laFirstParentalities: IParentality[] = this.getFirstParentalities(lsSourceKey, poParentalityRecord);

							laFirstParentalities.forEach((poParentality: IParentality) => {
								if (this.hasToExtendRequest(poEntityLinkEntity, poParentality)) { // Si on est lié à un enfant direct alors on veut poursuivre le parcours.
									laIdsToExtend.push(poEntityLinkEntity.id);
									const loLogicalLink: EntityLink = this.createLogicalLink(poEntityLinkEntity, poParentality)
									paResponseEntityLinks.set(loLogicalLink._id, loLogicalLink);
								}
							});
						}
					}
				});
			}
		});
		return [laIdsToExtend, laNewEntityLinksIds];
	}

	private createLogicalLink(poEntityLinkEntity: EntityLinkEntity, poParentality: IParentality): EntityLink {
		return new EntityLink({
			_id: IdHelper.buildId(EPrefix.link, poEntityLinkEntity.id),
			logical: true,
			entities: [
				{ id: poEntityLinkEntity.id, databaseId: poEntityLinkEntity.databaseId, type: EEntityLinkType.related },
				{ id: poParentality.parentId, databaseId: poEntityLinkEntity.databaseId, type: EEntityLinkType.related }
			]
		});
	}

	private hasToExtendRequest(poEntityLinkEntity: EntityLinkEntity, poFirstParentality: IParentality): boolean {
		const leEntityLinkType: EEntityLinkType = poEntityLinkEntity.type ?? EEntityLinkType.related;
		const leParentEntityLinkType: EEntityLinkType = poFirstParentality.parentLinkType ?? EEntityLinkType.related;
		return (
			leParentEntityLinkType === EEntityLinkType.child &&
			(leEntityLinkType === EEntityLinkType.related || leEntityLinkType === EEntityLinkType.child)
		) ||
			(
				(leParentEntityLinkType === EEntityLinkType.parent || leParentEntityLinkType === EEntityLinkType.related) &&
				leEntityLinkType === EEntityLinkType.parent
			);
	}

	private getFirstParentalities(psSourceKey: string, poParentalityRecord: Record<string, IParentality[]>): IParentality[] {
		return this.getParentParentalities(poParentalityRecord[psSourceKey] ?? [], poParentalityRecord);
	}

	private getParentParentalities(
		paParentalities: IParentality[],
		poParentalityRecord: Record<string, IParentality[]>,
		paAlreadyParsedParentalities: IParentality[] = []
	): IParentality[] {
		let laCurrentParentalities: IParentality[] = [];

		for (let lnIndex = 0; lnIndex < paParentalities.length; ++lnIndex) {
			const loParentality: IParentality | undefined = paParentalities[lnIndex];

			if (loParentality && !paAlreadyParsedParentalities.includes(loParentality)) {
				paAlreadyParsedParentalities.push(loParentality);
				const laParentalities: IParentality[] = poParentalityRecord[loParentality.parentId];

				if (laParentalities) {
					laCurrentParentalities = [
						...laCurrentParentalities,
						...this.getParentParentalities(laParentalities, poParentalityRecord, paAlreadyParsedParentalities)
					];
				}
			}
		}

		return ArrayHelper.hasElements(laCurrentParentalities) ? laCurrentParentalities : paParentalities;
	}

	/** Récupère les entités liées à un identifiant ou à un document issu de la base de données.
	 * @param poItem Identifiant ou document issu de la base de données dont il faut récupérer les liens associés.
	 * @param paLinkedEntityPrefixes Préfixe ou tableau de préfixes des données liées.
	 * @param pbLive Indique si la requête doit être live ou non.
	 * @param pbConflicts Indique si l'on doit récupérer les conflits ou non.
	 */
	public getLinkedEntities<T extends Entity = Entity>(
		poItem?: string | Entity,
		paLinkedEntityPrefixes?: EPrefix | EPrefix[],
		peEntityLinkType?: EEntityLinkType,
		pbLive?: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<T[]>;
	/** Récupère les entités liées à un tableau de documents issus du de bases de données ou d'idnetifiants.
	 * @param paItems Tableau des documents (ou identifiants) dont il faut récupérer les liens.
	 * @param paLinkedEntityPrefixes Préfixe ou tableau des préfixes des données liées à récupérer.
	 * @param pbLive Indique si la requête doit être live ou non.
	 * @param pbConflicts Indique si l'on doit récupérer les conflits ou non.
	 */
	public getLinkedEntities<T extends Entity = Entity>(
		paItems?: string[] | Entity[],
		paLinkedEntityPrefixes?: EPrefix | EPrefix[],
		peEntityLinkType?: EEntityLinkType,
		pbLive?: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, T[]>>;
	public getLinkedEntities<T extends Entity = Entity>(
		poData?: string | string[] | Entity | Entity[],
		paLinkedEntityPrefixes?: EPrefix | EPrefix[],
		peEntityLinkType?: EEntityLinkType,
		pbLive?: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<T[] | Map<string, T[]>> {
		if (!poData)
			return of(undefined);

		const laPrefixes: EPrefix[] = paLinkedEntityPrefixes instanceof Array ? paLinkedEntityPrefixes :
			paLinkedEntityPrefixes ? [paLinkedEntityPrefixes] : undefined;

		if (poData instanceof Array) {
			let laDataIds: string[];

			if (typeof ArrayHelper.getFirstElement(poData as string[]) === "string")
				laDataIds = poData as string[];
			else
				laDataIds = (poData as IStoreDocument[]).map((poItem: IStoreDocument) => poItem._id);

			return this.getLinkedEntitiesForMultipleItems<T>(laDataIds, laPrefixes, peEntityLinkType, pbLive, pbConflicts, poActivePageManager) as any as Observable<Map<string, T[]>>;
		}
		else {
			let lsDataId: string;
			const laLinkedEntities: T[] = [];

			if (typeof poData === "string")
				lsDataId = poData;
			else {
				lsDataId = poData._id;
				laLinkedEntities.push(...this.getLinkedEntitiesFromCacheData<T>(poData, laPrefixes, peEntityLinkType));
			}

			return this.getEntityLinks(lsDataId, laPrefixes, peEntityLinkType, pbLive, poActivePageManager)
				.pipe(
					switchMap((paEntityLinks: EntityLink[]) =>
						this.getEntityLinksEntities$<T>([lsDataId], paEntityLinks, pbLive, pbConflicts, poActivePageManager)
					),
					map((paLinkedEntities: T[]) => ArrayHelper.unique([...paLinkedEntities, ...laLinkedEntities], (poLinkedEntities: T) => poLinkedEntities._id))
				);
		}
	}

	private getLinkedEntitiesForMultipleItems<T extends Entity = Entity>(
		paItemIds: string[],
		paLinkedEntityPrefixes?: EPrefix[],
		peEntityLinkType?: EEntityLinkType,
		pbLive?: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, T[]>> {
		paItemIds.sort();
		return this.getEntityLinksFormMultipleItems$(paItemIds, paLinkedEntityPrefixes, peEntityLinkType, pbLive, poActivePageManager)
			.pipe(
				switchMap((paEntityLinks: EntityLink[]) => {
					return this.getEntityLinksEntities$<T>(paItemIds, paEntityLinks, pbLive, pbConflicts, poActivePageManager)
						.pipe(
							map((paEntities: T[]) =>
								this.groupEntitiesBySourceId<T>(paEntities, this.groupSourceIdsByTargetId(paItemIds, paEntityLinks))
							)
						);
				})
			);
	}

	private getLinkedEntitiesFromCacheData<T extends Entity = Entity>(poModel: IStoreDocument, paLinkedEntityPrefixes?: EPrefix[], peEntityLinkType: EEntityLinkType = EEntityLinkType.related): T[] {
		const laEntityLinksCache: IEntityLinkCache[] = this.getCachedEntities(poModel, EEntityLinkCacheData.Add);
		if (!ArrayHelper.hasElements(laEntityLinksCache))
			return [];

		return laEntityLinksCache
			.filter((poEntityLinkCache: IEntityLinkCache) => {
				if ((poEntityLinkCache.relationData[poEntityLinkCache.entity._id] ?? EEntityLinkType.related) !== peEntityLinkType)
					return false;

				const lsPrefix: EPrefix = IdHelper.getPrefixFromId(poEntityLinkCache.entity._id);
				if (ArrayHelper.hasElements(paLinkedEntityPrefixes) && !paLinkedEntityPrefixes.includes(lsPrefix))
					return false;

				return true;
			})
			.map((poEntityLinkCache: IEntityLinkCache) => poEntityLinkCache.entity as T)
	}

	public groupEntitiesBySourceId<T extends IStoreDocument = IStoreDocument>(
		paEntities: T[],
		poSourceIdsByTargetIds: Map<string, string[]>
	): Map<string, T[]> {
		const loEntitiesByItemIdMap = new Map<string, T[]>();

		paEntities.forEach((poEntity: T) => {
			poSourceIdsByTargetIds.get(poEntity._id)
				?.forEach((psSourceId: string) => {
					if (loEntitiesByItemIdMap.has(psSourceId))
						loEntitiesByItemIdMap.get(psSourceId).push(poEntity);
					else
						loEntitiesByItemIdMap.set(psSourceId, [poEntity]);
				});
		});

		return loEntitiesByItemIdMap;
	}

	/** Regroupe et retourne la map des identifiants cibles des liens d'entités en fonction de leur identifiant source.
	 * @param paEntityLinks Tableau des entités liées dont il faut regrouper les identifiants cibles par l'identifiant source.
	 */
	private groupTargetIdsBySourceId(
		paSortedSourceIds: string[],
		paEntityLinks: EntityLink[]
	): Map<string, string[]> {
		const loTargetIdsBySourceIds = new Map<string, string[]>();

		paEntityLinks.forEach((poEntityLink: EntityLink) => {
			const lbIsBetweenSources: boolean = this.isLinkBetweenSourceEntities(paSortedSourceIds, poEntityLink);
			poEntityLink.entities.forEach((poEntityA: IEntityLinkEntity) => {
				if (lbIsBetweenSources || ArrayHelper.binarySearch(paSortedSourceIds, poEntityA.id)) {
					let laTargetIds: string[] | undefined = loTargetIdsBySourceIds.get(poEntityA.id);
					if (!laTargetIds) {
						laTargetIds = [];
						loTargetIdsBySourceIds.set(poEntityA.id, laTargetIds);
					}
					poEntityLink.entities.forEach((poEntityB: IEntityLinkEntity) => {
						if (poEntityA !== poEntityB)
							laTargetIds.push(poEntityB.id);
					});
				}
			});
		});

		return loTargetIdsBySourceIds;
	}

	/** Regroupe et retourne la map des identifiants cibles des liens d'entités en fonction de leur identifiant source.
	 * @param paEntityLinks Tableau des entités liées dont il faut regrouper les identifiants cibles par l'identifiant source.
	 */
	private groupLinksBySourceId(
		paSortedSourceIds: string[],
		paEntityLinks: EntityLink[]
	): Map<string, EntityLink[]> {
		const loLinksBySourceIds = new Map<string, EntityLink[]>();

		paEntityLinks.forEach((poEntityLink: EntityLink) => {
			poEntityLink.entities.forEach((poEntityA: IEntityLinkEntity) => {
				if (ArrayHelper.binarySearch(paSortedSourceIds, poEntityA.id)) {
					let laLinks: EntityLink[] | undefined = loLinksBySourceIds.get(poEntityA.id);
					if (!laLinks) {
						laLinks = [];
						loLinksBySourceIds.set(poEntityA.id, laLinks);
					}
					laLinks.push(poEntityLink);
				}
			});
		});

		return loLinksBySourceIds;
	}

	/** Regroupe et retourne la map des identifiants sources des liens d'entités en fonction de leur identifiant cible.
	 * @param paEntityLinks Tableau des entités liées dont il faut regrouper les identifiants sources par l'identifiant cible.
	 */
	public groupSourceIdsByTargetId(
		paSortedSourceIds: string[],
		paEntityLinks: EntityLink[]
	): Map<string, string[]> {
		const loSourceIdsByTargetIds = new Map<string, string[]>();

		paEntityLinks.forEach((poEntityLink: EntityLink) => {
			poEntityLink.entities.forEach((poEntityA: IEntityLinkEntity) => {
				if (!ArrayHelper.binarySearch(paSortedSourceIds, poEntityA.id)) {
					poEntityLink.entities.forEach((poEntityB: IEntityLinkEntity) => {
						if (poEntityA !== poEntityB) {
							let laSourceIds: string[] | undefined = loSourceIdsByTargetIds.get(poEntityA.id);

							if (!laSourceIds) {
								laSourceIds = [];
								loSourceIdsByTargetIds.set(poEntityA.id, laSourceIds);
							}
							laSourceIds.push(poEntityB.id);
						}
					});
				}
			});
		});

		return loSourceIdsByTargetIds;
	}

	public getEntityLinksEntities$<T extends Entity = Entity>(
		paSortedItemIds: string[],
		paEntityLinks: EntityLink[],
		pbLive: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<T[]> {
		if (!ArrayHelper.hasElements(paEntityLinks))
			return of([]);

		const loEntitiesByDatabaseId = new Map<string, string[]>();
		const laEntities$: Observable<T[]>[] = [];

		paEntityLinks.forEach((poEntityLink: EntityLink) => {
			const lbIsBetweenSources: boolean = this.isLinkBetweenSourceEntities(paSortedItemIds, poEntityLink);
			poEntityLink.entities.forEach((poEntity: IEntityLinkEntity) => {
				if (
					lbIsBetweenSources ||
					!ArrayHelper.binarySearch(paSortedItemIds, poEntity.id)
				) {
					const laEntityIds: string[] = loEntitiesByDatabaseId.get(poEntity.databaseId);

					laEntityIds ? laEntityIds.push(poEntity.id) : loEntitiesByDatabaseId.set(poEntity.databaseId, [poEntity.id]);
				}
			});
		});

		loEntitiesByDatabaseId.forEach((paEntityIds: string[], psDatabaseId: string) => {
			const loDataSource: IDataSourceRemoteChanges = {
				databaseId: psDatabaseId,
				viewParams: {
					include_docs: true,
					keys: paEntityIds,
					conflicts: pbConflicts
				},
				live: pbLive,
				remoteChanges: !!poActivePageManager,
				activePageManager: poActivePageManager,
				baseClass: Entity
			};

			laEntities$.push(this.isvcStore.get<T>(loDataSource));
		});

		return combineLatest(laEntities$)
			.pipe(
				map((paResults: T[][]) => paResults.flat()),
				defaultIfEmpty([])
			);
	}

	private isLinkBetweenSourceEntities(paSortedSourceIds: string[], poEntityLink: EntityLink): boolean {
		return poEntityLink.entities.filter((poEntityLinkEntity: IEntityLinkEntity) =>
			ArrayHelper.binarySearch(paSortedSourceIds, poEntityLinkEntity.id)
		).length > 1; // Si + de 1 entité source, c'est que c'est un lien entre 2 entités sources
	}

	//#endregion

	//#endregion

}