import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { IDataSource } from '@calaosoft/osapp-common/store/models/IDataSource';
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { MapHelper } from '@calaosoft/osapp-common/utils/helpers/mapHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { AvatarHelper } from '@calaosoft/osapp/helpers/avatarHelper';
import { IContact } from '@calaosoft/osapp/model/contacts/IContact';
import { IContactCacheData } from '@calaosoft/osapp/model/contacts/IContactCacheData';
import { IGroupMember } from '@calaosoft/osapp/model/contacts/IGroupMember';
import { ActivePageManager } from '@calaosoft/osapp/model/navigation/ActivePageManager';
import { EAvatarSize } from '@calaosoft/osapp/model/picture/EAvatarSize';
import { IAvatar } from '@calaosoft/osapp/model/picture/IAvatar';
import { ERouteUrlPart } from '@calaosoft/osapp/model/route/ERouteUrlPart';
import { PermissionMissingError } from '@calaosoft/osapp/model/security/errors/PermissionMissingError';
import { IStoreDataResponse } from '@calaosoft/osapp/model/store/IStoreDataResponse';
import { Contact } from '@calaosoft/osapp/modules/contacts/models/contact';
import { IEntityDescriptor } from '@calaosoft/osapp/modules/entities/models/ientity-descriptor';
import { EntitiesUpdateService } from '@calaosoft/osapp/modules/entities/services/entities-update.service';
import { EntitiesService } from '@calaosoft/osapp/modules/entities/services/entities.service';
import { ILogSource } from '@calaosoft/osapp/modules/logger/models/ILogSource';
import { LogActionHandler } from '@calaosoft/osapp/modules/logger/models/log-action-handler';
import { LoggerService } from '@calaosoft/osapp/modules/logger/services/logger.service';
import { C_SECTORS_ROLE_ID, PermissionsService } from '@calaosoft/osapp/modules/permissions/services/permissions.service';
import { ISector } from '@calaosoft/osapp/modules/sectors/models/isector';
import { ContactsService } from '@calaosoft/osapp/services/contacts.service';
import { EntityLinkService } from '@calaosoft/osapp/services/entityLink.service';
import { GroupsService } from '@calaosoft/osapp/services/groups.service';
import { Store } from '@calaosoft/osapp/services/store.service';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, mapTo, mergeMap, switchMap, take } from 'rxjs/operators';
import { C_BUSINESS_PERMISSION_ID, C_PREFIX_BUSINESS } from '../../../app/app.constants';
import { Business } from '../model/business';
import { IBusiness } from '../model/ibusiness';

@Injectable({ providedIn: "root" })
export class BusinessesService implements ILogSource {

	//#region FIELDS

	public static readonly C_FILTER_CUSTOMERS_ID = "customersFilterId";

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId = "TRADE.BIZ.S::";
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region METHODS

	constructor(
		/** Service du store. */
		private readonly isvcStore: Store,
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcEntities: EntitiesService,
		private readonly isvcEntitiesUpdate: EntitiesUpdateService,
		private readonly ioRouter: Router,
		private readonly isvcContacts: ContactsService,
		private readonly isvcGroups: GroupsService,
		private readonly isvcPermissions: PermissionsService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
	) { }

	/** Suppression du client passé en paramètres.
	 * @param poBusiness Client à supprimer
	 * @param psDatabaseId id de la base de données sur laquelle il faut effectuer l'archivage.
	 */
	public deleteBusinessAsync(poBusiness: IBusiness): Promise<boolean> {
		return this.isvcEntities.getDescriptor$(
			"business",
			{ guid: IdHelper.getGuidFromId(poBusiness._id, C_PREFIX_BUSINESS) }
		)
			.pipe(
				take(1),
				mergeMap((poDescriptor: IEntityDescriptor) => {
					if (!this.isvcPermissions.evaluatePermission(C_BUSINESS_PERMISSION_ID, "delete", poDescriptor))
						return throwError(() => new PermissionMissingError(`Vous n'avez pas l'autorisation de supprimer le business ${poBusiness._id}`));
					return this.isvcEntitiesUpdate.deleteEntity(poBusiness, poDescriptor);
				}
				),
				map((poResponse: IStoreDataResponse) => poResponse.ok),
				catchError(poError => {
					console.error(`PAT.S::Erreur de suppression du customer ${poBusiness._id}.`, poError);

					return of(false);
				}),
				defaultIfEmpty(false)
			).toPromise();
	}

	/** Va sur la page de visu d'un business.
	 * @param poBusiness Business vers lequel naviguer.
	 */
	public goToBusinessAsync(poBusiness: IBusiness, psSlide?: string): Promise<boolean>;
	/** Va sur la page de visu d'un business.
	 * @param poCustomer Identifiant du business vers lequel naviguer.
	 */
	public goToBusinessAsync(psBusinessId: string, psSlide?: string): Promise<boolean>;
	public goToBusinessAsync(poBusinessOrBusinessId: IBusiness | string, psSlide?: string): Promise<boolean> {
		const lsBusinessGuid: string = IdHelper.getGuidFromId(
			typeof poBusinessOrBusinessId === "string" ?
				poBusinessOrBusinessId :
				poBusinessOrBusinessId._id,
			C_PREFIX_BUSINESS
		);

		return this.ioRouter.navigate(["businesses", lsBusinessGuid], { queryParams: { slide: psSlide } });
	}

	/** Navigue vers la page de création d'un client.
	 * @param poBusiness Modèle du client à créer (si des renseignement à son sujet sont déjà disponibles).
	 */
	public goToNewBusinessAsync(poBusiness?: IBusiness): Promise<boolean> {
		const loExtras: NavigationExtras | undefined = poBusiness ? { state: { model: poBusiness } } : undefined;
		return this.ioRouter.navigate(["businesses", ERouteUrlPart.new], loExtras);
	}

	/** Va sur la page d'edit d'un business.
	 * @param psBusinessId Identifiant du business vers lequel naviguer.
	 */
	public goToBusinessEditAsync(psBusinessId: string): Promise<boolean> {
		return this.ioRouter.navigate(["businesses", IdHelper.getGuidFromId(psBusinessId, C_PREFIX_BUSINESS), ERouteUrlPart.edit]);
	}

	/** Récupère un business par son identifiant.
	 * @param psCustomerId Identifiant du business à récupérer.
	 * @param pbLive
	 */
	public getBusiness$(psCustomerId: string): Observable<Business> {
		return this.isvcStore.getOne({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				key: psCustomerId
			},
			baseClass: Business,
			live: true
		}, false);
	}

	/** Récupère un business par son identifiant.
	 * @param psCustomerId Identifiant du business à récupérer.
	 * @param pbLive
	 */
	public getBusinessAsync(psCustomerId: string): Promise<Business> {
		return this.isvcStore.getOne({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				key: psCustomerId
			},
			baseClass: Business
		}, false).toPromise();
	}

	/** Récupère les customers. */
	public getCustomersByIds(paIds: string[], pbLive?: boolean): Observable<Business[]> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				keys: paIds
			},
			live: pbLive,
			baseClass: Business
		};

		return this.isvcStore.get<Business>(loDataSource);
	}

	/** Récupère la liste de tout les businesses */
	private getAllBusinesses$(pbLive?: boolean): Observable<Business[]> {
		return this.isvcStore.get({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				startkey: C_PREFIX_BUSINESS,
				endkey: C_PREFIX_BUSINESS + Store.C_ANYTHING_CODE_ASCII,
			},
			baseClass: Business,
			live: pbLive
		});
	}

	/** Récupère la liste des businesses du site courant. */
	public getSiteBusinesses$(pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Business[]> {
		if (ConfigData.appInfo.useSite) {
			return this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID], pbLive, poActivePageManager)
				.pipe(
					switchMap((paSectors: ISector[]) => this.isvcContacts.getSiteContacts(paSectors, C_PREFIX_BUSINESS, pbLive, poActivePageManager) as Observable<Business[]>),
					switchMap((paBusinesses: Business[]) => {
						const laBusinessIds: string[] = [];
						const laLinkedContactIds: string[] = [];

						paBusinesses.forEach((poBusiness: Business) => {
							laBusinessIds.push(poBusiness._id);
							laLinkedContactIds.push(...(poBusiness.contacts ?? []));
						});

						return this.isvcEntityLink.getLinkedEntityIds(laBusinessIds, [EPrefix.contact], undefined, pbLive, poActivePageManager).pipe(
							switchMap((poContactIdsByBusinessId: Map<string, string[]>) => {
								laLinkedContactIds.push(...MapHelper.valuesToArray(poContactIdsByBusinessId).flat());

								return this.isvcContacts.getContactById(laLinkedContactIds, undefined, pbLive, poActivePageManager).pipe(
									map((poContactById: Map<string, Contact>) => {
										this.fillBusinessesWithContacts(paBusinesses, poContactIdsByBusinessId, poContactById);

										return paBusinesses;
									})
								);
							})
						);
					})
				);
		}
		else {
			return combineLatest([this.getAllBusinesses$(pbLive), this.isvcPermissions.observableUpdatePermissions.value$]).pipe(
				map(([paBusinesses]): Business[] => paBusinesses.filter(((poBusiness: Business) => this.isvcPermissions.evaluatePermission(C_BUSINESS_PERMISSION_ID, "read", poBusiness))))
			);
		}
	}

	private fillBusinessesWithContacts(
		paBusinesses: Business[],
		poContactIdsByBusinessId: Map<string, string[]>,
		poContactById: Map<string, Contact>
	): void {
		paBusinesses.forEach((poBusiness: Business) => {
			const laContactsIds: string[] = ArrayHelper.unique(
				[...(poContactIdsByBusinessId.get(poBusiness._id) ?? []), ...(poBusiness.contacts ?? [])]
			);

			const laContacts: Contact[] = [];

			laContactsIds.forEach((psId: string) => {
				const loContact: Contact | undefined = poContactById.get(psId);
				if (loContact)
					laContacts.push(loContact);
			});

			poBusiness.linkedContacts = laContacts;
		});
	}

	/** Retourne `true` si le membre générique est un customer, `false` sinon.
	 * @param poMember Membre générique dont il faut vérifier s'il est un customer ou non.
	 */
	public static isCustomer(poMember: IGroupMember): boolean {
		return IdHelper.hasPrefixId(poMember._id, C_PREFIX_BUSINESS);
	}

	/** Sauveagarde en cache les informations sur l'adresse du Contact.
	 * @param poContact
	 */
	public saveAdressCacheData(poCustomer: IBusiness): void {
		this.isvcContacts.saveAdressCacheData(poCustomer);
	}

	/** Si on detecte un changement dans l'adresse on supprime les données GPS pour ne plus se baser dessus car elles son prioritaire.
	 * @param poContact
	 * @param poContactCacheData
	 */
	public deleteGPSDataIfNeeded(poContact: IContact, poContactCacheData?: IContactCacheData): boolean {
		return this.isvcContacts.deleteGPSDataIfNeeded(poContact, poContactCacheData);
	}

	/** Si le contact est un homme on supprime le nom de jeune fille.
	 * @param poContact
	 */
	public deleteMaidenNameIfNeeded(poContact: IContact): boolean {
		return this.isvcContacts.deleteMaidenNameIfNeeded(poContact);
	}

	/** Crée l'avatar d'un business.
	 * @param poBusiness
	 * @param peAvatarSize
	 * @param pbUseIconAsDefaultFallback Indique si l'icône doit être la solution de remplacement si l'image n'est pas disponible.
	 */
	public static createBusinessAvatar(poBusiness: IBusiness, peAvatarSize: EAvatarSize = EAvatarSize.big, pbUseIconAsDefaultFallback?: boolean): IAvatar {
		const loAvatar: IAvatar = {
			...AvatarHelper.createAvatarFromPicture(poBusiness.picture, peAvatarSize),
			text: poBusiness.name,
			icon: "business",
			useIconAsDefaultFallback: pbUseIconAsDefaultFallback
		};

		return loAvatar;
	}

	//#region SEARCH

	/** Récupère l'identifiant du secteur lié au customer.
	 * @param psCustomerId
	 * @returns
	 */
	public getCustomerSectorId(psCustomerId: string): Observable<string> {
		return this.isvcGroups.getContactGroupsIds(psCustomerId)
			.pipe(map((paCustomerGroupIds: string[]) => ArrayHelper.getFirstElement(paCustomerGroupIds)));
	}

	/** Récupère l'identifiant du secteur lié au customer.
	 * @param paCustomerIds
	 * @param pbLive
	 * @returns
	 */
	public getCustomersSectorIds(paCustomerIds: string[], pbLive?: boolean): Observable<Map<string, string>> {
		return this.isvcGroups.getContactsGroupIds(paCustomerIds, pbLive)
			.pipe(map((poGroupIdsByCustomerId: Map<string, string[]>) => MapHelper.map(poGroupIdsByCustomerId, (paCustomerGroupIds: string[]) => ArrayHelper.getFirstElement(paCustomerGroupIds))));
	}

	/** Sauvegarde un customer.
	 * @param poCustomer
	 */
	public saveCustomer(poCustomer: Business, pbExport?: boolean): Observable<boolean> {

		if (StringHelper.isBlank(poCustomer._id))
			poCustomer._id = IdHelper.buildId(C_PREFIX_BUSINESS);

		return this.isvcStore.put(poCustomer)
			.pipe(
				mergeMap((poResponse: IStoreDataResponse) => {
					if (ConfigData.appInfo.useLinks)
						return this.isvcEntityLink.saveEntityLinks(poCustomer).pipe(mapTo(poResponse));
					else
						return of(poResponse);
				}),
				map((poResponse: IStoreDataResponse) => poResponse.ok),
				filter((pbOk: boolean) => pbOk),
				defaultIfEmpty(false)
			);
	}

	//#endregion

	//#endregion
}