import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { plainToClass } from "@calaosoft/osapp-common/class-transformer";
import { ConfigData } from "@calaosoft/osapp-common/config/models/ConfigData";
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Store } from "../../../services/store.service";
import { WorkspaceService } from "../../../services/workspace.service";
import { AuthenticatedRequestOptionBuilder } from "../../api/models/authenticated-request-option-builder";
import { EOptimizationStatusCode } from "../models/eoptimization-status-code";
import { TourOptimizationRequestDTO } from "../models/tour-optimization-request-dto";
import { TourOptimizationResultDTO } from "../models/tour-optimization-result-dto";
import { ITourOptimizationResultDTO } from "./../models/itour-optimization-result-dto";

@Injectable()
export class TourOptimizationService {
	//#region METHODS
	public constructor(
		private ioHttpClient: HttpClient,
		private isvcStore: Store,
		private isvcWorkspace: WorkspaceService
	) { }

	public optimize(
		poTourOptimizable: TourOptimizationRequestDTO,
		psApp: string
	): Observable<TourOptimizationResultDTO> {
		return this.requestOptimizationAPI(poTourOptimizable, psApp);
	}

	public requestOptimizationAPI(
		poData: TourOptimizationRequestDTO,
		psApp: string
	): Observable<TourOptimizationResultDTO> {
		const lsWorkspace: string | null = this.isvcWorkspace.getWorkspaceIdFromDatabaseId(
			ArrayHelper.getFirstElement(
				this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)
			)
		);

		const lsUrl = `${ConfigData.environment.cloud_url}/api/apps/${psApp}/workspaces/${lsWorkspace}/entities/tours/optimize`;
		return this.ioHttpClient
			.post<ITourOptimizationResultDTO>(
				lsUrl,
				poData,
				AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
			)
			.pipe(
				catchError((poError: HttpErrorResponse): Observable<ITourOptimizationResultDTO> => {
					return of({
						code: EOptimizationStatusCode.invalid,
						error: poError.message,
						resourcesTours: {},
					});
				}),
				map((poTourOptimized: ITourOptimizationResultDTO): TourOptimizationResultDTO => plainToClass(TourOptimizationResultDTO, poTourOptimized))
			);
	}

	//#endregion
}
