import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { InAppBrowser } from '@capgo/inappbrowser';
import { HttpHelper } from '../../../helpers/http.helper';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { PlatformService } from '../../../services/platform.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { PermissionsService } from '../../permissions/services/permissions.service';

@Injectable()
export class BrowserService {

	//#region METHODS

	public constructor(
		private readonly isvcRouter: Router,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcPermissions: PermissionsService
	) { }

	/** Ouvre une URL directement dans l'app mobile ou dans un nouvel onglet sur webapp, s'il n'y a pas de restrictions d'URL.
	 * @param psUrl Url à ouvrir.
	 * @returns `true` si l'ouverture/navigation a eu lieu, `false` sinon.
	 */
	public openUrlAsync(psUrl: string): Promise<boolean> {
		const lsPath: string = HttpHelper.getPath(psUrl);

		// Si c'est un lien de l'app sur laquelle on se trouve, on fait une navigation angular.
		if (HttpHelper.isSameDomain(psUrl) && !StringHelper.isBlank(lsPath))
			return this.isvcRouter.navigate([lsPath]);

		// Si la route demandée n'est pas autorisée par les permissions, on affiche une popup et on ne navigue pas.
		else if (!this.isvcPermissions.isBrowserNavigationAllowed(psUrl)) {
			return this.isvcUiMessage.showPopupMessageAsync(
				new ShowMessageParamsPopup({
					header: "Accès bloqué",
					message: "Vous n'êtes pas autorisé à ouvrir ce lien externe."
				})
			)
				.then(_ => false);
		}

		// Ouverture intra-app car certains utilisateurs n'ont pas accès au navigateur (merchs de Textilot par exemple).
		else if (this.isvcPlatform.isMobileApp)
			return InAppBrowser.openWebView({ url: psUrl, title: "Contenu web externe" });

		else { // Sur webapp, on ouvre dans un nouvel onglet.
			window.open(psUrl);
			return Promise.resolve(true);
		}
	}

	//#endregion METHODS

}