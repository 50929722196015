import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EntityLinkService } from '@calaosoft/osapp/services/entityLink.service';
import { map, Observable } from 'rxjs';
import { C_PREFIX_OPPORTUNITY } from '../../../app/app.constants';
import { EOpportunityStep } from '../models/eopportunity-step';
import { IOpportunity } from '../models/iopportunity';

@Injectable()
export class OpportunitiesService {

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcRouter: Router
	) { }


	public getPendingOpportunitiesCount$(psItemId: string, pbLive = false): Observable<number> {
		return this.isvcEntityLink.getLinkedEntities(psItemId, [C_PREFIX_OPPORTUNITY], undefined, pbLive).pipe(
			map((paOpportunities: IOpportunity[]) => this.getPendingOpportunities(paOpportunities)),
			map((paOpportunities: IOpportunity[]) => paOpportunities.length)
		);
	}

	private getPendingOpportunities(paOpportunities: IOpportunity[]): IOpportunity[] {
		const laClosedStates: EOpportunityStep[] = [EOpportunityStep.won, EOpportunityStep.canceled, EOpportunityStep.lost];
		return paOpportunities.filter((poOpportunity: IOpportunity) => !laClosedStates.some((poState: EOpportunityStep) => poState === poOpportunity.step));
	}

	public navigateToOpportunitiesAsync(): Promise<boolean> {
		return this.isvcRouter.navigate(["businesses"], {
			queryParams: { tab: "1" }
		});
	}

	//#endregion METHODS

}
