import { Component, OnDestroy } from "@angular/core";
import { Destroyable } from "@calaosoft/osapp-common/destroyable/classes/destroyable";
import { GuidHelper } from "@calaosoft/osapp-common/guid/helpers/guidHelper";

@Component({ template: "" })
export abstract class DestroyableComponentBase extends Destroyable implements OnDestroy {

	//#region FIELDS

	protected msInstanceId: string = GuidHelper.newGuid();

	//#endregion

	//#region METHODS

	constructor() {
		super();
	}

	/** Récupère l'identifiant de l'instance. */
	public getInstanceId(): string {
		return this.msInstanceId;
	}

	public ngOnDestroy(): void {
		this.destroy();
	}

	//#endregion METHODS

}