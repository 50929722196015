import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'calao-dashboard-tiles',
	templateUrl: './dashboard-tiles.component.html',
	styleUrls: ['./dashboard-tiles.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'",
		"[style.flex-direction]": "'row'",
		"[style.align-items]": "'center'",
		"[style.width]": "'100%'"
	}
})
export class DashboardTilesComponent { }
