import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { EByteToUnit } from '../model/helper/EByteToUnits';
import { EPointsToUnit } from '../model/helper/EPointsToUnit';
import { ETimeToUnit } from '../model/helper/ETimeToUnit';


/** Permet de mettre à disposition des méthodes pour aider à convertir des valeurs d'objets. */
export abstract class ConvertHelper {

	//#region Length

	/** Convertit une valeur 'pt' vers une autre valeur de mesure.
	 * @param pnValue Valeur à convertir.
	 * @param peUnit Unité de la valeur souhaitée.
	 */
	public static pointsToUnit(pnValue: number, peUnit: EPointsToUnit): number {
		let lnMultiplier: number;

		switch (peUnit) {
			case EPointsToUnit.pt: lnMultiplier = 1; break;
			case EPointsToUnit.mm: lnMultiplier = 72 / 25.4; break;
			case EPointsToUnit.cm: lnMultiplier = 72 / 2.54; break;
			case EPointsToUnit.in: lnMultiplier = 72; break;
			case EPointsToUnit.px: lnMultiplier = 96 / 72; break;
			case EPointsToUnit.pc: lnMultiplier = 12; break;
			case EPointsToUnit.em: lnMultiplier = 12; break;
			case EPointsToUnit.ex: lnMultiplier = 6; break;
			default: throw new Error(`Unité '${peUnit}' non prise en charge.`);
		}

		return pnValue * lnMultiplier;
	}

	//#endregion

	//#region Bytes

	/** Convertit la valeur passée en paramètre (en octets) dans une autre unité de mesure.
	 * @param pnValue Valeur à convertir, en octets.
	 * @param peUnit Unité de la valeur souhaitée.
	 * @exemple ConvertHelper.byteToUnit(5340000, EByteToUnit.mo); // Retournera 5.09
	 */
	public static byteToUnit(pnValue: number, peUnit: EByteToUnit): number {
		let lnDiviser: number;

		switch (peUnit) {
			case EByteToUnit.o: lnDiviser = 1; break;
			case EByteToUnit.ko: lnDiviser = 1024; break;
			case EByteToUnit.mo: lnDiviser = 1049000; break;
			case EByteToUnit.go: lnDiviser = 1074000000; break;
			case EByteToUnit.to: lnDiviser = 1100000000000; break;
			case EByteToUnit.po: lnDiviser = 1126000000000000; break;
			default: throw new Error(`Unité '${peUnit}' non prise en charge.`);
		}

		return pnValue / lnDiviser;
	}

	//#endregion Bytes

	//#region Time

	/** Convertit la valeur passée en paramètre (en millisecondes) dans une autre unité de mesure.
	 * @param pnValue Valeur à convertir, en millisecondes.
	 * @param peUnit Unité de la valeur souhaitée.
	 */
	public static timeToUnit(pnValue: number, peUnit: ETimeToUnit): number {
		let lnDiviser: number;

		switch (peUnit) {
			case ETimeToUnit.ms: lnDiviser = 1; break;
			case ETimeToUnit.s: lnDiviser = 1000; break;
			case ETimeToUnit.mn: lnDiviser = 60000; break;
			case ETimeToUnit.h: lnDiviser = 3600000; break;
			case ETimeToUnit.j: lnDiviser = 86400000; break;
			default: throw new Error(`Unité '${peUnit}' non prise en charge.`);
		}

		return pnValue / lnDiviser;
	}

	/** Donne l'unité de conversion la plus adaptée pour un temps en millisecondes.  */
	public static timeToEnum(pnValue: number): ETimeToUnit {
		if (pnValue < 1000)
			return ETimeToUnit.ms;
		else if (pnValue < 60000)
			return ETimeToUnit.s;
		else if (pnValue < 3600000)
			return ETimeToUnit.mn;
		else if (pnValue < 86400000)
			return ETimeToUnit.h;
		else return ETimeToUnit.j;
	}

	//#endregion Time

	//#region Trigonometry

	/** Convertit une valeur en degré vers une valeur en radian et la retourne, retourne `NaN` si le paramètre n'est pas correct.
	 * @param pnValue Valeur en degré qu'il faut convertir en radian.
	 */
	public static degreeToRadian(pnValue: number): number {
		if (NumberHelper.isValid(pnValue))
			return pnValue * Math.PI / 180;
		else
			return NaN;
	}

	/** Convertit une distance angulaire en radians vers une distance en kilomètres, `NaN` si le paramètre est négatif ou non valide.
	 * @param pnValue Valeur à convertir.
	 */
	public static angularDistanceRadianToKilometers(pnValue: number): number {
		return NumberHelper.isValidPositive(pnValue) ? pnValue * 6378.137 : NaN; // 6378.137 == rayon de la Terre en kms.
	}

	/** Convertit une distance angulaire en radians vers une distance en mètres, `NaN` si le paramètre est négatif ou non valide.
	 * @param pnValue Valeur à convertir.
	 */
	public static angularDistanceRadianToMeters(pnValue: number): number {
		return NumberHelper.isValidPositive(pnValue) ? pnValue * 6378137 : NaN; // 6378137 == rayon de la Terre en mètres.
	}

	//#endregion Trigonometry

}