import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { EDateTimePickerMode } from '@calaosoft/osapp-common/dates/models/EDateTimePickerMode';
import { EStartView } from '@calaosoft/osapp-common/dates/models/EStartView';
import { ETimetablePattern } from '@calaosoft/osapp-common/dates/models/ETimetablePattern';
import { IDateTimePickerParams } from '@calaosoft/osapp-common/dates/models/IDateTimePickerParams';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { CapacitorException, ExceptionCode } from '@capacitor/core';
import { DatetimePicker, PresentOptions, PresentResult } from '@capawesome-team/capacitor-datetime-picker';
import { PickerOptions } from '@ionic/core/dist/types/components/picker/picker-interface';
import { ComponentBase } from '../../helpers/ComponentBase';
import { EModalSize } from '../../modules/modal/model/EModalSize';
import { ModalService } from '../../modules/modal/services/modal.service';
import { PatternsHelper } from '../../modules/utils/helpers/patterns.helper';
import { PatternResolverService } from '../../services/pattern-resolver.service';
import { BrowserTimePickerComponent } from './browser-time-picker/browser-time-picker.component';

@Component({
	selector: "osapp-date-time",
	templateUrl: './dateTimePicker.component.html',
	styleUrls: ['./dateTimePicker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimePickerComponent extends ComponentBase {

	//#region FIELDS

	/** Heure au début de la création du modèle. Utilisé pour vérifier que l'heure actuelle est celle par défaut.
	 * TODO: S'il y a des intervalles de minutes, et que l'heure actuelle n'est pas dans l'intervalle, alors l'heure sélectionnée sera 0.
	 * TODO: Initialiser l'heure de `mdCurrentDate` à la valeur de l'intervalle le plus proche.
	 */
	private mdCurrentDate = new Date();
	private msSelectedTime: string;

	/** Événement qui notifie que le modèle a changé. */
	@Output("modelChange") private readonly moModelChangeEvent: EventEmitter<Date> = new EventEmitter();
	/** Événement qui notifie qu'une date a été sélectionnée. */
	@Output("dateSelected") private readonly moDateSelectedEvent: EventEmitter<Date> = new EventEmitter();

	//#endregion

	//#region PROPERTIES

	/** Modèle sur lequel se baser pour le fonctionnement du composant. */
	private mdModel: Date | null = null;
	public get model(): Date | null {
		return this.mdModel;
	}
	@Input()
	public set model(pdModel: Date | null) {
		if (pdModel && DateHelper.isDate(pdModel) && pdModel !== this.mdModel) {
			this.mdModel = new Date(pdModel);
			this.msIsoModel = DateHelper.formatIsoTimeZone(this.mdModel);
			this.msSelectedTime = DateHelper.getHoursAndMinutes(this.mdModel);
			this.detectChanges();
		}
	}

	private moParams: IDateTimePickerParams | undefined;
	/** Paramètres possibles pour le composant. */
	public get params(): IDateTimePickerParams | undefined { return this.moParams; }
	@Input() public set params(poParams: IDateTimePickerParams) {
		if (poParams !== this.moParams) {
			this.moParams = poParams;
			this.initParams();
			this.prepareTimePickerOptions();

			if (!this.model && this.params?.autoFill)
				this.moModelChangeEvent.emit(this.model = this.mdCurrentDate);

			this.detectChanges();
		}
	}

	public get time(): string {
		return this.msSelectedTime;
	}

	private mbDisabled: boolean | undefined;
	/** @implements */
	public get disabled(): boolean | undefined { return this.mbDisabled; }
	@Input() public set disabled(pbDisabled: boolean | string) {
		if (pbDisabled !== this.mbDisabled) {
			this.mbDisabled = coerceBooleanProperty(pbDisabled);
			this.detectChanges();
		}
	}

	@ViewChild(MatDatepicker) public datePicker: MatDatepicker<Date>;

	/** Retourne la date du modèle, si non définit. */
	private msIsoModel = "";
	public get isoModel(): string {
		return this.msIsoModel;
	}
	public set isoModel(psIsoModel: string) {
		if (DateHelper.isDate(psIsoModel) && psIsoModel !== this.msIsoModel) {
			this.msIsoModel = psIsoModel;
			this.mdModel = new Date(this.msIsoModel);
			this.detectChanges();
		}
	}

	public get min(): Date | undefined {
		return StringHelper.isValid(this.params?.min) ? new Date(this.params!.min) : undefined;
	}

	public get max(): Date | undefined {
		return StringHelper.isValid(this.params?.max) ? new Date(this.params!.max) : undefined;
	}
	public get mobileMinTime(): string | undefined {
		return this.min && this.model && DateHelper.diffDays(this.model, this.min) === 0 ?
			this.min.toISOString() :
			undefined;
	}

	public get mobileMaxTime(): string | undefined {
		return this.max && this.model && DateHelper.diffDays(this.model, this.max) === 0 ?
			this.max.toISOString() :
			undefined;
	}

	public get minHourMinutes(): string | undefined {
		return StringHelper.isValid(this.params?.minHour) ? this.params!.minHour : undefined;
	}

	public get maxHourMinutes(): string | undefined {
		return StringHelper.isValid(this.params?.maxHour) ? this.params!.maxHour : undefined;
	}

	public get startView(): EStartView {
		return StringHelper.isValid(this.params?.startView) ? this.params!.startView : EStartView.month;
	}

	public timePickerOptions: PickerOptions;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcModal: ModalService,
		private readonly isvcPatternResolver: PatternResolverService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	/** Initialise tous les paramètres avec ceux présents dans les data du templateOptions. */
	private initParams(): void {
		if (!this.params)
			this.params = {};

		if (StringHelper.isBlank(this.params.label))
			this.params.label = "";

		if (StringHelper.isBlank(this.params.doneText))
			this.params.doneText = DateHelper.C_DONE_TEXT;

		if (StringHelper.isBlank(this.params.cancelText))
			this.params.cancelText = DateHelper.C_CANCEL_TEXT;

		if (!this.params.displayFormat)
			this.params.displayFormat = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;

		if (StringHelper.isBlank(this.params.pickerMode))
			this.params.pickerMode = EDateTimePickerMode.datetime;

		if (!ArrayHelper.hasElements(this.params.dayNames))
			this.params.dayNames = DateHelper.C_DAY_NAMES;

		if (!ArrayHelper.hasElements(this.params.dayShortNames))
			this.params.dayShortNames = DateHelper.C_DAY_SHORT_NAMES;

		if (!ArrayHelper.hasElements(this.params.monthNames))
			this.params.monthNames = DateHelper.C_MONTH_NAMES;

		if (!ArrayHelper.hasElements(this.params.monthShortNames))
			this.params.monthShortNames = DateHelper.C_MONTH_SHORT_NAMES;

		// Si la date min est renseignée.
		if (ObjectHelper.isDefined(this.params.min) && this.params.min !== "") {
			this.params.min = this.transformDateWithIsoFormat(
				typeof this.params.min === "string" && PatternsHelper.hasPattern(this.params.min) ? new Date(this.isvcPatternResolver.replaceDynParams(this.params.min)) : new Date(this.params.min)
			);
		}

		// Si la date max est renseignée.
		if (ObjectHelper.isDefined(this.params.max) && this.params.max !== "") {
			this.params.max = this.transformDateWithIsoFormat(
				typeof this.params.max === "string" && PatternsHelper.hasPattern(this.params.max) ? new Date(this.isvcPatternResolver.replaceDynParams(this.params.max)) : new Date(this.params.max)
			);
		}

		if (StringHelper.isBlank(this.params.icon))
			this.params.icon = DateHelper.C_DEFAULT_ICON;

		if (this.disabled === undefined)
			this.disabled = false;
	}

	private prepareTimePickerOptions(): void {
		this.timePickerOptions = {
			buttons: [
				{ role: "cancel", text: this.params?.cancelText },
				{
					role: "submit",
					text: this.params?.doneText,
					handler: (poNewDate: { hour: { value: number }, minute: { value: number } }) => {
						const ldNewDate: Date = new Date(this.model);
						ldNewDate.setHours(poNewDate.hour.value, poNewDate.minute.value);
						this.model = ldNewDate;
						this.moModelChangeEvent.emit(ldNewDate);
					}
				}
			],
			columns: undefined
		};
	}

	/** Transforme une date au format ISO8601.
	 * @param pdDate Date à transformer.
	 */
	private transformDateWithIsoFormat(pdDate: Date): string {
		// Retourne un résultat différent si le format contient des heures ou non.
		if (this.params?.displayFormat?.indexOf("HH") === -1)
			return DateHelper.transform(pdDate, ETimetablePattern.isoFormat_hyphen);
		else
			return DateHelper.formatIsoTimeZone(pdDate);
	}

	public pickDate(): void {
		if (!this.disabled) { // Si le composant n'est pas désactivé, on poursuit le traitement pour afficher une date.

			let loPickerOptions: PresentOptions = {
				mode: this.params?.pickerMode,
				value: DateHelper.transform(this.model ?? new Date(), ETimetablePattern.isoFormat_hyphenWithHours),
				locale: "fr-FR",
				format: ETimetablePattern.isoFormat_hyphenWithHours,
				cancelButtonText: "Annuler"
			}

			if (this.min) loPickerOptions.min = DateHelper.transform(this.min, ETimetablePattern.isoFormat_hyphenWithHours);
			if (this.max) loPickerOptions.max = DateHelper.transform(this.max, ETimetablePattern.isoFormat_hyphenWithHours);

			DatetimePicker.present(loPickerOptions)
				.then((poResult: PresentResult) => {
					this.moModelChangeEvent.emit(new Date(poResult.value));
				})
				.catch((poError: any) => {
					if (poError instanceof CapacitorException && poError.code === ExceptionCode.Unimplemented)
						(this.params?.pickerMode !== EDateTimePickerMode.time) ? this.datePicker.open() : this.pickHourAsync();
				});
		}
	}

	private onDateChanged(poDate: Date): void {
		this.moDateSelectedEvent.emit(new Date(poDate));
		if (DateHelper.compareTwoDates(poDate, this.model) !== 0) {
			this.model = poDate;
			this.moModelChangeEvent.emit(this.model);
		}
	}

	/** La date a été modifiée (jour).
	 * @param poDate La date est `null` si l'utilisateur n'a pas sélectionné de date et a directement cliqué sur "valider" et qu'il n'y a pas encore de date affectée.
	 */
	public onDateInputValueChanged(poDate: Date | null): void {
		const ldNewDate: Date = poDate === null ? new Date() : poDate;

		if (this.model)
			ldNewDate.setHours(this.model.getHours(), this.model.getMinutes());

		if (this.params?.pickerMode === EDateTimePickerMode.datetime) {
			this.model = ldNewDate;
			this.pickHourAsync();
		}
		else
			this.onDateChanged(ldNewDate);
	}

	private async pickHourAsync(): Promise<void> {
		const ldNewDate: Date = new Date(await this.getBrowserTimePickerComponentResultAsync());

		if (DateHelper.isDate(ldNewDate)) {
			this.model = ldNewDate;
			this.moModelChangeEvent.emit(this.model);
		}
	}

	private getBrowserTimePickerComponentResultAsync(): Promise<Date> {
		return this.isvcModal.open<Date>(
			{
				component: BrowserTimePickerComponent,
				componentProps: {
					date: this.model,
					min: this.min,
					max: this.max
				}
			},
			EModalSize.timePicker
		).toPromise();
	}

	//#endregion

}