import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'calao-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'",
		"[style.flex-direction]": "'column'"
	}
})
export class DashboardComponent { }
