import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IApplicationRole } from "@calaosoft/osapp-common/security/models/IApplicationRole";
import { ISelectTag } from '../../../../model/forms/ISelectTag';
import { PermissionsService } from '../../../../modules/permissions/services/permissions.service';
import { FormsService } from '../../../../services/forms.service';
import { GroupsService } from '../../../../services/groups.service';
import { SelectFieldComponent } from './select-field.component';

@Component({
	selector: 'deprecated-formly-field-permission-roles-select',
	templateUrl: './select-field.component.html',
	styleUrls: ['./select-field.component.scss']
})
export class PermissionRolesSelectFieldComponent extends SelectFieldComponent implements OnInit {

	//#region METHODS

	constructor(
		protected override readonly isvcPermissions: PermissionsService,
		psvcForms: FormsService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(isvcPermissions, psvcForms, poChangeDetector);
	}

	public override ngOnInit(): void {
		if (this.to.data)
			this.to.data.options = this.getOptions();
		else
			this.to.data = { options: this.getOptions() };

		super.ngOnInit();
	}

	private getOptions(): ISelectTag[] {
		const laAllowedRoles: IApplicationRole[] = this.isvcPermissions.getPermissionRolesThatUserCanAssign();
		const laRoles: IApplicationRole[] = this.isvcPermissions.getPermissionRoles(undefined, GroupsService.C_EXCLUDE_ROLE_IDS);

		return laRoles.map((poRole: IApplicationRole) =>
			({ id: poRole.id, value: poRole.id, label: poRole.label, disabled: !laAllowedRoles.some((poAllowedRole: IApplicationRole) => poAllowedRole.id === poRole.id) })
		);
	}

	//#endregion

}