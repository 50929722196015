import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { IState } from '@calaosoft/osapp-common/entities/models/istate';
import { StateMachine } from '@calaosoft/osapp-common/entities/models/state-machine';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EMPTY, Observable, defer, firstValueFrom, of } from 'rxjs';
import { filter, finalize, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { IPopoverItemParams } from '../../../../model/popover/IPopoverItemParams';
import { IStoreDataResponse } from '../../../../model/store/IStoreDataResponse';
import { ShowMessageParamsToast } from '../../../../services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '../../../../services/loading.service';
import { PopoverService } from '../../../../services/popover.service';
import { Store } from '../../../../services/store.service';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { Loader } from '../../../loading/Loader';
import { ModalService } from '../../../modal/services/modal.service';
import { PermissionsService } from '../../../permissions/services/permissions.service';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { EEntityEntriesListItemAction } from '../../models/eentity-entries-list-item-action';
import { IEntityAction } from '../../models/ientity-action';
import { IEntityDescriptor } from '../../models/ientity-descriptor';
import { IEntityEntriesListDefinition } from '../../models/ientity-entries-list-definition';
import { IEntityEntriesListParams } from '../../models/ientity-entries-list-params';
import { IEntityEntriesListParamsCreateParams } from '../../models/ientity-entries-list-params-create-params';
import { EntitiesUpdateService } from '../../services/entities-update.service';
import { EntitiesService } from '../../services/entities.service';
import { EntityPickerModalComponent } from '../entity-picker-modal/entity-picker-modal.component';

@Component({
	selector: 'calao-entity-entries-list',
	templateUrl: './entity-entries-list.component.html',
	styleUrls: ['./entity-entries-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityEntriesListComponent<T extends Entity> extends DestroyableComponentBase {

	//#region FIELDS

	private moDescriptor?: IEntityDescriptor;
	private moListDefinition?: IEntityEntriesListDefinition;

	//#endregion FIELDS

	//#region PROPERTIES

	/** Paramètres du formList. */
	@Input() public params?: IEntityEntriesListParams<T>;
	@ObserveProperty<EntityEntriesListComponent<T>>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<IEntityEntriesListParams<T>>();

	/** Clé de tri. */
	@Input() public sortKey?: keyof T;
	@ObserveProperty<EntityEntriesListComponent<T>>({ sourcePropertyKey: "sortKey" })
	public readonly observableSortKey = new ObservableProperty<keyof T>();

	public readonly observableHasDetail = new ObservableProperty<boolean>();

	public readonly observableCanCreate = new ObservableProperty<boolean>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntities: EntitiesService,
		private readonly isvcEntitiesUpdate: EntitiesUpdateService,
		private readonly isvcPopover: PopoverService,
		private readonly isvcModal: ModalService,
		private readonly isvcStore: Store,
		private readonly isvcLoading: LoadingService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcPermissions: PermissionsService
	) {
		super();

		this.observableParams.value$.pipe(
			switchMap((poParams?: IEntityEntriesListParams<T>) =>
				defer(() =>
					poParams?.entityDescriptor ?
						of(poParams?.entityDescriptor) :
						this.isvcEntities.getDescriptor$(poParams?.entityDescId)
				).pipe(
					tap((poDesc: IEntityDescriptor) => {
						this.moListDefinition = this.params?.listDefinition ??
							this.isvcEntities.getListDefinition(poDesc, this.params?.listId) as IEntityEntriesListDefinition;

						this.observableHasDetail.value = this.hasDetail(poDesc, this.moListDefinition);
						this.observableCanCreate.value =
							this.moListDefinition.actions?.includes(EEntityEntriesListItemAction.create) &&
							this.isvcEntities.hasPermission(poDesc, "create");
						this.moDescriptor = poDesc;
						if (poParams)
							poParams.entityDescriptor = poDesc;
					})
				)
			),
			secure(this)
		).subscribe();
	}

	public async onDetailClickedAsync(poItem: T, poEvent: MouseEvent): Promise<void> {
		await this.openPopover$(poItem, poEvent).toPromise();
	}

	@Queue<
		EntityEntriesListComponent<T>,
		Parameters<EntityEntriesListComponent<T>["openPopover$"]>,
		ReturnType<EntityEntriesListComponent<T>["openPopover$"]>
	>({
		excludePendings: true
	})
	private openPopover$(poEntity: T, poEvent: MouseEvent): Observable<boolean> {
		return defer(() => {
			const laPopoverItemParams: IPopoverItemParams[] | undefined = this.getPopoverItemParams(poEntity);
			if (ArrayHelper.hasElements(laPopoverItemParams)) {
				return this.isvcPopover.showPopoverAsync(
					laPopoverItemParams,
					poEvent
				);
			}
			return EMPTY;
		}).pipe(mapTo(true));
	}

	private hasDetail(poDesc: IEntityDescriptor, poListDef: IEntityEntriesListDefinition): boolean {
		return poListDef.actions?.some((peAction: EEntityEntriesListItemAction) =>
			this.isvcEntities.hasPermission(poDesc, peAction)
		);
	}

	private getPopoverItemParams(poEntity: T): IPopoverItemParams[] | undefined {
		const laPopoverItemParams: IPopoverItemParams[] = [];
		if (this.params && this.moDescriptor) {
			const loDescriptor: IEntityDescriptor = this.moDescriptor;
			let loDeleteItem: IPopoverItemParams | undefined;
			const laActions: (EEntityEntriesListItemAction | IEntityAction)[] = [...(this.moListDefinition?.actions ?? []), ...(Object.values(this.moDescriptor.actions ?? {}) ?? [])];
			laActions.forEach((poAction: EEntityEntriesListItemAction | IEntityAction) => {
				const lbIsBasicAction: boolean = [EEntityEntriesListItemAction.create, EEntityEntriesListItemAction.read].includes(this.getActionType(poAction) as EEntityEntriesListItemAction);
				const lbHasPermission: boolean = this.hasPermission(loDescriptor, poAction);
				const lbStateMachinesAllowAction = Object.entries(poEntity.stateMachines ?? {}).every(([psKey, poStateMachine]: [string, StateMachine]) => poStateMachine.canDoAction(poEntity[psKey], this.getActionType(poAction)));
				if (
					!lbIsBasicAction &&
					lbHasPermission &&
					lbStateMachinesAllowAction
				) {
					const loItem: IPopoverItemParams = {
						title: this.getPopoverItemTitle(poAction),
						icon: this.getPopoverItemIcon(poAction),
						color: this.getPopoverItemColor(poAction),
						action: () => this.onPopoverItemClicked$(poEntity, loDescriptor, poAction)
					};
					if (this.getActionType(poAction) === EEntityEntriesListItemAction.delete)
						loDeleteItem = loItem;
					else {
						laPopoverItemParams.push({
							title: this.getPopoverItemTitle(poAction),
							icon: this.getPopoverItemIcon(poAction),
							color: this.getPopoverItemColor(poAction),
							action: () => this.onPopoverItemClicked$(poEntity, loDescriptor, poAction)
						});
					}
				}
			});

			if (ObjectHelper.isDefined(poEntity.stateMachines)) {
				Object.entries(poEntity.stateMachines).forEach(([psStateMachineKey, poStateMachine]: [string, StateMachine]) => {
					if (poStateMachine.suggestNextStates) {
						Object.entries(poStateMachine.getTransitions(poEntity[psStateMachineKey])).forEach(([psStateKey, poState]: [string, IState]) => {
							laPopoverItemParams.push({
								title: `Passer à "${poState.label}"`,
								icon: poState.icon ?? "walk",
								color: poState.color ?? "primary",
								action: () => this.changeEntityState$(poEntity, loDescriptor, psStateMachineKey, psStateKey)
							});
						});
					}
				});
			}

			if (loDeleteItem)
				laPopoverItemParams.push(loDeleteItem);
		}

		return laPopoverItemParams;
	}

	private getActionType(poAction: EEntityEntriesListItemAction | IEntityAction): string {
		if (poAction instanceof Object)
			return poAction.type;
		else
			return poAction;
	}

	private hasPermission(poDescriptor: IEntityDescriptor, poAction: EEntityEntriesListItemAction | IEntityAction): boolean {
		if (poAction instanceof Object) {
			if (poAction.permissions)
				return this.isvcPermissions.evaluatePermission(poAction.permissions.scope, poAction.permissions.permissions);
		}
		return this.isvcEntities.hasPermission(poDescriptor, this.getActionType(poAction));
	}

	private getPopoverItemIcon(poAction: EEntityEntriesListItemAction | IEntityAction): string | undefined {
		if (poAction instanceof Object)
			return poAction.icon;
		else {
			switch (poAction) {
				case EEntityEntriesListItemAction.edit:
					return "create";
				case EEntityEntriesListItemAction.delete:
					return "trash";
			}
		}

		return undefined;
	}

	private getPopoverItemTitle(poAction: EEntityEntriesListItemAction | IEntityAction): string | undefined {
		if (poAction instanceof Object)
			return poAction.label;
		else {
			switch (poAction) {
				case EEntityEntriesListItemAction.edit:
					return "Éditer";
				case EEntityEntriesListItemAction.delete:
					return "Supprimer définitivement";
			}
		}

		return undefined;
	}

	private getPopoverItemColor(poAction: EEntityEntriesListItemAction | IEntityAction): string | undefined {
		if (poAction instanceof Object)
			return poAction.color ?? "primary";
		else {
			switch (poAction) {
				case EEntityEntriesListItemAction.delete:
					return "danger";
				default:
					return "primary";
			}
		}
	}

	private onPopoverItemClicked$(
		poEntity: T,
		poDescriptor: IEntityDescriptor,
		poAction: EEntityEntriesListItemAction | IEntityAction
	): Observable<boolean> {
		if (poAction instanceof Object) {
			return this.isvcEntities.getDescriptor$(this.observableParams.value?.entityDescId, { guid: IdHelper.getGuidFromId(poEntity._id, IdHelper.getPrefixFromId(poEntity._id)) }).pipe( // Pour récupérer le descripteur hydraté avec l'entité sélectionnée.
				take(1),
				switchMap((poUpdatedDescriptor: IEntityDescriptor) => this.isvcEntities.execEntityAction$(Object.values(poUpdatedDescriptor.actions ?? {}).find((poAction: IEntityAction) => poAction.type === poAction.type)))
			);
		}
		else {
			switch (poAction) {
				case EEntityEntriesListItemAction.edit:
					return defer(() => this.isvcEntities.navigateToEntityEditAsync(poEntity));
				case EEntityEntriesListItemAction.delete:
					return this.isvcEntitiesUpdate.deleteEntity(poEntity, poDescriptor).pipe(
						map((poResponse: IStoreDataResponse) => poResponse.ok)
					);
				default:
					return EMPTY;
			}
		}
	}

	public async onItemClickedAsync(poItem: T): Promise<void> {
		await this.onItemClicked$(poItem).toPromise();
	}

	@Queue<
		EntityEntriesListComponent<T>,
		Parameters<EntityEntriesListComponent<T>["onItemClicked$"]>,
		ReturnType<EntityEntriesListComponent<T>["onItemClicked$"]>
	>({
		excludePendings: true
	})
	private onItemClicked$(poItem: T): Observable<boolean> {
		if (
			this.moDescriptor &&
			this.moListDefinition?.actions?.includes(EEntityEntriesListItemAction.read) &&
			this.isvcEntities.hasPermission(this.moDescriptor, "read")
		)
			return defer(() => this.isvcEntities.navigateToEntityViewAsync(poItem));

		return EMPTY;
	}

	public async onCreateClickedAsync(): Promise<void> {
		await this.onCreateClicked$().toPromise();
	}

	@Queue<
		EntityEntriesListComponent<T>,
		Parameters<EntityEntriesListComponent<T>["onItemClicked$"]>,
		ReturnType<EntityEntriesListComponent<T>["onItemClicked$"]>
	>({
		excludePendings: true
	})
	private onCreateClicked$(): Observable<boolean> {
		if (this.observableCanCreate.value && this.moDescriptor) {
			const loDesc: IEntityDescriptor = this.moDescriptor;

			return defer(() => {
				if (
					this.observableParams.value?.parentEntityDescId &&
					StringHelper.isBlank(this.params?.createParams?.parentId)
				) {
					return this.getParentEntityId(this.observableParams.value.parentEntityDescId);
				}
				return of(undefined);
			}).pipe(
				mergeMap((psParentEntityId?: string) => {
					let loCreateParams: IEntityEntriesListParamsCreateParams | undefined = this.params?.createParams;
					if (!StringHelper.isBlank(psParentEntityId))
						loCreateParams = { ...(loCreateParams ?? {}), parentId: psParentEntityId };

					return this.isvcEntities.navigateToEntityCreationAsync(
						loDesc,
						loCreateParams
					);
				})
			);
		}

		return EMPTY;
	}

	private getParentEntityId(psParentEntityDescId: string): Observable<string> {
		return this.isvcModal.open<Entity>({
			component: EntityPickerModalComponent,
			componentProps: {
				entityDescId: psParentEntityDescId
			}
		}).pipe(
			filter((poParentEntity?: Entity) => !!poParentEntity),
			map((poParentEntity: Entity) => poParentEntity._id),
		) as Observable<string>;
	}

	private changeEntityState$(poEntity: T, poDescriptor: IEntityDescriptor, psStateMachineKey: string, psNewValue: string, pbIsCancelation?: boolean): Observable<boolean> {
		const lsOdlValue: string = poEntity[psStateMachineKey];
		const loOldValueState: IState = poEntity.stateMachines![psStateMachineKey].states[lsOdlValue];
		const loNewValueState: IState = poEntity.stateMachines![psStateMachineKey].states[psNewValue];
		poEntity[psStateMachineKey] = psNewValue;

		return this.saveEntity$(poEntity, poDescriptor).pipe(
			tap((pbSaved: boolean) => {
				const lsEntityName: string = this.isvcEntities.getEntityName(poEntity);

				if (pbSaved) {
					this.isvcUiMessage.showToastMessage(new ShowMessageParamsToast({
						message: `${lsEntityName} ${pbIsCancelation ? "revenu" : "passé"} à "${loNewValueState.label}"`,
						duration: 5000,
						buttons: pbIsCancelation ?
							[] :
							[{ text: `Revenir à "${loOldValueState.label}"`, handler: () => firstValueFrom(this.changeEntityState$(poEntity, poDescriptor, psStateMachineKey, lsOdlValue, true)) }]
					}));
				}
				else
					this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: `Une erreur est survenue lors de la sauvegarde de ${lsEntityName}.`, header: "Erreur" }));
			})
		);
	}

	private saveEntity$(poEntity: T, poDescriptor: IEntityDescriptor): Observable<boolean> {
		let loLoader: Loader;
		return defer(() => this.isvcLoading.create("Sauvegarde en cours")).pipe(
			switchMap((poLoader: Loader) => poLoader.present()),
			tap((poLoader: Loader) => loLoader = poLoader),
			switchMap(() => this.isvcEntitiesUpdate.saveModel(
				poEntity,
				poDescriptor,
				ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))
			)
			),
			map((poResponse: IStoreDataResponse) => poResponse.ok),
			finalize(() => loLoader?.dismiss())
		);
	}

	//#endregion

}
