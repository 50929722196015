import { IGuidOptions } from "../../utils/models/IGuidOptions";
import { IGuidBuilder } from "./iguid-builder";

export class LongGuidBuilder implements IGuidBuilder {

	//#region FIELDS

	private static readonly C_REGEX_STRING = "[0-9a-fA-F]{8}-?[0-9a-fA-F]{4}-?[1-5][0-9a-fA-F]{3}-?[0-9a-fA-F]{4}-?[0-9a-fA-F]{12}";
	private static readonly C_IS_VALID_REGEXP = new RegExp(`^${LongGuidBuilder.C_REGEX_STRING}$`, "i");

	//#endregion FIELDS

	//#region METHODS

	/** @implements */
	public build(poOptions: IGuidOptions = {}): string {
		let lsGuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (psLetter: string) => {
			const lnRandomNumber = Math.random() * 16 | 0;
			return (psLetter === 'x' ? lnRandomNumber : (lnRandomNumber & (0x3 | 0x8))).toString(16);
		});

		if (!poOptions.withHyphens)
			lsGuid = lsGuid.replace(/-/g, '');

		if (poOptions.upperCase)
			lsGuid = lsGuid.toUpperCase();

		return lsGuid;
	}

	/** @implements */
	public isValid(psGuid: string): boolean {
		return LongGuidBuilder.C_IS_VALID_REGEXP.test(psGuid);
	}

	//#endregion METHODS

}