import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { concatMap, filter, map, takeUntil } from 'rxjs/operators';
import { SecurityService } from '../../../../services/security.service';
import { Store } from '../../../../services/store.service';
import { IPreferences } from '../../model/IPreferences';
import { PreferencesServiceBase } from '../../preferences-service-base';
import { IRecentsConfig } from '../model/IRecentsConfig';

export const RECENTS_CONFIG = new InjectionToken<IRecentsConfig>("RECENTS_CONFIG");

@Injectable()
/** Service de gestion des récents. Paramètrable à l'aide du module. */
export class RecentsService extends PreferencesServiceBase<IPreferences, IRecentsConfig> implements OnDestroy {

	//#region METHODS

	constructor(
		psvcStore: Store,
		psvcSecurity: SecurityService,
		private ioRouter: Router,
		@Inject(RECENTS_CONFIG) @Optional() poRecentsConfig?: IRecentsConfig
	) {
		super(EPrefix.recents, psvcStore, psvcSecurity, poRecentsConfig);
	}

	public initAddWithNavigationInterception(): void {
		if (ArrayHelper.hasElements(this.ioPreferencesConfig?.interceptionRegexps)) {
			this.ioRouter.events
				.pipe(
					filter((poEvent: Event) => poEvent instanceof NavigationEnd),
					map((poEvent: NavigationEnd) => {
						let lsId: string;

						this.ioPreferencesConfig.interceptionRegexps.forEach((poRegExp: string | RegExp) => lsId = ArrayHelper.getLastElement(poEvent.url.match(poRegExp)));

						return lsId;
					}),
					filter((psId: string) => !StringHelper.isBlank(psId)),
					concatMap((psId: string) => this.add(psId)),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	//#endregion

}