import { Component } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { EMPTY, Observable, concat } from 'rxjs';
import { catchError, map, mergeMap, takeUntil, toArray } from 'rxjs/operators';
import { IActionButtonFieldPositionParams } from '../../../../../model/forms/actionButtonFields/IActionButtonFieldPositionParams';
import { ENavigationType } from '../../../../../model/navigation/ENavigationType';
import { IGeolocData } from '../../../../../model/navigation/IGeolocData';
import { IReverseGeocodingData } from '../../../../../model/navigation/IReverseGeocodingData';
import { FormsService } from '../../../../../services/forms.service';
import { ShowMessageParamsPopup } from '../../../../../services/interfaces/ShowMessageParamsPopup';
import { NavigationService } from '../../../../../services/navigation.service';
import { UiMessageService } from '../../../../../services/uiMessage.service';
import { ActionButtonFieldBaseComponent } from './actionButtonFieldBase.component';

interface ModelGeolocData {
	street: string,
	zipCode: string,
	city: string,
	latitude: number,
	longitude: number
}

@Component({
	selector: 'deprecated-calao-position-action-button-field',
	templateUrl: './actionButtonFieldBase.component.html'
})
export class ActionButtonFieldPositionComponent extends ActionButtonFieldBaseComponent<IActionButtonFieldPositionParams> {

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcNavigation: NavigationService,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	/** Récupère et remplit les coordonnées actuelles. */
	public override action(): void {
		const lsFilledAddressText = "Adresse renseignée";
		const lsUserPositionText = "Votre position";

		this.isvcNavigation.resetConfig(ENavigationType.gouv);

		concat(this.isvcNavigation.getModelGeocodingData(this.getModelGeolocData(), lsFilledAddressText), this.isvcNavigation.getCurrentGeolocDataWithTitle(lsUserPositionText))
			.pipe(
				catchError(poError => { console.error("ACT-BTN-FLD-POS.C:: ", poError); return EMPTY; }),
				toArray(),
				mergeMap((paResults: IReverseGeocodingData[][]) => {
					const laAllData: IReverseGeocodingData[] = ArrayHelper.flat(paResults);

					if (ArrayHelper.hasElements(laAllData)) { // Si au moins une adresse a été récupérée, on trie les adresses puis on se dirige vers le composant.
						const lnUserAddressLocationIndex: number = laAllData.findIndex((poItem: IReverseGeocodingData) => poItem.title === lsUserPositionText);
						if (lnUserAddressLocationIndex !== -1) { // Si la position courante a été récupérée, il faut la déplacer.
							const lnFilledAddressLocationIndex: number = laAllData.findIndex((poItem: IReverseGeocodingData) => poItem.title === lsFilledAddressText);
							// On déplace l'adresse de la position courante juste après l'adresse renseignée dans le modèle (index de cette adresse + 1).
							ArrayHelper.moveElement(laAllData, lnUserAddressLocationIndex, lnFilledAddressLocationIndex + 1);
						}

						return this.manageReverseGeocodingModalAndResponse(laAllData);
					}
					else { // Sinon, on affiche un message à l'utilisateur.
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Aucune donnée GPS n'a pu être trouvée.", header: "Erreur" }));
						return EMPTY;
					}
				}),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	private getModelGeolocData(): ModelGeolocData {
		const loModelGeolocData: ModelGeolocData = {
			street: this.model[this.params.specParams.streetField],
			zipCode: this.model[this.params.specParams.postalCodeField],
			city: this.model[this.params.specParams.cityField],
			latitude: undefined,
			longitude: undefined
		};

		const loLatitudeValue: string | number | undefined = this.model[this.params.specParams.latitudeField];
		const loLongitudeValue: string | number | undefined = this.model[this.params.specParams.longitudeField];

		if (NumberHelper.isValid(loLatitudeValue))
			loModelGeolocData.latitude = loLatitudeValue as number;
		else if (NumberHelper.isStringNumber(loLatitudeValue))
			loModelGeolocData.latitude = +loLatitudeValue;

		if (NumberHelper.isValid(loLongitudeValue))
			loModelGeolocData.latitude = loLongitudeValue as number;
		else if (NumberHelper.isStringNumber(loLongitudeValue))
			loModelGeolocData.latitude = +loLongitudeValue;

		return loModelGeolocData;
	}

	/** Ouvre la page de reverseGeocoding et applique les changements si nécessaires.
	 * @param paData Tableau des données GPS pour initialiser le composant.
	 */
	private manageReverseGeocodingModalAndResponse(paData: IReverseGeocodingData[]): Observable<void> {
		return this.isvcNavigation.openReverseGeocodingModal(paData)
			.pipe(
				map((poResult: IGeolocData) => {
					this.model[this.params.specParams.latitudeField] = poResult.latitude;
					this.model[this.params.specParams.longitudeField] = poResult.longitude;
					this.model[this.params.specParams.streetField] = poResult.street;
					this.model[this.params.specParams.postalCodeField] = poResult.zipCode;
					this.model[this.params.specParams.cityField] = poResult.city;

					return this.form.patchValue(this.model);
				})
			);
	}

	//#endregion
}