import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { IIndexedObject } from '@calaosoft/osapp-common/utils/models/IIndexedObject';

@Pipe({ name: 'pattern' })
export class PatternPipe implements PipeTransform {

	//#region METHODS

	constructor() { }

	/** Transforme la valeur reçue pour l'afficher.
	 * @param poValue Valeur de la clé de l'objet à afficher.
	 * @param psPatternKey Clé de l'objet à afficher qui peut être un pattern à résoudre.
	 * @param poItem Objet dont il faut afficher une valeur patternée ou non.
	 */
	public transform(poValue: any, psPatternKey: string, poItem: IIndexedObject): string {
		if ((poValue !== undefined && poValue !== null) || StringHelper.isBlank(psPatternKey)) // Si la valeur à afficher est renseignée ou qu'il n'y pas de clé alors on affiche tel quel.
			return poValue;

		// Résolution des patterns.
		const laResolvedKeys: string[] = this.getResolvedKeys(psPatternKey);

		let lsResult = "";
		// On crée la chaîne d'affichage en cherchant les valeurs dans l'élément à partir des clés obtenues.
		laResolvedKeys.forEach((psKey: string) => {
			if (!StringHelper.isBlank(poItem[psKey]))
				lsResult += ` ${poItem[psKey]}`;
		});

		return lsResult.replace(/\s*/, ""); // On supprime les espaces vides au début de la chaîne.
	}

	/** Récupère les clés après résolution du/des pattern(s).
	 * @param psPattern Pattern à résoudre.
	 */
	public getResolvedKeys<T = string>(psPattern: T): T[] {
		// Tableau des clés qu'il faut afficher après résolution des paterns possibles.
		const laResolvedPatternKeys: T[] = [];

		// On fragmente le pattern en supprimant les accolades ouvrantes.
		const laPatternFragments: T[] = this.getValueFragments<T>("{{", psPattern);
		// On supprime les accolades fermantes de chaque fragment de pattern.
		laPatternFragments.forEach((psPatternFragment: T) => laResolvedPatternKeys.push(...this.getValueFragments("}}", psPatternFragment)));

		return laResolvedPatternKeys;
	}

	/** Récupère un tableau des valeurs non nulles présentes dans la chaîne de caractères à traiter.
	 * @param psSplitValue Valeur par laquelle séparer la chaîne de caractères.
	 * @param psValue Chaîne de caractères sur laquelle appliquer la séparation et le filtrage des valeurs non nulles.
	 */
	private getValueFragments<T = string>(psSplitValue: string, psValue: T): T[] {
		return (psValue as unknown as string)
			.replace(/\s*/g, "") // On remplace tous les espaces par rien,
			.split(psSplitValue) // puis on sépare la chaîne de caractères en sous-parties,
			.filter((psItem: string) => !StringHelper.isBlank(psItem)) as unknown as T[]; // et on filtre pour ne garder que les sous-parties valides.
	}

	//#endregion
}