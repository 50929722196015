import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilesystemModule } from '../filesystem/filesystem.module';
import { BackupsService } from './services/backups.service';

@NgModule({
	imports: [
		CommonModule,
		FilesystemModule
	],
	providers: [BackupsService]
})
export class BackupsModule { }