import { ObjectHelper } from "@calaosoft/osapp-common/utils/helpers/objectHelper";
import { FilesystemErrorBase } from "./filesystem-error-base";

export class FilesystemGetFileFromUriError extends FilesystemErrorBase {

	constructor(poError: any, psUri: string) {
		super(poError, `Can not get file from URI '${psUri}'.`);
		// Nécessaire pour que le 'instanceof FilesystemGetFileFromUriError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemGetFileFromUriError);
	}

}