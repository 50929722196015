import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ObservableProperty } from "@calaosoft/osapp-common/observable/models/observable-property";
import { DocumentsCounters } from "../../../../doc-explorer/models/documents-counters";
import { EExplorerDisplayMode } from "../../../../doc-explorer/models/eexplorer-display-mode";
import { DocExplorerDocumentsService } from "../../../../doc-explorer/services/doc-explorer-documents.service";
import { FieldBase } from "../../../models/FieldBase";
import { FormsService } from "../../../services/forms.service";
import { IDocumentTileFieldParams } from "../models/idocument-tile-field-params";

@Component({
	selector: 'calao-document-tile-field',
	templateUrl: './document-tile-field.component.html',
	styleUrls: ['./document-tile-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTileFieldComponent extends FieldBase implements OnInit {

	//#region PROPERTIES

	public params: IDocumentTileFieldParams = {};

	public readonly observableDocumentCount = new ObservableProperty<DocumentsCounters | undefined>(new DocumentsCounters(0, 0));

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcRouter: Router,
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		if (this.props.data)
			this.params = this.props.data;

		this.observableDocumentCount.bind(
			this.isvcDocExplorerDocuments.getDocumentsCounters$(this.params.path ?? ""),
			this
		);
	}

	public navigateToDocumentAsync(): Promise<boolean> {
		return this.isvcRouter.navigate(["documents"], { queryParams: { displayMode: EExplorerDisplayMode.date } });
	}

	//#endregion
}