import { IRange } from '../models/irange';
import { ArrayHelper } from './arrayHelper';
import { StringHelper } from './stringHelper';

/** Permet de mettre à disposition des méthodes pour aider à manipuler des nombres. */
export abstract class NumberHelper {

	//#region METHODS

	/** Détermine si un nombre est valide ou non (valide si c'est un nombre fini (pas `NaN`/`(-)Infinity`/`null`).
	 * @param poData Donnée à déterminer si elle est un nombre valide ou non.
	 */
	public static isValid(poData: any): poData is number {
		return isFinite(poData as number) && typeof poData === "number";
	}

	/** Détermine si tous les nombres sont valides ou non (valides si ce sont des nombres finis (pas `NaN`/`(-)Infinity`/`null`).
	 * @param paData Tableau des nombres à vérifier.
	 */
	public static areValid(paData: any[]): paData is number[] {
		return paData && paData.every((poValue: any) => this.isValid(poValue));
	}

	/** Retourne `true` si le nombre est un nombre valide sous forme de chaîne de caractère, `false` si ce n'est pas le cas.
	 * @param poNumber Nombre à déterminer s'il est sous forme de string ou non.
	 */
	public static isStringNumber(poNumber: number | string): boolean {
		// On doit vérifier que la chaîne n'est pas vide car +"" === 0 ; ce qui fausse le résultat.
		return typeof poNumber === "string" && !StringHelper.isBlank(poNumber) && this.isValid(+poNumber);
	}

	/** Retourne `true` si le nombre est valide et positif (0 compris), `false` sinon.
	 * @param pnNumber Nombre à déterminer s'il est un nombre positif valide.
	 */
	public static isValidPositive(pnNumber?: number): pnNumber is number {
		return this.isValid(pnNumber) && pnNumber >= 0;
	}

	/** Retourne `true` si le nombre est valide et strictement positif, `false` sinon.
	 * @param pnNumber Nombre à déterminer s'il est un nombre valide strictement positif.
	 */
	public static isValidStrictPositive(pnNumber?: number): pnNumber is number {
		return this.isValid(pnNumber) && pnNumber > 0;
	}

	/** Retourne un nombre entier aléatoire appartenant à un intervalle donné.
	 * @param pnEndInterval Nombre entier maximum, exclus, que la valeur retournée peut avoir.
	 * @param pnStartInterval Nombre entier minimum, inclus, que la valeur retournée peut avoir, par défaut : 0.
	 */
	public static getRandomInteger(pnEndInterval: number, pnStartInterval: number = 0): number {
		return Math.floor(Math.random() * (Math.floor(pnEndInterval) - Math.ceil(pnStartInterval))) + Math.ceil(pnStartInterval);
	}

	/** Met à jour les valeurs minimales et maximales avec en fonctions des valeurs issus d'un tableau. */
	public static updateMinMax<T>(paObjects: Array<T>, pfValue: (poValue: T) => number, pnStartMin?: number, psStartMax?: number): { min: number, max: number } {
		// On affecte des valeurs par défaut si minDb et maxDb sont indéfinis (première update).
		const loResult: { min: number, max: number } = { min: pnStartMin!, max: psStartMax! };

		// Si aucune valeur, on retourne les valeurs par défaut passées en paramètre.
		if (paObjects.length === 0)
			return loResult;

		// Si les valeurs par défaut ne sont pas définis, on leur donne la valeur du premier élément.
		if (loResult.min === undefined)
			loResult.min = pfValue(paObjects[0]);

		if (loResult.max === undefined)
			loResult.max = pfValue(paObjects[0]);

		/** Valeur courante de l'objet parcourue. */
		let lnCurrentValue: number;

		// Récupération des valeurs min et max.
		paObjects.forEach((poObject: T) => {
			lnCurrentValue = pfValue(poObject);

			if (loResult.min > lnCurrentValue)
				loResult.min = lnCurrentValue;
			if (loResult.max < lnCurrentValue)
				loResult.max = lnCurrentValue;
		});

		return loResult;
	}

	/** Retourne la valeur de poValue par rapport à pnMin et pnMax.
	 * @returns Un nombre entre 0 et 1 si `pnValue` est entre `pnMin` et `pnMax`.
	 */
	public static getRatio(pnMin: number, pnMax: number, pnValue: number): number {
		if (pnMax === pnMin)	// Évite une division par 0.
			return 0.5;
		return ((pnValue - pnMin) / (pnMax - pnMin));
	}

	public static getPercentage(pnMin: number, pnMax: number, pnValue: number): number {
		return Math.round(NumberHelper.getRatio(pnMin, pnMax, pnValue) * 100);
	}

	/** Ajoute deux nombres entre eux et retourne le résultat ; renvoie `NaN` si les deux valent `NaN` sinon renvoie la valeur du nombre défini.
	 * @param pnNumber1 Premier nombre qu'il faut ajouter au second.
	 * @param pnNumber2 Second nombre qu'il faut ajouter au premier.
	 */
	public static addTwoNumbers(pnNumber1?: number, pnNumber2?: number): number {
		if (this.isValid(pnNumber1))
			return this.isValid(pnNumber2) ? pnNumber1 + pnNumber2 : pnNumber1;
		else
			return this.isValid(pnNumber2) ? pnNumber2 : NaN;
	}

	/** Soustrait deux nombres et retourne le résultat.
	 * renvoie `NaN` si les deux valent `NaN` sinon renvoie la valeur du nombre défini (négatif si c'est le second nombre qui est défini).
	 * @param pnNumber1 Nombre à partir duquel soustraire le second.
	 * @param pnNumber2 Nombre qu'il faut soustraire au premier.
	 */
	public static substractTwoNumbers(pnNumber1?: number, pnNumber2?: number): number {
		if (this.isValid(pnNumber1))
			return this.isValid(pnNumber2) ? pnNumber1 - pnNumber2 : pnNumber1;
		else
			return this.isValid(pnNumber2) ? (-pnNumber2) : NaN;
	}

	/** Calcul et retourne un pourcentage d'une valeur par rapport à une autre ; retourne `NaN` si calcul impossible (division avec `NaN` ou division par `0`).
	 * @param pnValue Nombre avec lequel calculer le pourcentage (numérateur).
	 * @param pnReferenceValue Nombre de référence avec lequel calculer le pourcentage (diviseur).
	 * @param pnDigits Nombre de décimales après la virgule, `2` par défaut.
	 */
	public static calculatePercentage(pnValue: number, pnReferenceValue: number, pnDigits: number = 2): number {
		return NumberHelper.isValid(pnReferenceValue) && pnReferenceValue !== 0 && NumberHelper.isValid(pnValue) ?
			+(((pnValue / pnReferenceValue) - 1) * 100).toFixed(pnDigits) : NaN;
	}

	/** Ajoute chaque nombre du tableau à une valeur initiale et retourne le résultat.
	 * @param poData Tableau de nombres à réduire en ajoutant les valeurs entre elles.
	 * @param pnInitialValue Valeur initiale à partir de laquelle ajouter les nombres du tableau.
	 */
	public static reduceNumbers(poData: number[], pnInitialValue: number): number {
		return poData.reduce((pnPrevious: number, pnCurrent: number) => this.addTwoNumbers(pnPrevious, pnCurrent), pnInitialValue);
	}

	/** Indique si une valeur est compris dans une plage de valeur
	 * @param pnValue
	 * @param poRange
	 * @example
	 * isInRange(5, {from: 3, to: 6}) // true
	 * isInRange(5, {from: 6, to: 6}) // false
	 * isInRange(5, {from: 6, to: 4}) // false
	 * isInRange(5, {from: 6}) // false
	 * isInRange(5, {from: 3}) // true
	 * isInRange(5, {}) // true
	 */
	public static isInRange(pnValue: number, poRange: IRange<number>): boolean {
		return (!NumberHelper.isValid(poRange.from) || poRange.from <= pnValue) && (!NumberHelper.isValid(poRange.to) || (pnValue <= poRange.to));
	}

	/** Compare deux nombres, retourne un nombre positif si le premier nombre est plus grand que le second,
	 * un nombre négatif si l'inverse et 0 si les deux nombres sont égaux ou non valides.
	 */
	public static compare(pnNumberA: number, pnNumberB: number): number {
		const lbIsValidA: boolean = this.isValid(pnNumberA);
		const lbIsValidB: boolean = this.isValid(pnNumberB);

		if (!lbIsValidA && !lbIsValidB)
			return 0;
		else if (!lbIsValidA && lbIsValidB)
			return -1;
		else if (lbIsValidA && !lbIsValidB)
			return 1;
		else
			return Math.sign(pnNumberA - pnNumberB);
	}

	/** Restreint une valeur donnée à un intervalle spécifié.
	 * @param pnNumber Le nombre a restreindre.
	 * @param pnMin Valeur minimale de l'intervalle (`-1` par défaut).
	 * @param pnMax Valeur maximale de l'intervalle (`1` par défaut).
	 * @example
	 * - clamp(-10, -5, 5) => -5
	 * - clamp(100, -5, 5) => 5
	 * - clamp(0, -5, 5) => 0
	 * - clamp(2, -5, 5) => 2
	 */
	public static clamp(pnNumber: number, pnMin: number = -1, pnMax: number = 1): number {
		return Math.min(Math.max(pnNumber, pnMin), pnMax);
	}

	/** Récupère le nombre le plus grand présent dans un tableau.
	 * @param paNumbers Tableau de nombres dont il faut récupérer le plus grand.
	 */
	public static getMax(paNumbers: number[]): number;
	/** Récupère l'objet considéré comme le plus grand depuis un tableau, `undefined` si pas d'élément dans le tableau.
	 * @param paItems Tableau d'objets dont il faut récupérer celui considéré comme le plus grand.
	 * @param pfGetValue Fonction permettant de déterminer la valeur de l'objet afin de comparer les différents objets.
	 */
	public static getMax<T>(paItems: T[], pfGetValue: (poItem: T) => number): T | undefined;
	public static getMax<T>(paValues: number[] | T[], pfGetValue?: (poItem: T) => number): number | T | undefined {
		if (ArrayHelper.hasElements(paValues)) { // Des éléments sont présents, il faut vérifier lequel est le plus grand.

			if (!pfGetValue) // Cas de nombres, on affecte une fonction par défaut.
				pfGetValue = (poNumber: T) => poNumber as number; //! 'Math.max()' retourne le plus grand nombre mais considère 'NaN' comme étant le grand nombre possible ...

			let loMaxObject: number | T | undefined;
			let lnMaxValue = -Infinity;

			for (let lnIndex = 0; lnIndex < paValues.length; ++lnIndex) {
				const loCurrentItem: number | T = paValues[lnIndex];
				const lnCurrentValue: number = pfGetValue(loCurrentItem as T);

				if (this.isValid(lnCurrentValue) && lnCurrentValue > lnMaxValue) {
					loMaxObject = loCurrentItem;
					lnMaxValue = lnCurrentValue;
				}
			}

			return loMaxObject;
		}
		else // Pas d'éléments dans le tableau.
			return undefined;
	}

	//#endregion

}