import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveArray } from '@calaosoft/osapp-common/observable/decorators/observe-array.decorator';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { ISegmentIcon } from '../../models/isegment-icon';
import { TabsSegmentsComponent } from '../tabs-segments/tabs-segments.component';

@Component({
	selector: 'calao-tabs-segments-icon-label',
	templateUrl: './tabs-segments-icon-label.component.html',
	styleUrls: ['./tabs-segments-icon-label.component.scss'],
})
export class TabsSegmentsIconLabelComponent extends TabsSegmentsComponent {

	//#region PROPERTIES

	/** Événement lors du clic sur un segment. */
	@Output("onSegmentClicked") public readonly segmentClickedEvent = new EventEmitter<ISegmentIcon>();

	/** Tableau des segments à afficher. */
	@Input() public segments?: ISegmentIcon[];
	@ObserveArray<TabsSegmentsIconLabelComponent>("segments")
	public readonly observableSegments = new ObservableArray<ISegmentIcon>();

	/** Index de l'onglet actif. */
	@Input() public override activeTabIndex?: number;
	@ObserveProperty<TabsSegmentsIconLabelComponent>({ sourcePropertyKey: "activeTabIndex" })
	public override readonly observableActiveTabIndex = new ObservableProperty<number>(0);

	//#endregion PROPERTIES

	//#region METHODS

	public onSegmentClicked(poSegment: ISegmentIcon, pnIndex: number): void {
		this.observableActiveTabIndex.value = pnIndex;
		this.segmentClickedEvent.emit(poSegment);
	}

	//#endregion METHODS

}
