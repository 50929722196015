import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ETaskPrefix } from '@calaosoft/osapp-common/background-tasks/models/ETaskPrefix';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { StoreDocumentHelper } from '@calaosoft/osapp-common/store/helpers/store-document-helper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { ContactHelper } from '../../helpers/contactHelper';
import { IAppointment } from '../../model/booking/IAppointment';
import { IAppointmentDTO } from '../../model/booking/IAppointmentDTO';
import { IAvailability } from '../../model/booking/IAvailability';
import { ICustomer } from '../../model/booking/ICustomer';
import { IManager } from '../../model/booking/IManager';
import { IPrice } from '../../model/booking/IPrice';
import { IPurchase } from '../../model/booking/IPurchase';
import { IRequest } from '../../model/booking/IRequest';
import { IResource } from '../../model/booking/IResource';
import { IReview } from '../../model/booking/IReview';
import { BackgroundTaskService } from '../../services/backgroundTask.service';
import { TaskDescriptor } from '../../services/backgroundTask/TaskDescriptor';
import { ISavePurchaseTaskParams } from '../purchase/tasks/save-purchase-task';
import { IGetAppointmentsParams } from './IGetAppointmentsParams';
import { IGetReviewsOptions } from './IGetReviewsOptions';
import { BookingApiClient } from './bookingApiClient';

@Injectable({
	providedIn: "root"
})
export class BookingService {

	//#region FIELDS

	private moContactSubject = new BehaviorSubject<ICustomer | IResource | IManager>(null);
	private moApiClient: BookingApiClient;

	//#endregion

	//#region PROPERTIES

	public get contact$(): Observable<ICustomer | IResource | IManager> {
		return this.moContactSubject.asObservable().pipe(distinctUntilChanged(StoreDocumentHelper.areDocumentsEquals));
	}
	public static readonly C_BOOK_DEADLINE = 15;

	//#endregion

	//#region METHODS

	constructor(
		private ioHttpClient: HttpClient,
		private isvcBackgroundTask: BackgroundTaskService
	) {
		this.moApiClient = new BookingApiClient(this.ioHttpClient);
	}

	/** Tri les rendez-vous en deux catégories, passé et à venir.
	 * @param paAppointments Tableau des rendez-vous à trier.
	 */
	public static sortAppointments(paAppointments: IAppointmentDTO[]): { laFutureAppointments: IAppointmentDTO[]; laPastAppointments: IAppointmentDTO[]; } {
		const ldCurrentDate: Date = new Date();
		const laPastAppointments: IAppointmentDTO[] = paAppointments.filter(poAppointment => new Date(poAppointment.endDate) <= ldCurrentDate);
		const laFutureAppointments: IAppointmentDTO[] = paAppointments.filter(poAppointment => new Date(poAppointment.endDate) > ldCurrentDate);

		laFutureAppointments.sort((a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());

		laPastAppointments.sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime());

		return { laFutureAppointments, laPastAppointments };
	}

	/** Regroupe les rendez-vous par jour.
	 * @param paAppointment Tableau des rendez-vous à trier.
	 */
	public static groupAppointmentsByDay(paAppointment: IAppointment[]) {
		paAppointment = paAppointment.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

		const loGroups = paAppointment.reduce((poGroups: {}, poAppointment: IAppointment) => {
			const lsDate: string = DateHelper.resetDay(new Date(poAppointment.startDate)).toISOString();
			if (!poGroups[lsDate])
				poGroups[lsDate] = [];

			poGroups[lsDate].push(poAppointment);
			return poGroups;
		}, {});

		const laSortedAppointments = Object.keys(loGroups).map((date: string) => ({
			date,
			appointments: loGroups[date]
		}));

		return laSortedAppointments;
	}

	/** Regroupe les rendez-vous par jour et heure.
	 * @param paAppointment Tableau des rendez-vous à trier.
	 */
	public static groupAppointmentsByDayAndHours(paAppointment: IAppointment[]) {
		const laAppointmentsByDay = BookingService.groupAppointmentsByDay(paAppointment);
		const laSortedAppointments = [];

		laAppointmentsByDay.forEach((poDay: { date: string, appointments: IAppointment[] }) => {
			const loGroups = poDay.appointments.reduce((poGroups: {}, poAppointment: IAppointment) => {
				const lsDate: string = new Date(poAppointment.startDate).toISOString();
				if (!poGroups[lsDate])
					poGroups[lsDate] = [];

				poGroups[lsDate].push(poAppointment);
				return poGroups;
			}, {});

			laSortedAppointments.push({
				date: poDay.date,
				hours: Object.keys(loGroups).map((hour: string) => ({
					hour,
					appointments: loGroups[hour]
				}))
			});
		});

		return laSortedAppointments;
	}

	/** Retourn `true` si la date en paramètre est passée.
	 * @param psDate Date à comparer.
	 */
	public static isOld(psDate: string): boolean {
		return new Date() > new Date(psDate);
	}

	public setContact(poContact: ICustomer | IResource | IManager): void {
		this.moContactSubject.next(poContact);
	}

	/** Met à jour un customer.
	 * @param poCustomer l'objet Customer à mettre à jour.
	 */
	public updateCustomer(poCustomer: ICustomer): Observable<ICustomer> {
		return this.moApiClient.postCustomer(poCustomer)
			.pipe(
				mergeMap(_ => this.getCustomer(poCustomer._id)),
				tap((poGetCustomer: ICustomer) => this.setContact(poGetCustomer))
			);
	}

	/** Retourne la liste de tous les clients.
	 * @param psManagerId L'identifiant du manager pour afficher toutes les données.
	*/
	public getCustomers(psManagerId?: string): Observable<ICustomer[]> {
		return this.moApiClient.getCustomers(psManagerId);
	}

	/** Retourne la liste de toutes les ressources. */
	public getResources(pbIsActive?: boolean): Observable<IResource[]> {
		return this.moApiClient.getResources(pbIsActive);
	}

	/** Retourne la liste de tous les managers. */
	public getManagers(pbIsActive?: boolean): Observable<IManager[]> {
		return this.moApiClient.getManagers(pbIsActive);
	}

	/** Retourne une ressource.
	 * @param psResourceId Identifiant de la ressource.
	 * @param pbRequestFullName Indique si l'on doit récupérer le nom et le prénom.
	 */
	public getResource(psResourceId: string, pbRequestFullName = true): Observable<IResource> {
		return this.moApiClient.getResource(psResourceId, pbRequestFullName);
	}

	/**
	 * Retourne des disponibiltés precises.
	 * @param psResourceId id d'un contact de type resource
	 */
	public getAvailability(psResourceId: string): Observable<IAvailability> {
		return this.moApiClient.getAvailability(psResourceId);
	}

	/**
	 * Met à jour les disponibiltés d'une ressource.
	 * @param psResourceId id d'un contact de type resource.
	 * @param poAvailability les disponibiltés à mettre à jour.
	 */
	public updateAvailability(psResourceId: string, poAvailability: IAvailability) {
		return this.moApiClient.updateAvailability(psResourceId, poAvailability);
	}

	/** Retourne un client précis.
	 * @param psCustomerId L'identifiant du client à retourner.
	 * @param psManagerId L'identifiant du manager pour afficher toutes les données.
	 */
	public getCustomer(psCustomerId: string, psManagerId?: string): Observable<ICustomer> {
		return this.moApiClient.getCustomer(psCustomerId, psManagerId);
	}

	/**
	 * Retourne des consultations filtré par l'id d'un contact.
	 * @param psContactId L'identifiant du contact pour lequel on veut récupérer les rdv.
	 * @param poParams Paramètre d'url
	**/
	public getAppointments(psContactId: string, poParams: IGetAppointmentsParams = {}): Observable<IAppointment[]> {
		return this.moApiClient.getResourceAppointments(psContactId, poParams);
	}

	/**
	 * Retourne les consultations de toutes les ressources.
	 * @param poParams Paramètre d'url
	**/
	public getResourcesAppointments(poParams: IGetAppointmentsParams = {}): Observable<IAppointment[]> {
		return this.moApiClient.getResourcesAppointments(poParams);
	}

	public prepareAppointmentsDisplayNames(...paAppointment: IAppointmentDTO[]): void {
		paAppointment.forEach((poAppointment: IAppointmentDTO) => {
			poAppointment.displayName = !StringHelper.isBlank((poAppointment.displayedContact as IResource)?.displayName) ?
				(poAppointment.displayedContact as IResource)?.displayName : ContactHelper.getCompleteFormattedName(poAppointment.displayedContact);
		});
	}

	/**
	 * Créé les rendez-vous pour une ressource donnée.
	 * @param psManagerId id du Manager.
	 * @param psResourceId id de la Ressource.
	 * @param pbavailability si true utilise les disponibilités.
	 * @param startDate la date de début.
	 * @param endDate la date de fin.
	 */
	public createAppointments(psManagerId: string, psResourceId: string, pbavailability?: boolean, psStartDate?: Date, pdEndDate?: Date) {
		return this.moApiClient.createAppointments(psManagerId, psResourceId, pbavailability, psStartDate?.toISOString(), pdEndDate?.toISOString());
	}

	/** Retourne la consultation suivant l'id demandé.
	 * @param psAppointmentId Identifiant de la consultation.
	 */
	public getAppointment(psAppointmentId: string): Observable<IAppointment> {
		return this.moApiClient.getAppointment(psAppointmentId);
	}

	/**
	 * Retoune une date au format ISO à laquelle on peut commencer de créer des rendez-vous.
	 * @param psManagerId l'id du Manager.
	 * @param psResourceId l'id de la Ressource.
	 */
	public getLastAppointmentDate(psManagerId: string, psResourceId?: string): Observable<string> {
		return this.moApiClient.getLastAppointmentDate(psManagerId, psResourceId);
	}

	/** Réserve une consultaion.
	 * @param poAppointment La consultation à réserver.
	 * @param poPurchase L'achat associé à la consultation.
	 */
	public bookAppointment(poAppointment: IAppointment, poPurchase: IPurchase): void {
		this.isvcBackgroundTask.addTask(new TaskDescriptor<ISavePurchaseTaskParams>(
			{
				id: ETaskPrefix.savePurchase + poPurchase._id,
				name: ETaskPrefix.savePurchase,
				taskType: "SavePurchaseTask",
				params: {
					purchase: JSON.parse(JSON.stringify(poPurchase)),
					appointment: JSON.parse(JSON.stringify(poAppointment))
				},
				enableTaskPersistance: true
			}));
	}

	/** Préréserve une consultation.
	 * @param poAppointment La consultation à préréserver.
	 */
	public prebookAppointment(poAppointment: IAppointment): Observable<boolean> {
		return this.contact$
			.pipe(
				take(1),
				mergeMap((poCustomer: ICustomer) => this.moApiClient.prebookAppointment(poAppointment, poCustomer)),
				mapTo(true),
				catchError(poError => {
					console.error(`BK.S::Pre-booking failed.`, poError);

					return of(false);
				}));
	}

	/**
	 * Retourne un ticket d'achat.
	 * @param psPurchaseId l'id du ticket d'achat recherché.
	 */
	public getPurchase(psPurchaseId: string): Observable<IPurchase> {
		return this.moApiClient.getPurchase(psPurchaseId);
	}

	/**
	 * Met à jour une consultation.
	 * @param poAppointment La consultation à mettre à jour.
	 */
	public updateAppointment(poAppointment: IAppointment): Observable<boolean> {
		return this.moApiClient.updateAppointment(poAppointment)
			.pipe(
				mapTo(true),
				catchError(poError => {
					console.error(`BK.S::Appointment-update failed.`, poError);

					return of(false);
				})
			);
	}

	/**
	 * Cache un rendez-vous (utile quand on rallonge le précédent)
	 * @param poAppointment La consultation à cacher.
	 */
	public hideAppointment(poAppointment: IAppointment): Observable<boolean> {
		poAppointment.status = "hidden";
		return this.updateAppointment(poAppointment);
	}

	/** Créé un contact.
	 * @param poContact l'objet Contact à créer.
	 */
	public createCustomer(poContact: ICustomer): Promise<ICustomer> {
		return this.moApiClient.postNewCustomer(poContact)
			.then((poCustomer: ICustomer) => {
				this.setContact(poCustomer);

				return poCustomer;
			});
	}

	public startAppointment(psAppointmentId: string): Observable<boolean> {
		return this.moApiClient.startAppointment(psAppointmentId).pipe(mapTo(true));
	}

	public finishAppointment(psAppointmentId: string): Observable<boolean> {
		return this.moApiClient.finishAppointment(psAppointmentId).pipe(mapTo(true));
	}

	public requestCustomerPhone(poAppointment: IAppointmentDTO, psReason: string): Observable<HttpResponse<any>> {
		return this.moApiClient.requestCustomerPhone(poAppointment, psReason);
	}

	public requestAppointmentCancellation(psManagerId: string, poAppointment: IAppointmentDTO, psReason: string): Observable<HttpResponse<any>> {
		return this.moApiClient.requestAppointmentCancellation(psManagerId, poAppointment, psReason);
	}

	public getRequests(psContactId: string, psType: string, pnLimit?: number, psStartDate?: string): Observable<IRequest[]> {
		return this.moApiClient.getRequests(psContactId, psType, pnLimit, psStartDate);
	}

	/**
	 * Réaffecte une autre consultation.
	 * @param psCurrentAppointmentId id de la consultation déjà réservé.
	 * @param psNewAppointmentId id de la consultation à réserver.
	 */
	public reassignAppointment(psCurrentAppointmentId: string, psNewAppointmentId: string): Observable<HttpResponse<any>> {
		return this.moApiClient.reassignAppointment(psCurrentAppointmentId, psNewAppointmentId);
	}

	/**
	 * Annule une consultation.
	 * @param psAppointmentId id de la consultation à annuler.
	 */
	public cancelAppointment(psAppointmentId: string): Observable<HttpResponse<any>> {
		return this.moApiClient.cancelAppointment(psAppointmentId);
	}

	/**
	 * Retourne les différents tarifs disponible pour une consultation.
	 */
	public getAppointmentsPrices(): Observable<IPrice[]> {
		return this.moApiClient.getAppointmentsPrices();
	}

	/**
	 * Retourne des avis filtrés par les options.
	 * @param poOptions Les options de filtrage.
	**/
	public getReviews(poOptions: IGetReviewsOptions = {}): Observable<IReview[]> {
		return this.moApiClient.getReviews(poOptions);
	}

	/**
	 * Publie un commentaire.
	 * @param psManagerId Id du manager.
	 * @param psReviewId Id du commentaire.
	 */
	public publishReview(psManagerId: string, psReviewId: string): Observable<IReview> {
		return this.moApiClient.publishReview(psManagerId, psReviewId);
	}

	/**
	 * Refuse la publication d'un commentaire.
	 * @param psManagerId Id du manager.
	 * @param psReviewId Id du commentaire.
	 */
	public refuseReview(psManagerId: string, psReviewId: string): Observable<IReview> {
		return this.moApiClient.refuseReview(psManagerId, psReviewId);
	}

	public createReview(poReview: IReview): Observable<IReview> {
		return this.moApiClient.createReview(poReview);
	}

	//#endregion

}