import { IConversationConfig } from '@calaosoft/osapp-common/conversations/models/IConversationConfig';
import { EEnvironmentId } from '@calaosoft/osapp-common/environment/models/EEnvironmentId';
import { IStatusBarConfig } from '@calaosoft/osapp-common/status-bar/models/IStatusBarConfig';
import { ITradeEnvironment } from '../model/ITradeEnvironment';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const APP_ID = "trade";
export const APP_CORE_DATABASE_PREFIX = `${APP_ID}_core_`;

export const environment: ITradeEnvironment = {
	production: false,
	id: EEnvironmentId.dev1,
	description: "DEV1",
	cloud_url: "https://clouddev1.mobile.calaosoft.fr",
	coreRoleAppConfig: `${APP_CORE_DATABASE_PREFIX}common_app_configs`,
	statusBar: { backgroundColorByHexString: "#ff9900" },
	GOOGLE_MAPS_BROWSER_API_KEY: "AIzaSyAHuCo3U-b_muFtSsz3jby-id2N01PVx2w",
	GOOGLE_MAPS_IOS_API_KEY: "AIzaSyDAidukjOGMBtIImawp_uc-vUbZMYbYZCI",
	API_KEY: "OTg3ODQ1NDUyNWQ0NDlhYzhjY2E4MTkxYjI2OTgyZTA",

	cloud_api_suffix: "/api/",
	cloud_dms_suffix: "/dms/trade/documents/",
	cloud_api_data_suffix: `/api/apps/${APP_ID}/data/`,
	cloud_api_apps_suffix: `/api/apps/${APP_ID}/`,

	dms: {
		shareDocumentMeta: true,
		maxDocumentSizeKb: 2000
	},

	appCredentials: {
		login: "trade",
		password: "nipumaja45"
	},

	credentials: {
		login: "r.portier@calaosoft.fr",
		password: "test"
	},

	excludeLogins: [
		"invite.trade@calaosoft.fr"
	],

	accountBlockedMessage: "Compte bloqué. Merci de voir avec votre administrateur.",

	unlockRequired: true,

	onesignalId: "73f9c2bf-eacf-406f-ae66-ba55564e4d24",
	googleProjectNumber: "732741247990",
	thumbnailLogo: 'img/calaotrade-logo-large.png',
	pinEnabled: false
};

export const conversation: IConversationConfig = {
	visioApiUrl: "jitsi.calaosoft.fr",
	conversationActivityExpirationDelaySeconds: 120
};

export const statusBar: IStatusBarConfig = { backgroundColorByHexString: "#ff9900" };