import { Inject, Injectable, Optional } from '@angular/core';
import { EUpdateStatus } from '@calaosoft/osapp-common/sqlite/models/eupdate-status';
import { IProviderFilesOptions } from '@calaosoft/osapp-common/sqlite/models/iprovider-files-options';
import { SqlDataSource } from '@calaosoft/osapp-common/sqlite/models/sql-data-source';
import { UpdateEvent } from '@calaosoft/osapp-common/sqlite/models/update-event';
import { NotImplementedYetError } from '@calaosoft/osapp-common/utils/errors/not-implemented-yet-error';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { Observable, defer } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { LoggerService } from '../../../logger/services/logger.service';
import { SqlAdapter } from '../../models/SqlAdapter';
import { SqlDatabaseProvider } from '../../models/SqlDatabaseProvider';
import { SqlRemoteProvider } from './sql-remote-provider.service';

@Injectable()
export class BrowserDatabaseProviderService extends SqlDatabaseProvider {

	//#region FIELDS

	private static readonly C_LOG_ID_BROWSER = "BRWSR.DB.PRVDR.S::";

	//#endregion FIELDS

	//#region METHODS

	public constructor(
		psvcAdapter: SqlAdapter<any>,
		psvcLogger: LoggerService,
		@Optional() @Inject(SqlRemoteProvider) paRemoteProviders?: SqlRemoteProvider[],
	) {
		super(psvcAdapter, psvcLogger, paRemoteProviders);
	}

	/** @override */
	public override isDataSourceReadyAsync(poDataSource: SqlDataSource): Promise<boolean> {
		return Promise.resolve(this.isvcAdapter.isOpened(poDataSource));
	}

	/** @override */
	protected override provideDatabase$(psDataSource: SqlDataSource): Observable<UpdateEvent> {
		return defer(() => this.isvcAdapter.openAsync(psDataSource))
			.pipe(mapTo(new UpdateEvent(EUpdateStatus.saved, 100)));
	}

	/** @override */
	public override getNumberOfDatabasesAsync(psDatabasePrefix: string, poOptions?: IProviderFilesOptions): Promise<number> {
		if (StringHelper.isBlank(psDatabasePrefix)) {
			console.error(`${BrowserDatabaseProviderService.C_LOG_ID_BROWSER}Database prefix '${psDatabasePrefix}' is not valid.`);
			return Promise.reject(new Error(`The recovery of the number of databases failed because database prefix is not valid.`));
		}

		const laDatabaseIds: string[] = this.isvcAdapter.getDatabaseIds();
		// Underscore est utilisé comme séparation lors du hash du DataSource qui est la clé de la map (ex: `stock-854258_9_...`, soit `databaseId_version_...`).
		const loRegex = new RegExp(`${psDatabasePrefix}.*?-\\d+`);
		const laDatabaseIdsMatched: string[] = laDatabaseIds.filter((lsDatabseId: string) => loRegex.test(lsDatabseId));

		return Promise.resolve(laDatabaseIdsMatched.length);
	}

	/** @override */
	public override getLastReadyAsync(psDatabaseId: string): Promise<SqlDataSource> {
		if (StringHelper.isBlank(psDatabaseId)) {
			console.error(`${BrowserDatabaseProviderService.C_LOG_ID_BROWSER}Database id '${psDatabaseId}' is not valid.`);
			return Promise.reject(new Error(`Recovery of last available database failed because database id is not valid.`));
		}

		return this.getRemoteProvider(psDatabaseId).getLastDatabaseAsync(psDatabaseId);
	}

	/** @override */
	public override getDatabasesAsync(psDatabasePrefix: string, poOptions: IProviderFilesOptions): Promise<SqlDataSource[]> {
		if (StringHelper.isBlank(psDatabasePrefix)) {
			console.error(`${BrowserDatabaseProviderService.C_LOG_ID_BROWSER}Database prefix '${psDatabasePrefix}' is not valid.`);
			return Promise.reject(new Error(`Database recovery failed because database prefix is not valid.`));
		}

		const laDatabaseIds: string[] = this.isvcAdapter.getDatabaseIds();
		const laSqlDataSources: SqlDataSource[] = [];
		// Underscore est utilisé comme séparation lors du hash du DataSource qui est la clé de la map (ex: `stock-854258_9_...`, soit `databaseId_version_...`).
		const loRegex = new RegExp(`${psDatabasePrefix}.*?_\\d+`);

		laDatabaseIds.forEach((psDatabaseId: string) => {
			if (loRegex.test(psDatabaseId))
				laSqlDataSources.push(SqlDataSource.fromHashCode(psDatabaseId));
		});

		return Promise.resolve(laSqlDataSources);
	}

	/** @override
	 * @throws NotImplementedYetError
	 */
	public override getLastDownloadedDatabaseNameAsync(psDatabaseId: string): Promise<string> {
		return Promise.reject(new NotImplementedYetError("getLastDownloadedDatabaseNameAsync()"));
	}

	/** @override
	 * @throws NotImplementedYetError
	 */
	public override getLastVersionDownloadedAsync(psDatabaseId: string): Promise<number> {
		return Promise.reject(new NotImplementedYetError("getLastVersionDownloadedAsync()"));
	}

	/** @override
	 * @throws NotImplementedYetError
	 */
	public override databaseExistsAsync(psDatabaseVersion: string, psDatabaseId: string, poOptions?: IProviderFilesOptions): Promise<boolean> {
		return Promise.reject(new NotImplementedYetError("databaseExists()"));
	}

	/** @override */
	public override getLastUrl(psDatabasePrefix: string, psDatabaseId: string, pnVersion: number): string {
		if (StringHelper.isBlank(psDatabasePrefix)) {
			console.error(`${BrowserDatabaseProviderService.C_LOG_ID_BROWSER}Database prefix '${psDatabasePrefix}' for database id '${psDatabaseId}' with version '${pnVersion}' is not valid.`);
			throw new Error(`Recovery of last database URL failed because database prefix is not valid.`);
		}
		else
			return this.getRemoteProvider(psDatabasePrefix).getUrl(psDatabaseId, pnVersion);
	}

	/** @override Sur navigateur, il n'y a rien besoin de faire, on n'a pas la base de données en locale. */
	protected override execRemoveDatabaseAsync(psDatabaseId: string, pnVersion?: undefined): Promise<void> {
		return Promise.resolve();
	}

	//#endregion METHODS

}