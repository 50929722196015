import { Exclude } from "@calaosoft/osapp-common/class-transformer";
import { Entity } from "@calaosoft/osapp-common/entities/models/entity";
import { IEntity } from "@calaosoft/osapp-common/entities/models/ientity";
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { ResolveModel } from "@calaosoft/osapp-common/utils/decorators/resolve-model.decorator";
import { DocumentFields } from "./document-fields";
import { Folder } from "./folder";
import { IDocumentFields } from "./idocument-fields";
import { IFormDocument } from "./iform-document";

@ModelMatch((poFormDocument?: any) => !!poFormDocument?.$document, Entity)
export class FormDocument extends Entity implements IFormDocument, IDocumentFields {

	//#region PROPERTIES

	/** @implements */
	@ResolveModel(DocumentFields)
	public $document: DocumentFields;
	/** @implements */
	@ResolveModel(Date)
	public archiveDate?: Date;
	/** @implements */
	@ResolveModel(Date)
	public restoreDate?: Date;

	public override createDate: string | Date;
	/** @implements */
	public get name(): string { return this.$document.name; };
	/** @implements */
	public get authorId(): string { return this.$document.authorId; };
	/** @implements */
	public get displayDate(): string | undefined { return this.$document.displayDate; };
	/** @implements */
	public get paths(): string[] { return this.$document.paths; };

	/** Icône du type de fichier. */
	@Exclude()
	public icon?: string;
	@Exclude()
	/** Nom de l'auteur. */
	@Exclude()
	public authorName?: string;
	@Exclude()
	/** Type de document. */
	@Exclude()
	public type?: string;
	@Exclude()
	/** Arbre de navigation pour atteindre le document avec son 1er chemin. */
	@Exclude()
	public observableNavigationTree = new ObservableArray<Folder>([]);
	@Exclude()
	/** Indique si le document est lu ou non. */
	public readonly observableIsRead = new ObservableProperty<boolean>(false);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: IEntity) {
		super(poData);

		this.createDate = poData?.createDate ?? new Date;
	}

	public get isInTrash(): boolean {
		return this.paths.some((psPath: string) => psPath.startsWith("trash"));
	}

	public get canArchive(): boolean {
		return this.paths.some((psPath: string) => !this.isInTrash && !psPath.startsWith("archives"));
	}

	public get canRestore(): boolean {
		return this.paths.some((psPath: string) => this.isInTrash || psPath.startsWith("archives"));
	}

	//#endregion METHODS

}
