import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { ESortOrder } from '@calaosoft/osapp-common/utils/models/ESortOrder';
import { Observable, merge } from 'rxjs';
import { filter, map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { ActivePageManager } from '../../../../../model/navigation/ActivePageManager';
import { Store } from '../../../../../services/store.service';
import { IColumnListSortEvent } from '../../../../lists/models/icolumn-list-sort-event';
import { DestroyableComponentBase } from '../../../../utils/components/destroyable-component-base';
import { IChangeTrackerItem } from '../../models/ichange-tracker-item';
import { ILot } from '../../models/ilot';
import { ChangeTrackingService } from '../../services/change-tracking.service';

@Component({
	selector: 'calao-change-tracked-database-detail',
	templateUrl: './change-tracked-database-detail.component.html',
	styleUrls: ['./change-tracked-database-detail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeTrackedDatabaseDetailComponent extends DestroyableComponentBase {

	//#region FIELDS

	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter);

	private readonly moObservableSort = new ObservableProperty<IColumnListSortEvent<IChangeTrackerItem>>(
		{ key: "id", order: ESortOrder.ascending }
	);

	//#endregion FIELDS

	//#region PROPERTIES

	/** Identifiant de la base de données. */
	@Input() public databaseId?: string | null;
	@ObserveProperty<ChangeTrackedDatabaseDetailComponent>({ sourcePropertyKey: "databaseId" })
	public readonly observableDatabaseId = new ObservableProperty<string>();

	public readonly observableLots: ObservableArray<ILot>;
	public readonly observableTrackedItems: ObservableArray<IChangeTrackerItem>;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcChangeTracking: ChangeTrackingService,
		private readonly isvcStore: Store,
		private readonly ioRouter: Router
	) {
		super();

		this.observableTrackedItems = new ObservableArray<IChangeTrackerItem>(
			this.getChangeTrackerItems$().pipe(secure(this))
		);
		this.observableLots = new ObservableArray(this.getLot$().pipe(secure(this)));
	}

	private getChangeTrackerItems$(): Observable<IChangeTrackerItem[]> {
		return this.observableDatabaseId.value$.pipe(
			switchMap((psDatabaseId: string) => {
				return merge(
					this.isvcStore.localChanges({ databaseId: psDatabaseId }),
					this.isvcStore.changes({ databaseId: psDatabaseId, remoteChanges: true, activePageManager: this.moActivePageManager })
				).pipe(
					startWith(undefined),
					mergeMap(() => this.isvcChangeTracking.getLastLotAsync(psDatabaseId)),
					mergeMap((poLastLot?: ILot) => this.isvcChangeTracking.getTrackedAsync(psDatabaseId, poLastLot)),
					switchMap((paResults: IChangeTrackerItem[]) => {
						return this.moObservableSort.value$.pipe(
							map((poSortEvent: IColumnListSortEvent<IChangeTrackerItem>) =>
								ArrayHelper.dynamicSort(paResults, poSortEvent.key, poSortEvent.order)
							)
						);
					})
				);
			})
		);
	}

	private getLot$(): Observable<ILot[]> {
		return this.observableDatabaseId.value$.pipe(
			filter((psDatabaseId?: string) => StringHelper.isValid(psDatabaseId)),
			mergeMap((psDatabaseId: string) => this.isvcChangeTracking.getLotsAsync(psDatabaseId))
		);
	}

	public onSortClicked(poEvent: IColumnListSortEvent<any>): void {
		this.moObservableSort.value = poEvent;
	}

	//#endregion METHODS

}