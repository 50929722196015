import { Exclude } from "@calaosoft/osapp-common/class-transformer";
import { Entity } from "@calaosoft/osapp-common/entities/models/entity";
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { EMessageAttachmentType } from "./emessage-attachment-type";
import { IMessageAttachment } from "./imessage-attachment";
import { IMessageEntityAttachment } from "./imessage-entity-attachment";
import { MessageAttachment } from "./message-attachment";

const C_TYPE = EMessageAttachmentType.entity;

@ModelMatch((poData: IMessageAttachment) => poData.type === C_TYPE, MessageAttachment)
export class MessageEntityAttachment extends MessageAttachment implements IMessageEntityAttachment {

	//#region PROPERTIES

	public entityPath: string;

	@Exclude()
	/** Si l'entité est indiquée, on part du principe que c'est une entité en phase d'ajout. */
	public entity?: Entity;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: Omit<IMessageEntityAttachment, "type">) {
		super({ ...(poData ?? {}), type: C_TYPE });
	}

	//#endregion METHODS

}
