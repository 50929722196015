import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';

@Component({
	selector: 'trade-todo-item-inline',
	templateUrl: './todo-item-inline.component.html',
	styleUrls: ['./todo-item-inline.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoItemInlineComponent {

	//#region PROPERTIES

	/** Couleur de l'icône dans la partie gauche de l'item. */
	@Input() public itemStartIconColor?: string;
	@ObserveProperty<TodoItemInlineComponent>({ sourcePropertyKey: "itemStartIconColor" })
	public readonly observableItemStartIconColor = new ObservableProperty<string>("#000000");

	/** Couleur de fond de l'item. */
	@Input() public itemBackgroundColor?: string;
	@ObserveProperty<TodoItemInlineComponent>({ sourcePropertyKey: "itemBackgroundColor" })
	public readonly observableItemBackgroundColor = new ObservableProperty<string>("#ffffff");

	/** Nombres de tâches restantes en plus de celles affichées. */
	@Input() public diffCount?: number;
	@ObserveProperty<TodoItemInlineComponent>({ sourcePropertyKey: "diffCount" })
	public readonly observableDiffCount = new ObservableProperty<number>();

	/** Concaténation des titres des tâches. */
	@Input() public content?: string;
	@ObserveProperty<TodoItemInlineComponent>({ sourcePropertyKey: "content" })
	public readonly observableContent = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcRouter: Router,
		private readonly poActivatedRoute: ActivatedRoute,
	) { }

	public navigateToTodoListPageAsync(): Promise<boolean> {
		return this.isvcRouter.navigate(["todo"], { relativeTo: this.poActivatedRoute })
	}

	//#endregion METHODS


}
