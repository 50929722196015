import { Component } from '@angular/core';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';

@Component({
	selector: 'calao-stat-horizontal-separator',
	templateUrl: './stat-horizontal-separator.component.html',
	styleUrls: ['./stat-horizontal-separator.component.scss'],
})
export class StatHorizontalSeparatorComponent extends FieldBase {

	constructor(poForms: FormsService) {
		super(poForms);
	}
}
