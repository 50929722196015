import { Exclude } from "@calaosoft/osapp-common/class-transformer";
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { IOldInterventionStatement } from "./iintervention-statement";

export class OldInterventionStatement implements IOldInterventionStatement {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];

	public skipReason: string;

	@Exclude()
	private mdInterventionDate: Date;
	/** Date de l'intervention. */
	public get interventionDate(): Date {
		if (!this.mdInterventionDate)
			this.mdInterventionDate = DateHelper.parseReverseDate(this.interventionDateString);

		return this.mdInterventionDate;
	}

	@Exclude()
	private mdInterventionDateString: string;
	/** Date de l'intervention. */
	public get interventionDateString(): string {
		if (!this.mdInterventionDateString)
			this.mdInterventionDateString = OldInterventionStatement.extractInterventionDateString(this._id);

		return this.mdInterventionDateString;
	}

	@Exclude()
	private mdCreateDate: Date;
	/** Date de création du relevé d'intervention. */
	public get createDate(): Date {
		if (!this.mdCreateDate)
			this.mdCreateDate = OldInterventionStatement.extractCreateDate(this._id);

		return this.mdCreateDate;
	}

	@Exclude()
	private msSiteId: string;
	/** Id du Site */
	public get siteId(): string {
		if (!this.msSiteId)
			this.msSiteId = OldInterventionStatement.extractSiteId(this._id);

		return this.msSiteId;
	}

	@Exclude()
	private msEventId: string;
	/** Id du Event. */
	public get eventId(): string {
		if (!this.msEventId)
			this.msEventId = OldInterventionStatement.extractEventId(this._id);

		return this.msEventId;
	}

	@Exclude()
	private msCustomerId: string;
	/** Id du Customer. */
	public get customerId(): string {
		if (!this.msCustomerId)
			this.msCustomerId = ""; // TODO : Intervention statement

		return this.msCustomerId;
	}

	public get canceled(): boolean {
		return !StringHelper.isBlank(this.skipReason);
	}

	//#endregion

	//#region METHODS

	public static extractCreateDate(psInterventionStatementId: string): Date {
		return ArrayHelper.getLastElement(IdHelper.extractDatesFromId(psInterventionStatementId));
	}

	public static extractInterventionDate(psInterventionStatementId: string): Date {
		return ArrayHelper.getFirstElement(IdHelper.extractDatesFromId(psInterventionStatementId));
	}

	public static extractInterventionDateString(psInterventionStatementId: string): string {
		return ArrayHelper.getFirstElement(IdHelper.extractDatesStringsFromId(psInterventionStatementId));
	}

	public static extractSiteId(psInterventionStatementId: string): string {
		return ""; // TODO intervention statement
	}

	public static extractCustomerId(psInterventionStatementId: string): string {
		return ""; // TODO intervention statement
	}

	public static extractEventId(psInterventionStatementId: string): string {
		const lsParentId: string = IdHelper.extractParentId(psInterventionStatementId);
		const lsSiteGuid: string = IdHelper.getLastGuidFromId(""); // TODO intervention statement

		return lsParentId.replace(new RegExp(`${lsSiteGuid}${IdHelper.C_VIRTUAL_NODE_SEPARATOR}\\w+${IdHelper.C_VIRTUAL_NODE_SEPARATOR}`),
			`${lsSiteGuid}${IdHelper.C_VIRTUAL_NODE_SEPARATOR}`);
	}

	//#endregion

}
