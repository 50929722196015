import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IContactsListParams } from '../../../../model/contacts/IContactsListParams';
import { ISelectorParams } from '../../../selector/selector/ISelectorParams';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { IDocExlorerFilterValues } from '../../models/idoc-explorer-filter-values';

@Component({
	selector: 'calao-doc-explorer-filter-bar',
	templateUrl: './doc-explorer-filter-bar.component.html',
	styleUrls: ['./doc-explorer-filter-bar.component.scss'],
})
export class DocExplorerFilterBarComponent<T extends IDocExlorerFilterValues> extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	/** Événement lors du changement des filtres. */
	@Output("onFilterValuesChange") private readonly moFilterValuesChangeEvent = new EventEmitter<T>();
	/** Événement lors du changement des filtres temporaires. */
	@Output("onTmpFilterValuesChange") private readonly moTmpFilterValuesChangeEvent = new EventEmitter<T>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Valeur des filtres. */
	@Input() public filterValues?: IDocExlorerFilterValues | null;
	@ObserveProperty<DocExplorerFilterBarComponent<T>>({ sourcePropertyKey: "filterValues" })
	public readonly observableFilterValues = new ObservableProperty<IDocExlorerFilterValues>();

	/** Nombre de résultats si les filtres sont validés. */
	@Input() public nbTmpResults?: number;
	@ObserveProperty<DocExplorerFilterBarComponent<T>>({ sourcePropertyKey: "nbTmpResults" })
	public readonly observableNbTmpResults = new ObservableProperty<number>();

	/** Configuration du composant osapp-selector pour le filtrage par état de lecture. */
	@Input() public notReadSelectorParams?: ISelectorParams | null;
	@ObserveProperty<DocExplorerFilterBarComponent<T>>({ sourcePropertyKey: "notReadSelectorParams" })
	public readonly observableNotReadSelectorParams = new ObservableProperty<ISelectorParams>();

	public readonly nbFiltersLabel$: Observable<string | undefined> = this.getNbFiltersLabel$().pipe(
		secure(this)
	);

	public readonly contactsSelectorParams: IContactsListParams = {
		contactsSelectorParams: {
			onlyUserContacts: true
		},
		contactsById: true
	};

	//#endregion PROPERTIES

	//#region METHODS

	constructor() {
		super();
	}

	public ngOnInit(): void {

	}

	public onFilterValuesChange(poFilterValues: T): void {
		this.moFilterValuesChangeEvent.emit(this.observableFilterValues.value = poFilterValues);
	}

	public onTmpFilterValuesChange(poTmpFilterValues: T): void {
		this.moTmpFilterValuesChangeEvent.emit(poTmpFilterValues);
	}

	private getNbFiltersLabel$(): Observable<string | undefined> {
		return this.observableFilterValues.value$.pipe(
			map((poValues: IDocExlorerFilterValues) => {
				const lnFilterNumber: number = Object.keys(
					ObjectHelper.omit(poValues, ["name"])
				).filter((psKey: keyof IDocExlorerFilterValues) => ObjectHelper.isDefined(poValues[psKey])).length;

				return lnFilterNumber > 0 ? `${lnFilterNumber}` : "";
			})
		);
	}

	public onNotReadSelectionChanged(poEvent: boolean[], observableValue: ObservableProperty<boolean>): void {
		observableValue.value = ArrayHelper.getFirstElement(poEvent);
	}

	//#endregion METHODS

}
