import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';

@Component({
	selector: 'calao-list-item-start',
	templateUrl: './list-item-start.component.html',
	styleUrls: ['./list-item-start.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'"
	}
})
export class ListItemStartComponent {

	//#region PROPERTIES

	/** Couleur de fond. */
	@Input() public backgroundColor?: string | null;
	@ObserveProperty<ListItemStartComponent>({ sourcePropertyKey: "backgroundColor" })
	public readonly observableBackgroundColor = new ObservableProperty<string>("var(--ion-color-background)");

	//#endregion

}
