import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { CameraSource, ImageOptions } from '@capacitor/camera';
import { Platform } from '@ionic/angular';
import { EMPTY, Subscription, from, fromEvent, timer } from 'rxjs';
import { filter, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { IFilePickerParams } from '../../model/file/IFilePickerParams';
import { CameraService } from '../../modules/camera/services/camera.service';
import { IHasLoader } from '../../modules/loading/models/ihas-loader';
import { DestroyableComponentBase } from '../../modules/utils/components/destroyable-component-base';
import { FileService } from '../../services/file.service';
import { LoadingService } from '../../services/loading.service';
import { PlatformService } from '../../services/platform.service';

@Component({
	selector: "file-picker",
	templateUrl: './filePicker.component.html',
	styleUrls: ['./filePicker.component.scss']
})
export class FilePickerComponent extends DestroyableComponentBase implements OnInit, AfterViewInit, IFilePickerParams, IHasLoader {

	//#region FIELDS

	private static readonly C_DEFAULT_ICON_NAME: string = "document";
	private static readonly C_DEFAULT_WAITING_TIME_MS: number = 500;

	/** Valeur du dernier input. */
	private msLastInputValue = "";
	private mbPickerClicked: boolean;

	@ViewChild("fileInput") private readonly moFileInput: ElementRef<HTMLInputElement>;

	/** Événement à lever pour indiquer que des fichiers ont étés ajoutés. */
	@Output("fileSelected") private readonly moFileSelectedEvent = new EventEmitter<File>();
	/** Événement qui indique si le composant charge actuellement un fichier ou non. */
	@Output("isLoading") private readonly moIsLoadingEvent = new EventEmitter<boolean>();

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public options: ImageOptions;
	/** @implements */
	@Input() public iconName: string;
	/** @implements */
	@Input() public multiple?: boolean;
	@ObserveProperty<FilePickerComponent>({
		sourcePropertyKey: "multiple",
		transformer: (poNewVal: any, poThis: FilePickerComponent) =>
			coerceBooleanProperty(poNewVal) && !poThis.isvcPlatform.isAndroid // On interdit le multiple pour les android suite à un bug capacitor 4
	})
	public readonly observableMultiple = new ObservableProperty<boolean>();
	/** @implements */
	@Input() public accept: string;
	/** @implements */
	@Input() public maxSizeKb: number;
	/** @implements */
	@Input() public label: string;

	private mbDisabled: boolean;
	public get disabled(): boolean {
		return this.mbDisabled;
	}
	/** @implements */
	@Input() public set disabled(pbNewValue: boolean) {
		if (pbNewValue !== this.mbDisabled)
			this.mbDisabled = pbNewValue;
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcCamera: CameraService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcFile: FileService,
		public readonly isvcLoading: LoadingService,
		poPlatform: Platform
	) {
		super();

		// Arrête l'animation du chargement.
		// Le chargement ne s'arrête pas tout seul, si l'utilisateur ouvre le gestionnaire de fichiers, puis fait retour (aucun évènement reçu).
		if (this.isvcPlatform.isMobileApp) {
			poPlatform.resume
				.pipe(
					tap(() => this.stopLoading(1000)),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
		else {
			fromEvent(window, "focus")
				.pipe(
					tap(() => {
						if (this.mbPickerClicked) {
							this.stopLoading(1000);
							this.mbPickerClicked = false;
						}
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	public ngOnInit(): void {
		if (StringHelper.isBlank(this.iconName))
			this.iconName = FilePickerComponent.C_DEFAULT_ICON_NAME;

		if (!this.options)
			this.options = this.isvcCamera.getAndSetCameraOptions(undefined, CameraSource.Photos);
	}

	public ngAfterViewInit(): void {
		this.moFileInput.nativeElement.onclick = (poEvent: MouseEvent) => {
			this.mbPickerClicked = true;
			if ((poEvent.target as HTMLInputElement).value) {
				this.msLastInputValue = (poEvent.target as HTMLInputElement).value;
				(poEvent.target as HTMLInputElement).value = "";

				/* Affiche l'animation de chargement dans 0.5 sec. Permet de n'être visible avant l'affichage du gestionnaire de fichier,
					que quand l'appareil est trop lent. */
				this.startLoading(FilePickerComponent.C_DEFAULT_WAITING_TIME_MS);
			}
		};
	}

	/** Permet de choisir un ou plusieurs fichiers sur le mobile en fonction de la plateforme et de paramètres. */
	public pickFiles(): void {
		this.moFileInput.nativeElement.click();
	}

	/** Le fichier choisi par le filepicker a changé.
	 * @param poEvent Événement lié à l'ajout d'un ou plusieurs fichiers via le bouton d'ajout de fichiers.
	 */
	public onFilesSelected(poEvent: Event): void {
		const loFileList: FileList | null = (poEvent.target as HTMLInputElement).files;

		from(loFileList ? Array.from(loFileList) : EMPTY)
			.pipe(
				filter((poFile: File) => this.msLastInputValue.indexOf(poFile.name) < 0),
				mergeMap((poFile: File) => this.isvcFile.processFile$(poFile, this.maxSizeKb)),
				filter((poFile?: File) => !!poFile),
				tap((poItem: File) => this.moFileSelectedEvent.emit(poItem)),
				finalize(() => this.stopLoading())
			)
			.subscribe();
	}

	/** Déclenche l'animation de chargement dans `pnWaitingTime`.
	 * @param pnWaitingTime Nombre de millisecondes avant de lancer l'animation.
	 */
	private startLoading(pnWaitingTime: number): Subscription {
		if (!pnWaitingTime)
			pnWaitingTime = FilePickerComponent.C_DEFAULT_WAITING_TIME_MS;

		return timer(pnWaitingTime).pipe(tap(() => this.moIsLoadingEvent.emit(true)))
			.subscribe();
	}

	/** Arrête l'animation de chargement.
	 * @param pnWaitingTime Temps avant l'arrêt. Peut être 0.
	 */
	private stopLoading(pnWaitingTime: number = 0): void {
		if (!pnWaitingTime)
			this.moIsLoadingEvent.emit(false);
		else
			timer(pnWaitingTime).pipe(tap(() => this.moIsLoadingEvent.emit(false)))
				.subscribe();
	}

	//#endregion

}