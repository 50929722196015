import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { ResolveModel } from "@calaosoft/osapp-common/utils/decorators/resolve-model.decorator";
import { IRange } from '@calaosoft/osapp-common/utils/models/irange';
import { DefaultValue } from "../../utils/models/decorators/default-value.decorator";
import { EEventOccurrenceCriterionType } from "./eevent-occurrence-criterion-type";
import { EventOccurrenceCriterion } from "./event-occurrence-criterion";
import { IConstraintLabelParams } from "./iconstraint-label-params";
import { IEventOccurrence } from "./ievent-occurrence";
import { IEventOccurrenceCriterion } from "./ievent-occurrence-criterion";
import { IEventOccurrenceDateCriterion } from "./ievent-occurrence-date-criterion";

const C_TYPE = EEventOccurrenceCriterionType.date;

@ModelMatch((poData: IEventOccurrenceCriterion) => poData?.type === C_TYPE, EventOccurrenceCriterion)
export class EventOccurrenceDateCriterion extends EventOccurrenceCriterion implements IEventOccurrenceDateCriterion {

	//#region PROPERTIES

	@ResolveModel(Date)
	@DefaultValue(() => new Date)
	public date: Date;

	public readonly type: EEventOccurrenceCriterionType = C_TYPE;

	//#endregion

	//#region METHODS

	constructor(poCriterion?: Partial<IEventOccurrenceDateCriterion>) {
		super(poCriterion);
	}

	public override getLabel(poParams: IConstraintLabelParams): string {
		throw new Error("Method not implemented.");
	}

	public override match(poEventOccurrence: IEventOccurrence): boolean {
		return DateHelper.areEqual(poEventOccurrence.startDate, this.date);
	}

	public override canMatchRange(poRange: IRange<Date>): boolean {
		return DateHelper.isDateInRange(this.date, poRange);
	}

	//#endregion

}
