import { Observable, ReplaySubject } from "rxjs";
import { ArrayHelper } from "../../utils/helpers/arrayHelper";
import { StringHelper } from "../../utils/helpers/stringHelper";
import { WaitInitialisation$ } from "../decorators/wait-initialisation.decorator";
import { IProviderFilesOptions } from "../models/iprovider-files-options";
import { SqlDataSource } from "../models/sql-data-source";
import { SqlRequestResult } from "../models/sql-request-result";
import { TRequestParam } from "../models/trequest-param";
import { TTransactionParams } from "../models/ttransaction-params";
import { TTransactionRequest } from "../models/ttransaction-request";
import { SqlAdapterBase } from "./sql-adapter-base";
import { SqlDatabaseProviderBase } from "./sql-database-provider-base";

export abstract class SqlWrapper {

	//#region FIELDS

	protected static readonly C_LOG_ID: string = "SQL.W::";

	/** Sujet pour indiquer que le service est prêt à être utilisé. */
	protected readonly moInitializedSubject = new ReplaySubject<void>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Abonnement pour être mis au courant de la fin d'initialisation de la base de données. */
	public readonly initialization$: Observable<void> = this.moInitializedSubject.asObservable();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcProvider: SqlDatabaseProviderBase,
		private readonly isvcAdapter: SqlAdapterBase<any>,
		private readonly mbHasPhysicalDatabase: boolean
	) { }

	/** Initialise le service.
	 * @param poDataSource Source de données de la base de données.
	 */
	public async openDatabaseAsync(poDataSource: SqlDataSource): Promise<void> {
		// La fonction init est appelée à chaque requête alors qu'elle ne devrait pas, on vérifie que l'adapteur existe pour éviter de le recréer.
		//todo : À corriger plus tard.

		if (this.mbHasPhysicalDatabase) {
			if (this.isvcAdapter.isOpened(poDataSource))
				return undefined;

			let lbExists = true;
			if (!poDataSource.ignoreExistenceCheck) {
				lbExists = !StringHelper.isBlank(
					await this.isvcProvider.getLastDownloadedDatabaseNameAsync(poDataSource.databaseId)
				);
			}

			if (lbExists) {
				await this.isvcAdapter.openAsync(poDataSource);
				this.raiseInitializedEvent();
			}
			else {
				// Si pas de fichier, on ouvre pas la base.
				console.error(`${SqlWrapper.C_LOG_ID}Trying to open database '${poDataSource.databaseId}' in version '${poDataSource.version}' without filename.`);
				return undefined;
			}
		}
		else {
			await this.isvcAdapter.openAsync(poDataSource);
			this.raiseInitializedEvent();
		}
	}

	/** Lève un événement pour indiquer que le service peut être utilisé. */
	private raiseInitializedEvent(): void {
		this.moInitializedSubject.next(undefined);
	}

	/** Supprime la base de données.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @throws Si l'identifiant de la base de données ne correspond à aucun `SqlDataSource`, alors une erreur est levé.
	 */
	public async removeDatabaseAsync(psDatabaseId: string): Promise<void> {
		const loSqlDatabaseSource: SqlDataSource | undefined = this.getOpenedDataSource(psDatabaseId);

		if (!loSqlDatabaseSource)
			return Promise.reject(new Error(`Deletion of database ${psDatabaseId} failed because the SQL data source of database '${psDatabaseId}' is missing.`));
		else {
			await this.isvcAdapter.closeDatabaseFromDataSourceAsync(loSqlDatabaseSource);
			return this.mbHasPhysicalDatabase ?
				this.isvcProvider.removeDatabaseAsync(psDatabaseId, loSqlDatabaseSource.version) : undefined;
		}
	}

	/** Ferme la base de données.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @throws Si l'identifiant de la base de données ne correspond à aucun `SqlDataSource`, alors une erreur est levé.
	 */
	public closeDatabaseAsync(psDatabaseId: string): Promise<void> {
		const loSqlDatabaseSource: SqlDataSource | undefined = this.getOpenedDataSource(psDatabaseId);

		if (!loSqlDatabaseSource)
			return Promise.reject(new Error(`Deletion of database ${psDatabaseId} failed because the SQL data source of database '${psDatabaseId}' is missing.`));
		else
			return this.isvcAdapter.closeDatabaseFromDataSourceAsync(loSqlDatabaseSource);
	}

	/** Vérifie si la base de données a été téléchargée.
	 * @param psDatabaseVersion Version de la base de données.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @param poOptions Options pour la récupération.
	 * @returns `true` si existe, `false` sinon.
	 */
	public databaseExistsAsync(psDatabaseVersion: string, psDatabaseId: string, poOptions?: IProviderFilesOptions): Promise<boolean> {
		return this.isvcProvider.databaseExistsAsync(psDatabaseVersion, psDatabaseId, poOptions);
	}

	/** Retourne la version courante de la base de données, `NaN` si aucune version présente.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	public getLastVersionDownloadedAsync(psDatabaseId: string): Promise<number> {
		return this.isvcProvider.getLastVersionDownloadedAsync(psDatabaseId);
	}

	public isOpened(poDatasource: SqlDataSource): boolean {
		return this.isvcAdapter.isOpened(poDatasource);
	}

	public getOpenedDataSource(psDatabaseId: string): SqlDataSource | undefined {
		const laDataSources: SqlDataSource[] = this.isvcAdapter.getOpenedDataSources(psDatabaseId)
			.filter((poDataSource: SqlDataSource) => poDataSource.databaseId === psDatabaseId);

		if (!ArrayHelper.hasElements(laDataSources))
			return undefined;
		else if (laDataSources.length > 1)
			console.error(`${SqlWrapper.C_LOG_ID}Multiple data sources with same id '${psDatabaseId}' : [${laDataSources.map((poItem: SqlDataSource) => `${poItem.databaseId}_v${poItem.version}`).join(", ")}].`);

		return ArrayHelper.getFirstElement(laDataSources);
	}

	/** Retourne le `SqlDataSource` le plus récent de la base de données, dont l'ID est passé en paramètre.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	public getDataSourceFromDatabaseIdAsync(psDatabaseId: string): Promise<SqlDataSource | undefined> {
		return this.isvcProvider.getLastReadyAsync(psDatabaseId);
	}

	//#region Requests

	/** Exécute et retourne le résultat d'une requête.
	 * @param poDataSource Base de données à requêter.
	 * @param psRequest Requête à exécuter.
	 * @param paParams Valeur à satisfaire pour filtrer les résultats (where).
	 * @param psDatabasePartialId L'ID partiel de la base de données.
	 */
	@WaitInitialisation$<SqlWrapper, Promise<any>>() //! Sans le typage des chevrons, il y a une erreur.
	public async requestAsync<T>(
		poDataSource?: SqlDataSource,
		psRequest?: string,
		paParams: TRequestParam[] = [],
		psDatabasePartialId?: string
	): Promise<SqlRequestResult<T>> {

		if (!poDataSource || StringHelper.isBlank(psRequest))
			return Promise.reject(new Error("No data source nor request to exec request !"));
		else if (StringHelper.isBlank(psDatabasePartialId))
			return Promise.resolve(new SqlRequestResult());

		try {
			return await this.isvcAdapter.requestAsync<T>(poDataSource, psRequest, paParams, psDatabasePartialId);
		} catch (poError) {
			console.error(`${SqlWrapper.C_LOG_ID}Error exec request from database '${poDataSource.databaseId}' in version '${poDataSource.version}' : {${psRequest}}.`, paParams, poError);
			return new SqlRequestResult();
		}
	}

	/** Exécute et retourne le résultat d'une série de requêtes dans une même transaction.
	 * @param poDataSource Base de données à requêter.
	 * @param paRequests Liste des requêtes à exécuter.
	 * @param paParams Liste des valeurs à injecter pour chaques requêtes.
	 * @param psDatabasePartialId L'ID partiel de la base de données.
	 */
	@WaitInitialisation$<SqlWrapper, Promise<any>>() //! Sans le typage des chevrons, il y a une erreur.
	public async runTransactionAsync<T = any>(
		poDataSource?: SqlDataSource,
		paRequests?: TTransactionRequest[],
		paParams: TTransactionParams[] = [],
		psDatabasePartialId?: string
	): Promise<SqlRequestResult<T>[]> {

		if (!poDataSource || !ArrayHelper.hasElements(paRequests))
			return Promise.reject(new Error("No data source nor requests to exec to run transaction !"));
		else if (StringHelper.isBlank(psDatabasePartialId))
			return Promise.resolve([]);

		try {
			return await this.isvcAdapter.runTransactionAsync(poDataSource, paRequests, paParams, psDatabasePartialId);
		} catch (poError) {
			console.error(`${SqlWrapper.C_LOG_ID}Error exec requests from database '${poDataSource.databaseId}' in version '${poDataSource.version}'.`, paRequests, paParams, poError);
			throw poError;
		}
	}

	//#endregion Requests

	//#endregion METHODS

}