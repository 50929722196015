import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';

@Component({
	selector: 'calao-dashboard-tile',
	templateUrl: './dashboard-tile.component.html',
	styleUrls: ['./dashboard-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardTileComponent extends DestroyableComponentBase {

	//#region PROPERTIES

	/** Contenu de la tuile. */
	@Input() public content?: string;
	@ObserveProperty<DashboardTileComponent>({ sourcePropertyKey: "content" })
	public readonly observableContent = new ObservableProperty<string>();

	/** Icône. */
	@Input() public icon?: string;
	@ObserveProperty<DashboardTileComponent>({ sourcePropertyKey: "icon" })
	public readonly observableIcon = new ObservableProperty<string>();

	/** Couleur de l'icône. Supporte les couleurs au format hex, rgb, rgba ou name. */
	@Input() public iconColor?: string;
	@ObserveProperty<DashboardTileComponent>({ sourcePropertyKey: "iconColor" })
	public readonly observableIconColor = new ObservableProperty<string>();

	/** Titre de la tuile. */
	@Input() public title?: string;
	@ObserveProperty<DashboardTileComponent>({ sourcePropertyKey: "title" })
	public readonly observableTitle = new ObservableProperty<string>();

	//#endregion

}
