import { classToPlain } from 'class-transformer';
import { ConfigData } from '../../config/models/ConfigData';
import { ArrayHelper } from '../../utils/helpers/arrayHelper';
import { StringHelper } from '../../utils/helpers/stringHelper';
import { IKeyValue } from '../../utils/models/ikey-value';
import { EDatabaseRole } from '../models/edatabase-role';
import { ICacheData } from '../models/icache-data';
import { IDatabaseConfig } from '../models/idatabase-config';
import { IStoreDocument } from '../models/istore-document';

/** Permet de mettre à disposition des méthodes pour aider à manipuler le store. */
export abstract class StoreHelper {

	//#region FIELDS

	/** "$cacheData" */
	public static readonly C_CACHE_DATA_FIELD_NAME = "$cacheData";

	//#endregion

	//#region METHODS

	/** Retourne le nom de la base de données d'où provient le document, chaîne vide si non renseigné.
	 * @param poDocument Document de données.
	 * @param psDefaultDatabaseId ID à utiliser en cas d'absence de databaseId dans les cacheData.
	 * @param pbFailIfNothing Indique si on lève une erreur dans le cas où on n'a pas de base de données dans la cacheData et pas de base de données par défaut de renseignée, 'true' par défaut.
	 */
	public static getDatabaseIdFromCacheData<T extends IStoreDocument>(poDocument: T, psDefaultDatabaseId: string = "", pbFailIfNothing: boolean = true): string {
		const loCacheData: ICacheData | undefined = StoreHelper.getDocumentCacheData(poDocument);
		let lsDatabaseId: string = psDefaultDatabaseId;

		if (loCacheData && loCacheData.databaseId)
			lsDatabaseId = loCacheData.databaseId;

		else if (StringHelper.isBlank(psDefaultDatabaseId)) {
			if (pbFailIfNothing) {
				const lsMessage = "Cannot retrieve database id from cache data for the document.";
				console.error(`STO.H:: ${lsMessage}`, poDocument);
				throw new Error(lsMessage);
			}
			else
				lsDatabaseId = "";
		}

		return lsDatabaseId;
	}

	/** Vérifie si le document possède une cacheData ou non.
	 * @param poDocument Document dont il faut vérifier l'existence des cacheData.
	 */
	public static hasCacheData(poDocument: IStoreDocument): boolean {
		return !!poDocument[StoreHelper.C_CACHE_DATA_FIELD_NAME];
	}

	/** Récupération de la cacheData d'un document, `undefined` si le document n'est pas renseigné ou si il ne contient pas de cacheData.
	 * @param poDocument Document dont il faut récupérer la cacheData.
	 */
	public static getDocumentCacheData<T extends IStoreDocument>(poDocument: T): ICacheData | undefined {
		return poDocument ? poDocument[StoreHelper.C_CACHE_DATA_FIELD_NAME] : undefined;
	}

	/** Défini les cacheData du document.
	 * @param poDocument Modèle de données.
	 * @param poCacheData Données de cache à appliquer.
	 */
	public static updateDocumentCacheData<T extends IStoreDocument>(poDocument: T, poCacheData: ICacheData): T {
		const loCacheData: ICacheData | undefined = StoreHelper.getDocumentCacheData(poDocument);

		if (loCacheData) {
			for (const lsProperty in poCacheData)
				loCacheData[lsProperty] = poCacheData[lsProperty];
		}
		else if (poDocument) {
			poDocument[StoreHelper.C_CACHE_DATA_FIELD_NAME] = poCacheData;
		}

		return poDocument;
	}

	/** Supprime la cacheData d'un document et retourne une copie de celle-ci.
	 * @param poDocument Document dont il faut supprimer la cacheData.
	 */
	public static deleteDocumentCacheData<T extends IStoreDocument>(poDocument: T): ICacheData | undefined {
		const loDocumentCacheData: ICacheData | undefined = StoreHelper.getDocumentCacheData(poDocument);

		delete poDocument[StoreHelper.C_CACHE_DATA_FIELD_NAME];

		return loDocumentCacheData;
	}

	/** Indique si le document possède l'état `dirty`.
	 * @param poDocument Document qu'il faut vérifier.
	 */
	public static isDocumentDirty<T extends IStoreDocument>(poDocument: T): boolean {
		const loCacheData: ICacheData | undefined = StoreHelper.getDocumentCacheData(poDocument);
		return !!loCacheData?.dirty;
	}

	/** Applique l'état `dirty` au document.
	 * @param poDocument Document qu'il faut rendre `dirty`.
	 */
	public static makeDocumentDirty<T extends IStoreDocument>(poDocument: T): void {
		StoreHelper.updateDocumentCacheData(poDocument, { dirty: true });
	}

	/** Enlève l'état `dirty` au document.
	 * @param poDocument Document qu'il faut rendre `not dirty`.
	 */
	public static makeDocumentNotDirty<T extends IStoreDocument>(poDocument: T): void {
		StoreHelper.updateDocumentCacheData(poDocument, { dirty: false });
	}

	/** Construit le chemin d'un document à partir de son ID et de celui de la base de données à laquelle il appartient. */
	public static getDocumentPathFromIdDatabaseId(psDocumentId: string, psDatabaseId: string): string {
		return `${psDatabaseId}/${psDocumentId}`;
	}

	/** Renvoie les rôles associés à une base de données, lève une erreur si elle est inconnue.
	 * @param psDatabaseName
	 * @throws Erreur si la base est inconnue.
	*/
	public static getDatabaseRoles(psDatabaseName: string): EDatabaseRole[] {
		const loDatabaseConfig: IDatabaseConfig | undefined = ConfigData.databases?.find((poDatabaseConfig: IDatabaseConfig) => poDatabaseConfig.id === psDatabaseName);

		if (!loDatabaseConfig)
			throw new Error(`Unknown database ${psDatabaseName}.`);
		else
			return loDatabaseConfig.roles;
	}

	/** Indique si deux documents sont égaux (même identifiant et même révision).
	 * @param poDocumentA
	 * @param poDocumentB
	 * @returns
	 */
	public static areDocumentsEqual<T extends IStoreDocument>(poDocumentA?: T, poDocumentB?: T): boolean {
		if (!poDocumentA || !poDocumentB)
			return false;

		return poDocumentA === poDocumentB || (poDocumentA._id === poDocumentB._id && poDocumentA._rev === poDocumentB._rev);
	}

	/** Indique si on la base est une base remote.
	 *
	 * @param psDatabaseName Nom de la base pouchdb (pouchdbDatabase.name).
	 */
	public static isRemoteDatabase(psDatabaseName: string): boolean {
		return psDatabaseName.includes(ConfigData.environment.cloud_url);
	}

	/** Récupère le numéro de séquence depuis une séquence sous forme de chaîne de caractères, 0 s'il n'en a pas.
	 * @param poSequence Séquence dont il faut extraire le numéro.
	 */
	public static getSequenceNumber(poSequence: string | number): number {
		if (typeof poSequence === "number")
			return poSequence;

		return !StringHelper.isBlank(poSequence) ? +(poSequence.replace(/-.+/, '')) : 0;
	}

	public static getCleanedDocument<T extends IStoreDocument>(poDocument: T): T {
		const loDocumentCopy: T = classToPlain(poDocument, { excludePrefixes: ["#"] }) as T;
		StoreHelper.deleteDocumentCacheData(loDocumentCopy);

		return loDocumentCopy;
	}

	/** Retourne 9 documents en prenant les 3 premiers, 3 au milieu et les 3 derniers.
	 * @param paDocs Documents à filtrer.
	 */
	public static getNineDocs(paDocs: IStoreDocument[]): IStoreDocument[] {
		return [
			...ArrayHelper.getSection(paDocs, 0, 2),
			...ArrayHelper.getSection(paDocs, paDocs.length - 3, paDocs.length),
			...ArrayHelper.getSection(paDocs, Math.abs(paDocs.length / 2), Math.abs(paDocs.length / 2) + 2)
		];
	}

	public static mapFunctionExecutor(
		poDoc: IStoreDocument,
		psViewFunction?: string
	): IKeyValue<string | number | string[], any>[] {
		const laResults: IKeyValue<string | number | string[], any>[] = [];

		if (!StringHelper.isBlank(psViewFunction)) {
			Function.apply(
				null,
				["emit", `return (${psViewFunction.replace(/;\s*$/, "")});`]
			).apply(
				null,
				[(poKey: string | number, poValue: any) => laResults.push({ key: poKey, value: poValue })]
			)(poDoc);
		}

		return laResults;
	}

	//#endregion
}
