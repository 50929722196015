import { Exclude, Expose } from "@calaosoft/osapp-common/class-transformer";
import { Entity } from "@calaosoft/osapp-common/entities/models/entity";
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { EGender } from "../../../model/EGender";
import { IContact } from "../../../model/contacts/IContact";
import { Group } from "../../../model/contacts/group";
import { Picture } from "../../../model/picture/picture";

@ModelMatch((poContact?: IContact) => IdHelper.hasPrefixId(poContact?._id ?? "", EPrefix.contact), Entity)
export class Contact extends Entity implements IContact {

	//#region PROPERTIES

	/** @implements */
	public firstName?: string;
	/** @implements */
	public lastName?: string;
	/** @implements */
	public displayName?: string;
	/** @implements */
	public maidenName?: string;
	/** @implements */
	public birthDate?: Date;
	/** @implements */
	public emailChecked?: boolean;
	/** @implements */
	public gender?: EGender;
	/** @implements */
	public profession?: string;
	/** @implements */
	public userId?: string;
	/** @implements */
	public latitude?: number;
	/** @implements */
	public longitude?: number;
	/** @implements */
	public htmlBody?: string;
	/** @implements */
	public avatarUrl?: string;
	/** @implements */
	public roles?: string[];
	/** @implements */
	public agendaId?: string;
	/** @implements */
	public email?: string;
	/** @implements */
	public phone?: string;
	/** @implements */
	public picture?: Picture;
	/** @implements */
	public zipCode?: string;
	/** @implements */
	public street?: string;

	@Exclude({ toPlainOnly: true })
	protected msCity?: string;
	/** @implements */
	@Expose()
	public get city(): string | undefined { return this.msCity; }

	public set city(psCity: string) {
		this.msCity = psCity;
	}


	@Exclude({ toPlainOnly: true })
	public groups?: Group[];

	//#endregion

	//#region METHODS

	constructor(poData?: Partial<IContact>) {
		super();

		if (poData)
			ObjectHelper.assign(this, poData);
	}

	//#endregion METHODS
}
