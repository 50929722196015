import { StoreDocument } from "@calaosoft/osapp-common/store/models/store-document";
import { ResolveModel } from "@calaosoft/osapp-common/utils/decorators/resolve-model.decorator";
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { IEventState } from "./ievent-state";

export class EventState extends StoreDocument implements IEventState {

	//#region PROPERTIES

	public userId: string;
	public status?: string;

	@ResolveModel(Date)
	public lastUpdate: Date;

	@ResolveModel(Date)
	public lastEndedDate?: Date;

	//#endregion

	//#region METHODS

	constructor(poEventState?: IEventState) {
		super();
		if (poEventState) {
			ObjectHelper.assign(this, poEventState);
		}
	}
}

//#endregion