
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { IPicture } from '../../../model/picture/IPicture';
import { ISite } from './isite';

@ModelMatch((poSite?: Entity) => IdHelper.hasPrefixId(poSite?._id ?? "", EPrefix.site), Entity)
export class Site extends Entity implements ISite {

	//#region PROPERTIES

	/** @implements */
	public name: string;
	/** @implements */
	public picture?: IPicture;
	/** @implements */
	public email?: string;
	/** @implements */
	public phone?: string;
	/** @implements */
	public street?: string;
	/** @implements */
	public zipCode?: string;
	/** @implements */
	public city?: string;

	//#endregion

	//#region METHODS

	constructor(poSite?: ISite) {
		super(poSite);
		this.name = poSite?.name ?? "";
	}


	//#endregion

}
