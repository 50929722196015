<div class="body">
	<!--  HEADER   du BODY-->
	<div class="header">
		<!-- TITRE -->
		<div class="contact-profile row-center flex-justify-center">

			<div class="title-visio-button">
				<div [ngClass]="conversation?.title ? 'conv-infos' : 'conv-infos add-title'"
					(click)="routeToConversationEdit()">
					<p>{{ conversation?.title ? conversation.title : "Définissez un titre" }}</p>
				</div>

				<ion-button *ngIf="canCall && !isMailConversation" shape="round" size="small" strong="true"
					[disabled]="isCallButtonDisabled" (click)="onCallAsync()">
					<ion-icon slot="start" name="videocam"></ion-icon>
					<ion-label>Rejoindre la visio</ion-label>
				</ion-button>
			</div>

			<div class="title-icon-linked-entites">
				<div class="title-icontxt">
					<ion-icon slot="icon-only" name="create" class="title-icon txt24px" aria-label="create"
						(click)="routeToConversationEdit()">
					</ion-icon>
				</div>
				<div class="title-icontxt" *ngIf="canSummarize && (observableHasMessageForSummarize.value$ | async)">
					<ion-icon slot="icon-only"
						color="{{(observableUserUsage.value$ | async)?.isQuotaExcedeed ? 'grey' : 'ai-button'}}" name="color-wand"
						(click)="openModalAiSummary()"></ion-icon>
				</div>
				<!-- Partie sur les entités liées. Affichée uniquement s'il y a une entitée liée ou qu'on peut en ajouter. -->
				<div *ngIf="!isMailConversation && ((firstLinkedEntityAndAvatar$ | async) || canAddLink)" class="element-lie">
					<div *ngIf="firstLinkedEntityAndAvatar$ | async as firstLinkedEntityAndAvatar"
						class="client-ava wrap image participant row-center flex-justify-center"
						(click)="goToLinkedEntity(firstLinkedEntityAndAvatar.linkedEntity)">
						<div class="link-ava">
							<ion-icon slot="icon-only" name="link"></ion-icon>
							<avatar class="v-align" [src]="firstLinkedEntityAndAvatar.avatar">
							</avatar>
						</div>
						<p>{{ firstLinkedEntityAndAvatar.linkedEntityName }}</p>
					</div>
					<ion-button [disabled]="!(canEdit$ | async)" *ngIf="canAddLink" fill="clear" (click)="selectEntities()">
						<ion-icon slot="icon-only" name="add-link" class="add txt24px" style="color: white">
						</ion-icon>
					</ion-button>
				</div>
			</div>
		</div>

	</div>


	<div class="frame">

		<!-- PARTICIPANTS -->
		<div class="sidepanel">

			<!-- UTILISATEUR 'MOI' -->
			<div class="profile">
				<div *ngIf="userParticipant" class="wrap image participant row-center flex-justify-center"
					(click)="goToParticipant(userParticipant)">
					<div class="loaderavatar" *ngIf="userParticipant.activity?.activity === 't'"></div>
					<avatar class="v-align" [src]="userParticipant.avatar"
						[ngClass]="userParticipant.activity?.activity === 't' ? 'away avatar-icon' : userParticipant.activity?.activity === 'o' ? 'online avatar-icon' : 'offline avatar-icon'">
					</avatar>
					<p>{{ userParticipant.label }}</p>
				</div>
				<div *ngIf="userParticipant?.activity?.isInVisio && userParticipant?.activity?.activity === 'o'"
					class="visio-icon">
					<ion-icon name="videocam"></ion-icon>
				</div>
			</div>

			<!-- AUTRES UTILISATEURS -->
			<div id="contacts">
				<div class="participants">
					<ul class="marge">
						<li *ngFor="let item of observableOtherParticipants.changes$ | async" class="contact participant">
							<div class="participantsbox">
								<div class="mat-badge-ctn">
									<div matBadge="!" [matBadgeHidden]="item.model?.email || item.model?.userId" matBadgeColor="warn"
										matBadgeSize="large" matBadgePosition="below after" (click)="warningToParticipant(item)">
									</div>
								</div>
								<div class=" loader" *ngIf="item.activity?.activity === 't'">
								</div>
								<div class="wrap image">
									<avatar style="width: inherit; height: inherit;" class="v-align" [src]="item.avatar"
										[ngClass]="item.activity?.activity === 't' ? 'away avatar-icon' : item.activity?.activity === 'o' ? 'online avatar-icon' : 'offline avatar-icon'"
										(click)="goToParticipant(item)">
									</avatar>
									<div class="meta">
									</div>
								</div>
								<div *ngIf="item.activity?.isInVisio && item.activity?.activity === 'o'" class="visio-icon">
									<ion-icon name="videocam"></ion-icon>
								</div>
							</div>
						</li>

					</ul>
				</div>
				<div class="btnAjout">
					<!-- Bouton "Ajouter un participant". -->
					<ion-button [disabled]="!(canEdit$ | async)" class="marge-icon add-participant" fill="clear"
						(click)="updateParticipants()" *ngIf="!isMailConversation">
						<ion-icon slot="icon-only" name="add" class="add txt24px" style="color: #999"></ion-icon>
					</ion-button>
				</div>
			</div>

		</div>

		<!-- CONTENU (TITRE ET MESSAGES ET ZONE DE TEXTE et ENTITES) -->
		<div class="content">

			<!-- MESSAGES -->
			<ion-content [forceOverscroll]="false" class="messages" #messagesContainer>
				<ion-list-header *ngIf="isDownloading" class="synchro-container rotate">
					<ion-note class="synchro-label">Synchronisation des données</ion-note>
					<ion-spinner color="medium"></ion-spinner>
				</ion-list-header>
				<ion-list class="messages-list">
					<ng-container *ngFor="let message of messages;">
						<ng-container *ngIf="findReadIndicators(message._id) as readIndicators">
							<!-- BUBULLES DES MESSAGES LUS -->
							<div *ngIf="readIndicators.length > 0" class="ctn-msg-bubbles rotate"
								[ngClass]="(message.senderContactPath?.indexOf(currentContactId) ?? -1) >= 0 ? 'sent' : 'replies'">
								<ng-container *ngFor="let bubble of readIndicators; let i = index; trackBy trackByIndex;">
									<avatar *ngIf="i <= maxReadIndicatorsLength" [src]="bubble.indicator.avatar" cssClass="round">
									</avatar>
								</ng-container>
								<ion-note *ngIf="readIndicators.length > maxReadIndicatorsLength">+ {{
									readIndicators.length -
									maxReadIndicatorsLength }} {{ readIndicators.length > (maxReadIndicatorsLength + 1)
									? "autres" :
									"autre" }}</ion-note>
							</div>
						</ng-container>
						<!-- CONTENU DES MESSAGES -->
						<div class="message message-content rotate" calaoGestures (longPress)="openMessagePopover($event, message)"
							[ngClass]="(message.senderContactPath?.indexOf(currentContactId) ?? -1) >= 0  ? 'sent' + (isAiSummary(message) ? ' ai-message' : '')
							: 'replies'" lines="none">
							<ng-container *ngIf="!message.deleted; else messageDeleted">
								<div class="text">
									<p linkify redirectLinks *ngIf="!isMailConversation">{{ message.body }}</p>
									<p linkify redirectLinks *ngIf="isMailConversation">{{ transformMailBody(message.body)}}</p>

									<!-- Pièces jointes type résumé par IA -->
									<ng-container *ngIf="getMessageAttachedAiSummary(message) as attachedAISummary">
										<p>{{ attachedAISummary }}</p>
									</ng-container>

									<div class="message-attachments-wrapper">
										<!-- Pièces jointes type fichiers -->
										<ng-container *ngIf="getMessageAttachedFiles(message) as attachedFiles">
											<gallery *ngIf="attachedFiles.length > 0" [files]="attachedFiles" [readOnly]="true"
												[displayMode]="galleryDisplayMode"></gallery>
										</ng-container>

										<!-- Pièces jointes type entités -->
										<ng-container *ngIf="getMessageAttachedEntities$(message) | async as attachedEntities">
											<ng-container *ngIf="attachedEntities.length > 0">
												<div *ngFor="let attachedEntity of attachedEntities" (click)="openEntity(attachedEntity)"
													class="entity-wrapper">
													<ng-container *ngIf="getEntityCategory(attachedEntity) as category">
														<div class="entity-icon">
															<ion-icon slot="icon-only" [name]="category.icon" size="large"></ion-icon>
														</div>
														<div class="entity-labels">
															<label class="attached-entity-category-name">{{category.name}}</label>
															<label class="attached-entity-name">{{getEntityName(attachedEntity)}}</label>
														</div>
													</ng-container>

												</div>
											</ng-container>
										</ng-container>
									</div>
									<span class="messages-date-and-check {{isAiSummary(message) ? ' ai-message' : ''}}">
										<span class="ai-message-information" *ngIf="isAiSummary(message)">Vous seul pouvez
											voir ce message. <a (click)="deleteMessageDefinitely(message)"> Supprimer</a></span>
										<span class="white-space-nowrap just-flex">
											<span>{{ message.createDate | adaptiveDate }}</span>
											<ion-icon class="txt16px icon"
												[name]="message.isSyncToServer == true ? 'checkmark-done' : 'checkmark'"
												[ngClass]="message.isSyncToServer == true ? 'colored-checkmark' : ''">
											</ion-icon>
										</span>
									</span>
								</div>
							</ng-container>
							<ng-template #messageDeleted>
								<div class="text">
									<p>{{ deletedMessageBody }}</p>
									<span class="messages-date-and-check">
										<span>{{ message.createDate | adaptiveDate }}</span>
										<ion-icon class="txt16px icon"
											[name]="message.isSyncToServer == true ? 'checkmark-done' : 'checkmark'">
										</ion-icon>
									</span>
								</div>
							</ng-template>
						</div>
						<ion-note *ngIf="(message.senderContactPath?.indexOf(currentContactId) ?? 0) < 0"
							class="sender-name rotate">
							<ng-container *ngIf="otherParticipantsMap.has(message.senderContactPath); else participantDeleted">
								{{getSenderDisplayName(message)}}
							</ng-container>
							<ng-template #participantDeleted>
								Participant inconnu
							</ng-template>
						</ion-note>
					</ng-container>
				</ion-list>
				<ion-infinite-scroll #infiniteScroll *ngIf="infiniteScrollEnabled && messages.length > 0" threshold="10%"
					position="bottom" (ionInfinite)="loadMoreMessages(infiniteScroll)" class="rotate">
					<ion-infinite-scroll-content loadingText="Chargement des messages...">
					</ion-infinite-scroll-content>
				</ion-infinite-scroll>
			</ion-content>

		</div>
	</div>


	<!-- ZONE D'ENVOI DE MESSAGE -->
	<div class="message-input" *ngIf="!isMailConversation">
		<!-- Loader pour l'envoi d'un message avec pièces jointes. -->
		<osapp-spinner *ngIf="observableIsMessageSending.value$ | async" message="Envoi du message en cours ...">
		</osapp-spinner>

		<!-- Zone de la galerie des pièces jointes si l'utilisateur en ajoute. On la masque pour ne pas avoir la preview des images. -->
		<div [hidden]="true" class="just-flex flex-col">
			<gallery #sendGallery (onFilesChanged)="onFilesChanged($event)" (onDocumentPicked)="onDocumentPicked($event)"
				[filePickerVisible]="false" [files]="files" [command$]="galleryCommand$" [cameraButtonVisible]="false"
				[acceptedFiles]="galleryAcceptFiles" [hidden]="!sendGallery.files || sendGallery.files.length === 0"></gallery>
		</div>

		<!-- Formulaire de soumission du message avec un bouton pour ajouter des pièces jointes. -->
		<form class="wrap" (ngSubmit)="onMessageSend()">
			<div class="button-area">
				<ion-button (click)="openAttachmentsPopover($event)">
					<ion-icon slot="icon-only" name="add"></ion-icon>
				</ion-button>
			</div>
			<div class="message-area">
				<ng-container *ngIf="sendOnReturn; then inputMessage else textAreaMessage"></ng-container>
				<div class="tag-wrapper">
					<ion-button *ngFor="let attachment of newMessage.attachments" shape="round" mode="md" size="small"
						(click)="openPendingAttachmentAsync(attachment)" class="attachment-tag-button">
						<div class="attachment-name">{{ getAttachmentName(attachment) }}</div>
						<div slot="end" (click)="removeAttachment(attachment)">
							<ion-icon name="close"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<ng-template #inputMessage>
				<input [(ngModel)]="newMessage.body" type="text" placeholder="Tapez votre message" name="newMessage"
					(change)="onTapping(newMessage.body)" />
			</ng-template>
			<ng-template #textAreaMessage>
				<textarea [(ngModel)]="newMessage.body" class="message-textarea" placeholder="Tapez votre message"
					(change)="onTapping(newMessage.body)" name="newMessage" rows="2" cols="35"></textarea>
			</ng-template>

			<div class="button-area">
				<!-- focus-me -->
				<ion-button type="submit" class="submit" [disabled]="!newMessage.canSend">
					<ion-icon slot="icon-only" name="send"></ion-icon>
				</ion-button>
			</div>
		</form>
	</div>
</div>