import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { IContact } from '../../../../model/contacts/IContact';
import { ICalendarEvent } from '../../models/icalendar-event';

@Component({
	selector: 'calao-calendar-event',
	templateUrl: './calendar-event.component.html',
	styleUrls: ['./calendar-event.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarEventComponent {

	//#region PROPERTIES

	/** Flux de l'icône de la tâche/évènement. */
	@Input() public eventIcon?: string;
	@ObserveProperty<CalendarEventComponent>({ sourcePropertyKey: "eventIcon" })
	public readonly observableEventIcon = new ObservableProperty<string>();

	/** Flux de l'icône du status de la tâche/évènement. */
	@Input() public statusIcon?: string;
	@ObserveProperty<CalendarEventComponent>({ sourcePropertyKey: "statusIcon" })
	public readonly observableStatusIcon = new ObservableProperty<string>();

	/** Classe css à appliquer pour coloriser l'icône. */
	@Input() public class?: string;
	@ObserveProperty<CalendarEventComponent>({ sourcePropertyKey: "class" })
	public readonly observableClass = new ObservableProperty<string>();

	/** `true` si les particpants doivent être affichés, sinon `false` par défaut. */
	@Input() public displayParticipants?: boolean = false;

	/** Evènement du calendrier. */
	@Input() public event?: ICalendarEvent;
	@ObserveProperty<CalendarEventComponent>({ sourcePropertyKey: "event" })
	public readonly observableEvent = new ObservableProperty<ICalendarEvent>();

	/** Couleur de la police (noir par defaut). */
	@Input() public fontColor?: string;
	@ObserveProperty<CalendarEventComponent>({ sourcePropertyKey: "fontColor" })
	public readonly observableFontColor = new ObservableProperty<string>("#000");

	/** `true` si le texte du libellé est barré, sinon false par défaut. */
	@Input() public isStrike?: boolean;
	@ObserveProperty<CalendarEventComponent>({ sourcePropertyKey: "isStrike" })
	public readonly observableIsStrike = new ObservableProperty<boolean>(false);

	/** IContact du validateur de l'occurrence. */
	@Input() public validator?: IContact;
	@ObserveProperty<CalendarEventComponent>({ sourcePropertyKey: "validator" })
	public readonly observableValidator = new ObservableProperty<IContact>();

	/** Icône à afficher avant le titre. */
	@Input() public titleIcon?: string;
	@ObserveProperty<CalendarEventComponent>({ sourcePropertyKey: "titleIcon" })
	public readonly observableTitleIcon = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor() { }

	//#endregion METHODS

}
