import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, ElementRef, Input, QueryList, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { SwiperContainer } from 'swiper/element';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { TabsTabComponent } from '../tabs-tab/tabs-tab.component';

@Component({
	selector: 'calao-tabs-tab-container',
	templateUrl: './tabs-tab-container.component.html',
	styleUrls: ['./tabs-tab-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsTabContainerComponent extends DestroyableComponentBase implements AfterContentInit {

	//#region FIELDS

	@ContentChildren(TabsTabComponent) private moTabs: QueryList<TabsTabComponent>;
	@ContentChildren("tabTemplate") private moTemplates: QueryList<TemplateRef<HTMLElement>>;

	//#endregion

	//#region PROPERTIES

	/** Indique si on veut naviguer lors du swipe. */
	@Input() public navigateOnSwipe?: boolean;
	@ObserveProperty<TabsTabContainerComponent>({ sourcePropertyKey: "navigateOnSwipe" })
	public readonly observableNavigateOnClick = new ObservableProperty<boolean>();

	public readonly observableActiveTabIndex = new ObservableProperty<number>(0);

	public readonly observableTemplates = new ObservableArray<TemplateRef<HTMLElement>>();

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioRouter: Router,
		private readonly ioRoute: ActivatedRoute,
		private readonly ioElementRef: ElementRef<HTMLElement>
	) {
		super();
	}

	public ngAfterContentInit(): void {
		const loSwiperContainer: SwiperContainer = this.ioElementRef.nativeElement.querySelector('swiper-container');

		loSwiperContainer.addEventListener('swiperslidechange', () => {
			if (this.observableNavigateOnClick.value) {
				this.ioRouter.navigate([], {
					relativeTo: this.ioRoute,
					queryParams: { tab: loSwiperContainer.swiper.activeIndex }
				});
			}
			else
				this.observableActiveTabIndex.value = loSwiperContainer.swiper.activeIndex;
		});

		this.observableTemplates.resetSubscription(this.getTemplates$());

		this.moTabs.changes.pipe(
			startWith({}),
			tap(() => {
				this.moTabs.forEach((poTab: TabsTabComponent, pnIndex: number) => poTab.index = pnIndex);
			}),
			switchMap(() => this.observableActiveTabIndex.value$),
			tap((pnIndex: number) => loSwiperContainer.swiper?.slideTo(pnIndex)),
			secure(this)
		).subscribe();
	}

	private getTemplates$(): Observable<TemplateRef<HTMLElement>[]> {
		return this.moTemplates.changes.pipe(
			startWith({}),
			map(() => this.moTemplates.toArray()),
			secure(this)
		);
	}

	//#endregion

}
