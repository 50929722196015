import { ObjectHelper } from "../../utils/helpers/objectHelper";

export class DatabaseCreationError extends Error {

	constructor(psDatabaseId: string) {
		super(`La base de données "${psDatabaseId}" n'a pas pu être créée !`);
		// Nécessaire pour que le 'instanceof DatabaseCreationError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, DatabaseCreationError);
	}

}