import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { IEntityLinkRelationData } from '@calaosoft/osapp-common/entities/models/ientity-link-relation-data';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { Observable, filter, firstValueFrom, switchMap, tap } from 'rxjs';
import { EntityLinkService } from '../../../../../../services/entityLink.service';
import { EntityPickerModalComponent } from '../../../../../entities/components/entity-picker-modal/entity-picker-modal.component';
import { IEntityDescriptor } from '../../../../../entities/models/ientity-descriptor';
import { EntitiesService } from '../../../../../entities/services/entities.service';
import { ModalService } from '../../../../../modal/services/modal.service';
import { IDeletableTag } from '../../../../../tags/models/ideletable-tag';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineFieldParams } from '../../models/iinline-field-params';
import { IInlineListFieldLayoutParams } from '../inline-list-field-layout/models/iinline-list-field-layout-params';
import { IEntitiesSelectorFieldParams } from './models/ientities-selector-field-params';

@Component({
	selector: 'calao-entities-selector-field',
	templateUrl: './entities-selector-field.component.html',
	styleUrls: ['./entities-selector-field.component.scss'],
})
export class EntitiesSelectorFieldComponent extends FieldBase<void> implements IInlineFieldParams, OnInit {

	//#region PROPERTIES

	public readonly observableParams = new ObservableProperty<IEntitiesSelectorFieldParams>();

	public readonly observableModels = new ObservableArray<Entity>([]);
	public readonly observableTags = new ObservableArray<IDeletableTag>([]);

	public layout: 'inline';
	public hideLabelWhenFilled?: boolean;
	public layoutParams: IInlineListFieldLayoutParams;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcEntities: EntitiesService,
		private readonly isvcModal: ModalService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.initParams();

		this.observableParams.value$.pipe(
			switchMap((poParams: IEntitiesSelectorFieldParams) => this.isvcEntities.getDescriptor$(poParams.entityDescId).pipe(
				switchMap((poDesc: IEntityDescriptor) => this.isvcEntityLink.getLinkedEntities(
					this.isvcEntityLink.currentEntity,
					IdHelper.getPrefixFromId(poDesc.idPattern),
					poParams.linkTypes.target,
					true
				))
			)),
			tap((paLinkedEntities: Entity[]) => this.observableModels.resetArray(paLinkedEntities)),
			secure(this)
		).subscribe();

		this.observableModels.changes$.pipe(
			tap((paEntities: Entity[]) => {
				this.observableTags.resetArray(paEntities.map((poEntity: Entity) => {
					return {
						id: poEntity._id,
						label: this.isvcEntities.getEntityName(poEntity),
						deletable: true
					}
				}))
			}),
			secure(this)
		).subscribe();
	}

	private initParams(): void {
		const loParams: IEntitiesSelectorFieldParams = this.props.data;

		this.layout = loParams.layout;
		this.hideLabelWhenFilled = loParams.hideLabelWhenFilled;
		this.layoutParams = loParams.layoutParams as unknown as IInlineListFieldLayoutParams;

		if (loParams.readOnly === undefined)
			loParams.readOnly = this.to.readonly;

		this.observableParams.value = loParams;
	}

	public onSelectEntityClickedAsync(): Promise<Entity> {
		return firstValueFrom(this.selectEntity$());
	}

	@Queue<
		EntitiesSelectorFieldComponent,
		Parameters<EntitiesSelectorFieldComponent["selectEntity$"]>,
		ReturnType<EntitiesSelectorFieldComponent["selectEntity$"]>
	>({
		excludePendings: true
	})
	private selectEntity$(): Observable<Entity> {
		return this.isvcModal.open<Entity>({
			component: EntityPickerModalComponent,
			componentProps: {
				entityDescId: this.observableParams.value?.entityDescId
			}
		}).pipe(
			filter((poEntity?: Entity) => !!poEntity),
			tap((poEntity: Entity) => {
				if (!this.observableModels.find((poSelectedEntity: Entity) => poEntity._id === poSelectedEntity._id)) {
					const loRelationData: Map<string, IEntityLinkRelationData> | undefined = new Map([
						[poEntity._id, {
							[this.model._id]: this.observableParams.value.linkTypes.current,
							[poEntity._id]: this.observableParams.value.linkTypes.target
						}]
					]);

					this.isvcEntityLink.updateCachedEntityLinks(
						this.model,
						this.observableModels,
						[...this.observableModels, poEntity],
						loRelationData
					);

					this.observableModels.push(poEntity);
				}
			}),
		);
	}

	public removeEntity(psId: string): void {
		const laOldModels = [...this.observableModels]
		ArrayHelper.removeElementById(this.observableModels, psId);

		this.isvcEntityLink.updateCachedEntityLinks(
			this.model,
			laOldModels,
			this.observableModels,
		);
	}

	public getEntitiesName(paEntities: Entity[]): string {
		return paEntities.map((poEntity: Entity) => this.isvcEntities.getEntityName(poEntity)).join(", ");
	}

	//#endregion METHODS

}
