import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { TCRUDPermissions } from '../../permissions/models/tcrud-permissions';

export class DmsPermissionError extends Error {

	constructor(psScope?: string, pePermission?: TCRUDPermissions) {
		super(`DMS permission refused: ${psScope} : ${pePermission}.`);

		// Nécessaire pour que le 'instanceof DmsPermissionError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, DmsPermissionError);

		console.error(`${DmsPermissionError.name}::${this.message}`);
	}

}