import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { EInlineFieldLayoutSeparator } from '../inline-field-layout/models/einline-field-layout-separator';

@Component({
	selector: 'calao-inline-list-field-layout',
	templateUrl: './inline-list-field-layout.component.html',
	styleUrls: ['./inline-list-field-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineListFieldLayoutComponent {

	//#region FIELDS

	/** Evènement lors du clic sur le bouton d'ajout. */
	@Output("onAddClicked") private readonly moAddClickedEvent = new EventEmitter<boolean>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Icône du champs. Par défaut "box". */
	@Input() public icon?: string;
	@ObserveProperty<InlineListFieldLayoutComponent>({ sourcePropertyKey: "icon" })
	public readonly observableIcon = new ObservableProperty<string>("box");

	/** Libellé du bouton d'ajout. */
	@Input() public addButtonLabel: string;
	@ObserveProperty<InlineListFieldLayoutComponent>({ sourcePropertyKey: "addButtonLabel" })
	public readonly observableAddButtonLabel = new ObservableProperty<string>("");

	/** Style de séparateur à appliquer au champs. Par défaut "none". */
	@Input() public separator?: EInlineFieldLayoutSeparator;
	@ObserveProperty<InlineListFieldLayoutComponent>({ sourcePropertyKey: "separator" })
	public readonly observableSeparator = new ObservableProperty<EInlineFieldLayoutSeparator>(EInlineFieldLayoutSeparator.none);

	/** Si `true` ajoute un astérisque à coté du libellé du champs. */
	@Input() public required?: boolean;
	@ObserveProperty<InlineListFieldLayoutComponent>({ sourcePropertyKey: "required" })
	public readonly observableRequired = new ObservableProperty<InlineListFieldLayoutComponent>();

	//#endregion PROPERTIES

	//#region METHODS

	public raiseAddClicked(): void {
		this.moAddClickedEvent.emit(true);
	}

	//#endregion METHODS

}
