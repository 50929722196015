import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGroupSelection } from '../../../../model/contacts/IGroupSelection';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { IContactFilterValues } from '../../models/IContactFilterValues';

@Component({
	selector: 'calao-contacts-filter-bar',
	templateUrl: './contacts-filter-bar.component.html',
	styleUrls: ['./contacts-filter-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsFilterBarComponent extends DestroyableComponentBase {

	//#region FIELDS

	/** Événement lors du changement des filtres. */
	@Output("onContactFilterValuesChange") private readonly moFilterValuesChangeEvent = new EventEmitter<IContactFilterValues>();
	/** Événement lors du changement des filtres temporaires. */
	@Output("onTmpContactFilterValuesChange") private readonly moTmpFilterValuesChangeEvent = new EventEmitter<IContactFilterValues>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Valeur des filtres. */
	@Input() public filterValues?: IContactFilterValues;
	@ObserveProperty<ContactsFilterBarComponent>({ sourcePropertyKey: "filterValues" })
	public readonly observableFilterValues = new ObservableProperty<IContactFilterValues>();

	/** Nombre de résultats temporaires. */
	@Input() public nbTmpResults?: number;
	@ObserveProperty<ContactsFilterBarComponent>({ sourcePropertyKey: "nbTmpResults" })
	public readonly observableNbTmpResults = new ObservableProperty<number>();

	public nbFiltersLabel$: Observable<string | undefined> = this.getNbFiltersLabel$().pipe(secure(this));
	public groupsFiltersLabel$: Observable<string | undefined> = this.getGroupFiltersLabel$().pipe(secure(this));
	/** Mode de sélection. */
	public selectorDisplayMode = ESelectorDisplayMode;

	//#endregion PROPERTIES

	//#region METHODS

	constructor() {
		super();
	}

	public onFilterValuesChange(poFilterValues: IContactFilterValues): void {
		this.moFilterValuesChangeEvent.emit(this.observableFilterValues.value = poFilterValues);
	}

	public onTmpFilterValuesChange(poFilterValues: IContactFilterValues): void {
		this.moTmpFilterValuesChangeEvent.emit(poFilterValues);
	}

	private getGroupFiltersLabel$(): Observable<string | undefined> {
		return this.observableFilterValues.value$.pipe(
			map((poValues?: IContactFilterValues) => {
				const laNames: string[] = [];

				poValues?.groups?.forEach((poGroup: IGroupSelection) => {
					if (poGroup.selected)
						ArrayHelper.binaryInsert(laNames, poGroup.group.name);
				});

				return ArrayHelper.hasElements(laNames) ?
					`${ArrayHelper.getFirstElement(laNames)} ${laNames.length > 1 ? ` +${laNames.length - 1}` : ""}` : undefined;
			})
		);
	}

	private getNbFiltersLabel$(): Observable<string | undefined> {
		return this.observableFilterValues.value$.pipe(
			map((poValues?: IContactFilterValues) => {
				const lnFilterNumber: number = +ArrayHelper.hasElements(poValues?.groups?.filter((poGroup: IGroupSelection) => poGroup.selected));

				return lnFilterNumber > 0 ? `${lnFilterNumber}` : undefined;
			})
		);
	}

	//#endregion METHODS

}
