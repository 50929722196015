import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { CalendarEventsService } from '@calaosoft/osapp/modules/calendar-events/services/calendar-events.service';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';
import { take } from 'rxjs/operators';

@Injectable()
export class CanEditEventOccurenceGuard {

	//#region METHODS

	constructor(private readonly isvcEvent: CalendarEventsService, private readonly isvcUiMessage: UiMessageService) { }

	public async canActivate(poRoute: ActivatedRouteSnapshot): Promise<boolean> {
		const lsEventGuid: string | undefined = poRoute.params.taskGuid || poRoute.params.eventGuid;
		let ldOccurrenceDate: Date | undefined;
		if (!StringHelper.isBlank(poRoute.params.occurrenceDate))
			ldOccurrenceDate = DateHelper.parseReverseDate(poRoute.params.occurrenceDate);

		if (!StringHelper.isBlank(lsEventGuid) && ldOccurrenceDate) {
			const loOccurrence: BaseEventOccurrence | undefined = await this.isvcEvent.getEventOccurrenceAsync(IdHelper.buildId(EPrefix.event, lsEventGuid), ldOccurrenceDate, poRoute.queryParams.rev);
			if (loOccurrence && !(await loOccurrence.canBeEdited$.pipe(take(1)).toPromise())) {
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: "Vous devez être participant ou auteur de la série pour éditer." }));
				return false;
			}
		}

		return true;
	}

	//#endregion

}
