import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { plainToClass } from "@calaosoft/osapp-common/class-transformer";
import { ConfigData } from "@calaosoft/osapp-common/config/models/ConfigData";
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { ModalController } from "@ionic/angular";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { UserUsageQuota } from "../../../modules/forms/models/user-usage-quota";
import { ModalComponentBase } from "../../../modules/modal";
import { AiGenerationService } from "../../../services/ai-generation.service";
import { ConsentService } from "../../../services/consent.service";
import { EConsent } from "../../../services/interfaces/econsent";
import { PlatformService } from "../../../services/platform.service";
import { IAiConversationSummaryModalResponse } from "./iai-conversation-summary-modal-response";

@Component({
	selector: "calao-ai-conversation-summary-modal",
	templateUrl: "./ai-conversation-summary-modal.component.html",
	styleUrls: ["./ai-conversation-summary-modal.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiConversationSummaryModalComponent extends ModalComponentBase<IAiConversationSummaryModalResponse> implements OnInit {
	//#region FIELDS

	private readonly C_LOG_ID = "CONV.AI.C::";

	//#endregion

	//#region PROPERTIES

	public cguUrl = ConfigData.appInfo.CguUrl;


	/** Représente l'usage de l'utilisateur */
	public readonly observableUserUsage = new ObservableProperty<UserUsageQuota | null>()
		.bind(
			this.isvcAiGeneration.getUserUsage()
				.pipe(
					map((paResult: UserUsageQuota) => plainToClass(UserUsageQuota, paResult)),
					catchError((loError: Error) => {
						console.error(this.C_LOG_ID, "Erreur lors de la récupération de l'usage de l'utilisateur", loError);
						return of(null);
					})
				),
			this
		);


	public readonly observableConsent = new ObservableProperty<boolean>(true)
		.bind(
			this.isvcConsents.get$(EConsent.aiConv),
			this
		);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcAiGeneration: AiGenerationService,
		private readonly isvcConsents: ConsentService,
		poModalCtrl: ModalController, psvcPlatform: PlatformService) {
		super(poModalCtrl, psvcPlatform);
		if (!this.cguUrl) {
			console.error(this.C_LOG_ID, "Erreur lors de la récupération de l'url des CGU");
		}
	}

	/** Consent à l'envoi de donnée à des services tiers */
	public consent(): void {
		this.isvcConsents.consentAsync(EConsent.aiConv);
	}

	/** Génère le résumé */
	public summarize(): void {
		this.close({
			summarize: true
		});
	}

	//#endregion
}
