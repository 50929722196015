import { Injectable } from "@angular/core";
import { BatteryStatus, BatteryStatusResponse } from '@awesome-cordova-plugins/battery-status/ngx';
import { ELogActionId } from '@calaosoft/osapp-common/logging/models/ELogActionId';
import { tap } from 'rxjs/operators';
import { LoggerService } from '../../logger/services/logger.service';
import { IBatteryLogData } from "../models/ibattery-log-data";

@Injectable({ providedIn: "root" })
export class BatteryService {

	//#region FIELDS

	private static readonly C_LOG_ID = "BATTERY.S::";

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioBattery: BatteryStatus,
		private readonly isvcLogger: LoggerService
	) {
	}

	/** Émet un log d'action (`calao-low-device-battery`) lorsque la batterie de l'appareil passe à 20%. */
	public logOnLowBattery(): void {
		this.ioBattery.onLow()
			.pipe(
				tap((poResponse: BatteryStatusResponse) => this.isvcLogger.action(BatteryService.C_LOG_ID, "Batterie du téléphone faible.", ELogActionId.lowDeviceBattery, { "battery": { "level": poResponse.level } } as IBatteryLogData)),
			).subscribe();
	}

	//#endregion

}
