import { Injectable } from '@angular/core';
import { ETaskPrefix } from '@calaosoft/osapp-common/background-tasks/models/ETaskPrefix';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { GuidHelper } from "@calaosoft/osapp-common/guid/helpers/guidHelper";
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { ESuffix } from '@calaosoft/osapp-common/utils/models/ESuffix';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { ENetworkFlag } from '../model/application/ENetworkFlag';
import { IApplicationEvent } from '../model/application/IApplicationEvent';
import { INetworkEvent } from '../model/application/INetworkEvent';
import { EBackgroundTaskEventStatus } from '../model/backgroundTask/EBackgroundTaskEventStatus';
import { EBackgroundTaskFlag } from '../model/backgroundTask/EBackgroundTaskFlag';
import { IBackgroundTaskEvent } from '../model/backgroundTask/IBackgroundTaskEvent';
import { IConvSyncTaskParams } from '../model/backgroundTask/taskParams/IConvSyncTaskParams';
import { IDmsTaskParams } from '../model/backgroundTask/taskParams/IDmsTaskParams';
import { IFlushLogTaskParams } from '../model/backgroundTask/taskParams/IFlushLogTaskParams';
import { IHeartbeatTaskParams } from '../model/backgroundTask/taskParams/IHeartbeatTaskParams';
import { IStoreTaskParams } from '../model/backgroundTask/taskParams/IStoreTaskParams';
import { IUpdateTaskParams } from '../model/backgroundTask/taskParams/IUpdateTaskParams';
import { EConfigEventType } from '../model/config/EConfigEventType';
import { EConfigFlag } from '../model/config/EConfigFlag';
import { IConfigEvent } from '../model/config/IConfigEvent';
import { EConversationEvent } from '../model/conversation/EConversationEvent';
import { IConversationEvent } from '../model/conversation/IConversationEvent';
import { IDatabaseDocument } from '../model/databaseDocument/IDatabaseDocument';
import { IDatabaseDocumentEvent } from '../model/databaseDocument/IDatabaseDocumentEvent';
import { IFlag } from '../model/flag/IFlag';
import { IFormEvent } from '../model/forms/IFormEvent';
import { ENotificationEventType } from '../model/notification/ENotificationEventType';
import { ENotificationFlag } from '../model/notification/ENotificationFlag';
import { INotificationEvent } from '../model/notification/INotificationEvent';
import { ESecurityFlag } from '../model/security/ESecurityFlag';
import { ISecurityEvent } from '../model/security/ISecurityEvent';
import { Database } from '../model/store/Database';
import { EStoreEventStatus } from '../model/store/EStoreEventStatus';
import { EStoreEventType } from '../model/store/EStoreEventType';
import { EStoreFlag } from '../model/store/EStoreFlag';
import { IStoreEvent } from '../model/store/IStoreEvent';
import { EDmsAction } from '../modules/dms/model/EDmsAction';
import { EDmsFlag } from '../modules/dms/model/EDmsFlag';
import { IDmsEvent } from '../modules/dms/model/IDmsEvent';
import { LocalDmsService } from '../modules/dms/services/localDms.service';
import { RemoteDmsService } from '../modules/dms/services/remoteDms.service';
import { SyncDmsService } from '../modules/dms/services/syncDms.service';
import { EntitiesService } from '../modules/entities/services/entities.service';
import { LoggerService } from '../modules/logger/services/logger.service';
import { IPermissionEvent } from '../modules/permissions/models/IPermissionEvent';
import { PermissionsService } from '../modules/permissions/services/permissions.service';
import { ApplicationService } from './application.service';
import { BackgroundTaskService } from './backgroundTask.service';
import { TaskDescriptor } from './backgroundTask/TaskDescriptor';
import { ConfigService } from './config.service';
import { ContactsService } from './contacts.service';
import { ConversationService } from './conversation.service';
import { DatabaseDocumentInitializerService } from './databaseDocumentInitializer.service';
import { FlagService } from './flag.service';
import { FormsService } from './forms.service';
import { NetworkService } from './network.service';
import { NotificationService } from './notification.service';
import { SecurityService } from './security.service';
import { Store } from './store.service';
import { UpdateService } from './update.service';
import { WorkspaceService } from './workspace.service';

@Injectable({ providedIn: "root" })
export class OrchestratorService {

	//#region FIELDS

	/** Sujet de l'application permettant de faire transiter les événements de l'application. */
	private moApplicationSubject: Subject<IApplicationEvent> = new Subject();

	//#endregion

	//#region METHODS

	constructor(
		private isvcBackgroundTask: BackgroundTaskService,
		private isvcFlag: FlagService,
		private isvcWorkspace: WorkspaceService,
		private isvcStore: Store,
		private isvcConfig: ConfigService,
		private isvcLocalDms: LocalDmsService,
		private isvcRemoteDms: RemoteDmsService,
		private isvcSyncDms: SyncDmsService,
		private isvcSecurity: SecurityService,
		private isvcConversation: ConversationService,
		private isvcNetwork: NetworkService,
		private isvcForms: FormsService,
		private isvcApplication: ApplicationService,
		private isvcDatabaseDocInit: DatabaseDocumentInitializerService,
		private isvcUpdate: UpdateService,
		private isvcPermissions: PermissionsService,
		private isvcNotification: NotificationService,
		private isvcContact: ContactsService,
		private readonly isvcEntities: EntitiesService
	) {
	}

	public init() {
		this.isvcApplication.setApplicationSubject(this.moApplicationSubject);
		this.initSubscribtions();
		this.addFlushLogTask();
	}

	/** Ajoute les flags à l'événement venant du bts.
	 * @param poEvent Événement à modifier.
	 */
	private addBackgroundTaskFlags(poEvent: IBackgroundTaskEvent): void {
		poEvent.alteredFlags = [];

		if (poEvent.data.taskId === BackgroundTaskService.C_INIT_TASK_ID)
			poEvent.alteredFlags.push({ key: EBackgroundTaskFlag.BackgroundTaskServiceInitialized, value: true });

		switch (poEvent.data.taskStatus) {

			case EBackgroundTaskEventStatus.Running:
				poEvent.alteredFlags.push({ key: EBackgroundTaskFlag.TaskRunning, value: true });
				break;

			case EBackgroundTaskEventStatus.Finished:
				if (!poEvent.data.taskId)
					poEvent.alteredFlags.push({ key: EBackgroundTaskFlag.TaskRunning, value: false });
				poEvent.alteredFlags.push({ key: EBackgroundTaskFlag.TaskFinished, value: true });
				break;

			case EBackgroundTaskEventStatus.Error:
				poEvent.alteredFlags.push({ key: EBackgroundTaskFlag.TaskError, value: true });
				break;
		}
	}

	/** Ajoute les flags aux événements de config.
	 * @param poEvent Événément reçu.
	 */
	private addConfigFlags(poEvent: IConfigEvent): void {
		if (!poEvent.alteredFlags)
			poEvent.alteredFlags = [];

		switch (poEvent.data.configEventType) {

			case EConfigEventType.PlatformReady:
				poEvent.alteredFlags = [{ key: EConfigFlag.PlatformReady, value: true }];
				break;

			case EConfigEventType.ConfigReady:
				poEvent.alteredFlags = [{ key: EConfigFlag.ConfigReady, value: true }];
				break;

			case EConfigEventType.StaticConfigReady:
				poEvent.alteredFlags = [{ key: EConfigFlag.StaticConfigReady, value: true }];
				break;
		}
	}

	/** Ajoute les flags aux événements de notifications.
 * @param poEvent Événément reçu.
 */
	private addNotificationFlags(poEvent: INotificationEvent): void {
		if (!poEvent.alteredFlags)
			poEvent.alteredFlags = [];

		switch (poEvent.data.notificationEventType) {

			case ENotificationEventType.Initialised:
				poEvent.alteredFlags = [{ key: ENotificationFlag.Initialized, value: true }];
				break;
		}
	}

	/** Ajoute les flags aux événements du dms local.
	 * @param poEvent Événement reçu.
	 */
	private addLocalDmsFlags(poEvent: IDmsEvent): void {
		if (poEvent.data.isDmsReady)
			poEvent.alteredFlags = [{ key: EDmsFlag.DmsReady, value: true }];
	}

	/** Ajoute les flags à l'événement.
	 * @param poEvent Événement reçu.
	 */
	private addSecurityFlags(poEvent: ISecurityEvent): void {
		if (!poEvent.alteredFlags)
			poEvent.alteredFlags = [];
		if (typeof poEvent.data.isAuthenticated === "boolean")
			poEvent.alteredFlags.push({ key: ESecurityFlag.authenticated, value: poEvent.data.isAuthenticated });
	}

	/** Ajoute les flags à modifier à l'événement du store.
	 * @param poEvent Événement à modifier.
	 */
	private addStoreFlags(poEvent: IStoreEvent): void {
		poEvent.alteredFlags = [];

		switch (poEvent.data.storeEventType) {

			case EStoreEventType.Init:
				if (!poEvent.data.databaseId && poEvent.data.status === EStoreEventStatus.successed)
					poEvent.alteredFlags.push({ key: EStoreFlag.DBInitialized, value: true });
				else if (!poEvent.data.databaseId && poEvent.data.status === EStoreEventStatus.failed)
					poEvent.alteredFlags.push({ key: EStoreFlag.DBInitialized, value: false });
				break;

			case EStoreEventType.Synchro:
				if (poEvent.data.status === EStoreEventStatus.working)
					poEvent.alteredFlags.push({ key: EStoreFlag.DBIsSyncing, value: true });
				break;

			case EStoreEventType.InitStartup:
				if (!poEvent.data.databaseId && poEvent.data.status === EStoreEventStatus.successed)
					poEvent.alteredFlags.push({ key: EStoreFlag.DBStartupInitialized, value: true });
				else if (!poEvent.data.databaseId && poEvent.data.status === EStoreEventStatus.failed)
					poEvent.alteredFlags.push({ key: EStoreFlag.DBStartupInitialized, value: false });
				break;
		}
	}

	/** Initialise tous les abonnements. */
	private initSubscribtions(): void {
		this.isvcBackgroundTask.subscribe((poEvent: IBackgroundTaskEvent) => this.onBackgroundTaskEvent(poEvent));
		this.isvcStore.subscribe((poEvent: IStoreEvent) => this.onStoreEvent(poEvent));
		this.isvcConfig.subscribe((poEvent: IConfigEvent) => this.onConfigEvent(poEvent));
		this.isvcNotification.subscribe((poEvent: INotificationEvent) => this.onNotificationEvent(poEvent));
		this.isvcLocalDms.subscribe((poEvent: IDmsEvent) => this.onLocalDmsEvent(poEvent));
		this.isvcRemoteDms.subscribe((poEvent: IDmsEvent) => this.onRemoteDmsEvent(poEvent));
		this.isvcSyncDms.subscribe((poEvent: IDmsEvent) => this.onSyncDmsEvent(poEvent));
		this.isvcSecurity.subscribe((poEvent: ISecurityEvent) => this.onSecurityEvent(poEvent));
		this.isvcConversation.subscribe((poEvent: IConversationEvent) => this.onConversationEvent(poEvent));
		this.isvcNetwork.subscribe((poEvent: INetworkEvent) => this.onNetworkEvent(poEvent));
		this.isvcApplication.subscribe((poEvent: IApplicationEvent) => this.onApplicationEvent(poEvent));
		this.isvcForms.getFormEventObservable().subscribe((poEvent: IFormEvent) => this.onFormsEvent(poEvent));
		this.isvcPermissions.permissionsEvent$.subscribe((poEvent: IPermissionEvent) => this.onPermissionEvent(poEvent));
		this.isvcContact.events$.subscribe((poEvent: IApplicationEvent) => this.onContactsEvent(poEvent));
	}

	private onPermissionEvent(poEvent: IPermissionEvent): void {
		this.setFlags(poEvent);
		this.raiseEvent(poEvent);
	}

	private onContactsEvent(poEvent: IApplicationEvent): void {
		this.setFlags(poEvent);
		this.raiseEvent(poEvent);
	}

	/** Ajoute une tache quand l'évenement est levé. */
	private addFlushLogTask(): void {
		const loTaskDesc: TaskDescriptor<IFlushLogTaskParams> = new TaskDescriptor({
			id: `${ETaskPrefix.flushLog}task`,
			name: ETaskPrefix.flushLog,
			taskType: "FlushLogTask",
			intervalRepetition: LoggerService.C_FLUSH_LOG_INTERVAL_MS,
			execAfter: [
				{ key: ENetworkFlag.isOnlineReliable, value: true },
				{ key: EStoreFlag.DBInitialized, value: true }
			]
		});

		this.isvcBackgroundTask.addTask(loTaskDesc);
	}

	/** Lève un événement après l'avoir traité.
	 * @param poEvent Événement traité qu'il faut renvoyer.
	 */
	private raiseEvent(poEvent: IApplicationEvent): void {
		this.moApplicationSubject.next(poEvent);
	}

	/** Nouvel événement provenant du service de l'application.
	 * @param poEvent Événement d'application survenu.
	 */
	private onApplicationEvent(poEvent: IApplicationEvent): void {
		switch (poEvent.type) {

			case EApplicationEventType.databaseDocumentEvent:
				const loDatabaseDocument: IDatabaseDocument = {
					document: (poEvent as IDatabaseDocumentEvent).data.document,
					databaseIds: this.isvcStore.getDatabasesIdsByRole((poEvent as IDatabaseDocumentEvent).data.databasesRole)
				};
				this.isvcDatabaseDocInit.addDocument(loDatabaseDocument);
				break;
		}
	}

	private onFormsEvent(poEvent: IFormEvent): void {
		this.raiseEvent(poEvent);
	}

	/** Méthode appelée quand la connexion réseau change.
	 * @param poEvent Événement reçu.
	 */
	private onNetworkEvent(poEvent: INetworkEvent): void {
		if (!poEvent.alteredFlags)
			poEvent.alteredFlags = [];

		poEvent.alteredFlags.push({ key: ENetworkFlag.isOffline, value: poEvent.data.isOffline });
		poEvent.alteredFlags.push({ key: ENetworkFlag.isOnlineReliable, value: poEvent.data.isOnlineReliable });
		this.setFlags(poEvent);
		this.raiseEvent(poEvent);
	}

	/** Méthode appelée lors de l'arrivée d'un flag de conversation*/
	private onConversationEvent(poEvent: IConversationEvent): void {
		if (poEvent.data.eventType === EConversationEvent.synchro)
			this.synchroConvDb();
	}

	/** Lance la synchro d'une base locale vers une base distante.
	 * @param poEvent Événement qui lance la synchro.
	 */
	private synchroConvDb(): void {
		const loTaskDesc: TaskDescriptor<IConvSyncTaskParams> = new TaskDescriptor({
			id: `${ETaskPrefix.dbSync}conv`,
			name: "synchroConvDb",
			taskType: "ConvSyncTask",
			params: {},
			enableTaskPersistance: true
		});

		this.isvcBackgroundTask.addTask(loTaskDesc);
	}

	/** Appelée lors de l'arrivée d'un événement du bts.
	 * @param poEvent Événement reçu.
	 */
	private onBackgroundTaskEvent(poEvent: IBackgroundTaskEvent): void {
		this.addBackgroundTaskFlags(poEvent);
		this.setFlags(poEvent);
		this.raiseEvent(poEvent);
	}

	/** Appelée lors de l'arrivée d'un événement du config service.
	 * @param poEvent Événement reçu.
	 */
	private onConfigEvent(poEvent: IConfigEvent): void {
		// La plateforme est prête.
		this.addConfigFlags(poEvent);
		this.setFlags(poEvent);
		this.raiseEvent(poEvent);

		switch (poEvent.data.configEventType) {
			case EConfigEventType.StaticConfigReady:
				if (this.isvcUpdate.getPlatformUpdateConfig() && !ConfigData.environment.storeRelease) {
					// Regarde si une mise à jour est disponible.
					const loTaskUpdateDesc: TaskDescriptor<IUpdateTaskParams> = new TaskDescriptor({
						id: "upd_check",
						name: "updateCheck",
						taskType: "UpdateTask",
						intervalRepetition: 1800000,
						intervalMultiplicator: 1
					});

					this.isvcBackgroundTask.addTask(loTaskUpdateDesc);
				}

				// Initialise le composant de notifications.
				const loTaskNotifDesc: TaskDescriptor = new TaskDescriptor({
					id: "notif_init",
					name: "Initialisation du service de notification",
					taskType: "NotifInitTask"
				});

				loTaskNotifDesc.execAfter = [{ key: ESecurityFlag.authenticated, value: true }];

				this.isvcBackgroundTask.addTask(loTaskNotifDesc);
				break;
		}
	}

	/** Appelée lors de l'arrivée d'un événement du notification service.
	 * @param poEvent Événement reçu.
	 */
	private onNotificationEvent(poEvent: INotificationEvent) {
		this.addNotificationFlags(poEvent);
		this.setFlags(poEvent);
		this.raiseEvent(poEvent);

		switch (poEvent.data.notificationEventType) {
			case ENotificationEventType.OnPushClick:
				this.isvcBackgroundTask.addTask(this.isvcNotification.buildRouteToConversationPageTask(poEvent.data.openResult));
				break;
		}
	}

	/** Appelée lors de l'arrivée d'un événement du dms local.
	 * @param poEvent Événement reçu.
	 */
	private onLocalDmsEvent(poEvent: IDmsEvent): void {
		// Le dms local est prêt.
		this.addLocalDmsFlags(poEvent);
		this.setFlags(poEvent);
		this.raiseEvent(poEvent);
	}

	/** Appelée lors de l'arrivée d'un événement du dms distant.
	 * @param poEvent Événement reçu.
	 */
	private onRemoteDmsEvent(poEvent: IDmsEvent): void {
		this.raiseEvent(poEvent);
	}

	/** Lancée lors de la réception d'un événement du security service.
	 * @param poEvent Événement reçu.
	 */
	private onSecurityEvent(poEvent: ISecurityEvent): void {
		this.addSecurityTasks(poEvent);
		this.addSecurityFlags(poEvent);
		this.setFlags(poEvent);
		this.raiseEvent(poEvent);
	}

	/** Ajoute les tâches demandés par le service de sécurité.
	 * @param poEvent Événement du service de sécurité.
	 */
	private addSecurityTasks(poEvent: ISecurityEvent): void {
		if (poEvent.data.startHeartbeat) {
			const loTaskDescriptor: TaskDescriptor<IHeartbeatTaskParams> = new TaskDescriptor({
				id: `${ETaskPrefix.heartbeat}task`,
				name: "heartbeatSession",
				taskType: "HeartbeatTask",
				execAfter: [{ key: ESecurityFlag.authenticated, value: true }],
				replayInterval: ConfigData.authentication.hearbeatIntervalMilliseconds
			});

			this.isvcBackgroundTask.addTask(loTaskDescriptor);
		}
	}

	/** Appelée lors de l'arrivée d'un événement du store.
	 * @param poEvent Événement reçu.
	 */
	private onStoreEvent(poEvent: IStoreEvent): void {
		this.addStoreFlags(poEvent);
		this.setFlags(poEvent);

		switch (poEvent.data.storeEventType) {

			case EStoreEventType.Synchro:
				if (!StringHelper.isBlank(poEvent.data.databaseId) && poEvent.data.status === EStoreEventStatus.required)
					this.addSynchroDbTask(poEvent.data.databaseId);
				else
					this.raiseEvent(poEvent);
				break;

			case EStoreEventType.NeedInfos:
				this.isvcWorkspace.requestWorkspaceSelection(this.isvcEntities.getEntityRole(poEvent.data.document))
					.pipe(
						catchError(poError => {
							poEvent.data.error = poError;
							this.raiseEvent(poEvent);
							return throwError(() => poError);
						}),
						tap((psResult: string) => {
							if (!StringHelper.isBlank(psResult)) // Dans le cas où on veut annuler, le résultat est à `undefined`.
								poEvent.data.databaseId = psResult;
							this.raiseEvent(poEvent);
						})
					)
					.subscribe();
				break;

			case EStoreEventType.Init:
				if (!StringHelper.isBlank(poEvent.data.databaseId) && poEvent.data.status === EStoreEventStatus.needReplication)
					this.addReplicateDatabaseTask(poEvent.data.databaseId, poEvent.data.login, poEvent.data.password);
				else if (!StringHelper.isBlank(poEvent.data.databaseId) && poEvent.data.status === EStoreEventStatus.initToLocalReplication)
					this.addReplicateToLocalDatabaseTask(poEvent.data.databaseId);
				else
					this.raiseEvent(poEvent);
				break;

			default:
				this.raiseEvent(poEvent);
				break;
		}
	}

	/** Appelée lors de l'arrivée d'un événement du dms synchro.
	 * @param poEvent Événement reçu.
	 */
	private onSyncDmsEvent(poEvent: IDmsEvent): void {

		if (poEvent.data && poEvent.data.dmsAction) {
			let loTaskDescriptor: TaskDescriptor<IDmsTaskParams>;

			switch (poEvent.data.dmsAction) {

				case EDmsAction.download:
					loTaskDescriptor = this.createDmsTask(ESuffix.download,
						`download file ${poEvent.data.fileId}`,
						"DownloadDmsFileTask",
						poEvent.data.fileId);
					break;

				case EDmsAction.sync:
					loTaskDescriptor = this.createDmsTask(ESuffix.all_pending,
						"synchroDms",
						"SyncDmsTask");
					break;

				case EDmsAction.upload:
					loTaskDescriptor = this.createDmsTask(ESuffix.upload,
						`upload file ${poEvent.data.fileId}`,
						"UploadDmsFileTask",
						poEvent.data.fileId);
					break;

				case EDmsAction.delete:
					loTaskDescriptor = this.createDmsTask(ESuffix.delete,
						`delete file ${poEvent.data.fileId}`,
						"DeleteDmsFileTask",
						poEvent.data.fileId);
					break;
			}

			this.isvcBackgroundTask.addTask(loTaskDescriptor);
		}
		else
			this.raiseEvent(poEvent);
	}

	private createDmsTask(peTaskSuffix: ESuffix, psTaskName: string, psTaskType: string, psFileId?: string): TaskDescriptor<IDmsTaskParams> {
		const lsFileId: string = StringHelper.isBlank(psFileId) ? "" : psFileId;
		const lsFileGuid: string = GuidHelper.extractGuid(psFileId);

		return new TaskDescriptor<IDmsTaskParams>({
			id: `${ETaskPrefix.dmsSync}${lsFileId}${peTaskSuffix}`,
			name: psTaskName,
			taskType: psTaskType,
			params: { fileGuid: StringHelper.isBlank(lsFileGuid) ? undefined : lsFileGuid }
		});
	}

	/** Permet de modifier les flags qui sont spécifiés dans l'événement.
	 * @param poEvent Événement reçu.
	 */
	private setFlags(poEvent: IApplicationEvent): void {
		if (poEvent.alteredFlags)
			poEvent.alteredFlags.forEach((poFlag: IFlag) => this.isvcFlag.setFlagValue(poFlag.key, poFlag.value));
	}

	/** Lance la synchro d'une base locale vers une base distante.
	 * @param poEvent Événement qui lance la synchro.
	 */
	private addSynchroDbTask(psDbId: string, poOptions?: PouchDB.Replication.ReplicateOptions): void {
		const loTaskDesc: TaskDescriptor<IStoreTaskParams> = new TaskDescriptor({
			id: this.isvcStore.getLocalToServerReplicationTaskId(psDbId),
			name: "synchroDb",
			taskType: "DbSyncTask",
			params: { dbId: psDbId, options: poOptions },
			enableTaskPersistance: true
		});

		if (loTaskDesc.params.dbId)
			this.isvcBackgroundTask.addTask(loTaskDesc);
		else
			console.error(`ORCH.S:: Synchro de la base impossible car l'id est ${psDbId}.`);
	}

	/** Lance la réplication d'une base de données.
	 * @param poEvent Événement qui lance l'initialisation.
	 */
	private addReplicateDatabaseTask(psDbId: string, psLogin?: string, psPassword?: string): void {
		const loTaskDesc: TaskDescriptor<IStoreTaskParams> = new TaskDescriptor({
			id: `${ETaskPrefix.dbInit}${psDbId}`,
			name: "DatabaseReplication",
			taskType: "DatabaseReplicateTask",
			params: { dbId: psDbId, login: psLogin, password: psPassword }
		});

		if (loTaskDesc.params.dbId)
			this.isvcBackgroundTask.addTask(loTaskDesc);
		else
			console.error(`ORCH.S:: Synchro de la base impossible car l'Id est ${psDbId}`);
	}

	/** Lance la réplication serveur vers local d'une base de données.
	 * @param poEvent Événement qui lance l'initialisation.
	 */
	private addReplicateToLocalDatabaseTask(psDbId: string): void {
		const loDatabase: Database = this.isvcStore.getDatabaseById(psDbId);
		const loTaskDesc: TaskDescriptor<IStoreTaskParams> = new TaskDescriptor({
			id: this.isvcStore.getServerToLocalReplicationTaskId(psDbId),
			name: "DatabaseReplicationToLocal",
			taskType: "DbToLocalTask",
			params: { dbId: psDbId },
			intervalRepetition: this.convertIntervalSecondsToMilliseconds(
				loDatabase.syncOptions?.intervalSeconds?.foreground
			) ?? Store.C_OFFLINE_FIRST_TO_LOCAL_INTERVAL,
			backgroundIntervalRepetition: this.convertIntervalSecondsToMilliseconds(
				loDatabase.syncOptions?.intervalSeconds?.background
			)
		});

		if (loTaskDesc.params.dbId)
			this.isvcBackgroundTask.addTask(loTaskDesc);
		else
			console.error(`ORCH.S::Le téléchargement de la base depuis le serveur est impossible car son identifiant n'est pas défini.`);
	}

	private convertIntervalSecondsToMilliseconds(poInterval?: number | number[]): number | number[] | undefined {
		if (poInterval instanceof Array)
			return poInterval.map((pnInterval: number) => DateHelper.secondsToMilliseconds(pnInterval));
		else if (NumberHelper.isValidStrictPositive(poInterval))
			return DateHelper.secondsToMilliseconds(poInterval);
		return undefined;
	}

	//#endregion
}