<calao-dashboard-tiles>
	<ion-button class="left-button" fill="clear" size="big" (click)="moveLeft()"
		[hidden]="observableHideLeftBtn.value$ | async">
		<ion-icon name="chevron-back-outline"></ion-icon>
	</ion-button>

	<drag-scroll #dragScroll [ngClass]="isMobile ? '' : 'web-drag-scroll'"
		(reachesLeftBound)="leftBoundStat($any($event))" (reachesRightBound)="rightBoundStat($any($event))">
		<formly-form drag-scroll-item *ngFor="let tile of (observableTiles.changes$ | async)" [fields]="tile ?? []">
		</formly-form>
	</drag-scroll>

	<ion-button class="right-button" fill="clear" size="big" (click)="moveRight()"
		[hidden]="observableHideRightBtn.value$ | async">
		<ion-icon name="chevron-forward-outline"></ion-icon>
	</ion-button>
</calao-dashboard-tiles>