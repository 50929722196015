import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DestroyableComponentBase } from '../../../../utils/components/destroyable-component-base';
import { ColumnListRowComponent } from '../column-list-row/column-list-row.component';
import { ColumnListComponent } from '../column-list.component';

@Component({
	selector: 'calao-column-list-row-column',
	templateUrl: './column-list-row-column.component.html',
	styleUrls: ['./column-list-row-column.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnListRowColumnComponent<T> extends DestroyableComponentBase {

	//#region PROPERTIES

	/** Taille de la colonne. */
	@Input() public size?: number;
	@ObserveProperty<ColumnListRowColumnComponent<T>>({ sourcePropertyKey: "size" })
	public readonly observableSize = new ObservableProperty<number>();

	/** Classe css. */
	@Input() public cssClass?: string;
	@ObserveProperty<ColumnListRowColumnComponent<T>>({ sourcePropertyKey: "cssClass" })
	public readonly observableCssClass = new ObservableProperty<string>();

	public readonly size$: Observable<number> = this.getCalculatedSize$().pipe(secure(this));

	//#endregion PROPERTIES

	//#region METHODS

	constructor(private readonly ioParent: ColumnListComponent<T>, private readonly ioRow: ColumnListRowComponent<T>) {
		super();
	}

	private getCalculatedSize$(): Observable<number> {
		return combineLatest([
			this.ioRow.observableColumnsNumber.value$,
			this.observableSize.value$,
			this.ioRow.observableDetail.value$
		]).pipe(
			map(([pnColumns, pnSize, pbDetail]: [number, number, boolean]) => {
				if (!pbDetail || pnColumns === 0)
					return pnSize;

				return pnSize - (this.ioParent.detailColumnSize / pnColumns);
			})
		);
	}
	//#endregion METHODS

}
