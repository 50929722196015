import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { ModalComponentBase } from '@calaosoft/osapp/modules/modal';
import { PlatformService } from '@calaosoft/osapp/services/platform.service';
import { ModalController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { C_PREFIX_BUSINESS } from '../../../app/app.constants';
import { Business } from '../../businesses/model/business';
import { BusinessesService } from '../../businesses/services/businesses.service';
import { Sector } from '../sectors/models/sector';
import { SectorsService } from '../sectors/services/sectors.service';
import { ISectorBusinessSelector } from './isector-business-selector-modal';

@Component({
	selector: 'sector-business-selector-modal',
	templateUrl: './sector-business-selector-modal.component.html',
	styleUrls: ['./sector-business-selector-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorBusinessSelectorModalComponent extends ModalComponentBase<string> implements ISectorBusinessSelector {

	//#region PROPERTIES

	/** @implements */
	@Input() public listOfEntities: string[] | undefined;
	@ObserveProperty<SectorBusinessSelectorModalComponent>({ sourcePropertyKey: "listOfEntities" })
	public readonly observableIdsList = new ObservableProperty<string[]>();

	public selectedItem: string = "";

	public frontRow: ObservableProperty<Business[]> = new ObservableProperty([]);

	/** @implements */
	@Input({ required: true }) public title!: string;
	@ObserveProperty<SectorBusinessSelectorModalComponent>({ sourcePropertyKey: "title" })
	public readonly observabletitle = new ObservableProperty<string>();

	/** @implements */
	@Input({ required: true }) public prefix: EPrefix | undefined;
	@ObserveProperty<SectorBusinessSelectorModalComponent>({ sourcePropertyKey: "prefix" })
	public readonly observablePrefix = new ObservableProperty<EPrefix>();

	/** Valeur du bouton radio sélectionné. */
	public selectedRadioValue: string | undefined;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef,
		private readonly isvcBiz: BusinessesService,
		private readonly isvcSectors: SectorsService,
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);

		combineLatest([this.observableIdsList.value$, this.observablePrefix.value$])
			.pipe(
				mergeMap(([laIdsList, lePrefix]: [string[] | undefined, EPrefix | undefined]) => {
					if (lePrefix === C_PREFIX_BUSINESS)
						return this.isvcBiz.getSiteBusinesses$().pipe(
							map((paBizs: Business[]) => paBizs.filter((poBiz: Business) => laIdsList?.includes(poBiz._id)))
						);
					else
						return this.isvcSectors.getSectors$().pipe(
							map((paSectors: Sector[]) => paSectors.filter((poSector: Sector) => laIdsList?.includes(poSector._id))));
				}),
				tap((poDoc: Business[]) => this.frontRow.value = poDoc),
			).subscribe();
	}


	public onSelectionChanged(psId: string): void {
		this.selectedItem = psId;
	}

	public onValidateClicked(): Promise<boolean> {
		return this.ioModalCtrl.dismiss(this.selectedItem);
	}

	public onCancelClicked(): Promise<boolean> {
		return this.ioModalCtrl.dismiss(undefined);
	}


	//#endregion METHODS
}
