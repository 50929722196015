import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Optional, Output, ViewChild } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { switchMap, tap } from 'rxjs';
import { CustomIonInputStringEvent } from '../../../ionic/models/icustom-ion-input-event';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { FilterBarComponent } from '../filter-bar/filter-bar.component';

@Component({
	selector: 'calao-filter-bar-search-input',
	templateUrl: './filter-bar-search-input.component.html',
	styleUrls: ['./filter-bar-search-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarSearchInputComponent extends DestroyableComponentBase implements AfterContentInit, OnDestroy {

	//#region FIELDS

	/** Événement lors du changement de la valeur de la recherche. */
	@Output("onValueChange") private readonly moOnValueChangeEvent = new EventEmitter<string>();
	private static readonly C_VISIBLE = "visible";

	//#endregion

	//#region PROPERTIES

	@ViewChild("input", { read: ElementRef }) public input?: ElementRef<HTMLIonInputElement>;
	/** Champ de recherche. */
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "input" })
	public readonly observableInput = new ObservableProperty<ElementRef<HTMLIonInputElement>>();

	@Input() public placeholder?: string;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "placeholder" })
	public readonly observablePlaceholder = new ObservableProperty<string>();

	@Input() public value?: string | null;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "value" })
	public readonly observableValue = new ObservableProperty<string>();

	/** Indique si on doit afficher le bouton toggle de modale. */
	@Input() public hasModal?: boolean;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "hasModal" })
	public readonly observableHasModal = new ObservableProperty<boolean>();

	/** Doc propriété. */
	@Input() public filterBadgeLabel?: string | null;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "filterBadgeLabel" })
	public readonly observableFilterBadgeLabel = new ObservableProperty<string>();

	/** Temps d'attente pour déclencher l'événement, en ms. */
	@Input() public debounce?: number | null;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "debounce" })
	public readonly observableDebounce = new ObservableProperty<number>(0);

	/** Focus sur le champ de recherche. */
	@Input() public autofocus?: boolean;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "autofocus" })
	public readonly observableAutofocus = new ObservableProperty<boolean>();

	/** Reset du champ de recherche. */
	@Input() public clearInput?: boolean;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "clearInput" })
	public readonly observableClearInput = new ObservableProperty<boolean>(true);

	//#endregion

	//#region METHODS

	constructor(@Optional() private readonly ioFilterBar?: FilterBarComponent) {
		super();
	}

	public ngAfterContentInit(): void {
		if (!ObjectHelper.isDefined(this.observableHasModal.value))
			this.observableHasModal.value = this.ioFilterBar?.hasModal ?? false;

		this.observableInput.value$.pipe(
			switchMap((poInput: ElementRef<HTMLIonInputElement>) => this.focus$(poInput)),
			secure(this),
		).subscribe();
	}

	public onValueChange(poEvent: Event): void {
		const lsValue: string = (poEvent as CustomIonInputStringEvent)?.detail?.value;
		this.moOnValueChangeEvent.emit(StringHelper.isBlank(lsValue) ? undefined : lsValue);
	}

	public presentFilterModal(): void {
		this.ioFilterBar?.presentFilterModal();
	}

	public focus(poInput: ElementRef<HTMLIonInputElement>): void {
		this.focus$(poInput).subscribe();
	}

	private focus$(poInput: ElementRef<HTMLIonInputElement>) {
		return this.observableAutofocus.value$.pipe(
			tap({
				next: (pbAutofocus: boolean) => {
					if (pbAutofocus) {
						poInput.nativeElement.getInputElement().then((poInputElement: HTMLInputElement) =>
							setTimeout(() => poInputElement.focus(), 500) // Correction bug calao-tabs
						);
					}
				}
			})
		);
	}

	//#endregion

}
