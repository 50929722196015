import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { IonDatetime } from '@ionic/angular';
import { BottomSheetComponentBase } from '../../../modules/bottom-sheet/models/BottomSheetComponentBase';
import { PlatformService } from '../../../services/platform.service';
import { ITimePickerBottomSheetParams } from '../models/itime-picker-bottom-sheet-params';

@Component({
	selector: 'calao-mobile-time-picker',
	templateUrl: './mobile-time-picker.component.html',
	styleUrls: ['./mobile-time-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTimePickerComponent extends BottomSheetComponentBase<ITimePickerBottomSheetParams, string> {

	//#region PROPERTIES

	public readonly observableInitialized = new ObservableProperty<boolean | undefined>();

	//#endregion

	//#region METHODS

	constructor(
		poMatBottomSheet: MatBottomSheet,
		psvcPlatform: PlatformService,
		@Inject(MAT_BOTTOM_SHEET_DATA) poParams: ITimePickerBottomSheetParams
	) {
		super(poMatBottomSheet, psvcPlatform, undefined, poParams);

		//! Corrige un bug de valeur par défaut après plusieurs appels.
		setTimeout(() => this.observableInitialized.value = true, 1);
	}

	public async onTimeChangedAsync(poDateTime: IonDatetime): Promise<void> {
		await poDateTime.confirm();
		this.close(poDateTime.value as string);
	}

	//#endregion

}