import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { FieldBase } from '@calaosoft/osapp/model/forms/FieldBase';
import { IFormDefinition } from '@calaosoft/osapp/model/forms/IFormDefinition';
import { FormsService } from '@calaosoft/osapp/services/forms.service';
import { PlatformService } from '@calaosoft/osapp/services/platform.service';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';


//** On ne peux pas utiliser un wrapper, car le drag-scroll et les drag-scroll-item doivent être défini dans le même .html */
@Component({
	selector: 'calao-scroll-field',
	templateUrl: './scroll-field.component.html',
	styleUrls: ["./scroll-field.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollFieldComponent extends FieldBase implements AfterViewInit, OnInit {

	//#region PROPERTIES

	/** Indique si le boutton de défilement vers la gauche doit être caché */
	public observableHideLeftBtn = new ObservableProperty<boolean>(true);
	/** Indique si le boutton de défilement vers la droite doit être caché */
	public observableHideRightBtn = new ObservableProperty<boolean>(true);

	public get isMobile(): boolean {
		return this.isvcPlatform.isMobile;
	}

	public readonly observableTiles = new ObservableArray<IFormDefinition[]>();

	@ViewChild('dragScroll') public dragScroll?: DragScrollComponent;
	@ViewChild('dragScroll', { read: ElementRef }) public dragScrollElement?: ElementRef<HTMLElement>;
	@ViewChildren(DragScrollItemDirective, { read: ElementRef }) public dragScrollDirectives?: QueryList<ElementRef<HTMLElement>>;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPlatform: PlatformService,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		if (this.props.data && ArrayHelper.hasElements(this.props.data.definition)) {
			this.observableTiles.resetArray(this.props.data.definition.map((poField: IFormDefinition) => [poField]));
		}
	}

	public ngAfterViewInit(): void {
		if (this.dragScroll) {
			this.dragScroll.scrollbarHidden = true;
			this.dragScroll.snapOffset = 10;
			if (this.dragScrollElement)
				this.disableTouchPropagation(this.dragScrollElement);
			this.dragScrollDirectives?.forEach((poElementRef: ElementRef<HTMLElement>) => this.disableTouchPropagation(poElementRef));
		}
	}

	public moveLeft(): void {
		for (let lnIndex = 0; lnIndex < 2; ++lnIndex) {
			this.dragScroll?.moveLeft();
		}
	}

	public moveRight(): void {
		for (let lnIndex = 0; lnIndex < 2; ++lnIndex) {
			this.dragScroll?.moveRight();
		}
	}

	public leftBoundStat(reachesLeftBound: boolean): void {
		this.observableHideLeftBtn.value = reachesLeftBound;
	}

	public rightBoundStat(reachesRightBound: boolean): void {
		this.observableHideRightBtn.value = reachesRightBound;
	}

	/** Evite les bug de conflits lors du swipe. */
	public disableTouchPropagation(poElementRef: ElementRef<HTMLElement>): void {
		poElementRef.nativeElement.addEventListener("touchmove", (poEvent: TouchEvent) => poEvent.stopPropagation());
		poElementRef.nativeElement.addEventListener("touchstart", (poEvent: TouchEvent) => poEvent.stopPropagation());
		poElementRef.nativeElement.addEventListener("touchend", (poEvent: TouchEvent) => poEvent.stopPropagation());
	}

	//#endregion
}