import { HttpHeaders } from '@angular/common/http';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { IIndexedObject } from '@calaosoft/osapp-common/utils/models/IIndexedObject';
import { IHttpOptions } from '../../../model/IHttpOptions';
import { OsappApiHelper } from '../../osapp-api/helpers/osapp-api.helper';

export abstract class AuthenticatedRequestOptionBuilder {

	//#region METHODS

	public static buildAuthenticatedRequestOptions(poHeaders?: IIndexedObject): IHttpOptions {
		let lsToken: string | undefined;

		if (ConfigData.authentication)
			lsToken = ConfigData.authentication.token;

		if (!StringHelper.isBlank(lsToken)) {
			return {
				headers: new HttpHeaders({
					appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
					token: lsToken,
					"api-key": ConfigData.environment.API_KEY ?? "",
					...poHeaders
				})
			} as IHttpOptions;

		}
		else
			return this.buildNonAuthenticatedRequestOptions(poHeaders);
	}

	public static buildNonAuthenticatedRequestOptions(poHeaders?: IIndexedObject): IHttpOptions {
		return {
			headers: new HttpHeaders({
				appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
				"api-key": ConfigData.environment.API_KEY ?? "",
				...poHeaders
			}),
			responseType: undefined
		};
	}

	//#endregion

}