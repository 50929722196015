import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { IonItemSliding } from '@ionic/angular';

@Component({
	selector: "calao-sliding-list-item",
	templateUrl: './sliding-list-item.component.html',
	styleUrls: ['./sliding-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlidingListItemComponent {

	//#region FIELDS

	private moActiveItemSliding: IonItemSliding;

	@Input() public contextualSlot?: boolean | string | null;
	@ObserveProperty<SlidingListItemComponent>({
		sourcePropertyKey: "contextualSlot",
		transformer: (poVal: any) => coerceBooleanProperty(poVal)
	})
	public readonly observableContextualSlot = new ObservableProperty<boolean>();

	//#endregion

	//#region METHODS

	constructor() { }

	/** Ferme les options révélées de l'itemSliding. */
	public closeItemSlidingOptions(): void {
		if (this.moActiveItemSliding) {
			this.moActiveItemSliding.close();
			this.moActiveItemSliding = undefined;
		}
	}

	/** Événement levé lors d'un swipe qui permet de fermer les items qui sont ouverts pour qu'un seul ne le soit en même temps.
	 * @param poItemSliding Liste des items pouvant être glissés pour révéler des boutons.
	 */
	public onSwipingEvent(poItemSliding: IonItemSliding): void {
		if (!this.moActiveItemSliding || this.moActiveItemSliding !== poItemSliding) // L'item sélectionné est différent de celui déjà ouvert, on ouvre le nouveau.
			this.moActiveItemSliding = poItemSliding;
		else // L'item cliqué est celui déjà ouvert, on le ferme.
			this.closeItemSlidingOptions();
	}

	//#endregion

}