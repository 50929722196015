import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';

export class NoInternetConnectionError extends Error {

	//#region METHODS

	constructor(psErrorMessage: string = "Pas de connexion internet") {
		super(psErrorMessage);
		// Nécessaire pour que le 'instanceof NoInternetConnectionError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, NoInternetConnectionError);
	}

	//#endregion

}