import { IStoreDocument } from "@calaosoft/osapp-common/store/models/istore-document";
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';

export class ReplicatorError extends Error {

	constructor(
		public readonly error: any,
		public readonly replicatedDocs: ReadonlyArray<IStoreDocument> = [],
		psMessage?: string
	) {
		super(psMessage);
		// Nécessaire pour que le 'instanceof ReplicatorError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, ReplicatorError);
	}

}