import { Component, OnInit } from '@angular/core';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EAutocapitalize } from '../../../../models/EAutocapitalize';
import { FieldBase } from '../../../../models/FieldBase';
import { ITextareaFieldParams } from '../../../../models/inputs/ITextareaFieldParams';
import { IInlineFieldLayoutParams } from '../inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from '../inline-field-layout/models/iinlineField';

@Component({
	selector: 'calao-textarea-field',
	templateUrl: './textareaField.component.html',
	styleUrls: ['./inputs.component.scss']
})
export class TextareaFieldComponent extends FieldBase<string> implements OnInit, IInlineField {

	//#region PROPERTIES

	/** Différents paramètres possibles pour ce composant de formulaire. */
	public params: ITextareaFieldParams & IInlineField;
	public layout: "inline";
	public layoutParams: IInlineFieldLayoutParams;
	public hideLabelWhenFilled: boolean;

	//#endregion

	//#region METHODS

	public override ngOnInit(): void {
		super.ngOnInit();

		this.params = this.to.data;
		this.layout = this.params.layout;
		this.layoutParams = this.params.layoutParams;
		this.hideLabelWhenFilled = this.params.hideLabelWhenFilled;
		this.initProperties();
	}

	private initProperties(): void {
		if (this.params.autoGrow === undefined)
			this.params.autoGrow = true;

		if (StringHelper.isBlank(this.params.autocapitalize))
			this.params.autocapitalize = EAutocapitalize.sentences;
	}

	public onModelChanged(): void {
		this.markAsDirty();
	}

	//#endregion

}