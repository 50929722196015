<ion-card>
	<ion-card-header>
		<ion-card-title class="icon-wrapper">
			<ion-icon [name]="observableIcon.value$ | async" [style.color]="observableIconColor.value$ | async"></ion-icon>
		</ion-card-title>

		<div class="tile-content">
			<ion-card-subtitle>{{observableTitle.value$ | async}}</ion-card-subtitle>
			<ng-content></ng-content>
		</div>
	</ion-card-header>

	<ion-card-content>
		{{observableContent.value$ | async}}
	</ion-card-content>
</ion-card>