import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveArray } from '@calaosoft/osapp-common/observable/decorators/observe-array.decorator';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { ESortOrder } from '@calaosoft/osapp-common/utils/models/ESortOrder';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { BottomSheetService } from '../../../bottom-sheet/services/bottom-sheet.service';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { ESortSelectorSize } from '../../models/esort-selector-size';
import { ISortOption } from '../../models/isort-option';
import { ISortSelectorBottomSheetParams } from '../../models/isort-selector-bottom-sheet-params';
import { ISortSelectorResult } from '../../models/isort-selector-result';
import { SortSelectorBottomSheetComponent } from '../sort-selector-bottom-sheet/sort-selector-bottom-sheet.component';

@Component({
	selector: 'calao-sort-selector',
	templateUrl: './sort-selector.component.html',
	styleUrls: ['./sort-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortSelectorComponent<T = any> extends DestroyableComponentBase {

	//#region FIELDS

	/** Événement lors du changement de valeur du tri. */
	@Output("onSortChanged") private readonly moSortChangedEvent = new EventEmitter<ISortSelectorResult>();

	//#endregion

	//#region PROPERTIES

	/** Options de tri. */
	@Input() public options?: ReadonlyArray<ISortOption<T>>;
	@ObserveArray<SortSelectorComponent>("options")
	private readonly observableOptions = new ObservableArray<ISortOption<T>>();

	/** Valeur du tri. */
	@Input() public by?: T;
	@ObserveProperty<SortSelectorComponent>({ sourcePropertyKey: "by" })
	private readonly observableBy = new ObservableProperty<T>();

	/** Ordre du tri. */
	@Input() public order?: ESortOrder | null;
	@ObserveProperty<SortSelectorComponent>({ sourcePropertyKey: "order" })
	private readonly observableOrder = new ObservableProperty<ESortOrder>(ESortOrder.ascending);

	public readonly label$: Observable<string> = combineLatest([this.observableBy.value$, this.observableOrder.value$]).pipe(
		map(([poBy, peOrder]: [T, ESortOrder]) => `Tri : ${this.observableOptions.find((poOption: ISortOption<T>) => poOption.value === poBy)?.label?.toLowerCase()} - ${this.getSortOrderLabel(peOrder)}`),
		takeUntil(this.destroyed$),
		shareReplay(1)
	);

	/** Taille du composant. */
	@Input() public size?: ESortSelectorSize;
	@ObserveProperty<SortSelectorComponent>({ sourcePropertyKey: "size" })
	public readonly observableSize = new ObservableProperty<ESortSelectorSize>(ESortSelectorSize.default);

	//#endregion

	//#region METHODS

	constructor(private readonly isvcBottomSheet: BottomSheetService) {
		super();
	}

	public onSelectClicked(): void {
		this.select$().pipe(takeUntil(this.destroyed$)).subscribe();
	}

	@Queue<SortSelectorComponent, Parameters<SortSelectorComponent["select$"]>, ReturnType<SortSelectorComponent["select$"]>>({
		excludePendings: true
	})
	private select$(): Observable<ISortSelectorResult | undefined> {
		return this.isvcBottomSheet.open(
			SortSelectorBottomSheetComponent<T>,
			{ data: this.getSortSelectorBottomSheetParams() }
		)
			.pipe(
				tap((poResult?: ISortSelectorResult<T>) => {
					if (poResult)
						this.moSortChangedEvent.emit({ by: this.by = poResult.by, order: this.order = poResult.order });
				})
			);
	}

	private getSortSelectorBottomSheetParams(): ISortSelectorBottomSheetParams<T> {
		return {
			by: this.observableBy.value,
			order: this.observableOrder.value,
			options: this.observableOptions
		};
	}

	private getSortOrderLabel(peOrder: ESortOrder): string {
		switch (peOrder) {
			case ESortOrder.ascending:
				return "croissant";
			case ESortOrder.descending:
				return "décroissant";
			default:
				return "";
		}
	}

	//#endregion

}