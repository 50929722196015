import { Injectable } from '@angular/core';
import { ELogActionId } from '@calaosoft/osapp-common/logging/models/ELogActionId';
import { PerformanceManager } from '@calaosoft/osapp-common/performance/PerformanceManager';
import { StoreHelper } from '@calaosoft/osapp-common/store/helpers/store-helper';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { firstValueFrom } from 'rxjs';
import { Store } from '../../../../services/store.service';
import { LogAction } from '../../../logger/decorators/log-action.decorator';
import { ILogActionHandler } from '../../../logger/models/ILogActionHandler';
import { LogActionHandler } from '../../../logger/models/log-action-handler';
import { LoggerService } from '../../../logger/services/logger.service';
import { StoreBenchmarkParams } from './StoreBenchmarkParams';
import { StoreBenchmarkResult } from './StoreBenchmarkResult';

@Injectable()
export class StoreBenchmarkService implements ILogActionHandler {

	//#region FIELDS

	private static readonly C_LOG_ID = "STOBENCH.S::";

	//#endregion

	//#region PROPERTIES

	public readonly defaultBenchmarkParams: StoreBenchmarkParams = new StoreBenchmarkParams();

	//#endregion

	//#region METHODS

	public constructor(
		private isvcStore: Store,
		public readonly isvcLogger: LoggerService
	) { }

	/** @implements */
	public readonly logSourceId: string = StoreBenchmarkService.C_LOG_ID;
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	@LogAction<Parameters<StoreBenchmarkService["benchmarkDatabaseAsync"] | any>, ReturnType<StoreBenchmarkService["benchmarkDatabaseAsync"]>>({
		actionId: ELogActionId.storeBenchmarkDatabase,
		successMessage: (poResult: StoreBenchmarkResult) => `Benchmark ${poResult?.databaseInfo?.db_name} completed.`,
		errorMessage: (psDatabaseId: string, _: StoreBenchmarkParams) => `Benchmark ${psDatabaseId} failed.`,
		dataBuilder: (_, poResult: StoreBenchmarkResult, psDatabaseId: string, poParams: StoreBenchmarkParams) => { return poResult ?? { databaseId: psDatabaseId, params: poParams }; }
	})
	public async benchmarkDatabaseAsync(psDatabaseId: string, poParams: StoreBenchmarkParams = this.defaultBenchmarkParams): Promise<StoreBenchmarkResult> {
		let lnTotalDurationMs = 0;
		let lnTotalDocs = 0;

		const loDbInfo: PouchDB.Core.DatabaseInfo = await (this.isvcStore.getDatabaseById(psDatabaseId).defaultDatabase as PouchDB.Database).info();
		const lnMaxRandomOffset = await this.getDataSourceDocCount(poParams.viewName, loDbInfo) - poParams.nbDocsPerGet;

		for (let lnIndex = 0; lnIndex < poParams.loops; lnIndex++) {
			const loPerformanceManager = new PerformanceManager();
			const lnOffset = poParams.randomOffset ? this.getRandomOffset(lnMaxRandomOffset) : 0;
			const loStartkey: string | [] = poParams.randomStartKey ? await this.getRandomStartKeyAsync(psDatabaseId, poParams.viewName, lnMaxRandomOffset) : undefined;

			if (ArrayHelper.hasElements(loStartkey))
				loStartkey.pop(); // Retire le dernier élément de la clé d'une vue pour simuler la recherche sur une plage (de liens)

			loPerformanceManager.markStart();

			const loGetResult = await firstValueFrom(this.isvcStore.get({
				databaseId: psDatabaseId,
				viewParams: {
					limit: poParams.nbDocsPerGet, include_docs: poParams.includeDocs
					, skip: lnOffset
					, startkey: loStartkey
				},
				viewName: poParams.viewName
			}));
			const lnDurationMs = loPerformanceManager.markEnd().measure();
			lnTotalDurationMs += lnDurationMs;
			lnTotalDocs += loGetResult.length;

			if (poParams.log)
				console.debug(`${StoreBenchmarkService.C_LOG_ID}Benchmarking ${psDatabaseId}: Loop ${lnIndex} (offset=${lnOffset}, startkey=${loStartkey}): first_result._id=${ArrayHelper.hasElements(loGetResult) ? loGetResult[0]._id : "No result"} (${lnDurationMs} ms).`);
		}

		const loResult = new StoreBenchmarkResult(poParams, loDbInfo);
		loResult.totalDurationMs = lnTotalDurationMs.toFixed(0);
		loResult.totalDocs = lnTotalDocs;
		loResult.averagePerGetDurationMs = (lnTotalDurationMs / poParams.loops).toFixed(2);
		loResult.averagePerDocDurationMs = (lnTotalDurationMs / lnTotalDocs).toFixed(2);

		return loResult;
	}

	private async getDataSourceDocCount(psViewName: string, poDbInfo: PouchDB.Core.DatabaseInfo): Promise<number> {
		if (StringHelper.isBlank(psViewName) || psViewName == "_all_docs")
			return poDbInfo.doc_count;
		else
			return (await firstValueFrom(await this.isvcStore.get({ databaseId: poDbInfo.db_name, viewName: psViewName }))).length;
	}

	private async getRandomStartKeyAsync(psDatabaseId: string, psViewName: string, pnMaxRandomOffset: number): Promise<any> {
		const lnIndex = this.getRandomOffset(pnMaxRandomOffset);
		const loResult = await firstValueFrom(this.isvcStore.getOne({ databaseId: psDatabaseId, viewName: psViewName, viewParams: { skip: lnIndex, limit: 1 } }));

		return psViewName ? StoreHelper.getDocumentCacheData(loResult).key : loResult._id;
	}

	private getRandomOffset(pnMaxRandomOffset: number): number {
		return NumberHelper.getRandomInteger(pnMaxRandomOffset, 0);
	}

	//#endregion

}
