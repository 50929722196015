import { Injectable } from '@angular/core';
import { ELogActionId } from '@calaosoft/osapp-common/logging/models/ELogActionId';
import { PerformanceManager } from '@calaosoft/osapp-common/performance/PerformanceManager';
import { SqlFilesHelper } from '@calaosoft/osapp-common/sqlite/helpers/sql-files.helper';
import { SqlRequestHelper } from '@calaosoft/osapp-common/sqlite/helpers/sql-request-helper';
import { SqlHelper } from '@calaosoft/osapp-common/sqlite/helpers/sql.helper';
import { IBatchedRequest } from '@calaosoft/osapp-common/sqlite/models/ibatched-request';
import { SqlDataSource } from '@calaosoft/osapp-common/sqlite/models/sql-data-source';
import { SqlRequestResult } from '@calaosoft/osapp-common/sqlite/models/sql-request-result';
import { TRequestParam } from '@calaosoft/osapp-common/sqlite/models/trequest-param';
import { TTransactionRequest } from '@calaosoft/osapp-common/sqlite/models/ttransaction-request';
import { AND_REQUEST, EXISTS_REQUEST, INSERT_REQUEST, NOT_REQUEST, SELECT_REQUEST, WHERE_REQUEST } from '@calaosoft/osapp-common/sqlite/sql.constants';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { ESortOrder } from '@calaosoft/osapp-common/utils/models/ESortOrder';
import PCancelable from 'p-cancelable';
import { ReplaySubject, firstValueFrom } from 'rxjs';
import { LoggerService } from '../../../logger/services/logger.service';
import { ICountAllResponse } from '../../../sqlite/models/icount-all-response';
import { LocalDatabaseProviderService } from '../../../sqlite/services/providers/local-database-provider.service';
import { SqlService } from '../../../sqlite/services/sql.service';
import { ETrackingStatus } from '../models/etracking-status.enum';
import { IChangeTrackerItem } from '../models/ichange-tracker-item';
import { ICreateChangeTrackerItem } from '../models/icreate-change-tracker-item';
import { ILot } from '../models/ilot';
import { ChangeTrackingService } from './change-tracking.service';

@Injectable()
export class MobileChangeTrackingService extends ChangeTrackingService {

	//#region FIELDS

	private static readonly C_DEFAULT_DATABASE_VERSION = 1;
	private static readonly C_CHANGE_TRACKER_ITEMS_KEYS: (keyof IChangeTrackerItem)[] = ["id", "rev", "lotId"];
	private static readonly C_LOT_ITEMS_KEYS: (keyof ILot)[] = ["id", "since"];
	private static readonly C_LOG_ID = "MOBILECHANGETRACK.S::";

	private readonly moTrackerDataSourceSubjectsByDatabaseId = new Map<string, ReplaySubject<SqlDataSource>>();

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcSql: SqlService,
		private readonly isvcAndroidProvider: LocalDatabaseProviderService,
		private readonly isvcLogger: LoggerService
	) {
		super();
	}

	public override async trackMultipleAsync(psDatabaseId: string, paChangeTrackerItems: ICreateChangeTrackerItem[])
		: Promise<void> {

		try {
			console.debug(`${MobileChangeTrackingService.C_LOG_ID}Tracking documents for '${psDatabaseId}'.`, paChangeTrackerItems);

			const loPerformanceManager = new PerformanceManager().markStart();
			const loDataSource: SqlDataSource | undefined = await this.getSqlDataSourceAsync(psDatabaseId);
			const lsOnConflitRequest: string = SqlRequestHelper.getOnConflictUpdateRequest<IChangeTrackerItem>(
				["id", "lotId"],
				["rev"]
			);
			const laInsertRequests: IBatchedRequest<ICreateChangeTrackerItem>[] = SqlRequestHelper.getInsertRequest<ICreateChangeTrackerItem>(
				MobileChangeTrackingService.C_CHANGES_TABLE_NAME,
				MobileChangeTrackingService.C_CHANGE_TRACKER_ITEMS_KEYS,
				paChangeTrackerItems
			);
			const loLastLot: ILot | undefined = await this.getLastLotWithDataSourceAsync(loDataSource, psDatabaseId);
			const laRequests: string[] = [];
			const laBatchedValues: TRequestParam[][] = [];

			laInsertRequests.forEach((poInsertRequest: IBatchedRequest<ICreateChangeTrackerItem>) => { // Pour chaque requête d'insert, on vient préparer les valeurs.
				laRequests.push(`${poInsertRequest.request} ${lsOnConflitRequest}`);
				laBatchedValues.push(
					this.prepareChangeTrackerItems(
						poInsertRequest.values,
						loLastLot?.id
					)
				);
			});
			await this.isvcSql.runTransactionAsync(
				loDataSource,
				laRequests,
				laBatchedValues,
				loDataSource?.databaseId
			);
			await this.sendTrackingStatusAsync(psDatabaseId, ETrackingStatus.tracked);

			console.debug(`${MobileChangeTrackingService.C_LOG_ID}Documents tracked for '${psDatabaseId}' \
				in : ${loPerformanceManager.markEnd().measure()}ms.`);
		}
		catch (poError) {
			console.error(`${MobileChangeTrackingService.C_LOG_ID}Error while tracking document for '${psDatabaseId}'.`, poError);
			await this.sendTrackingStatusAsync(psDatabaseId, ETrackingStatus.error);
			throw poError;
		}
	}

	private prepareChangeTrackerItems(paChangeTrackerItems: ICreateChangeTrackerItem[], pnLotId?: number): TRequestParam[] {
		const lnLotId: number = pnLotId ?? MobileChangeTrackingService.C_START_LOT_ID;

		return paChangeTrackerItems.map((poChangeTrackerItem: ICreateChangeTrackerItem) => ([
			poChangeTrackerItem.id,
			poChangeTrackerItem.rev,
			lnLotId
		]))
			.flat();
	}

	public override getTrackedAsync(psDatabaseId: string, poToLot?: ILot): PCancelable<IChangeTrackerItem[]> {
		const loPerformanceManager = new PerformanceManager().markStart();

		return new PCancelable<IChangeTrackerItem[]>(async (pfResolve, pfReject) => {
			try {
				const loDataSource: SqlDataSource | undefined = await this.getSqlDataSourceAsync(psDatabaseId);
				const lsRequest: string = this.getTrackedRequest();
				const loResult: SqlRequestResult<IChangeTrackerItem> = await this.isvcSql.requestAsync<IChangeTrackerItem>(
					loDataSource,
					lsRequest,
					[poToLot?.id ?? MobileChangeTrackingService.C_START_LOT_ID],
					loDataSource?.databaseId
				);
				console.debug(`${MobileChangeTrackingService.C_LOG_ID}Get tracked for '${psDatabaseId}' \
					in : ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve(loResult.results);
			}
			catch (poError) {
				pfReject(poError);
			}
		});
	}

	private getTrackedRequest(): string {
		return `${SqlRequestHelper.selectAllFromTableRequest(MobileChangeTrackingService.C_CHANGES_TABLE_NAME)} \
WHERE lotId <= ?`;
	}

	public override dropTrackedAsync(psDatabaseId: string, pnLotId: number, paDocIds: string[]): PCancelable<void> {
		return new PCancelable(async (pfResolve, pfReject) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			try {
				const loDataSource: SqlDataSource | undefined = await this.getSqlDataSourceAsync(psDatabaseId);
				const laRequests: TTransactionRequest[] = [this.getDeleteTrackedRequest(paDocIds)];

				if (pnLotId > 1) // Le lot 0 n'existe pas et on n'a potentiellement pas terminé de synchronisé le lot 1
					laRequests.push(this.getDeleteLotsRequest());

				await this.isvcSql.runTransactionAsync(
					loDataSource,
					laRequests,
					[
						[...paDocIds, pnLotId],
						[pnLotId - 1] // On ne veux pas supprimer le lot en cours de synchro
					],
					loDataSource?.databaseId
				);

				await this.sendTrackingStatusAsync(
					psDatabaseId,
					await this.hasTrackedAsync(psDatabaseId) ? ETrackingStatus.tracked : ETrackingStatus.none
				);
				console.debug(`${MobileChangeTrackingService.C_LOG_ID}Drop tracked for '${psDatabaseId}' \
					in : ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve();
			}
			catch (poError) {
				await this.sendTrackingStatusAsync(psDatabaseId, ETrackingStatus.error);
				pfReject(poError);
			}
		});
	}

	private getDeleteLotsRequest(): string {
		return `${SqlRequestHelper.getDeleteRequestLessOrEqual<ILot>(MobileChangeTrackingService.C_LOTS_TABLE_NAME, "id")}`;
	}

	private getDeleteTrackedRequest(paDocIds: string[]): string {
		return `${SqlRequestHelper.getDeleteRequestByIds(MobileChangeTrackingService.C_CHANGES_TABLE_NAME, paDocIds)} \
${AND_REQUEST} lotId <= ?`;
	}

	public override getAndUpdateLastLotAsync(psDatabaseId: string, pnSince: number): PCancelable<ILot[]> {
		return new PCancelable(async (pfResolve, pfReject) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			try {
				const loDataSource: SqlDataSource | undefined = await this.getSqlDataSourceAsync(psDatabaseId);
				const laRequests: TTransactionRequest[] = [
					this.getGetAllLotsRequest(),
					([poLotsResult]: [SqlRequestResult<ILot>]) => this.getCreateNewLotRequest(poLotsResult, pnSince)
				];

				const laResults: SqlRequestResult<any>[] = await this.isvcSql.runTransactionAsync(
					loDataSource,
					laRequests,
					undefined,
					loDataSource?.databaseId
				);
				console.debug(`${MobileChangeTrackingService.C_LOG_ID}Get and update last lot for '${psDatabaseId}' \
					in : ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve(ArrayHelper.getFirstElement(laResults)?.results ?? []);
			}
			catch (poError) {
				pfReject(poError);
			}
		});
	}

	private getCreateNewLotRequest(poLotsResult: SqlRequestResult<ILot>, pnSince: number): string {
		const lnLotId: number = (ArrayHelper.getLastElement(poLotsResult.results)?.id ??
			MobileChangeTrackingService.C_START_LOT_ID) + 1;
		// Exemple de requête: INSERT INTO lots (id,since) SELECT 505,420 WHERE NOT EXISTS (SELECT * FROM lots WHERE id = 504 AND since = 420)
		// Ici l'objectif est d'insérer uniquement si le lot précédent n'a pas le même since, sinon on ne crée pas de nouveau lot.
		return `${INSERT_REQUEST} ${MobileChangeTrackingService.C_LOTS_TABLE_NAME} \
(${MobileChangeTrackingService.C_LOT_ITEMS_KEYS.map((psKey: keyof ILot) => SqlHelper.sanitize(psKey.toString())).join()}) \
${SELECT_REQUEST} ${SqlHelper.sanitize(lnLotId.toString())},${SqlHelper.sanitize(pnSince.toString())} \
${WHERE_REQUEST} ${NOT_REQUEST} ${EXISTS_REQUEST} \
(${SqlRequestHelper.selectAllFromTableRequest(MobileChangeTrackingService.C_LOTS_TABLE_NAME)} \
${WHERE_REQUEST} id = ${SqlHelper.sanitize((lnLotId - 1).toString())} ${AND_REQUEST} \
since = ${SqlHelper.sanitize(pnSince.toString())})`;
	}

	private getGetAllLotsRequest(): string {
		return `${SqlRequestHelper.selectAllFromTableRequest(MobileChangeTrackingService.C_LOTS_TABLE_NAME)} \
${SqlRequestHelper.getOrderByRequest("id", ESortOrder.ascending)}`;
	}

	private async getLastLotWithDataSourceAsync(poDataSource?: SqlDataSource, psDatabaseId?: string): Promise<ILot | undefined> {
		const loPerformanceManager = new PerformanceManager().markStart();
		const loResult: SqlRequestResult<ILot> =
			await this.isvcSql.requestAsync<ILot>(poDataSource, this.getLastLotRequest(), [], poDataSource?.databaseId);

		console.debug(`${MobileChangeTrackingService.C_LOG_ID}Get last lot for '${psDatabaseId}' \
				in : ${loPerformanceManager.markEnd().measure()}ms.`);

		return ArrayHelper.getFirstElement(loResult.results);
	}

	private getLastLotRequest(): string {
		return `${SqlRequestHelper.selectAllFromTableRequest(MobileChangeTrackingService.C_LOTS_TABLE_NAME)} \
${SqlRequestHelper.getOrderByRequest("id", ESortOrder.descending)} ${SqlRequestHelper.limitRequest(1)}`;
	}

	protected override async hasTrackedAsync(psDatabaseId: string): Promise<boolean> {
		const loPerformanceManager = new PerformanceManager().markStart();
		const loDataSource: SqlDataSource | undefined = await this.getSqlDataSourceAsync(psDatabaseId);
		const lsRequest: string = this.getFirstTrackedRequest();
		const loResult: SqlRequestResult<IChangeTrackerItem> =
			await this.isvcSql.requestAsync<IChangeTrackerItem>(loDataSource, lsRequest, [], loDataSource?.databaseId);

		console.debug(`${MobileChangeTrackingService.C_LOG_ID}Check hasTracked for '${psDatabaseId}' \
				in : ${loPerformanceManager.markEnd().measure()}ms.`);

		return loResult.hasResults();
	}

	private getFirstTrackedRequest(): string {
		return `${SqlRequestHelper.selectAllFromTableRequest(MobileChangeTrackingService.C_CHANGES_TABLE_NAME)} \
${SqlRequestHelper.limitRequest(1)}`;
	}

	private async getSqlDataSourceAsync(psDatabaseId: string): Promise<SqlDataSource | undefined> {
		let loDataSourceSubject: ReplaySubject<SqlDataSource> | undefined =
			this.moTrackerDataSourceSubjectsByDatabaseId.get(psDatabaseId);

		let loDataSource: SqlDataSource | undefined;
		if (!loDataSourceSubject) {
			this.moTrackerDataSourceSubjectsByDatabaseId.set(psDatabaseId, loDataSourceSubject = new ReplaySubject);
			const lsTrackerDatabaseId: string = this.getTrackerDatabaseId(psDatabaseId);
			loDataSource = await this.openDatabase(lsTrackerDatabaseId);
			await this.createTablesAsync(loDataSource);
			loDataSourceSubject.next(loDataSource);
		}

		if (!loDataSource)
			loDataSource = await firstValueFrom(loDataSourceSubject.asObservable());

		return loDataSource;
	}

	public async openDatabase(psTrackerDatabaseId: string): Promise<SqlDataSource> {
		const loDataSource = new SqlDataSource(
			psTrackerDatabaseId,
			MobileChangeTrackingService.C_DEFAULT_DATABASE_VERSION,
			SqlFilesHelper.getFileName(psTrackerDatabaseId, MobileChangeTrackingService.C_DEFAULT_DATABASE_VERSION),
			true
		);

		await this.isvcSql.openDatabaseAsync(loDataSource);
		return loDataSource;
	}

	private async createTablesAsync(poDataSource: SqlDataSource): Promise<void> {
		const laRequests: string[] = [
			SqlRequestHelper.getCreateTableRequest<IChangeTrackerItem>(
				MobileChangeTrackingService.C_CHANGES_TABLE_NAME,
				["lotId", "id"],
				[{ key: "id", value: "TEXT" }, { key: "rev", value: "TEXT" }, { key: "lotId", value: "NUMBER" }]
			),
			SqlRequestHelper.getCreateTableRequest<ILot>(
				MobileChangeTrackingService.C_LOTS_TABLE_NAME,
				["id"],
				[{ key: "id", value: "NUMBER" }, { key: "since", value: "NUMBER" }]
			)
		];

		await this.isvcSql.runTransactionAsync(poDataSource, laRequests, [], poDataSource.databaseId);
	}

	public override getLastLotAsync(psDatabaseId: string): PCancelable<ILot | undefined> {
		return new PCancelable(async (pfResolve, pfReject) => {
			try {
				const loDataSource: SqlDataSource | undefined = await this.getSqlDataSourceAsync(psDatabaseId);

				pfResolve(await this.getLastLotWithDataSourceAsync(loDataSource, psDatabaseId));
			}
			catch (poError) {
				pfReject(poError);
			}
		});
	}

	public override getTrackerDatabaseId(psDatabaseId: string): string {
		return super.getTrackerDatabaseId(psDatabaseId).replace(/_/g, "-");
	}

	public override async countTrackedAsync(psDatabaseId: string): Promise<number> {
		const loDataSource: SqlDataSource | undefined = await this.getSqlDataSourceAsync(psDatabaseId);
		const loSqlResult: SqlRequestResult<ICountAllResponse> = await this.isvcSql.requestAsync<ICountAllResponse>(
			loDataSource,
			SqlRequestHelper.getCountRequest(MobileChangeTrackingService.C_CHANGES_TABLE_NAME),
			[],
			loDataSource?.databaseId
		);

		return ArrayHelper.getFirstElement(loSqlResult.results)?.count ?? 0;
	}

	public override getLotsAsync(psDatabaseId: string): PCancelable<ILot[]> {
		return new PCancelable(async (pfResolve, pfReject) => {
			try {
				const loDataSource: SqlDataSource | undefined = await this.getSqlDataSourceAsync(psDatabaseId);
				const lsRequest: string = this.getGetAllLotsRequest();
				const loResponse: SqlRequestResult<ILot> = await this.isvcSql.requestAsync<ILot>(loDataSource, lsRequest, [], loDataSource?.databaseId);

				pfResolve(loResponse.results);
			}
			catch (poError) {
				pfReject(poError);
			}
		});
	}

	public override async removeAsync(psDatabaseId: string): Promise<void> {
		const lsTrackerDatabaseId: string = this.getTrackerDatabaseId(psDatabaseId);
		// Version "1" en dur car format "databaseId-version" et il n'y a qu'une version de base de données changeTracking.
		const lbDatabaseExists: boolean = await this.isvcAndroidProvider.databaseExistsAsync(
			MobileChangeTrackingService.C_DEFAULT_DATABASE_VERSION.toString(),
			lsTrackerDatabaseId
		);

		if (!lbDatabaseExists) {
			console.debug(`${MobileChangeTrackingService.C_LOG_ID}Tracked database '${lsTrackerDatabaseId}' not exists, can not be removed`);
			return;
		}
		try {
			await this.isvcSql.removeDatabaseAsync(lsTrackerDatabaseId);

			this.logRemoveDatabase(
				`Change tracking database deleted for '${psDatabaseId}'`,
				ELogActionId.sqliteChangeTrackingRemoveSuccess
			);
		}
		catch (poError) {
			this.logRemoveDatabase(
				`Error while deleting change tracking database for '${psDatabaseId}'`,
				ELogActionId.sqliteChangeTrackingRemoveFailed,
				poError
			);

			throw poError;
		}
	}

	private logRemoveDatabase(psMessage: string, peLogActionId: ELogActionId, poError?: any): void {
		this.isvcLogger.action(
			MobileChangeTrackingService.C_LOG_ID,
			psMessage,
			peLogActionId,
			undefined,
			poError
		);
	}

	//#endregion METHODS

}