<ng-container *ngIf="obsProps.value$ | async as observedProps">
	<div class="main-container flex-row">
		<div class="progress-container">
			<div class="progress-circle" [style]="getProgressCircleStyle()">
				<div class="progress-circle-text">
					<span>{{obsCompletionPercentage.value$ | async}}%</span>
				</div>
			</div>
		</div>
		<div class="flex-col">
			<ion-label class="title">{{observedProps.title}}</ion-label>
		</div>
	</div>
</ng-container>