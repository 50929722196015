import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { secure } from '@calaosoft/osapp-common/rxjs/operators/secure';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { InAppBrowser, UrlEvent } from '@capgo/inappbrowser';
import { Observable, Subject, catchError, map, mergeMap, of } from 'rxjs';
import { UserHelper } from '../helpers/user.helper';
import { AuthenticatedRequestOptionBuilder } from '../modules/api/models/authenticated-request-option-builder';
import { DestroyableServiceBase } from '../modules/services/models/destroyable-service-base';
import { ISubscriptionsInfo } from './interfaces/isubscriptions-info';
import { PlatformService } from './platform.service';
import { WorkspaceService } from './workspace.service';

interface InteropCache {
	[key: number]: InteropInfo;
}

interface InteropInfo {
	scopes: string[];
	systemId: string;
	redirectUri: string;
}

interface AuthInfo {
	mailbox?: {
		scopes: string[];
	};
	authorization: {
		url: string;
		scopes: string[];
	};
}

@Injectable({ providedIn: "root" })
export class InteropService extends DestroyableServiceBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "INTER.S::";

	private moCache: InteropCache = {};

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcWorkspace: WorkspaceService,
		private readonly ioHttpClient: HttpClient,
		private readonly isvcPlatform: PlatformService
	) {
		super();
	}

	private addCacheNewAuth(paScopes: string[], psActualUrl: string, systemId: string): number {
		const lnDate = new Date().getTime();
		this.moCache[lnDate] = {
			scopes: paScopes,
			systemId: systemId,
			redirectUri: psActualUrl
		};
		return lnDate;
	}

	private getRedirectUrl(): string {
		if (this.isvcPlatform.isMobileApp) {
			return "http://localhost";
		}
		return window.location.origin;
	}

	//todo : Migrer le code dans le BrowserService.
	private openWebViewForAuth$(psUrl: string, psRedirectUrl: string, paSystemTypes: string[]): Subject<boolean> {
		const loSubject = new Subject<boolean>();
		InAppBrowser.openWebView({ url: psUrl, title: "Authentification externe" });
		InAppBrowser.removeAllListeners();

		InAppBrowser.addListener("urlChangeEvent", (poEvent: UrlEvent) => {
			if (poEvent.url.startsWith(psRedirectUrl)) {
				InAppBrowser.removeAllListeners();
				InAppBrowser.close();

				const loUrlSearchParams: URLSearchParams = new URL(poEvent.url).searchParams;
				const lsCode: string | null = loUrlSearchParams.get("code");
				const lsState: string | null = loUrlSearchParams.get("state");

				if (!lsCode || !lsState) {
					console.error(`${InteropService.C_LOG_ID} Can not get 'state' or 'code' in webview's return authorization with url '${poEvent.url}'.`);
					loSubject.next(false);
					loSubject.complete();
				}
				else
					this.createAuth$(lsCode, lsState, paSystemTypes).subscribe(loSubject);
			}
		});

		InAppBrowser.addListener("closeEvent", () => {
			InAppBrowser.removeAllListeners();
			loSubject.next(false);
			loSubject.complete();
		});

		return loSubject;
	}

	private openNavPopupForAuth$(psUrl: string, psRedirectUrl: string, paSystemTypes: string[]): Observable<boolean> {
		const loSubject = new Subject<boolean>();
		const loPopup: Window = window.open(psUrl, "popup", "popup,width=700,height=800");
		loPopup.focus();

		// On met un setInterval car il n'est pas possible d'écouter les Events d'une pop up.
		const pnIntervalId: number = window.setInterval(() => {
			if (!loPopup || loPopup.closed) {
				console.warn(`${InteropService.C_LOG_ID} Impossible d'ouvrir la popup d'authentification`);
				clearInterval(pnIntervalId);
				loSubject.next(false);
				loSubject.complete();
			} else {
				try {
					if (loPopup.location.href.startsWith(psRedirectUrl)) {
						const lsCode = new URL(loPopup.location.href).searchParams.get('code');
						const lsState = new URL(loPopup.location.href).searchParams.get('state');
						if (!lsCode || !lsState) {
							console.error(`${InteropService.C_LOG_ID} impossible de récupérer le state ou le code dans le retour de la popup d'autorisation. ${loPopup.location.href} `);
							loSubject.next(false);
							loSubject.complete();
						} else {
							this.createAuth$(lsCode, lsState, paSystemTypes).subscribe(loSubject);
						}
						clearInterval(pnIntervalId);
						loPopup.close();
						window.focus();
					}
				} catch (poError) {
					if (poError instanceof DOMException) {/* On ne peut pas récupérer le href de la popup car l'authentification n'est pas terminée.*/ }
					else {
						console.warn(`${InteropService.C_LOG_ID} Problème dans la popup d'authentification ${psUrl}`, poError)
						clearInterval(pnIntervalId);
						loPopup.close();
						loSubject.next(false);
						loSubject.complete();
					}
				}
			}
		}, 500);

		return loSubject.asObservable();
	}

	public hasSubscribe$(psSystem: string, psSystemType: string): Observable<boolean> {
		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}security/users/${UserHelper.getUserGuid(UserHelper.getUserId())}/systems/${psSystem}/subscriptions?workspaceId=${this.isvcWorkspace.getCurrentWorkspaceId()}`;
		return this.ioHttpClient.get<ISubscriptionsInfo>(lsApiUrl, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).pipe(
			map((poSubscriptionsInfo: ISubscriptionsInfo) => ArrayHelper.hasElements(poSubscriptionsInfo[psSystemType]?.ids))
		);
	}

	public getSubscribeInfo$(psSystem: string): Observable<ISubscriptionsInfo> {
		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}security/users/${UserHelper.getUserGuid(UserHelper.getUserId())}/systems/${psSystem}/subscriptions?workspaceId=${this.isvcWorkspace.getCurrentWorkspaceId()}`;
		return this.ioHttpClient.get<ISubscriptionsInfo>(lsApiUrl, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions());
	}

	public getAuthInfo$(psSystem: string): Observable<AuthInfo> {
		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}security/users/${UserHelper.getUserGuid(UserHelper.getUserId())}/systems/${psSystem}/authorizations?workspaceId=${this.isvcWorkspace.getCurrentWorkspaceId()}`;
		return this.ioHttpClient.get<AuthInfo>(lsApiUrl, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions());
	}

	public demandAuth$(psSystem: string, paSystemTypes: string[]): Observable<boolean> {
		return this.getAuthInfo$(psSystem).pipe(
			catchError((poError: Error) => {
				console.error(`${InteropService.C_LOG_ID} DemandAuth à échoué.`, poError);
				return of(false);
			}),
			mergeMap((poAuthInfo: AuthInfo) => {
				let laScopesNeeded: string[] = [];

				paSystemTypes.forEach((psSystemType: string) => {
					if (Object.keys(poAuthInfo).includes(psSystemType)) {
						laScopesNeeded = laScopesNeeded.concat(poAuthInfo[psSystemType].scopes ?? []);
					} else {
						console.error(`${InteropService.C_LOG_ID} ${psSystemType} inexistant pour le systeme ${psSystem}. Impossible de récupérer les scopes nécessaires.`);
					}
				});
				if (laScopesNeeded.every((psScope) => poAuthInfo.authorization.scopes.includes(psScope))) {
					console.warn(`${InteropService.C_LOG_ID} Tout les scopes voulu sont déjà autorisé pour le systeme ${psSystem}.`);
					//TODO Gerer cas quand on aura séparé les appels n8n entre l'auth et les subscriptions.
				}

				laScopesNeeded = laScopesNeeded.concat(poAuthInfo.authorization.scopes);
				laScopesNeeded = ArrayHelper.unique(laScopesNeeded);


				if (!ArrayHelper.hasElements(laScopesNeeded)) {
					console.error(`${InteropService.C_LOG_ID} Impossible de récupérer les scopes nécessaires pour le systeme ${psSystem}.`);
					return of(false);
				}

				const lsRedirectUrl = this.getRedirectUrl();
				const lsState = this.addCacheNewAuth(laScopesNeeded, lsRedirectUrl, psSystem);
				const lsScopesEncoded = encodeURIComponent(laScopesNeeded.join(" "));
				const lsUrl = `${poAuthInfo.authorization.url}&redirect_uri=${lsRedirectUrl}&scope=${lsScopesEncoded}&state=${lsState}`;

				if (this.isvcPlatform.isMobileApp) {
					return this.openWebViewForAuth$(lsUrl, lsRedirectUrl, paSystemTypes);
				} else {
					return this.openNavPopupForAuth$(lsUrl, lsRedirectUrl, paSystemTypes);
				}
			}),
		);
	}

	private createAuth$(psCode: string, psKey: string, paSystemTypes: string[]): Observable<boolean> {
		const loInteropInfo: InteropInfo = this.moCache[psKey];
		if (!loInteropInfo) {
			console.error(`${InteropService.C_LOG_ID} ${psKey} impossible à retrouver dans l'InteropCache.`);
			return of(false);
		}

		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}n8n/systems/${loInteropInfo.systemId}/subscribe`;
		const loBody = {
			...loInteropInfo,
			code: psCode,
			workspaceId: this.isvcWorkspace.getCurrentWorkspaceId(),
			userId: UserHelper.getUserGuid(UserHelper.getUserId())
		};

		paSystemTypes.forEach((psSystemType) => loBody[psSystemType] = true);

		return this.ioHttpClient.post(lsApiUrl, loBody, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).pipe(
			map(_ => true),
			catchError((poError: Error) => {
				console.error(`${InteropService.C_LOG_ID} Envoi Auth à n8n à échoué.`, poError);
				return of(false);
			}),
			secure(this)
		);
	}

	public deleteAuth$(psSystem: string, paSystemTypes: string[]): Observable<boolean> {
		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}n8n/systems/${psSystem}/unsubscribe`;
		const loBody = {
			workspaceId: this.isvcWorkspace.getCurrentWorkspaceId(),
			userId: UserHelper.getUserGuid(UserHelper.getUserId())
		};

		paSystemTypes.forEach((psSystemType) => loBody[psSystemType] = true);

		return this.ioHttpClient.post(lsApiUrl, loBody, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).pipe(
			map(_ => true),
			catchError((poError: Error) => {
				console.error(`${InteropService.C_LOG_ID} Envoi delete Auth à n8n à échoué.`, poError);
				return of(false);
			})
		);
	}

	//#endregion

}