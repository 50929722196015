import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { StoreDocumentHelper } from '@calaosoft/osapp-common/store/helpers/store-document-helper';
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EMPTY, Observable, combineLatest, defer, of } from 'rxjs';
import { finalize, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { IStoreDataResponse } from '../../../../model/store/IStoreDataResponse';
import { Loader } from '../../../loading/Loader';
import { EntityModalBase } from '../../models/entity-modal-base';
import { IEntityDescriptor } from '../../models/ientity-descriptor';
import { ILayoutParams } from '../../models/ilayout-params';

@Component({
	selector: 'calao-entity-modal',
	templateUrl: './entity-modal.component.html',
	styleUrls: ['./entity-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityModalComponent extends EntityModalBase implements OnInit {

	//#region PROPERTIES

	@Input() public closeAfterSave?: boolean;
	@ObserveProperty<EntityModalComponent>({ sourcePropertyKey: "closeAfterSave" })
	public readonly observableCloseAfterSave = new ObservableProperty<boolean>(false);

	public readonly observableTitle = new ObservableProperty<string>("").bind(this.getTitle$(), this);

	public readonly displayActionButtons$: Observable<boolean> = combineLatest([this.observableCanEdit.value$, this.canDelete$]).pipe(
		map(ArrayHelper.some),
		secure(this)
	);

	public readonly displayHeader$: Observable<boolean> = combineLatest([
		this.observableTitle.value$.pipe(map((psTitle: string) => !StringHelper.isBlank(psTitle))),
		this.displayActionButtons$
	]).pipe(
		map(ArrayHelper.some),
		secure(this)
	);

	//#endregion

	//#region METHODS

	public onEditAsync(): void {
		this.observableIsEdit.value = true;
	}

	@Queue<EntityModalComponent, Parameters<EntityModalComponent["delete$"]>, ReturnType<EntityModalComponent["delete$"]>>({
		excludePendings: true
	})
	private delete$(): Observable<IStoreDataResponse | undefined> {
		return this.observableDescriptor.value$.pipe(
			take(1),
			switchMap((poDescriptor?: IEntityDescriptor) => {
				if (poDescriptor?.entry) {
					return this.isvcEntitiesUpdate.deleteEntity(poDescriptor.entry, poDescriptor).pipe(
						mapTo(undefined),
						tap(() => this.close())
					);
				}

				return of(undefined);
			})
		);
	}

	public onDeleteAsync(): Promise<IStoreDataResponse | undefined> {
		return this.delete$().toPromise();
	}

	private getTitle$(): Observable<string> {
		return combineLatest([this.params$, this.observableDescriptor.value$, this.observableIsEdit.value$]).pipe(
			map(([poParams, poDescriptor, pbIsEdit]: [ILayoutParams, IEntityDescriptor, boolean]) => {
				if (pbIsEdit)
					return (StoreDocumentHelper.hasRevision(poDescriptor?.entry) ? poParams.editTitle : poParams.createTitle) ?? "";
				return poParams.title ?? "";
			})
		);
	}

	@Queue<EntityModalComponent, Parameters<EntityModalComponent["submit$"]>, ReturnType<EntityModalComponent["submit$"]>>({
		excludePendings: true
	})
	private submit$(): Observable<void> {
		const loModel: Entity | undefined = this.observableDescriptor.value?.entry;
		let loLoader: Loader;

		if (loModel) {
			return defer(() => this.isvcLoading.create("Sauvegarde en cours")).pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => this.save(loModel)),
				map(() => { }),
				finalize(() => {
					this.observableIsEdit.value = false;
					loLoader?.dismiss();
					if (this.observableCloseAfterSave.value)
						this.close(loModel);
				})
			);
		}
		return EMPTY;
	}

	private save(poModel?: Entity): Observable<any> {
		if (!poModel || !this.observableDescriptor.value)
			return EMPTY;

		return this.isvcEntitiesUpdate.saveModel(
			poModel,
			this.observableDescriptor.value,
			ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))
		);
	}

	public onSubmitAsync(): Promise<void> {
		return this.submit$().toPromise();
	}

	//#endregion

}
