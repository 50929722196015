import { Type } from '@angular/core';
import { IIndexedArray } from '@calaosoft/osapp-common/utils/models/IIndexedArray';

export class DynHostItem<T = any, P = IIndexedArray<any>> {

	//#region PROPERTIES

	/** Composant dynamique qu'on va manipuler. */
	public component: Type<T>;
	/** Paramètres facultatifs du composant dynamique. */
	public params: P;

	//#endregion

	//#region METHODS

	/** Constructeur d'un DynHostItem.
	 * @param poComponent Type du composant à instancier.
	 * @param poParams Objet paramètre pour le composant à instancier.
	 */
	constructor(poComponent: Type<T>, poParams?: P) {
		this.component = poComponent;
		this.params = poParams;
	}

	//#endregion
}