import { Type } from "@angular/core";
import { Exclude } from "@calaosoft/osapp-common/class-transformer";
import { DateHelper } from "@calaosoft/osapp-common/dates/helpers/dateHelper";
import { ETimetablePattern } from "@calaosoft/osapp-common/dates/models/ETimetablePattern";
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { ResolveModel } from "@calaosoft/osapp-common/utils/decorators/resolve-model.decorator";
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { BaseEvent } from "@calaosoft/osapp/modules/calendar-events/models/base-event";
import { ENotificationType } from "@calaosoft/osapp/modules/calendar-events/models/enotification-type";
import { EventState } from "@calaosoft/osapp/modules/calendar-events/models/event-state";
import { IEvent } from "@calaosoft/osapp/modules/calendar-events/models/ievent";
import { IEventNotification } from "@calaosoft/osapp/modules/calendar-events/models/ievent-notification";
import { IEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/ievent-occurrence";
import { EReminderAlarmActionType } from "@calaosoft/osapp/modules/reminder-alarms/models/ereminder-alarm-action-type";
import { ReminderAlarm } from "@calaosoft/osapp/modules/reminder-alarms/models/reminder-alarm";
import { EReminderStatus } from "./ereminder-status";
import { ETaskStatus } from "./etask-status";
import { ETradeEventType } from "./etrade-event-type";
import { IReminder } from "./ireminder";
import { ReminderOccurrence } from "./reminder-occurrence";

const C_TYPE = ETradeEventType.reminder;
@ModelMatch((poData: IEvent) => poData?.eventType === C_TYPE, BaseEvent)
export class Reminder extends BaseEvent<ReminderOccurrence> implements IReminder {

	//#region PROPERTIES

	@Exclude()
	protected override occurrenceType: Type<ReminderOccurrence> = ReminderOccurrence;
	public override eventType: string = ETradeEventType.reminder;
	public override status: EReminderStatus = EReminderStatus.pending;
	public override notifications: IEventNotification[];
	@Exclude()
	@ResolveModel(Date)
	protected override mdStartDate: Date;
	public override get startDate(): Date { return this.mdStartDate; }
	public override set startDate(pdStartDate: Date) {
		if (pdStartDate !== this.mdStartDate)
			super.onStartDateChanged(pdStartDate);
	}

	public important?: boolean | undefined;

	@Exclude()
	public override themeColor = "var(--ion-color-event)";

	//#endregion

	//#region METHODS

	/**
	 * Génère la prochaine occurrence du rappel, si elle existe.
	 * La prochaine occurrence est déterminée à partir des documents evtState présents en base.
	 * On recherche le document qui a été mis à jour le plus récemment et on vérifie son champ lastEndedDate.
	 * Si le champ est absent, alors la récurrence est terminée et aucune occurrence ne sera générée.
	 * @returns L'occurrence générée, ou undefined si aucune
	 */
	public override generateNextOccurrence(
		pdDate?: Date,
		psRev?: string
	): ReminderOccurrence | undefined {
		const loLastState: EventState | undefined = this.getLastState();

		// Si un document d'état existe, on génère la prochaine occurrence à partir de celui-ci
		if (loLastState) {
			// Si la récurrence est terminée, le champ nextOccurrence de l'état le plus récent doit être undefined, et donc aucune occurrence ne sera générée ici.
			if (loLastState.status !== ETaskStatus.done)
				return super.generateNextOccurrence(loLastState.lastEndedDate ?? this.startDate, psRev);
			else
				return undefined;
		}
		// Sinon, c'est qu'il s'agit d'une création, on génère la première occurence à l'aide de la date de début
		else
			return this.generateOccurrence(this.startDate, psRev);

	}

	public override getRoute(): string {
		return `agenda/reminders/${IdHelper.getGuidFromId(this._id, EPrefix.event)}/occurrences/${DateHelper.toUTCString(this.startDate)}${this._rev ? `?rev=${this._rev}` : ""}`;
	}

	protected override generateReminderAlarmsFromOccurence(poOccurrence: IEventOccurrence): ReminderAlarm[] {
		return (poOccurrence.notifications ?? []).map((poEventNotification: IEventNotification) => {
			const ldStartDate: Date = poOccurrence.startDate ?? new Date;
			return new ReminderAlarm(
				this._id,
				poEventNotification.type === ENotificationType.absolute ? (poEventNotification.date ? new Date(poEventNotification.date) : new Date) : ldStartDate,
				this.getReminderDuration(poEventNotification),
				this.title,
				`À ${DateHelper.transform(ldStartDate, ETimetablePattern.HH_mm)} le ${DateHelper.transform(ldStartDate, ETimetablePattern.EEEE_dd_MMMM)}`,
				this.getRoute(),
				{ eventRev: this._rev, occurrenceStartDate: ldStartDate },
				EReminderAlarmActionType.rescheduleAndRepeat
			);
		});
	}

	//#endregion
}