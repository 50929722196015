<ng-container *ngIf="obsProps.value$ | async as observedProps">

	<!-- Affichage indicateur mode progress-bar -->
	<div *ngIf="observedProps.mode === EMode.bar" class="main-container flex-col">
		<ng-container *ngTemplateOutlet="title; context: { observedProps: observedProps }"></ng-container>

		<div class="progress-container">
			<div class="progress-bar progress-bar-background" [style]="getProgressBarBackgroundStyle()">
				<div class="progress-bar" [style]="getProgressBarStyle()"></div>
			</div>
		</div>
	</div>

	<!-- Affichage indicateur mode circle -->
	<div *ngIf="observedProps.mode === EMode.circle" class="main-container flex-row-circle">

		<div class="progress-container">
			<div class="progress-circle" [style]="getProgressCircleStyle()">
				<div class="progress-circle-text">
					<ng-container
						*ngTemplateOutlet="(obsCompletionPercentage.value$ | async) ? percentageTemplate : noPercentageTemplate">
					</ng-container>
					<ng-template #percentageTemplate let-percentage="percentage">
						<span>{{obsCompletionPercentage.value$ | async}}%</span>
					</ng-template>
					<ng-template #noPercentageTemplate>
						<span class="no-percentage">--%</span>
					</ng-template>
				</div>
			</div>
		</div>

		<ng-container *ngTemplateOutlet="title; context: { observedProps: observedProps }"></ng-container>
	</div>

	<!-- Affichage indicateur mode plafond -->
	<div *ngIf="observedProps.mode === EMode.ceiling" class="main-container flex-col">
		<ng-container *ngTemplateOutlet="title; context: { observedProps: observedProps }"></ng-container>

		<div class="progress-container">
			<div class="progress-bar ceiling-bar" [style]="getCeilingBarStyle()"></div>
		</div>
	</div>

	<!-- Affichage titre uniquement -->
	<div *ngIf="!observedProps.mode" class="main-container flex-col">
		<ng-container *ngTemplateOutlet="title; context: { observedProps: observedProps }"></ng-container>
	</div>

</ng-container>

<ng-template #title let-observedProps="observedProps">
	<div class="flex-col">
		<ion-label class="title">{{observedProps.title}}</ion-label>
		<ion-label *ngIf="obsLabel.value$| async as label" class="label">{{label}}</ion-label>
	</div>
</ng-template>