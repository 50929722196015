import { IEntityMeta } from '@calaosoft/osapp-common/entities/models/ientity-meta';
import { GuidHelper } from "@calaosoft/osapp-common/guid/helpers/guidHelper";
import { IDmsDocument } from './IDmsDocument';
import { IDmsDocumentAttribute } from './IDmsDocumentAttribute';
import { IDmsMeta } from './IDmsMeta';

export class DmsDocument implements IDmsDocument {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];
	/** @implements */
	public description?: string;
	/** @implements */
	public type?: string;
	/** @implements */
	public subType?: string;
	/** @implements */
	public name: string;
	/** @implements */
	public originalName?: string;
	/** @implements */
	public guid?: string;
	public attributes?: IDmsDocumentAttribute[];
	public createDate?: Date;
	public lastAccess?: Date;
	public modifyDate?: Date;
	public size?: number;
	/** Chemin de classification dans les interfaces de gestion des documents */
	public paths?: string[];
	/** Id de l'auteur. */
	public authorId?: string;

	/** Icône du type de fichier. */
	public icon?: string;
	/** Nom de l'auteur. */
	public authorName?: string;
	public meta: IEntityMeta;

	//#endregion

	//#region METHODS

	public static FromIDmsDocument(poData: IDmsDocument): DmsDocument {
		const loDocument = new DmsDocument();

		if (poData) {
			loDocument._id = poData._id;
			loDocument._rev = poData._rev;
			loDocument.description = poData.description;
			loDocument.type = poData.type;
			loDocument.subType = poData.subType;
			loDocument.name = poData.name;
			loDocument.originalName = poData.originalName;
			loDocument.guid = poData.guid;
		}

		return loDocument;
	}

	public static FromIDmsMeta(poData: IDmsMeta): DmsDocument {
		const loDocument = new DmsDocument();

		if (poData) {
			loDocument.guid = GuidHelper.extractGuid(poData._id);
			loDocument.attributes = poData.attributes;
			loDocument.createDate = poData.createDate;
			loDocument.lastAccess = poData.lastAccess;
			loDocument.modifyDate = poData.modifyDate;
			loDocument.size = poData.size;
			loDocument.description = poData.description;
			loDocument.name = poData.name;
			loDocument.originalName = poData.originalName;
			loDocument.type = poData.documentType;
			loDocument.paths = poData.paths;
			loDocument.authorId = poData.authorId;
		}

		return loDocument;
	}

	//#endregion

}