import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { secure } from '@calaosoft/osapp-common/rxjs/operators/secure';
import { EAvatarSize } from '@calaosoft/osapp/model/picture/EAvatarSize';
import { IAvatar } from '@calaosoft/osapp/model/picture/IAvatar';
import { IPopoverItemParams } from '@calaosoft/osapp/model/popover/IPopoverItemParams';
import { HasPermissions } from '@calaosoft/osapp/modules/permissions/decorators/has-permissions.decorator';
import { EPermissionScopes } from "@calaosoft/osapp/modules/permissions/models/epermission-scopes";
import { IPermissionContext } from '@calaosoft/osapp/modules/permissions/models/ipermission-context';
import { IHasPermission, PermissionsService } from '@calaosoft/osapp/modules/permissions/services/permissions.service';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { PopoverService } from '@calaosoft/osapp/services/popover.service';
import { EMPTY, Observable, defer, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { C_BUSINESS_PERMISSION_ID } from '../../../../app/app.constants';
import { Business } from '../../model/business';
import { IBusiness } from '../../model/ibusiness';
import { BusinessesService } from '../../services/businesses.service';

@Component({
	selector: 'trade-business-item',
	templateUrl: './business-item.component.html',
	styleUrls: ['./business-item.component.scss'],
})
export class BusinessItemComponent extends DestroyableComponentBase implements OnInit, IHasPermission {

	//#region FIELDS

	private static readonly C_LOG_ID = "BIZ.ITM.C::";

	@Output("onDeleteItemClicked") private readonly moDeleteItemClickedEvent = new EventEmitter<Business>();
	@Output("onItemClicked") private readonly moItemClickedEvent = new EventEmitter<Business>();
	@Output("onItemEditClicked") private readonly moItemEditClickedEvent = new EventEmitter<Business>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Business. */
	@Input() public item?: Business;
	@ObserveProperty<BusinessItemComponent>({ sourcePropertyKey: "item" })
	public readonly observableItem = new ObservableProperty<Business>();

	public readonly observablePopOverItemsParams = new ObservableArray<IPopoverItemParams>([]);

	public readonly permissionScope?: EPermissionScopes = C_BUSINESS_PERMISSION_ID;

	@HasPermissions({ permission: "edit", context: (poInstance: BusinessItemComponent) => (poInstance.item as IPermissionContext) })
	public get canEditBusiness(): boolean {
		return true;
	}

	@HasPermissions({ permission: "delete", context: (poInstance: BusinessItemComponent) => (poInstance.item as IPermissionContext) })
	public get canDeleteBusiness(): boolean {
		return true;
	}

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcPermissions: PermissionsService,
		private readonly isvcPopover: PopoverService
	) {
		super();
	}

	public onItemClicked(poBusiness: Business): void {
		this.moItemClickedEvent.emit(poBusiness);
	}

	public ngOnInit(): void {
		this.observableItem.value$.pipe(
			tap((poBusiness: Business) => {
				const loCallPopOverParams: IPopoverItemParams = {
					action: () => this.onCallBusinessClicked$(),
					id: "call",
					color: "primary",
					icon: "call",
					title: "Appeler"
				};
				const loEditPopOverParams: IPopoverItemParams = {
					action: () => this.onEditBusinessClicked$(),
					id: "edit",
					color: "primary",
					icon: "create",
					title: "Éditer"
				};
				const loDeletePopOverParams: IPopoverItemParams = {
					action: () => this.onDeleteBusinessClicked$(),
					id: "delete",
					color: "danger",
					icon: "trash",
					title: "Supprimer définitivement"
				};

				const laPopOverItemsParams: IPopoverItemParams[] = [];
				let lbHasCallPopOverParams = false;
				let lbHasEditPopOverParams = false;
				let lbDeletePopOverParams = false;
				laPopOverItemsParams.forEach((poPopOverParam: IPopoverItemParams) => {
					if (!lbHasCallPopOverParams)
						lbHasCallPopOverParams = poPopOverParam.id === loCallPopOverParams.id;
					if (!lbHasEditPopOverParams)
						lbHasEditPopOverParams = poPopOverParam.id === loEditPopOverParams.id;
					if (!lbDeletePopOverParams)
						lbDeletePopOverParams = poPopOverParam.id === loDeletePopOverParams.id;
				});

				if (!lbDeletePopOverParams && poBusiness.phone)
					laPopOverItemsParams.push(loCallPopOverParams);
				if (!lbHasEditPopOverParams && this.canEditBusiness)
					laPopOverItemsParams.push(loEditPopOverParams);
				if (!lbHasCallPopOverParams && this.canDeleteBusiness)
					laPopOverItemsParams.push(loDeletePopOverParams);

				this.observablePopOverItemsParams.resetArray([...laPopOverItemsParams]);
			}),
			secure(this)
		).subscribe();
	}

	public async presentPopOverAsync(poEvent: MouseEvent): Promise<void> {
		this.presentPopOver$(poEvent).subscribe();
	}

	@Queue<BusinessItemComponent, Parameters<BusinessItemComponent["presentPopOver$"]>, ReturnType<BusinessItemComponent["presentPopOver$"]>>({
		excludePendings: true
	})
	private presentPopOver$(poEvent: MouseEvent): Observable<HTMLIonPopoverElement> {
		return defer(() => this.isvcPopover.showPopoverAsync(this.observablePopOverItemsParams, poEvent));
	}

	private onCallBusinessClicked$(): Observable<void> {
		if (this.observableItem.value) {
			const loLink: HTMLAnchorElement = document.createElement('a');
			loLink.href = `tel:${this.observableItem.value.phone}`;
			document.body.appendChild(loLink);
			loLink.click();
			document.body.removeChild(loLink);
			return EMPTY;
		}
		else {
			this.warnBusinessNotDefined();
			return EMPTY;
		}
	}

	private onEditBusinessClicked$(): Observable<void> {
		if (this.observableItem.value)
			return of(this.moItemEditClickedEvent.emit(this.observableItem.value));
		else {
			this.warnBusinessNotDefined();
			return EMPTY;
		}
	}

	private onDeleteBusinessClicked$(): Observable<void> {
		if (this.observableItem.value)
			return of(this.moDeleteItemClickedEvent.emit(this.observableItem.value));
		else {
			this.warnBusinessNotDefined();
			return EMPTY;
		}
	}

	/** Crée et retourne un avatar depuis les données passées en paramètre.
	 * @param poBusiness
	 */
	public getBusinessAvatar(poBusiness: IBusiness): IAvatar {
		return BusinessesService.createBusinessAvatar(poBusiness, EAvatarSize.big, true);
	}

	private warnBusinessNotDefined(): void {
		console.warn(`${BusinessItemComponent.C_LOG_ID}business is not defined !`);
	}

	//#endregion

}