import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';

export class NoOnlineReliableNetworkError extends Error {

	constructor() {
		super("Votre connexion internet est insuffisante pour poursuivre.");
		// Nécessaire pour que le 'instanceof NoOnlineReliableNetworkError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, NoOnlineReliableNetworkError);
	}

}