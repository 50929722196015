import { IAppInfo } from '../../applications/models/IAppInfo';
import { IAuthentication } from '../../authentication/models/IAuthentication';
import { IBackgroundTask } from '../../background-tasks/models/IBackgroundTask';
import { IBookingConfig } from '../../booking/models/IBookingConfig';
import { IContactsConfig } from '../../contacts/models/icontacts-config';
import { IConversationConfig } from '../../conversations/models/IConversationConfig';
import { IDocumentsConfiguration } from '../../documents/models/idocuments-configuration';
import { IEnvironment } from '../../environment/models/IEnvironment';
import { ILoggerConfig } from '../../logging/models/ILoggerConfig';
import { ILogisticsConfig } from '../../logistics/models/ILogisticsConfig';
import { IConfigOneSignal } from '../../one-signal/models/IConfigOneSignal';
import { ISecurityConfig } from '../../security/models/ISecurityConfig';
import { IDatabaseConfig } from '../../store/models/idatabase-config';
import { IUpdateConfig } from '../../update/models/IUpdateConfig';

export abstract class ConfigData {

	/** Informations statique sur l'application. */
	public static appInfo: IAppInfo;
	/** Informations statique sur le type d'authentification qui va être utilisé par l'application. */
	public static authentication: IAuthentication;
	/** Informations sur l'environnement. */
	public static environment: IEnvironment;
	/** Informations sur les tâches de fond. */
	public static backgroundTask: IBackgroundTask;
	/** Configuration pour la mise à jour de l'application. */
	public static update: IUpdateConfig;
	/** Indique si on est en version de production ou non. */
	public static isProductionEnvironment: boolean;
	/** Information sur les configurations des conversation. */
	public static conversation?: IConversationConfig;
	/** Configuration des bases de données. */
	public static databases: IDatabaseConfig[] = [];
	/** Configuration des bases de données de workspace. */
	public static workspaceDatabases: IDatabaseConfig[];
	/** Configuration de la sécurité de l'application. */
	public static security?: ISecurityConfig;
	/** Configuration pour OneSignal (utilisé par le NotificationService). Si `undefined`, le notification.service n'est jamais intialisé. */
	public static oneSignal?: IConfigOneSignal;
	/** Configuration pour le module Calao. */
	public static logistics?: ILogisticsConfig;

	/** Configuration du logging. */
	public static logger: ILoggerConfig;

	/** Configuration du module Booking. */
	public static booking?: IBookingConfig;
	/** Configuration du module contact. */
	public static contacts?: IContactsConfig;
	/** Identifiants des descripteurs d'entités inclus dans l'application. */
	public static builtInEntityDescIds?: string[];
	/** Identifiants des descripteurs de configuration inclus dans l'application. */
	public static builtInConfigDescIds?: string[];
	/** Désactive la gestion des liens. */
	public static disableLinks?: boolean;

	/** Configuration pour l'enregistrement des documents. */
	public static documentsConfiguration?: IDocumentsConfiguration;
}