import { ChangeDetectorRef, Component, Input, ViewContainerRef } from "@angular/core";
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { ComponentBase } from "../../../helpers/ComponentBase";
import { RouteComponent } from "../../../model/RouteComponent";
import { PageManagerService } from "../../routing/services/pageManager.service";
import { ILayoutParams } from "./ilayout-params";
import { ILayoutParamsComponent } from "./ilayout-params-component";

@Component({ template: "" })
export abstract class LayoutBase<T extends ILayoutParams = ILayoutParams> extends ComponentBase {

	//#region PROPERTIES

	/** Doc propriété. */
	@Input() public params?: T;
	@ObserveProperty<LayoutBase>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<T>();

	//#endregion

	//#region METHODS

	constructor(protected readonly isvcPageManager: PageManagerService, poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	protected clearLayout(poElementRef?: ViewContainerRef): void {
		if (poElementRef)
			poElementRef.clear();
	}

	protected addComponent(poComponentParams: ILayoutParamsComponent, poTarget: ViewContainerRef): void {
		if (!StringHelper.isBlank(poComponentParams?.type)) {
			const loComponent: RouteComponent | undefined = this.isvcPageManager.getRouteComponentFromId(poComponentParams.type);
			if (loComponent) {
				poComponentParams.params.layoutParams = this.params;

				ObjectHelper.assign(
					poTarget.createComponent(loComponent.componentType).instance,
					poComponentParams.params ?? {}
				);

				this.detectChanges();
			}
		}
	}

	//#endregion

}
