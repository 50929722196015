import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { UserHelper } from '@calaosoft/osapp/helpers/user.helper';
import { BaseEvent } from '@calaosoft/osapp/modules/calendar-events/models/base-event';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { CalendarEventsService } from '@calaosoft/osapp/modules/calendar-events/services/calendar-events.service';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';

@Injectable()
export class CanShowEventGuard {

	//#region METHODS

	constructor(private readonly isvcEvent: CalendarEventsService, private readonly isvcUiMessage: UiMessageService) { }

	public async canActivate(poRoute: ActivatedRouteSnapshot): Promise<boolean> {
		const lsEventGuid: string | undefined = poRoute.params.taskGuid || poRoute.params.eventGuid;

		if (!StringHelper.isBlank(lsEventGuid)) {
			const loEvent: BaseEvent<BaseEventOccurrence> | undefined = await this.isvcEvent.getEventAsync(IdHelper.buildId(EPrefix.event, lsEventGuid));
			if (loEvent && (loEvent.private && !loEvent.participantIds.includes(UserHelper.getUserContactId()))) {
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Accès impossible", message: "Vous n'êtes pas autorisé à accéder à cet élément." }));
				return false;
			}
		}

		return true;
	}

	//#endregion

}
