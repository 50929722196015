import { Type } from '@angular/core';
import { Exclude } from '@calaosoft/osapp-common/class-transformer';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { BaseEvent } from '@calaosoft/osapp/modules/calendar-events/models/base-event';
import { IEvent } from '@calaosoft/osapp/modules/calendar-events/models/ievent';
import { ETradeEventType } from './etrade-event-type';
import { EventOccurrence } from './event-occurrence';

const C_TYPE = ETradeEventType.standard;

@ModelMatch((poData: IEvent) => poData?.eventType === C_TYPE, BaseEvent)
export class Event extends BaseEvent<EventOccurrence> {

	//#region FIELDS

	@Exclude()
	protected override readonly occurrenceType: Type<EventOccurrence> = EventOccurrence;
	public override eventType: string = C_TYPE;

	@Exclude()
	public override themeColor = "var(--ion-color-event)";

	//#endregion

	//#region METHODS

	public override getRoute(pdDate: Date | undefined = this.startDate): string {
		return `agenda/events/${IdHelper.getGuidFromId(this._id, EPrefix.event)}/occurrences/${pdDate ? DateHelper.toUTCString(pdDate) : null}${this._rev ? `?rev=${this._rev}` : ""}`;
	}

	//#endregion

}