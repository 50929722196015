import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { AvatarHelper } from '../../../../helpers/avatarHelper';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { IAvatarsParams } from '../../models/IAvatarsParams';

@Component({
	selector: "calao-avatars",
	templateUrl: './avatars.component.html',
	styleUrls: ['./avatars.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarsComponent extends ComponentBase implements IAvatarsParams {

	//#region PROPERTIES

	private moAvatar: IAvatar;
	/** @implements */
	public get avatar(): IAvatar {
		return this.moAvatar;
	}
	@Input() public set avatar(poValue: IAvatar) {
		// Affectation si le membre n'est pas renseigné ou si la nouvelle valeur est valide et différentes de l'actuelle.
		if (!this.moAvatar ||
			(poValue && AvatarHelper.areDifferent(this.moAvatar, poValue))) {

			this.moAvatar = poValue;
			this.detectChanges();
		}
	}

	private mnAvatarsCount: number;
	/** @implements */
	public get avatarsCount(): number {
		return this.mnAvatarsCount;
	}
	@Input() public set avatarsCount(pnValue: number) {
		const lnValue: number = pnValue - 1;
		// Affectation si la nouvelle valeur n'est pas la même que l'actuelle.
		if (this.mnAvatarsCount !== lnValue) {
			this.mnAvatarsCount = lnValue > 0 && NumberHelper.isValid(lnValue) ? lnValue : 0;
			this.detectChanges();
		}
	}

	private msTooltip: string;
	/** @implements */
	public get tooltip(): string { return this.msTooltip; }
	@Input() public set tooltip(psTooltip: string) {
		if (psTooltip !== this.msTooltip) {
			this.msTooltip = psTooltip;
			this.detectChanges();
		}
	}


	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	//#endregion
}