import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DateHelper } from "@calaosoft/osapp-common/dates/helpers/dateHelper";
import { ObservableArray } from "@calaosoft/osapp-common/observable/models/observable-array";
import { ObservableProperty } from "@calaosoft/osapp-common/observable/models/observable-property";
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { UserHelper } from "@calaosoft/osapp/helpers/user.helper";
import { UserData } from "@calaosoft/osapp/model/application/UserData";
import { FieldBase } from "@calaosoft/osapp/model/forms/FieldBase";
import { ActivePageManager } from "@calaosoft/osapp/model/navigation/ActivePageManager";
import { BaseEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence";
import { FormsService } from "@calaosoft/osapp/services/forms.service";
import { combineLatest, defaultIfEmpty, map, switchMap, tap } from "rxjs";
import { C_NB_LATE_EVENTS_IN_LATE_ITEMS } from "../../app/app.constants";
import { ReminderOccurrence } from "../trade-events/models/reminder-occurrence";
import { TaskOccurrence } from "../trade-events/models/task-occurrence";
import { TradeEventsService } from "../trade-events/services/trade-events.service";

@Component({
	selector: 'calao-late-item-field',
	templateUrl: './late-item-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LateItemFieldComponent extends FieldBase implements OnInit {

	//#region FIELDS

	private readonly moActivePageManager = new ActivePageManager(this, this.isvcRouter);

	private readonly maParticipantIds: string[] = UserData.current?._id ? [UserHelper.getUserContactId()] : [];

	/** Date actuelle. */
	private readonly observableTodayDate = new ObservableProperty<Date>().bind(
		DateHelper.getDays$(),
		this
	);

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly observableLateEventsCount = new ObservableProperty<number>(0);

	public readonly observableLateNbDiff = new ObservableProperty<number>();

	public readonly observableLateOccurrences = new ObservableArray<BaseEventOccurrence>();

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcTradeEvents: TradeEventsService,
		private readonly isvcRouter: Router,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observableTodayDate.value$.pipe(
			switchMap((pdDate: Date) => combineLatest([
				this.isvcTradeEvents.getLateRemindersOccurrences$(
					this.moActivePageManager,
					{ participantIds: this.maParticipantIds, dateRange: { to: pdDate } }
				),
				this.isvcTradeEvents.getLateTasksOccurrences$(
					this.moActivePageManager,
					{ participantIds: this.maParticipantIds, dateRange: { to: pdDate } }
				)
			])),
			map(([paLateReminders, paLateTasks]: [ReminderOccurrence[], TaskOccurrence[]]) => ([] as BaseEventOccurrence[]).concat(paLateReminders).concat(paLateTasks)),
			tap((paOccurrences: BaseEventOccurrence[]) => {
				const lnOccurrencesCount: number = paOccurrences.length;
				this.observableLateEventsCount.value = lnOccurrencesCount;
				const lnDiff = this.isvcTradeEvents.getTodoItemDiff(lnOccurrencesCount, C_NB_LATE_EVENTS_IN_LATE_ITEMS); // On calcule la différence entre le total des tâches/rappels en retard et celles affichées dans l'item Late.
				this.observableLateNbDiff.value = (lnDiff > 0) ? lnDiff : 0;
				this.observableLateOccurrences.resetArray(this.isvcTradeEvents.prepareLateItems(paOccurrences));
			}),
			secure(this),
			defaultIfEmpty([])
		).subscribe();
	}

	//#endregion
}