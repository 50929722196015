import { Observable, ReplaySubject, defer, firstValueFrom } from "rxjs";
import { Destroyable } from "../../destroyable/classes/destroyable";
import { PerformanceManager } from "../../performance/PerformanceManager";
import { Queue } from "../../queue/decorators/queue.decorator";
import { SqlWrapper } from "../../sqlite/classes/sql-wrapper";
import { SqlFilesHelper } from "../../sqlite/helpers/sql-files.helper";
import { SqlRequestHelper } from "../../sqlite/helpers/sql-request-helper";
import { IBatchedRequest } from "../../sqlite/models/ibatched-request";
import { SqlDataSource } from "../../sqlite/models/sql-data-source";
import { SqlRequestResult } from "../../sqlite/models/sql-request-result";
import { TRequestParam } from "../../sqlite/models/trequest-param";
import { StoreHelper } from "../../store/helpers/store-helper";
import { IDataSourceViewParams } from "../../store/models/IDataSourceViewParams";
import { IDesignDocument } from "../../store/models/IDesignDocument";
import { IChangesResponse } from "../../store/models/ichanges-response";
import { IChangesResponseResult } from "../../store/models/ichanges-response-result";
import { IStoreDocument } from "../../store/models/istore-document";
import { OsappError } from "../../utils/errors/OsappError";
import { ArrayHelper } from "../../utils/helpers/arrayHelper";
import { IdHelper } from "../../utils/helpers/idHelper";
import { NumberHelper } from "../../utils/helpers/numberHelper";
import { ObjectHelper } from "../../utils/helpers/objectHelper";
import { StringHelper } from "../../utils/helpers/stringHelper";
import { EPrefix } from "../../utils/models/EPrefix";
import { IKeyValue } from "../../utils/models/ikey-value";
import { EIndexorViewState } from "../models/eindexor-view-state";
import { IIndexorViewInfo } from "../models/iindexor-view-info";
import { IIndexorViewRow } from "../models/iindexor-view-row";
import { IGetDataSourceParams } from "./IGetDataSourceParams";
import { IOpenDatabaseParams } from "./IOpenDatabaseParams";
import { IRequestParams } from "./IRequestParams";

export class Indexor extends Destroyable {
	//#region FIELDS

	public static readonly C_DEFAULT_DATABASE_VERSION = 1;

	private static readonly C_DEFAULT_BATCH_SIZE = 999;
	private static readonly C_VIEW_DATA_TABLE_NAME = "viewdata";
	private static readonly C_VIEW_DATA_ID_INDEX_NAME = "idIndex";
	private static readonly C_VIEW_DATA_KEY_INDEX_NAME = "keyIndex";
	private static readonly C_VIEW_INFO_TABLE_NAME = "viewinfo";
	private static readonly C_DATABASE_ID_REPLACEMENT_REGEXP = new RegExp(SqlDataSource.C_SEPARATOR, "g");
	private static readonly C_LOG_ID = "INDEX.S::";

	private readonly moIndexorDataSourceSubjectByDatabaseId = new Map<string, ReplaySubject<SqlDataSource>>();

	//#endregion FIELDS

	//#region METHODS

	constructor(private readonly isvcSql: SqlWrapper) {
		super();
	}

	private async getSqlDataSourceAsync(poParams: IGetDataSourceParams): Promise<SqlDataSource> {
		return firstValueFrom(this.getSqlDataSource$(poParams));
	}

	@Queue<Indexor, Parameters<Indexor["getSqlDataSource$"]>, ReturnType<Indexor["getSqlDataSource$"]>>({
		idBuilder: (poParams: IGetDataSourceParams) => `${poParams.databaseId}/${poParams.designDoc}/${poParams.viewName}`
	})
	private getSqlDataSource$(poParams: IGetDataSourceParams): Observable<SqlDataSource> {
		return defer(() => this.getSqlDataSourceQueuedAsync(poParams));
	}

	private async getSqlDataSourceQueuedAsync(poParams: IGetDataSourceParams): Promise<SqlDataSource> {
		let loDataSource: SqlDataSource = this.getSqlDataSource(poParams);

		let loDataSourceSubject: ReplaySubject<SqlDataSource> | undefined = this.moIndexorDataSourceSubjectByDatabaseId.get(
			loDataSource.databaseId
		);
		if (!loDataSourceSubject) {
			await this.openDatabaseAsync(loDataSource);
			this.moIndexorDataSourceSubjectByDatabaseId.set(
				loDataSource.databaseId,
				(loDataSourceSubject = new ReplaySubject())
			);
			await this.createTablesAsync(loDataSource);
			loDataSourceSubject.next(loDataSource);
		} else loDataSource = await firstValueFrom(loDataSourceSubject.asObservable());

		let loViewInfo: IIndexorViewInfo | undefined = await this.getViewInfoAsync(loDataSource, poParams.viewName);

		switch (this.getViewState(poParams.designDoc, loViewInfo)) {
			case EIndexorViewState.ok:
				break; // On ne fait rien

			case EIndexorViewState.notInitialized:
				await this.initIndexorViewAsync(loDataSource, poParams.designDoc, poParams.viewName);
				break;
			default:
				await this.isvcSql.removeDatabaseAsync(poParams.databaseId);
				loDataSource = await this.getSqlDataSourceQueuedAsync(poParams);
				break;
		}

		if (!loViewInfo && !(loViewInfo = await this.getViewInfoAsync(loDataSource, poParams.viewName)))
			throw new OsappError("View info should be defined.");

		await this.updateIndexAsync(loViewInfo, poParams, loDataSource);

		return loDataSource;
	}

	private async updateIndexAsync(
		poViewInfo: IIndexorViewInfo,
		poParams: IGetDataSourceParams,
		poDataSource: SqlDataSource
	): Promise<void> {
		const loPerf = new PerformanceManager().markStart();

		let laDocs: IStoreDocument[] | undefined;
		let lnLastSeq: number = NumberHelper.isValid(poViewInfo?.seq) ? poViewInfo.seq : 0;
		do {
			laDocs = [];
			const loResponse: IChangesResponse<IStoreDocument> = await poParams.changes({
				since: lnLastSeq,
				include_docs: true,
				limit: Indexor.C_DEFAULT_BATCH_SIZE
			});

			lnLastSeq = loResponse.last_seq as number;
			laDocs = loResponse.results.map((poResult: IChangesResponseResult<IStoreDocument>) => poResult.doc!);
			await this.execUptdateIndexBatchAsync(poDataSource, laDocs, poViewInfo, lnLastSeq);
		} while (laDocs?.length === Indexor.C_DEFAULT_BATCH_SIZE);

		console.debug(`${Indexor.C_LOG_ID}Updated index ${poDataSource.databaseId} in ${loPerf.markEnd().measure()}ms.`);
	}

	private async execUptdateIndexBatchAsync(
		poDataSource: SqlDataSource,
		paDocs: IStoreDocument[],
		poViewInfo: IIndexorViewInfo,
		pnLastSeq?: number
	): Promise<void> {
		const laDocIdsToPurgeFromView: string[] = [];
		const laIndexorItems: IIndexorViewRow[] = [];
		paDocs.forEach((poDoc: IStoreDocument) => {
			laDocIdsToPurgeFromView.push(poDoc._id);

			if (!poDoc._deleted) {
				// Si le document est supprimé, on n'a pas besoin de le passer dans la vue
				const laViewExecData: IKeyValue<string[] | string | number, any>[] = StoreHelper.mapFunctionExecutor(
					poDoc,
					poViewInfo.view
				);

				laViewExecData.forEach((poViewExecData: IKeyValue<string[] | string | number, any>) =>
					laIndexorItems.push({
						id: poDoc._id,
						rev: poDoc._rev!,
						key: this.prepareKey(poViewExecData.key),
						value: this.prepareValue(poViewExecData.value)
					})
				);
			}
		});

		const laInsertRequests: IBatchedRequest<IIndexorViewRow>[] = SqlRequestHelper.getInsertRequest<IIndexorViewRow>(
			Indexor.C_VIEW_DATA_TABLE_NAME,
			["id", "rev", "key", "value"],
			laIndexorItems
		);
		const laInsertRequestsString: string[] = [];
		const laInsertRequestsParams: TRequestParam[][] = [];

		laInsertRequests.forEach((poInsertRequest: IBatchedRequest<IIndexorViewRow>) => {
			laInsertRequestsString.push(poInsertRequest.request);
			const laInsertRequestParams: TRequestParam[] = [];
			poInsertRequest.values.forEach((poItem: IIndexorViewRow) =>
				laInsertRequestParams.push(poItem.id, poItem.rev, poItem.key, poItem.value)
			);

			laInsertRequestsParams.push(laInsertRequestParams);
		});

		if (ArrayHelper.hasElements(laDocIdsToPurgeFromView)) {
			laInsertRequestsString.unshift(
				SqlRequestHelper.getDeleteRequestByIds(Indexor.C_VIEW_DATA_TABLE_NAME, laDocIdsToPurgeFromView)
			);
			laInsertRequestsParams.unshift(laDocIdsToPurgeFromView);
		}

		laInsertRequestsString.push(
			SqlRequestHelper.getUpdateByRequest<IIndexorViewInfo>(Indexor.C_VIEW_INFO_TABLE_NAME, ["seq"], "viewName")
		);
		laInsertRequestsParams.push([pnLastSeq, poViewInfo.viewName]);

		await this.isvcSql.runTransactionAsync(
			poDataSource,
			laInsertRequestsString,
			laInsertRequestsParams,
			poDataSource.databaseId
		);
	}

	protected getIndexorDatabaseId(poParams: IOpenDatabaseParams): string {
		return `${poParams.databaseId}-${IdHelper.getGuidFromId(poParams.designDoc._id, EPrefix.design)}-${
			poParams.viewName
		}`.replace(Indexor.C_DATABASE_ID_REPLACEMENT_REGEXP, "-");
	}

	private async initIndexorViewAsync(
		poDataSource: SqlDataSource,
		poDesignDoc: IDesignDocument,
		psViewName: string
	): Promise<void> {
		const lsView: string | undefined = poDesignDoc.views?.[psViewName]?.map;
		if (!StringHelper.isBlank(lsView)) {
			const loInfo: IIndexorViewInfo = { viewName: psViewName, view: lsView };
			const laRequests: IBatchedRequest<IIndexorViewInfo>[] = SqlRequestHelper.getInsertRequest<IIndexorViewInfo>(
				Indexor.C_VIEW_INFO_TABLE_NAME,
				["viewName", "view"],
				[loInfo]
			);

			for (let lnIndex = 0; lnIndex < laRequests.length; ++lnIndex) {
				const loRequest: IBatchedRequest<IIndexorViewInfo> = laRequests[lnIndex];

				await this.isvcSql.requestAsync(
					poDataSource,
					loRequest.request,
					[loInfo.viewName, loInfo.view],
					poDataSource.databaseId
				);
			}
		}
	}

	private getViewState(poDesignDoc: IDesignDocument, poViewInfo?: IIndexorViewInfo): EIndexorViewState {
		if (!poViewInfo) return EIndexorViewState.notInitialized;
		else if (poViewInfo.view === poDesignDoc.views?.[poViewInfo.viewName]?.map) return EIndexorViewState.ok;
		return EIndexorViewState.bad;
	}

	private async getViewInfoAsync(
		poDataSource: SqlDataSource,
		psViewName: string
	): Promise<IIndexorViewInfo | undefined> {
		const loResponse: SqlRequestResult<IIndexorViewInfo> = await this.isvcSql.requestAsync<IIndexorViewInfo>(
			poDataSource,
			SqlRequestHelper.getBy<IIndexorViewInfo>(Indexor.C_VIEW_INFO_TABLE_NAME, "viewName", psViewName),
			[psViewName],
			poDataSource.databaseId
		);

		return ArrayHelper.getFirstElement(loResponse.results);
	}

	private async createTablesAsync(poDataSource: SqlDataSource): Promise<void> {
		const laRequests: string[] = [
			SqlRequestHelper.getCreateTableRequest<IIndexorViewRow>(
				Indexor.C_VIEW_DATA_TABLE_NAME,
				["key", "id"],
				[
					{ key: "key", value: "TEXT" },
					{ key: "id", value: "TEXT" },
					{ key: "rev", value: "TEXT" },
					{ key: "value", value: "TEXT" }
				]
			),
			SqlRequestHelper.getCreateIndexRequest<IIndexorViewRow>(
				Indexor.C_VIEW_DATA_ID_INDEX_NAME,
				Indexor.C_VIEW_DATA_TABLE_NAME,
				["id"]
			),
			SqlRequestHelper.getCreateIndexRequest<IIndexorViewRow>(
				Indexor.C_VIEW_DATA_KEY_INDEX_NAME,
				Indexor.C_VIEW_DATA_TABLE_NAME,
				["key"]
			),
			SqlRequestHelper.getCreateTableRequest<IIndexorViewInfo>(
				Indexor.C_VIEW_INFO_TABLE_NAME,
				["viewName"],
				[
					{ key: "viewName", value: "TEXT" },
					{ key: "view", value: "TEXT" },
					{ key: "seq", value: "NUMBER" }
				]
			)
		];

		await this.isvcSql.runTransactionAsync(poDataSource, laRequests, [], poDataSource.databaseId);
	}

	private openDatabaseAsync(poDataSource: SqlDataSource): Promise<void> {
		return this.isvcSql.openDatabaseAsync(poDataSource);
	}

	protected getSqlDataSource(poParams: IOpenDatabaseParams): SqlDataSource {
		const lsIndexorDatabaseId: string = this.getIndexorDatabaseId(poParams);

		return new SqlDataSource(
			lsIndexorDatabaseId,
			Indexor.C_DEFAULT_DATABASE_VERSION,
			SqlFilesHelper.getFileName(lsIndexorDatabaseId, Indexor.C_DEFAULT_DATABASE_VERSION),
			true
		);
	}

	/** Requête un index.
	 * @param poParams
	 */
	public async requestAsync(poParams: IRequestParams): Promise<IIndexorViewRow[]> {
		const loPerf = new PerformanceManager().markStart();

		let laResults: IIndexorViewRow[] | undefined;
		const loDataSource: SqlDataSource = await this.getSqlDataSourceAsync(poParams);

		if (ObjectHelper.isDefined(poParams.viewParams.key))
			laResults = await this.requestByKeyAsync(loDataSource, poParams.viewParams.key);
		else if (ArrayHelper.hasElements(poParams.viewParams.keys))
			laResults = await this.requestByKeysAsync(poParams.viewParams.keys, loDataSource);
		else if (ObjectHelper.isDefined(poParams.viewParams.startkey) || ObjectHelper.isDefined(poParams.viewParams.endkey))
			laResults = await this.requestByRangeAsync(poParams.viewParams, loDataSource);

		console.debug(
			`${Indexor.C_LOG_ID}Requested index ${loDataSource.databaseId} in ${loPerf.markEnd().measure()}ms.`,
			"params:",
			poParams,
			"results:",
			laResults
		);

		return laResults ?? [];
	}

	/** Ferme l'accès à un index.
	 * @param psDatabaseId
	 * @param poDesignDoc
	 * @param psViewName
	 */
	public closeAsync(psDatabaseId: string, poDesignDoc: IDesignDocument, psViewName: string): Promise<void> {
		const lsDatabaseId: string = this.getIndexorDatabaseId({
			databaseId: psDatabaseId,
			viewName: psViewName,
			designDoc: poDesignDoc
		});

		const loSubject: ReplaySubject<SqlDataSource> | undefined =
			this.moIndexorDataSourceSubjectByDatabaseId.get(lsDatabaseId);
		if (loSubject) {
			loSubject.complete();
			this.moIndexorDataSourceSubjectByDatabaseId.delete(lsDatabaseId);
		}
		return this.isvcSql.closeDatabaseAsync(lsDatabaseId);
	}

	/** Supprime un index.
	 * @param psDatabaseId
	 * @param poDesignDoc
	 * @param psViewName
	 */
	public removeAsync(psDatabaseId: string, poDesignDoc: IDesignDocument, psViewName: string): Promise<void> {
		const loSubject: ReplaySubject<SqlDataSource> | undefined =
			this.moIndexorDataSourceSubjectByDatabaseId.get(psDatabaseId);
		if (loSubject) {
			loSubject.complete();
			this.moIndexorDataSourceSubjectByDatabaseId.delete(psDatabaseId);
		}
		return this.isvcSql.removeDatabaseAsync(
			this.getIndexorDatabaseId({
				databaseId: psDatabaseId,
				viewName: psViewName,
				designDoc: poDesignDoc
			})
		);
	}

	private async requestByKeyAsync(
		poDataSource: SqlDataSource,
		poKey: string | number | string[]
	): Promise<IIndexorViewRow[]> {
		const laKeyResults: IIndexorViewRow[] = [];
		const loResponse: SqlRequestResult<IIndexorViewRow> = await this.isvcSql.requestAsync<IIndexorViewRow>(
			poDataSource,
			SqlRequestHelper.getBy<IIndexorViewRow>(Indexor.C_VIEW_DATA_TABLE_NAME, "key", ""),
			[this.prepareKey(poKey)],
			poDataSource.databaseId
		);

		loResponse.results.forEach((poResult: IIndexorViewRow) => laKeyResults.push(this.prepareViewData(poResult)));

		return laKeyResults;
	}

	private async requestByKeysAsync(
		paKeys: (string | number | string[])[],
		poDataSource: SqlDataSource
	): Promise<IIndexorViewRow[]> {
		const laKeysResults: IIndexorViewRow[] = [];
		const laKeys: string[] = paKeys.map(this.prepareKey);

		const laRequests: IBatchedRequest<string | number>[] = SqlRequestHelper.getByKeys<IIndexorViewRow>(
			Indexor.C_VIEW_DATA_TABLE_NAME,
			"key",
			laKeys
		);

		const laStringRequests: string[] = [];
		const laBatchedParams: (string | number)[][] = [];

		laRequests.forEach((poRequest: IBatchedRequest<string | number>) => {
			laStringRequests.push(poRequest.request);
			laBatchedParams.push(poRequest.values);
		});

		const laResponses: SqlRequestResult<IIndexorViewRow>[] = await this.isvcSql.runTransactionAsync<IIndexorViewRow>(
			poDataSource,
			laStringRequests,
			laBatchedParams,
			poDataSource.databaseId
		);

		laResponses.forEach((poResponse: SqlRequestResult<IIndexorViewRow>) =>
			poResponse.results.forEach((poResult: IIndexorViewRow) => laKeysResults.push(this.prepareViewData(poResult)))
		);

		return laKeysResults;
	}

	private async requestByRangeAsync(
		poViewParams: IDataSourceViewParams,
		poDataSource: SqlDataSource
	): Promise<IIndexorViewRow[]> {
		const laRangeResults: IIndexorViewRow[] = [];
		const lsPreparedStartKey: string = this.prepareKey(poViewParams.startkey);
		const lsPreparedEndKey: string = this.prepareKey(poViewParams.endkey);
		const lsRequest: string = SqlRequestHelper.getByRange<IIndexorViewRow>(
			Indexor.C_VIEW_DATA_TABLE_NAME,
			{ from: lsPreparedStartKey, to: lsPreparedEndKey },
			"key"
		);
		const laParams: TRequestParam[] = [];

		if (ObjectHelper.isDefined(poViewParams.startkey)) laParams.push(lsPreparedStartKey);

		if (ObjectHelper.isDefined(poViewParams.endkey)) laParams.push(this.prepareEndKey(lsPreparedEndKey));

		const loResponse: SqlRequestResult<IIndexorViewRow> = await this.isvcSql.requestAsync<IIndexorViewRow>(
			poDataSource,
			lsRequest,
			laParams,
			poDataSource.databaseId
		);

		loResponse.results.forEach((poResult: IIndexorViewRow) => laRangeResults.push(this.prepareViewData(poResult)));

		return laRangeResults;
	}

	private prepareViewData(poResult: IIndexorViewRow): IIndexorViewRow {
		return {
			id: poResult.id,
			value: this.parseValue(poResult.value),
			key: this.parseKey(poResult.key),
			rev: poResult.rev
		};
	}

	private prepareKey(poKey?: string | string[] | number): string {
		if (poKey instanceof Array)
			// On découpe la partie définie du tableau
			return poKey.filter((psKeyPart: string) => !StringHelper.isBlank(psKeyPart)).join();
		else if (typeof poKey === "string") return poKey;
		return poKey?.toString() ?? "";
	}

	private prepareEndKey(psKey: string): string {
		return `${psKey}\ufff0`;
	}

	private parseKey(psKey: string): string | (string | number)[] | number {
		if (psKey.includes(","))
			// On est dans le cas d'un tableau sérialisé.
			return psKey.split(",").map((psSubKey: string) => this.parseKey(psSubKey) as string | number);
		else if (NumberHelper.isStringNumber(psKey)) return +psKey;
		return psKey;
	}

	private parseValue(psValue: string): any {
		try {
			return JSON.parse(psValue);
		} catch {
			return psValue;
		}
	}

	private prepareValue(poValue?: any): string {
		if (typeof poValue !== "string") return JSON.stringify(poValue);
		return poValue;
	}

	/** Ferme tous les index ouverts. */
	public async closeAllAsync(): Promise<void> {
		for (const loMapIterator of this.moIndexorDataSourceSubjectByDatabaseId) {
			loMapIterator[1].complete();
			this.moIndexorDataSourceSubjectByDatabaseId.delete(loMapIterator[0]);

			await this.isvcSql.closeDatabaseAsync(loMapIterator[0]);
		}
	}

	//#endregion METHODS
}
