import { Injectable } from '@angular/core';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { Observable, defer, of } from 'rxjs';
import { catchError, mapTo, mergeMap, tap } from 'rxjs/operators';
import { FileHelper } from '../helpers/fileHelper';
import { MaximumFileSizeError } from '../modules/dms/model/maximum-file-size-error';
import { Loader } from '../modules/loading/Loader';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { LoadingService } from './loading.service';
import { UiMessageService } from './uiMessage.service';

@Injectable({
	providedIn: 'root'
})
export class FileService {

	//#region FIELDS

	private readonly mnDmsMaxSizeKb?: number = ConfigData.environment.dms.maxDocumentSizeKb;

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcLoading: LoadingService
	) { }

	/** Fait des traitements / vérifications sur le fichier.
	 * @param poInitialFile Le fichier d'origine.
	 * @param pnMaxSizeKb Taille maximale du fichier.
	 */
	public processFile$(poInitialFile: File, pnMaxSizeKb?: number): Observable<File | undefined> {
		return defer(() => poInitialFile.type.indexOf("image") >= 0 ? this.reduceImageIfNeeded$(poInitialFile, pnMaxSizeKb) : of(poInitialFile)).pipe(
			tap((poFile: File) => this.checkFileSize(poFile)),
			catchError((poError) => {
				this.displayError(poError);
				return of(undefined);
			})
		);
	}

	private reduceImageIfNeeded$(poFile: File, pnMaxSizeKb: number = 0): Observable<File> {
		const lnMaxSizeKb: number = this.mnDmsMaxSizeKb && this.mnDmsMaxSizeKb < pnMaxSizeKb ? this.mnDmsMaxSizeKb : pnMaxSizeKb;

		if (lnMaxSizeKb && lnMaxSizeKb < (poFile.size / 1000))
			return this.reduceImage$(poFile, lnMaxSizeKb);
		else
			return of(poFile);
	}

	private reduceImage$(poFile: File, pnMaxSizeKb: number): Observable<File> {
		let loLoader: Loader;

		return defer(() => this.isvcLoading.create({ message: "Compression de l'image en cours" })).pipe(
			mergeMap((poLoader: Loader) => {
				loLoader = poLoader;
				return loLoader.present();
			}),
			mergeMap(() => FileHelper.reduceImage(poFile, pnMaxSizeKb)),
			mergeMap((poReducedFile: File) => defer(() => loLoader.dismiss()).pipe(mapTo(poReducedFile)))
		);
	}

	private checkFileSize(poFile: File): void {
		if (this.mnDmsMaxSizeKb < (poFile.size / 1000))
			throw new MaximumFileSizeError(poFile.name);
	}

	private displayError(poError: any): void {
		let lsTitle = "Erreur";
		let lsMessage = "Une erreur est survenue lors de l'ajout de votre fichier.";

		if (poError instanceof MaximumFileSizeError) {
			lsTitle = "Fichier trop volumineux";
			lsMessage = poError.message;
		}

		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
			header: lsTitle,
			message: lsMessage,
			buttons: [UiMessageService.createOkButton()],
		}));
	}

	//#endregion

}
