import { Component, OnInit } from '@angular/core';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IStatTileHeaderComponentParams } from '../models/istat-tile-header-component-params';

@Component({
	selector: 'calao-stat-tile-header',
	templateUrl: './stat-tile-header.component.html',
	styleUrls: ['./stat-tile-header.component.scss'],
})
export class StatTileHeaderComponent extends FieldBase implements OnInit {

	//#region PROPERTIES

	/** Configuration du composant. */
	public readonly obsProps = new ObservableProperty<IStatTileHeaderComponentParams>();

	//#endregion PROPERTIES

	//#region METHODS

	public constructor(poForms: FormsService) {
		super(poForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.obsProps.value = this.props.data as IStatTileHeaderComponentParams;
	}

	//#endregion METHODS


}
