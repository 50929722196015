import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FileTransfer, FileTransferError, FileTransferObject } from "@awesome-cordova-plugins/file-transfer/ngx";
import { PerformanceManager } from "@calaosoft/osapp-common/performance/PerformanceManager";
import { EUpdateStatus } from "@calaosoft/osapp-common/sqlite/models/eupdate-status";
import { UpdateEvent } from "@calaosoft/osapp-common/sqlite/models/update-event";
import { TTransferHeaders } from "@calaosoft/osapp-common/transfert/models/ttransfer-headers";
import { OsappError } from "@calaosoft/osapp-common/utils/errors/OsappError";
import { NumberHelper } from "@calaosoft/osapp-common/utils/helpers/numberHelper";
import { Observable, Observer } from "rxjs";
import { last, map, mapTo, scan, tap } from "rxjs/operators";
import { FilesystemRenameError } from "../../filesystem/models/errors/filesystem-rename-error";
import { FilesystemService } from "../../filesystem/services/filesystem.service";
import { ITransfertParameters } from "../models/Itransfert-parameters";
import { ITransfertProgress } from "../models/itransfert-progress";

@Injectable()
export class TransfertService {
	//#region FIELDS

	private static readonly C_LOG_ID = "TF.S::";
	private static readonly C_DEFAULT_TIMEOUT_MS = 30000;
	private static readonly C_TMP_EXTENSION = ".tmp";

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioFileTransfer: FileTransfer,
		/** Service de gestion des requêtes http. */
		private readonly ioHttpClient: HttpClient,
		private readonly isvcFilesystem: FilesystemService
	) {}

	/** Télécharge un fichier.
	 * @param psDownloadUrl Url du fichier.
	 * @param psTargetUrl Chemin de destination.
	 * @param poHeaders Headers utilisé pour récupérer le fichier.
	 * @param pfOnProgress Callback appelée lors de l'avancement de téléchargement.
	 * @param pnTimeout Durée du timeout en ms.
	 */
	public async download(
		psDownloadUrl: string,
		psTargetUrl: string,
		poHeaders: TTransferHeaders,
		pfOnProgress?: (poEvent: ProgressEvent) => void,
		pnTimeout: number = TransfertService.C_DEFAULT_TIMEOUT_MS
	): Promise<void> {
		if (!FileTransfer.installed()) return Promise.reject(new OsappError("Plugin FileTransfer non installé."));

		return this.downloadAndSave$(psDownloadUrl, psTargetUrl, poHeaders, pfOnProgress, pnTimeout)
			.pipe(last(), mapTo(undefined))
			.toPromise();
	}

	/** Télécharge un fichier et l'enregistre sur disque.
	 * @param psDownloadUrl Url du fichier.
	 * @param psTargetUrl Chemin de destination.
	 * @param poHeaders Headers utilisé pour récupérer le fichier.
	 * @param pfOnProgress Callback appelée lors de l'avancement de téléchargement.
	 * @param pnTimeout Durée du timeout en ms.
	 * @returns La progression de téléchargement.
	 * @throws
	 * - `FileTransferError`
	 * - `FilesystemRenameError`
	 */
	public downloadAndSave$(
		psDownloadUrl: string,
		psTargetUrl: string,
		poHeaders: TTransferHeaders,
		pfOnProgress?: (poEvent: ProgressEvent) => void,
		pnTimeout?: number
	): Observable<UpdateEvent> {
		return new Observable((poObserver: Observer<UpdateEvent>) => {
			const loFileTransferObject: FileTransferObject = this.ioFileTransfer.create();
			let lnTimeout: number;

			loFileTransferObject.onProgress((poProgressEvent: ProgressEvent<EventTarget>) => {
				const lnProgress: number = Math.round((poProgressEvent.loaded / poProgressEvent.total) * 100);
				poObserver.next(new UpdateEvent(EUpdateStatus.downloading, lnProgress));

				this.clearTimeout(lnTimeout);
				lnTimeout = this.createTimeout(pnTimeout, loFileTransferObject);

				if (pfOnProgress) pfOnProgress(poProgressEvent);
			});

			this.execDownloadAndSaveAsync(psDownloadUrl, psTargetUrl, poHeaders, loFileTransferObject)
				.then(() => {
					poObserver.next(new UpdateEvent(EUpdateStatus.saved, 100));
					poObserver.complete();
				})
				.catch((poError) => poObserver.error(poError))
				.finally(() => this.clearTimeout(lnTimeout));
		});
	}

	private clearTimeout(pnTimeout: number): void {
		if (NumberHelper.isValid(pnTimeout)) window.clearTimeout(pnTimeout);
	}

	private createTimeout(pnTimeout: number, poFileTransfer: FileTransferObject): number {
		if (NumberHelper.isValid(pnTimeout)) return window.setTimeout(() => poFileTransfer.abort(), pnTimeout);
		else return NaN;
	}

	private execDownloadAndSaveAsync(
		psDownloadUrl: string,
		psTargetUrl: string,
		poHeaders: TTransferHeaders,
		poFileTransferObject: FileTransferObject
	): Promise<void> {
		const lsTmpTargetUrl = `${
			psTargetUrl.endsWith("/") ? psTargetUrl.substring(0, psTargetUrl.length - 1) : psTargetUrl
		}${TransfertService.C_TMP_EXTENSION}`;
		const loPerfManager = new PerformanceManager().markStart();

		return poFileTransferObject
			.download(psDownloadUrl, lsTmpTargetUrl, true, { headers: poHeaders })
			.then(() => {
				console.debug(
					`${
						TransfertService.C_LOG_ID
					}Download file from '${psDownloadUrl}' to '${lsTmpTargetUrl}' succeeded in ${loPerfManager
						.markEnd()
						.measure()}ms.`
				);
				return this.isvcFilesystem.renameAsync(lsTmpTargetUrl, psTargetUrl);
			})
			.then(() =>
				console.debug(`${TransfertService.C_LOG_ID}Rename file from '${lsTmpTargetUrl}' to '${psTargetUrl}' succeeded.`)
			)
			.catch((poError: FileTransferError | FilesystemRenameError) => {
				console.error(
					`${
						TransfertService.C_LOG_ID
					}Download file from '${psDownloadUrl}' to '${psTargetUrl}' failed in ${loPerfManager.markEnd().measure()}ms.`,
					poError
				);

				// Peu importe le résultat du renommage, on relève l'erreur de l'échec de téléchargement.
				return this.isvcFilesystem
					.removeFileAsync(lsTmpTargetUrl)
					.catch((_) => {
						throw poError;
					})
					.then(() => {
						throw poError;
					});
			});
	}

	/** Télécharge et retourne un fichier.
	 * @param poParameters Les paramètres utiles pour télécharger le fichier.
	 */
	public downloadFile$(poParameters: ITransfertParameters): Observable<Blob> {
		const loRequest$: Observable<HttpEvent<Blob>> = this.ioHttpClient.get(poParameters.fileUrl, {
			headers: poParameters.headers,
			observe: "events",
			responseType: "blob",
			reportProgress: true
		}) as Observable<HttpEvent<Blob>>;

		return this.transfertProgress$(loRequest$, poParameters);
	}

	/** Téléverse un fichier.
	 * @param poParameters Les paramètres utiles pour téléverser le fichier.
	 * @returns Le guid du fichier téléversé.
	 */
	public upload$(poParameters: ITransfertParameters): Observable<string> {
		const loRequest$: Observable<HttpEvent<JSON>> = this.ioHttpClient.post(poParameters.fileUrl, poParameters.body, {
			headers: poParameters.headers,
			observe: "events",
			responseType: "json",
			reportProgress: true,
			params: poParameters.parameters
		}) as Observable<HttpEvent<JSON>>;

		return this.transfertProgress$<string>(loRequest$, poParameters);
	}

	/** Retourne un résultat de progression de téléversement mis à jour à partir du nouvel événement http reçu.
	 * @param poPreviousResult Résultat précédent de la progression du téléversement.
	 * @param poEvent Événement de la requête http.
	 * @param pnFileSize Taille du fichier à téléverser.
	 */
	private getTransfertProgress<U>(
		poPreviousResult: ITransfertProgress<U>,
		poEvent: HttpEvent<U>,
		pnFileSize: number
	): ITransfertProgress<U> {
		if (poEvent.type === HttpEventType.Response) return { progress: 100, content: poEvent.body };
		else if (poEvent.type === HttpEventType.DownloadProgress || poEvent.type === HttpEventType.UploadProgress) {
			const lnProgressValue: number = poEvent.loaded / (poEvent.total ?? pnFileSize);

			if (NumberHelper.isValid(lnProgressValue)) return { progress: Math.round(100 * lnProgressValue) };
		}

		return poPreviousResult;
	}

	/** Télécharge le fichier et met à jour la barre de progression à chaque étape.
	 * @param poRequest$ La requête pour télécharger un fichier.
	 * @param poParameters Les paramètres utiles pour télécharger le fichier.
	 * @returns Le fichier téléchargé.
	 */
	private transfertProgress$<T = Blob>(
		poRequest$: Observable<HttpEvent<T>>,
		poParameters: ITransfertParameters
	): Observable<T>;
	/** Téléverse le fichier et met à jour la barre de progression à chaque étape.
	 * @param poRequest$ La requête pour téléverser un fichier.
	 * @param poParameters Les paramètres utiles pour téléverser le fichier.
	 * @returns Le guid du fichier téléversé.
	 */
	private transfertProgress$<T = string, U = JSON>(
		poRequest$: Observable<HttpEvent<U>>,
		poParameters: ITransfertParameters
	): Observable<T>;
	private transfertProgress$<T = Blob | string, U = JSON | Blob>(
		poRequest$: Observable<HttpEvent<U>>,
		poParameters: ITransfertParameters
	): Observable<T> {
		return poRequest$.pipe(
			scan(
				(poPrevious: ITransfertProgress<U>, poEvent: HttpEvent<U>): ITransfertProgress<U> =>
					this.getTransfertProgress(poPrevious, poEvent, poParameters.fileSize),
				{ progress: 0, content: undefined } // Valeur initiale
			),
			tap((poUploadProgress: ITransfertProgress<U>) => {
				// Si une callback a été passée en paramètre on lui passe le pourcentage de progression du transfert.
				if (poParameters.onProgress) poParameters.onProgress(poUploadProgress);
			}),
			last(),
			map((poUploadProgress: ITransfertProgress<U>): T => poUploadProgress.content as unknown as T)
		);
	}

	//#endregion
}
