import { IConstructor } from "../models/iconstructor";
import { ModelResolver } from "../models/model-resolver";

/** Permet d'indiquer quel type de données json correspondent à un type.
 * @param pfMatch Fonction qui indique quel type de json correspond à cette classe.
 * @param poBaseType Type de base, utile pour la classification des types.
 */
export function ModelMatch<T, V extends T>(pfMatch: (poData: V) => boolean, poBaseType: IConstructor<T>): ClassDecorator {
	return function (poConstructor: any) {
		ModelResolver.addClass(pfMatch, poConstructor, poBaseType);

		return poConstructor;
	};
}
