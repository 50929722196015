import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { plainToClass } from '@calaosoft/osapp-common/class-transformer';
import { StoreHelper } from '@calaosoft/osapp-common/store/helpers/store-helper';
import { IDataSource } from '@calaosoft/osapp-common/store/models/IDataSource';
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { ESuffix } from '@calaosoft/osapp-common/utils/models/ESuffix';
import { Observable, ReplaySubject } from 'rxjs';
import { map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AvatarHelper } from '../../../helpers/avatarHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EAvatarSize } from '../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../model/picture/IAvatar';
import { ERouteUrlPart } from '../../../model/route/ERouteUrlPart';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { EntityLinkService } from '../../../services/entityLink.service';
import { FlagService } from '../../../services/flag.service';
import { Store } from '../../../services/store.service';
import { Contact } from '../../contacts/models/contact';
import { EPermissionsFlag } from '../../permissions/models/EPermissionsFlag';
import { ISector } from '../../sectors/models/isector';
import { SectorsService } from '../../sectors/services/sectors.service';
import { ISite } from '../models/isite';
import { Site } from '../models/site';

@Injectable()
export class SitesService implements OnDestroy {

	//#region FIELDS

	private static readonly C_DEFAULT_SITE_ICON = "site";

	/** Identifiant du descripteur de formulaire par défaut pour un site. */
	public static C_DEFAULT_SITES_FORMDESC_ID = "formDesc_sites";
	public static C_SITES_EDIT_FORMDEF_ID = `site${ESuffix.edit}`;

	private readonly moUserSitesSubject = new ReplaySubject<Site[]>(1);

	//#endregion

	//#region PROPERTIES

	/** Observable de la liste des sites de l'utilisateur avec cache. */
	public get userSites$(): Observable<Site[]> {
		return this.moUserSitesSubject.asObservable();
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcSectors: SectorsService,
		private readonly ioRouter: Router,
		private readonly isvcEntityLink: EntityLinkService,
		psvcFlags: FlagService
	) {
		psvcFlags.waitForFlag(EPermissionsFlag.isLoaded, true).pipe(
			mergeMap(() => this.getUserSites(UserHelper.getUserContactId(), true)),
			tap((paSites: Site[]) => this.moUserSitesSubject.next(paSites))
		)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this.moUserSitesSubject.complete();
	}

	/** Récupère tous les sites.
	 * @param pbLive
	 */
	public getSites(pbLive?: boolean): Observable<Site[]> {
		const loDatasource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: EPrefix.site,
				endkey: `${EPrefix.site}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
			live: pbLive,
			baseClass: Site
		};

		return this.isvcStore.get<Site>(loDatasource);
	}

	/** Récupère les sites filtrés par identifiants.
	 * @param paIds
	 * @param pbLive
	 */
	public getSitesFromIds(paIds: string[], pbLive?: boolean): Observable<Site[]> {
		const loDatasource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				keys: paIds,
				include_docs: true
			},
			live: pbLive,
			baseClass: Site
		};

		return this.isvcStore.get<Site>(loDatasource);
	}

	/** Récupère le site selon l'identifiant.
	 * @param psId
	 */
	public getSite(psId: string): Observable<Site> {
		const loDatasource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psId,
				include_docs: true
			}
		};

		return this.isvcStore.getOne<ISite>(loDatasource).pipe(map((paSites: ISite) => plainToClass(Site, paSites)));
	}

	/** Retourne les sites d'un tableau de contacts.
	 * @param paContacts
	 * @param pbLive
	 * @returns
	 */
	public getContactsSites(paContacts: Contact[], pbLive?: boolean): Observable<Map<string, Site[]>> {
		return this.isvcEntityLink.getLinkedEntities<Site>(paContacts, EPrefix.site, undefined, pbLive);
	}

	/** Récupère les identifiants des sites de l'utilisateur.
	 * @param psUserContactId
	 * @param pbLive
	 */
	public getUserSiteIds(psUserContactId: string = UserHelper.getUserContactId(), pbLive?: boolean): Observable<string[]> {
		return this.isvcSectors.getUserSectors(psUserContactId, pbLive).pipe(
			map((paSectors: ISector[]) => ArrayHelper.unique(paSectors.map((poSector: ISector) =>
				StringHelper.isBlank(poSector.siteId) ? undefined : poSector.siteId
			)))
		);
	}

	/** Récupère les sites de l'utilisateur.
	 * @param psUserContactId
	 * @param pbLive
	 */
	public getUserSites(psUserContactId: string = UserHelper.getUserContactId(), pbLive?: boolean): Observable<Site[]> {
		return this.getUserSiteIds(psUserContactId, pbLive).pipe(
			switchMap((paSiteIds: string[]) => this.getSitesFromIds(paSiteIds, pbLive))
		);
	}

	/** Sauvegarde un site.
	 * @param poSite
	 */
	public saveSite(poSite: Site): Observable<Site> {
		const lsDatabaseId: string = StoreHelper.hasCacheData(poSite) ? StoreHelper.getDatabaseIdFromCacheData(poSite) :
			ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));

		return this.isvcStore.put(new Site(poSite), lsDatabaseId)
			.pipe(
				mergeMap(() => this.isvcEntityLink.saveEntityLinks(poSite)),
				mapTo(poSite)
			);
	}

	/** Supprime un site.
	 * @param poSite
	 */
	public deleteSite(poSite: Site): Observable<boolean> {
		return this.isvcStore.delete(poSite).pipe(map((poStoreDataResponse: IStoreDataResponse) => poStoreDataResponse.ok));
	}

	/** Retourne les secteurs appartenant au site
	 * @param poSiteId
	 */
	public getSiteSectors(poSiteId: string, pbLive?: boolean) {
		return this.isvcSectors.getSectors(pbLive)
			.pipe(
				map((paSectors: ISector[]) => paSectors?.filter((poSector: ISector) => poSector.siteId === poSiteId))
			);
	}

	/** Route vers la création d'un site. */
	public routeToSiteCreation(pbSetToDefaultSite?: boolean): Promise<boolean> {
		return this.ioRouter.navigate(["sites", ERouteUrlPart.new], pbSetToDefaultSite ? { queryParams: { setToDefaultSite: pbSetToDefaultSite } } : {});
	}

	/** Route vers l'édition d'un site.
	 * @param poSite
	 */
	public routeToSiteEdition(poSite: ISite): Promise<boolean> {
		return this.ioRouter.navigate(["sites", poSite._id, ERouteUrlPart.edit]);
	}

	/** Route vers un site.
	 * @param poSite
	 */
	public routeToSite(poSite: ISite): Promise<boolean> {
		return this.ioRouter.navigate(["sites", poSite._id]);
	}

	/** Construit un avatar à partir d'un site.
	 * @param poSite
	 * @param peAvatarSize
	 */
	public static createSiteAvatar(poSite: ISite, peAvatarSize: EAvatarSize = EAvatarSize.big): IAvatar {
		if (poSite.picture && (poSite.picture.base64 || poSite.picture.guid || poSite.picture.url))
			return { ...AvatarHelper.createAvatarFromPicture(poSite.picture, peAvatarSize), icon: SitesService.C_DEFAULT_SITE_ICON };
		else
			return AvatarHelper.createAvatarFromIcon(SitesService.C_DEFAULT_SITE_ICON, peAvatarSize);
	}

	//#endregion

}
