import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { IRange } from '@calaosoft/osapp-common/utils/models/irange';
import { IConstraintLabelParams } from "./iconstraint-label-params";
import { IEventOccurrence } from "./ievent-occurrence";
import { IEventOccurrenceAction } from "./ievent-occurrence-action";

export abstract class EventOccurrenceAction implements IEventOccurrenceAction {

	//#region PROPERTIES

	public abstract readonly type: string;

	//#endregion

	//#region METHODS

	constructor(poAction?: Partial<IEventOccurrenceAction>) {
		if (poAction)
			ObjectHelper.assign(this, poAction);
	}

	/** Action à effectuer sur l'occurrence.
	 * @param poEventOccurrence
	 */
	public abstract action(poEventOccurrence: IEventOccurrence): void;

	/** Construit la description de la contrainte. */
	public abstract buildlabel(poParams?: IConstraintLabelParams): string;

	public actionOnRange(poRange: IRange<Date>): IRange<Date> {
		return poRange;
	}

	//#endregion
}
