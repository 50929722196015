import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Folder } from '../../models/folder';

@Component({
	selector: 'calao-folder-item',
	templateUrl: './folder-item.component.html',
	styleUrls: ['./folder-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderItemComponent {

	//#region FIELDS

	@Output("onOpenFolderClicked") private readonly moOpenFolderClickedEvent = new EventEmitter<Folder>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Dossier. */
	@Input() public folder: Folder;
	@ObserveProperty<FolderItemComponent>({ sourcePropertyKey: "folder" })
	public readonly observableDocument = new ObservableProperty<Folder>();

	//#endregion

	//#region METHODS

	constructor() { }

	public openFolder(poFolder: Folder): void {
		this.moOpenFolderClickedEvent.next(poFolder);
	}

	//#endregion METHODS

}
