import { Pipe, PipeTransform } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { ContactHelper } from '../helpers/contactHelper';
import { IContact } from '../model/contacts/IContact';

@Pipe({ name: 'groupMemberNamesEllipsis' })
export class GroupMemberNamesEllipsisPipe implements PipeTransform {

	//#region METHODS

	constructor() { }

	/** Transforme la valeur reçue pour l'afficher.
	 * @param paMembers Tableau des membres du groupe.
	 */
	public transform(paMembers: IContact[]): string {
		let lsGroupMemberNames: string;

		if (!ArrayHelper.hasElements(paMembers)) // Si aucun élément.
			lsGroupMemberNames = "Aucun membre";
		else { // Sinon, au moins un membre de présent.
			lsGroupMemberNames = ContactHelper.getCompleteFormattedName(ArrayHelper.getFirstElement(paMembers));

			if (paMembers.length > 1) // Si au moins 2 membres appartiennent à ce groupe, on affiche le nom des deux, concaténés.
				lsGroupMemberNames += `, ${ContactHelper.getCompleteFormattedName(paMembers[1])}`;

			if (paMembers.length > 2) {
				const lnOtherMembersCount: number = paMembers.length - 2;
				lsGroupMemberNames += ` +${lnOtherMembersCount} autre${lnOtherMembersCount > 1 ? 's.' : '.'}`;
			}
		}

		return lsGroupMemberNames;
	}

	//#endregion
}