import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { mapTo } from 'rxjs/operators';
import { WorkspaceService } from '../../../services/workspace.service';
import { AuthenticatedRequestOptionBuilder } from '../../api/models/authenticated-request-option-builder';

@Injectable()
export class CalendarShareService {

	//#region METHODS

	constructor(
		private readonly ioHttp: HttpClient,
		private readonly isvcWorkspace: WorkspaceService,
	) { }

	public async getCalendarSharedTokenAsync(): Promise<string | undefined> {
		try {
			const lsToken: string = await this.ioHttp.get<string>(`${this.getBaseUrl()}share`, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).toPromise();
			return lsToken;
		}
		catch (poError) {
			if (poError.status === 404)
				return undefined;
			else
				throw poError;
		}
	}

	public shareCalendarAsync(): Promise<string> {
		return this.ioHttp.post<string>(`${this.getBaseUrl()}share`, undefined, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).toPromise();
	}

	public deleteCalendarSharedTokenAsync(): Promise<void> {
		return this.ioHttp.delete(`${this.getBaseUrl()}share`, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).pipe(mapTo(undefined)).toPromise();
	}

	public getCalendarUrl(psToken: string): string {
		return `${ConfigData.environment.cloud_url}/share/${ConfigData.appInfo.appId}/calendars/${psToken}`;
	}

	private getBaseUrl(): string {
		return `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${this.isvcWorkspace.getCurrentWorkspaceId()}/calendars/`;
	}

	//#endregion METHODS

}
