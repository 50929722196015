import { Injectable } from '@angular/core';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { IDataSource } from '@calaosoft/osapp-common/store/models/IDataSource';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { ESortOrder } from '@calaosoft/osapp-common/utils/models/ESortOrder';
import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { UserHelper } from '../../../helpers/user.helper';
import { UserData } from '../../../model/application/UserData';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { Store } from '../../../services/store.service';
import { IDataSourceRemoteChanges } from '../../store/model/IDataSourceRemoteChanges';
import { IGetUsersTourParams } from '../models/IGetUsersTourParams';
import { IPreviousNextTourId } from '../models/IPreviousNextTourId';
import { ITour } from '../models/ITour';
import { ITourAppointData } from '../models/ITourAppointData';

@Injectable({ providedIn: "root" })
export class TourService {

	//#region FIELDS

	public static readonly C_TOUR_PREFIX = "tour_" as EPrefix;
	private static readonly C_LOG_ID = "TOUR.S::"

	//#endregion

	//#region PROPERTIES

	//#endregion

	//#region METHODS

	public constructor(private isvcStore: Store) { }

	/** Récupère un objet tournée à partir d'un identifiant de tournée, `undefined` si la tournée n'a pas été trouvée.
	 * @param psTourId Identifiant de la tournée à récupérer en base de données (avec ou sans préfixe).
	 * @param pbIsLive Ecoute les changements sur la base de données, par défaut `false`.
	 */
	public getTour<T>(psTourId: string, pbIsLive: boolean = false): Observable<ITour<T>> {
		const lsSearchedTourId: string = IdHelper.hasPrefixId(psTourId, TourService.C_TOUR_PREFIX) ?
			psTourId : IdHelper.buildId(TourService.C_TOUR_PREFIX, psTourId);

		return this.isvcStore.getOne({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				key: lsSearchedTourId,
				include_docs: true
			},
			live: pbIsLive
		} as IDataSource<ITour<T>>);
	}

	/** Récupère toutes les tournées de la base de données.
	 * @param pbIsLive Ecoute les changements sur la base de données, par défaut `false`.
	 * @param poActivePageManager Gestionnaire d'activité de l'appelant.
	 */
	public getTours<T>(pbIsLive: boolean = false, poActivePageManager?: ActivePageManager): Observable<ITour<T>[]> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				startkey: TourService.C_TOUR_PREFIX,
				endkey: `${TourService.C_TOUR_PREFIX}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
			live: pbIsLive
		};

		if (poActivePageManager) {
			(loDataSource as IDataSourceRemoteChanges).remoteChanges = true;
			(loDataSource as IDataSourceRemoteChanges).activePageManager = poActivePageManager;
		}

		return this.isvcStore.get<ITour<T>>(loDataSource)
			.pipe(
				map((paTours: ITour<T>[]) => {
					return paTours.sort((poItemA: ITour<T>, poItemB: ITour<T>) => {
						const lnSortComparison: number = DateHelper.compareTwoDates(poItemA.startDate, poItemB.startDate);
						return lnSortComparison === 0 ? poItemA._id.localeCompare(poItemB._id) : lnSortComparison;
					});
				})
			);
	}

	/** Retourne la tournée courante de l'utilisateur passé en paramètre :
	 * - la tournée courante exacte si elle existe,
	 * - la tournée la plus récente dans le passé sinon,
	 * - la tournée la plus récente dans le futur sinon,
	 * - `undefined` si aucune tournée n'est trouvée.
	 * @param psUserId L'_id dont on souhaite la tournée. Si `undefined` récupère la tournée de l'utilisateur connecté à l'application.
	 */
	public getCurrentTourAsync<T>(psUserId?: string): Promise<ITour<T> | undefined> {

		const loGetUsersTourParams: IGetUsersTourParams = { isLive: false };

		return firstValueFrom(this.getUserTours<T>(loGetUsersTourParams)
			.pipe(
				map((paUserTours: ITour<T>[]) => {
					return this.getCurrentTour(paUserTours);
				})
			));
	}

	public getCurrentTourIdAsync(psUserId?: string): Promise<string | undefined> {
		return this.getCurrentTourAsync<unknown>(psUserId)
			.then((poTour: ITour<unknown>) => poTour?._id);
	}

	/** Récupère toutes les tournées affectées à l'utilisateur courant.
	 * @param poGetUsersTourParams Objet optionnel des options passées à la méthode.
	 */
	public getUserTours<T>(poGetUsersTourParams: IGetUsersTourParams = {}): Observable<ITour<T>[]> {

		return this.getTours(poGetUsersTourParams.isLive, poGetUsersTourParams.activePageManager)
			.pipe(
				map((paTours: ITour<T>[]) => {
					return paTours.filter((poTour: ITour<T>) => {
						if (!poTour.participantsIds) {
							console.warn(`${TourService.C_LOG_ID} attention champs "participantsIds" manquant dans "${poTour._id}"`);
							return false;
						}
						else {
							const lsUserId: string = UserHelper.getUserContactId();
							return poTour.participantsIds.some((psParticipantId: string) => psParticipantId === lsUserId || psParticipantId === UserData.current._id);
						}
					});
				})
			);
	}

	/** Récupère les tournées passées triées par date de début (de la plus récente à la plus éloignée).
	 * @param paTours Tableau des tournées à partir duquel filtrer et trier les tournées passées.
	 * @param pdDate Date qui détermine les tournées passées.
	 */
	private getPastTours<T>(paTours: ITour<T>[], pdDate: Date): ITour<T>[] {
		return ArrayHelper.dynamicSort(
			// On garde uniquement les tournées dont la date de début est supérieure ou égale à la date en paramètre.
			paTours.filter((poTour: ITour<T>) => DateHelper.compareTwoDates(poTour.startDate, pdDate) <= 0),
			"startDate",
			ESortOrder.descending
		);
	}

	/** Récupère les tournées qui sont à venir triées par date de début (de la plus récente à la plus éloignée).
	 * @param paTours Tableau des tournées à partir duquel filtrer et trier les tournée futures.
	 * @param pdDate Date qui détermine les tournées futures.
	 */
	private getFutureTours<T>(paTours: ITour<T>[], pdDate: Date): ITour<T>[] {
		// On garde uniquement les tournées dont la date de début est supérieure ou égale à la date en paramètre.
		return paTours.filter((poTour: ITour<T>) => DateHelper.compareTwoDates(poTour.startDate, pdDate) >= 0);
	}

	/** Retourne `true` si le rdv est le même jour qu'aujourd'hui sinon retourne `false`.
	 * @param poTour Données de rendez-vous.
	 */
	public isCurrentDate(poTour: ITourAppointData): boolean {
		return DateHelper.diffDays(new Date(), poTour.expectedDate) === 0;
	}

	/** Retourne la seule tournée considérée active. */
	private getCurrentTour<T>(paTours: ITour<T>[]): ITour<T> | undefined {
		const ldCurrentDate = new Date();

		// On retourne la première tournée dont la date de début et de fin comprennent la date du jour.
		const loCurentTour: ITour<T> | undefined = paTours.find((poItem: ITour<T>): boolean => DateHelper.isBetweenTwoDates(ldCurrentDate, poItem.startDate, poItem.endDate));

		if (loCurentTour) // S'il y a une tournée courante, on la retourne.
			return loCurentTour;
		else { // Sinon on cherche la tournée la plus récente dans celles du passé.
			const loPastNewestTour: ITour<T> | undefined = ArrayHelper.getFirstElement(this.getPastTours(paTours, ldCurrentDate));

			if (loPastNewestTour) // S'il y a une tournée récente passée, on la retourne.
				return loPastNewestTour;
			else // Sinon on cherche la tournée la plus récente dans celles du futur.
				return ArrayHelper.getFirstElement(this.getFutureTours(paTours, ldCurrentDate)); // S'il y a une tournée récente future, on la retourne.
		}
	}

	public isCurrentTourId<T>(paTours: ITour<T>[], psTestedTourId: string): boolean {
		if (paTours.length === 0)
			return false;

		return this.getCurrentTour(paTours)?._id === psTestedTourId;
	}

	/** Récupère un objet contenant les identifiants de la tournée précédente et de la tournée suivante, chaque identifiant peut être vide.
	 * @param psTourId Identifiant de la tournée dont on veut récupérer les identifiants de la tournée précédente et de la tournée suivante.
	 */
	public getPreviousNextTourIds(psTourId: string): Observable<IPreviousNextTourId>;
	/** Récupère un objet contenant les identifiants de la tournée précédente et de la tournée suivante, chaque identifiant peut être vide.
	 * @param poTour Tournée dont on veut récupérer les identifiants de la tournée précédente et de la tournée suivante.
	 */
	public getPreviousNextTourIds<T>(poTour: ITour<T>): Observable<IPreviousNextTourId>;
	public getPreviousNextTourIds<T>(poTourData: ITour<T> | string): Observable<IPreviousNextTourId> {

		return this.getUserTours()
			.pipe(
				mergeMap((paResults: ITour<T>[]) => {
					const loReferenceTour: ITour<T> | undefined = typeof poTourData === "string" ? paResults.find((poTour: ITour<T>) => poTour._id === poTourData) : poTourData;

					if (!loReferenceTour)
						return throwError(() => new Error(`La tournée "${typeof poTourData === "string" ? poTourData : poTourData._id}" n'a pas été trouvée !`));
					else {
						const lnCurrentTourIndex = paResults.findIndex((poTour: ITour<T>) => poTour._id === loReferenceTour._id);
						const loPreviousTour: ITour<T> = this.getTourByIndex(paResults, lnCurrentTourIndex - 1);
						const loNextTour: ITour<T> = this.getTourByIndex(paResults, lnCurrentTourIndex + 1);

						return of({ previousTourId: loPreviousTour?._id, nextTourId: loNextTour?._id } as IPreviousNextTourId);
					}
				})
			);
	}

	/** Retourne une tournée depuis le tableau des tournées de l'utilisateur d'après un index.
	 * @param paTours Le tableau des tournées de l'utilisateur.
	 * @param pnIndex L'index de la tournée à retourner.
	 */
	private getTourByIndex<T>(paTours: ITour<T>[], pnIndex: number): ITour<T> {
		return paTours[pnIndex];
	}

	/** Récupère les tournées précédentes à une tournée.
	 * @param psTourId Identifiant de la tournée dont il faut récupérer les tournées précédentes.
	 */
	public getPreviousTours<T>(psTourId: string): Observable<ITour<T>[]>;
	/** Récupère les tournées précédentes à une tournée.
	 * @param poTour Tournée dont il faut récupérer les tournées précédentes.
	 */
	public getPreviousTours<T>(poTour: ITour<T>): Observable<ITour<T>[]>;
	public getPreviousTours<T>(poTourData: string | ITour<T>): Observable<ITour<T>[]> {
		return this.getPreviousOrNextTours(poTourData, true);
	}

	/** Récupère les tournées suivantes à une tournée.
	 * @param psTourId Identifiant de la tournée dont il faut récupérer les tournées suivantes.
	 */
	public getNextTours<T>(psTourId: string): Observable<ITour<T>[]>;
	/** Récupère les tournées suivantes à une tournée.
	 * @param poTour Tournée dont il faut récupérer les tournées suivantes.
	 */
	public getNextTours<T>(poTour: ITour<T>): Observable<ITour<T>[]>;
	public getNextTours<T>(poTourData: string | ITour<T>): Observable<ITour<T>[]> {
		return this.getPreviousOrNextTours(poTourData, false);
	}

	/** Récupère les tournées passées ou futures à partir d'une tournée spécifiée.
	 * @param poTourData Identifiant de la tournée / Tournée dont on veut récupérer les tournées passées ou futures.
	 * @param pbPreviousWanted Indique si on veut les tournées passées `true` ou futures `false`.
	 */
	private getPreviousOrNextTours<T>(poTourData: string | ITour<T>, pbPreviousWanted: boolean): Observable<ITour<T>[]> {
		return this.getUserTours()
			.pipe(
				mergeMap((paResults: ITour<T>[]) => {
					const lsTourId: string = typeof poTourData === "string" ? poTourData : poTourData._id;
					const lnReferenceTourIndex: number = paResults.findIndex((poTour: ITour<T>) => poTour._id === lsTourId);

					if (lnReferenceTourIndex === -1)
						return throwError(() => new Error(`La tournée "${typeof poTourData === "string" ? poTourData : poTourData._id}" n'a pas été trouvée !`));
					else {
						if (pbPreviousWanted) {
							// Si la référence n'est pas le premier élément, on peut retourner les précédents en inversant les éléments
							// pour retrouver les éléments du plus proche au plus loin de la tournée référente.
							if (lnReferenceTourIndex > 0)
								return of(ArrayHelper.getSection(paResults, 0, lnReferenceTourIndex - 1).reverse());
							else // Sinon, pas d'éléments passés.
								return of([]);
						}
						else {
							// Si la référence n'est pas le dernier élément, on peut retourner les suivants.
							return of(ArrayHelper.getSection(paResults, lnReferenceTourIndex + 1));
						}
					}
				})
			);
	}

	/** Récupère la tournée précédente (la plus proche dans le passé) par rapport à la tournée en paramètre, `undefined` si non trouvé ou s'il n'y en a pas.
	 * @param psTourId Identifiant de la tournée dont on veut récupérer la tournée précédente.
	 */
	public getPreviousTourAsync<T>(psTourId: string): Promise<ITour<T> | undefined>;
	/** Récupère la tournée précédente (la plus proche dans le passé) par rapport à la tournée en paramètre, `undefined` si non trouvé ou s'il n'y en a pas.
	 * @param poTour Tournée dont on veut récupérer la tournée précédente.
	 */
	public getPreviousTourAsync<T>(poTour: ITour<T>): Promise<ITour<T> | undefined>;
	public getPreviousTourAsync<T>(poTourData: string | ITour<T>): Promise<ITour<T> | undefined> {
		return this.getPreviousOrNextTours(poTourData, true)
			.pipe(take(1))
			.toPromise()
			.then((paTours: Array<ITour<T>>) => ArrayHelper.getFirstElement(paTours));
	}

	/** Récupère la première tournée de l'utilisateur.
	 * @param poGetUsersTourParams Objet optionnel des options passées à la méthode.
	 * @return La première tournée de l'utilisateur ou `undefined` si l'utilisateur ne possède aucune tournée.
	*/
	public getFirstTour<T>(poGetUsersTourParams: IGetUsersTourParams = {}): Observable<ITour<T>> {
		return this.getUserTours(poGetUsersTourParams).pipe(map((paTours: ITour<T>[]) => paTours[0]));
	}

	//#endregion

}