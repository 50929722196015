import { Injectable } from "@angular/core";
import { IDataSource } from "@calaosoft/osapp-common/store/models/IDataSource";
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { IStoreDocument } from "@calaosoft/osapp-common/store/models/istore-document";
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { Observable, Subject, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { IStoreDataResponse } from "../../../model/store/IStoreDataResponse";
import { FlagService } from "../../../services/flag.service";
import { PatternResolverService } from "../../../services/pattern-resolver.service";
import { Store } from "../../../services/store.service";
import { EventsService } from "../../events/events.service";
import { IFormDescriptor } from "../models/IFormDescriptor";
import { IFormDescriptorDataSource } from "../models/IFormDescriptorDataSource";
import { IFormListEvent } from "../models/IFormListEvent";

@Injectable()
export class FormsService<T extends IStoreDocument = IStoreDocument> {

	//#region FIELDS

	/** Sujet du service des FormList permettant de partager/envoyer/recevoir des données via un système d'abonnement. */
	private moFormListSubject: Subject<IFormListEvent<T>>;

	//#endregion

	//#region PROPERTIES

	/** Identifiant de l'action "back" lors d'un enregistrement de formulaire. */
	public static readonly C_BACK_ACTION_ID: string = "back";
	/** Nombre maximum d'entrées dans la liste de formulaire à afficher, 50 par défaut. */
	public static readonly C_MAX_DISPLAY_ENTRIES: number = 50;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en base de données. */
		private readonly isvcStore: Store,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly isvcEvents: EventsService,
		private readonly isvcFlags: FlagService
	) {

		this.moFormListSubject = new Subject();
	}

	/** Récupération de l'observable écoutant les événement des liste de formulaires. */
	public onFormListEvent(): Observable<IFormListEvent<T>> {
		return this.moFormListSubject.asObservable();
	}

	/** Lève un événement pour une liste de formulaires.
	 * @param poEvent Événement à lever.
	 */
	public raiseFormListEvent(poEvent: IFormListEvent<T>): void {
		this.moFormListSubject.next(poEvent);
	}

	/** Enregistre dans la base de données le document.
	 * @param poDocument Document qu'il faut enregistrer sur la base de données.
	 * @param psDatabaseId Identifiant de la base de données où enregistrer le document.
	 */
	public postFormEntry(poDocument: IStoreDocument, psDatabaseId: string): Observable<IStoreDataResponse> {
		return this.isvcStore.put(poDocument, psDatabaseId)
			.pipe(catchError(poError => { console.error(`FORM.S:: Error put entry : `, poError); return throwError(() => poError); }));
	}

	/** Transforme les paramètres de la source de données en données tenant compte de l'environnement de l'application.
	 * @param poDataSource Source de données à transformer.
	 * ### Example
	 *
	 * ```javascript
	 * {
	 *  "id": "ngapActesDataSource",
	 *  "type": "couchdb",
	 *  "db": "ngap_core_common_forms_entries",
	 *  "view": "entry/by_profession?key={{app.profession}}"
	 * }
	 * //DEVIENT
	 * {
	 *  "id": "ngapActesDataSource",
	 *  "type": "couchdb",
	 *  "db": "ngap_core_common_forms_entries",
	 *  "view": "entry/by_profession?key=42"
	 * }
	 * ```
	 */
	private resolveDynDataSourceParams(poDataSource: IDataSource): IDataSource {
		const loResolvedDataSource: IDataSource = poDataSource;

		if (poDataSource && poDataSource.viewParams) {
			if (poDataSource.viewParams.key)
				poDataSource.viewParams.key = this.isvcPatternResolver.replaceDynParams(poDataSource.viewParams.key as string);

			if (typeof poDataSource.viewParams.startkey === "string")
				poDataSource.viewParams.startkey = this.isvcPatternResolver.replaceDynParams(poDataSource.viewParams.startkey);

			if (typeof poDataSource.viewParams.endkey === "string")
				poDataSource.viewParams.endkey = this.isvcPatternResolver.replaceDynParams(poDataSource.viewParams.endkey);
		}

		return loResolvedDataSource;
	}

	public getModel$<U extends IStoreDocument>(psModelId?: string): Observable<U | undefined> {
		if (StringHelper.isBlank(psModelId))
			return of(undefined);

		return this.isvcStore.getOne<U>(
			{
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				viewParams: {
					key: psModelId,
					include_docs: true
				}
			} as IDataSource<U>,
			false
		);
	}

	/** Configure une source de données conformément aux exigences du descripteur en appliquant notamment les valeurs dynamiques.
	 * @param poDescriptorDataSource DataSource telle qu'elle est décrite dans le descripteur.
	 * @returns Une DataSource dans laquelle les templates sont remplacés par les valeurs évaluées dynamiquement.
	 */
	public prepareFormDataSource(poDescriptorDataSource: IFormDescriptorDataSource): IDataSource {
		let loPreparedDataSource: IDataSource;

		if (poDescriptorDataSource) {
			loPreparedDataSource = {
				id: poDescriptorDataSource.id,
				type: poDescriptorDataSource.type,
				databaseId: poDescriptorDataSource.db, // Écart de nommage NoSQL/TypeScript.
				databasesIds: [],
				viewName: poDescriptorDataSource.view, // Écart de nommage NoSQL/TypeScript.
				viewParams: poDescriptorDataSource.viewParams,
				live: poDescriptorDataSource.live
			};

			if (poDescriptorDataSource.databases) // Écart de nommage NoSQL/TypeScript.
				loPreparedDataSource.databasesIds!.push(...poDescriptorDataSource.databases);

			if (poDescriptorDataSource.role)
				loPreparedDataSource.databasesIds!.push(...this.isvcStore.getDatabasesIdsByRole(poDescriptorDataSource.role));
		}
		else {
			loPreparedDataSource = {
				id: undefined,
				type: undefined,
				databaseId: undefined,
				databasesIds: [],
				viewName: undefined,
				viewParams: undefined
			};
		}

		return this.resolveDynDataSourceParams(loPreparedDataSource);
	}

	public getFormDescriptors(psDatabaseId: string): Observable<IFormDescriptor[]> {
		return this.isvcStore.get({
			databaseId: psDatabaseId,
			viewParams: {
				startkey: EPrefix.formDesc,
				endkey: EPrefix.formDesc + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			}
		} as IDataSource<IFormDescriptor>);
	}

	//#endregion
}