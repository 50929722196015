import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ObserveArray } from '@calaosoft/osapp-common/observable/decorators/observe-array.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { ModalController } from '@ionic/angular';
import { IGroup } from '../../../../../../../model/contacts/IGroup';
import { PlatformService } from '../../../../../../../services/platform.service';
import { IGroupFilterValues } from '../../../../../../groups/model/igroup-filter-values';
import { ModalComponentBase } from '../../../../../../modal';
import { IGroupsFieldModal } from '../models/igroups-field-modal';

@Component({
	selector: 'calao-groups-field-modal',
	templateUrl: './groups-field-modal.component.html',
	styleUrls: ['./groups-field-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsFieldModalComponent extends ModalComponentBase<IGroupsFieldModal> implements OnInit {

	//#region FIELDS

	private readonly moObservableGroupsCache = new ObservableArray<IGroup>();

	//#endregion FIELDS


	/** Groupes à afficher dans la liste de la modale. */
	@Input() public groups?: IGroup[];
	@ObserveArray<GroupsFieldModalComponent>("groups")
	public readonly observableGroups = new ObservableArray<IGroup>();

	public readonly observableFilterValues = new ObservableProperty<IGroupFilterValues>();

	//#region METHODS

	constructor(
		private readonly poRouter: Router,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform)
	}

	public override ngOnInit(): void {
		this.moObservableGroupsCache.resetArray(this.observableGroups);
	}

	public onGroupFilterValuesChange(poFilterValues?: IGroupFilterValues): void {
		this.observableFilterValues.value = poFilterValues;
		this.observableGroups.resetArray(ObjectHelper.isEmpty(poFilterValues) ? this.moObservableGroupsCache : this.filterGroups());
	}

	private filterGroups(): IGroup[] {
		if (this.observableFilterValues.value?.name)
			return this.moObservableGroupsCache.filter((poGroup: IGroup) => poGroup.name.toLocaleLowerCase().includes(this.observableFilterValues.value.name.toLocaleLowerCase()));
		return this.moObservableGroupsCache;
	}

	public onGroupClicked(poGroup: IGroup) {
		this.close({
			groups: [poGroup]
		});
	}

	public routeToAddGroupAsync(): Promise<boolean> {
		this.close();
		return this.poRouter.navigate(["contacts", "groupes", "new"]);
	}

	//#endregion METHODS

}
