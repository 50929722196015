import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ObservableProperty } from "@calaosoft/osapp-common/observable/models/observable-property";
import { TPermission } from "@calaosoft/osapp-common/permissions/models/tpermission";
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { tapAll } from "@calaosoft/osapp-common/rxjs/operators/tap-all";
import { ArrayHelper } from "@calaosoft/osapp-common/utils/helpers/arrayHelper";
import { ModalController } from "@ionic/angular";
import { Observable, catchError, mergeMap, of, tap, throwError } from "rxjs";
import { ModalComponentBase } from "../../../modules/modal";
import { EPermissionScopes } from "../../../modules/permissions/models/epermission-scopes";
import { PermissionsService } from "../../../modules/permissions/services/permissions.service";
import { ShowMessageParamsPopup } from "../../../services/interfaces/ShowMessageParamsPopup";
import { ISubscriptionsInfo } from "../../../services/interfaces/isubscriptions-info";
import { InteropService } from "../../../services/interop.service";
import { PlatformService } from "../../../services/platform.service";
import { UiMessageService } from "../../../services/uiMessage.service";

interface ICacheSubscriptionsInfoBySystem {
	[systemId: string]: ISubscriptionsInfo;
}

interface IToggleInfo {
	[systemId: string]: {
		[systemType: string]: boolean;
	};
}

@Component({
	selector: "interop-modal",
	templateUrl: "./interop-modal.component.html",
	styleUrls: ["./interop-modal.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteropModalComponent extends ModalComponentBase<void> implements OnInit {
	//#region FIELDS

	private get maSystemsIds(): string[] {
		return ArrayHelper.unique([...this.systemsIdsForMailbox, ...this.systemsIdsForCalendar]);
	}

	private moToggle: IToggleInfo = {};

	private moCacheSub = new ObservableProperty<ICacheSubscriptionsInfoBySystem>({});

	//#endregion

	//#region PROPERTIES

	public systemsIdsForMailbox: string[] = [];

	public systemsIdsForCalendar: string[] = [];

	public readonly observableIsLoading = new ObservableProperty<boolean>(false);

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcInterop: InteropService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcPermissions: PermissionsService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public override ngOnInit(): void {

		const loPermissionsMailbox: TPermission = this.isvcPermissions.getPermissionValue([EPermissionScopes.conversations, EPermissionScopes.interoperabilite], "mailbox");
		this.systemsIdsForMailbox = loPermissionsMailbox instanceof Array ? loPermissionsMailbox : [];

		const loPermissionsCalendar: TPermission = this.isvcPermissions.getPermissionValue([EPermissionScopes.events, EPermissionScopes.interoperabilite], "calendar");
		this.systemsIdsForCalendar = loPermissionsCalendar instanceof Array ? loPermissionsCalendar : [];

		this.initSubscribeInfo();
	}

	private initSubscribeInfo(): void {
		this.maSystemsIds.forEach((psSystemId: string) => {
			this.isvcInterop.getSubscribeInfo$(psSystemId).pipe(
				tap((psAuthInfo: ISubscriptionsInfo) => {
					if (this.moCacheSub.value)
						this.moCacheSub.value[psSystemId] = psAuthInfo;
					this.detectChanges();
				}),
				secure(this)
			).subscribe()
		});
	}

	public onApplyButtonClick(psSystemId: string): void {

		const laSystemToSubscribe: string[] = [];
		const laSystemToUnsubscribe: string[] = [];

		if (this.systemsIdsForMailbox.includes(psSystemId)) {
			const lbSubscribe: boolean = this.hasSubscribe(psSystemId, "mailbox");
			if (Object.keys(this.moToggle).includes(psSystemId) && Object.keys(this.moToggle[psSystemId]).includes("mailbox")) {
				if (lbSubscribe !== this.moToggle[psSystemId]["mailbox"]) {
					lbSubscribe ? laSystemToUnsubscribe.push("mailbox") : laSystemToSubscribe.push("mailbox");
				}
			}
		}

		if (this.systemsIdsForCalendar.includes(psSystemId)) {
			const lbSubscribe: boolean = this.hasSubscribe(psSystemId, "calendar");
			if (Object.keys(this.moToggle).includes(psSystemId) && Object.keys(this.moToggle[psSystemId]).includes("calendar")) {
				if (lbSubscribe !== this.moToggle[psSystemId]["calendar"]) {
					lbSubscribe ? laSystemToUnsubscribe.push("calendar") : laSystemToSubscribe.push("calendar");
				}
			}
		}

		if (ArrayHelper.hasElements(laSystemToUnsubscribe)) {
			this.sendUnsubscription(psSystemId, laSystemToUnsubscribe);
		}

		if (ArrayHelper.hasElements(laSystemToSubscribe)) {
			this.sendSubscription(psSystemId, laSystemToSubscribe);
		}
	}

	private sendSubscription(psSystemId: string, paSystemToSubscribe: string[]): void {
		this.observableIsLoading.value = true;
		this.isvcInterop.demandAuth$(psSystemId, paSystemToSubscribe).pipe(
			tap((pbResult: boolean) => {
				this.initSubscribeInfo();
				if (!pbResult) {
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
						header: "Erreur",
						message: "Impossible de vous connecter. Réessayer plus tard.",
					}));
				}
			}),
			catchError((poError: Error) => {
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
					header: "Erreur",
					message: "Impossible de vous connecter. Réessayer plus tard.",
				}));
				return throwError(() => poError);
			}),
			tapAll(() => this.observableIsLoading.value = false),
			secure(this)
		).subscribe();
	}

	private sendUnsubscription(psSystemId: string, paSystemToUnsubscribe: string[]): void {
		this.observableIsLoading.value = true;
		this.isvcInterop.deleteAuth$(psSystemId, paSystemToUnsubscribe).pipe(
			tap((pbResult: boolean) => {
				this.initSubscribeInfo();
				if (!pbResult) {
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
						header: "Erreur",
						message: "Impossible de vous désinscrire. Réessayer plus tard.",
					}));
				}
			}),
			catchError((poError: Error) => {
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
					header: "Erreur",
					message: "Impossible de vous désinscrire. Réessayer plus tard.",
				}));
				return throwError(() => poError);
			}),
			tapAll(() => this.observableIsLoading.value = false),
			secure(this)
		).subscribe();
	}

	public hasSystemId(psSystemId: string): boolean {
		return this.maSystemsIds.includes(psSystemId);
	}

	public onToggleChanged(psSystemId: string, psSystemType: string, pbChecked: boolean): void {
		if (!Object.keys(this.moToggle).includes(psSystemId))
			this.moToggle[psSystemId] = {};
		this.moToggle[psSystemId][psSystemType] = pbChecked;
	}

	public hasSubscribe$(psSystemId: string, psSystemType: string): Observable<boolean> {
		return this.moCacheSub.value$.pipe(
			mergeMap((poValue: ICacheSubscriptionsInfoBySystem) => {
				if (poValue[psSystemId] && poValue[psSystemId][psSystemType]) {
					return of(poValue[psSystemId][psSystemType].ids.length > 0);
				}
				return of(false);
			})
		);
	}

	private hasSubscribe(psSystemId: string, psSystemType: string): boolean {
		const loCacheSub = this.moCacheSub.value ?? {};
		if (loCacheSub[psSystemId] && loCacheSub[psSystemId][psSystemType])
			return loCacheSub[psSystemId][psSystemType].ids.length > 0;
		return false;
	}

	public getEmailBySystemId(psSystemId: string): string {
		return (this.moCacheSub.value ?? {})[psSystemId]?.account?.email ?? "";
	}

	public getDisplayNameBySystemId(psSystemId: string): string {
		return (this.moCacheSub.value ?? {})[psSystemId]?.account?.displayName ?? "";
	}

	//#endregion
}
