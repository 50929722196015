import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { combineLatest } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { PageManagerService } from '../../../routing/services/pageManager.service';
import { TabsComponent } from '../../../tabs/components/tabs/tabs.component';
import { ILayoutParamsComponent } from '../../models/ilayout-params-component';
import { ITabLayoutParams } from '../../models/itab-layout-params';
import { ITabLayoutTabParams } from '../../models/itab-layout-tab-params';
import { LayoutBase } from '../../models/layout-base';

@Component({
	selector: 'calao-tab-layout',
	templateUrl: './tab-layout.component.html',
	styleUrls: ['./tab-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabLayoutComponent extends LayoutBase<ITabLayoutParams> implements AfterViewInit, OnInit {

	//#region FIELDS

	@ViewChildren("header", { read: ViewContainerRef }) private moHeaderContainers?: QueryList<ViewContainerRef>;
	@ViewChildren("tabContainer", { read: ViewContainerRef }) private moTabContainers?: QueryList<ViewContainerRef>;
	@ViewChild("tabs", { static: true }) private moTabsComponent: TabsComponent;

	//#endregion

	//#region PROPERTIES

	public observableDefaultSegmentIndex = new ObservableProperty<number>(0);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly ioRoute: ActivatedRoute,
		psvcPageManager: PageManagerService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(psvcPageManager, poChangeDetector);
	}

	public ngOnInit(): void {
		this.ioRoute.queryParamMap.pipe(
			tap((poParams: ParamMap) => {
				const lsTabIndex: string | null = poParams.get("tab");
				if (!StringHelper.isBlank(lsTabIndex))
					this.observableDefaultSegmentIndex.value = +(lsTabIndex ?? 0);
			}),
			secure(this)
		).subscribe();
	}

	public override ngAfterViewInit(): void {
		super.ngAfterViewInit();
		combineLatest([
			this.observableParams.value$,
			this.moHeaderContainers.changes.pipe(startWith({})),
			this.moTabContainers.changes.pipe(startWith({})),
			this.moTabsComponent.observableActiveTabIndex.value$
		]).pipe(
			tap(([poParams, _, __, pnActiveIndex]: [ITabLayoutParams | undefined, any, any, number]) => {
				this.moHeaderContainers?.forEach((poItem: ViewContainerRef, pnIndex: number) => {
					this.clearLayout(poItem);
					if (ArrayHelper.hasElements(poParams?.headers)) {
						const loComp: ILayoutParamsComponent | undefined = poParams.headers[pnIndex];

						if (loComp)
							this.addComponent(loComp, poItem);
					}
				});

				this.moTabContainers?.forEach((poItem: ViewContainerRef, pnIndex: number) => {
					this.clearLayout(poItem);
					if (ArrayHelper.hasElements(poParams?.tabs) && pnIndex === pnActiveIndex) {
						const loTab: ITabLayoutTabParams | undefined = poParams.tabs[pnIndex];

						if (loTab?.component)
							this.addComponent(loTab.component, poItem);
					}
				});
			}),
			secure(this)
		).subscribe();
	}

	//#endregion

}
