import { Observable, defer, mergeMap, of } from "rxjs";
import { ELogActionId } from "../../logging/models/ELogActionId";
import { TTransferHeaders } from "../../transfert/models/ttransfer-headers";
import { final } from "../../utils/decorators/final.decorator";
import { ArrayHelper } from "../../utils/helpers/arrayHelper";
import { StringHelper } from "../../utils/helpers/stringHelper";
import { NoProviderFoundError } from "../errors/NoProviderFoundError";
import { IProviderFilesOptions } from "../models/iprovider-files-options";
import { SqlDataSource } from "../models/sql-data-source";
import { UpdateEvent } from "../models/update-event";
import { SqlAdapterBase } from "./sql-adapter-base";
import { SqlRemoteProviderBase } from "./sql-remote-provider-base";

/** Prépare l'utilisation d'une base de données SQL. Mets à disposition les bases  */
export abstract class SqlDatabaseProviderBase {

	//#region FIELDS

	protected static readonly C_LOG_ID = "SQL.DB.PRVDR::";

	//#endregion FIELDS

	//#region METHODS

	public constructor(
		protected readonly isvcAdapter: SqlAdapterBase<any>,
		private readonly iaRemoteProviders?: SqlRemoteProviderBase[]
	) { }

	//! TODO A Supprimer ?
	public abstract isDataSourceReadyAsync(poDataSource: SqlDataSource): Promise<boolean>;

	protected abstract provideDatabase$(poDataSource: SqlDataSource, poHeaders?: TTransferHeaders): Observable<UpdateEvent>;

	/** Retourne le nombre de fichiers dans le dossier donné en paramètre (sur mobile).
	 * Retourne le nombre de bases de données chargées en mémoire (sur WebApp).
	 * @param psDatabasePrefix Préfixe de la base de données.
	 * @param poOptions Options pour la récupération.
	 * @throws Lève une erreur si le préfixe n'est pas valide.
	 */
	public abstract getNumberOfDatabasesAsync(psDatabasePrefix: string, poOptions?: IProviderFilesOptions): Promise<number>;

	/** Retourne la dernière SqlDatasource disponible pour le provider.
	 * - Android: La dernière version téléchargée.
	 * - WebApp: La dernière version disponible sur le serveur.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @throws Lève une erreur si l'identifiant n'est pas valide.
	 */
	public abstract getLastReadyAsync(psDatabaseId: string): Promise<SqlDataSource | undefined>;

	/** - Retourne les bases de données sur disque (sur mobile).
	 * - Retourne les bases de données chargées en mémoire (sur WebApp).
	 * @param psDatabasePrefix Préfixe des bases de données à récupérer.
	 * @param poOptions Options pour la récupération.
	 * @throws Lève une erreur si le préfixe n'est pas valide.
	 */
	public abstract getDatabasesAsync(psDatabasePrefix: string, poOptions?: IProviderFilesOptions): Promise<SqlDataSource[]>;

	/** Construit et retourne le nom du fichier de la base de données à partir de son identifiant.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @throws Lève une erreur si l'identifiant n'est pas valide.
	 */
	public abstract getLastDownloadedDatabaseNameAsync(psDatabaseId: string): Promise<string>;

	/** Retourne la version courante de la base de données, `NaN` si aucune version présente.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @throws Lève une erreur si l'identifiant n'est pas valide.
	 */
	public abstract getLastVersionDownloadedAsync(psDatabaseId: string): Promise<number>;

	/** Vérifie si la base de données a été téléchargée.
	 * @param psDatabaseVersion Version de la base de données.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @param poOptions Options pour la vérification.
	 * @returns `true` si existe, `false` sinon.
	 * @throws Lève une erreur si l'identifiant n'est pas valide.
	 */
	public abstract databaseExistsAsync(psDatabaseVersion: string, psDatabaseId: string, poOptions?: IProviderFilesOptions): Promise<boolean>;

	/** Retourne l'URL pour récupérer la dernière version de la base de données.
	 * @param psDatabasePrefix Préfixe des bases de données (ex: `stock`, `catalog`).
	 * @param psDatabaseId Identifiant de la base de données.
	 * @param pnVersion Version de la base de données.
	 * @throws Lève une erreur si le préfixe ou l'identifiant n'est pas valide.
	 */
	public abstract getLastUrl(psDatabasePrefix: string, psDatabaseId: string, pnVersion: number): string;

	/** Exécute la suppression de la base de données passée en paramètre.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @param pnVersion Version de la base de données.
	 */
	protected abstract execRemoveDatabaseAsync(psDatabaseId: string, pnVersion?: number): Promise<void>;

	public provide$(poDataSource: SqlDataSource, poHeaders?: TTransferHeaders): Observable<UpdateEvent | undefined> {
		return defer(() => this.isDataSourceReadyAsync(poDataSource))
			.pipe(
				mergeMap((pbIsVersionReady: boolean) => {
					this.logAction(
						`Trying update '${poDataSource.databaseId}' to version ${poDataSource.version}, ${pbIsVersionReady ? 'version already installed' : 'continue'}.`,
						ELogActionId.sqliteCompareAvailableVersion
					);

					return pbIsVersionReady ? of(undefined) : this.provideDatabase$(poDataSource, poHeaders);
				})
			);
	}

	/** Supprime la base de données passée en paramètre.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @param pnVersion Version de la base de données.
	 * @throws Lève une erreur si l'identifiant n'est pas valide.
	 */
	@final()
	public async removeDatabaseAsync(psDatabaseId: string, pnVersion?: number): Promise<void> {
		if (StringHelper.isBlank(psDatabaseId)) {
			console.error(`${SqlDatabaseProviderBase.C_LOG_ID}Database id '${psDatabaseId}' (version ${pnVersion}) is not valid.`);
			return Promise.reject(new Error(`Database deletion failed because database id is not valid.`));
		}

		const loDataSource: SqlDataSource | undefined = this.isvcAdapter.getDataSource(psDatabaseId);
		const loClosingDatabaseAsync: Promise<void> = loDataSource ? this.isvcAdapter.closeDatabaseFromDataSourceAsync(loDataSource) : Promise.resolve();

		await loClosingDatabaseAsync;
		return this.execRemoveDatabaseAsync(psDatabaseId, pnVersion);
	}

	/** Retourne un `RemoteProvider` en fonction de son préfixe.
	 * @param psDatabasePrefix Préfixe des bases de données (ex: `stock`, `catalog`).
	 * @throws Lève une erreur si aucun provider n'est trouvé.
	 */
	protected getRemoteProvider(psDatabasePrefix: string): SqlRemoteProviderBase {
		if (StringHelper.isBlank(psDatabasePrefix)) {
			console.error(`${SqlDatabaseProviderBase.C_LOG_ID}Database prefix '${psDatabasePrefix}' is not valid.`);
			throw new Error(`Recovery of remote provider failed because database prefix is not valid.`);
		}

		const laRemoteProviders: SqlRemoteProviderBase[] | undefined = this.iaRemoteProviders?.filter(
			(poProvider: SqlRemoteProviderBase) => poProvider.canProcess(psDatabasePrefix)
		);

		if (!ArrayHelper.hasElements(laRemoteProviders))
			throw new NoProviderFoundError(psDatabasePrefix);
		else if (laRemoteProviders.length > 1)
			console.error(`${SqlDatabaseProviderBase.C_LOG_ID}Multiple providers found for identifier ${psDatabasePrefix}.`);

		return ArrayHelper.getFirstElement(laRemoteProviders);
	}

	protected abstract logAction(psMessage: string, peLogActionId: ELogActionId, poError?: any): void;

	/** Retourne l'URL pour récupérer la dernière version de la base de données.
	 * @param psDatabasePrefix Préfixe des bases de données (ex: `stock`, `catalog`).
	 * @param psDatabaseId Identifiant de la base de données.
	 * @throws Lève une erreur si le préfixe ou l'identifiant n'est pas valide.
	 */
	public getLastVersionUrl(psDatabasePrefix: string, psDatabaseId: string): string {
		if (StringHelper.isBlank(psDatabasePrefix) || StringHelper.isBlank(psDatabaseId)) {
			console.error(`${SqlDatabaseProviderBase.C_LOG_ID}Database prefix '${psDatabasePrefix}' or database id '${psDatabaseId}' is not valid.`);
			throw new Error(`Recovery of last database version URL failed because database prefix or database id is not valid.`);
		}
		else
			return this.getRemoteProvider(psDatabasePrefix).getLastUrl(psDatabaseId);
	}

	/** Retourne la dernière version de la base de données en paramètre.
	 * @param psDatabasePrefix Préfixe des bases de données (ex: `stock`, `catalog`).
	 * @param psDatabaseId Identifiant de la base de données.
	 * @throws Lève une erreur si le préfixe ou l'identifiant n'est pas valide.
	 */
	public getLastRemoteDatabaseAsync(psDatabasePrefix: string, psDatabaseId: string): Promise<SqlDataSource> {
		if (StringHelper.isBlank(psDatabasePrefix) || StringHelper.isBlank(psDatabaseId)) {
			console.error(`${SqlDatabaseProviderBase.C_LOG_ID}Database prefix '${psDatabasePrefix}' or database id '${psDatabaseId}' is not valid.`);
			throw new Error(`Recovery of last database version URL failed because database prefix or database id is not valid.`);
		}
		else
			return this.getRemoteProvider(psDatabasePrefix).getLastDatabaseAsync(psDatabaseId);
	}

	public deleteOldCachedDatabases(psDatabasePrefix: string): void {
		this.isvcAdapter.closeOldDatabases(psDatabasePrefix);
	}

	//#endregion METHODS

}