import { Entity } from "@calaosoft/osapp-common/entities/models/entity";
import { IEntity } from "@calaosoft/osapp-common/entities/models/ientity";
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { UserData } from "../../../model/application/UserData";
import { IContact } from "../../../model/contacts/IContact";
import { IGroup } from "../../../model/contacts/IGroup";
import { EConversationType } from "../../../model/conversation/e-conversation-type";
import { EConversationDocumentType } from "../../../model/conversation/EConversationDocumentType";
import { IConversation } from "../../../model/conversation/IConversation";
import { IMessage } from "../../../model/conversation/IMessage";
import { IParticipant } from "../../../model/conversation/IParticipant";

@ModelMatch((poConv?: IEntity) => IdHelper.hasPrefixId(poConv?._id ?? "", EPrefix.conversation), Entity)
export class Conversation extends Entity implements IConversation {

	//#region PROPERTIES

	/** @implements */
	public readonly createUserId: string;
	/** @implements */
	public participants: IParticipant<IContact | IGroup>[];
	public defaultTitle?: string;
	public title?: string;
	public lastMessage?: IMessage;
	public conversationType?: EConversationType;
	/** @implements */
	public triggers: string[] = [];
	/** @implements */
	public readonly type: EConversationDocumentType = EConversationDocumentType.conversation;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poConv?: IConversation) {
		super(poConv);

		this.createUserId = poConv?.createUserId ?? UserData.current?._id ?? "";
		this.participants = poConv?.participants ?? [];
	}

	//#endregion METHODS

}
