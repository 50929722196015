import { Exclude } from "class-transformer";
import { DateHelper } from "../../dates/helpers/dateHelper";
import { StoreDocument } from "../../store/models/store-document";
import { ModelMatch } from "../../utils/decorators/model-match.decorator";
import { ResolveModel } from "../../utils/decorators/resolve-model.decorator";
import { IdHelper } from "../../utils/helpers/idHelper";
import { StringHelper } from "../../utils/helpers/stringHelper";
import { EPrefix } from "../../utils/models/EPrefix";
import { ModelResolver } from "../../utils/models/model-resolver";
import { EEntityLinkCacheData } from "./EEntityLinkCacheData";
import { EEntityLinkType } from "./eentity-link-type";
import { EntityLinkEntity } from "./entity-link-entity";
import { IEntityLink } from "./ientity-link";
import { IEntityLinkEntity } from "./ientity-link-entity";

@ModelMatch((poData: IEntityLink) => poData._id.startsWith(EPrefix.link), StoreDocument)
export class EntityLink extends StoreDocument implements IEntityLink {

	//#region PROPERTIES

	@ResolveModel(Date)
	/** @implements */
	public readonly creationDate: Date;
	@ResolveModel(EntityLinkEntity)
	/** @implements */
	public readonly entities: EntityLinkEntity[] = [];

	@Exclude()
	/** État du lien pour savoir quelle opération réaliser dessus. */
	public readonly cacheDataState?: EEntityLinkCacheData;

	@Exclude()
	/** @implements */
	public logical?: boolean;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: Partial<IEntityLink>) {
		super({ ...(poData ?? {}), _id: poData?._id ?? IdHelper.buildId(EPrefix.link) });
		this.creationDate = DateHelper.toDate(poData?.creationDate) ?? new Date;
		this.entities = poData?.entities?.map((poEntity: IEntityLinkEntity) => ModelResolver.toClass(EntityLinkEntity, poEntity)) ?? [];
	}

	public getTargetEntitiesBySourceId(psSourceId: string): EntityLinkEntity[] {
		return this.entities.filter((poEntity: EntityLinkEntity) => poEntity.id !== psSourceId);
	}

	public getTargetEntitiesByTargetPrefix(psTargetPrefix: string): EntityLinkEntity[] {
		return this.entities.filter((poEntity: EntityLinkEntity) => poEntity.id.startsWith(psTargetPrefix));
	}

	public hasLink(
		peEntityLinkType?: EEntityLinkType,
		psLinkedEntityPrefix?: string,
		psSourceId?: string
	): boolean {
		return this.entities.some((poEntity: EntityLinkEntity) =>
			this.hasLinkWith(poEntity, psLinkedEntityPrefix, peEntityLinkType, psSourceId)
		);
	}

	public getLinkedEntityLinkEntities(
		peEntityLinkType?: EEntityLinkType,
		peLinkedEntityPrefix?: EPrefix,
		psSourceId?: string
	): EntityLinkEntity[] {
		return this.entities.filter((poEntity: EntityLinkEntity) =>
			this.hasLinkWith(poEntity, peLinkedEntityPrefix, peEntityLinkType, psSourceId)
		);
	}

	private hasLinkWith(
		poEntity: EntityLinkEntity,
		psLinkedEntityPrefix?: string,
		peEntityLinkType?: EEntityLinkType,
		psSourceId?: string
	): boolean {
		return (
			StringHelper.isBlank(psLinkedEntityPrefix) ||
			poEntity.id.startsWith(psLinkedEntityPrefix)
		) &&
			(
				StringHelper.isBlank(peEntityLinkType) ||
				(poEntity.type ?? EEntityLinkType.related) === peEntityLinkType
			) &&
			(
				StringHelper.isBlank(psSourceId) ||
				poEntity.id !== psSourceId
			);
	}
	//#endregion METHODS

}
