import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { EntityHeaderComponent } from '../../../entities/components/entity-header/entity-header.component';
import { EntitiesUpdateService } from '../../../entities/services/entities-update.service';
import { EntitiesService } from '../../../entities/services/entities.service';
import { IFormParams } from '../../../forms/models/IFormParams';
import { FormsService } from '../../../forms/services/forms.service';
import { PermissionsService } from '../../../permissions/services/permissions.service';
import { PageManagerService } from '../../../routing/services/pageManager.service';
import { Document } from '../../models/document';
import { EListItemOption } from '../../models/elist-item-option';
import { DocExplorerService } from '../../services/doc-explorer.service';

@Component({
	selector: 'calao-document-header',
	templateUrl: './document-header.component.html',
	styleUrls: ['./document-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.width]": "'100%'"
	}
})
export class DocumentHeaderComponent extends EntityHeaderComponent<Document> implements OnInit {

	//#region PROPERTIES

	public override readonly observableCanEdit = new ObservableProperty<boolean>(false);
	public override readonly observableCanDelete = new ObservableProperty<boolean>(false);
	public readonly observableCanMoveToTrash = new ObservableProperty<boolean>(false);
	public readonly observableCanRestore = new ObservableProperty<boolean>(false);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		psvcEntities: EntitiesService,
		psvcPermissions: PermissionsService,
		psvcForms: FormsService,
		psvcEntitiesUpdate: EntitiesUpdateService,
		poRouter: Router,
		poRoute: ActivatedRoute,
		psvcPageManager: PageManagerService,
		private readonly isvcDocExplorer: DocExplorerService
	) {
		super(psvcEntities, psvcPermissions, psvcForms, psvcEntitiesUpdate, poRouter, poRoute, psvcPageManager);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.observableModel.value$.pipe(
			filter((poDocument?: Document) => !!poDocument),
			mergeMap((poDocument: Document) => combineLatest([this.getPermission$("edit"), this.getPermission$("trash"), this.getPermission$("delete")]).pipe(
				tap(([pbEdit, pbTrash, pbDelete]: [boolean, boolean, boolean]) => {
					this.observableCanEdit.value = pbEdit && !poDocument.isInTrash;
					this.observableCanDelete.value = pbDelete && poDocument.isInTrash;
					this.observableCanMoveToTrash.value = pbTrash && poDocument.canArchive;
					this.observableCanRestore.value = pbTrash && poDocument.canRestore;
				})
			)),
			secure(this)
		).subscribe();
	}

	protected override getDisplayActionButtons$(): Observable<boolean> {
		return this.observableParams.value$.pipe(
			mergeMap((poFormParams?: IFormParams<Document>) => {
				if (poFormParams?.hideActionButtons)
					return of(false);
				else
					return combineLatest([this.observableCanEdit.value$, this.observableCanDelete.value$, this.observableCanMoveToTrash.value$, this.observableCanRestore.value$]).pipe(map(ArrayHelper.some));
			}),
		);
	}

	public onMoveToTrashAsync(): Promise<boolean> {
		return this.onOptionClicked$(EListItemOption.trash).toPromise();
	}

	public onRestoreAsync(): Promise<boolean> {
		return this.onOptionClicked$(EListItemOption.restore).toPromise();
	}

	@Queue<
		DocumentHeaderComponent,
		Parameters<DocumentHeaderComponent["onOptionClicked$"]>,
		ReturnType<DocumentHeaderComponent["onOptionClicked$"]>
	>({
		excludePendings: true
	})
	protected override onOptionClicked$(peOption: EListItemOption): Observable<boolean> {
		switch (peOption) {
			case EListItemOption.restore:
				return this.restore$();
			case EListItemOption.trash:
				return this.moveToTrash$();
			default:
				return super.onOptionClicked$(peOption);
		}
	}

	private moveToTrash$(): Observable<boolean> {
		const loDocument: Document | undefined = this.observableModel.value;
		if (loDocument)
			return this.isvcDocExplorer.moveToTrash$(loDocument, ArrayHelper.getFirstElement(loDocument.paths)).pipe(tap(() => this.isvcPageManager.goBack()));
		return of(false);
	}

	private restore$(): Observable<boolean> {
		const loDocument: Document | undefined = this.observableModel.value;
		if (loDocument)
			return this.isvcDocExplorer.restore$(loDocument, ArrayHelper.getFirstElement(loDocument.paths)).pipe(tap(() => this.isvcPageManager.goBack()));
		return of(false);
	}

	//#endregion METHODS

}
