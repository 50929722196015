import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { ObserveArray } from '@calaosoft/osapp-common/observable/decorators/observe-array.decorator';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';

@Component({
	selector: 'calao-column-list',
	templateUrl: './column-list.component.html',
	styleUrls: ['./column-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnListComponent<T> {

	//#region FIELDS

	private static readonly C_DETAIL_COLUMN_SIZE = 0.5;

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly detailColumnSize: number = ColumnListComponent.C_DETAIL_COLUMN_SIZE;

	@ContentChild(TemplateRef) public template?: TemplateRef<HTMLElement>;

	/** Liste des items à afficher. */
	@Input() public items?: T[];
	@ObserveArray<ColumnListComponent<T>>("items")
	public readonly observableItems = new ObservableArray<T>();

	/** Clé de tri. */
	@Input() public sortKey?: keyof T | null;
	@ObserveProperty<ColumnListComponent<T>>({ sourcePropertyKey: "sortKey" })
	public readonly observableSortKey = new ObservableProperty<keyof T>();

	/** Indique si les items ont l'affichage du détail. */
	@Input() public detail?: boolean | string | null;
	@ObserveProperty<ColumnListComponent<T>>({
		sourcePropertyKey: "detail",
		transformer: (poNewValue: any) => coerceBooleanProperty(poNewValue)
	})
	public readonly observableDetail = new ObservableProperty<boolean>();

	/** Taille d'un item. */
	@Input() public itemSize?: number | null;
	@ObserveProperty<ColumnListComponent<T>>({ sourcePropertyKey: "itemSize" })
	public readonly observableItemSize = new ObservableProperty<number>(70);

	//#endregion PROPERTIES

}
