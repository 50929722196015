import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { StoreHelper } from '@calaosoft/osapp-common/store/helpers/store-helper';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { map, Observable } from "rxjs";
import { PathHelper } from "../../../helpers/path-helper";
import { Store } from "../../../services/store.service";
import { Document } from '../models/document';
import { IDocumentsByPathCache } from "./idocuments-by-path-cache";
import { IDocumentsViewMeta } from "./idocuments-view-meta";
import { IDocumentViewMetaCache } from "./idocuments-view-meta-cache";

export class DocumentsCache {

	//#region FIELDS

	/** Liste des données brutes de la vue des documents triée par path (sans include_docs).
	 * Utilisé pour la gestion des compteurs et des dossiers d'entités par exemple.
	 */
	private readonly moObservableDocumentsViewMetaSortedByPath = new ObservableProperty<IDocumentViewMetaCache[]>([]);

	/** Map qui contient les informations de chargement pour les meta par path.
	 * Utilisé pour la gestion des compteurs et des dossiers d'entités par exemple.
	 */
	private readonly moObservableDocumentsViewMetaLoadedPath = new ObservableProperty<string[]>([]);

	//#endregion FIELDS

	//#region PROPERTIES

	/** Map des emplacements de tous les documents (peu importe les permissions) par leur id. */
	public allDocumentsPathsById = new Map<string, string[]>();

	/** Map des documents lisible par id. */
	public documentsById = new Map<string, Document>();

	/** Map des documents lisible par l'utilisateur classé par son emplacement non strict.
	 * Si un document à pour emplacement `internal/frais` alors il sera rangé dans `internal` et `internal/frais`.
	 */
	public readonly observableDocumentsByPath = new ObservableProperty<IDocumentsByPathCache[]>([]);

	//#endregion PROPERTIES

	//#region METHODS

	public fillDocumentsCache(paAllDocuments: Document[], paReadableDocuments: Document[], psPath: string): void {
		this.allDocumentsPathsById =
			new Map<string, string[]>(paAllDocuments.map((poDocuemnt: Document) => [poDocuemnt._id, poDocuemnt.paths]));

		const loTempDocumentsById = new Map<string, Document>(this.documentsById);
		const laTempDocumentsByPath: IDocumentsByPathCache[] = [...(this.observableDocumentsByPath.value ?? [])];

		if (ArrayHelper.hasElements(paReadableDocuments)) {
			paReadableDocuments.forEach((poDocument: Document) => {
				loTempDocumentsById.set(poDocument._id, poDocument);

				poDocument.paths.forEach((psEndPath: string) => {
					const loPathDocumentsCache: IDocumentsByPathCache | undefined = ArrayHelper.binaryFind(
						laTempDocumentsByPath,
						psEndPath,
						"path"
					);

					if (loPathDocumentsCache) {
						const lnDocumentCacheIndex: number = loPathDocumentsCache.documents.findIndex((poDocumentCache: Document) => poDocumentCache._id === poDocument._id);
						if (lnDocumentCacheIndex !== -1)
							ArrayHelper.removeElementByIndex(loPathDocumentsCache.documents, lnDocumentCacheIndex)
						loPathDocumentsCache.documents.push(poDocument);
					}
					else
						ArrayHelper.binaryInsert(laTempDocumentsByPath, { path: psEndPath, documents: [poDocument] }, "path");
				});
			});
		}
		else
			ArrayHelper.binaryInsert(laTempDocumentsByPath, { path: psPath, documents: [] }, "path");

		this.documentsById = loTempDocumentsById;
		this.observableDocumentsByPath.value = laTempDocumentsByPath;
	}

	public fillDocumentsViewMetaCache(paMeta: IDocumentsViewMeta[], paPaths: string[]): void {
		const laTempDocumentsViewMetaLoadedPath: string[] = this.getDocumentsViewMetaLoadedPath();
		const laTempDocumentsViewMetaSortedByPath: IDocumentViewMetaCache[] = this.getDocumentsViewMetaSortedByPath();
		paMeta.forEach((poMeta: IDocumentsViewMeta) => {
			laTempDocumentsViewMetaSortedByPath.push({ path: StoreHelper.getDocumentCacheData(poMeta)?.key ?? "", meta: poMeta });
			poMeta.otherPaths.forEach((psPath: string) =>
				laTempDocumentsViewMetaSortedByPath.push({ path: psPath, meta: poMeta })
			);
		});

		paPaths.forEach((psPath: string) =>
			ArrayHelper.binaryInsert(laTempDocumentsViewMetaLoadedPath, psPath, undefined, true)
		);

		laTempDocumentsViewMetaSortedByPath.sort(
			(poA: IDocumentViewMetaCache, poB: IDocumentViewMetaCache) =>
				poA.path > poB.path ? 1 : poA.path < poB.path ? -1 : 0
		);

		this.moObservableDocumentsViewMetaLoadedPath.value = laTempDocumentsViewMetaLoadedPath;
		this.moObservableDocumentsViewMetaSortedByPath.value = laTempDocumentsViewMetaSortedByPath;
	}

	private getDocumentsViewMetaSortedByPath(): IDocumentViewMetaCache[] {
		return [...(this.moObservableDocumentsViewMetaSortedByPath.value ?? [])];
	}

	public getDocumentsViewMetaCacheFromCache$(psPath: string): Observable<IDocumentViewMetaCache[]> {
		return this.moObservableDocumentsViewMetaSortedByPath.value$.pipe(
			map((paCachedMeta: IDocumentViewMetaCache[]) =>
				// On trie les chemins de base pour avoir des résultats triés par path.
				this.requestViewMetaCacheByPath(paCachedMeta, psPath)
			)
		);
	}

	public requestViewMetaCacheByPath(paCachedMeta: IDocumentViewMetaCache[], psPath: string): IDocumentViewMetaCache[] {
		const lsPath: string = PathHelper.preparePath(psPath);
		return ArrayHelper.binarySliceBy(
			paCachedMeta,
			{
				from: this.createLogicalDocumentsViewMetaCache(lsPath),
				to: this.createLogicalDocumentsViewMetaCache(`${lsPath}${Store.C_ANYTHING_CODE_ASCII}`)
			},
			(poMeta: IDocumentViewMetaCache) => poMeta.path
		);
	}

	public requestViewMetaByPath(paCachedMeta: IDocumentViewMetaCache[], psPath: string): IDocumentsViewMeta[] {
		return this.requestViewMetaCacheByPath(paCachedMeta, psPath).map((poCache: IDocumentViewMetaCache) => poCache.meta);
	}

	public getDocumentsViewMetaLoadedPath(): string[] {
		return [...(this.moObservableDocumentsViewMetaLoadedPath.value ?? [])];
	}

	public isDocumentsViewMetaPathLoaded(
		psPath: string,
		paCachedPaths: string[] = this.getDocumentsViewMetaLoadedPath()
	): boolean {
		const laPathParts: string[] = psPath.split(PathHelper.C_DATABASE_PATH_SEPARATOR);
		let lnIndex = 0;
		let lsTestPath = "";
		let lbLoaded = false;

		do {
			lbLoaded = ArrayHelper.binarySearch(paCachedPaths, lsTestPath);
			lsTestPath = laPathParts.slice(0, ++lnIndex).join(PathHelper.C_DATABASE_PATH_SEPARATOR);
		} while (!lbLoaded && lnIndex <= laPathParts.length);

		return lbLoaded;
	}

	public areLoadedViewMetaPaths$(paPaths: string[]): Observable<boolean> {
		return this.moObservableDocumentsViewMetaLoadedPath.value$.pipe(
			map((paLoadedPaths: string[]) => paPaths.every((psPath: string) => this.isDocumentsViewMetaPathLoaded(psPath, paLoadedPaths)))
		);
	}

	/** Permet de créer des documents logiques utiles pour retrouver les indexes dans le tableau trié.
	 * @param psPath
	 */
	private createLogicalDocumentsViewMetaCache(psPath: string = ""): IDocumentViewMetaCache {
		return { path: psPath, meta: { otherPaths: [], _id: "", authorId: "" } };
	}

	public clear(): void {
		this.documentsById.clear();
		this.allDocumentsPathsById.clear();
		this.observableDocumentsByPath.value = [];
		this.moObservableDocumentsViewMetaSortedByPath.value = [];
	}

	//#endregion METHODS

}