import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { EEventOccurrenceActionType } from "./eevent-occurrence-action-type";
import { EventOccurrenceAction } from "./event-occurrence-action";
import { IConstraintLabelParams } from "./iconstraint-label-params";
import { IEventOccurrence } from "./ievent-occurrence";
import { IEventOccurrenceAction } from "./ievent-occurrence-action";
import { IEventOccurrenceParticipantAction } from "./ievent-occurrence-participant-action";

const C_TYPE = EEventOccurrenceActionType.participants;

@ModelMatch((poData: IEventOccurrenceAction) => poData?.type === C_TYPE, EventOccurrenceAction)
export class EventOccurrenceParticipantAction extends EventOccurrenceAction implements IEventOccurrenceParticipantAction {

	//#region PROPERTIES

	public override readonly type: string = C_TYPE;

	/** @implements */
	public add?: string[];
	/** @implements */
	public remove?: string[];

	//#endregion

	//#region METHODS

	constructor(poAction?: Partial<IEventOccurrenceParticipantAction>) {
		super(poAction);
	}

	public override action(poEventOccurrence: IEventOccurrence): void {
		this.add?.forEach((psId: string) => ArrayHelper.pushIfNotPresent(poEventOccurrence.participantIds, psId));
		this.remove?.forEach((psId: string) => ArrayHelper.removeElement(poEventOccurrence.participantIds, psId));
	}

	public override buildlabel(poParams?: IConstraintLabelParams): string {
		throw new Error("Method not implemented.");
	}


	//#endregion

}
