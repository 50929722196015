import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { EntityLink } from '@calaosoft/osapp-common/entities/models/entity-link';
import { EntityLinkEntity } from '@calaosoft/osapp-common/entities/models/entity-link-entity';
import { IEntity } from '@calaosoft/osapp-common/entities/models/ientity';
import { ObserveArray } from '@calaosoft/osapp-common/observable/decorators/observe-array.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { IIndexedArray } from '@calaosoft/osapp-common/utils/models/IIndexedArray';
import { Keyboard } from '@capacitor/keyboard';
import { IonItemSliding, PopoverController } from '@ionic/angular';
import { EMPTY, EmptyError, Observable, combineLatest, defer, from, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mapTo, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { IContact } from '../../../model/contacts/IContact';
import { IGroup } from '../../../model/contacts/IGroup';
import { IGroupMember } from '../../../model/contacts/IGroupMember';
import { EConversationStatus } from '../../../model/conversation/EConversationStatus';
import { IConversation } from '../../../model/conversation/IConversation';
import { IConversationActivity } from '../../../model/conversation/IConversationActivity';
import { IConversationListParams } from '../../../model/conversation/IConversationListParams';
import { IGetConversationOptions } from '../../../model/conversation/IGetConversationOptions';
import { IOpenConversationOptions } from '../../../model/conversation/IOpenConversationOptions';
import { IParticipant } from '../../../model/conversation/IParticipant';
import { EConversationType } from '../../../model/conversation/e-conversation-type';
import { ELinkAction } from '../../../model/link/ELinkAction';
import { ELinkTemplate } from '../../../model/link/ELinkTemplate';
import { LinkInfo } from '../../../model/link/LinkInfo';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EAvatarSize } from '../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../model/picture/IAvatar';
import { IPopoverItemParams } from '../../../model/popover/IPopoverItemParams';
import { ISearchOptions } from '../../../model/search/ISearchOptions';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { Message } from '../../../modules/conversations/model/message';
import { Loader } from '../../../modules/loading/Loader';
import { EModalSize } from '../../../modules/modal';
import { ModalService } from '../../../modules/modal/services/modal.service';
import { HasPermissions } from '../../../modules/permissions/decorators/has-permissions.decorator';
import { EPermissionScopes } from '../../../modules/permissions/models/epermission-scopes';
import { IHasPermission, PermissionsService } from '../../../modules/permissions/services/permissions.service';
import { ApplicationService } from '../../../services/application.service';
import { ArchivingService } from '../../../services/archiving.service';
import { ContactsService } from '../../../services/contacts.service';
import { ConversationService } from '../../../services/conversation.service';
import { EntityLinkService } from '../../../services/entityLink.service';
import { GroupsService } from '../../../services/groups.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '../../../services/loading.service';
import { PatternResolverService } from '../../../services/pattern-resolver.service';
import { PopoverService } from '../../../services/popover.service';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { WorkspaceService } from '../../../services/workspace.service';
import { InteropModalComponent } from '../../interoperabilite/interopModal/interop-modal.component';
import { LinkPopoverComponent } from '../../popover/linkPopover.component';
import { ConversationComponent } from '../conversation.component';

interface IHydratedConversation extends IConversation {
	lastMessageAvatar?: IAvatar;
	conversationAvatar?: IAvatar | string;
	subtitle?: string;
	userActivity?: IConversationActivity;
};

@Component({
	selector: "calao-conversation-list",
	templateUrl: './conversations-list.component.html',
	styleUrls: ['./conversations-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationsListComponent extends ComponentBase implements OnInit, IHasPermission {

	//#region FIELDS

	private static readonly C_LOG_ID = "CONVLIST.C::";

	private readonly moActivePageManager: ActivePageManager;

	/** Objet itemSliding qui est actif. */
	private moActiveItemSliding?: IonItemSliding;
	/** Objet de paramètres pour la récupération des conversations. */
	private moGetConversationOptions: IGetConversationOptions;
	/**	Map des dates d'ajout de l'utilisateur dans un groupe par l'id du groupe. */
	private moUserJoinDateByGroupId = new Map<string, Date>();

	//#endregion

	//#region PROPERTIES

	@Input() public params: IConversationListParams;

	public readonly userContactPath?: string;
	public readonly userContactId: string = ContactsService.getUserContactId();
	public readonly deletedMessageBody: string = ConversationComponent.C_DELETED_MESSAGE_BODY;

	@HasPermissions({ permission: "delete" })
	/** Indique si l'utilisateur peut supprimer des conversations. */
	public get canDelete(): boolean { return true; }

	@HasPermissions({ permission: "mailbox", permissionScopes: [EPermissionScopes.conversations, EPermissionScopes.interoperabilite] })
	/** Indique si l'utilisateur a accès a une interop mail. */
	public get canInteropMailbox(): boolean { return true; }

	public readonly permissionScope: EPermissionScopes = EPermissionScopes.conversations;
	/** Tableau de toutes les conversations. */
	public readonly conversations = new ObservableArray<IHydratedConversation>();

	/** Liste des participants indexés par identifiant de conversation. */
	public participants: IIndexedArray<IParticipant<IGroupMember>[]> = {};
	/** Permet de savoir si le bouton de suppression doit être affiché */
	public enableDelete = false;
	/** Indique si on est en version de production ou non. */
	public isProductionEnvironment: boolean;
	/** Optionspour le composant de recherche. */
	public searchOptions: ISearchOptions<IConversation>;
	/** Valeur à passer à la barre de recherche. */
	public searchValue = "";

	public conversationTypeEnabled: EConversationType[] = [];
	public conversationTypeFiltered: EConversationType[] = [];

	private mbIsDownloading = false;
	public get isDownloading(): boolean { return this.mbIsDownloading; }
	public set isDownloading(pbNewValue: boolean) {
		if (pbNewValue !== this.mbIsDownloading) {
			this.mbIsDownloading = pbNewValue;
			this.detectChanges();
		}
	}

	/** Conversations filtrées. */
	private maFilteredConversations: IConversation[] = [];
	public get filteredConversations(): IConversation[] { return this.filterConversationType(this.maFilteredConversations); }
	@Input() public set filteredConversations(paConversations: IConversation[]) {
		if (this.maFilteredConversations !== paConversations) {
			this.maFilteredConversations = paConversations;
			this.detectChanges();
		}
	}

	/** Affichage ou non du bouton de création. */
	private mbHideFabButton: boolean;
	public get hideFabButton(): boolean { return this.mbHideFabButton; }
	@Input() public set hideFabButton(pbValue: boolean | string) {
		if (this.mbHideFabButton !== pbValue) {
			this.mbHideFabButton = coerceBooleanProperty(pbValue);
			this.detectChanges();
		}
	}

	private msConversationsStatusFilter: EConversationStatus;
	public get conversationsStatusFilter(): EConversationStatus { return this.msConversationsStatusFilter; }
	@Input() public set conversationsStatusFilter(psStatus: EConversationStatus) {
		if (this.msConversationsStatusFilter !== psStatus) {
			this.msConversationsStatusFilter = psStatus;
			this.detectChanges();
		}
	}

	/** Tableau d'objet indexé contenant une activité utilisateur pour valeur et la conversation liée en clé. */
	private moUserActivityByConvId: IIndexedArray<IConversationActivity>;
	private set userActivityByConvId(poValue: IIndexedArray<IConversationActivity>) {
		if (this.moUserActivityByConvId !== poValue) {
			this.moUserActivityByConvId = poValue;
			this.detectChanges();
		}
	}

	/** Tableau des options avancées à afficher dans les popovers. */
	public advancedOptions?: IPopoverItemParams[];
	@ObserveArray<ConversationsListComponent>("advancedOptions")
	public readonly observableAdvancedOptions = new ObservableArray<IPopoverItemParams>(this.getAdvancedOptions());

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcConversation: ConversationService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioPopoverCtrl: PopoverController,
		private readonly isvcLoading: LoadingService,
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcContact: ContactsService,
		private readonly isvcPatternResolver: PatternResolverService,
		public readonly isvcPermissions: PermissionsService,
		private readonly isvcArchiving: ArchivingService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcGroups: GroupsService,
		private readonly isvcPopover: PopoverService,
		private readonly isvcModal: ModalService,
		poRouter: Router,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);

		this.moActivePageManager = new ActivePageManager(this, poRouter);
		this.userContactPath = this.isvcConversation.getCurrentUserContactPath();
		this.isProductionEnvironment = ConfigData.isProductionEnvironment;
		this.initSearchOptions();
	}

	public ngOnInit(): void {
		if (!this.params)
			this.params = {};

		this.setLinkedEntities();
		this.conversations.changes$.pipe(tap(_ => this.initConversationTypeEnabled()), takeUntil(this.destroyed$)).subscribe();
		this.conversations.resetSubscription(this.initConversations$().pipe(takeUntil(this.destroyed$)));
	}

	private initSearchOptions(): void {
		this.searchOptions = {
			searchboxPlaceholder: "Rechercher une conversation",
			hasPreFillData: true,
			searchFunction: (poConversation: IConversation, psSearchValue: string) => this.conversationSearchFunction(poConversation, psSearchValue)
		};
	}

	/** Remplis le tableau `this.params.linkedEntities` en resolvant les patterns de `this.params.linkedEntitesPattern`. */
	private setLinkedEntities(): void {
		if (this.params && ArrayHelper.hasElements(this.params.linkedEntities)) {
			for (let i = 0; i < this.params.linkedEntities.length; i++) {
				if (typeof this.params.linkedEntities[i] === "string") {
					// On résoud l'élément.
					const loResolvedEntity: IEntity = this.isvcPatternResolver.resolveFormsPattern<IEntity>(this.params.linkedEntities[i] as string);

					// Si résolution correct, on l'ajoute au tableau des éléments résolus.
					if (loResolvedEntity)
						this.params.linkedEntities[i] = loResolvedEntity;
					else
						console.error(`${ConversationsListComponent.C_LOG_ID}Entity pattern not resolved correctly : `, this.params.linkedEntities[i]);
				}
			}
		}
	}

	/** Récupère les conversations en local et/ou distant suivant l'état du réseau. */
	private initConversations$(): Observable<IConversation[]> {
		const lbHasLinkedEntities: boolean = ArrayHelper.hasElements(this.params.linkedEntities);

		this.setGetConversationOptions(lbHasLinkedEntities);

		return defer(() => lbHasLinkedEntities ? this.getConversationIdsFromParams$(this.params.linkedEntities!) : of(undefined))
			.pipe(
				mergeMap(_ => this.getConversations$()),
				tap(_ => this.isDownloading = true),
				map((paConversations: IConversation[]) => this.filterConversations(paConversations)),
				// On utilise un switchMap car on veut appeler les opérateurs suivants si la liste des conversations est modifiée ou si un document d'activité est modifié.
				// Le bouton d'options est absent pour une nouvelle conversation puis doit apparaître si la conversation est lue (doc d'activité mise à jour).
				// Optimisation possible: Au lieu de faire un getLive à chaque fois que la liste des conversations est modifiée, on devrait faire un getLive si une nouvelle conversation ou si une conversation est supprimée.
				switchMap((paConversations: IConversation[]) => this.setUserActivitiesByConvId$(paConversations).pipe(mapTo(paConversations))),
				switchMap((paConversations: IConversation[]) => this.isvcGroups.getUserJoinDateByGroupId$(true, this.moActivePageManager).pipe(
					tap((poUserJoinDateByGroupId: Map<string, Date>) => this.moUserJoinDateByGroupId = poUserJoinDateByGroupId),
					mapTo(paConversations)
				)),
				tap(_ => this.isDownloading = false),
				mergeMap((paConversations: IConversation[]) => this.setParticipantsByConvId$(paConversations).pipe(mapTo(paConversations))),
				tap((paConversations: IConversation[]) => this.setConversationsInfo(paConversations)),
				catchError(poError => this.onInitGetConversationsError$(poError))
			);
	}

	private setGetConversationOptions(pbHasLinkedEntities: boolean): void {
		if (!this.moGetConversationOptions) {
			this.moGetConversationOptions = {
				sortRequired: true,
				conversationIds: pbHasLinkedEntities ? [] : undefined,
				activePageManager: this.moActivePageManager
			};
		}

		this.moGetConversationOptions.live = true; // On veut récupérer les ajouts/suppressions/modifications locales des conversations.
	}

	/** Récupère les identifiants des conversations à récupérer.
	 * @param paLinkedEntities Tableau des entités dont on veut récupérer les conversations liées. Chaque chaîne de caractères sera considéré comme un pattern et résolu.
	 */
	private getConversationIdsFromParams$(paLinkedEntities: (string | IEntity)[]): Observable<string[] | undefined> {
		return combineLatest(
			paLinkedEntities.map((poIdOrEntity: string | IEntity) =>
				this.isvcEntityLink.getEntityLinks(typeof poIdOrEntity === "string" ? poIdOrEntity : poIdOrEntity._id, [EPrefix.conversation], undefined, true)
			)
		).pipe(
			map((paEntityLinks: EntityLink[][]) => {
				return this.moGetConversationOptions.conversationIds = ArrayHelper.unique(paEntityLinks.flat()
					.map((poEntityLink: EntityLink) =>
						poEntityLink.getTargetEntitiesByTargetPrefix(EPrefix.conversation).map((poEntity: EntityLinkEntity) =>
							poEntity.id
						)
					).flat());
			})
		);
	}

	private getConversations$(): Observable<IConversation[]> {
		let loLoader: Loader;

		return from(this.isvcLoading.create(ApplicationService.C_LOAD_DATA_LOADER_TEXT))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.isvcConversation.getConversations(this.userContactId, this.moGetConversationOptions)),
				tap(_ => {
					if (loLoader?.isPresented) // On dissmiss le loader après premier chargement.
						loLoader?.dismiss(); // On retire le loader quand les données sont chargées.
				}),
				finalize(() => loLoader?.dismiss()) // On retire le loader en cas d'erreur ou si le flux est clôturé avant d'avoir atteint le tap (ex: Retour avec bouton physique).
			);
	}

	private filterConversations(paConversations: IConversation[]): IConversation[] {
		if (!this.params?.filterConversationsByWorkspace)
			return paConversations;
		else {
			const lsWorkspaceDbId: string = this.isvcWorkspace.getDefaultWorkspaceDatabaseId();

			return paConversations.filter((poConversation: IConversation) =>
				poConversation.participants?.some((poParticipant: IParticipant) => poParticipant.participantPath?.startsWith(lsWorkspaceDbId))
			);
		}
	}

	private setUserActivitiesByConvId$(paConversations: IConversation[]): Observable<void> {
		return this.isvcConversation.getConversationsUserActivities(paConversations)
			.pipe(map((poUserActivities: IIndexedArray<IConversationActivity>) => { this.moUserActivityByConvId = poUserActivities; }));
	}

	private setParticipantsByConvId$(paConversations: IConversation[]): Observable<void> {
		return this.isvcConversation.getConversationsParticipants(paConversations)
			.pipe(map((poParticipants: IIndexedArray<IParticipant<IContact>[]>) => { this.participants = poParticipants; }));
	}

	/** Affecte des informations (titre, avatar, dernier message, ...) pour les conversations associées.
	 * @param paConversations Tableau des conversations dont il faut affecter des informations.
	 */
	private setConversationsInfo(paConversations: IHydratedConversation[]): void {
		paConversations.forEach((poConversation: IConversation) => {
			this.setConversationTitleAndAvatar(poConversation);
			this.setLastMessageAvatar(poConversation);
		});

		const loConversationsByConvId: Map<string, IHydratedConversation> = ArrayHelper.groupByUnique(
			paConversations,
			(poConversation: IHydratedConversation) => poConversation._id
		);

		Object.keys(this.moUserActivityByConvId)
			.forEach((psConvId: string) => {
				if (loConversationsByConvId.has(psConvId))
					loConversationsByConvId.get(psConvId)!.userActivity = this.moUserActivityByConvId[psConvId];
			});

		this.detectChanges();
	}

	/** Affecte un titre et un avatar pour une conversation donnée.
	 * @param poConversation Conversation dont il faut affecter un titre et potentiellement un avatar.
	 */
	private setConversationTitleAndAvatar(poConversation: IHydratedConversation): void {
		const lsIconContacts = "contacts";
		const lsIconGroups = "groups";
		const laParticipants: IParticipant[] = this.getOtherContactParticipants(this.participants[poConversation._id]);
		const laGroups: IParticipant<IGroup>[] = this.isvcConversation.getGroups(this.participants[poConversation._id]);
		const lnParticipantsLength: number = laParticipants.length;
		const lnGroupsLength: number = laGroups.length;

		poConversation.subtitle = this.isvcConversation.getDefaultTitle(poConversation);

		if (lnParticipantsLength === 1 && lnGroupsLength === 0) { // Un seul participant et aucun groupe.
			const loFirstParticipantAvatar: IAvatar | undefined = ArrayHelper.getFirstElement(laParticipants).avatar;
			poConversation.conversationAvatar = loFirstParticipantAvatar ? loFirstParticipantAvatar : lsIconContacts;
		}
		else {
			if (lnGroupsLength === 0) // S'il n'y a pas de groupes et plusieurs contacts.
				poConversation.conversationAvatar = lsIconContacts;
			else { // S'il y a au moins un groupe.
				const loFirstGroupAvatar: IAvatar | undefined = ArrayHelper.getFirstElement(laGroups).avatar;
				if (lnGroupsLength === 1 && lnParticipantsLength === 0 && loFirstGroupAvatar) // Un seul groupe et qui possède un avatar, et pas de contact.
					poConversation.conversationAvatar = loFirstGroupAvatar;
				else if (lnGroupsLength > 0) // S'il y a au moins un groupe.
					poConversation.conversationAvatar = lsIconGroups;
			}
		}
	}

	/** Génère un titre à partir du dernier message envoyé de la conversation ainsi que l'avatar de l'envoyeur.
	 * @param poConversation Conversation à partir de laquelle on veut générer le titre et l'avatar.
	 */
	private setLastMessageAvatar(poConversation: IHydratedConversation): void {
		let lsMemberId: string;

		try {
			if (poConversation.lastMessage) {
				if (!StringHelper.isBlank(poConversation.lastMessage?.senderContactPath))
					lsMemberId = Store.getDocumentIdFromPath(poConversation.lastMessage.senderContactPath);
				else // Pour rétro-compat des messages qui n'ont que le sender.
					lsMemberId = ((poConversation.lastMessage as any)?.sender as string).replace(EPrefix.user, EPrefix.contact);

				const loParticipant: IParticipant<IGroupMember> | undefined = this.isvcConversation.findParticipant(this.participants[poConversation._id], lsMemberId);

				if (loParticipant) { // NB : il est possible qu'un message ait été émis par un contact qui a, depuis, été retiré des participants.
					const loAvatar: IAvatar | undefined = ObjectHelper.clone(loParticipant.avatar, true);
					if (loAvatar)
						loAvatar.size = EAvatarSize.small;
					poConversation.lastMessageAvatar = loAvatar;
				}
				else
					console.debug(`${ConversationsListComponent.C_LOG_ID}Contact ${lsMemberId} is not participant of conversation ${poConversation._id} anymore. Displaying its avatar is not supported.`);
			}
		}
		catch (poError) { // Ne pas empêcher le chargement de la liste en cas de problème avec 1 avatar/dernier message.
			console.error(`${ConversationsListComponent.C_LOG_ID}Error while displaying conversation ${poConversation._id} last message.`, poError);
		}
	}

	private onInitGetConversationsError$(poError: any): Observable<IConversation[] | never> {
		if (poError instanceof EmptyError)
			return of([]);
		else {
			console.error(`${ConversationsListComponent.C_LOG_ID}Erreur getConversations() : `, poError);
			return throwError(() => poError);
		}
	}

	/** Ferme les options révélées de l'itemSliding. */
	private closeItemSlidingOptions(): void {
		if (this.moActiveItemSliding) {
			this.moActiveItemSliding.close();
			this.moActiveItemSliding = undefined;
		}
	}

	/** Exécute la suppression de la conversation après clic du bouton de confirmation.
	 * @param poConversation Conversation à supprimer.
	 */
	private execDelete(poConversation: IConversation): Observable<boolean> {
		return this.isvcConversation.delete(poConversation)
			.pipe(
				map(_ => {
					this.closeItemSlidingOptions();

					return !!ArrayHelper.removeElementById(this.filteredConversations, poConversation._id);
				}),
				tap(_ => this.detectChanges())
			);
	}

	/** Récupère les participants d'une conversation, hormis l'utilisateur.
	 * @param paParticipants Liste des participants.
	 */
	private getOtherContactParticipants(paParticipants?: IParticipant<IGroupMember>[]): IParticipant[] {
		return this.isvcConversation.getContacts(paParticipants)
			.filter((poParticipant: IParticipant<IContact>) => poParticipant.participantId !== this.userContactId);
	}

	public onConversationDelete(poConversation: IConversation): void {
		this.isvcConversation.isDeletable(poConversation)
			.pipe(
				mergeMap((pbCanDelete: boolean) => pbCanDelete ? this.showDeleteConversationPopup() : EMPTY),
				filter((poResponse: IUiResponse<boolean>) => !!poResponse.response),
				mergeMap(_ => this.execDelete(poConversation)),
				catchError(poError => { console.error(ConversationsListComponent.C_LOG_ID, poError); return EMPTY; }),
				finalize(() => this.closeItemSlidingOptions()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private showDeleteConversationPopup(): Observable<IUiResponse<boolean>> {
		return this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				message: "Cette conversation sera supprimée pour TOUS les utilisateurs ! <br/> Voulez-vous continuer ?",
				header: "Suppression",
				backdropDismiss: false,
				buttons: [
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse() }
				]
			})
		);
	}

	/** Permet de passer à une page de conversation (vue ou création).
	 * @param poConversation Conversation cliquée, si non renseigné alors la conversation sera en mode création.
	 */
	public onItemClicked(poConversation: IConversation): void {
		Keyboard.hide().catch(() => { });
		this.isvcConversation.routeToConversation(poConversation);
	}

	/** Événement levé lors d'un swipe qui permet de fermer les items qui sont ouverts pour qu'un seul ne le soit en même temps.
	 * @param poItemSliding Liste des items pouvant être glissés pour révéler des boutons.
	 */
	public onSwipingEvent(poItemSliding: IonItemSliding): void {
		if (!this.moActiveItemSliding || this.moActiveItemSliding !== poItemSliding) // L'item sélectionné est différent de celui déjà ouvert, on ouvre le nouveau.
			this.moActiveItemSliding = poItemSliding;
		else // L'item cliqué est celui déjà ouvert, on le ferme.
			this.closeItemSlidingOptions();
	}

	@Queue<ConversationsListComponent, Parameters<ConversationsListComponent["createConversation$"]>, ReturnType<ConversationsListComponent["createConversation$"]>>({
		excludePendings: true
	})
	/** Crée et retourne une nouvelle conversation. */
	private createConversation$(): Observable<IConversation | undefined> {
		this.disableFabButton();

		return this.isvcConversation.createOrOpenConversation(this.userContactId, this.createOpenConvOptions())
			.pipe(
				tap(
					_ => { },
					poError => console.error(ConversationsListComponent.C_LOG_ID, poError)
				),
				takeUntil(this.destroyed$),
				finalize(() => this.enableFabButton())
			);
	}

	/** Supprime et recrée la toolbar en ajoutant le fab button de création de conversations désactivé. */
	private disableFabButton(): void {
		this.hideFabButton = true;
	}

	/** Supprime et recrée la toolbar en ajoutant le fab button de création de conversations activé. */
	private enableFabButton(): void {
		this.hideFabButton = false;
	}

	/** Gestion du click du fab button de création de conversations. */
	public onCreateConversationClicked(): void {
		this.createConversation$().subscribe();
	}

	private createOpenConvOptions(pbIsModal: boolean = false): IOpenConversationOptions {
		return {
			galleryAcceptFiles: this.params.galleryAcceptFiles,
			currentContactId: this.userContactId,
			isModal: pbIsModal,
			linkedEntities: this.params.linkedEntities,
			contactSelectorParams: this.params.contactSelectorParams
		} as IOpenConversationOptions;
	}

	/** Ouvre les options de l'itemSliding après un swipe ou clic sur le bouton des options.
	 * @param poItemSliding Liste des items pouvant être glissés pour révéler des boutons.
	 */
	public openItemSlidingOptions(poItemSliding: IonItemSliding): void {
		this.onSwipingEvent(poItemSliding);

		// Ouvre l'ItemSliding s'il est fermé, sinon l'ouvre.
		(poItemSliding as IonItemSliding & { el: { classList: DOMTokenList } }).el.classList.contains("item-sliding-active-slide") ? poItemSliding.close() : poItemSliding.open("end");
	}

	/** Méthode appelée lors du clic sur l'avatar d'une conversation.
	 * @param poConversation Conversation associée à l'avatar.
	 * @param poEvent Événement du clic.
	 */
	public onAvatarClicked(poConversation: IConversation, poEvent: Event): void {
		const laParticipants: IParticipant[] = this.getOtherContactParticipants(this.participants[poConversation._id]);

		if (laParticipants.length === 1) {
			const loPopover: Promise<HTMLIonPopoverElement> = this.ioPopoverCtrl.create({
				component: LinkPopoverComponent,
				componentProps: {
					links: this.createPopoverLinksInfo(ArrayHelper.getFirstElement(laParticipants))
				},
				event: poEvent
			});

			loPopover.then((poPopover: HTMLIonPopoverElement) => poPopover.present());
		}
	}

	/** Crée les informations pour la créations des liens du menu contextuel.
	 * @param poParticipant Participant dont il faut afficher le contact.
	 */
	private createPopoverLinksInfo(poParticipant: IParticipant): LinkInfo[] {
		return [
			this.getContactNavigationLinkInfo(poParticipant),
			this.getContactFilterLinkInfo(poParticipant)
		];
	}

	private getContactNavigationLinkInfo(poParticipant: IParticipant): LinkInfo {
		return new LinkInfo({
			label: "Voir le contact",
			action: ELinkAction.callback,
			templateId: ELinkTemplate.item,
			actionParams: {
				function: () => {
					this.isvcConversation.getContactFromParticipantAsync(poParticipant)
						.then((poContact?: IContact) => {
							if (poContact)
								return this.isvcContact.routeToContact(poContact);
							else {
								console.error(`${ConversationsListComponent.C_LOG_ID}Error while getting contact from participant '${poParticipant.participantPath}' : it does not exist !`);
								this.isvcUiMessage.showPopupMessage(
									new ShowMessageParamsPopup({
										header: "Erreur",
										message: "Un problème est survenu, impossible de naviguer vers la fiche contact du participant."
									})
								);
							}
						});
				}
			}
		});
	}

	private getContactFilterLinkInfo(poParticipant: IParticipant): LinkInfo {
		return new LinkInfo({
			label: "Filtrer par ce contact",
			action: ELinkAction.callback,
			templateId: ELinkTemplate.item,
			actionParams: {
				function: () => {
					this.searchValue = poParticipant.label;
					this.detectChanges();
				}
			}
		});
	}

	/** Fonction de recherche de conversations.
	 * @param poConversation Conversation sur laquelle rechercher.
	 * @param psSearchValue Valeur à recherche dans la conversation.
	 */
	private conversationSearchFunction(poConversation: IConversation, psSearchValue: string): boolean {
		if (!poConversation.title)
			return false;

		return this.innerConversationSearchFunction(poConversation.title, psSearchValue) ||
			poConversation.participants?.some((poParticipant: IParticipant) => this.innerConversationSearchFunction(poParticipant.label, psSearchValue)) ||
			(!!poConversation.lastMessage && this.innerConversationSearchFunction(poConversation.lastMessage.body, psSearchValue));
	}

	/** Permet de savoir si le champs correspond à la valeur recherchée.
	 * @param psField Champs de la conversation.
	 * @param psSearchValue Valeur à recherche dans le champs.
	 */
	private innerConversationSearchFunction(psField: string, psSearchValue: string): boolean {
		return !StringHelper.isBlank(psField) && psField.toLowerCase().indexOf(psSearchValue.toLowerCase()) >= 0;
	}

	/** Méthode appelée lors d'un changement dans les conversations filtrées.
	 * @param paFilteredConversations Tableau des conversations répondant au filtre de recherche.
	 */
	public onFilteredConversationsChanged(paFilteredConversations: IConversation[]): void {
		if (!ArrayHelper.areArraysFromDatabaseEqual(this.filteredConversations, paFilteredConversations))
			this.filteredConversations = this.filterConversationByStatus(paFilteredConversations);
	}

	/** Appelle le filtre correspondant au statut.
	 * @param paFilteredConversations Conversations à filtrer.
	 */
	private filterConversationByStatus(paFilteredConversations: IConversation[]): IConversation[] {
		if (this.conversationsStatusFilter === EConversationStatus.active)
			return this.getFilteredActiveConversations(paFilteredConversations);
		else if (this.conversationsStatusFilter === EConversationStatus.archived)
			return this.getFilteredArchivedConversations(paFilteredConversations);
		else
			return paFilteredConversations;
	}

	/** Retourne les conversations actives.
	 * @param paConversations Conversations à filtrer.
	 */
	private getFilteredActiveConversations(paConversations: IConversation[]): IConversation[] {
		return paConversations.filter((poConversation: IConversation) => !this.isConversationArchived(poConversation));
	}

	/** Retourne `true` si la conversation est active.
	 * @param poConversation La conversation à vérifier.
	 */
	public isActiveConversation(poConversation: IConversation): boolean {
		return !this.isConversationArchived(poConversation);
	}

	public canArchive(poConversation: IConversation): boolean {
		return this.hasActivity(poConversation) && this.isRead(poConversation) && this.isActiveConversation(poConversation);
	}

	/** Retourne `true` si la conversation est archivée et peut être restaurée. */
	public canRestore(poConversation: IConversation): boolean {
		return !this.isActiveConversation(poConversation);
	}

	/** Retourne `true` si la conversation possède au moins un bouton d'options. */
	public hasOptionButtons(poConversation: IConversation): boolean {
		return this.canDelete || this.canArchive(poConversation) || this.canRestore(poConversation);
	}

	/** Retourne les conversation archivées.
	 * @param paConversations Les conversation à filtrer.
	 */
	private getFilteredArchivedConversations(paConversations: IConversation[]): IConversation[] {
		return paConversations.filter((poConversation: IConversation) => this.isConversationArchived(poConversation));
	}

	/** Retourne `true` si la conversation est archivée, sinon false.
	 * @param poConversation La conversation à vérifier.
	 */
	private isConversationArchived(poConversation: IConversation): boolean {
		const loUserActivity: IConversationActivity | undefined = this.moUserActivityByConvId[poConversation._id];

		// Pour que la conversation soit considérée comme archivée, il faut que l'activité utilisateur l'indique et que la conversation soit considérée comme lue.
		if (loUserActivity?.archive && this.isRead(poConversation)) {
			if (!poConversation.lastMessage) // Aucun message n'a été posté dans la conversation.
				return true;
			// L'archivage a eu lieu après le dernier message.
			else if (DateHelper.compareTwoDates(loUserActivity.archive, poConversation.lastMessage.createDate) > 0)
				return true;
		}

		return false; // Par défaut, la conversation n'est pas archivée.
	}

	/** Retourne la liste des conversations de l'activité la plus récente à la moins récente. */
	public orderConversationsByDate(paConversations: IConversation[]): IConversation[] {
		return paConversations.sort((poConv1: IConversation, poConv2: IConversation) => {
			// On prend l'activité du dernier message s'il existe, sinon celle de la date de création de la conversation.
			return DateHelper.compareTwoDates(
				poConv2.lastMessage ? poConv2.lastMessage.createDate : poConv2.createDate,
				poConv1.lastMessage ? poConv1.lastMessage.createDate : poConv1.createDate
			);
		});
	}

	/** Permet de récuperer la classe CSS pour l'affichage de la conversation.
	 * @param poConversation
	 */
	public isRead(poConversation: IHydratedConversation): boolean {
		return !poConversation || this.isvcConversation.isRead(poConversation, this.moUserJoinDateByGroupId, poConversation.userActivity);
	}

	/** Retourne `true` si l'utilisateur a une activité sur la conversation, sinon `false`.
	 * @param poConversation La conversation à vérifier.
	 */
	public hasActivity(poConversation: IConversation): boolean {
		return coerceBooleanProperty(this.moUserActivityByConvId[poConversation._id]);
	}

	/** Archive la conversation.
	 * @param poConversation La conversation à archiver.
	 */
	public onConversationArchiveClicked(poConversation: IConversation): void {
		this.archiveOrRestoreConversation$(poConversation, "archive").subscribe();
	}

	/** Restaure la conversation.
	 * @param poConversation La conversation à restaurer.
	 */
	public onConversationRestoreClicked(poConversation: IConversation): void {
		this.archiveOrRestoreConversation$(poConversation, "restore").subscribe();
	}

	private archiveOrRestoreConversation$(poConversation: IConversation, psMode: "archive" | "restore"): Observable<void> {
		const lsConversationUserActivityId: string = this.isvcConversation.getConversationUserActivityId(poConversation);

		return this.isvcConversation.getConversationUserActivity(lsConversationUserActivityId)
			.pipe(
				mergeMap((poConversationActivity: IConversationActivity) => psMode === "archive" ?
					this.isvcArchiving.archiveConversation(poConversationActivity) : this.isvcArchiving.restoreConversation(poConversationActivity)
				),
				map((poUserActivity: IConversationActivity) => {
					if (this.msConversationsStatusFilter)
						this.filteredConversations = this.filteredConversations.filter((poFilteredConversation: IConversation) => poFilteredConversation._id !== poConversation._id);
					else
						this.userActivityByConvId = { ...this.moUserActivityByConvId, [poConversation._id]: poUserActivity };
				})
			);
	}

	public getConversationClass(poConversation: IConversation): string {
		let lsClass: string;

		if (this.isRead(poConversation))
			lsClass = "read-item";
		else
			lsClass = "not-read-item";

		return lsClass;
	}

	public presentAdvancedOptions(poEvent: Event): Promise<HTMLIonPopoverElement> {
		return this.isvcPopover.showPopoverAsync(this.observableAdvancedOptions, poEvent as MouseEvent);
	}

	private getAdvancedOptions(): IPopoverItemParams[] {
		const laAdvancedOptions: IPopoverItemParams[] = [];
		if (this.canInteropMailbox) {
			laAdvancedOptions.push({
				color: "primary",
				icon: "download",
				title: "Connecter CalaoTrade à ...",
				action: () => this.openInteropModal$()
			});
		}
		return laAdvancedOptions;
	}

	private openInteropModal$(): Observable<any> {
		return this.isvcModal.open({
			component: InteropModalComponent,
			componentProps: {},
			cssClass: "transparent"
		}, EModalSize.medium);
	}

	private initConversationTypeEnabled() {
		this.conversationTypeEnabled = [EConversationType.chat];
		if (this.conversations.some((poConversation: IConversation) => poConversation.conversationType ? poConversation.conversationType == EConversationType.mail : false)
		) {
			this.conversationTypeEnabled.push(EConversationType.mail);
		}
		this.conversationTypeFiltered = this.conversationTypeEnabled;
	}

	public switchConversationTypeEnabled(psConversationType: EConversationType): void {
		if (this.conversationTypeFiltered.includes(psConversationType)) {
			if (this.conversationTypeFiltered.length !== 1) {
				this.conversationTypeFiltered = this.conversationTypeFiltered.filter((psConversationTypeInArray: string) => psConversationTypeInArray !== psConversationType);
			}
		} else {
			this.conversationTypeFiltered.push(psConversationType);
		}
	}

	public isConversationTypeFiltered(psConversationType: EConversationType): boolean {
		return this.conversationTypeFiltered.includes(psConversationType);
	}

	public filterConversationType(paConversationType: IConversation[]): IConversation[] {
		return paConversationType.filter((poConversation: IConversation) => this.isConversationFilteredByConversationType(poConversation));
	}

	private isConversationFilteredByConversationType(poConversation: IConversation): boolean {
		return this.conversationTypeFiltered.includes(poConversation.conversationType ?? EConversationType.chat);
	}

	public getLogoByConversationType(poConversation: IConversation): string {
		switch (poConversation.conversationType) {

			case "mail":
				return "mail-sharp";
			case "chat":
				return "chatbubbles";

			default:
				return "chatbubbles";
		}
	}

	public removeHtmlMessage(poMessage: Message): string {
		return StringHelper.removeHtmlTags(poMessage.body);
	}

	//#endregion

}