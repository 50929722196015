import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileIndexorService } from './services/mobile-indexor.service';



@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [MobileIndexorService]
})
export class IndexorModule { }
