import { Exclude, Expose } from '@calaosoft/osapp-common/class-transformer';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ETimetablePattern } from '@calaosoft/osapp-common/dates/models/ETimetablePattern';
import { ResolveModel } from "@calaosoft/osapp-common/utils/decorators/resolve-model.decorator";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { IRange } from '@calaosoft/osapp-common/utils/models/irange';
import { RRule, Options as RRuleOptions } from 'rrule';
import { ERecurrenceType } from "./erecurrence-type";
import { IRecurrence } from './irecurrence';

export class Recurrence implements IRecurrence {

	//#region FIELDS

	private static readonly C_LOG_ID = "RECURR::";
	private static readonly C_MAX_DATE: Date = new Date(9999, 12, 31, 23, 59, 59, 999);

	//#endregion

	//#region PROPERTIES

	public type: ERecurrenceType;
	public every: number;

	@Exclude()
	@ResolveModel(Date)
	private mdStartDate: Date;
	@Expose()
	public get startDate(): Date { return this.mdStartDate; }
	public set startDate(pdStartDate: Date) {
		if (pdStartDate !== this.mdStartDate)
			this.mdStartDate = pdStartDate ? DateHelper.resetMinutes(pdStartDate) : pdStartDate;
	}

	@Exclude()
	@ResolveModel(Date)
	private mdEndDate: Date | undefined;
	@Expose()
	public get endDate(): Date | undefined { return this.mdEndDate; }
	public set endDate(pdEndDate: Date | undefined) {
		if (pdEndDate !== this.mdEndDate)
			this.mdEndDate = pdEndDate ? DateHelper.resetMinutes(pdEndDate) : pdEndDate;
	}
	public limit: number;
	public months: number[];
	public monthDays: number[];
	public yearDays: number[];
	public weekNumbers: number[];
	public weekDays: number[];
	public hours: number[];
	public minutes: number[];
	public seconds: number[];
	public index: number[];

	public get everyNLabel(): string {
		switch (this.type) {
			case ERecurrenceType.hourly:
				return this.every > 1 ? `Toutes les ${this.every} heures` : "Toutes les heures";
			case ERecurrenceType.daily:
				return this.every > 1 ? `Tous les ${this.every} jours` : "Tous les jours";
			case ERecurrenceType.weekly:
				return this.every > 1 ? `Toutes les ${this.every} semaines` : "Toutes les semaines";
			case ERecurrenceType.monthly:
				return this.every > 1 ? `Tous les ${this.every} mois` : "Tous les mois";
			case ERecurrenceType.yearly:
				return this.every > 1 ? `Tous les ${this.every} ans` : "Tous les ans";
			default:
				return undefined;
		}
	}

	public get datesLabel(): string {
		if (!this.startDate)
			return "";
		if (!this.endDate)
			return `à partir du ${DateHelper.transform(this.startDate, ETimetablePattern.dd_MM_yyyy_slash)}`;
		return `du ${DateHelper.transform(this.startDate, ETimetablePattern.dd_MM_yyyy_slash)}
				au ${DateHelper.transform(this.endDate, ETimetablePattern.dd_MM_yyyy_slash)}`;
	}

	public get weekDaysShortLabel(): string {
		return this.weekDays?.map((pnWeekDay: number) =>
			DateHelper.transform(
				DateHelper.setWeekDay(new Date, pnWeekDay < 6 ? pnWeekDay + 1 : 0), ETimetablePattern.EEE
			).slice(0, -1)
		).join(", ");
	}

	public get weekDaysLongLabel(): string {
		return this.weekDays?.map((pnWeekDay: number) =>
			DateHelper.transform(
				DateHelper.setWeekDay(new Date, pnWeekDay < 6 ? pnWeekDay + 1 : 0), ETimetablePattern.EEEE
			)
		).join(" et ");
	}

	public get label(): string {
		return `${this.everyNLabel}${ArrayHelper.hasElements(this.weekDays) ? `, le${this.weekDays.length > 0 ? "s" : ""} ${this.weekDaysLongLabel}` : ""} ${StringHelper.lowerFirst(this.datesLabel)}`;
	}

	//#endregion

	//#region METHODS

	constructor(poRecurrence?: IRecurrence) {
		if (poRecurrence) {
			ObjectHelper.assign(this, poRecurrence);
		}
	}

	private buildRRule(): RRule {
		const loRruleOptions: Partial<RRuleOptions> = {};

		// Initialisation des propriétés obligatoires dans OSAPP (mais pas nécessairement par RRule)
		switch (this.type) {
			case ERecurrenceType.hourly: {
				loRruleOptions.freq = RRule.HOURLY;
				break;
			}
			case ERecurrenceType.daily: {
				loRruleOptions.freq = RRule.DAILY;
				break;
			}
			case ERecurrenceType.weekly: {
				loRruleOptions.freq = RRule.WEEKLY;
				break;
			}
			case ERecurrenceType.monthly: {
				loRruleOptions.freq = RRule.MONTHLY;
				break;
			}
			case ERecurrenceType.yearly: {
				loRruleOptions.freq = RRule.YEARLY;
				break;
			}
			default: {
				console.error(`${Recurrence.C_LOG_ID}Le type de récurrence '${this.type}' n'est pas implémenté.`);
				throw new Error("Type de récurrence inconnu.");
			}
		}

		loRruleOptions.dtstart = new Date(this.startDate);
		this.fixDateRruleBug(loRruleOptions.dtstart);

		loRruleOptions.interval = this.every;

		// Initialisation des propriétés optionnelles
		if (this.endDate) {
			loRruleOptions.until = new Date(this.endDate);
			this.fixDateRruleBug(loRruleOptions.until);
		}

		loRruleOptions.count = this.limit;
		loRruleOptions.bymonth = this.months;
		loRruleOptions.bymonthday = this.monthDays;
		loRruleOptions.byyearday = this.yearDays;
		loRruleOptions.byweekno = this.weekNumbers;
		loRruleOptions.byweekday = this.weekDays;
		loRruleOptions.byhour = this.hours;
		loRruleOptions.byminute = this.minutes;
		loRruleOptions.bysecond = this.seconds;
		loRruleOptions.bysetpos = this.index;

		return (new RRule(loRruleOptions));
	}

	/** https://github.com/jkbrzt/rrule/issues/290 https://github.com/jkbrzt/rrule/issues/571
	 * @param pdDate
	 */
	private fixDateRruleBug(pdDate: Date): void {
		pdDate.setHours(12, 0, 0, 0);
	}

	/** Génère les dates d'occurrences associées à la récurrence, soit sur sa totalité, soit sur une plage donnée
	 * TODO : Passer sur un RuleSet pour gérer les contraintes
	 * @param poRange Optionnel. Indique la plage de dates sur laquelle générer les occurrences
	 * @throws {Error} Si le type de récurrence n'est pas connu.
	 * @returns Un tableau de Date correspondant aux occurrences sur la période demandée
	 */
	public generate(poRange?: IRange<Date>, pnLimit?: number): Date[] {
		const loRule: RRule = this.buildRRule();
		let laDates: Date[] = [];

		laDates = loRule.between(
			DateHelper.resetDay(poRange?.from ?? this.startDate),
			DateHelper.fillDay(poRange?.to ?? this.endDate ?? Recurrence.C_MAX_DATE),
			true,
			NumberHelper.isValidStrictPositive(pnLimit) ?
				(_, pnLength: number) => pnLength < pnLimit :
				undefined
		);

		const lnHours: number = this.startDate.getHours();
		const lnMinutes: number = this.startDate.getMinutes();

		laDates.forEach((pdDate: Date) => pdDate.setHours(lnHours, lnMinutes));

		return laDates;
	}

	/**
	 * Génère la première occurrence respectant les règles de cette récurrence après une date donnée
	 * @param pdSince Date à partir de laquelle générer l'occurrence
	 * @throws {Error} Si le type de récurrence n'est pas connu.
	 * @returns Date de l'occurrence, `undefined` si aucune occurrence ne se situe après la date spécifiée
	 */
	public generateFirstOccurrenceSince(pdSince: Date): Date | undefined {
		return this.buildRRule().after(pdSince, true);
	}

	/**
	 * Génère la prochaine occurrence respectant les règles de cette récurrence après une date donnée
	 * @param pdSince Date à partir de laquelle générer l'occurrence
	 * @throws {Error} Si le type de récurrence n'est pas connu.
	 * @returns Date de l'occurrence, `undefined` si aucune occurrence ne se situe après la date spécifiée
	 */
	public generateNextOccurrenceSince(pdSince: Date): Date | undefined {
		return this.buildRRule().after(pdSince);
	}

}

//#endregion