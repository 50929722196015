import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { EDateTimePickerMode } from '@calaosoft/osapp-common/dates/models/EDateTimePickerMode';
import { ETimetablePattern } from '@calaosoft/osapp-common/dates/models/ETimetablePattern';
import { IDateTimePickerParams } from '@calaosoft/osapp-common/dates/models/IDateTimePickerParams';
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { combineLatest, map } from 'rxjs';
import { DateTimePickerComponent } from '../../../../../../components/date/dateTimePicker.component';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IDateFieldParams } from '../date-time-field/models/idate-field-params';
import { IInlineFieldLayoutParams } from '../inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from '../inline-field-layout/models/iinlineField';

@Component({
	selector: 'calao-date-field',
	templateUrl: './date-field.component.html',
	styleUrls: ['./date-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFieldComponent extends FieldBase<Date> implements OnInit, IInlineField {

	//#region PROPERTIES

	@ViewChild('datePicker', { static: false }) datePickerRef: DateTimePickerComponent;

	/** `true` si readOnly, sinon `false`. Est `false` par défaut. */
	public readonly observableIsReadOnly = new ObservableProperty<boolean>(false);

	/** Style de layout à afficher (undefined par défaut). */
	public layout: "inline";
	@ObserveProperty<DateFieldComponent>({ sourcePropertyKey: "layout" })
	public readonly observableLayout = new ObservableProperty<"inline">();

	/** Paramètres d'affichage à passer au layout. */
	public layoutParams: IInlineFieldLayoutParams;
	@ObserveProperty<DateFieldComponent>({ sourcePropertyKey: "layoutParams" })
	public readonly observableLayoutParams = new ObservableProperty<IInlineFieldLayoutParams>();

	/** Masque le libellé du champs dans le layout si `true` et que le champs est renseigné. */
	public hideLabelWhenFilled: boolean;
	@ObserveProperty<DateFieldComponent>({ sourcePropertyKey: "hideLabelWhenFilled" })
	public readonly observableHideWhenFilled = new ObservableProperty<boolean>();

	/** Format d'affichage de la date. */
	public readonly observableDateLabelFormat = new ObservableProperty<string>("EEE dd MMMM yyyy");

	/** Configuration du datePicker */
	public readonly observableDatePickerParams = new ObservableProperty<IDateTimePickerParams>();

	/** Masque le libellé du champs. */
	public readonly observableHideLabel = new ObservableProperty<boolean>().bind(
		combineLatest([this.observableHideWhenFilled.value$, this.observableFieldValue.value$]).pipe(
			map(([pbHideLabelWhenFilled, poDate]: [boolean, Date]) => pbHideLabelWhenFilled && !!poDate)
		),
		this
	);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit() {
		super.ngOnInit();

		const loParams = this.props as IDateFieldParams;

		this.observableIsReadOnly.value = !!loParams.readOnly;
		this.observableLayout.value = loParams.layout;
		this.observableLayoutParams.value = loParams.layoutParams;
		this.observableHideWhenFilled.value = loParams.hideLabelWhenFilled;
		this.observableDateLabelFormat.value = loParams.dateFormat;
		this.observableDatePickerParams.value = {
			pickerMode: EDateTimePickerMode.date,
			displayFormat: ETimetablePattern.dd_MMM_yyyy,
			hideIcon: true,
			autoFill: !!loParams.dateTimePickerParams?.autoFill && !(this.model as Entity)._rev
		};
	}

	public onFieldClicked() {
		this.datePickerRef.pickDate();
	}

	public onDateChanged(pdDate: Date): void {
		this.observableFieldValue.value = pdDate;
	}

	public onClearClicked(poEvent: Event): void {
		poEvent.stopPropagation();
		this.fieldValue = undefined;
	}

	//#endregion METHODS

}
