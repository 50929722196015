import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from '@calaosoft/osapp-common/rxjs/operators/secure';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { IItemDescriptionLine } from '@calaosoft/osapp/modules/calendar-events/models/iitem-description-line';
import { ILinkedEntityIcon } from '@calaosoft/osapp/modules/calendar-events/models/ilinked-entity-icon';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { Observable, filter, map, switchMap } from 'rxjs';
import { C_COLOR_EVENT_DEFAULT, C_COLOR_EVENT_ITEM_START, C_PREFIX_BUSINESS, C_PREFIX_OPPORTUNITY } from '../../../../app/app.constants';
import { Business } from '../../../businesses/model/business';
import { TradeEventsService } from '../../services/trade-events.service';

@Component({
	selector: 'trade-event-item',
	templateUrl: './event-item.component.html',
	styleUrls: ['./event-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventItemComponent extends DestroyableComponentBase {

	//#region PROPERTIES

	public readonly eventItemStartColor = C_COLOR_EVENT_ITEM_START;
	public readonly eventItemColor = C_COLOR_EVENT_DEFAULT;

	/** Occurrence de l'évènement. */
	@Input() public item?: BaseEventOccurrence;
	@ObserveProperty<EventItemComponent>({ sourcePropertyKey: "item" })
	public readonly observableItem = new ObservableProperty<BaseEventOccurrence>();

	@Input() public hideLinkedBusiness?: boolean;
	@ObserveProperty<EventItemComponent>({ sourcePropertyKey: "hideLinkedBusiness" })
	public readonly observableHideLinkedBusiness = new ObservableProperty<boolean>(false);

	public readonly linkedBusinessDescriptionLine$: Observable<IItemDescriptionLine> = this.observableItem.value$.pipe(
		switchMap((poEventOccurrence?: BaseEventOccurrence) => this.observableHideLinkedBusiness.value$.pipe(
			filter((pbHideLinkedBusiness: boolean) => !pbHideLinkedBusiness),
			switchMap(() => this.isvcTradeEvents.getEventLinkedEntity$<Business>(C_PREFIX_BUSINESS, poEventOccurrence?.event)),
			filter((poBusiness?: Business) => !!poBusiness),
			map((poBusiness: Business) => { return { icon: "briefcase", value: poBusiness.name } })
		)),
		secure(this)
	);

	public readonly linkedEntitiesIcons$: Observable<ILinkedEntityIcon[]> = this.observableItem.value$.pipe(
		switchMap((poEventOccurrence?: BaseEventOccurrence) => this.isvcTradeEvents.getEventLinkedEntitiesIcons$(C_PREFIX_OPPORTUNITY, poEventOccurrence?.event)),
		secure(this)
	);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcTradeEvents: TradeEventsService
	) {
		super();
	}

	public getEventIcon$(poEvent: BaseEventOccurrence): Observable<string> {
		return this.isvcTradeEvents.getIconNameFromSubtype$(poEvent);
	}

	public getEventTypeLabel$(poEvent: BaseEventOccurrence): Observable<string> {
		return this.isvcTradeEvents.getShortTypeLabelFromSubtype$(poEvent);
	}

	/** Navigue vers la page de visualisation d'un évènement. */
	public routeToViewEvent(poEventOccurrence: BaseEventOccurrence): void {
		this.isvcTradeEvents.routeToViewEventAsync(poEventOccurrence);
	}

	/** Navigue vers la page d'édition d'un évènement. */
	public routeToEditEvent(poEventOccurrence: BaseEventOccurrence): void {
		this.isvcTradeEvents.routeToEditEventAsync(poEventOccurrence);
	}

	/** Retourne le nom de l'icône à afficher avant le titre.
	 * @param poEventOccurrence Evènement à afficher dans l'item.
	 */
	public getTitleIcon(poEventOccurrence: BaseEventOccurrence): string | undefined {
		return poEventOccurrence.event.private ? "lock-closed" : undefined;
	}

	//#endregion

}
