import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { plainToClass } from "@calaosoft/osapp-common/class-transformer";
import { Entity } from "@calaosoft/osapp-common/entities/models/entity";
import { IDataSource } from "@calaosoft/osapp-common/store/models/IDataSource";
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { IStoreDocument } from "@calaosoft/osapp-common/store/models/istore-document";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { ModelResolver } from "@calaosoft/osapp-common/utils/models/model-resolver";
import { ERouteUrlPart } from "@calaosoft/osapp/model/route/ERouteUrlPart";
import { IStoreDataResponse } from "@calaosoft/osapp/model/store/IStoreDataResponse";
import { Contact } from "@calaosoft/osapp/modules/contacts/models/contact";
import { IEntityDescriptor } from "@calaosoft/osapp/modules/entities/models/ientity-descriptor";
import { EntitiesUpdateService } from "@calaosoft/osapp/modules/entities/services/entities-update.service";
import { EntitiesService } from "@calaosoft/osapp/modules/entities/services/entities.service";
import { ILogSource } from "@calaosoft/osapp/modules/logger/models/ILogSource";
import { LoggerService } from "@calaosoft/osapp/modules/logger/services/logger.service";
import { EntityLinkService } from "@calaosoft/osapp/services/entityLink.service";
import { Store } from "@calaosoft/osapp/services/store.service";
import { Observable, of, tap } from "rxjs";
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { C_PREFIX_BUSINESS, C_PREFIX_SECTOR } from "../../../../app/app.constants";
import { Business } from "../../../businesses/model/business";
import { Sectorization } from "../../models/sectorization";
import { EMapUrlPart } from "../models/emap-url-part";
import { ESectors } from "../models/esectors";
import { ISector } from "../models/isector";
import { Sector } from "../models/sector";

@Injectable()
export class SectorsService implements ILogSource {

	//#region FIELDS
	//#endregion FIELDS

	//#region PROPERTIES

	public readonly logSourceId = "TRADE.SECT.S::";


	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		/** Service du store. */
		private readonly isvcStore: Store,
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcUpdateEntity: EntitiesUpdateService,
		private readonly isvcEntities: EntitiesService,
		private readonly ioRouter: Router,
		/** @implements */
		public readonly isvcLogger: LoggerService
	) { }

	/** Récupère les entités liées à un secteur.
	 * @param paSectors Secteurs à qui on va réccupérer les liens.
	 * @param paPrefixes Prefix des types de lien voulus.
	 * @returns Une promesse avec une map des entités liées par secteur.
	 */
	private getLinkedEntitiesAsync(paSectors: Sector[], paPrefixes: EPrefix[]): Promise<Map<string, IStoreDocument[]>> {
		return this.isvcEntityLink.getLinkedEntities(paSectors.map((poSector: Sector) => poSector._id), paPrefixes).toPromise();
	}

	/** Récupère tous les secteurs.
	 * @returns Un observable des secteurs.
	 */
	public getSectors$(): Observable<Sector[]> {
		const loDataSource: IDataSource<IStoreDocument> = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: C_PREFIX_SECTOR,
				endkey: C_PREFIX_SECTOR + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			},
			baseClass: Sector,
			live: true
		};
		return this.isvcStore.get<ISector>(loDataSource)
			.pipe(
				mergeMap((paSectors: Sector[]) => this.addLinksAsync(paSectors))
			);
	}


	/** Récupère un secteur par son identifiant.
	 * @param psSectorId Identifiant du secteur.
	 * @returns Un observable du secteur.
	 */
	public getSector$(psSectorId: string): Observable<Sector> {
		const loDataSource: IDataSource<IStoreDocument> = {
			id: psSectorId,
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psSectorId,
				include_docs: true
			},
			baseClass: Sector,
			live: true,
		};
		return this.isvcStore.get<ISector>(loDataSource)
			.pipe(
				mergeMap((paSectors: Sector[]): Promise<Sector[]> => this.addLinksAsync(paSectors)),
				map((paSectors: Sector[]): Sector => ArrayHelper.getFirstElement(paSectors))
			);
	}

	/** Définit les liens des secteurs avec les entités.
	 * @param paSectors Liste des secteurs.
	 */
	public async addLinksAsync(paSectors: Sector[]): Promise<Sector[]> {
		const loLinksBySectorId: Map<string, IStoreDocument[]> = await this.getLinkedEntitiesAsync(paSectors, [C_PREFIX_BUSINESS, EPrefix.contact]);
		paSectors.forEach((poSector: Sector) => {
			poSector.linkedContacts = plainToClass(
				Contact,
				loLinksBySectorId.get(poSector._id)?.filter((poDocument: IStoreDocument) =>
					poDocument._id.startsWith(EPrefix.contact)) ?? []
			);

			poSector.linkedBusinesses = plainToClass(
				Business,
				loLinksBySectorId.get(poSector._id)?.filter((poDocument: IStoreDocument) =>
					poDocument._id.startsWith(C_PREFIX_BUSINESS)) ?? []
			);
		});
		return paSectors;
	}

	/** Permet de sauvegarder des secteurs en base de données.
	 * @param poSector Secteur à enregistrer.
	 */
	public saveSectorAsync(poSector: Sector): Promise<IStoreDataResponse> {
		return this.isvcEntityLink.getLinkedEntities([poSector], C_PREFIX_BUSINESS)
			.pipe(
				tap((poLinksById: Map<string, Entity[]>) => {
					this.removeCacheLinks(poSector, poLinksById);
					this.addCacheLinks(poSector, poLinksById);
				}),
				mergeMap(_ => this.isvcEntityLink.saveEntityLinks(poSector)),
				tap(_ => poSector.linkedBusinesses = undefined),
				tap(_ => poSector.linkedContacts = undefined),
				mergeMap(_ => this.isvcStore.put(poSector))
			)
			.toPromise();
	}

	private removeCacheLinks(poSector: Sector, poLinksById: Map<string, Entity[]>): void {
		poLinksById.get(poSector._id)
			?.forEach((poSectFromBd: Entity) => {
				if (!poSector.linkedBusinesses?.map((poSect: Business) => poSect._id).includes(poSectFromBd._id))
					this.isvcEntityLink.cacheLinkToRemove(poSector, poSectFromBd);
			});
	}

	private addCacheLinks(poSector: Sector, poLinksById: Map<string, Entity[]>): void {
		poSector.linkedBusinesses?.forEach((poSect: Business) => {
			if (!poLinksById.get(poSector._id)
				?.map((poSectFromBd: IStoreDocument) => poSectFromBd._id)
				.includes(poSect._id)
			)
				this.isvcEntityLink.cacheLinkToAdd(poSector, poSect);
		});
	}

	/** Va sur la page d'edit d'un secteur.
	 * @param psSectorId Identifiant du secteur vers lequel naviguer.
	 */
	public navigateToSectorEditAsync(psSectorId: string, poRoute: ActivatedRoute): Promise<boolean> {
		return this.ioRouter.navigate([ESectors.sectors, IdHelper.getGuidFromId(psSectorId, C_PREFIX_SECTOR), ERouteUrlPart.edit], { relativeTo: poRoute });
	}

	/** Va sur la page d'un secteur.
 * @param psSectorId Identifiant du secteur vers lequel naviguer.
 */
	public navigateToSectorAsync(psSectorId: string, poRoute: ActivatedRoute): Promise<boolean> {
		return this.ioRouter.navigate([ESectors.sectors, IdHelper.getGuidFromId(psSectorId, C_PREFIX_SECTOR)], { relativeTo: poRoute });
	}

	/** Navigue vers la page de création d'un secteur. */
	public navigateToSectorNewAsync(poRoute: ActivatedRoute, poSectorization: Sectorization): Promise<boolean> {
		return this.ioRouter.navigate([ESectors.sectors, ERouteUrlPart.new],
			{ relativeTo: poRoute, state: { sectorization: ModelResolver.toPlain(poSectorization) } });
	}

	/** Va sur la page de la carte d'un secteur.
 * @param psSectorId Identifiant du secteur vers lequel naviguer.
 */
	public navigateToSectorMapAsync(psSectorId: string, poRoute: ActivatedRoute): Promise<boolean> {
		return this.ioRouter.navigate([ESectors.sectors, IdHelper.getGuidFromId(psSectorId, C_PREFIX_SECTOR), EMapUrlPart.map], { relativeTo: poRoute });
	}

	/** Va sur la page de la carte des secteurs.
* @param psSectorId Identifiant du secteur vers lequel naviguer.
*/
	public navigateToSectorsMapAsync(poRoute: ActivatedRoute): Promise<boolean> {
		return this.ioRouter.navigate([ESectors.sectors, EMapUrlPart.map], { relativeTo: poRoute });
	}

	/** Va sur la page de la sectorization.*/
	public navigateToSectorizationAsync(psSctrzId: string): Promise<boolean> {
		return this.ioRouter.navigate(["sectorizations", psSctrzId], { replaceUrl: true });
	}

	/** Suppression du secteur passé en paramètre.
 * @param poSector secteur à supprimer
 */
	public deleteSectorAsync(poSector: Sector): Promise<boolean> {
		return this.isvcEntities.getDescriptor$(ESectors.sectors)
			.pipe(
				take(1),
				mergeMap((poDescriptor: IEntityDescriptor) =>
					this.isvcUpdateEntity.deleteEntity(poSector, poDescriptor)
				),
				map((poResponse: IStoreDataResponse) => poResponse.ok),
				catchError(poError => {
					console.error(`${this.logSourceId}Error when trying to remove sector "${poSector._id}" :`, poError);

					return of(false);
				}),
			).toPromise();
	}
	//#endregion
}