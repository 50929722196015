import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';

@Component({
	selector: 'calao-list-item-content',
	templateUrl: './list-item-content.component.html',
	styleUrls: ['./list-item-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.width]": "'100%'",
		"[style.display]": "'flex'"
	}
})
export class ListItemContentComponent {

	//#region FIELDS

	/** Événement lors du clic sur le bouton de détail. */
	@Output("onDetailClicked") private readonly moDetailClickedEvent = new EventEmitter<MouseEvent>();

	//#endregion

	//#region PROPERTIES

	/** Indique si on doit afficher l'icône de détail de l'item. */
	@Input() public detail?: boolean | string | null;
	@ObserveProperty<ListItemContentComponent>({ sourcePropertyKey: "detail", transformer: (poNewValue: any) => coerceBooleanProperty(poNewValue) })
	public readonly observableDetail = new ObservableProperty<boolean>();

	/** Icône de détail. `chevron-forward` par défaut. */
	@Input() public detailIcon?: string;
	@ObserveProperty<ListItemContentComponent>({ sourcePropertyKey: "detailIcon" })
	public readonly observableDetailIcon = new ObservableProperty<string>("chevron-forward");

	/** Couleur de fond. */
	@Input() public backgroundColor?: string | null;
	@ObserveProperty<ListItemContentComponent>({ sourcePropertyKey: "backgroundColor" })
	public readonly observableBackgroundColor = new ObservableProperty<string>();

	//#endregion

	//#region METHODS


	public onDetailClicked(poEvent: MouseEvent): void {
		poEvent.stopPropagation();
		this.moDetailClickedEvent.emit(poEvent);
	}

	//#endregion

}
