import { Exclude } from 'class-transformer';
import { BehaviorSubject, Observable, ObservableInput, Subscription, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayHelper } from '../../utils/helpers/arrayHelper';
import { NumberHelper } from '../../utils/helpers/numberHelper';
import { ObjectHelper } from '../../utils/helpers/objectHelper';
import { ESortOrder } from '../../utils/models/ESortOrder';
import { MoveToObservableArrayError } from './errors/move-to-observable-array-error';
import { ReplaceObservableArrayError } from './errors/replace-observable-array-error';

export class ObservableArray<T> extends Array<T> {

	//#region FIELDS

	private static readonly C_LOG_ID = "OBSARR::";

	@Exclude()
	private readonly moChanges: BehaviorSubject<Array<T>>;

	/** Abonnement du tableau observable pour écouter les changements dans le flux d'entrée. */
	@Exclude()
	private moSubscription?: Subscription;

	//#endregion

	//#region PROPERTIES

	/** Flux continu de récupération des changements du tableau. */
	public get changes$(): Observable<Array<T>> { return this.moChanges.asObservable(); }

	/** Flux continu de récupération des changements sur la longueur du tableau. */
	public get length$(): Observable<number> { return this.changes$.pipe(map((paValues: T[]) => paValues.length)); }

	public get lastIndex(): number { return this.length - 1; }

	//#endregion

	//#region METHODS

	constructor(paValues?: T[] | number);
	constructor(poValue$?: ObservableInput<T[]>, pfAreItemsEqual?: (poItemA: T, poItemB: T) => boolean);
	constructor(paValues?: T[] | ObservableInput<T[]> | number, pfAreItemsEqual?: (poItemA: T, poItemB: T) => boolean) {
		if (typeof paValues === "number")
			super(paValues);
		else if (paValues instanceof Array)
			super(...paValues);
		else
			super();

		this.moChanges = new BehaviorSubject([...this]);
		ObjectHelper.initInstanceOf(this, ObservableArray);

		if (!!paValues && typeof paValues !== "number" && !(paValues instanceof Array))
			this.resetSubscription(paValues, pfAreItemsEqual);
		else
			this.emitNewArray();
	}

	/** Réinitialise un abonnement sur un nouveau flux en se désabonnant du possible précédent.
	 * @param poObservableInput Flux d'entrée sur lequel s'abonner.
	 * @param pfAreItemsEqual Fonction qui détermine si deux éléments sont identiques, égalité simple par défaut (`===`).
	 */
	public resetSubscription(poObservableInput: ObservableInput<T[]>, pfAreItemsEqual?: (poItemA: T, poItemB: T) => boolean): void {
		this.moSubscription?.unsubscribe();

		this.moSubscription = from(poObservableInput)
			.subscribe({
				next: (paResults: T[]) => this.resetArray(paResults, pfAreItemsEqual),
				error: (poError: any) => console.error(`${ObservableArray.C_LOG_ID}Error in observableArray subscription.`, poError),
				complete: () => this.moSubscription?.unsubscribe()
			});
	}

	public override push(...paValues: T[]): number {
		if (ArrayHelper.hasElements(paValues)) {
			const lnResult: number = super.push(...paValues);

			this.emitNewArray();

			return lnResult;
		}
		else
			return this.length;
	}

	public override pop(): T | undefined {
		const loResult: T | undefined = super.pop();

		this.emitNewArray();

		return loResult;
	}

	/** Vide le tableau de tous ses éléments. */
	public clear(): void {
		this.splice(0, this.length);
		this.emitNewArray();
	}

	public override sort(pfComp?: (a: T, b: T) => number): this {
		const loResult: this = super.sort(pfComp);

		this.emitNewArray();

		return loResult;
	}

	public override reverse(): this {
		super.reverse();

		this.emitNewArray();

		return this;
	}

	/** Envoi un événement contenant la liste dans le flux de changements. */
	private emitNewArray(): void {
		this.moChanges.next([...this]);
	}

	public override splice(start: number, deleteCount?: number): T[];
	public override splice(start: number, deleteCount: number, ...items: T[]): T[];
	public override splice(start: number, deleteCount?: number, ...rest: T[]): T[] {
		let laResults: T[];

		if (!!rest)
			laResults = super.splice(start, deleteCount as number, ...rest); // Typage surcharge 2.
		else
			laResults = super.splice(start, deleteCount);

		this.emitNewArray();

		return laResults;
	}

	public override shift(): T | undefined {
		const loResult: T | undefined = super.shift();

		if (loResult)
			this.emitNewArray();

		return loResult;
	}

	public override unshift(...items: T[]): number {
		if (ArrayHelper.hasElements(items)) {
			const lnResult: number = super.unshift(...items);

			this.emitNewArray();

			return lnResult;
		}

		return this.length;
	}

	/** Réinitialise le tableau courant avec des nouvelles données ou en réinitialisant à tableau vide.
	 * @param paNewArray Nouveau tableau avec lequel réinitialiser le tableau courant.
	 * @param pfAreItemsEqual Fonction qui détermine si deux éléments sont identiques, égalité simple par défaut (`===`).
	 */
	public resetArray(paNewArray?: ReadonlyArray<T>, pfAreItemsEqual?: (poItemA: T, poItemB: T) => boolean): void {
		let lbChanged = false;

		if (!ArrayHelper.areArraysStrictEqual(paNewArray, this, pfAreItemsEqual)) {
			if (ArrayHelper.hasElements(this)) {
				super.splice(0, this.length);
				lbChanged = true;
			}
			if (ArrayHelper.hasElements(paNewArray)) {
				// Boucle for car push avec spread peut causer un maximum call stack
				for (let lnIndex = 0; lnIndex < paNewArray.length; ++lnIndex) {
					super.push(paNewArray[lnIndex]);
				}
				lbChanged = true;
			}
		}

		if (lbChanged)
			this.emitNewArray();
	}

	/** Supprime un élément spécifique du tableau et le retourne si celui-ci a été supprimé, retourne `undefined` sinon.
	 * @param poItem Objet qu'il faut supprimer du tableau.
	 */
	public remove(poItem: T): T | undefined;
	/** Supprime un élément spécifique du tableau et le retourne si celui-ci a été supprimé, retourne `undefined` sinon.
	 * @param pfFinder Fonction permettant de trouver l'objet à supprimer.
	 */
	public remove(pfFinder: (poItem: T) => boolean): T | undefined;
	public remove(poData: T | ((poItem: T) => boolean)): T | undefined {
		return this.removeAt(
			typeof poData === "function" ?
				this.findIndex((poData as (poItem: T) => boolean)) : this.indexOf(poData)
		);
	}

	/** Supprimer un élément à un index donné et le retourne.
	 * @param pnIndex Index de l'élément à supprimer.
	 */
	public removeAt(pnIndex: number): T | undefined {
		let loRemovedItem: T | undefined

		if (pnIndex >= 0 && pnIndex < this.length) {
			loRemovedItem = this.splice(pnIndex, 1)[0];
			this.emitNewArray();
		}

		return loRemovedItem;
	}

	/** Supprime tous les articles respectant un prédicat et les retourne.
	 * @param pfFinder Prédicat permettant de trouver les éléments à supprimer.
	 */
	public removeEach(pfFinder: (poItem: T) => boolean): T[] {
		const laRemovedItems: T[] = ArrayHelper.removeElementsByFinder(this, pfFinder);

		if (ArrayHelper.hasElements(laRemovedItems))
			this.emitNewArray();

		return laRemovedItems;
	}

	/** Retourne `true` si au moins un éléments est présent dans le tableau, `false` sinon. */
	public hasElements(): boolean {
		return this.length > 0;
	}

	/** Retourne le premier élément du tableau, `undefined` s'il n'y en a pas. */
	public first(): T | undefined {
		return this[0];
	}

	/** Retourne le dernier élément du tableau, `undefined` s'il n'y en a pas. */
	public last(): T | undefined {
		return this[this.length - 1];
	}

	/** Déplace un élément du tableau vers un nouvel index, en déplaçant si besoin les éléments entre l'ancien et le nouvel index.
	 * @param pnFromIndex Index courant de l'élément à déplacer.
	 * @param pnToIndex Nouvel index de l'élément après déplacement.
	 * @throws `MoveToObservableArrayError` si le déplacement de l'élément n'a pas pu se réaliser.
	 */
	public moveTo(pnFromIndex: number, pnToIndex: number): void {
		if (ArrayHelper.moveElement(this, pnFromIndex, pnToIndex))
			this.emitNewArray();
		else
			throw new MoveToObservableArrayError(pnFromIndex, pnToIndex, this.length);
	}

	/** Déplace un élément à la fin du tableau.
	 * @param pnIndex Index de l'élément à déplacer à la fin du tableau.
	 * @throws `MoveToObservableArrayError` si le déplacement de l'élément n'a pas pu se réaliser.
	 */
	public moveToEnd(pnIndex: number): void {
		this.moveTo(pnIndex, this.lastIndex);
		this.emitNewArray();
	}

	/** Déplace un élément à un nouvel index.
	 * @param pfFinder Fonction permettant de trouver l'élément à déplacer.
	 * @param pnIndex Index de destination de l'élément.
	 * @throws `MoveToObservableArrayError` si le déplacement de l'élément n'a pas pu se réaliser.
	 */
	public move(pfFinder: (poItem: T) => boolean, pnIndex: number,): void {
		return this.moveTo(this.findIndex(pfFinder), pnIndex);
	}

	public override filter(pfPredicate: (poValue: T, pnIndex: number, paArray: ObservableArray<T>) => boolean): ObservableArray<T> {
		return new ObservableArray(super.filter(pfPredicate));
	}

	/** Retourne le tableau trié (en modifiant directement le tableau).
	 * @param psKey Clé sur laquelle trier le tableau.
	 * @param peSortOrder Ordre de tri : croissant par défaut (alphabétique, plus vieux au plus récent).
	 */
	public sortBy(psKey: keyof T, peSortOrder: ESortOrder = ESortOrder.ascending): this {
		return this.sortByMultiple([psKey], peSortOrder);
	}

	/** Retourne le tableau trié (en modifiant directement le tableau).
	 * @param paKeys Tableau des clés sur lesquelles trier le tableau.
	 * @param peSortOrder Ordre de tri : croissant par défaut (alphabétique, plus vieux au plus récent).
	 */
	public sortByMultiple(paKeys: Array<keyof T>, peSortOrder: ESortOrder = ESortOrder.ascending): this {
		ArrayHelper.dynamicSortMultiple(this, paKeys, peSortOrder);
		this.emitNewArray();
		return this;
	}

	/** Remplace un élément par un autre en fonction d'un index.
	 * @param poItem Élément qu'il faut mettre à la place de l'ancien.
	 * @param pnIndex Index de l'élément à remplacer.
	 * @throws `ReplaceObservableArrayError` si le remplacement n'a pas eu lieu.
	 */
	public replace(poItem: T, pnIndex: number): void {
		if (NumberHelper.isValidPositive(pnIndex) && pnIndex < this.length) {
			this[pnIndex] = poItem;
			this.emitNewArray();
		}
		else
			throw new ReplaceObservableArrayError(pnIndex, this.length);
	}

	/** Insère un élément à un index donné si l'index est valide, `true` si l'insertion s'est passée, `false` sinon.
	 * @param poItem Élément à insérer dans le tableau.
	 * @param pnIndex Index où insérer le nouvel élément.
	 */
	public insert(poItem: T, pnIndex: number): boolean {
		if (pnIndex >= this.length) { // Si l'index est la fin du tableau ou au-delà, on ajoute juste l'élément à la fin.
			this.push(poItem);
			return true;
		}
		else if (NumberHelper.isValidPositive(pnIndex)) {
			this.splice(pnIndex, 0, poItem);
			return true;
		}
		else
			return false;
	}

	/** Trouve le dernier index de l'élément éligible (contraire de `findIndex` qui trouve le premier).
	 * @param pfFinder Fonction qui doit retourner `true` s'il s'agit de l'élément recherché, `false` sinon.
	 * @returns
	 * - `-1` si aucun élément correspondant trouvé.
	 * - l'index de l'élément recherché.
	 */
	public findLastIndex(pfFinder: (poItem: T) => boolean): number {
		return ArrayHelper.findLastIndex(this, pfFinder);
	}

	//#endregion

}