import { Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { EConversationStatus } from '@calaosoft/osapp/model/conversation/EConversationStatus';
import { ITradeConversationsListParams } from '../../model/ITradeConversationsListParams';

@Component({
	selector: 'trade-conversation-tab',
	templateUrl: './conversation-tab.component.html',
	styleUrls: ['./conversation-tab.component.scss'],
	// Pas de onPush car bug detection tabs
})
export class TradeConversationTabComponent {

	//#region PROPERTIES

	/** Paramètres pour le composant. */
	@Input() public params?: ITradeConversationsListParams;
	@ObserveProperty<TradeConversationTabComponent>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<ITradeConversationsListParams>();

	public readonly statusFilters = EConversationStatus;

	//#endregion

}
