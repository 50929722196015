import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '../../../../../../../../services/platform.service';
import { IRelativeNotificationDate } from '../../../../../../../calendar-events/models/irelative-notification-date';
import { ModalComponentBase } from '../../../../../../../modal/model/ModalComponentBase';
import { ECustomNotificationDateUnit } from '../../models/ecustom-notification-date-unit';

@Component({
	selector: 'calao-notification-add-modal',
	templateUrl: './notification-add-modal.component.html',
	styleUrls: ['./notification-add-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationAddModalComponent extends ModalComponentBase<IRelativeNotificationDate> {

	//#region FIELDS

	private moSelectedDurationUnity?: ECustomNotificationDateUnit = ECustomNotificationDateUnit.minutes;
	private moSelectedDurationValue?: number = 15;
	private moReminderDuration?: IRelativeNotificationDate = { minutes: 15 };

	//#endregion FIELDS

	//#region PROPERTIES

	/** `true` pour afficher l'ajout de rappels 'custom', sinon `false`. Est à `false` par défaut. */
	public readonly observableDisplayCustomReminder = new ObservableProperty<boolean>(false);
	/** Valeur de l'input pour la création d'un rappel custom. */
	public readonly observableCustomValue = new ObservableProperty<number>(15);
	/** `true` pour désactiver le submit de la création d'un rappel custom. Est à `false` par défaut. */
	public readonly observableDisableSubmit = new ObservableProperty<boolean>(false);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public onChange(poEvent: Event): void {
		const loEvent = poEvent as CustomEvent;

		if (!this.observableDisplayCustomReminder.value)
			this.addStandardReminder(loEvent);
		else
			this.addCustomReminder(loEvent);
	}

	private addCustomReminder(loEvent: CustomEvent<any>): void {
		const lsValue: string = loEvent.detail.value;

		if (lsValue in ECustomNotificationDateUnit)
			this.moSelectedDurationUnity = lsValue as ECustomNotificationDateUnit;
		else if (NumberHelper.isStringNumber(lsValue))
			this.moSelectedDurationValue = parseInt(lsValue);

		if (this.moSelectedDurationUnity === ECustomNotificationDateUnit.weeks && this.moSelectedDurationValue)
			this.moReminderDuration = { [ECustomNotificationDateUnit.days]: this.moSelectedDurationValue * 7 };

		else
			this.moReminderDuration = { [`${this.moSelectedDurationUnity}`]: this.moSelectedDurationValue };
	}

	private addStandardReminder(loEvent: CustomEvent<any>): void {
		let lnValue: number;
		if (NumberHelper.isStringNumber(loEvent.detail.value)) {
			lnValue = parseInt(loEvent.detail.value);

			if (lnValue < 60)
				this.moReminderDuration = { minutes: lnValue };
			else if (lnValue < 1440)
				this.moReminderDuration = { hours: lnValue / 60 };

			else
				this.moReminderDuration = { days: lnValue / 1440 };
		}
		else
			this.moReminderDuration = undefined;
	}

	public async validateAsync(): Promise<boolean | void> {
		if (this.moReminderDuration)
			return this.close(this.moReminderDuration);
		else {
			this.observableDisplayCustomReminder.value = true;
			this.moReminderDuration = { minutes: 15 };
		}
	}

	public cancelAsync(): Promise<boolean> {
		return this.close();
	}

	public onCustomValueChanged(poEvent: Event): void {
		const lnValue: Number = new Number((poEvent as CustomEvent).detail.value);
		this.observableDisableSubmit.value = lnValue.valueOf() < 1 || !Number.isInteger(lnValue.valueOf());
	}

	//#endregion METHODS

}
