import { StoreHelper } from "@calaosoft/osapp-common/store/helpers/store-helper";
import { IStoreDocument } from "@calaosoft/osapp-common/store/models/istore-document";
import { OsappError } from '@calaosoft/osapp-common/utils/errors/OsappError';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';

export class ConflictError extends OsappError {

	constructor(paConflictDocs: IStoreDocument[], paSavedDocs: IStoreDocument[], psDatabaseId: string) {
		super(`Error when saving documents ${ConflictError.getStringifiedDocuments(paConflictDocs)} into database '${psDatabaseId}' because documents ${ConflictError.getStringifiedDocuments(paSavedDocs)} already exist !`);

		// Nécessaire pour que le 'instanceof ConflictError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, ConflictError);
	}

	private static getStringifiedDocuments(paDocuments: IStoreDocument[]): string {
		return JSON.stringify(
			paDocuments.map((poConflict: IStoreDocument) => {
				// On supprime les cacheData pour le log car inutile et prend de la place.
				return { ...poConflict, [StoreHelper.C_CACHE_DATA_FIELD_NAME]: undefined };
			})
		);
	}

}