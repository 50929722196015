import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ETimetablePattern } from '@calaosoft/osapp-common/dates/models/ETimetablePattern';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { DestroyableComponentBase } from '../../utils/components/destroyable-component-base';
import { ITourAppointData } from '../models/ITourAppointData';
import { ITourParams } from '../models/ITourParams';
import { TourService } from './tour.service';

@Component({
	selector: "calao-tour",
	templateUrl: './tour.component.html',
	styleUrls: ['./tour.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourComponent extends DestroyableComponentBase implements ITourParams, AfterViewInit {

	//#region FIELDS

	private static readonly C_DATE_NOW = new Date();

	/** Émetteur d'événement pour informer qu'un clic sur le bouton "précédent" a été réalisé. */
	@Output("previousButtonClicked") private readonly moPreviousButtonCLickedEvent = new EventEmitter<void>();
	/** Émetteur d'événement pour informer qu'un clic sur le bouton "suivant" a été réalisé. */
	@Output("nextButtonClicked") private readonly moNextButtonCLickedEvent = new EventEmitter<void>();

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public title?;
	/** @implements */
	@Input() public isCurrentTour: boolean;
	/** @implements */
	@Input() public templates: Array<TemplateRef<any>>;
	/** @implements */
	@Input() public appointementComplete?: (poAppointment: ITourAppointData) => boolean;

	/** @implements */
	@Input() public previousTourId?: string;
	@ObserveProperty<TourComponent>({ sourcePropertyKey: "previousTourId" })
	public readonly observablePreviousTourId = new ObservableProperty<string>();

	/** @implements */
	@Input() public nextTourId?: string;
	@ObserveProperty<TourComponent>({ sourcePropertyKey: "nextTourId" })
	public readonly observableNextTourId = new ObservableProperty<string>();

	private readonly moData = new ObservableArray<ITourAppointData>();
	/** @implements */
	public get data(): ObservableArray<ITourAppointData> { return this.moData; }
	@Input() public set data(paData: ITourAppointData[] | ObservableArray<ITourAppointData>) {
		paData.sort((poItemA: ITourAppointData, poItemB: ITourAppointData) => ArrayHelper.compareByProperty(poItemA, poItemB, "expectedDate"));

		if (paData instanceof ObservableArray)
			this.moData.resetSubscription(paData.changes$);
		else
			this.moData.resetArray(paData);
	}

	/** @implements */
	@Input() public showOnlyHeader?: boolean;
	@ObserveProperty<TourComponent>({ sourcePropertyKey: "showOnlyHeader" })
	public readonly observableShowOnlyHeader = new ObservableProperty<boolean>();

	/** @implements */
	@Input() public blankInformationTitle?: string;
	@ObserveProperty<TourComponent>({ sourcePropertyKey: "blankInformationTitle" })
	public readonly observableBlankInformationTitle = new ObservableProperty<string>();

	/** @implements */
	@Input() public blankInformationSubtitle?: string;
	@ObserveProperty<TourComponent>({ sourcePropertyKey: "blankInformationSubtitle" })
	public readonly observableBlankInformationSubtitle = new ObservableProperty<string>();

	//#endregion

	//#region METHODS

	constructor(private readonly isvcTour: TourService, private readonly ioDatePipe: DatePipe) {
		super();
	}

	/** Scroll accès au rdv du jour. */
	public ngAfterViewInit(): void {
		const ldDate = new Date();
		const loCurrentItem: ITourAppointData = this.data.find((poItem: ITourAppointData) => this.isSameDay(poItem.expectedDate, ldDate));

		if (loCurrentItem)
			setTimeout(() => document.getElementById(loCurrentItem.expectedDate.toString()).scrollIntoView({ behavior: "smooth" }), 100);
	}

	public isSameDay(poItemA: Date, poItemB: Date): boolean {
		return DateHelper.diffDays(poItemA, poItemB) === 0;
	}

	/** Retourne la date d'un rendez-vous de la tournée.
	 * @param poItem Rendez-vous dont il faut récupérer la date formattée.
	 */
	public getAppointmentFormattedDate(poItem: ITourAppointData): string {
		// Affiche l'année si l'année du rendez-vous est différente de l'année actuelle.
		const lsStartDateFormat: string = DateHelper.diffCalendarYear(poItem.expectedDate, TourComponent.C_DATE_NOW) === 0 ?
			ETimetablePattern.EEEE_dd_MMMM : ETimetablePattern.EEEE_dd_MMMM_yyyy;

		// Affiche la date de fin si la période contient plus d'une journée.
		if (poItem.expectedEndDate) {
			const lsEndDateFormat: string = DateHelper.diffCalendarYear(poItem.expectedEndDate, TourComponent.C_DATE_NOW) === 0 ?
				ETimetablePattern.EEEE_dd_MMMM : ETimetablePattern.EEEE_dd_MMMM_yyyy;

			return `${this.ioDatePipe.transform(poItem.expectedDate, lsStartDateFormat)} - ${this.ioDatePipe.transform(poItem.expectedEndDate, lsEndDateFormat)}`;
		}
		else
			return this.ioDatePipe.transform(poItem.expectedDate, lsStartDateFormat);
	}

	/** Permet de connaître le pourcentage de complétion d'une journée. */
	public getPercentageDayCompletion(psDate: Date): number {
		if (!this.appointementComplete)
			return 0;

		const laAppointments: ITourAppointData[] = this.data.filter((poAppointment: ITourAppointData) => this.isSameDay(poAppointment.expectedDate, new Date(psDate)));
		let lnCompleted = 0;
		laAppointments.forEach((poAppoint) => { lnCompleted += +this.appointementComplete(poAppoint); });

		return (lnCompleted / laAppointments.length);
	}

	public isCurrentDate(poTour: ITourAppointData): boolean {
		return this.isvcTour.isCurrentDate(poTour);
	}

	/** Lève un événement pour signaler un clic sur le bouton "précédent". */
	public raisePreviousButtonClicked(): void {
		this.moPreviousButtonCLickedEvent.emit();
	}

	/** Lève un événement pour signaler un clic sur le bouton "suivant". */
	public raiseNextButtonClicked(): void {
		this.moNextButtonCLickedEvent.emit();
	}

	//#endregion

}