import { Exclude, Expose } from "@calaosoft/osapp-common/class-transformer";
import { DateHelper, IDateTypes } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { IEventDuration } from "./ievent-duration";

export class EventDuration implements IEventDuration {

	//#region FIELDS

	private static readonly C_NB_MINUTES_IN_HOUR = 60;
	private static readonly C_NB_HOURS_IN_DAY = 24;

	//#endregion

	//#region PROPERTIES

	@Exclude()
	private mnMinutes = 0;
	@Expose()
	public get minutes(): number {
		return this.mnMinutes;
	}
	public set minutes(pnMinutes: number) {
		if (NumberHelper.isValid(pnMinutes)) {
			const lnNbHours: number = Math.floor(pnMinutes / EventDuration.C_NB_MINUTES_IN_HOUR);
			this.hours += lnNbHours;
			this.mnMinutes = (pnMinutes - (lnNbHours * EventDuration.C_NB_MINUTES_IN_HOUR)) % EventDuration.C_NB_MINUTES_IN_HOUR;
		}
	}

	@Exclude()
	private mnHours = 0;
	@Expose()
	public get hours(): number {
		return this.mnHours;
	}
	public set hours(pnHours: number) {
		if (NumberHelper.isValid(pnHours)) {
			const lnNbDays: number = Math.floor(pnHours / EventDuration.C_NB_HOURS_IN_DAY);
			this.days += lnNbDays;
			this.mnHours = Math.abs(pnHours - (lnNbDays * EventDuration.C_NB_HOURS_IN_DAY)) % EventDuration.C_NB_HOURS_IN_DAY;
		}
	}

	public days = 0;

	//#endregion

	//#region METHODS

	constructor(poEventDuration?: IEventDuration) {
		if (poEventDuration)
			ObjectHelper.assign(this, poEventDuration);
	}

	/**
	 * Prend en paramètre une Date et en renvoie une nouvelle à laquelle la durée courante a été ajoutée
	 * @param poDate Date à laquelle la durée doit être ajoutée
	 * @returns Date + durée décrite par cette classe
	 */
	public addDurationToDate(poDate: IDateTypes): Date {
		return DateHelper.addDays(DateHelper.addHours(DateHelper.addMinutes(poDate, this.minutes), this.hours), this.days);
	}

	/** Retire la durée définie pour l'instance courante à la date en paramètre.
	 * @param poDate Date dont il faut déduire celle définie dans l'instance courante.
	 * @returns Date en paramètre - date définie dans l'instance.
	 */
	public removeDurationToDate(poDate: IDateTypes): Date {
		return DateHelper.addDays(DateHelper.addHours(DateHelper.addMinutes(poDate, -this.minutes), -this.hours), -this.days);
	}

}

//#endregion