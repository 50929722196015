import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { Document } from "./document";
import { Folder } from "./folder";
import { IDocExlorerFilterValues } from "./idoc-explorer-filter-values";

export class FolderContent {

	//#region PROPERTIES

	public current: Folder;
	/** Liste des dossiers enfants. */
	public folders: Folder[] = [];
	/** Liste des fichiers du dossier courant. */
	public documents: Document[] = [];

	//#endregion

	//#region METHODS

	public searchDocuments(poFilters: IDocExlorerFilterValues): Document[] {
		const lsFilterText: string | undefined = poFilters.name?.toLowerCase();

		return this.documents.filter((poDocument: Document) => {
			if (!StringHelper.isBlank(lsFilterText) && !poDocument.name.toLowerCase().includes(lsFilterText))
				return false;
			if (poFilters.dateRange && !DateHelper.isBetweenTwoDates(poDocument.displayDate ?? new Date, poFilters.dateRange.from, poFilters.dateRange.to))
				return false;
			if (poFilters.notRead && poDocument.observableIsRead.value)
				return false;
			if (ArrayHelper.hasElements(poFilters.authorIds) && !poFilters.authorIds.includes(poDocument.authorId))
				return false;

			return true;
		});
	}

	public searchFolders(poFilters: IDocExlorerFilterValues): Folder[] {
		const lsFilterText: string | undefined = poFilters.name?.toLowerCase();
		return this.folders.filter((poFolder: Folder) => {
			if (!StringHelper.isBlank(lsFilterText) && !poFolder.name.toLowerCase().includes(lsFilterText))
				return false;
			if (poFilters.notRead && poFolder.documentsCounters?.notRead === 0)
				return false;
			return true;
		});
	}

	//#endregion METHODS

}
