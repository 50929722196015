import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from '@calaosoft/osapp-common/rxjs/operators/secure';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { C_COLOR_TASK_ICON_PRIORITY_1, C_COLOR_TASK_ICON_PRIORITY_2, C_COLOR_TASK_ICON_PRIORITY_3, C_COLOR_TASK_ICON_PRIORITY_4, C_COLOR_TASK_ICON_PRIORITY_5, C_COLOR_TASK_PRIORITY_1, C_COLOR_TASK_PRIORITY_2, C_COLOR_TASK_PRIORITY_3, C_COLOR_TASK_PRIORITY_4, C_COLOR_TASK_PRIORITY_5 } from '../../../../app/app.constants';

@Component({
	selector: 'trade-list-item-start-priority',
	templateUrl: './list-item-start-priority.component.html',
	styleUrls: ['./list-item-start-priority.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemStartPriorityComponent extends DestroyableComponentBase {

	//#region PROPERTIES

	/** Priorité. */
	@Input() public priority?: number | null;
	@ObserveProperty<ListItemStartPriorityComponent>({ sourcePropertyKey: "priority" })
	public readonly observablePriority = new ObservableProperty<number>();

	public readonly prioLabel$: Observable<string | undefined> = this.observablePriority.value$.pipe(
		map((pnPrio?: number) => pnPrio?.toString()),
		secure(this)
	)

	public readonly priorityColor$: Observable<string> =
		this.getPriorityColor$().pipe(secure(this));

	public readonly priorityIconColor$: Observable<string> =
		this.getPriorityIconColor$().pipe(secure(this));

	//#endregion

	//#region METHODS

	private getPriorityColor$(): Observable<string> {
		return this.observablePriority.value$.pipe(
			map((pnPrio: number) => {
				switch (pnPrio) {
					case 1:
						return C_COLOR_TASK_PRIORITY_1;
					case 2:
						return C_COLOR_TASK_PRIORITY_2;
					case 3:
						return C_COLOR_TASK_PRIORITY_3;
					case 4:
						return C_COLOR_TASK_PRIORITY_4;
					default:
						return C_COLOR_TASK_PRIORITY_5;
				}
			})
		);
	}

	private getPriorityIconColor$(): Observable<string> {
		return this.observablePriority.value$.pipe(
			map((pnPrio: number) => {
				switch (pnPrio) {
					case 1:
						return C_COLOR_TASK_ICON_PRIORITY_1;
					case 2:
						return C_COLOR_TASK_ICON_PRIORITY_2;
					case 3:
						return C_COLOR_TASK_ICON_PRIORITY_3;
					case 4:
						return C_COLOR_TASK_ICON_PRIORITY_4;
					default:
						return C_COLOR_TASK_ICON_PRIORITY_5;
				}
			})
		);
	}

	//#endregion

}
