import { Pipe, PipeTransform } from '@angular/core';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { ConvertHelper } from '../helpers/convertHelper';
import { ETimeToUnit } from '../model/helper/ETimeToUnit';

@Pipe({
	name: 'delay'
})
export class DelayPipe implements PipeTransform {

	//#region METHODS

	constructor() { }

	/** Retourne le nombre de millisecondes passé en paramètre dans une mesure de temps compréhensible par l'utilisateur, '0.00 ms' sinon.
	 * @param pnDelay Temps en millisecondes à transformer.
	 * @param peMsToUnit Paramètre optionnel permettant de spécifier directement l'unité de temps à utiliser.
	 */
	public transform(pnDelay: any, peMsToUnit?: ETimeToUnit): string {
		if (!NumberHelper.isValid(pnDelay) || pnDelay === 0)
			return this.getResult(0, ETimeToUnit.s);

		else if (peMsToUnit)
			return this.getResult(ConvertHelper.timeToUnit(pnDelay, ETimeToUnit[peMsToUnit]), ETimeToUnit[peMsToUnit]);

		else {
			const leTimeToUnitValue: ETimeToUnit = ConvertHelper.timeToEnum(pnDelay);
			const loStringEnumUnit: string = this.getAssociateUnitConst(leTimeToUnitValue);
			return this.getResult(ConvertHelper.timeToUnit(pnDelay, leTimeToUnitValue), loStringEnumUnit);
		}
	}

	private getResult(pnDelay: number, psUnit: string): string {
		if (pnDelay - Math.trunc(pnDelay) === 0) {
			return `${pnDelay} ${psUnit}`;
		}
		return `${pnDelay.toFixed(2)} ${psUnit}`;
	}

	private getAssociateUnitConst(peUnit: ETimeToUnit): string {
		let lsUnitConst = "";
		switch (peUnit) {
			case ETimeToUnit.ms: lsUnitConst = "ms"; break;
			case ETimeToUnit.s: lsUnitConst = "s"; break;
			case ETimeToUnit.mn: lsUnitConst = "min"; break;
			case ETimeToUnit.h: lsUnitConst = "h"; break;
			case ETimeToUnit.j: lsUnitConst = "j"; break;
		}
		return lsUnitConst;
	}

	//#endregion

}
