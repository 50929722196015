import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObserveArray } from '@calaosoft/osapp-common/observable/decorators/observe-array.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { IApplicationRole } from '@calaosoft/osapp-common/security/models/IApplicationRole';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserHelper } from '../../../../helpers/user.helper';
import { IContact } from '../../../../model/contacts/IContact';
import { IGroup } from '../../../../model/contacts/IGroup';
import { GroupsService } from '../../../../services/groups.service';
import { PermissionsService } from '../../../permissions/services/permissions.service';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';

@Component({
	selector: 'calao-contacts-list',
	templateUrl: './contacts-list.component.html',
	styleUrls: ['./contacts-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsListComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	/** Événement lors d'un clic sur un item d'un contact. */
	@Output("onContactClicked") private readonly moOnContactClicked = new EventEmitter<IContact>();
	/** Événement lors d'un clic sur le bouton supprimer d'un item d'un contact. */
	@Output("onDeleteContactClicked") private readonly moOnDeleteContactClickedEmitter = new EventEmitter<IContact>();
	/** Événement lors d'un clic sur le bouton editer d'un item d'un contact. */
	@Output("onEditContactClicked") private readonly moOnEditContactClickedEmitter = new EventEmitter<IContact>();

	private readonly maRoles: IApplicationRole[];

	private readonly moObservableGroupsByContactId = new ObservableProperty<Map<string, IGroup[]>>(new Map());


	//#endregion FIELDS

	//#region PROPERTIES

	/** Contacts à afficher. */
	@Input() public contacts?: IContact[];
	@ObserveArray<ContactsListComponent>("contacts")
	public readonly observableContacts = new ObservableArray<IContact>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcGroups: GroupsService,
		psvcPermissions: PermissionsService
	) {
		super();

		this.maRoles = psvcPermissions.getPermissionRoles();
	}

	public ngOnInit(): void {
		this.observableContacts.changes$.pipe(
			map((paContacts: IContact[]) => ArrayHelper.hasElements(paContacts) ? this.moveUserContactToFirst(paContacts) : paContacts),
			switchMap((paContacts: IContact[]) => {
				if (ArrayHelper.hasElements(paContacts)) {
					return this.isvcGroups.getContactsGroups(paContacts, true).pipe(
						tap((poGroupsByContactId: Map<string, IGroup[]>) => {
							this.moObservableGroupsByContactId.value = poGroupsByContactId;
						}),
					);
				}
				else
					return of(undefined);
			}),
			secure(this)
		).subscribe();
	}

	private moveUserContactToFirst(paContacts: IContact[]): IContact[] {
		const lnUserContactIndex: number = paContacts.findIndex((poContact: IContact) => this.isUserContact(poContact));
		if (lnUserContactIndex) {
			ArrayHelper.moveElement(paContacts, lnUserContactIndex, 0);
		}
		return paContacts;
	}

	private getRolesName(paGroups?: IGroup[]): string {
		const laRoleLabels: string[] = [];
		paGroups?.forEach((poGroup: IGroup) => {
			poGroup.roles?.forEach((psRoleId: string) => {
				const loRole: IApplicationRole | undefined = this.maRoles.find((poRole: IApplicationRole) => poRole.id === psRoleId);
				if (loRole && !laRoleLabels.includes(loRole.label)) {
					laRoleLabels.push(loRole.label);
				}
			});
		});
		return laRoleLabels.join(', ');
	}

	/** Récupère la concaténation des noms des groupes en paramètre, chaîne vide s'il n'y en a pas.
	 * @param paGroups Tableau des groupes à concaténer.
	 */
	private getGroupsName(paGroups?: IGroup[]): string {
		return paGroups ?
			paGroups.filter((poGroup: IGroup) => !ArrayHelper.hasElements(poGroup.roles))
				.map((poGroup: IGroup) => poGroup.name).join(", ") :
			"";
	}

	public isUserContact(poContact: IContact): Boolean {
		return UserHelper.getUserContactId() === poContact._id;
	}

	/** Retourne la concaténation des noms des groupes du contact passé en paramètre.
	 * @param poContact Le contact associé aux groupes.
	 */
	public getGroupslabel$(poContact: IContact): Observable<string> {
		return this.moObservableGroupsByContactId.value$.pipe(
			map((poGroupsByContactId: Map<string, IGroup[]>) => {
				if (poGroupsByContactId.has(poContact._id)) {
					const laGroups: IGroup[] = this.moObservableGroupsByContactId.value.get(poContact._id ?? "");
					const lsRolesName: string = this.getRolesName(laGroups);
					const lsGroupsName: string = this.getGroupsName(laGroups);
					return `${lsRolesName}${!StringHelper.isBlank(lsRolesName) && !StringHelper.isBlank(lsGroupsName) ? ", " : ""}${lsGroupsName}`;
				}
				else
					return "";
			})
		);
	}

	/** Emet l'évènement au clic sur l'item d'un contact.
	 * @param poContactLe Le contact cliqué.
	 */
	public onContactClicked(poContact: IContact): void {
		this.moOnContactClicked.emit(poContact);
	}

	/** Emet l'évènement au clic sur le bouton supprimer d'un item d'un contact.
	 * @param poContactLe Le contact cliqué.
	 */
	public onDeleteContactClicked(poContact: IContact): void {
		this.moOnDeleteContactClickedEmitter.emit(poContact);
	}

	/** Emet l'évènement au clic sur le bouton éditer d'un item d'un contact.
	 * @param poContactLe Le contact cliqué.
	 */
	public onEditContactClicked(poContact: IContact): void {
		this.moOnEditContactClickedEmitter.emit(poContact);
	}

	//#endregion METHODS

}
