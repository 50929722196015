import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { Directory } from '@capacitor/filesystem';
import { FilesystemErrorBase } from './filesystem-error-base';

export class FilesystemListDirectoryEntriesError extends FilesystemErrorBase {

	constructor(poError: any, psPath: string, peDirectory: Directory) {
		super(poError, `Can not list directory entries from '${psPath}' in directory '${peDirectory}'.`);
		// Nécessaire pour que le 'instanceof FilesystemListDirectoryEntriesError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemListDirectoryEntriesError);
	}

}