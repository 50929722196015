import { ObjectHelper } from "../../utils/helpers/objectHelper";

export class NoProviderFoundError extends Error {

	constructor(psDatabaseId: string) {
		super(`No provider with identifier ${psDatabaseId} was found.`);
		// Nécessaire pour que le 'instanceof NoDatabaseIdFoundError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, NoProviderFoundError);
	}

}