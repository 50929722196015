import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { FieldBase } from '../../../models/FieldBase';
import { FormsService } from '../../../services/forms.service';
import { ITodayHeaderFieldParams } from '../models/itoday-header-field-params';

@Component({
	selector: 'calao-today-header-field',
	templateUrl: './today-header-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodayHeaderFieldComponent extends FieldBase implements OnInit {

	//#region PROPERTIES

	/** Date actuelle. */
	public readonly observableTodayDate = new ObservableProperty<Date>().bind(
		DateHelper.getDays$(),
		this
	);

	public params: ITodayHeaderFieldParams = {};

	//#endregion PROPERTIES

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		if (this.props.data)
			this.params = this.props.data;
	}


	//#endregion
}