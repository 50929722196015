import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { Observable } from "rxjs";
import { take, tap } from "rxjs/operators";
import { IHasPermission } from "../services/permissions.service";
import { ICanExecuteParams, ICanExecuteStringParams } from "./models/ican-execute-params";

type IPermissionsMethodDescriptor = TypedPropertyDescriptor<any>;

const C_LOG_ID = "CAN.EXEC::";

/** Ajoute la vérification d'une permission à une méthode.
 * @param poParams
 */
export function CanExecute<T extends IHasPermission>(poParams: ICanExecuteParams<T>):
	(poTarget: T, psMethodName: string, poDescriptor: IPermissionsMethodDescriptor) => IPermissionsMethodDescriptor;
/** Ajoute la vérification d'une permission à une méthode.
 * @param poParams
 */
export function CanExecute<T extends IHasPermission>(poParams: ICanExecuteStringParams<T>):
	(poTarget: T, psMethodName: string, poDescriptor: IPermissionsMethodDescriptor) => IPermissionsMethodDescriptor;
export function CanExecute<T extends IHasPermission>(poParams: ICanExecuteParams<T> | ICanExecuteStringParams<T>):
	(poTarget: T, psMethodName: string, poDescriptor: IPermissionsMethodDescriptor) => IPermissionsMethodDescriptor {

	return function (poTarget: T, psMethodName: string, poDescriptor: IPermissionsMethodDescriptor): IPermissionsMethodDescriptor {
		const lfOriginalMethod: Function = poDescriptor.value; // On sauvegarde l'ancienne implémentation du getter.

		poDescriptor.value = function (): any {
			const loTarget: T = this; // Représente la classe qui appelle le décorateur.
			const loPermission: Observable<boolean> | boolean | undefined = loTarget[poParams.permission];

			// Si la permission est vérifiée de façon synchrone, on appelle l'ancienne méthode en la mettant dans le contexte de l'appelant.
			if (isPermissionCheckedSynchronously(loTarget, loPermission))
				return lfOriginalMethod.apply(loTarget, arguments);
			else if (loPermission instanceof Observable) {
				loPermission.pipe(
					take(1),
					tap((pbBoolean: boolean) => {
						if (pbBoolean)
							lfOriginalMethod.apply(loTarget, arguments);
						else
							poParams.showHasNotPermissionsPopup?.(loTarget);
					}),
					secure(loTarget)
				).subscribe();
			}
			else {
				poParams.showHasNotPermissionsPopup?.(loTarget);
				console.error(`${C_LOG_ID}Impossible d'exécuter cette action, vous n'avez pas les droits.`); //todo : supprimer le log ou le changer de niveau ?
				return () => { }; // On ne lance rien car on n'a pas les droits.
			}
		};

		return poDescriptor;
	};

	function isPermissionCheckedSynchronously(poTarget: T, poPermission?: Observable<boolean> | boolean): boolean {
		return (
			poPermission === undefined &&
			poTarget.isvcPermissions.evaluatePermission(poParams.permissionScope ?? poTarget.permissionScope, poParams.permission)
		) ||
			poPermission === true;
	}
}