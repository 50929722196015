import { ArrayHelper } from "../../utils/helpers/arrayHelper";
import { StringHelper } from "../../utils/helpers/stringHelper";
import { IProviderFilesOptions } from "../models/iprovider-files-options";
import { TSqliteExtension } from "../models/tsqlite-extension";

/** Permet de mettre à disposition des méthodes pour aider à manipuler des fichiers sql. */
export abstract class SqlFilesHelper {

	//#region FIELDS

	private static readonly C_SEP = "-";
	private static readonly C_LOG_ID = "SQL.FILES.H::";

	//#endregion FIELDS

	//#region METHODS

	/** `true` si la chaîne de caractères se termine par une extension possible de base de données sqlite, `false` sinon.
	 * @param psFileNameOrPath Chaîne de caractères à vérifier.
	 */
	public static hasCorrectSqliteExtension(psFileNameOrPath: string): boolean {
		return StringHelper.isValid(psFileNameOrPath) && (psFileNameOrPath.endsWith(".db") || psFileNameOrPath.endsWith(".zip"));
	}

	/** L'extension de base de données sqlite contenue dans la chaîne de caractères, `undefined` si la chaîne ne se termine pas par une extension possible.
	 * @param psFileNameOrPath Chaîne de caractères dont on veut extraire l'extension de base de données sqlite.
	 */
	public static extractExtension(psFileNameOrPath: string): TSqliteExtension | undefined {
		return this.hasCorrectSqliteExtension(psFileNameOrPath) ? ArrayHelper.getLastElement(psFileNameOrPath.split(".")) as TSqliteExtension : undefined;
	}

	/** Construit et retourne le nom du fichier.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @param pnVersion Verison de la base de données.
	 * @param poOptions Options pour la récupération.
	 */
	public static getFileName(psDatabaseId: string, pnVersion: number, poOptions?: IProviderFilesOptions): string {
		if (StringHelper.isBlank(psDatabaseId)) {
			console.error(`${SqlFilesHelper.C_LOG_ID}Database id '${psDatabaseId}' in version '${pnVersion}' is not valid.`);
			throw new Error(`Recovery of file name failed because database id '${psDatabaseId}' in version '${pnVersion}' is not valid.`);
		}
		else {
			const lsExtension: TSqliteExtension = poOptions?.includesZipOnly ? "zip" : "db";

			return `${psDatabaseId}${SqlFilesHelper.C_SEP}${pnVersion.toString()}.${lsExtension}`;
		}
	}

	//#endregion METHODS

}