import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DashboardEntityTileComponent } from './components/dashboard-entity-tile/dashboard-entity-tile.component';
import { DashboardListHeaderComponent } from './components/dashboard-list-header/dashboard-list-header.component';
import { DashboardListItemComponent } from './components/dashboard-list-item/dashboard-list-item.component';
import { DashboardListComponent } from './components/dashboard-list/dashboard-list.component';
import { DashboardTileComponent } from './components/dashboard-tile/dashboard-tile.component';
import { DashboardTilesComponent } from './components/dashboard-tiles/dashboard-tiles.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

const components: Array<Type<any> | any[]> = [
	DashboardComponent,
	DashboardTilesComponent,
	DashboardTileComponent,
	DashboardListComponent,
	DashboardListItemComponent,
	DashboardListHeaderComponent,
	DashboardEntityTileComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		IonicModule
	]
})
export class DashboardModule { }
