import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObserveArray } from '@calaosoft/osapp-common/observable/decorators/observe-array.decorator';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { Observable, map, merge, startWith, switchMap, tap } from 'rxjs';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { PlatformService } from '../../../../services/platform.service';
import { ITabLayoutTabParams } from '../../../entities/models/itab-layout-tab-params';
import { TabsSegmentComponent } from '../tabs-segment/tabs-segment.component';

@Component({
	selector: 'calao-scroll-tabs-segments',
	templateUrl: './scroll-tabs-segments.component.html',
	styleUrls: ['./scroll-tabs-segments.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollTabsSegmentsComponent extends ComponentBase {

	//#region FIELDS

	@ViewChildren(TabsSegmentComponent) private moSegments: QueryList<TabsSegmentComponent>;

	private mbIsSegmentClick = false;

	//#endregion

	//#region PROPERTIES

	@Input() public tabs?: ITabLayoutTabParams[];
	@ObserveArray<ScrollTabsSegmentsComponent>("tabs")
	public readonly observableTabs = new ObservableArray<ITabLayoutTabParams>();

	/** Indique si on veut naviguer lors du clic sur un segment. */
	@Input() public navigateOnClick?: boolean;
	@ObserveProperty<ScrollTabsSegmentsComponent>({ sourcePropertyKey: "navigateOnClick" })
	public readonly observableNavigateOnClick = new ObservableProperty<boolean>();

	public readonly observableActiveTabIndex = new ObservableProperty<number>(0);
	public readonly activeTabValue$: Observable<string> = this.observableActiveTabIndex.value$.pipe(
		map((pnIndex: number) => `${pnIndex}`),
		secure(this)
	);

	@ViewChild('dragScroll') public dragScroll?: DragScrollComponent;
	@ViewChild('dragScroll', { read: ElementRef }) public dragScrollElement?: ElementRef<HTMLElement>;
	@ViewChildren(DragScrollItemDirective, { read: ElementRef }) public dragScrollDirectives?: QueryList<ElementRef<HTMLElement>>;

	/** Indique si le boutton de défilement vers la gauche doit être caché */
	public observableHidLeftBtn = new ObservableProperty<boolean>(true);
	/** Indique si le boutton de défilement vers la droite doit être caché */
	public observableHidRightBtn = new ObservableProperty<boolean>(true);

	public get isMobile(): boolean {
		return this.isvcPlatform.isMobile;
	}

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcPlatform: PlatformService,
		private readonly ioRouter: Router,
		private readonly ioRoute: ActivatedRoute,
		poChangeDetectorRef: ChangeDetectorRef,
	) {
		super(poChangeDetectorRef);
	}

	public override ngAfterViewInit(): void {
		super.ngAfterViewInit();

		this.moSegments.changes.pipe(
			startWith({}),
			switchMap(() => this.observeSegmentIndexClicked$()),
			tap((pnIndex: number) => {
				if (this.observableNavigateOnClick.value) {
					this.ioRouter.navigate([], {
						relativeTo: this.ioRoute,
						queryParams: { tab: pnIndex }
					});
				}
				else
					this.observableActiveTabIndex.value = pnIndex;
			}),
			secure(this)
		).subscribe();

		this.observableActiveTabIndex.value$.pipe(
			tap((pnIndex: number) => {
				this.moSegments.forEach((poItem: TabsSegmentComponent) => {
					poItem.detectChanges();
				});
				if (!this.mbIsSegmentClick) // Navigation hors clic, ex: swipe.
					this.dragScroll?.moveTo(pnIndex);
				this.mbIsSegmentClick = false;
			}),
			secure(this)
		).subscribe();

		if (this.dragScroll) {
			this.dragScroll.scrollbarHidden = true;
			this.dragScroll.snapOffset = 10;
			if (this.dragScrollElement)
				this.disableTouchPropagation(this.dragScrollElement);
			this.dragScrollDirectives?.forEach((poElementRef: ElementRef<HTMLElement>) => this.disableTouchPropagation(poElementRef));
		}
	}

	private observeSegmentIndexClicked$(): Observable<number> {
		return merge(...this.moSegments.map(
			(poItem: TabsSegmentComponent, pnIndex: number) => {
				poItem.index = pnIndex;
				return poItem.segmentClickedEvent.pipe(map(() => {
					this.mbIsSegmentClick = true;
					return pnIndex;
				}));
			}
		))
	}

	public leftBoundStat(reachesLeftBound: boolean): void {
		this.observableHidLeftBtn.value = reachesLeftBound;
		this.detectChanges();
	}

	public rightBoundStat(reachesRightBound: boolean): void {
		this.observableHidRightBtn.value = reachesRightBound;
		this.detectChanges();
	}

	public moveLeft(): void {
		for (let lnIndex = 0; lnIndex < 2; ++lnIndex) {
			this.dragScroll?.moveLeft();
		}
	}

	public moveRight(): void {
		for (let lnIndex = 0; lnIndex < 2; ++lnIndex) {
			this.dragScroll?.moveRight();
		}
	}

	/** Evite les bug de conflits lors du swipe. */
	public disableTouchPropagation(poElementRef: ElementRef<HTMLElement>): void {
		poElementRef.nativeElement.addEventListener("touchmove", (poEvent: TouchEvent) => poEvent.stopPropagation());
		poElementRef.nativeElement.addEventListener("touchstart", (poEvent: TouchEvent) => poEvent.stopPropagation());
		poElementRef.nativeElement.addEventListener("touchend", (poEvent: TouchEvent) => poEvent.stopPropagation());
	}

	//#endregion METHODS

}
