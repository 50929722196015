import { Exclude, Expose } from "@calaosoft/osapp-common/class-transformer";
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ETimetablePattern } from "@calaosoft/osapp-common/dates/models/ETimetablePattern";
import { Entity } from "@calaosoft/osapp-common/entities/models/entity";
import { ObserveArray } from '@calaosoft/osapp-common/observable/decorators/observe-array.decorator';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { ResolveModel } from "@calaosoft/osapp-common/utils/decorators/resolve-model.decorator";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { ModelResolver } from '@calaosoft/osapp-common/utils/models/model-resolver';
import { Observable, combineLatest } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { ContactHelper } from "../../../helpers/contactHelper";
import { UserHelper } from "../../../helpers/user.helper";
import { UserData } from "../../../model/application/UserData";
import { IContact } from "../../../model/contacts/IContact";
import { GalleryFile } from "../../../model/gallery/gallery-file";
import { Contact } from "../../contacts/models/contact";
import { DefaultValue } from "../../utils/models/decorators/default-value.decorator";
import { BaseEvent } from "./base-event";
import { EEventParticipationStatus } from "./eevent-participation-status";
import { EventDuration } from "./event-duration";
import { EventOccurrenceDifferential } from "./event-occurrence-differential";
import { EventState } from "./event-state";
import { IEventNotification } from "./ievent-notification";
import { IEventOccurrence } from "./ievent-occurrence";
import { IEventParticipantStatus } from "./ievent-participant-status";
import { Recurrence } from "./recurrence";

export abstract class BaseEventOccurrence extends Entity implements IEventOccurrence {

	//#region FIELDS

	@Exclude()
	protected static readonly C_CURRENT_USER_LABEL: string = "Moi";
	@Exclude()
	private static readonly C_DEFAULT_ID = "";

	//#endregion FIELDS

	//#region PROPERTIES

	@ResolveModel(BaseEvent)
	protected moEvent: BaseEvent;
	public get event(): BaseEvent {
		return this.moEvent;
	}

	public readonly eventId: string;

	/** Identifiant du créateur de l'occurrence. */
	public authorId: string;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "authorId" })
	public readonly observableAuthorId = new ObservableProperty<string>();

	public eventType?: string;
	@ResolveModel(Recurrence)
	public recurrences?: Recurrence[];
	@ResolveModel(GalleryFile)
	public attachments?: GalleryFile[];

	@Exclude()
	protected maParticipantIds: string[];
	/** Identifiants des participants. */
	@Expose()
	public get participantIds(): string[] {
		return this.maParticipantIds;
	}

	public set participantIds(paParticipantsIds: string[]) {
		this.maParticipantIds = paParticipantsIds;
	}

	@ObserveArray<any>("maParticipantIds") // Je force le typage en any pour pouvoir écouter les changements sur le tableau privé.
	public readonly observableParticipantIds = new ObservableArray<string>();

	public eventSubtype?: string;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "eventSubtype" })
	public readonly observableSubtype = new ObservableProperty<string>();

	@Exclude()
	// On garde une référence sur l'état de l'évènement qui a généré l'occurrence pour permettre la comparaison
	public stateByUserId?: Map<string, EventState>;

	public fullDay: boolean;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "fullDay" })
	public readonly observableFullDay = new ObservableProperty<boolean>(false, () => this.onFullDayChanged());

	@ResolveModel(EventDuration)
	public duration: EventDuration;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "duration" })
	public readonly observableDuration = new ObservableProperty<EventDuration>(new EventDuration, () => this.calculateEndDate());

	@ResolveModel(Date)
	public startDate?: Date;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "startDate" })
	public readonly observableStartDate = new ObservableProperty<Date>(undefined, () => this.calculateEndDate());

	public title: string;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "title" })
	public readonly observableTitle = new ObservableProperty<string>();

	public street?: string;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "street" })
	public readonly observableStreet = new ObservableProperty<string>();

	public zipCode?: string;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "zipCode" })
	public readonly observableZipCode = new ObservableProperty<string>();

	public city?: string;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "city" })
	public readonly observableCity = new ObservableProperty<string>();

	public place?: string;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "place" })
	public readonly observablePlace = new ObservableProperty<string>();

	public comment?: string;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "comment" })
	public readonly observableComment = new ObservableProperty<string>();

	public notes?: string;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "notes" })
	public readonly observableNotes = new ObservableProperty<string>();

	@Exclude()
	public participants: Contact[];
	@ObserveArray<BaseEventOccurrence>("participants")
	public readonly observableParticipants = new ObservableArray<Contact>();

	public get lastChangeStatusContactId(): string | undefined {
		if (ArrayHelper.hasElements(this.recurrences)) {
			const lsLastChangeStatusUserId: string = ArrayHelper.getLastElement(this.eventOccurrenceDifferential?.differential.status)?.userId;
			if (!StringHelper.isBlank(lsLastChangeStatusUserId))
				return UserHelper.getUserContactId(lsLastChangeStatusUserId);
		}
		else {
			const loLastState: EventState | undefined = this.event.getLastState();
			if (loLastState)
				return UserHelper.getUserContactId(loLastState.userId);
		}
		return undefined;
	}

	/** Contact de la personne à avoir modifié le status de l'occurrence. */
	@Exclude()
	@ResolveModel(Contact)
	@Exclude()
	public lastChangeStatusContact?: Contact;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "lastChangeStatusContact" })
	public readonly observableLastChangeStatusContact = new ObservableProperty<Contact>();

	@Exclude()
	public differentialExecuted?: boolean;

	@Exclude()
	public readonly observableEndDate = new ObservableProperty<Date>;
	@Exclude()
	public readonly dateLabel$: Observable<string> = this.getDateLabel$();
	@Exclude()
	public readonly periodLabel$: Observable<string> = this.getPeriodLabel$();
	@Exclude()
	public readonly authorLabel$: Observable<string> = this.getAuthorLabel$();
	@Exclude()
	public readonly participantsLabel$: Observable<string> = this.getParticipantsLabel$();
	@Exclude()
	public readonly addressLabel$: Observable<string> = this.getAddressLabel$();

	public status?: string;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "status" })
	public readonly observableStatus = new ObservableProperty<string>();

	@ResolveModel(Date)
	public statusChangeDate?: Date;
	@ObserveProperty<BaseEventOccurrence>({ sourcePropertyKey: "statusChangeDate" })
	public readonly observableStatusChangeDate = new ObservableProperty<Date>();

	public get sortDate(): Date | undefined {
		return this.startDate;
	}

	@Exclude()
	public readonly isLate$: Observable<boolean> = this.getLateStatus$();

	protected getLateStatus$(): Observable<boolean> {
		return DateHelper.getMinutes$().pipe(
			switchMap((pdDate: Date) => {
				return this.observableEndDate.value$.pipe(
					map((pdEndDate?: Date) => pdEndDate ? DateHelper.compareTwoDates(pdDate, pdEndDate) >= 0 : false)
				);
			}),
			distinctUntilChanged()
		);
	}

	public get canBeDeleted(): boolean {
		return this.event?.canBeDeleted;
	}

	@Exclude()
	public readonly canBeEdited$: Observable<boolean> = this.getCanBeEdited$();

	@Exclude()
	public eventOccurrenceDifferential?: EventOccurrenceDifferential;

	@Expose({ toPlainOnly: true })
	@Exclude({ toClassOnly: true })
	public get isDelegated(): boolean {
		return !this.participantIds.includes(UserHelper.getUserContactId(this.authorId));
	}

	public get delegatedByMe(): boolean {
		return this.isDelegated && this.authorId === UserHelper.getUserId();
	}

	@Exclude({ toPlainOnly: true })
	@DefaultValue(() => [])
	public participantsStatus: IEventParticipantStatus[];
	@ObserveArray<BaseEventOccurrence>("participantsStatus")
	public readonly observableParticipantsStatus = new ObservableArray<IEventParticipantStatus>();

	@Exclude()
	public hasToRequestParticipation$: Observable<boolean> = this.observableParticipantsStatus.changes$.pipe(
		map((paStatus: IEventParticipantStatus[]) => paStatus.some((poParticipantStatus: IEventParticipantStatus) =>
			this.getHasToRequestParticipation(poParticipantStatus)
		))
	);

	public get hasToRequestParticipation(): boolean {
		return this.participantsStatus.some((poParticipantStatus: IEventParticipantStatus) =>
			this.getHasToRequestParticipation(poParticipantStatus)
		);
	}

	public get hasToRequestGlobalParticipation(): boolean {
		return this.participantsStatus.some((poParticipantStatus: IEventParticipantStatus) =>
			this.getHasToRequestParticipation(poParticipantStatus) &&
			poParticipantStatus.isBaseParticipation
		);
	}

	public hasVisio?: boolean;

	public get isMinePrivateEvent(): boolean {
		return this.event.private && this.participantIds.some((psParticpant: string) => psParticpant === UserHelper.getUserContactId());
	}

	public notifications: IEventNotification[];

	//#endregion

	//#region METHODS

	constructor(poEvent: BaseEvent, pdStartDate?: Date) {
		super();
		Object.defineProperty(this, "_id", { get: () => BaseEventOccurrence.C_DEFAULT_ID, set: () => { } }); // Pas d'identifiant car pas en base
		this.startDate = pdStartDate;
		this.eventId = poEvent?._id;
		this.title = poEvent?.title;
		this.comment = poEvent?.comment;
		this.authorId = poEvent?.authorId;
		this.duration = new EventDuration(poEvent?.duration);
		this.fullDay = poEvent?.fullDay;
		this.eventType = poEvent?.eventType;
		this.eventSubtype = poEvent?.eventSubtype;
		this.stateByUserId = poEvent?.stateByUserId;
		this.street = poEvent?.street;
		this.zipCode = poEvent?.zipCode;
		this.city = poEvent?.city;
		this.maParticipantIds = poEvent?.participantIds ?? [];
		this.attachments = [...(poEvent?.attachments ?? [])];
		this.recurrences = [...(poEvent?.recurrences ?? [])];
		this.status = poEvent?.status;
		this.statusChangeDate = poEvent?.statusChangeDate ?? poEvent?.creationDate;
		this.notes = poEvent?.notes;
		this.moEvent = poEvent;
		this.place = poEvent?.place;
		this.hasVisio = poEvent?.hasVisio;
		this.notifications = [...(poEvent?.notifications ?? [])];

		// Si l'évènement doit durer la journée entière, on considère qu'il démarre à minuit le jour concerné
		if (this.fullDay)
			this.startDate = DateHelper.resetDay(this.startDate);
	}

	private calculateEndDate(): void {
		let ldEndDate: Date;
		if (this.duration && this.startDate) {
			if (this.fullDay)
				ldEndDate = DateHelper.addDays(DateHelper.fillDay(this.startDate), this.duration.days ?? 1);
			else
				ldEndDate = this.duration.addDurationToDate(this.startDate);
		}

		this.observableEndDate.value = ldEndDate;
	}

	private calculateStartDate(): void {
		if (this.startDate && this.fullDay)
			this.startDate = DateHelper.resetDay(this.startDate);
	}

	private onFullDayChanged(): void {
		this.calculateStartDate();
		this.calculateEndDate();
	}

	protected getDateLabel$(): Observable<string> {
		return combineLatest([this.observableFullDay.value$, this.observableStartDate.value$, this.observableEndDate.value$]).pipe(
			map(([pbFullDay, pdStart, pdEnd]: [boolean, Date, Date]) => {
				let lsLabel = "";

				if (DateHelper.diffDays(pdStart, pdEnd) === 0) {
					// L'évènement se déroule sur un seul jour
					lsLabel = `le ${DateHelper.transform(pdStart, ETimetablePattern.dd_MM_yyyy_slash)}`;
					if (pbFullDay)
						lsLabel += ", toute la journée";
					else
						lsLabel += `, de ${DateHelper.transform(pdStart, ETimetablePattern.HH_mm)} à ${DateHelper.transform(pdEnd, ETimetablePattern.HH_mm)}`;
				}
				else {
					if (pdStart && pdEnd) {
						const lePattern: ETimetablePattern = pbFullDay ? ETimetablePattern.dd_MM_yyyy_slash : ETimetablePattern.dd_MM_yyyy_HH_mm_slash;
						lsLabel = `du ${DateHelper.transform(pdStart, lePattern)} au ${DateHelper.transform(pdEnd, lePattern)}`;
					}
				}

				return lsLabel;
			})
		);
	}

	private getPeriodLabel$(): Observable<string> {
		return combineLatest([this.observableStartDate.value$, this.observableEndDate.value$]).pipe(
			map(([pdStart, pdEnd]: [Date, Date]) => {
				if (this.fullDay)
					return `du ${DateHelper.transform(pdStart, ETimetablePattern.dd_MM_yyyy_slash)} au ${DateHelper.transform(pdEnd, ETimetablePattern.dd_MM_yyyy_slash)}`;
				else
					return `${DateHelper.transform(pdStart, ETimetablePattern.HH_mm)} à ${DateHelper.transform(pdEnd, ETimetablePattern.HH_mm)}`;
			})
		);
	}

	protected getParticipantsLabel$(): Observable<string> {
		return this.observableParticipants.changes$.pipe(
			map((paContacts: IContact[]) => paContacts.map((poContact: IContact) => {
				return ContactHelper.isCurrentUser(poContact) ? BaseEventOccurrence.C_CURRENT_USER_LABEL : ContactHelper.getCompleteFormattedName(poContact);
			}).join(", "))
		);
	}

	protected getAuthorLabel$(): Observable<string> {
		return this.observableParticipants.changes$.pipe(
			map((paContacts: IContact[]) => paContacts.find((poContact: IContact) => poContact._id === UserHelper.getUserContactId(this.authorId))),
			map((poAuthorContact?: IContact) => {
				if (poAuthorContact)
					return ContactHelper.isCurrentUser(poAuthorContact) ? BaseEventOccurrence.C_CURRENT_USER_LABEL : ContactHelper.getCompleteFormattedName(poAuthorContact);
				else
					return "";
			})
		);
	}

	private getAddressLabel$(): Observable<string> {
		return combineLatest([this.observableStreet.value$, this.observableZipCode.value$, this.observableCity.value$])
			.pipe(
				map(([psStreet, psZipCode, psCity]: [string, string, string]) => {
					if (!StringHelper.isBlank(psStreet) && !StringHelper.isBlank(psZipCode + psCity))
						psStreet += ",";
					return `${psStreet} ${psZipCode} ${psCity}`;
				})
			);
	}

	public hasSomeParticipants(paParticipantsIds?: string[]): boolean {
		return !ArrayHelper.hasElements(this.participantIds) || !ArrayHelper.hasElements(paParticipantsIds) ||
			paParticipantsIds.some((psId: string) => this.participantIds.includes(psId));
	}

	protected getCanBeEdited$(): Observable<boolean> {
		return combineLatest([this.observableAuthorId.value$, this.observableParticipantIds.changes$]).pipe(
			map(([psAutorId]: [string, string[]]) => psAutorId === UserData.current?._id || this.hasSomeParticipants([UserHelper.getUserContactId()])),
			distinctUntilChanged()
		);
	}

	public getTranslatedStatus(): string {
		switch (this.observableStatus.value) {
			case ("todo"):
				return "À faire";
			case ("active"):
				return "En cours";
			case ("pending"):
				return "En attente";
			case ("done"):
				return ("terminée");
			default:
				return "";
		}
	}

	private getHasToRequestParticipation(poParticipantStatus: IEventParticipantStatus): boolean {
		return poParticipantStatus.status === EEventParticipationStatus.waiting &&
			!poParticipantStatus.organizer &&
			poParticipantStatus.participantId === UserHelper.getUserContactId();
	}

	public override clone(): this {
		const loClonedOccurrence: this =
			ModelResolver.toClass(BaseEventOccurrence, ModelResolver.toPlain(this)) as this;
		loClonedOccurrence.moEvent = this.event.clone();

		return loClonedOccurrence;
	}

	//#endregion

}