import { Component, Input, OnInit } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { IMenuComponentDescriptor } from '../../model/IMenuComponentDescriptor';
import { IPopoverItem } from '../../model/IPopoverItem';
import { LinkInfo } from '../../model/link/LinkInfo';
import { DestroyableComponentBase } from '../../modules/utils/components/destroyable-component-base';
import { MenuService } from './menu.service';

/** Composant permettant l'utilisation des popovers. */
@Component({
	selector: 'calao-menu-popover',
	templateUrl: './menuPopover.component.html'
})
export class MenuPopoverComponent extends DestroyableComponentBase implements OnInit {

	//#region PROPERTIES

	/** Nom du menu popover à trouver dans la base de données. */
	@Input() public popoverName = "popover";
	/** Liste des liens disponibles dans le menu. */
	public linkList: LinkInfo[] = [];
	/** Titre du menu. */
	public title: string;

	//#endregion

	//#region METHODS

	constructor(private readonly isvcMenu: MenuService) {
		super();
	}

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		// On regarde dans le service des menus pour voir si on a une structure "custom" càd qui a été ajoutée par le code spécifique de l'application.
		const laCustomPopoverItems: Array<IPopoverItem> = this.isvcMenu.getPopoverCustomItems();
		if (ArrayHelper.hasElements(laCustomPopoverItems))
			this.linkList = laCustomPopoverItems as any; //! Typage foireux.

		if (!ArrayHelper.hasElements(this.linkList))
			this.init();
	}

	private init(): void {
		this.isvcMenu.getMenu(this.popoverName) // On récupère le menu en base de données.
			.pipe(
				map((poMenu: IMenuComponentDescriptor) => {
					this.linkList = this.linkList.concat((poMenu as any).links); //! Typage foireux.

					if (!StringHelper.isBlank((poMenu as any).title)) //! Typage foireux.
						this.title = (poMenu as any).title; //! Typage foireux.
				}),
				catchError(poError => { console.error(`MNUP.C:: Erreur à la récupèration des informations de la popover : `, poError); return throwError(() => poError); }),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	//#endregion

}