import { ArrayHelper } from "@calaosoft/osapp-common/utils/helpers/arrayHelper";

export abstract class HttpHelper {

	//#region FIELDS

	private static readonly C_LOG_ID = "HTTP.H::";

	//#endregion FIELDS

	//#region METHODS

	private constructor() { }

	/** Extrait le domaine d'une url. Le résultat est vide s'il est impossible d'extraire le domaine.
	 * @example `http://localhost:8100/conversations/conv_c8f132201f9e4979bf732adeacbef21f` retourne `localhost:8100`.
	 */
	public static getDomain(psUrl: string): string {
		try {
			return new URL(psUrl).hostname;
		}
		catch (error) {
			console.error(`${HttpHelper.C_LOG_ID}Invalid url '${psUrl}'`, error);
			return "";
		}
	}

	public static isSameDomain(psTargetUrl: string): boolean {
		return window.location.hostname === HttpHelper.getDomain(psTargetUrl);
	}

	public static getPath(psUrl: string): string {
		try {
			const loUrl = new URL(psUrl);
			return `${loUrl.pathname}${loUrl.search}${loUrl.hash}`;
		}
		catch (poError) {
			console.error(`${HttpHelper.C_LOG_ID}Invalid url '${psUrl}'`, poError);
			return "";
		}
	}

	/** `true` si l'url provient d'un domaine autorisé ou qu'il n'y a pas de restriction (tableau vide), `false` sinon.
	 * @param psUrl Url dont il faut vérifier si le domaine est autorisé.
	 * @param paAllowedDomains Tableau des domaines autorisés.
	 */
	public static isDomainAllowed(psUrl: string, paAllowedDomains: string[] = []): boolean {
		if (!ArrayHelper.hasElements(paAllowedDomains)) // Si pas de restrictions de domaines, tout est autorisé.
			return true;

		try {
			const lsHostname: string = new URL(psUrl).hostname;

			return paAllowedDomains.some((psAllowedDomain: string) => lsHostname.includes(psAllowedDomain));
		}
		catch (poError) {
			console.error(`${HttpHelper.C_LOG_ID}Invalid url '${psUrl}', can not testing allowed domain`, poError);
			return false;
		}
	}

	//#endregion

}