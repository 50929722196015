import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { OverlayEventDetail } from '@ionic/core';
import { FlagService } from '../../services/flag.service';
import { EFlag } from '../flags/models/EFlag';
export class Loader {

	//#region FIELDS

	private static readonly C_LOADER_TEXT_SUFFIX = "...";

	/** Indique si on veut afficher seulement l'icône, sans texte à côté. */
	private mbIconOnly: boolean;

	//#endregion

	//#region PROPERTIES

	private msText: string;
	/** Texte affiché dans le loader. */
	public get text(): string { return this.msText; }
	public set text(psText: string) {
		let lsText: string;

		if (this.mbIconOnly)
			lsText = "";
		else // Permet d'avoir des messages de chargement sous le même format : "Texte ..."
			lsText = `${(StringHelper.isBlank(psText) ? "Chargement" : psText?.replace(/\s?\.\.\.$/, ""))} ${Loader.C_LOADER_TEXT_SUFFIX}`;

		if (lsText !== this.msText) {
			this.msText = lsText;
			this.updateText(this.msText);
		}
	}

	private mbBackdropDismiss: boolean;
	/** Indique si un clic en dehors du loader doit le fermer. */
	public get backdropDismiss(): boolean { return this.mbBackdropDismiss; }
	public set backdropDismiss(pbBackdropDismiss: boolean) {
		if (pbBackdropDismiss !== this.mbBackdropDismiss) {
			this.mbBackdropDismiss = pbBackdropDismiss;

			if (this.isPresented)
				this.updateBackdropDissmiss(this.mbBackdropDismiss);
		}
	}

	private mbPresented: boolean;
	public get isPresented(): boolean { return this.mbPresented; }

	private mbDismissed: boolean;
	public get isDismissed(): boolean { return this.mbDismissed; }

	//#endregion

	//#region METHODS

	constructor(private readonly moLoader: HTMLIonLoadingElement, private readonly msvcFlag: FlagService, pbIconOnly?: boolean) {
		this.onDidDismiss().then(_ => {
			this.mbDismissed = true;
			this.mbPresented = false;
		});

		this.mbIconOnly = pbIconOnly;
		this.text = moLoader.message as string;
		this.mbBackdropDismiss = moLoader.backdropDismiss;
	}

	public onDidDismiss(): Promise<OverlayEventDetail<any>> {
		return this.moLoader.onDidDismiss();
	}

	/** Fait disparaître le spinner. */
	public dismiss(): Promise<boolean> {
		if (this.isPresented)
			return this.moLoader.dismiss();
		else {
			console.warn("LOADER::Cannot dismiss not presented loader.");
			return Promise.resolve(false);
		}
	}

	/** Affiche un spinner.
	 * ### Penser à appeler `dismiss()` pour supprimer le possible loader.
	 */
	public async present(): Promise<Loader> {
		if (!this.isDismissed && !this.isPresented) {
			this.mbPresented = true;

			if (this.msvcFlag.getFlagValue(EFlag.splashHidden))
				await this.moLoader.present();
			else
				this.msvcFlag.waitForFlag(EFlag.splashHidden, true).subscribe(() => this.present());
		}
		else if (this.isDismissed)
			console.warn("LOADER::Cannot present already dismissed loader.");
		else
			console.warn("LOADER::Cannot present already presented loader.");

		return this;
	}

	/** Met a jour le texte du spinner. */
	private updateText(psText: string): void {
		if (this.moLoader.message !== psText)
			this.moLoader.message = psText;
	}

	/** Met à jour les options de fermeture du loader. */
	private updateBackdropDissmiss(pbBackdropDismiss: boolean): void {
		this.moLoader.backdropDismiss = pbBackdropDismiss;
	}

	//#endregion
}