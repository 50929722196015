import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';

@Component({
	selector: 'calao-filter-bar-item',
	templateUrl: './filter-bar-item.component.html',
	styleUrls: ['./filter-bar-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'",
		"[style.flex-direction]": "'column'"
	}
})
export class FilterBarItemComponent<T = any> extends DestroyableComponentBase {

	//#region PROPERTIES

	@ContentChild(TemplateRef) public readonly templateRef: TemplateRef<any>;

	/** Clé de l'item. */
	@Input() public key?: string;
	@Input() public defaultValue?: T;

	public readonly observableValue = new ObservableProperty<T>();

	//#endregion

}
