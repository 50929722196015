import { ObservableObjectBase } from '@calaosoft/osapp-common/observable/models/observable-object-base';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';

export class ImageDmsIds extends ObservableObjectBase {

	//#region PROPERTIES

	private msMainId: string;
	/** Identifiant de l'image principale. */
	public get mainId(): string { return this.msMainId; }
	public set mainId(psMainId: string) {
		if (psMainId !== this.msMainId) {
			this.msMainId = psMainId;
			this.moObserver.emit(this);
		}
	}

	private maAlternativeIds: string[] = [];
	/** Identifiant de l'image secondaire. */
	public get alternativeIds(): string[] { return this.maAlternativeIds; }
	public set alternativeIds(paAlternativeIds: string[]) {
		if (!ArrayHelper.areArraysEqual(this.maAlternativeIds, paAlternativeIds)) {
			this.maAlternativeIds = paAlternativeIds;
			this.moObserver.emit(this);
		}
	}

	//#endregion PROPERTIES

	//#region METHODS

	constructor(psMainId: string, paAlternativeIds: string[]) {
		super();

		this.msMainId = psMainId;
		this.alternativeIds.push(...paAlternativeIds);
	}

	public equals(poNewValue: ImageDmsIds | undefined): boolean {
		return !!poNewValue &&
			this.msMainId === poNewValue.mainId &&
			ArrayHelper.areArraysEqual(this.maAlternativeIds, poNewValue.alternativeIds);
	}

	//#endregion METHODS

}