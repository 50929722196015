import { Exclude } from "@calaosoft/osapp-common/class-transformer";
import { StoreDocument } from "@calaosoft/osapp-common/store/models/store-document";
import { ResolveModel } from "@calaosoft/osapp-common/utils/decorators/resolve-model.decorator";
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { IInterventionStatement } from "./iintervention-statement";

export class InterventionStatement extends StoreDocument implements IInterventionStatement {
	@ResolveModel(Date)
	public date: Date;
	public userId: string;
	public entityId: string;
	public skipReason?: string;
	@ResolveModel(Date)
	public originalStartDate?: Date;

	@Exclude()
	public get userContactId(): string {
		return IdHelper.replacePrefixId(this.userId, EPrefix.contact, EPrefix.user);
	}
}