import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { IEntityLinkRelationData } from '@calaosoft/osapp-common/entities/models/ientity-link-relation-data';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { startWith, switchMap, tap } from 'rxjs';
import { EntityLinkService } from '../../../../../../services/entityLink.service';
import { EntitySelectorComponent } from '../../../../../entities/components/entity-selector/entity-selector.component';
import { IEntityDescriptor } from '../../../../../entities/models/ientity-descriptor';
import { IEntitySelectorParamsBase } from '../../../../../entities/models/ientity-selector-params-base';
import { EntitiesService } from '../../../../../entities/services/entities.service';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IEntitySelectorFieldParams } from './models/ientity-selector-field-params';

@Component({
	selector: 'calao-entity-selector-field',
	templateUrl: './entity-selector-field.component.html',
	styleUrls: ['./entity-selector-field.component.scss'],
})
export class EntitySelectorFieldComponent extends FieldBase<string> implements OnInit {

	//#region FIELDS

	@ViewChild(EntitySelectorComponent) moEntitySeletor: EntitySelectorComponent;

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly observableParams = new ObservableProperty<IEntitySelectorFieldParams>();
	public readonly observableSelectorParams = new ObservableProperty<IEntitySelectorParamsBase>();

	public readonly observableModel = new ObservableProperty<Entity | undefined>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcEntities: EntitiesService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.initParams();

		this.observableParams.value$.pipe(
			switchMap((poParams: IEntitySelectorFieldParams) => this.isvcEntities.getDescriptor$(poParams.entityDescId).pipe(
				switchMap((poDesc: IEntityDescriptor) => this.isvcEntityLink.getLinkedEntities(
					this.isvcEntityLink.currentEntity,
					IdHelper.getPrefixFromId(poDesc.idPattern),
					poParams.linkTypes.target,
					true
				)),
				tap((paLinkedEntities: Entity[]) => {
					const loLinkedEntity: Entity | undefined = ArrayHelper.getFirstElement(paLinkedEntities);
					this.observableModel.value = loLinkedEntity;
					if (loLinkedEntity)
						this.fieldValue = loLinkedEntity._id;
					this.observableSelectorParams.value = {
						...poParams,
						preselectedEntityId: loLinkedEntity?._id ?? poParams.preselectedEntityId
					};
				})
			)),
			secure(this)
		).subscribe();
	}

	private initParams(): void {
		let loOldResetValue: any | undefined;
		this.options.fieldChanges
			.pipe(
				startWith(undefined),
				tap(() => {
					const loParams: IEntitySelectorFieldParams = { ...this.props.data }; // On clone pour la propriété observable

					if (loParams.resetIfValueChanged && loOldResetValue) {
						if (loOldResetValue !== loParams.resetIfValueChanged)
							this.moEntitySeletor.onResetEntityClicked();
					}
					loOldResetValue = loParams.resetIfValueChanged

					if (loParams.readOnly === undefined)
						loParams.readOnly = this.to.readonly;

					if (!ObjectHelper.areEqual(this.observableParams.value, loParams))
						this.observableParams.value = loParams;
				}),
				secure(this)
			)
			.subscribe();
	}

	public onSelectedEntityChanged(poEntity?: Entity): void {
		const loRelationData: Map<string, IEntityLinkRelationData> | undefined = this.getRelationalData(poEntity);

		this.isvcEntityLink.updateCachedEntityLinks(
			this.model,
			this.observableModel.value ? [this.observableModel.value] : [],
			poEntity ? [poEntity] : [],
			loRelationData
		);

		this.observableModel.value = poEntity;
		this.fieldValue = poEntity?._id;
	}


	private getRelationalData(poEntity?: Entity): Map<string, IEntityLinkRelationData> {
		return poEntity ? new Map([
			[poEntity._id, {
				[this.model._id]: this.observableParams.value.linkTypes.current,
				[poEntity._id]: this.observableParams.value.linkTypes.target
			}]
		]) : undefined;
	}
	//#endregion METHODS

}
