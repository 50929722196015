import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { IEntity } from '@calaosoft/osapp-common/entities/models/ientity';
import { StoreDocumentHelper } from '@calaosoft/osapp-common/store/helpers/store-document-helper';
import { IDataSource } from '@calaosoft/osapp-common/store/models/IDataSource';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { AlertButton } from '@ionic/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EMPTY, Observable, Subject, defer, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { IFormEvent } from '../../..//model/forms/IFormEvent';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { LifeCycleObserverComponentBase } from '../../../helpers/LifeCycleObserverComponentBase';
import { EApplicationEventType } from '../../../model/application/EApplicationEventType';
import { IApplicationEvent } from '../../../model/application/IApplicationEvent';
import { EBarElementDock } from '../../../model/barElement/EBarElementDock';
import { EBarElementPosition } from '../../../model/barElement/EBarElementPosition';
import { IBarElement } from '../../../model/barElement/IBarElement';
import { EFormEngine } from '../../../model/forms/EFormEngine';
import { EFormEventType } from '../../../model/forms/EFormEventType';
import { IFormDefinition } from '../../../model/forms/IFormDefinition';
import { IFormDescriptor } from '../../../model/forms/IFormDescriptor';
import { IFormDescriptorDataSource } from '../../../model/forms/IFormDescriptorDataSource';
import { IFormParams } from '../../../model/forms/IFormParams';
import { ELifeCycleEvent } from '../../../model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '../../../model/lifeCycle/ILifeCycleEvent';
import { ERouteUrlPart } from '../../../model/route/ERouteUrlPart';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { HooksService } from '../../../modules/hooks/services/hooks.service';
import { HasPermissions } from '../../../modules/permissions/decorators/has-permissions.decorator';
import { EPermissionScopes } from '../../../modules/permissions/models/epermission-scopes';
import { IPermission } from '../../../modules/permissions/models/ipermission';
import { IHasPermission, PermissionsService } from '../../../modules/permissions/services/permissions.service';
import { PageManagerService } from '../../../modules/routing/services/pageManager.service';
import { EntityLinkService } from '../../../services/entityLink.service';
import { FormsService } from '../../../services/forms.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../../services/interfaces/ShowMessageParamsToast';
import { PatternResolverService } from '../../../services/pattern-resolver.service';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { WorkspaceService } from '../../../services/workspace.service';
import { DynamicPageComponent } from '../../dynamicPage/dynamicPage.component';
import { ToolbarComponent } from '../../toolbar/toolbar.component';

/** Crée et affiche un formulaire à partir des définitions et entrées données en paramètre. */
@Component({
	selector: "deprecated-calao-form",
	templateUrl: 'form.component.html',
	styleUrls: ['./form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent<T extends Entity = Entity> extends LifeCycleObserverComponentBase
	implements OnInit, OnDestroy, IHasPermission, IFormParams<T> {

	//#region FIELDS

	/** Couleur par défaut du bouton de validation. */
	private static readonly C_BACKGROUND_VALID_TOOLBAR_BUTTON_COLOR = "validation";
	/** Couleur par défaut du bouton de validation, lorsque les inputs sont invalides. */
	private static readonly C_BACKGROUND_INVALID_TOOLBAR_BUTTON_COLOR = "rgba(27, 193, 77, 0.3)";
	private static readonly C_DEFAULT_EDIT_ICON = "create";
	private static readonly C_DEFAULT_SUBMIT_ICON = "checkmark";
	/** Priorité par défaut des boutons de la toolbar du composant. */
	private static readonly C_DEFAULT_TOOLBAR_BUTTON_PRIORITY = 100;

	/** Valeur de priorité par défaut pour un bouton de la toolbar. */
	private mnDefaultButtonToolbarPriority: number = ToolbarComponent.C_DEFAULT_TOOLBAR_BUTTON_PRIORITY;
	/** Sujet interne pour changer l'entité courante. */
	private moSetCurrentEntitySubject = new Subject<void>();
	/** Indique si la popup de modifications non enregistrées est déjà affichée ou non. */
	private mbIsShowingNotSavedPopup = false;

	//#endregion

	//#region PROPERTIES

	private moModel: T & { formDescId?: string };
	public get model(): T & { formDescId?: string } { return this.moModel; }
	/** @implements */
	@Input() public set model(poValue: T & { formDescId?: string }) {
		this.moModel = poValue;
		// On encapsule le `detectChanges()` dans un observable afin d'éviter d'exécuter cette méthode si la vue a été détruite, sinon erreur 'viewDestroyedError'.
		defer(() => of(this.detectChanges())).pipe(takeUntil(this.destroyed$)).subscribe();
	}

	@HasPermissions({ permission: "edit" })
	private get formEditAllowed(): boolean {
		return this.formDefinition && (this.permissionCheckDisabled || this.formDefinition.allowEdit !== false);
	}

	/** @implements */
	@Input() public engine?: EFormEngine;
	/** @implements */
	@Input() public readOnly?: boolean;
	/** @implements */
	@Input() public customSubmit?: (poModel: T, poForm: FormComponent<T>, psTargetDatabase?: string, psActionAfterSave?: string) => Observable<T>;
	/** @implements */
	@Input() public modelId?: string;
	/** @implements */
	@Input() public entryIdPattern?: string;
	/** @implements */
	@Input() public modelPath?: string;
	/** @implements */
	@Input() public formDescriptorId?: string;
	/** @implements */
	@Input() public formDefinitionId?: string;
	/** @implements */
	@Input() public formDescriptor?: IFormDescriptor<T>;
	/** @implements */
	@Input() public assignedInstanceId?: string;
	/** @implements */
	@Input() public submitType?: string;
	/** @implements */
	@Input() public dataSource?: IDataSource;
	/** @implements */
	@Input() public linkedEntities?: Entity[];
	/** @implements */
	@Input() public workspaceId?: string;
	/** @implements */
	@Input() public customEdit?: () => void;
	/** @implements */
	@Input() public parentEntity?: IEntity;
	/** @implements */
	@Input() public toolbarHidden?: boolean;
	/** @implements */
	@Input() public permissionCheckDisabled?: boolean;
	/** @implements */
	@Input() public disableEntityTracking?: boolean;
	private mbVisuAfterCreate = true;
	/** @implements */
	public get visuAfterCreate(): boolean { return this.mbVisuAfterCreate; }
	@Input() public set visuAfterCreate(pbVisuAfterCreate: boolean) {
		if (pbVisuAfterCreate !== this.mbVisuAfterCreate)
			this.mbVisuAfterCreate = pbVisuAfterCreate;
	}

	/** Définition du formulaire. */
	public formDefinition: IFormDefinition;
	/* référence vers le formulaire. */
	public form: UntypedFormGroup;
	public permissionScope: EPermissionScopes;
	public buttonIconName: string;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des formulaires. */
		protected readonly isvcForms: FormsService,
		/** Service de gestion des pages. */
		protected readonly isvcPageManager: PageManagerService,
		/** Service de gestion des popups et toasts. */
		protected readonly isvcUiMessage: UiMessageService,
		/** Service de gestion des liens et de l'entité courante */
		protected readonly isvcEntityLink: EntityLinkService,
		protected readonly isvcWorkspace: WorkspaceService,
		public readonly isvcPermissions: PermissionsService,
		protected readonly ioRoute: ActivatedRoute,
		protected readonly ioRouter: Router,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly isvcHooks: HooksService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poFormBuilder: UntypedFormBuilder,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poParentPage, poChangeDetectorRef);

		this.form = poFormBuilder.group({});
	}

	/** Endroit où initialiser le composant après sa création. Initialisation du contenu du formulaire après avoir récupéré les attributs. */
	public ngOnInit(): void {
		if (StringHelper.isBlank(this.engine))
			this.engine = EFormEngine.formly;

		if (this.assignedInstanceId && !StringHelper.isBlank(this.assignedInstanceId))
			this.setInstanceId(this.assignedInstanceId);
		else
			this.mnDefaultButtonToolbarPriority = FormComponent.C_DEFAULT_TOOLBAR_BUTTON_PRIORITY;

		if (!this.customSubmit)
			this.customSubmit = (poModel: T, poForm: FormComponent<T>, psTargetDatabase: string, psActionAfterSave: string) =>
				poForm.submit(poModel, psTargetDatabase, psActionAfterSave);

		if (!this.model)
			this.model = {} as any;

		if (!this.dataSource)
			this.dataSource = {};

		// Si disableEntityTracking n'est pas défini par le composant parent, et qu'il y a un descripteur de formulaire, on récupère la valeur du descripteur.
		if (this.disableEntityTracking === undefined && this.formDefinition)
			this.disableEntityTracking = this.formDefinition.disableEntityTracking;

		this.init()
			.pipe(catchError(poError => { console.error("FORM.C:: Échec initialisation composant :", poError); return EMPTY; }))
			.subscribe();
	}

	protected onLifeCycleEvent(poValue: IApplicationEvent): void {
		if (poValue.type === EApplicationEventType.LifeCycleEvent) {
			switch ((poValue as ILifeCycleEvent).data.value) {

				case ELifeCycleEvent.viewBackTo:
					if (this.isvcEntityLink.isValidEntityModel(this.model) && !this.disableEntityTracking)
						this.raiseSetCurrentEntityEvent();

					this.fillTitle();
					break;

				case ELifeCycleEvent.viewWillLeave:
					if (!this.disableEntityTracking)
						this.isvcEntityLink.clearCurrentEntity(this.model._id).subscribe();
					break;
			}
		}
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.getParentToolbar()?.clear(this.getInstanceId());
		this.moSetCurrentEntitySubject.complete();
	}

	/** Ajout des attributs de lecture sur les options de chaque champs de façon récursive dans le cas où des groupe de champs sont présents.
	 * @param paFields Tableau de champs dans lesqeuls ajouter la valeur du mode readOnly.
	 */
	private addReadModeOnFields(paFields: Array<FormlyFieldConfig>): void {
		for (let lnIndex = paFields.length - 1; lnIndex >= 0; --lnIndex) {
			const loItem: FormlyFieldConfig = paFields[lnIndex];

			if (loItem.fieldGroup)
				this.addReadModeOnFields(loItem.fieldGroup);

			else {
				if (loItem.templateOptions) {
					if (loItem.templateOptions.data)
						loItem.templateOptions.data.readOnly = this.readOnly;
					else
						loItem.templateOptions.data = { readOnly: this.readOnly };
				}
				else
					loItem.templateOptions = { data: { readOnly: this.readOnly } };
			}
		}
	}

	/** Affiche une popup d'erreur. */
	private displayErrorPopup(poError: any): void {
		console.error("FORM.C:: ", poError);

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({ header: "Erreur", message: "Un problème est survenu lors de l'initialisation du formulaire.\nVeuillez réessayer ultérieurement." })
		);
	}

	/** Modifie le nom de la page grâce aux paramètres du formulaire de définition.
	 * @returns le nom de la page.
	 */
	private fillTitle(): void {
		let lsTitle: string | undefined;

		if (this.readOnly)
			lsTitle = this.formDefinition.title;
		else {
			lsTitle = this.model._rev ? this.formDefinition.editTitle : this.formDefinition.createTitle;

			if (StringHelper.isBlank(lsTitle))
				lsTitle = this.formDefinition.title;
		}

		if (lsTitle && !StringHelper.isBlank(lsTitle))
			this.moParentPage!.title = lsTitle;
	}

	/** Quand on obtient le descripteur de formulaire, on le gère (récupération du formDefId, formDefinition, définition, mode RO ou non, ...).
	 * @param poDescriptor Descripteur du formulaire.
	 */
	private onGetFormDescriptorSuccess(poDescriptor: IFormDescriptor<T>): void {
		this.formDescriptor = poDescriptor;
		if (this.formDescriptor.permissionScope)
			this.permissionScope = this.formDescriptor.permissionScope;

		if (!this.formDefinitionId) // Récupération du formDefinitionId par défaut s'il n'a pas été renseigné.
			this.formDefinitionId = this.formDescriptor.defaultDefinition ? this.formDescriptor.defaultDefinition : "default";

		// Récupération des infos de la définition.
		let loFormDefinition: IFormDefinition | undefined = !this.formDescriptor.formDefinitions ?
			undefined : this.formDescriptor.formDefinitions.find((poItem: IFormDefinition) => poItem.id === this.formDefinitionId);

		if (!loFormDefinition)
			loFormDefinition = this.formDescriptor.formDefinitions[0];

		if (this.readOnly === undefined && loFormDefinition.readOnly !== undefined) // Mode RO ou pas.
			this.readOnly = loFormDefinition.readOnly; // Changement du texte du bouton.

		if (loFormDefinition.definition)
			this.addReadModeOnFields(loFormDefinition.definition);

		const lsEntryIdPattern: string | undefined = StringHelper.isBlank(this.entryIdPattern) ? this.formDescriptor.entryIdPattern : this.entryIdPattern;

		if (StringHelper.isBlank(this.model._id) && StringHelper.isValid(lsEntryIdPattern)) { // Si on a un modèle vide (nouveau), on customise l'id de l'entrée.
			this.model._id = this.isvcPatternResolver.generateCustomId(
				lsEntryIdPattern,
				this.model,
				this.parentEntity
			);
		}

		if (!StringHelper.isBlank(loFormDefinition.onSubmitAction))
			loFormDefinition.onSubmitAction = loFormDefinition.onSubmitAction.toLowerCase();

		// On vérifie si on a une dataSource dans la définition, si elle n'est pas definie on prend celle donnée par le store.getDatabasesIdsByRole
		// sinon on utilise celle de la dataSource.
		if (StringHelper.isValid(loFormDefinition.dataSource)) {
			const loDataSource: IFormDescriptorDataSource | undefined = this.formDescriptor.dataSources?.find((poItem: IDataSource) => poItem.id === loFormDefinition!.dataSource);
			if (this.dataSource) {
				if (loDataSource) {
					if (loDataSource.db)
						this.dataSource.databaseId = loDataSource.db;
					// Si un identifiant de workspace est renseigné alors on récupère la base de données où enregistrer l'entrée.
					else if (loDataSource.role) {
						this.dataSource.role = loDataSource.role;

						if (!StringHelper.isBlank(this.workspaceId))
							this.dataSource.databaseId = this.isvcWorkspace.getDatabaseIdFromWorkspaceIdAndRole(this.workspaceId, loDataSource.role);
					}
				}
			}
		}

		if (this.linkedEntities) // Déclare les liens entre le formulaire et les entités liées passées en paramètres (sans les créer, persistés à l'enregistrement).
			this.linkedEntities.forEach((poEntity: Entity) => this.isvcEntityLink.cacheLinkToAdd(this.model, poEntity));

		this.formDefinition = loFormDefinition; // Récupération de la définition.
		this.visuAfterCreate = this.formDefinition.visuAfterCreate ?? false;
		this.fillTitle();

		this.detectChanges(); // Actualisation du template.
	}

	/** Initialisation de la définition du formulaire. */
	private initDescriptor(): Observable<boolean> {

		if (this.formDescriptor) {
			this.onGetFormDescriptorSuccess(this.formDescriptor);
			return of(true);
		}

		else if (this.formDescriptorId) {
			return this.isvcForms.getFormDescriptor(this.formDescriptorId).pipe( // Récupération du document du form definition.
				map(
					(poFormDescriptor: IFormDescriptor<T>) => {
						if (poFormDescriptor) {
							this.onGetFormDescriptorSuccess(poFormDescriptor);
							return true;
						}
						else {
							console.error(`FORM.C:: Unknown form descriptor '${this.formDescriptorId}'.`);
							return false;
						}
					}
				)
			);
		}

		else
			return throwError(() => "No form descriptor was provided.");
	}

	/** Initialise la toolbar lorsqu'on arrive sur le formulaire (après un clic ou une soumission). */
	private initToolbar(): void {
		if (!this.toolbarHidden) { // Si on ne doit pas cacher la toolbar, on l'initialise.
			const laBarElements: IBarElement[] = this.createBarElements();

			if (this.readOnly && !StringHelper.isBlank(this.formDefinition.editFormDefinitionId)) // Si mode RO et editFormDefIf valide.
				this.innerInitToolbar_readOnlyMode(laBarElements);

			else if (!this.readOnly && this.submitType === "toolbar") // Si pas mode RO et type de soumission 'toolbar'.
				this.innerInitToolbar_editMode(laBarElements);
		}
	}

	private createBarElements(): IBarElement[] {
		const laCustomElements: IBarElement[] = [];

		if (ArrayHelper.hasElements(this.formDefinition.customBarElements)) {
			// On filtre les liens en fonction des permissions d'accès.
			this.formDefinition.customBarElements = this.formDefinition.customBarElements?.filter((poElement: IBarElement) =>
				!ArrayHelper.hasElements(poElement.permissions) ||
				poElement.permissions?.every((poPermission: IPermission) => this.isvcPermissions.evaluatePermission(poPermission.permission as EPermissionScopes, poPermission.type))
			);

			this.formDefinition.customBarElements?.forEach((poElement: IBarElement) => {
				if (poElement.link)
					poElement.onTap = () => {
						if (poElement.linkWithModelId)
							this.ioRouter.navigate([poElement.link, this.model._id]);
						else
							this.ioRouter.navigate([poElement.link]);
					};
				laCustomElements.push(poElement);
			});
		}

		return [
			{
				id: "circle",
				component: "fabButton",
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "checkmark",
				priority: this.mnDefaultButtonToolbarPriority
			},
			...laCustomElements
		] as IBarElement[];
	}

	/** Initialise la toolbar quand le formulaire est en mode readOnly.
	 * @param paBarElements Tableau des éléments de la toolbar à afficher.
	 */
	private innerInitToolbar_readOnlyMode(paBarElements: IBarElement[]): void {
		const loFirstElement: IBarElement = ArrayHelper.getFirstElement(paBarElements);
		loFirstElement.icon = this.buttonIconName;

		if (this.formEditAllowed) {
			loFirstElement.hidden = false;
			loFirstElement.onTap = () => this.customEdit ? this.customEdit() : this.onEdit();
		}
		else
			loFirstElement.hidden = true;

		this.getParentToolbar()?.init(paBarElements, this.getInstanceId());
	}

	/** Initialise la toolbar quand le formulaire est en mode edit.
	 * @param paBarElements Tableau des éléments de la toolbar à afficher.
	 */
	private innerInitToolbar_editMode(paBarElements: IBarElement[]): void {
		const loFirstElement: IBarElement = ArrayHelper.getFirstElement(paBarElements);
		loFirstElement.icon = this.buttonIconName;

		if (this.formEditAllowed) {
			loFirstElement.hidden = false;
			loFirstElement.color = FormComponent.C_BACKGROUND_VALID_TOOLBAR_BUTTON_COLOR;

			if (!loFirstElement.options)
				loFirstElement.options = {};

			loFirstElement.options.isDisable = !this.form.valid;

			loFirstElement.onTap = () => {
				if (this.form.valid) { // Vérifie si le formulaire est valide.
					this.detectChanges();
					this.customSubmit?.(this.model, this, this.dataSource?.databaseId, this.formDefinition.onSubmitAction)
						.pipe(takeUntil(this.destroyed$))
						.subscribe();
				}
				else
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: this.getCanNotSaveMessage() }));
			};
		}
		else
			loFirstElement.hidden = true;

		this.getParentToolbar()?.init(paBarElements, this.getInstanceId());
	}

	/** Préparation de l'initialisation de la définition. */
	private initWithEntry(): Observable<boolean> {
		// Si on n'a pas de descriptorID, on prend celui par défaut de l'entrée.
		if (StringHelper.isBlank(this.formDescriptorId) && !StringHelper.isBlank(this.model.formDescId))
			this.formDescriptorId = this.model.formDescId;

		return this.initDescriptor();
	}

	/** Récupération du document à partir de l'id puis appel de l'initialisation. */
	private initWithEntryId(): Observable<boolean> {
		if (this.modelId) {
			return this.isvcForms.getEntryFromId(this.modelId) // Récupération de l'entrée.
				.pipe(
					tap((poEntry: T) => this.model = poEntry),
					mergeMap((_: T) => this.initWithEntry()), // On peut maintenant initialiser avec l'entrée.
					takeUntil(this.destroyed$)
				);
		}
		else
			return throwError(() => new Error(`Id of the model to choose for the form is missing.`));
	}

	/** Applique les valeurs par défaut et rend les valeurs du descripteur valides si nécessaire. */
	private sanitizeFormDefinition(): void {

		if (StringHelper.isBlank(this.formDefinition.editFormDefinitionId))
			this.formDefinition.editFormDefinitionId = "";

		if (this.readOnly)
			this.buttonIconName = !StringHelper.isBlank(this.formDefinition.editButtonIcon) ?
				this.formDefinition.editButtonIcon : FormComponent.C_DEFAULT_EDIT_ICON;
		else
			this.buttonIconName = !StringHelper.isBlank(this.formDefinition.submitButtonIcon) ?
				this.formDefinition.submitButtonIcon : FormComponent.C_DEFAULT_SUBMIT_ICON;

		if (StringHelper.isBlank(this.formDefinition.submitButtonText))
			this.formDefinition.submitButtonText = "Enregistrer.";

		this.submitType = !StringHelper.isBlank(this.formDefinition.submitType) ?
			this.formDefinition.submitType.toLowerCase() : "";

		// Enlève le bouton de validation si on utilise la toolbar.
		this.detectChanges();
	}

	/** On passe du mode visu au mode edit. */
	private onEdit(): void {
		if (this.customEdit)
			this.customEdit();
		else
			this.ioRouter.navigate([".", ERouteUrlPart.edit], { relativeTo: this.ioRoute });
	}

	/** Initialisation à faire lorsqu'on détecte un événement du cycle de vie ionic.
	 * On réalise la même chose dans le didEnter et dans le willEnter si ce n'est qu'on modifie un booléen spécifique dans chaque cas.
	 */
	private init(): Observable<boolean> {
		let loInit$: Observable<boolean>;

		if (!StringHelper.isBlank(this.modelId))
			loInit$ = this.initWithEntryId();
		else if (!StringHelper.isBlank(this.modelPath))
			loInit$ = this.initWithEntryPath(this.modelPath);
		else
			loInit$ = this.initWithEntry();

		return loInit$ // Force le chargement du cache de permissions.
			.pipe(
				mergeMap((pbResult: boolean) => {
					if (!pbResult)
						return throwError(() => "Init failed");

					else {
						this.innerInit();
						return of(pbResult);
					}
				}),
				catchError(poError => { this.displayErrorPopup(poError); return throwError(() => poError); }),
				takeUntil(this.destroyed$)
			);
	}

	private innerInit(): void {
		if (this.isvcEntityLink.isValidEntityModel(this.model) && !this.disableEntityTracking)
			this.raiseSetCurrentEntityEvent();

		this.sanitizeFormDefinition();
		this.initToolbar();

		if (!this.readOnly) // Si le formulaire est en mode edit, met à jour la toolbar le formulaire devient valide ou invalide.
			this.form.statusChanges.pipe(distinctUntilChanged()).subscribe(() => this.initToolbar());

		this.initCustomSubmit();
		this.detectChanges();

		this.isvcForms.waitFormEvent(this.model._id, EFormEventType.afterSubmit)
			.pipe(
				tap((poEvent: IFormEvent) => this.form.patchValue(poEvent.data.model)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private raiseSetCurrentEntityEvent(): void {
		this.moSetCurrentEntitySubject.next(undefined);
	}

	private initWithEntryPath(poEntryPath: string): Observable<boolean> {
		return this.isvcForms.getEntryFromId(Store.getDocumentIdFromPath(poEntryPath), Store.getDatabaseIdFromDocumentPath(poEntryPath)) // Récupération de l'entrée.
			.pipe(
				tap((poEntry: T) => this.model = poEntry),
				mergeMap((_: T) => this.initWithEntry()), // On peut maintenant initialiser avec l'entrée.
				takeUntil(this.destroyed$)
			);
	}

	/** On initialise le customSubmit en lui ajoutant du comportement. */
	private initCustomSubmit(): void {
		if (!this.readOnly) {
			const lfCustomSubmit: ((poModel: T, poForm: FormComponent<T>, psTargetDatabase: string, psActionAfterSave: string) => Observable<any>) | undefined =
				this.customSubmit;

			if (!lfCustomSubmit)
				return;

			this.customSubmit = (poModel: T, poForm: FormComponent<T>, psTargetDatabase: string, psActionAfterSave: string) => {

				if (!StringHelper.isBlank(poForm.formDefinition.lastChangeAppFieldName)) {
					poModel[poForm.formDefinition.lastChangeAppFieldName] = ConfigData.appInfo.appId;
					poModel["lastChange"] = this.isvcHooks.getLastChange(ConfigData.appInfo.appId);
				}

				this.isvcForms.raiseFormEvent(this.createFormEvent(EFormEventType.beforeSubmit));

				Object.assign(poModel, poForm.form.value);

				this.clearValues(poModel);

				return lfCustomSubmit(poModel, poForm, psTargetDatabase, psActionAfterSave)
					.pipe(tap(_ => this.innerCustomSubmit(poModel, poForm, psActionAfterSave, StoreDocumentHelper.isNew(poModel))));
			};
		}
	}

	/** Nettoie les chaînes de caractères et nombres non valides en les affectant à `undefined` pour ne pas sérialiser les valeurs en base de données.
	 * @param poModel
	 */
	private clearValues(poModel: T): void {
		Object.keys(poModel).forEach((psKey: string) => {
			if (!this.isValidValue(poModel[psKey]))
				poModel[psKey] = undefined;
		});
	}

	/** Retourne `true` si la valeur est considérée comme valide.
	 * @param poValue Valeur à vérifier si elle est valide ou non.
	 */
	private isValidValue(poValue: any): boolean {
		if (typeof poValue === "string")
			return !StringHelper.isBlank(poValue);
		else if (typeof poValue === "number")
			return NumberHelper.isValid(poValue);
		else
			return poValue !== undefined && poValue !== null;
	}

	private innerCustomSubmit(poModel: T, poForm: FormComponent<T>, psActionAfterSave: string, pbIsNew: boolean): void {
		this.isvcForms.raiseFormEvent(this.createFormEvent(EFormEventType.afterSubmit));
		poForm.form.markAsPristine();
		if (StringHelper.isBlank(psActionAfterSave) ? this.formDefinition.onSubmitAction : psActionAfterSave.toLowerCase() === FormsService.C_BACK_ACTION_ID) {
			if (!this.getParentPage()?.isModal && pbIsNew && this.visuAfterCreate)
				this.ioRouter.navigateByUrl(this.ioRoute.snapshot.url.join("/").replace("new", poModel._id), { replaceUrl: true });
			else
				this.isvcPageManager.goBack(poModel);
		}
		else
			this.raiseSetCurrentEntityEvent(); // Fixe l'entité courante.

		this.model = poModel; // `this.model` appartient au mode visu, on le met à jour avec les nouvelles valeurs.
	}

	private createFormEvent(peFormEventType: EFormEventType): IFormEvent {
		return {
			type: EApplicationEventType.formEvent,
			createDate: new Date(),
			data: {
				eventType: peFormEventType,
				model: this.model
			}
		};
	}

	/** Enregistre le document sur la base de données.
	 * @param poModel Document à enregistrer.
	 * @param psTargetDatabase Identifiant de la base de données où sera enregistré le document.
	 * @param psActionAfterSave Action qui sera réalisée après l'enregistrement.
	 */
	private save(poModel: T, psTargetDatabase?: string, psActionAfterSave?: string): Observable<T> {
		return this.isvcForms.saveModel(poModel, psTargetDatabase)
			.pipe(
				catchError(poError => {
					console.error("FORM.C:: Model save failed: ", poError);
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Une erreur est survenue lors de l'enregistrement." }));
					return throwError(() => poError);
				}),
				map((poResponse: IStoreDataResponse) => {
					console.debug(`FORM.C:: Form model saved: id=${poResponse.id}, rev=${poResponse.rev}.`);
					this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Enregistré !" }));
					return poModel;
				})
			);
	}

	/** Fonction de validation par défaut : soumet le document pour l'enregistrer en base de données.
	 * @param poModel Document à enregistrer.
	 * @param psTargetDatabase Identifiant de la base de données où sera enregistré le document.
	 * @param psActionAfterSave Action qui sera réalisée après l'enregistrement.
	 */
	public submit(poModel: T, psTargetDatabase?: string, psActionAfterSave?: string): Observable<T> {
		if (this.form.valid) { // Vérifie si le formulaire est valide.
			this.detectChanges();
			psTargetDatabase = StringHelper.isBlank(psTargetDatabase) ? this.dataSource?.databaseId : psTargetDatabase;
			return this.save(poModel, psTargetDatabase, psActionAfterSave);
		}
		else {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: this.getCanNotSaveMessage() }));
			return EMPTY;
		}
	}

	protected override onNavigating(psNextUrl?: string): void {
		// Si la popup des modifications non enregistrées n'est pas affichée, on permet ou non la navigation sinon elle est bloquée.
		if (!this.mbIsShowingNotSavedPopup) {
			if (!this.readOnly && !this.form.pristine && this.form.dirty && this.form.touched && (!psNextUrl || !this.formDefinition.allowedRedirectionUrls?.includes(psNextUrl))) {
				this.mbIsShowingNotSavedPopup = true;

				this.isvcUiMessage.showMessage(
					new ShowMessageParamsPopup({
						message: "Des modifications ont été réalisées. Si vous continuez, les données modifiées seront perdues. Voulez-vous vraiment continuer ?",
						header: "Modifications non enregistrées",
						buttons: [
							{ text: "Annuler", handler: () => this.allowNavigationFromPopup(false) },
							{ text: "Continuer", handler: () => this.allowNavigationFromPopup(true) }
						] as AlertButton[],
						backdropDismiss: false
					})
				);
			}
			else
				this.moParentPage!.allowNavigation(true);
		}
	}

	/** Autorise ou non la navigation et change le booléen indiquant que la popup des modifications non enregistrées n'est plus affichée.
	 * @param pbAllowNavigation Indique si la navigation est permise ou non.
	 */
	private allowNavigationFromPopup(pbAllowNavigation: boolean): void {
		this.moParentPage!.allowNavigation(pbAllowNavigation);
		this.mbIsShowingNotSavedPopup = false;
	}

	/** Retourne le message à afficher informant l'utilisateur quels champs de formulaires ne sont pas valides (et donc pourquoi il ne peux pas enregistrer). */
	private getCanNotSaveMessage(): string {
		// On récupère les clés du formulaire (qui sont les mêmes que pour le modèle) dont les valeurs ne sont pas valides,
		// on récupère les labels associés en filtrant ceux qui ne sont pas renseignés.
		const laNotValidFieldLabels: (string | undefined)[] = Object.keys(this.form.controls)
			.filter((psFormControlKey: string) => !this.form.controls[psFormControlKey].valid)
			.map((psKey: string) => {
				const lsResult: string | undefined = this.formDefinition.definition ? this.innerGetCanNotSaveMessage(psKey, this.formDefinition.definition) : undefined;

				if (StringHelper.isBlank(lsResult)) // La clé correspondant au champ de formulaire qui n'est pas valide n'existe pas dans la définition du formulaire.
					console.error(`FORM.C:: Le champ de formulaire '${psKey}' est manquant dans la définition de formulaire.`, this.formDefinition);

				return lsResult;
			})
			.filter((psErrorLabel: string) => !StringHelper.isBlank(psErrorLabel));

		return `Veuillez vérifier les informations saisies pour les champs :\n<ul>${laNotValidFieldLabels.join("</li>")}</ul>`;
	}

	private innerGetCanNotSaveMessage(psModelKey: string, paFieldConfigs: FormlyFieldConfig[]): string {
		for (let lnIndex = 0; lnIndex < paFieldConfigs.length; ++lnIndex) {
			const loFieldConfig: FormlyFieldConfig = paFieldConfigs[lnIndex];

			if (loFieldConfig.key === psModelKey) // Si on est dans le champ de formulaire qui nous intéresse.
				return `<li>${loFieldConfig.templateOptions?.label}`;
			else if (loFieldConfig.fieldGroup) { // Sinon si on est dans un groupe de champ de formulaire, il faut itérer dessus : appel récursif.
				const lsResult: string = this.innerGetCanNotSaveMessage(psModelKey, loFieldConfig.fieldGroup);
				if (!StringHelper.isBlank(lsResult)) // Si on obtient un résultat on s'arrête sinon on continue.
					return lsResult;
			}
		}

		return "";
	}

	//#endregion

}
