/* eslint-disable max-lines */
import { coerceArray } from "@angular/cdk/coercion";
import { KeyValue } from "@angular/common";
import { Injectable, OnDestroy, Type } from "@angular/core";
import { Router } from "@angular/router";
import { EPlatform } from "@calaosoft/osapp-common/applications/models/EPlatform";
import { ETaskPrefix } from "@calaosoft/osapp-common/background-tasks/models/ETaskPrefix";
import { ConfigData } from "@calaosoft/osapp-common/config/models/ConfigData";
import { DateHelper } from "@calaosoft/osapp-common/dates/helpers/dateHelper";
import { EEnvironmentId } from "@calaosoft/osapp-common/environment/models/EEnvironmentId";
import { GuidHelper } from "@calaosoft/osapp-common/guid/helpers/guidHelper";
import { IIndexorViewRow } from "@calaosoft/osapp-common/indexor/models/iindexor-view-row";
import { ELogActionId } from "@calaosoft/osapp-common/logging/models/ELogActionId";
import { ObservableProperty } from "@calaosoft/osapp-common/observable/models/observable-property";
import { PerformanceManager } from "@calaosoft/osapp-common/performance/PerformanceManager";
import { Queue } from "@calaosoft/osapp-common/queue/decorators/queue.decorator";
import { Queuer } from "@calaosoft/osapp-common/queue/models/queuer";
import { afterSubscribe } from "@calaosoft/osapp-common/rxjs/operators/after-subscribe";
import { bufferUntil } from "@calaosoft/osapp-common/rxjs/operators/buffer-until";
import { tapComplete } from "@calaosoft/osapp-common/rxjs/operators/tap-complete";
import { tapError } from "@calaosoft/osapp-common/rxjs/operators/tap-error";
import { ICredentials } from "@calaosoft/osapp-common/security/models/ICredentials";
import { StoreDocumentHelper } from "@calaosoft/osapp-common/store/helpers/store-document-helper";
import { StoreHelper } from "@calaosoft/osapp-common/store/helpers/store-helper";
import { IDataSource } from "@calaosoft/osapp-common/store/models/IDataSource";
import { IDataSourceViewParams } from "@calaosoft/osapp-common/store/models/IDataSourceViewParams";
import { IDesignDocument } from "@calaosoft/osapp-common/store/models/IDesignDocument";
import { IStoreReplicationResponse } from "@calaosoft/osapp-common/store/models/IStoreReplicationResponse";
import { IStoreReplicationToLocalResponse } from "@calaosoft/osapp-common/store/models/IStoreReplicationToLocalResponse";
import { IStoreReplicationToServerResponse } from "@calaosoft/osapp-common/store/models/IStoreReplicationToServerResponse";
import { EDatabaseRole } from "@calaosoft/osapp-common/store/models/edatabase-role";
import { ELocalToServerReplicationMode } from "@calaosoft/osapp-common/store/models/elocal-to-server-replication-mode.enum";
import { ESyncType } from "@calaosoft/osapp-common/store/models/esync-type";
import { ICacheData } from "@calaosoft/osapp-common/store/models/icache-data";
import { IDatabaseConfig } from "@calaosoft/osapp-common/store/models/idatabase-config";
import { IStoreChangesOptions } from "@calaosoft/osapp-common/store/models/istore-changes-options";
import { IStoreDocument } from "@calaosoft/osapp-common/store/models/istore-document";
import { ISyncType } from "@calaosoft/osapp-common/store/models/isync-type";
import { EStoreReplicationResponseStatus } from "@calaosoft/osapp-common/store/models/replications/estore-replication-response-status";
import { OsappError } from "@calaosoft/osapp-common/utils/errors/OsappError";
import { ArrayHelper } from "@calaosoft/osapp-common/utils/helpers/arrayHelper";
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { MapHelper } from "@calaosoft/osapp-common/utils/helpers/mapHelper";
import { NumberHelper } from "@calaosoft/osapp-common/utils/helpers/numberHelper";
import { ObjectHelper } from "@calaosoft/osapp-common/utils/helpers/objectHelper";
import { StringHelper } from "@calaosoft/osapp-common/utils/helpers/stringHelper";
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { ModelResolver } from "@calaosoft/osapp-common/utils/models/model-resolver";
import { FileInfo } from "@capacitor/filesystem";
import { ModalController } from "@ionic/angular";
import { AlertButton, ModalOptions, OverlayEventDetail } from "@ionic/core";
import PCancelable, { CancelError } from "p-cancelable";
import {
	BehaviorSubject,
	EMPTY,
	GroupedObservable,
	Observable,
	Subject,
	combineLatest,
	defer,
	firstValueFrom,
	from,
	fromEvent,
	merge,
	of,
	throwError,
	timer
} from "rxjs";
import {
	bufferWhen,
	catchError,
	concatMap,
	defaultIfEmpty,
	delay,
	distinctUntilChanged,
	expand,
	filter,
	finalize,
	groupBy,
	last,
	map,
	mapTo,
	mergeMap,
	mergeMapTo,
	reduce,
	retryWhen,
	scan,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap,
	toArray
} from "rxjs/operators";
import { PouchDB, PouchdbAdapterCordovaSqlite } from "../../lib/pouchdb";
import { IUnlockDeviceResult } from "../components/security/unlock-device-modal/IUnlockDeviceResult";
import { UnlockDeviceModalComponent } from "../components/security/unlock-device-modal/unlock-device-modal.component";
import { EXTENSIONS_AND_MIME_TYPES } from "../helpers/fileHelper";
import { EApplicationEventType } from "../model/application/EApplicationEventType";
import { ENetworkFlag } from "../model/application/ENetworkFlag";
import { IApplicationEvent } from "../model/application/IApplicationEvent";
import { UserData } from "../model/application/UserData";
import { Version } from "../model/application/Version";
import { IDatabaseMeta } from "../model/databaseDocument/IDatabaseMeta";
import { IFlag } from "../model/flag/IFlag";
import { ActivePageManager } from "../model/navigation/ActivePageManager";
import { ESecurityFlag } from "../model/security/ESecurityFlag";
import { Database } from "../model/store/Database";
import { EChangeType } from "../model/store/EChangeType";
import { EStoreEventStatus } from "../model/store/EStoreEventStatus";
import { EStoreEventType } from "../model/store/EStoreEventType";
import { EStoreFlag } from "../model/store/EStoreFlag";
import { IChangeEvent } from "../model/store/IChangeEvent";
import { IDataFromPouchQuery } from "../model/store/IDataFromPouchQuery";
import { IDatabaseInitEvent } from "../model/store/IDatabaseInitEvent";
import { IStoreDataResponse } from "../model/store/IStoreDataResponse";
import { IStoreEvent } from "../model/store/IStoreEvent";
import { IStoreReplicationOptions } from "../model/store/IStoreReplicationOptions";
import { DeviceNotAuthorizedError } from "../model/store/error/device-not-authorized-error";
import { FirstInitFailedError } from "../model/store/error/first-init-failed-error";
import { IConfigInitPouchDB } from "../model/store/pouchDB/IConfigInitPouchDB";
import { ILocalDatabaseConfig } from "../model/store/pouchDB/ILocalDatabaseConfig";
import { IRemoteDatabaseConfig } from "../model/store/pouchDB/IRemoteDatabaseConfig";
import { IUiResponse } from "../model/uiMessage/IUiResponse";
import { WorkspaceFilter } from "../model/workspaces/WorkspaceFilter";
import { BackupsService } from "../modules/backups/services/backups.service";
import { EventsService } from "../modules/events/events.service";
import { FilesystemService } from "../modules/filesystem/services/filesystem.service";
import { EFlag } from "../modules/flags/models/EFlag";
import { Loader } from "../modules/loading/Loader";
import { LogAction } from "../modules/logger/decorators/log-action.decorator";
import { ILogActionHandler } from "../modules/logger/models/ILogActionHandler";
import { LogActionHandler } from "../modules/logger/models/log-action-handler";
import { LoggerService } from "../modules/logger/services/logger.service";
import { NoOnlineReliableNetworkError } from "../modules/network/models/errors/NoOnlineReliableNetworkError";
import { OsappApiHelper } from "../modules/osapp-api/helpers/osapp-api.helper";
import { DevicesSecurityService } from "../modules/security/devices/services/devices-security.service";
import { DestroyableServiceBase } from "../modules/services/models/destroyable-service-base";
import { LocalDatabaseProviderService } from "../modules/sqlite/services/providers/local-database-provider.service";
import { ChangeTrackedDatabase } from "../modules/store/change-tracking/models/change-tracked-database";
import { ETrackingStatus } from "../modules/store/change-tracking/models/etracking-status.enum";
import { ICreateChangeTrackerItem } from "../modules/store/change-tracking/models/icreate-change-tracker-item";
import { ChangeTrackingService } from "../modules/store/change-tracking/services/change-tracking.service";
import { ExternalDataSourcesCache } from "../modules/store/external/external-data-sources-cache";
import { IExternalDataSource } from "../modules/store/external/models/iexternal-data-source";
import { MobileIndexorService } from "../modules/store/indexor/services/mobile-indexor.service";
import { IDataSourceRemoteChanges } from "../modules/store/model/IDataSourceRemoteChanges";
import { IDatabaseSyncMarker } from "../modules/store/model/IDatabaseSyncMarker";
import { IResetDatabasesResult } from "../modules/store/model/IResetDatabasesResult";
import { DumpApplicationEvent } from "../modules/store/model/dump-application-event";
import { EDumpStatus } from "../modules/store/model/edump-status";
import { ELocalDatabaseMode } from "../modules/store/model/elocal-database-mode";
import { ConflictError } from "../modules/store/model/errors/ConflictError";
import { IPouchDBFailedToFetchError } from "../modules/store/model/errors/ipouchdb-failed-to-fetch-error";
import { NoDatabaseInternetConnectionError } from "../modules/store/model/errors/no-database-internet-connection-error";
import { TokenError } from "../modules/store/model/errors/token-error";
import { UnknownSyncTypeError } from "../modules/store/model/errors/unknown-sync-type-error";
import { ILastLocalToServerReplicationModeDocument } from "../modules/store/model/ilast-local-to-server-replication-mode-document";
import { ILocalDatabaseFileMeta } from "../modules/store/model/ilocal-database-file-meta";
import { ISynchronizationEvent } from "../modules/store/model/isynchronization-event";
import { ReplicatorError } from "../modules/store/replicator/models/errors/replicator-error";
import { IOnProgressFunction } from "../modules/store/replicator/models/ion-progress-function";
import { IReplicatorParamsBase } from "../modules/store/replicator/models/ireplicator-params-base";
import { ReplicatorBase } from "../modules/store/replicator/models/replicator-base";
import { ChangeTrackingReplicatorService } from "../modules/store/replicator/services/change-tracking-replicator.service";
import { ClassicReplicatorService } from "../modules/store/replicator/services/classic-replicator.service";
import { TransfertService } from "../modules/transfert/services/transfert.service";
import { PatternsHelper } from "../modules/utils/helpers/patterns.helper";
import { ZipService } from "../modules/zip/services/zip.service";
import { ApplicationService } from "./application.service";
import { FlagService } from "./flag.service";
import { IStore } from "./interfaces/IStore";
import { ShowMessageParamsPopup } from "./interfaces/ShowMessageParamsPopup";
import { LoadingService } from "./loading.service";
import { MailService } from "./mail.service";
import { NetworkService } from "./network.service";
import { PlatformService } from "./platform.service";
import { UiMessageService } from "./uiMessage.service";

PouchDB.plugin(PouchdbAdapterCordovaSqlite);

/** Nombre de documents répliqués maximum à intégrer dans les logs de réplications. */
const C_MAX_REPLICATED_DOCS_NB = 9;

//#region Interfaces et classes spécifiques au Store

interface IGroupedDocuments<T> {
	databaseId: string;
	documents: T[];
}

interface IReplicatedDoc {
	_id: string;
	_rev: string;
}

interface IReplicateLogActionData {
	readonly databaseId: string;
	readonly nbDocReplicated: number;
	readonly source: string;
	readonly target: string;
	localSeqBeforeReplication?: number;
	serverSeqBeforeReplication?: string;
	docs?: IReplicatedDoc[];
	duration?: number;
}

interface IStoreReplicatorParams extends IReplicatorParamsBase {
	readonly isSyncFromRemote: boolean;
	readonly isSyncToRemote: boolean;
	readonly replicationLabel: string;
}

interface IExecReplicateResult {
	readonly result: IStoreReplicationResponse<IStoreDocument>;
}
interface IReplicateResult extends IStoreReplicationResponse {
	/** Durée de la réplication en ms. */
	durationMs: number;
}
interface IReplicateDatabaseParams {
	readonly database: Database;
	readonly replicationLabel: string;
	readonly sourceInstance: PouchDB.Database;
	readonly targetInstance: PouchDB.Database;
	readonly replicationEndSubject: Subject<void>;
	readonly timersMs: number[];
	readonly replicateOptions: IStoreReplicationOptions;
	readonly onProgress?: IOnProgressFunction;
}

class InnerReplicateError extends ReplicatorError {
	constructor(
		public readonly replicateResult: IReplicateResult,
		public readonly replicatorName: string,
		poError: ReplicatorError
	) {
		super(poError.error, poError.replicatedDocs);
		// Nécessaire pour que le 'instanceof InnerReplicatorError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, InnerReplicateError);
	}
}

//#endregion Interfaces et classes spécifiques au Store

interface IGetScanResult<T extends IStoreDocument> {
	docs: T[];
	changed: boolean;
}

interface IDataSourceData {
	dataSourceViewParams: IDataSourceViewParams;
	documentId: string;
}

interface IDataSourcesData {
	dataSourceViewParamsByDocId: Map<string, IDataSourceViewParams>;
	requestableDataSource: IDataSource;
}

interface IExternalDataSourceIndexationResult<T extends IStoreDocument> {
	observablesByExternalDataSource: Map<IExternalDataSource<any>, Observable<IDataSourceData>[]>;
	documentsById: Map<string, T>;
}

interface IDocIdAndViewName {
	docId: string;
	viewName: string;
}

/** Le store permet l'accès aux bases de données. */
@Injectable({ providedIn: "root" })
export class Store extends DestroyableServiceBase implements IStore, ILogActionHandler, OnDestroy {
	//#region FIELDS

	/** Identifiant du service dans les logs. */
	private static readonly C_LOG_ID = "STO.S::";
	private static readonly C_POUCH_CONFLICT_ERROR_NAME = "conflict";
	/** Nom du fichier json contenant les metadonnées du dump d'une base de données. */
	private static readonly C_META_DOC_NAME = "meta.json";
	/** Delai avant de retenter. */
	private static readonly C_RETRY_DELAY_MS = 30000;
	private static readonly C_REMOTE_CHANGES_INTERVAL_MS = 30000;
	private static readonly C_CANCEL_REPLICATION_ON_USER_ACTION = false;
	/** La taille par défaut des paquets de données lorsque les données ne sont pas chargées en un coup. */
	public static readonly C_DEFAULT_BATCH_SIZE = 999;

	/** Nom du document local de chaque base de config : "_local/databaseMeta". */
	private readonly C_DATABASE_META = "_local/databaseMeta";
	private readonly C_DELETED_FILTER_NAME = "filters/deletedfilter";
	/** Temps d'attente entre chaque changements depuis le getLive(), en ms. */
	private readonly C_GET_LIVE_BUFFER_TIME_MS = 100;
	/** Nombre limite de révisions à conserver en local. */
	private readonly C_DEFAULT_REVS_LIMIT = 100;
	/** Map des bases de données indexées par leur identifiant. */
	private readonly moDatabaseById = new Map<string, Database>();
	/** Subject pour l'envoi d'event */
	private readonly moEventSubject = new Subject<IStoreEvent>();
	/** Sujet d'émission des événements de changement local des données. */
	private readonly moLocalChangesSubject = new Subject<IChangeEvent<IStoreDocument>>(); // TODO TB OPTI : créer un flux par base pour ne plus avoir à tester l'id de bdd quand on reçoit un changement
	private readonly moRemoteChangesSubject = new Subject<IChangeEvent<IStoreDocument>>(); // TODO TB OPTI : créer un flux par base pour ne plus avoir à tester l'id de bdd quand on reçoit un changement
	/** Sujet d'émission des événements de changement du nombre d'actions utilisateur en cours. */
	private readonly moUserActionsCounterSubject = new BehaviorSubject<number>(0);
	private readonly moConflictsSubject = new Subject<string[]>();
	private readonly moViewDocByDocIdByDatabaseId = new Map<string, Map<string, IDesignDocument>>();

	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter, () => true);

	/** `true` si une popup d'expiration de token est déjà affichée, `false` sinon. */
	private mbIsExpiredTokenPopupShowed = false;

	//#endregion

	//#region PROPERTIES

	/** Caractères "n'importe quoi" pour les startkey endkey notamment : \ufff0". */
	public static readonly C_ANYTHING_CODE_ASCII = "\ufff0";
	/** Propriété d'identifiant d'un document enregistré en base de données : "_id". */
	public static readonly C_ID_PROPERTY = "_id" as keyof IStoreDocument;
	/** Propriété de révision d'un document enregistré en base de données : "_rev". */
	public static readonly C_REVISION_PROPERTY = "_rev" as keyof IStoreDocument;
	/** Propriété de conflits d'un document enregistré en base de données : "_conflicts". */
	public static readonly C_CONFLICTS_PROPERTY = "_conflicts";
	/** Nom de la vue des documents par défaut, "all_docs". */
	public static readonly C_DEFAULT_VIEW_DOCS = "_all_docs";
	/** Message indiquant qu'un réseau est impératif au premier lancement de l'app. */
	public static readonly C_NETWORK_NEEDED_FOR_FIRST_LAUNCH =
		"Une connexion réseau est impérative pour le premier lancement de l'application.";
	/** Intervalle entre 2 réplications serveur vers local pour une base de donnée. */
	public static readonly C_OFFLINE_FIRST_TO_LOCAL_INTERVAL = 30000;

	/** Adapter PouchDB (voir @method getAdapter) */
	private msAdapter: string;
	/** Adapter PouchDB (voir @method getAdapter) */
	private get adapter(): string {
		if (!this.msAdapter) this.msAdapter = this.getAdapter();

		return this.msAdapter;
	}

	/** Suffixe du nom du fichier d'une base de données sqlite.  */
	private get sqliteSuffixDatabaseName(): string {
		return this.isvcPlatform.isAndroid ? "SQLite.db" : "";
	}

	/** Lève un événement à chaque conflit récupéré depuis une requête get par l'utilisateur. */
	public get conflicts$(): Observable<string[]> {
		return this.moConflictsSubject.asObservable();
	}

	/** @implements */
	public readonly logSourceId: string = Store.C_LOG_ID;
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region METHODS

	constructor(
		private isvcNetwork: NetworkService,
		private isvcPlatform: PlatformService,
		private isvcFlag: FlagService,
		private isvcApplication: ApplicationService,
		private isvcUiMessage: UiMessageService,
		private isvcLoading: LoadingService,
		private isvcZip: ZipService,
		private isvcTransfert: TransfertService,
		private isvcMail: MailService,
		private ioModalCtrl: ModalController,
		private isvcDevicesSecurity: DevicesSecurityService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly ioRouter: Router,
		private readonly isvcChangeTrackingReplicator: ChangeTrackingReplicatorService,
		private readonly isvcClassicReplicator: ClassicReplicatorService,
		private readonly isvcChangeTracker: ChangeTrackingService,
		private readonly isvcFilesystem: FilesystemService,
		private readonly isvcEvents: EventsService,
		private readonly isvcMobileIndexor: MobileIndexorService,
		private readonly isvcLocalDatabaseProvider: LocalDatabaseProviderService,
		private readonly isvcBackups: BackupsService
	) {
		super();
		//Nécessaire pour utiliser le store dans les tests e2e
		// @ts-ignore
		if (window.Cypress) {
			// @ts-ignore
			window.store = this;
		}

		isvcApplication
			.observeFlag(ESecurityFlag.authenticated)
			.pipe(
				filter((poFlag: IFlag) => poFlag.value !== undefined),
				map((poFlag: IFlag) => poFlag.value),
				distinctUntilChanged(),
				mergeMap((pbAuthenticated: boolean) =>
					pbAuthenticated ? this.initDynamicDatabases() : of(this.closeDatabases())
				)
			)
			.subscribe();
	}

	public override ngOnDestroy(): void {
		this.moEventSubject.complete();
		this.moLocalChangesSubject.complete();
		this.moRemoteChangesSubject.complete();
		this.moUserActionsCounterSubject.complete();
		this.moConflictsSubject.complete();
		super.ngOnDestroy();
	}

	/** Ferme les bases de données autres que l'appStorage et config (qui sont nécessaires). */
	private closeDatabases(): void {
		this.moDatabaseById.forEach((poDatabase: Database, psId: string) => {
			if (!poDatabase.hasRole(EDatabaseRole.config) && !poDatabase.hasRole(EDatabaseRole.applicationStorage)) {
				poDatabase.close();
				this.moDatabaseById.delete(psId);
			}
		});

		ConfigData.databases = ConfigData.databases?.filter(
			(poDatabaseConfig: IDatabaseConfig) =>
				!(
					poDatabaseConfig.roles?.includes(EDatabaseRole.workspace) ?? poDatabaseConfig.role === EDatabaseRole.workspace
				)
		);

		this.raiseStoreEvent(this.createInitErrorEvent(EStoreEventType.Init, ConfigData.databases));
	}

	/** Demande l'id d'une base de donnée qui n'est pas renseigné.
	 * @param peRole Rôle de la base de données recherchée.
	 */
	private askAndWaitDatabaseId$(poDocument: IStoreDocument): Observable<string> {
		const loEvent: IStoreEvent = {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				storeEventType: EStoreEventType.NeedInfos,
				status: EStoreEventStatus.required,
				document: poDocument
			}
		};

		const loWaitDatabaseId$: Observable<string> = this.isvcApplication.appEvent$.pipe(
			filter(
				(poEvent: IApplicationEvent) =>
					poEvent.type === EApplicationEventType.StoreEvent &&
					(poEvent as IStoreEvent).data.storeEventType === EStoreEventType.NeedInfos
			),
			take(1),
			mergeMap((poEvent: IStoreEvent) =>
				poEvent.data.error ? throwError(() => poEvent.data.error) : of(poEvent.data.databaseId)
			)
		);

		return loWaitDatabaseId$.pipe(afterSubscribe(() => this.raiseStoreEvent(loEvent)));
	}

	/** Gère le passage de la base de données au statut 'isInitialized' (booléen), ajoute le nom de cette bdd dans l'observable
	 * et termine cet observable si le compteur a atteint 0.
	 * @param psDatabaseId id de la base de données sur laquelle on fait des opérations.
	 * @param pbSuccess l'initialisation de la base de données s'est bien passée ou non.
	 */
	private completeInitDatabase(psDatabaseId: string, pbSuccess: boolean): Observable<Database> {
		const loDatabase: Database = this.moDatabaseById.get(psDatabaseId);
		loDatabase.isInitialized = pbSuccess;

		if (pbSuccess) {
			console.debug(`${Store.C_LOG_ID}Database ${psDatabaseId} initialized.`);
			this.raiseStoreEvent(this.createInitDatabaseEvent(psDatabaseId, EStoreEventStatus.successed));
		} else {
			console.warn(`${Store.C_LOG_ID}Database ${psDatabaseId} not initialized.`);
			this.raiseStoreEvent(this.createInitDatabaseEvent(psDatabaseId, EStoreEventStatus.failed));
		}

		return of(loDatabase);
	}

	private createInitErrorEvent(peType: EStoreEventType, paDatabases: IDatabaseConfig[]): IStoreEvent {
		return this.createInitEvent(peType, EStoreEventStatus.failed, paDatabases);
	}

	private createInitEvent(
		peType: EStoreEventType,
		peStatus: EStoreEventStatus,
		paDatabases: IDatabaseConfig[]
	): IDatabaseInitEvent {
		return {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				storeEventType: peType,
				status: peStatus
			},
			databases: paDatabases
		};
	}

	private createInitSuccessEvent(peType: EStoreEventType, paDatabases: IDatabaseConfig[]): IStoreEvent {
		return this.createInitEvent(peType, EStoreEventStatus.successed, paDatabases);
	}

	private getDatabaseType(poDatabaseConfig: IDatabaseConfig): Type<Database> {
		if (poDatabaseConfig.localToServerReplicationMode === ELocalToServerReplicationMode.changeTracking)
			return ChangeTrackedDatabase;
		return Database;
	}

	/** Crée une base de données Pouch locale et une distante.
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin
	 * @param psPassword
	 */
	private async createLocalAndRemoteDatabaseAsync(
		poDatabaseConfig: IDatabaseConfig,
		psLogin?: string,
		psPassword?: string
	): Promise<Database> {
		const loDatabase: Database = await this.createDatabaseAsync(poDatabaseConfig, "localRemote", psLogin, psPassword);
		this.moDatabaseById.set(poDatabaseConfig.id, loDatabase);
		return loDatabase;
	}

	/** Crée une base de données Pouch locale.
	 * @param poDatabaseConfig Configurations de la base de données.
	 */
	private async createLocalDatabaseAsync(poDatabaseConfig: IDatabaseConfig): Promise<Database> {
		const loDatabase: Database = await this.createDatabaseAsync(poDatabaseConfig, "local");
		this.moDatabaseById.set(poDatabaseConfig.id, loDatabase);
		return loDatabase;
	}

	/** Crée une base de données Pouch distante.
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin
	 * @param psPassword
	 */
	private async createRemoteDatabaseAsync(
		poDatabaseConfig: IDatabaseConfig,
		psLogin?: string,
		psPassword?: string
	): Promise<Database> {
		const loDatabase: Database = await this.createDatabaseAsync(poDatabaseConfig, "remote", psLogin, psPassword);
		this.moDatabaseById.set(poDatabaseConfig.id, loDatabase);
		return loDatabase;
	}

	private async createDatabaseAsync(
		poDatabaseConfig: IDatabaseConfig,
		psType: "local" | "remote" | "localRemote",
		psLogin?: string,
		psPassword?: string
	): Promise<Database> {
		const loDatabase: Database = new (this.getDatabaseType(poDatabaseConfig))(
			poDatabaseConfig.id,
			this.extractSyncTypeFromConfig(poDatabaseConfig),
			poDatabaseConfig.roles,
			poDatabaseConfig.checkDatabaseMetaInstance,
			poDatabaseConfig.localToServerReplicationMode,
			poDatabaseConfig.optimization,
			poDatabaseConfig.syncOptions
		);

		const loConfig: IConfigInitPouchDB = await this.getDatabaseConfigAsync(
			loDatabase,
			poDatabaseConfig.revs_limit,
			psLogin,
			psPassword
		);

		if (psType === "local" || psType === "localRemote")
			loDatabase.createLocalInstance(loConfig.localPouchConfig.name, loConfig.localPouchConfig);

		if (psType === "remote" || psType === "localRemote")
			loDatabase.createRemoteInstance(loConfig.remotePouchConfig.name, loConfig.remotePouchConfig);

		this.initDatabaseLocalStatus(loDatabase);

		return loDatabase;
	}

	private createPreparingInitDatabaseEvent(): IStoreEvent {
		return {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				storeEventType: EStoreEventType.Init,
				status: EStoreEventStatus.preparing
			}
		} as IStoreEvent;
	}

	/** @implements */
	public delete<T extends IStoreDocument>(
		poDocument: T | string,
		psDatabaseId?: string,
		pbUpdateDeletion?: boolean,
		pbFailIfNotFound: boolean = true
	): Observable<IStoreDataResponse> {
		if (ObjectHelper.isNullOrEmpty(poDocument)) return throwError(() => "Impossible de supprimer un document vide.");

		const loCacheData: ICacheData = StoreHelper.getDocumentCacheData(poDocument as IStoreDocument);
		const lsDatabaseId: string = loCacheData ? loCacheData.databaseId : psDatabaseId;

		return pbUpdateDeletion
			? this.deleteByUpdateDeletion(poDocument, lsDatabaseId, pbFailIfNotFound)
			: this.deleteByDeletionDocument(poDocument, lsDatabaseId, pbFailIfNotFound);
	}

	/** Supprime un document de la base de données en mettant à jour son état à '_deleted: true'.
	 * @param poDocument Document qu'il faut mettre à jour à l'état supprimer.
	 * @param psDatabaseId Identifiant de la base de données où se trouve le document à mettre à jour.
	 * @param pbFailIfNotFound Indique si une erreur doit être levée lorsque la suppression du document échoue.
	 */
	private deleteByUpdateDeletion<T extends IStoreDocument>(
		poDocument: T | string,
		psDatabaseId: string,
		pbFailIfNotFound: boolean
	): Observable<IStoreDataResponse> {
		return defer(() => {
			// Si on a juste l'_id du document, il faut le rechercher dans la base avant de le supprimer
			if (typeof poDocument === "string") {
				const loParams: IDataSource<T> = {
					databaseId: psDatabaseId,
					viewParams: {
						key: poDocument,
						include_docs: true
					}
				};
				return this.getOne<T>(loParams, false);
			} else return of(poDocument);
		}).pipe(
			mergeMap((poDocumentToDelete?: T) => {
				if (poDocumentToDelete)
					// Ajout des informations de suppression au corps du document
					return this.updateAsDeleted(poDocumentToDelete, psDatabaseId);
				else if (typeof poDocument === "string")
					// Document non présent en base de données et est une chaîne de caractères : erreur.
					return throwError(() => `Le document ${poDocument as string} à supprimer n'existe pas en base.`);
				else {
					// Document non présent en base de données, pas de suppression.
					const lsErrorMessage = `update deletion document "${(poDocument as IStoreDocument)._id
						}" failed because not in database "${psDatabaseId}".`;
					if (pbFailIfNotFound) return throwError(() => lsErrorMessage);
					else {
						console.warn(`${Store.C_LOG_ID}${lsErrorMessage}`);
						return of({
							id: (poDocument as IStoreDocument)._id,
							rev: (poDocument as IStoreDocument)._rev,
							ok: true
						} as IStoreDataResponse);
					}
				}
			})
		);
	}

	/** Supprime un document en base de données.
	 * @param poDocument Document qu'il faut supprimer de la base de données.
	 * @param psDatabaseId Identifiant de la base de données où se trouve le document à supprimer.
	 * @param pbFailIfNotFound Indique si une erreur doit être levée lorsque la suppression du document échoue.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private deleteByDeletionDocument<T extends IStoreDocument>(
		poDocument: T | string,
		psDatabaseId: string,
		pbFailIfNotFound: boolean = true
	): Observable<IStoreDataResponse> {
		if (this.isInitializedDatabase(psDatabaseId))
			return this.deleteDocumentIntoDatabase(psDatabaseId, poDocument, pbFailIfNotFound);
		else {
			return this.askAndWaitDatabaseId$(typeof poDocument === "string" ? { _id: poDocument } : poDocument).pipe(
				mergeMap((psId: string) => {
					if (this.isInitializedDatabase(psId))
						return this.deleteDocumentIntoDatabase(psId, poDocument, pbFailIfNotFound);
					else {
						const lsDocumentId: string =
							typeof poDocument === "string" ? poDocument : (poDocument as IStoreDocument)._id;
						return throwError(
							() =>
								`Erreur lors de la suppression du document '${lsDocumentId}', base de données "${psId}" non initialisée.`
						);
					}
				})
			);
		}
	}

	/** Met à jour le document avec la valeur '_deleted' à true.
	 * @param poDocument Document à mettre à jour avec la valeur '_deleted' à true.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	private updateAsDeleted<T extends IStoreDocument>(
		poDocument: T,
		psDatabaseId?: string
	): Observable<IStoreDataResponse> {
		poDocument._deleted = true;
		poDocument.deleted = true;
		return this.put(poDocument, psDatabaseId).pipe(
			tap((_) => this.raiseChangeEvent(poDocument, psDatabaseId, EChangeType.delete))
		);
	}

	/** Supprime un document sur la base de données en le récupérant si l'on passe uniquement l'identifiant.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @param poDocument Document à supprimer de la base de données.
	 * @param pbFailIfNotFound Indique si une erreur doit être levée lorsque la suppression du document échoue.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private deleteDocumentIntoDatabase<T extends IStoreDocument>(
		psDatabaseId: string,
		poDocument: T | string,
		pbFailIfNotFound: boolean = true
	): Observable<IStoreDataResponse> {
		let loSelectedDatabase: PouchDB.Database;

		return this.getDatabaseInstance(psDatabaseId).pipe(
			mergeMap((poSelectedDatabase: PouchDB.Database) => {
				loSelectedDatabase = poSelectedDatabase;

				if (typeof poDocument === "string") {
					return from(loSelectedDatabase.get(poDocument)) // On récupère le document sur la base car on n'a que l'identifiant.
						.pipe(
							catchError((poError) => {
								console.error(
									`${Store.C_LOG_ID}Erreur récupération document "${poDocument}" dans la bdd "${psDatabaseId}" avant suppression :`,
									poError
								);
								return throwError(() => poError);
							})
						);
				} else return of(poDocument);
			}),
			mergeMap((poResultDoc: IStoreDocument) =>
				from(this.trackIfNeeded(this.getDatabaseById(psDatabaseId), poResultDoc)).pipe(mapTo(poResultDoc))
			),
			mergeMap((poResultDoc: IStoreDocument) => this.deleteDocument(loSelectedDatabase, poResultDoc, psDatabaseId)),
			tap((poResponse: PouchDB.Core.Response) => this.onDocumentUpdated(poResponse, psDatabaseId, "DELETE")),
			catchError((poError) => {
				if (!pbFailIfNotFound && poError.status === 404) {
					// Si le document n'a pas été trouvé.
					return of({
						id: typeof poDocument === "string" ? poDocument : (poDocument as IStoreDocument)._id,
						ok: true
					} as IStoreDataResponse);
				}
				return throwError(() => poError);
			})
		);
	}

	private deleteDocument<T extends IStoreDocument>(
		poSelectedDatabase: PouchDB.Database,
		poDocument: T,
		psDatabaseId: string
	): Observable<IStoreDataResponse> {
		return from(poSelectedDatabase.remove(poDocument as PouchDB.Core.IdMeta & PouchDB.Core.RevisionIdMeta)).pipe(
			tap((_) => this.raiseChangeEvent(poDocument, psDatabaseId, EChangeType.delete)),
			catchError((poError) => {
				console.error(
					`${Store.C_LOG_ID}Erreur suppression document "${poDocument._id}" depuis la bdd "${psDatabaseId}".`,
					poDocument,
					poError
				);
				return this.onPouchDbSaveError(poDocument, psDatabaseId, poError);
			})
		);
	}

	/** Retourne `true` si le document existe, `false` sinon.
	 * @param psDocId Identifiant du document à rechercher.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	public docExists(psDocId: string, psDatabase: string): Observable<boolean>;
	/** Retourne `true` si un document existe, `false` dans le cas contraire.
	 * @param psDocId Identifiant du document dont on veut vérifier l'existence.
	 * @param paDatabaseIds Tableau des identifiants de bases de données où chercher le document.
	 */
	public docExists(psDocId: string, paDatabaseIds: string[]): Observable<boolean>;
	public docExists(psDocId: string, poDatabaseIdData: string | string[]): Observable<boolean> {
		const lbIsDatabaseId: boolean = typeof poDatabaseIdData === "string";

		return this.getOne({
			databaseId: lbIsDatabaseId ? poDatabaseIdData : undefined,
			databasesIds: lbIsDatabaseId ? undefined : poDatabaseIdData,
			viewParams: {
				key: psDocId,
				include_docs: false
			}
		} as IDataSource).pipe(map((poResult?: IStoreDocument) => !!poResult));
	}

	/** Crée une base locale pour le mode 'local' et l'initialise en créant temporairement une base distante.
	 * @param psDatabaseId Id/nom de la base de données locale à initialiser.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private fillLocalDatabase(
		psDatabaseId: string,
		psLogin: string,
		psPassword: string,
		pfOnProgress?: IOnProgressFunction
	): Observable<IStoreReplicationResponse> {
		return this.replicateToLocal(psDatabaseId, undefined, psLogin, psPassword, pfOnProgress) // Synchronisation avec la base locale.
			.pipe(
				catchError((poError) => {
					console.error(`${Store.C_LOG_ID}Local database "${psDatabaseId}" initialization failed:`, poError);
					return throwError(() => poError);
				}),
				map((poResponse: IStoreReplicationResponse) => poResponse)
			);
	}

	/** @implements */
	public get<T extends IStoreDocument>(poDataSource: IDataSource<T>): Observable<T[]>;
	public get<T extends IStoreDocument>(poDataSource: IDataSourceRemoteChanges<T>): Observable<T[]>;
	public get<T extends IStoreDocument>(poDataSource: IDataSource<T> | IDataSourceRemoteChanges<T>): Observable<T[]> {
		const loGetLive$: Observable<IChangeEvent<T>[]> = defer(() =>
			poDataSource.live || (poDataSource as IDataSourceRemoteChanges<T>).remoteChanges
				? this.innerGet_live<T>(poDataSource)
				: EMPTY
		).pipe(startWith([]));

		return combineLatest([loGetLive$, this.innerGet(poDataSource)]).pipe(
			scan((poAccumulator: IGetScanResult<T>, paCurrentResults: [IChangeEvent<T>[], T[]]) => {
				const laCurrentEvents: IChangeEvent<T>[] = paCurrentResults[0];
				const laCurrentDocuments: T[] = Array.from(poAccumulator?.docs ?? paCurrentResults[1]);

				const laResults: boolean[] = laCurrentEvents.map(
					(
						poCurrentEvent: IChangeEvent<T> // On récupère le résultat pour chaque event
					) => this.handleChangeEvent<T>(poCurrentEvent, laCurrentDocuments, poDataSource)
				);

				return {
					docs: laCurrentDocuments,
					// Si il n'y a pas d'évènement de changement, on est dans le cas du premier get donc changed true,
					// sinon si au moins un event a donné lieu à un changement, changed true
					changed: !ArrayHelper.hasElements(laResults) || ArrayHelper.some(laResults)
				};
			}, undefined), // On met une graine undefined pour forcer le premier passage dans le scan
			filter((poResult: IGetScanResult<T>) => poResult.changed), // Si pas de changement on ne passe pas pour ne pas trigger les UI pour rien
			map((poResult: IGetScanResult<T>) => poResult.docs),
			switchMap((paDocs: T[]) =>
				poDataSource.withExternal ? this.fillExternalDocuments$(paDocs, poDataSource) : of(paDocs)
			),
			bufferUntil(() => (poDataSource as IDataSourceRemoteChanges).activePageManager?.isActive$ ?? true),
			map((paResults: T[][]) => ArrayHelper.getLastElement(paResults) ?? [])
		);
	}

	/**
	 * @param poCurrentEvent
	 * @param paCurrentDocuments
	 * @param poDataSource
	 * @returns `true` si changement, false sinon.
	 */
	public handleChangeEvent<T extends IStoreDocument>(
		poCurrentEvent: IChangeEvent<T>,
		paCurrentDocuments: T[],
		poDataSource: IDataSource<T>
	): boolean {
		if (poCurrentEvent.filtered)
			return !!ArrayHelper.removeElementById(paCurrentDocuments, poCurrentEvent.document._id);
		else {
			switch (poCurrentEvent.changeType) {
				case EChangeType.delete:
					return !!ArrayHelper.removeElementById(paCurrentDocuments, poCurrentEvent.document._id);
				case EChangeType.create:
				case EChangeType.update:
					ArrayHelper.replaceElementByFinder(
						paCurrentDocuments,
						(poDocument: T) => {
							const lbMatches: boolean = poDocument._id === poCurrentEvent.document._id;
							if (lbMatches)
								StoreHelper.updateDocumentCacheData(
									poCurrentEvent.document,
									StoreHelper.getDocumentCacheData(poDocument)
								);
							else this.updateDocumentDatabaseIdCacheData(poDocument, poCurrentEvent.databaseId);

							return lbMatches;
						},
						poDataSource.baseClass
							? ModelResolver.toClass(poDataSource.baseClass, poCurrentEvent.document)
							: poCurrentEvent.document,
						true
					);
					return true;
				default:
					return false;
			}
		}
	}

	/** Permet la récupération des changements sur une base distante.
	 * @param paDatabaseIds Identifiants de bases de données.
	 * @param poParams Paramètres de filtrage des données à récupérer.
	 * @returns
	 */
	public changes<T extends IStoreDocument>(poDataSource: IDataSourceRemoteChanges<T>): Observable<IChangeEvent<T>> {
		if (!poDataSource) return EMPTY;

		const loDataSource: IDataSourceRemoteChanges<T> = this.prepareDataSource<T, IDataSourceRemoteChanges<T>>(
			poDataSource
		);

		if (!ArrayHelper.hasElements(loDataSource.databasesIds)) {
			const lsMessage = "Veuillez préciser une base de données pour récupérer les changements.";

			console.error(`${Store.C_LOG_ID}${lsMessage}`);
			return throwError(() => lsMessage);
		}

		this.getDatabasesByIds(loDataSource.databasesIds).forEach((poDatabase: Database) =>
			this.initRemoteChanges(poDatabase)
		);

		return this.moRemoteChangesSubject.asObservable().pipe(
			mergeMap((poChange: IChangeEvent<T>) => this.processChange$(poChange, loDataSource)),
			bufferUntil(() => loDataSource.activePageManager.isActive$.pipe(filter((pbIsActive: boolean) => pbIsActive))),
			mergeMap((paChangeEvents: IChangeEvent<T>[]) => paChangeEvents),
			map((paChangeEvent) => {
				// Si le datasource spécifie une classe de base, on instancie la classe correspondante
				if (poDataSource.baseClass)
					paChangeEvent.document = ModelResolver.toClass(poDataSource.baseClass, paChangeEvent.document);
				return paChangeEvent;
			})
		);
	}

	public getDatabasesByIds(paIds: string[]): Database[] {
		return ArrayHelper.getValidValues(paIds.map((psId: string) => this.getDatabaseById(psId)));
	}

	private getChangesFromServer<T extends IStoreDocument>(
		poDatabase: Database,
		psLastSeq: string = "now"
	): Observable<PouchDB.Core.ChangesResponseChange<T>[]> {
		return defer(() => {
			if (!poDatabase.hasRemoteInstance()) return of([]);

			const loChanges: PouchDB.Core.Changes<T> = poDatabase
				.getRemoteInstance()
				.changes({ include_docs: true, since: psLastSeq, batch_size: Store.C_DEFAULT_BATCH_SIZE });
			const loCompleteEvent$: Observable<PouchDB.Core.ChangesResponse<T>> = fromEvent(
				loChanges,
				"complete"
			) as Observable<PouchDB.Core.ChangesResponse<T>>;
			const loError$: Observable<never> = fromEvent(loChanges, "error").pipe(
				mergeMap((poError) => throwError(() => poError)),
				takeUntil(loCompleteEvent$)
			);
			const loChange$: Observable<PouchDB.Core.ChangesResponseChange<T>> = fromEvent(loChanges, "change").pipe(
				map((paValues: any[]) => ArrayHelper.getFirstElement(paValues)),
				takeUntil(loCompleteEvent$)
			) as Observable<PouchDB.Core.ChangesResponseChange<T>>;

			return merge(loChange$, loError$).pipe(
				toArray(),
				takeUntil(poDatabase.canReplicate$.pipe(filter((pbCanReplicate: boolean) => !pbCanReplicate))),
				finalize(() => loChanges.cancel()) // Permet de clôturer la récupération des changements lors de la clôture du flux.
			);
		});
	}

	/** Retourne la stratégie d'essai de nouvelle tentative pour une requête vers la base de données.
	 * @param poErrors$
	 * @param pnDelayMs Delai avant de retenter (en ms).
	 */
	public getRequestRetryStrategy(poErrors$: Observable<any>, pnDelayMs = Store.C_RETRY_DELAY_MS): Observable<any> {
		return poErrors$.pipe(
			mergeMap((poError: any) => {
				return this.handlePouchDbError(poError).pipe(
					catchError((poPouchDBError: any) => {
						if (poPouchDBError instanceof TokenError) {
							this.handleTokenError(poError);
							return throwError(() => poPouchDBError);
						} else if (poPouchDBError instanceof DeviceNotAuthorizedError)
							return this.handleUnauthorizedDevice().pipe(mergeMapTo(throwError(() => poPouchDBError)));

						return timer(pnDelayMs).pipe(
							mergeMap(() => this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true))
						);
					})
				);
			})
		);
	}

	private getChangeTypeFromChange<T extends IStoreDocument>(poDoc: T, pbDeleted: boolean): EChangeType {
		if (pbDeleted) return EChangeType.delete;
		if (poDoc._rev.startsWith("1-")) return EChangeType.create;

		return EChangeType.update;
	}

	/** Partie du get() qui s'occupe de récupérer un lot de documents dans les bases de données.
	 * @param poDataSource Source de données permettant la récupération des documents.
	 */
	private innerGet<T extends IStoreDocument>(poDataSource: IDataSource<T>): Observable<T[]> {
		// Si la source de données possède un tableau d'identifiants de base de données ou un rôle, il faut faire un get multi, sinon get simple.
		const loGet$: Observable<T[]> =
			(ArrayHelper.hasElements(poDataSource.databasesIds) && poDataSource.databasesIds.length > 1) || poDataSource.role
				? this.getFromMultiDatabases(poDataSource)
				: this.getFromDatabase(
					poDataSource,
					poDataSource.databaseId ?? ArrayHelper.getFirstElement(poDataSource.databasesIds)
				);
		const loPerformance = new PerformanceManager();

		return defer(() => {
			console.debug(`${Store.C_LOG_ID}Nouvelle requête récupération :`, poDataSource);
			if (!poDataSource.lowPriority) this.appendUserActionsCounter(1);
			return of(loPerformance.markStart());
		}).pipe(
			switchMap((_) => loGet$),
			tap((paResults: T[]) => {
				if (poDataSource.viewParams.conflicts) {
					const laConflictedDocIds: string[] = [];
					paResults.forEach((poResult: T) => {
						if (ArrayHelper.hasElements(poResult._conflicts)) laConflictedDocIds.push(poResult._id);
					});

					if (ArrayHelper.hasElements(laConflictedDocIds)) this.moConflictsSubject.next(laConflictedDocIds);
				}
			}),
			map((paResults: T[]) => {
				this.createCompiledViewDoc(poDataSource.viewName);

				if (poDataSource.baseClass) {
					const laResults: T[] = [];

					for (let lnIndex = 0; lnIndex < paResults.length; ++lnIndex) {
						laResults.push(ModelResolver.toClass(poDataSource.baseClass, paResults[lnIndex]));
					}

					return laResults;
				}

				return paResults;
			}),
			tap(
				(paResults: T[]) =>
					console.debug(
						`${Store.C_LOG_ID}Get duration (ms) : ${loPerformance.markEnd().measure()} ; nb docs got: ${paResults.length
						} ; dataSource:`,
						poDataSource
					),
				(poError) => {
					loPerformance.clear();
					console.error(
						`${Store.C_LOG_ID}Erreur lors de la requête de récupération ; dataSource:`,
						poDataSource,
						poError
					);
				}
			),
			finalize(() => {
				if (!poDataSource.lowPriority) this.appendUserActionsCounter(-1);
			})
		);
	}

	/** Récupère les documents initiaux puis filtre les changements détectés sur les bases de données.
	 * @param poDataSource Source de données permettant la récupération des documents.
	 */
	private innerGet_live<T extends IStoreDocument>(
		poDataSource: IDataSource<T> | IDataSourceRemoteChanges<T>
	): Observable<IChangeEvent<T>[]> {
		const laSources$: Observable<IChangeEvent<T>>[] = [];
		const loEmittedSubject = new Subject<void>();

		if (poDataSource.live) laSources$.push(this.localChanges(poDataSource));
		if ((poDataSource as IDataSourceRemoteChanges<T>).remoteChanges)
			laSources$.push(this.changes(poDataSource as IDataSourceRemoteChanges<T>));

		return merge(...laSources$).pipe(
			// On s'abonne aux différentes sources.
			tap(() => loEmittedSubject.next(undefined)), // Dès qu'un événement est envoyé on le signale dans le sujet.
			// A chaque événement dans le sujet on crée un buffer qui va garder les résultats pendant le temps indiqué.
			// Si un buffer existe déjà, il n'en crée pas un autre.
			bufferWhen(() => loEmittedSubject.asObservable().pipe(delay(this.C_GET_LIVE_BUFFER_TIME_MS))),
			finalize(() => loEmittedSubject.complete())
		);
	}

	/** Retourne les changements fait localement.
	 * @param poDataSource
	 */
	public localChanges<T extends IStoreDocument = IStoreDocument>(
		poDataSource: IDataSource<T>
	): Observable<IChangeEvent<T>> {
		const loDataSource: IDataSource<T> = this.prepareDataSource<T>(poDataSource);
		return (this.moLocalChangesSubject.asObservable() as Observable<IChangeEvent<T>>).pipe(
			mergeMap((poChange: IChangeEvent<T>) => this.processChange$(poChange, loDataSource))
		);
	}

	/** Vérifie si le changement opéré correspond à la source de données.
	 * @param poChange Événement qui s'est produit.
	 * @param poDataSource Source de données qui permet d'écouter les changements du store.
	 */
	private changeMatchesDataSourceDatabases<T extends IStoreDocument>(
		poChange: IChangeEvent<T>,
		poDataSource: IDataSource<T>
	): boolean {
		// On vérifie que l'une des bases de données d'où proviennent les changements correspond à l'une des bases de données de la source de données.
		if (ArrayHelper.hasElements(poDataSource.databasesIds))
			return poDataSource.databasesIds.some(
				(psDataSourceDatabaseId: string) => poChange.databaseId === psDataSourceDatabaseId
			);
		else return poChange.databaseId === poDataSource.databaseId;
	}

	/** Vérifie si le changement opéré correspond à la source de données.
	 * @param poChange Événement qui s'est produit.
	 * @param poDataSource Source de données qui permet d'écouter les changements du store.
	 */
	private changeMatchesDataSourceParams<T extends IStoreDocument>(
		poChange: IChangeEvent<T>,
		poDataSource: IDataSource<T>
	): boolean {
		return ObjectHelper.isEmpty(poDataSource.viewParams) || this.changeMatchesViewParams(poChange, poDataSource);
	}

	private processChange$<T extends IStoreDocument>(
		poChange: IChangeEvent<T>,
		poDataSource: IDataSource<T>
	): Observable<IChangeEvent<T>> {
		return defer(() => {
			if (!this.changeMatchesDataSourceDatabases(poChange, poDataSource)) return EMPTY;

			if (this.isRequestByView(poDataSource))
				// Gestion des changements dans le cas d'une requête par vue.
				return this.processViewChange$<T>(poDataSource, poChange);
			else if (this.changeMatchesDataSourceParams(poChange, poDataSource)) return of(poChange);
			else return EMPTY;
		}).pipe(
			map((poResultChange: IChangeEvent<T>) => {
				if (poDataSource.filter)
					poResultChange = { ...poResultChange, filtered: !poDataSource.filter(poResultChange.document) };

				return poResultChange;
			})
		);
	}

	private isRequestByView(psViewName: string | undefined): boolean;
	private isRequestByView<T extends IStoreDocument>(poDataSource: IDataSource<T>): boolean;
	private isRequestByView<T extends IStoreDocument>(poParam?: IDataSource<T> | string): boolean {
		const lsViewName: string | undefined = typeof poParam === "object" ? poParam.viewName : poParam;

		return !StringHelper.isBlank(lsViewName) && lsViewName !== Store.C_DEFAULT_VIEW_DOCS;
	}

	private processViewChange$<T extends IStoreDocument>(
		poDataSource: IDataSource<T>,
		poChange: IChangeEvent<T>
	): Observable<IChangeEvent<T>> {
		if (poChange.document._deleted) return of(poChange);

		const loDocIdAndViewName: IDocIdAndViewName = this.getDocIdAndViewName<T>(poDataSource);

		return this.getViewDoc$(poChange.databaseId, loDocIdAndViewName.docId).pipe(
			mergeMap((poView: IDesignDocument) =>
				StoreHelper.mapFunctionExecutor(poChange.document, poView.views?.[loDocIdAndViewName.viewName]?.map)
			),
			map((poKeyValue: KeyValue<string | string[] | number, any>) => {
				const loChange: IChangeEvent<T> = { ...poChange };
				loChange.key = poKeyValue.key;

				if (!poDataSource.viewParams?.include_docs)
					loChange.document = { ...poKeyValue.value, _id: loChange.document._id, _rev: loChange.document._rev };
				else loChange.document = ObjectHelper.clone(loChange.document);

				StoreHelper.updateDocumentCacheData(loChange.document, { key: loChange.key });

				return loChange;
			}),
			filter((poResultChange: IChangeEvent<T>) => this.changeMatchesDataSourceParams(poResultChange, poDataSource))
		);
	}

	private getDocIdAndViewName<T extends IStoreDocument>(poDataSource: IDataSource<T>): IDocIdAndViewName {
		const laDesignParts: string[] = poDataSource.viewName.split("/");
		return { docId: ArrayHelper.getFirstElement(laDesignParts), viewName: ArrayHelper.getLastElement(laDesignParts) };
	}

	@Queue<Store, Parameters<Store["getViewDoc$"]>, ReturnType<Store["getViewDoc$"]>>({
		idBuilder: (psDatabaseId: string, psDocId: string) => `${psDatabaseId}-${psDocId}`
	})
	private getViewDoc$(
		psDatabaseId: string,
		psDocId: string,
		pbLive?: boolean
	): Observable<IDesignDocument | undefined> {
		let loDbViewChache: Map<string, IDesignDocument> | undefined = this.moViewDocByDocIdByDatabaseId.get(psDatabaseId);

		if (loDbViewChache) {
			const loView: IDesignDocument | undefined = loDbViewChache.get(psDocId);
			if (loView) return of(loView);
		} else this.moViewDocByDocIdByDatabaseId.set(psDatabaseId, (loDbViewChache = new Map()));

		return this.getOne<IDesignDocument>(
			{
				databaseId: psDatabaseId,
				viewParams: {
					key: IdHelper.buildId(EPrefix.design, psDocId),
					include_docs: true
				},
				allowDesign: true,
				lowPriority: true,
				live: pbLive,
				remoteChanges: pbLive,
				activePageManager: pbLive ? this.moActivePageManager : undefined
			},
			false
		).pipe(tap((poView?: IDesignDocument) => loDbViewChache.set(psDocId, poView)));
	}

	private changeMatchesViewParams<T extends IStoreDocument>(
		poChange: IChangeEvent<T>,
		poDataSource: IDataSource<T>
	): boolean {
		const loViewParams: IDataSourceViewParams = poDataSource.viewParams;
		return this.documentMatchesViewParams(poChange.key, loViewParams);
	}

	private documentMatchesViewParams(
		poDocumentKey: string | string[] | number,
		poViewParams: IDataSourceViewParams
	): boolean {
		let lbMatches = true;

		if (StringHelper.isValid(poViewParams.key) || NumberHelper.isValid(poViewParams.key))
			lbMatches = poDocumentKey === poViewParams.key;
		else if (poViewParams.keys) lbMatches = poViewParams.keys.includes(poDocumentKey);
		else {
			lbMatches = this.matchStartKey(poViewParams, poDocumentKey) && this.matchEndKey(poViewParams, poDocumentKey);
		}

		return lbMatches;
	}

	private matchStartKey(poViewParams: IDataSourceViewParams, poDocumentKey: string | number | string[]): boolean {
		return poViewParams.startkey instanceof Array && poDocumentKey instanceof Array
			? poViewParams.startkey.every((poStartKey: string | number, pnIndex: number) =>
				this.compareStartKey(poDocumentKey[pnIndex], poStartKey)
			)
			: !(poViewParams.startkey instanceof Array || poDocumentKey instanceof Array) &&
			this.compareStartKey(poDocumentKey, poViewParams.startkey);
	}

	private matchEndKey(poViewParams: IDataSourceViewParams, poDocumentKey: string | number | string[]): boolean {
		return poViewParams.endkey instanceof Array && poDocumentKey instanceof Array
			? poViewParams.endkey.every((poEndKey: string | number, pnIndex: number) =>
				this.compareEndKey(poDocumentKey[pnIndex], poEndKey)
			)
			: !(poViewParams.endkey instanceof Array || poDocumentKey instanceof Array) &&
			this.compareEndKey(poDocumentKey, poViewParams.endkey);
	}

	private compareStartKey(poDocKey?: string | number, poStartKey?: number | string): boolean {
		return (
			!ObjectHelper.isDefined(poStartKey) ||
			(typeof poStartKey === "string" ? StringHelper.isBlank(poStartKey) : !NumberHelper.isValid(poStartKey)) ||
			poDocKey >= poStartKey
		);
	}

	private compareEndKey(poDocKey?: string | number, poEndKey?: number | string): boolean {
		return (
			!ObjectHelper.isDefined(poEndKey) ||
			(typeof poEndKey === "string" ? StringHelper.isBlank(poEndKey) : NumberHelper.isValid(poEndKey)) ||
			poDocKey <= poEndKey
		);
	}

	/** @implements */
	public getOne<T extends IStoreDocument>(poDataSource: IDataSource<T>, pbFailIfNoResult?: boolean): Observable<T>;
	public getOne<T extends IStoreDocument>(
		poDataSource: IDataSourceRemoteChanges<T>,
		pbFailIfNoResult?: boolean
	): Observable<T>;
	public getOne<T extends IStoreDocument>(
		poDataSource: IDataSource<T> | IDataSourceRemoteChanges<T>,
		pbFailIfNoResult: boolean = true
	): Observable<T> {
		const loDataSource: IDataSource<T> | IDataSourceRemoteChanges<T> = this.prepareDataSource<T>(poDataSource);

		return this.get(loDataSource).pipe(
			mergeMap((paResults: T[]) => {
				const lsDatabaseIds: string = !StringHelper.isBlank(loDataSource.databaseId)
					? loDataSource.databaseId
					: loDataSource.databasesIds.toString();

				if (pbFailIfNoResult && !ArrayHelper.hasElements(paResults)) {
					const lsMessage = `No result found for document ${poDataSource.viewParams.key} in database ${lsDatabaseIds}.`;
					console.error(`${Store.C_LOG_ID}${lsMessage}`);
					return throwError(() => ({ isEmptyResult: true, message: lsMessage }));
				} else {
					if (paResults.length > 1)
						console.warn(
							`${Store.C_LOG_ID}More than 1 result found for document ${poDataSource.id} in database ${lsDatabaseIds}.`
						);

					return of(ArrayHelper.getFirstElement(paResults));
				}
			})
		);
	}

	/** Permet de récupérer les documents avec la view allDocs.
	 * @param poParams paramètres de requête PouchDB (clé, vue, paramètres de la vue).
	 * @param psDatabaseId Id de la base de données à requêter.
	 */
	private getAll<T extends IStoreDocument>(poParams: IDataSource<T>, psDatabaseId: string): Observable<T[]> {
		if (poParams.viewParams?.keys && !ArrayHelper.hasElements(poParams.viewParams.keys)) return of([]);

		return from(this.pouchDbGetAllAsync<T>(psDatabaseId, poParams)).pipe(
			catchError((poError) => {
				console.error(`${Store.C_LOG_ID}Error retrieve all data of database "${psDatabaseId}":`, poError);
				return throwError(() => poError);
			}),
			map((poResult?: PouchDB.Core.AllDocsResponse<T>) => this.getDataValuesFromPouchQuery(poParams, poResult))
		);
	}

	private pouchDbGetAllAsync<T extends IStoreDocument>(
		psDatabaseId: string,
		poParams: IDataSource<T>
	): Promise<PouchDB.Core.AllDocsResponse<T> | undefined> {
		return (
			this.moDatabaseById.get(psDatabaseId)?.defaultDatabase.allDocs(poParams.viewParams) ?? Promise.resolve(undefined)
		);
	}

	/** Permet de récupérer les documents avec une vue autre que `allDocs`.
	 * @param poDataSource Source de données pour la requête PouchDB (clé, vue, paramètres de la vue).
	 * @param psDatabaseId Identifiant de la base de données à requêter.
	 */
	private getByView<T extends IStoreDocument>(poDataSource: IDataSource<T>, psDatabaseId: string): Observable<T[]> {
		const loDatabase: Database | undefined = this.moDatabaseById.get(psDatabaseId);
		const loLocalInstance: PouchDB.Database | undefined = loDatabase?.getLocalInstance();
		if (loLocalInstance) {
			// Si on a une instance locale alors on va utiliser l'indexeur propriétaire.
			const loDocIdAndViewName: IDocIdAndViewName = this.getDocIdAndViewName<T>(poDataSource);

			return this.getViewDoc$(psDatabaseId, loDocIdAndViewName.docId).pipe(
				switchMap((poDesignDoc?: IDesignDocument) => {
					if (poDesignDoc) {
						return this.isvcMobileIndexor.requestAsync({
							databaseId: psDatabaseId,
							changes: (poOptions: IStoreChangesOptions) => loLocalInstance.changes(poOptions),
							designDoc: poDesignDoc,
							viewName: loDocIdAndViewName.viewName,
							viewParams: poDataSource.viewParams
						});
					}

					return of([]); // Si pas de document de vue, alors on retourne un résultat vide.
				}),
				switchMap((paResults: IIndexorViewRow[]) => {
					if (poDataSource.viewParams?.include_docs)
						return this.getMobileIndexorDocumentDetail$<T>(psDatabaseId, paResults);
					else {
						return of(
							paResults.map((poResult: IIndexorViewRow) =>
								StoreHelper.updateDocumentCacheData(
									{ _id: poResult.id, _rev: poResult.rev, ...poResult.value },
									{ key: poResult.key }
								)
							)
						);
					}
				})
			);
		}

		return from(this.pouchDbGetByViewAsync<T>(psDatabaseId, poDataSource)).pipe(
			map((poResult?: PouchDB.Query.Response<any>) => this.getDataValuesFromPouchQuery(poDataSource, poResult)),
			catchError((poError) => {
				// TODO Voir comment gérer la réplication async de la vue
				if (poError.status === 404)
					// La vue n'est pas présente.
					return of([]);

				console.error(`${Store.C_LOG_ID}Error request getByView on ${psDatabaseId}:`, poError);
				return throwError(() => poError);
			})
		);
	}

	private getMobileIndexorDocumentDetail$<T extends IStoreDocument>(
		psDatabaseId: string,
		paResults: IIndexorViewRow[]
	): Observable<T[]> {
		const laIds: string[] = [];
		const loIndexorViewDataByDocId = new Map<string, IIndexorViewRow[]>();

		paResults.forEach((poResult: IIndexorViewRow) => {
			laIds.push(poResult.id);
			const laIndexorViewData: IIndexorViewRow[] | undefined = loIndexorViewDataByDocId.get(poResult.id);

			if (!laIndexorViewData) loIndexorViewDataByDocId.set(poResult.id, [poResult]);
			else laIndexorViewData.push(poResult);
		});

		return this.getAll<T>({ viewParams: { keys: ArrayHelper.unique(laIds), include_docs: true } }, psDatabaseId).pipe(
			map((paDocuments: T[]) =>
				paDocuments
					.map((poDocument: T) => {
						const laIndexorViewData: IIndexorViewRow[] | undefined = loIndexorViewDataByDocId.get(poDocument._id);

						return (
							laIndexorViewData?.map((poIndexorViewData: IIndexorViewRow, pnIndex: number) => {
								const loDocClone: T = pnIndex > 0 ? { ...poDocument } : poDocument; // Si on n'est pas sur la première occurrence du document dans les resultats de la vue, alors on clone.

								return StoreHelper.updateDocumentCacheData(loDocClone, { key: poIndexorViewData.key });
							}) ?? []
						);
					})
					.flat()
			)
		);
	}

	private pouchDbGetByViewAsync<T extends IStoreDocument>(
		psDatabaseId: string,
		poDataSource: IDataSource<T>
	): Promise<PouchDB.Query.Response<{}> | undefined> {
		return (
			this.moDatabaseById.get(psDatabaseId)?.defaultDatabase.query(poDataSource.viewName, poDataSource.viewParams) ??
			Promise.resolve(undefined)
		);
	}

	/** @implements
	 * @throws
	 */
	public getDatabaseById(psDatabaseId: string): Database {
		const loDatabase: Database | undefined = this.moDatabaseById.get(psDatabaseId);

		if (!loDatabase) throw new Error(`Unknown database with id "${psDatabaseId}".`);

		return loDatabase;
	}

	/** @implements */
	public async getDatabaseConfigAsync(
		poDatabase: Database,
		pnRevsLimit?: number,
		psLogin?: string,
		psPassword?: string
	): Promise<IConfigInitPouchDB> {
		return {
			localPouchConfig: await this.innerGetDatabaseConfig_localPouchConfigAsync(poDatabase.id, pnRevsLimit),
			remotePouchConfig: this.innerGetDatabaseConfig_remotePouchConfig(poDatabase, psLogin, psPassword)
		};
	}

	private async innerGetDatabaseConfig_localPouchConfigAsync(
		psDatabaseId: string,
		pnRevsLimit?: number
	): Promise<ILocalDatabaseConfig> {
		return {
			adapter: this.adapter,
			auto_compaction: true,
			revs_limit: isNaN(pnRevsLimit) ? this.C_DEFAULT_REVS_LIMIT : pnRevsLimit,
			name: psDatabaseId,
			location: "default",
			mode: await this.getLocalDatabaseModeFromMetaAsync(psDatabaseId)
		} as ILocalDatabaseConfig;
	}

	private async getLocalDatabaseModeFromMetaAsync(psDatabaseId: string): Promise<ELocalDatabaseMode> {
		let loMeta: ILocalDatabaseFileMeta | undefined;
		const lsMetaPath: string = this.getLocalDatabaseFileMetaPath(psDatabaseId);
		if (await this.isvcFilesystem.existsAsync(lsMetaPath, LocalDatabaseProviderService.mobileAppDatabasesDirectory)) {
			try {
				loMeta = JSON.parse(
					await this.isvcFilesystem.readFileAsTextAsync(
						lsMetaPath,
						LocalDatabaseProviderService.mobileAppDatabasesDirectory
					)
				);
			} catch (poError) {
				console.warn(`${Store.C_LOG_ID}Error while reading meta for database ${psDatabaseId}. No meta will be used`);
			}
		}

		return loMeta?.mode ?? ELocalDatabaseMode.legacy; // Si pas de fichier ou mode invalide alors legacy.
	}

	private getLocalDatabaseFileMetaPath(psDatabaseId: string): string {
		return `${this.isvcLocalDatabaseProvider.mobileAppDatabasesPath}${psDatabaseId}.meta.json`;
	}

	/** Retourne un objet de configuration pouch pour les bases de données distantes.
	 * @param poDatabase Base de données.
	 * @param psLogin Login de l'utilisateur.
	 * @param psPassword Mot de passe de l'utilisateur.
	 */
	private innerGetDatabaseConfig_remotePouchConfig(
		poDatabase: Database,
		psLogin?: string,
		psPassword?: string
	): IRemoteDatabaseConfig {
		return {
			fetch: (psUrl: string, opts: RequestInit) => {
				//https://github.com/pouchdb-community/pouchdb-authentication/issues/239#issuecomment-431872289
				(opts.headers as any).set("appInfo", OsappApiHelper.stringifyForHeaders(ConfigData.appInfo));

				if (ConfigData.authentication.token) (opts.headers as any).set("Token", ConfigData.authentication.token);

				if (!StringHelper.isBlank(poDatabase.meta?.originInstanceId))
					(opts.headers as any).set("databaseMetaInstance", poDatabase.meta.originInstanceId);

				opts.credentials = "omit";

				return PouchDB.fetch(psUrl, opts);
			},
			crossDomain: true,
			ajax: { cache: false },
			auth: {
				username: psLogin,
				password: psPassword
			},
			name: this.buildRemoteDatabaseName(poDatabase.id),
			skip_setup: true
		};
	}

	private buildRemoteDatabaseName(psDatabaseId: string): string {
		return `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_data_suffix}${psDatabaseId}`;
	}

	/** Détermine dynamiquement l'adapter approprié en fonction du contexte. */
	private getAdapter(): string {
		// On utilise SQLite si on est sur un mobile, sinon on utilise idb (websql n'étant plus supporté sur divers navigateurs).
		const lsAdapter = this.isvcPlatform.isMobileApp ? "cordova-sqlite" : "idb";

		console.debug(`${Store.C_LOG_ID}Store adapter is ${lsAdapter}.`);

		return lsAdapter;
	}

	/** @implements */
	public getDatabases(): ReadonlyMap<string, Database> {
		return this.moDatabaseById as ReadonlyMap<string, Database>;
	}

	/** Retourne les bases de données qui correspondent au rôle indiqué.
	 * @param peRole Rôle recherché.
	 * @param pbFailIfNoResult Indique si on lève une erreur dans le cas où on ne trouve pas de base de données ayant le rôle.
	 */
	public getDatabasesByRole(peRole: EDatabaseRole, pbFailIfNoResult: boolean = true): Database[] {
		const laDatabases: Database[] = MapHelper.valuesToArray(this.moDatabaseById).filter((poDatabase: Database) =>
			poDatabase.hasRole(peRole)
		);

		if (pbFailIfNoResult && !ArrayHelper.hasElements(laDatabases))
			throw new Error(`Impossible de trouver les bases de données correspondant au rôle "${peRole}".`);
		else return laDatabases;
	}

	/** Retourne les bases de données à optimiser. */
	public getDatabasesToOptimize(): Database[] {
		return MapHelper.valuesToArray(this.moDatabaseById).filter(
			(poDatabase: Database) => poDatabase.optimization?.enabled
		);
	}

	/** @implements */
	public getDatabasesIdsByRole(peRole: EDatabaseRole, pbFailIfNoResult: boolean = true): string[] {
		return this.getDatabasesByRole(peRole, pbFailIfNoResult).map((poDatabase: Database) => poDatabase.id);
	}

	/** Récupère seulement les valeurs des données contenues dans la propriété 'rows': Array<any> (données utiles).
	 * @param poDataFromQuery objet de données récupéré après une requête sur une base de données Pouch, contenant un tableau de résultats.
	 */
	private getDataValuesFromPouchQuery<T extends IStoreDocument>(
		poDataSource: IDataSource<T>,
		poDataFromQuery?: PouchDB.Core.AllDocsResponse<T>
	): T[] {
		let laDataValues: T[];

		if (ArrayHelper.hasElements(poDataFromQuery?.rows)) {
			// Si le premier élément du tableau de données contient une prop 'doc' alors il faut prendre les données avec les infos.
			const lbGetAllInfo: boolean = poDataFromQuery.rows.some(
				(poItem: IDataFromPouchQuery<T>) => poItem.doc !== undefined
			);

			laDataValues = poDataFromQuery.rows
				.filter(
					(poItem: IDataFromPouchQuery<T>) =>
						(!lbGetAllInfo || (lbGetAllInfo && !ObjectHelper.isNullOrEmpty(poItem.doc))) &&
						this.isValidValueFromPouchQuery(poItem, !lbGetAllInfo || poDataSource.allowDesign)
				)
				.map((poItem: IDataFromPouchQuery<T>) => {
					let loDoc: T;
					if (lbGetAllInfo) loDoc = poItem.doc;
					else
						loDoc = {
							_id: poItem.id,
							_rev: poItem.value.rev,
							_deleted: poItem.value.deleted,
							...poItem.value
						} as any as T;

					return StoreHelper.updateDocumentCacheData(loDoc, { key: poItem.key });
				});
		} else laDataValues = [];

		return laDataValues;
	}

	/** Vérifie si la valeur est valide.
	 * @param poItem Item à tester.
	 * @param pbAllowDesign Permet de savoir si l'on doit accepter les documents '_design'.
	 */
	private isValidValueFromPouchQuery<T extends IStoreDocument>(
		poItem: IDataFromPouchQuery<T>,
		pbAllowDesign: boolean
	): boolean {
		return poItem.key && poItem.value && !poItem.value.deleted && (pbAllowDesign || poItem.id.indexOf("_design") < 0);
	}

	/** Retourne l'observable utilisé pour faire un get vers PouchDB.
	 * @param poDataSource Paramètres de la requête.
	 * @param psDatabaseId Id de la base de données à requêter.
	 * @param pbHandleUnknownDatabases Indique si l'on doit gérer les bases de données inconnues.
	 */
	private getRequestType<T extends IStoreDocument>(
		poDataSource: IDataSource<T>,
		psDatabaseId: string,
		pbHandleUnknownDatabases: boolean
	): Observable<T[]> {
		if (!this.moDatabaseById.has(psDatabaseId))
			// Si la base n'existe pas.
			return pbHandleUnknownDatabases ? of([]) : throwError(() => `Erreur : la base ${psDatabaseId} n'existe pas`);
		else if (!this.isRequestByView(poDataSource))
			// La base existe et est initialisée.
			return this.getAll(poDataSource, psDatabaseId);
		else return this.getByView(poDataSource, psDatabaseId);
	}

	/** Requête sur une base de données.
	 * @param poDataSource Paramètres de la requête.
	 * @param psDatabaseId Identifiant de la base de données à requêter.
	 */
	private getFromDatabase<T extends IStoreDocument>(
		poDataSource: IDataSource<T>,
		psDatabaseId: string
	): Observable<T[]> {
		// On clone la dataSource préparée pour ne pas altérer l'objet qui peut être utilisé dans un getLive() et qui ne doit pas être changé.
		const loDataSource: IDataSource<T> = this.prepareDataSource<T>(poDataSource);

		return defer(() => {
			console.debug(`${Store.C_LOG_ID}Getting data from database : ${psDatabaseId}`);
			if (!StringHelper.isBlank(poDataSource.viewName) && poDataSource.live) {
				// On gère le cas que la vue présente sur le serveur mais pas encore répliquée.
				return this.innerGet_live({
					viewParams: {
						key: this.getDocIdAndViewName(loDataSource).docId
					},
					databasesIds: loDataSource.databasesIds
				}).pipe(startWith(undefined));
			}
			return of(null);
		})
			.pipe(
				mergeMap(_ => this.getRequestType(loDataSource, psDatabaseId, loDataSource.handleUnknownDatabases)),
				expand((paResults: T[]) => {
					if (!NumberHelper.isValidStrictPositive(loDataSource.viewParams.batch_size)) // Si pas de batch alors on stoppe la requête.
						return EMPTY;
					else { // Une valeur est définie pour batch_size
						const lnBatchSize: number = loDataSource.viewParams.batch_size;

						if (ArrayHelper.hasElements(loDataSource.viewParams.keys)) { // Cas 'keys'.
							// NB : Le paramètre batch_size est inhibé par le code appelant dans le cas d'une vue
							// car il n'est pas supporté sur les vues, uniquement sur _all_docs pour laquelle :
							// - chaque clé n'est susceptible d'émettre qu'un et un seul résultat.
							// - chaque clé recherchée/non trouvée donne lieu à un item de réponse "key not found".
							loDataSource.viewParams.keys.splice(0, lnBatchSize);

							if (!ArrayHelper.hasElements(loDataSource.viewParams.keys))
								return EMPTY;
						}
						else { // Cas 'startkey'/'endkey'.
							if (paResults.length !== lnBatchSize)
								// On a atteint la fin des résultats.
								return EMPTY;

							loDataSource.viewParams.skip = 1; // On skip le premier car c'est le dernier du batch précédent.
							loDataSource.viewParams.startkey = ArrayHelper.getLastElement(paResults)._id;
						}

						return this.getRequestType(loDataSource, psDatabaseId, loDataSource.handleUnknownDatabases);
					}
				}, 1),
				reduce((paResults: T[], paCurrentResults: T[]) => {
					return ArrayHelper.appendArrays(paResults, paCurrentResults);
				}, []),
				map((paResults: T[]) => this.onGetResult(paResults, loDataSource, psDatabaseId))
			);
	}

	private prepareDataSource<T extends IStoreDocument = IStoreDocument, U extends IDataSource<T> = IDataSource<T>>(poDataSource: U): U {
		if (!poDataSource.viewParams)
			poDataSource.viewParams = {};

		if (ArrayHelper.hasElements(poDataSource.fields)) {
			poDataSource.fields.push(Store.C_ID_PROPERTY, Store.C_REVISION_PROPERTY);
			if (poDataSource.viewParams.conflicts) poDataSource.fields.push(Store.C_CONFLICTS_PROPERTY);
			poDataSource.fields = ArrayHelper.unique(poDataSource.fields);
			poDataSource.viewParams.include_docs = true;
		}

		poDataSource.handleUnknownDatabases = poDataSource.handleUnknownDatabases !== false;

		const loDataSource: U = {
			...poDataSource,
			viewParams: {
				// On n'utilise pas le clone car dans le cas d'un long tableau dans keys il demande trop de ressources.
				...poDataSource.viewParams,
				keys: poDataSource.viewParams?.keys ? [...poDataSource.viewParams.keys] : undefined
			}
		};

		// Si on inclus les conflits, on est obligé d'inclure le document.
		if (poDataSource.viewParams.conflicts) poDataSource.viewParams.include_docs = true;

		//! ATTENTION : Si on fait un get avec une vue et si la clé est un nombre sous forme de string, on convertit la clé en nombre.
		//! Si on fait cette transformation tout le temps, cela pose problème dans COM (récupération des clients) avec l'adapteur 'idb'.
		if (this.isRequestByView(loDataSource) && NumberHelper.isValid(+loDataSource.viewParams.key))
			loDataSource.viewParams.key = +loDataSource.viewParams.key;

		if (StringHelper.isBlank(loDataSource.viewName)) { // Pas de batch dans le cas d'une requête par vue.
			if (loDataSource.viewParams?.include_docs && (isNaN(loDataSource.viewParams.batch_size) || loDataSource.viewParams.batch_size === 0))
				loDataSource.viewParams.batch_size = Store.C_DEFAULT_BATCH_SIZE;

			if (!NumberHelper.isValidStrictPositive(loDataSource.viewParams.limit))
				loDataSource.viewParams.limit = loDataSource.viewParams.batch_size;
		}
		else if (NumberHelper.isValidStrictPositive(loDataSource.viewParams.batch_size)) {
			loDataSource.viewParams.batch_size = undefined;
			console.warn(`${Store.C_LOG_ID}Batch is ignored for view requests.`);
		}

		if (ArrayHelper.hasElements(loDataSource.viewParams.keys))
			loDataSource.viewParams.keys = ArrayHelper.unique(
				loDataSource.viewParams.keys.filter(
					(poKey: string | string[]) => typeof poKey !== "string" || !StringHelper.isBlank(poKey)
				),
				(poKey: string | string[]) => (typeof poKey === "string" ? poKey : poKey.toString())
			);


		if (loDataSource.role) loDataSource.databasesIds = this.getDatabasesIdsByRole(poDataSource.role);

		if (!ArrayHelper.hasElements(loDataSource.databasesIds) && !StringHelper.isBlank(loDataSource.databaseId))
			loDataSource.databasesIds = [loDataSource.databaseId];

		if (UserData.current) loDataSource.databasesIds = this.prepareWorkspaceFiltersDatabases(loDataSource.databasesIds);

		return loDataSource;
	}

	/** Requête sur plusieurs bases de données.
	 * @param poDataSource Paramètres de la requête.
	 */
	private getFromMultiDatabases<T extends IStoreDocument>(poDataSource: IDataSource<T>): Observable<T[]> {
		const loDataSource: IDataSource<T> = this.prepareDataSource<T>(poDataSource);

		return from(loDataSource.databasesIds).pipe(
			mergeMap((psDatabaseId: string) => this.getFromDatabase(loDataSource, psDatabaseId)),
			reduce((paAccumulResults: T[], paCurrentResults: T[]) => paAccumulResults.concat(paCurrentResults), [])
		);
	}

	/** Appelle les méthodes adéquates pour initialiser les bases de données en fonction du type de synchro.
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param poLoader Indicateur de chargement.
	 * @param psLogin Login, optionnel.
	 * @param psPassword Mot de passe, optionnel.
	 */
	@Queue<Store, Parameters<Store["initDatabase"]>, ReturnType<Store["initDatabase"]>>({
		idBuilder: (poDatabaseConfig: IDatabaseConfig) => poDatabaseConfig?.id,
		paramsReducer: (_, paNewArgs: [IDatabaseConfig, Loader, string, string]) => paNewArgs
	})
	private initDatabase(
		poDatabaseConfig: IDatabaseConfig,
		poLoader: Loader,
		psLogin?: string,
		psPassword?: string
	): Observable<Database> {
		if (
			!ConfigData.databases.some(
				(poCachedDatabaseConfig: IDatabaseConfig) => poDatabaseConfig.id === poCachedDatabaseConfig.id
			)
		)
			ConfigData.databases.push(poDatabaseConfig);

		const leSyncType: ESyncType = this.extractSyncTypeFromConfig(poDatabaseConfig);

		poDatabaseConfig.roles = poDatabaseConfig.roles ? poDatabaseConfig.roles : [poDatabaseConfig.role];

		if (this.moDatabaseById.get(poDatabaseConfig.id)?.isInitialized) {
			console.warn(`${Store.C_LOG_ID}Database ${poDatabaseConfig.id} has already been initialized.`);
			return of(this.moDatabaseById.get(poDatabaseConfig.id));
		} else {
			console.debug(`${Store.C_LOG_ID}Initializing database ${poDatabaseConfig.id} (sync : ${leSyncType}).`);
			this.raiseStoreEvent(this.createInitDatabaseEvent(poDatabaseConfig.id, EStoreEventStatus.working));

			return this.getInitDatabaseObservable(leSyncType, poDatabaseConfig, poLoader, psLogin, psPassword).pipe(
				mergeMap(() => this.completeInitDatabase(poDatabaseConfig.id, true))
			);
		}
	}

	private prepareDatabase(poDatabase: Database): Observable<Database> {
		return defer(() => (poDatabase.hasDatabaseMeta ? this.getDatabaseMeta(poDatabase.id) : of(undefined))).pipe(
			tap((poDatabaseMeta?: IDatabaseMeta) => (poDatabase.meta = poDatabaseMeta)),
			mergeMap(() => {
				if (poDatabase.syncType === ESyncType.offlineFirst || poDatabase.syncType === ESyncType.replicateOnStart)
					return this.innerPrepareDatabase(poDatabase);
				else return of(true);
			}),
			map(() => poDatabase)
		);
	}

	private innerPrepareDatabase(poDatabase: Database): Observable<boolean> {
		return defer(() => this.getSyncMarkerAsync(poDatabase.id)).pipe(
			tap((poSyncMarker: IDatabaseSyncMarker) => (poDatabase.syncMarker = poSyncMarker)), // On initialise le marqueur de synchronisation de la base de données.
			mergeMap((poSyncMarker: IDatabaseSyncMarker) => {
				// Retrocompat
				return poDatabase.isLocalInstanceEmpty().pipe(
					mergeMap((pbIsEmpty: boolean) => {
						// Si la base a été répliquée et que on est dans un cas de mise à jour de retrocompatibilité, on la considère initialisée.
						if (
							!pbIsEmpty &&
							poSyncMarker.fromServer &&
							ConfigData.appInfo.previousAppVersion === Version.C_UNKNOWN_VERSION
						)
							return poDatabase.markLocalInstanceAsFirstReplicated();
						else if (pbIsEmpty)
							// Sinon si elle est vide on force le fait qu'elle n'ai pas eu de première init.
							return poDatabase.markLocalInstanceAsNotFirstReplicated();
						else return of(true);
					})
				);
			})
		);
	}

	/** Retourne l'observable permettant d'initialiser une base de données en fonction de son type de synchro.
	 * @param peSyncType Type de synchro de la base de données.
	 * @param poDbConfig Configurations de la base de données.
	 * @param poLoader Indicateur de chargement.
	 * @param psLogin Login, optionnel.
	 * @param psPassword Mot de passe, optionnel.
	 * @throws
	 * - `UnknownSyncTypeError` si type de synchro inconnu.
	 * -
	 */
	private getInitDatabaseObservable(
		peSyncType: ESyncType,
		poDbConfig: IDatabaseConfig,
		poLoader: Loader,
		psLogin?: string,
		psPassword?: string
	): Observable<Database> {
		switch (peSyncType) {
			case ESyncType.fillIfEmpty:
				return this.initDatabaseFillIfEmpty(poDbConfig, psLogin, psPassword);

			case ESyncType.offlineFirst:
				return this.initDatabaseOfflineFirst(poDbConfig, poLoader, psLogin, psPassword);

			case ESyncType.remote:
				return this.initDatabaseRemote(poDbConfig, psLogin, psPassword);

			case ESyncType.replicateOnStart:
				return this.initDatabaseReplicateOnStart(poDbConfig, poLoader, psLogin, psPassword);

			case ESyncType.none:
				return this.initDatabaseNone(poDbConfig);

			case ESyncType.manual:
				return this.initDatabaseManualReplication(poDbConfig, psLogin, psPassword);

			default:
				return throwError(() => new UnknownSyncTypeError(peSyncType));
		}
	}

	/** Lance la réplication d'une base de données. DOIT S'EXECUTER après que la base soit initialisée. */
	public initReplication(
		poDatabaseConfig: IDatabaseConfig,
		psLogin?: string,
		psPassword?: string
	): Observable<Database> {
		const leSyncType: ESyncType = this.extractSyncTypeFromConfig(poDatabaseConfig);

		console.debug(`${Store.C_LOG_ID}Lancement d'une réplication pour la base de donnée "${poDatabaseConfig.id}".`);

		switch (leSyncType) {
			case ESyncType.replicateOnStart:
				return this.replicateDatabaseReplicateOnStart(poDatabaseConfig, psLogin, psPassword);

			case ESyncType.offlineFirst:
			case ESyncType.manual:
			case ESyncType.none:
			case ESyncType.uploadOnly:
			case ESyncType.remote:
			case ESyncType.fillIfEmpty:
				console.warn(
					`${Store.C_LOG_ID}La réplication doit se faire pendant l'initialisation pour la sync de type ${leSyncType}. La base "${poDatabaseConfig.id}" risque de ne pas être à jour.`
				);
				return of(this.moDatabaseById.get(poDatabaseConfig.id));
			default:
				throw new Error(`Unkown sync type : ${leSyncType}.`);
		}
	}

	/** Retourne le type de synchronisation pour la base de données passée en paramètre. */
	private extractSyncTypeFromConfig(poDatabaseConfig: IDatabaseConfig): ESyncType {
		let leSyncType: ESyncType = ESyncType.none;

		if (poDatabaseConfig) {
			if (poDatabaseConfig.syncType) leSyncType = poDatabaseConfig.syncType;
			else if (ArrayHelper.hasElements(poDatabaseConfig.syncTypes)) {
				poDatabaseConfig.syncTypes.forEach((poSyncTypeByPlatform: ISyncType) => {
					if (poSyncTypeByPlatform.platforms.indexOf(ConfigData.appInfo.platform) >= 0)
						leSyncType = poSyncTypeByPlatform.syncType;
				});
			}
		} else console.warn(`${Store.C_LOG_ID}Le type de synchronisation par défaut à été retourné.`);

		return leSyncType;
	}

	/** Initialise une base de données au comportement "fillIfEmpty" (la remplit uniquement si elle est vide).
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private initDatabaseFillIfEmpty(
		poDatabaseConfig: IDatabaseConfig,
		psLogin: string,
		psPassword: string
	): Observable<Database> {
		return defer(() => this.createLocalDatabaseAsync(poDatabaseConfig)).pipe(
			mergeMap((poDatabase: Database) => this.prepareDatabase(poDatabase)),
			mergeMap((poDatabase: Database) => {
				return poDatabase.isLocalInstanceEmpty().pipe(
					mergeMap((pbIsEmpty: boolean) => {
						if (pbIsEmpty) {
							this.logInitializingDatabase(poDatabaseConfig);
							return this.fillLocalDatabase(poDatabaseConfig.id, psLogin, psPassword);
						} else return of({} as IStoreReplicationResponse);
					}),
					catchError((poError) => {
						this.raiseStoreEvent(this.createInitDatabaseEvent(poDatabaseConfig.id, EStoreEventStatus.failed));
						return throwError(() => poError);
					}),
					map(() => poDatabase)
				);
			})
		);
	}

	private logInitializingDatabase(poDatabaseConfig: IDatabaseConfig): void {
		this.isvcLogger.action(Store.C_LOG_ID, `Initializing database ${poDatabaseConfig.id}.`, ELogActionId.storeInitDb, {
			databaseId: poDatabaseConfig.id
		});
	}

	/** Initialise une base de données qu'on réplique manuellement (conversations notamment).
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private initDatabaseManualReplication(
		poDatabaseConfig: IDatabaseConfig,
		psLogin: string,
		psPassword: string
	): Observable<Database> {
		return defer(() =>
			// On initialise également la base remote pour permettre la réplication manuelle (comme dans le cas de la base de conversation).
			this.createLocalAndRemoteDatabaseAsync(poDatabaseConfig, psLogin, psPassword)
		).pipe(mergeMap((poDatabase: Database) => this.prepareDatabase(poDatabase)));
	}

	/** Initialise une base de données locale, qu'on ne synchronise pas.
	 * @param poDatabaseConfig Configurations de la base de données.
	 */
	private initDatabaseNone(poDatabaseConfig: IDatabaseConfig): Observable<Database> {
		return defer(() => this.createLocalDatabaseAsync(poDatabaseConfig)).pipe(
			mergeMap((poDatabase: Database) => this.prepareDatabase(poDatabase))
		);
	}

	/** Initialise une base de données au comportement "offlineFirst".
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param poLoader Indicateur de chargement.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private initDatabaseOfflineFirst(
		poDatabaseConfig: IDatabaseConfig,
		poLoader: Loader,
		psLogin: string,
		psPassword: string
	): Observable<Database> {
		return defer(() => this.createLocalAndRemoteDatabaseAsync(poDatabaseConfig, psLogin, psPassword)).pipe(
			mergeMap((poDatabase: Database) => this.prepareDatabase(poDatabase)),
			mergeMap((poDatabase: Database) => {
				return poDatabase.isLocalInstanceNew().pipe(
					mergeMap((pbIsDatabaseNew: boolean) => {
						return this.isvcNetwork.asyncIsNetworkReliable().pipe(
							map((pbHasNetwork: boolean) => {
								if (pbIsDatabaseNew && !pbHasNetwork)
									// Base de données jamais initialisée et il n'y a pas d'internet.
									throw new FirstInitFailedError(Store.C_NETWORK_NEEDED_FOR_FIRST_LAUNCH);
							})
						);
					}),
					mergeMap(() => this.canReplicationBeDeferred(poDatabase)),
					mergeMap((pbCanReplicationBeDeferred: boolean) => {
						if (pbCanReplicationBeDeferred) {
							// Si la réplication peut être asynchrone, on lance un événement.
							console.debug(
								`${Store.C_LOG_ID}Base de données "${poDatabaseConfig.id}" initialisée mais sa réplication sera différée.`
							);
							return of(poDatabase);
						} else {
							// Sinon, on réplique la base tout de suite.
							console.debug(
								`${Store.C_LOG_ID}Base de données "${poDatabaseConfig.id}" initialisée, réplication non différée.`
							);
							this.logInitializingDatabase(poDatabaseConfig);
							return this.installDump(poDatabase, poLoader, () =>
								this.replicateDatabaseOfflineFirstWithNetwork(poDatabaseConfig, poLoader)
							);
						}
					})
				);
			}),
			tap(() =>
				// On lance l'événement qui déclenche l'ajout de la tâche qui va répliquer les données du serveur vers la base locale.
				this.raiseStoreEvent(
					this.createInitDatabaseEvent(poDatabaseConfig.id, EStoreEventStatus.initToLocalReplication)
				)
			)
		);
	}

	public getProgressPercentageString(poSynchronizationEvent?: ISynchronizationEvent): string {
		if (!poSynchronizationEvent) return "0%";
		else if (poSynchronizationEvent.loaded === 0 && poSynchronizationEvent.total === 0)
			// On est dans le cas d'une base à jour
			return "100%";

		const lnPercentage: number = (poSynchronizationEvent.loaded / poSynchronizationEvent.total) * 100;

		return `${lnPercentage >= 100 ? 100 : lnPercentage.toFixed(1)}%`;
	}

	/** Remonte les données en attente sur le serveur, puis détruit les bases locales et télécharge/installe un dump des bases de données en paramètres.
	 * @param paDatabases Tableau des bases de données d'espace de travail qu'il faut "optimiser".
	 * @returns Un résumé du nombre de bases optimisées.
	 * @throws
	 * - `NoOnlineReliableNetworkError` si pas de connexion internet,
	 * - `NoDatabaseLocalInstanceError` si pas d'instance locale pour une base de données,
	 * - `NoDatabaseRemoteInstanceError` si pas d'instance distante pour une base de données,
	 * - `IPouchDBFailedToFetchError`, si une erreur pouchDb survient (à priori tout le temps "Failed to fetch").
	 * - autre erreur.
	 */
	public async resetDatabasesAsync(paDatabases: Database[]): Promise<IResetDatabasesResult> {
		const loResult: IResetDatabasesResult = {
			totalCount: 0,
			successCount: 0,
			failedCount: 0
		};

		if (!ArrayHelper.hasElements(paDatabases)) return loResult;

		if (!this.isvcFlag.getFlagValue(ENetworkFlag.isOnlineReliable)) throw new NoOnlineReliableNetworkError();

		let loLoader: Loader | undefined;

		try {
			loLoader = await this.isvcLoading.create();
			await loLoader.present();

			// On lève le flag qui arrête le BTS afin de ne pas avoir de réplication d'une base de données en arrière plan qui peut casser la réinitialisation.
			this.isvcFlag.setFlagValue(EFlag.stopBackgroundTaskService, true);

			for (let lnIndex = 0; lnIndex < paDatabases.length; ++lnIndex) {
				await this.isvcBackups.backupDatabaseAsync(paDatabases[lnIndex]);
				await firstValueFrom(this.resetWorkspaceDatabase(paDatabases[lnIndex], lnIndex, paDatabases.length, loLoader));
				loResult.totalCount++;
				loResult.successCount++;
			}

			return loResult;
		} finally {
			loLoader?.dismiss();
		}
	}

	/** Réinitialise une base de données d'espace de travail et la retourne,
	 * `undefined` si elle n'a pas pu être réinitialisée et qu'il n'y a pas eu d'erreur (pas l'instance locale notamment).
	 * @param poCurrentDatabase Base de données d'espace de travail à réinitialiser.
	 * @param poLoader Loader pour suivre l'avancement de la réinitialisation.
	 * @returns
	 * - la base de données réinitialisée,
	 * - `NoDatabaseLocalInstanceError` si pas d'instance locale pour la base de données,
	 * - `NoDatabaseRemoteInstanceError` si pas d'instance distante pour la base de données,
	 * - autre erreur.
	 */
	private resetWorkspaceDatabase(poCurrentDatabase: Database, poLoader: Loader): Observable<Database>;
	/** Réinitialise une base de données d'espace de travail et la retourne,
	 * `undefined` si elle n'a pas pu être réinitialisée et qu'il n'y a pas eu d'erreur (pas l'instance locale notamment).
	 * @param poCurrentDatabase Base de données d'espace de travail à réinitialiser.
	 * @param pnDatabaseIndex Index de la base de données d'espace de travail si on est dans un processus de réinitialisations multiples (pour mettre un compteur sur le loader).
	 * @param pnDatabasesLength Nombre de base de données d'espace de travail total à réinitialiser si on est dans un processus de réinitialisations multiples.
	 * @param poLoader Loader pour suivre l'avancement de la réinitialisation.
	 * @returns
	 * - la base de données réinitialisée,
	 * - `NoDatabaseLocalInstanceError` si pas d'instance locale pour la base de données,
	 * - `NoDatabaseRemoteInstanceError` si pas d'instance distante pour la base de données,
	 * - autre erreur.
	 */
	private resetWorkspaceDatabase(
		poCurrentDatabase: Database,
		pnDatabaseIndex: number,
		pnDatabasesLength: number,
		poLoader: Loader
	): Observable<Database>;
	@LogAction<Parameters<Store["resetWorkspaceDatabase"] | any>, ReturnType<Store["resetWorkspaceDatabase"]>>({
		actionId: ELogActionId.resetDatabase,
		successMessage: (poDatabaseResult: Database) =>
			`La réinitialisation de la base de données '${poDatabaseResult.id}' a réussi.`,
		errorMessage: (_, poDatabase: Database) => `La réinitialisation de la base de données '${poDatabase.id}' a échoué.`,
		dataBuilder: (_, __, poDatabaseParam: Database) => {
			return { databaseId: poDatabaseParam.id };
		}
	})
	private resetWorkspaceDatabase(
		poCurrentDatabase: Database,
		poDatabaseIndexOrLoader: number | Loader,
		pnDatabasesLength?: number,
		poLoader?: Loader
	): Observable<Database> {
		if (!poCurrentDatabase.hasLocalInstance()) {
			console.warn(
				`${Store.C_LOG_ID}Reset database '${poCurrentDatabase.id}' skipped because no local instance found.`
			);
			return of(poCurrentDatabase);
		} else if (!poCurrentDatabase.hasRemoteInstance()) {
			console.warn(
				`${Store.C_LOG_ID}Reset database '${poCurrentDatabase.id}' skipped because no remote instance found.`
			);
			return of(poCurrentDatabase);
		} else {
			return defer(() => this.getViewDocsAsync(poCurrentDatabase.id)).pipe(
				mergeMap((paViews: IDesignDocument[]) => this.clearDatabaseIndexesAsync(poCurrentDatabase, paViews)),
				mergeMap((poDatabase: Database) =>
					this.innerResetWorkspaceDatabase(
						poDatabase,
						poDatabaseIndexOrLoader,
						NumberHelper.isValidStrictPositive(pnDatabasesLength) ? pnDatabasesLength : 1,
						poLoader
					)
				),
				mergeMap((poDatabase: Database) => this.isvcChangeTracker.removeAsync(poDatabase.id).then(() => poDatabase))
			);
		}
	}

	private async clearDatabaseIndexesAsync(poDatabase: Database, paViews: IDesignDocument[]): Promise<Database> {
		for (let lnIndex = 0; lnIndex < paViews.length; ++lnIndex) {
			const loDesignDoc: IDesignDocument = paViews[lnIndex];
			for (const lsKey in loDesignDoc.views) {
				try {
					await this.isvcMobileIndexor.removeAsync(poDatabase.id, loDesignDoc, lsKey);
				} catch (poError) {
					// en cas d'erreur lors de la suppression de l'index, on ne fait rien
				}
			}
		}
		return poDatabase;
	}

	private getViewDocsAsync(psDatabaseId: string): Promise<IDesignDocument[]> {
		return firstValueFrom(
			this.get<IDesignDocument>({
				databaseId: psDatabaseId,
				allowDesign: true,
				viewParams: {
					startkey: EPrefix.design,
					endkey: `${EPrefix.design}${Store.C_ANYTHING_CODE_ASCII}`,
					include_docs: true
				}
			})
		);
	}

	/** Réinitialise une base de données d'espace de travail et la retourne,
	 * `undefined` si elle n'a pas pu être réinitialisée et qu'il n'y a pas eu d'erreur (pas l'instance locale notamment).
	 * @param poDatabase Base de données de l'espace de travail à réinitialiser.
	 * @param poDatabaseIndexOrLoader Index de la base de données de l'espace de travail si on est dans un processus de réinitialisations multiples
	 * (pour mettre un compteur sur le loader) ou loader pour suivre l'avancement de la réinitialisation.
	 * @param pnDatabasesLength Nombre de base de données d'espaces de travail total à réinitialiser si on est dans un processus de réinitialisations multiples.
	 * @param poLoader Loader pour suivre l'avancement de la réinitialisation.
	 */
	private innerResetWorkspaceDatabase(
		poDatabase: Database,
		poDatabaseIndexOrLoader: number | Loader,
		pnDatabasesLength?: number,
		poLoader?: Loader
	): Observable<Database> {
		const lsConsoleMessageBase = `${Store.C_LOG_ID}Reset workspace database '${poDatabase.id}'`;
		let lnDatabaseIndex: number;
		let loLoader: Loader;

		if (typeof poDatabaseIndexOrLoader === "number") {
			lnDatabaseIndex = poDatabaseIndexOrLoader;
			loLoader = poLoader;
		} else {
			lnDatabaseIndex = 0;
			loLoader = poDatabaseIndexOrLoader;
		}

		console.debug(`${lsConsoleMessageBase} ongoing.`);
		loLoader.text = `Optimisation de l'espace de travail${pnDatabasesLength === 1 ? "" : ` ${lnDatabaseIndex + 1}/${pnDatabasesLength ?? 1}`
			} ...`;

		return this.replicateToServer(poDatabase.id).pipe(
			mergeMap((_) => this.loadPrebuild$(poDatabase, loLoader)),
			tap((_) => console.debug(`${lsConsoleMessageBase} complete.`)),
			catchError((poError) => {
				console.error(`${lsConsoleMessageBase} failed, trying to replicate.`, poError);
				console.debug(`${lsConsoleMessageBase} ongoing.`);

				return this.replicateToLocal(poDatabase.id).pipe(
					tap(
						(_) => console.debug(`${lsConsoleMessageBase} complete.`),
						(poReplicateError) => console.error(`${lsConsoleMessageBase} failed`, poReplicateError)
					),
					mapTo(poDatabase)
				);
			})
		);
	}

	/** Récupère et prépare la pré-construction d'une base.
	 * @param poDatabase
	 * @param poLoader Indicateur de chargement.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private loadPrebuild$(poDatabase: Database, poLoader: Loader): Observable<Database> {
		const lsDatabasePrebuildDirectory: string = this.getDatabasePrebuildDirectory(poDatabase);
		const loPerformanceManager = new PerformanceManager();
		const lsBaseMessage: string = poLoader.text;

		// On télécharge le zip et on dézipe.
		return defer(() => {
			loPerformanceManager.markStart();
			return this.prepareNewPrebuildAsync(poLoader, poDatabase, lsDatabasePrebuildDirectory);
		}).pipe(
			tap((_) =>
				console.debug(
					`${Store.C_LOG_ID}Prebuild loading time of database ${poDatabase.id} : ${loPerformanceManager
						.markEnd()
						.measure()}ms.`
				)
			),
			mergeMap((poDumpMeta?: PouchDB.Core.DatabaseInfo) => {
				poLoader.text = `${lsBaseMessage}</br>Finalisation de la base de données`;

				return this.markSynchro(
					poDatabase,
					poDatabase.getRemoteInstance()?.name, // Le dump provenant du serveur, on simule un marquage depuis le serveur.
					poDatabase.getLocalInstance()?.name,
					{ last_seq: poDumpMeta?.update_seq, end_time: new Date().toISOString() } as any
				);
			}),
			mergeMap((_) => poDatabase.markLocalInstanceAsFirstReplicatedAsync()),
			mergeMap((_) =>
				this.saveLastLocalToServerReplicationModeAsync(poDatabase.id, poDatabase.localToServerReplicationMode)
			),
			mergeMap(() => {
				poLoader.text = `${lsBaseMessage}</br>Synchronisation 0%`;
				// On synchronise avec la base distante.
				return this.replicateToLocal(
					poDatabase.id,
					undefined,
					undefined,
					undefined,
					(poEvent: ISynchronizationEvent) =>
						(poLoader.text = `${lsBaseMessage}</br>Synchronisation : ${this.getProgressPercentageString(poEvent)}`)
				);
			}),
			tap((_) =>
				this.isvcEvents.raiseEvent(new DumpApplicationEvent({ database: poDatabase, status: EDumpStatus.finished }))
			),
			// On nettoie le répertoire de dump après passage.
			map(() => poDatabase),
			tap(
				(_) => (poLoader.text = lsBaseMessage),
				(_) => (poLoader.text = lsBaseMessage)
			)
		);
	}

	/** Télécharge et dézippe le prebuild dans un dossier temporaire.
	 * @param poLoader
	 * @param poDatabase
	 * @param psDatabasePrebuildDirectory
	 */
	private async prepareNewPrebuildAsync(
		poLoader: Loader,
		poDatabase: Database,
		psDatabasePrebuildDirectory: string
	): Promise<PouchDB.Core.DatabaseInfo | undefined> {
		const lsTmpDirectoryName: string = await this.installNewPrebuildAsync(
			poLoader,
			psDatabasePrebuildDirectory,
			poDatabase
		);
		const loMeta: PouchDB.Core.DatabaseInfo | undefined = await this.loadPrebuildMetaAsync(
			psDatabasePrebuildDirectory,
			lsTmpDirectoryName
		);

		await poDatabase.closeLocalInstanceAsync();

		const laDatabaseTmpDirectoryPath = `${psDatabasePrebuildDirectory}${lsTmpDirectoryName}/`;
		const laEntries: FileInfo[] = await this.isvcFilesystem.listDirectoryEntriesAsync(
			laDatabaseTmpDirectoryPath,
			LocalDatabaseProviderService.mobileAppDatabasesDirectory
		);

		for (let lnIndex = 0; lnIndex < laEntries.length; ++lnIndex) {
			await this.prepareLocalDbFileAsync(poDatabase, laEntries[lnIndex], laDatabaseTmpDirectoryPath);
		}

		await this.isvcFilesystem.removeAsync(
			psDatabasePrebuildDirectory,
			LocalDatabaseProviderService.mobileAppDatabasesDirectory
		);
		await this.createLocalDbMetaFileAsync(poDatabase);

		const loLocalDatabaseConfig: ILocalDatabaseConfig = await this.innerGetDatabaseConfig_localPouchConfigAsync(
			poDatabase.id,
			ConfigData.databases.find((poDbConfig: IDatabaseConfig) => poDbConfig.id === poDatabase.id)?.revs_limit
		);

		poDatabase.createLocalInstance(poDatabase.id, loLocalDatabaseConfig);
		this.initDatabaseLocalStatus(poDatabase);

		return loMeta;
	}

	private async installNewPrebuildAsync(
		poLoader: Loader,
		psDatabasePrebuildDirectory: string,
		poDatabase: Database
	): Promise<string> {
		const lsBaseMessage: string = poLoader.text;
		const lsZipName: string = this.getDatabasePrebuildZipName(poDatabase);
		const lsDirectoryPath = await this.isvcFilesystem.getFileUriAsync(
			psDatabasePrebuildDirectory,
			LocalDatabaseProviderService.mobileAppDatabasesDirectory
		);
		const lsZipPath = `${lsDirectoryPath}${lsZipName}`;

		await this.downloadPrebuildAsync(poLoader, lsBaseMessage, poDatabase, lsZipPath);

		const lsTmpDirectoryName = `${poDatabase.id}_tmp`;
		const lsTmpDirectory = `${lsDirectoryPath}${lsTmpDirectoryName}/`;
		await this.unzipPrebuildAsync(poLoader, lsBaseMessage, lsZipPath, lsTmpDirectory);

		return lsTmpDirectoryName;
	}

	private async unzipPrebuildAsync(
		poLoader: Loader,
		lsBaseMessage: string,
		lsZipPath: string,
		lsTmpDirectory: string
	): Promise<void> {
		poLoader.text = `${lsBaseMessage}</br>Extraction : 0%`;
		await this.isvcZip.extractAsync(
			lsZipPath,
			lsTmpDirectory,
			(poEvent: ProgressEvent) =>
				(poLoader.text = `${lsBaseMessage}</br>Extraction : ${this.getProgressPercentageString(poEvent)}`)
		);
	}

	private async downloadPrebuildAsync(
		poLoader: Loader,
		psBaseMessage: string,
		poDatabase: Database,
		psZipPath: string
	): Promise<void> {
		poLoader.text = `${psBaseMessage}</br>Téléchargement : 0%`;
		await this.isvcTransfert.download(
			`${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}prebuild/${poDatabase.id}`,
			psZipPath,
			{
				"api-key": ConfigData.environment.API_KEY ?? "",
				appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
				accept: EXTENSIONS_AND_MIME_TYPES.zip.mimeType,
				Token: ConfigData.authentication.token
			},
			(poEvent: ProgressEvent<EventTarget>) =>
				(poLoader.text = `${psBaseMessage}</br>Téléchargement : ${this.getProgressPercentageString(poEvent)}`)
		);
	}

	private async loadPrebuildMetaAsync(
		psDatabasePrebuildDirectory: string,
		psTmpDirectoryName: string
	): Promise<PouchDB.Core.DatabaseInfo | undefined> {
		const lsMetaPath = `${psDatabasePrebuildDirectory}${psTmpDirectoryName}/${Store.C_META_DOC_NAME}`;
		const loMeta: PouchDB.Core.DatabaseInfo | undefined = JSON.parse(
			await this.isvcFilesystem.readFileAsTextAsync(
				lsMetaPath,
				LocalDatabaseProviderService.mobileAppDatabasesDirectory
			)
		);
		await this.isvcFilesystem.removeFileAsync(lsMetaPath, LocalDatabaseProviderService.mobileAppDatabasesDirectory);
		return loMeta;
	}

	private async prepareLocalDbFileAsync(
		poDatabase: Database,
		poEntry: FileInfo,
		paDatabaseTmpDirectoryPath: string
	): Promise<void> {
		const lsDatabaseBaseName = `${this.isvcLocalDatabaseProvider.mobileAppDatabasesPath}${poEntry.name}`;
		const lsDatabaseTargetDirectory = `${lsDatabaseBaseName}${poEntry.name === poDatabase.id ? this.sqliteSuffixDatabaseName : ""}`;
		// Il peut y avoir plusieurs chemins possibles sur android suivant l'adapteur sqlite.
		const laDatabasePaths: string[] = [lsDatabaseTargetDirectory];
		if (this.isvcPlatform.isAndroid) laDatabasePaths.push(lsDatabaseBaseName);

		for (let lnIndex = 0; lnIndex < laDatabasePaths.length; ++lnIndex) {
			const lsDatabasePath: string = laDatabasePaths[lnIndex];

			if (
				await this.isvcFilesystem.existsAsync(lsDatabasePath, LocalDatabaseProviderService.mobileAppDatabasesDirectory)
			) {
				await this.isvcFilesystem.removeFileAsync(
					lsDatabasePath,
					LocalDatabaseProviderService.mobileAppDatabasesDirectory
				);
			}
		}

		await this.isvcFilesystem.renameAsync(
			`${paDatabaseTmpDirectoryPath}${poEntry.name}`,
			lsDatabaseTargetDirectory,
			LocalDatabaseProviderService.mobileAppDatabasesDirectory
		);
	}

	private async createLocalDbMetaFileAsync(poDatabase: Database) {
		const loDbFileMeta: ILocalDatabaseFileMeta = {
			mode: this.isvcPlatform.isAndroid ? ELocalDatabaseMode.android : ELocalDatabaseMode.ios
		};

		await this.isvcFilesystem.createFileAsync(
			this.getLocalDatabaseFileMetaPath(poDatabase.id),
			JSON.stringify(loDbFileMeta),
			LocalDatabaseProviderService.mobileAppDatabasesDirectory,
			true
		);
	}

	/** Récupère le répertoire de sauvegarde local des dump.
	 * @param poDatabase
	 */
	private getDatabasePrebuildDirectory(poDatabase: Database): string {
		switch (this.isvcPlatform.platform) {
			case EPlatform.ios:
			case EPlatform.android:
				return `${poDatabase.id}/`;
			default:
				return "";
		}
	}

	/** Récupère le nom du zip contenant la base de données prebuilt.
	 * @param poDatabase
	 */
	private getDatabasePrebuildZipName(poDatabase: Database): string {
		return `${poDatabase.id}_prebuild.zip`;
	}

	/** Détruit puis recrée une base de données locale et retourne l'instance de `Database` associée.
	 * @param poDatabase Base de données à détruire puis recréer.
	 */
	public async destroyAndCreateLocalInstanceDatabaseAsync(poDatabase: Database): Promise<Database> {
		const loLocalDatabaseConfig: ILocalDatabaseConfig = await this.innerGetDatabaseConfig_localPouchConfigAsync(
			poDatabase.id,
			ConfigData.databases.find((poDbConfig: IDatabaseConfig) => poDbConfig.id === poDatabase.id)?.revs_limit
		);

		await firstValueFrom(poDatabase.destroyLocalInstance());

		poDatabase.createLocalInstance(poDatabase.id, loLocalDatabaseConfig);

		this.initDatabaseLocalStatus(poDatabase);

		return poDatabase;
	}

	/** Crée un objet événement générique pour l'initialisation d'une base de données.
	 * @param psId Identifiant de la base de donneés à initialiser.
	 * @param peStatus Status de l'événement du store.
	 * @param peStoreEventType Type de l'événement du store, "init" par défaut.
	 */
	private createInitDatabaseEvent(
		psId: string,
		peStatus: EStoreEventStatus,
		peStoreEventType: EStoreEventType = EStoreEventType.Init,
		psLogin?: string,
		psPassword?: string
	): IStoreEvent {
		return {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				databaseId: psId,
				storeEventType: peStoreEventType,
				status: peStatus,
				login: psLogin,
				password: psPassword
			}
		};
	}

	/** Initialise une base de données au comportement "offlineFirst" si on n'a pas de réseau.
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param poLoader Indicateur de chargement.
	 */
	private replicateDatabaseOfflineFirstWithNetwork(
		poDatabaseConfig: IDatabaseConfig,
		poLoader?: Loader
	): Observable<Database> {
		const loReplicationOptions: IStoreReplicationOptions = { live: false, retry: false, batch_size: 200 };
		const lsBaseText: string = poLoader?.text;
		const lfReplicateToServerOnProgress: IOnProgressFunction | undefined = this.getReplicateToServerOnProgressFunction(
			lsBaseText,
			poLoader
		);

		return this.replicateToServer(poDatabaseConfig.id, loReplicationOptions, lfReplicateToServerOnProgress).pipe(
			tap((_) => {
				if (poLoader) poLoader.text = lsBaseText;
			}),
			mergeMap((_) => {
				return this.replicateToLocal(
					poDatabaseConfig.id,
					loReplicationOptions,
					undefined,
					undefined,
					this.getReplicateToLocalOnProgressFunction(lsBaseText, poLoader)
				);
			}),
			tapError((_) =>
				this.raiseStoreEvent(
					this.createInitDatabaseEvent(poDatabaseConfig.id, EStoreEventStatus.failed, EStoreEventType.Synchro)
				)
			),
			map((_) => this.getDatabaseById(poDatabaseConfig.id)),
			tapComplete(() => {
				if (poLoader) poLoader.text = lsBaseText;
			})
		);
	}

	private getReplicateToServerOnProgressFunction(
		psBaseText: string,
		poLoader?: Loader
	): IOnProgressFunction | undefined {
		return poLoader
			? (poEvent: ISynchronizationEvent) =>
			(poLoader.text = `${psBaseText}</br>Envoi des données vers le serveur : ${this.getProgressPercentageString(
				poEvent
			)}`)
			: undefined;
	}

	private getReplicateToLocalOnProgressFunction(
		psBaseText: string,
		poLoader?: Loader
	): IOnProgressFunction | undefined {
		return poLoader
			? (poEvent: ISynchronizationEvent) =>
			(poLoader.text = `${psBaseText}</br>Réception des données depuis le serveur : ${this.getProgressPercentageString(
				poEvent
			)}`)
			: undefined;
	}

	/** Initialise une base de données au comportement "remote" (distante uniquement).
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private initDatabaseRemote(
		poDatabaseConfig: IDatabaseConfig,
		psLogin: string,
		psPassword: string
	): Observable<Database> {
		return defer(() => this.createRemoteDatabaseAsync(poDatabaseConfig, psLogin, psPassword)).pipe(
			mergeMap((poDatabase: Database) => this.prepareDatabase(poDatabase))
		);
	}

	private initRemoteChanges(poDatabase: Database): void {
		if (!poDatabase.remoteChangesInitialized && this.hasToInitRemoteChanges(poDatabase)) {
			let loQueuer: Queuer<PouchDB.Core.ChangesResponseChange<IStoreDocument>[]>;

			poDatabase.canReplicate$
				.pipe(
					switchMap((pbCanReplicate: boolean) => {
						if (loQueuer) loQueuer.end();

						if (pbCanReplicate && poDatabase.hasRemoteInstance()) {
							loQueuer = this.createRemoteChangesQueuer(poDatabase);

							return loQueuer.start().pipe(afterSubscribe(() => loQueuer.exec()));
						}

						return EMPTY;
					}),
					finalize(() => loQueuer?.end())
				)
				.subscribe();

			poDatabase.remoteChangesInitialized = true;
		}
	}

	private hasToInitRemoteChanges(poDatabase: Database): boolean {
		return poDatabase.syncType === ESyncType.remote;
	}

	private createRemoteChangesQueuer(
		poDatabase: Database
	): Queuer<PouchDB.Core.ChangesResponseChange<IStoreDocument>[]> {
		let lsLastSeq: string;

		// On crée une file d'attente pour les récupérations des changements du serveur.
		const loQueuer: Queuer<PouchDB.Core.ChangesResponseChange<IStoreDocument>[]> = new Queuer({
			thingToQueue: () => {
				console.debug(`${Store.C_LOG_ID}Getting remote changes for database ${poDatabase.id}...`);

				return defer(() => {
					if (StringHelper.isBlank(lsLastSeq)) return poDatabase.getLastSeqFromInstance("server"); // On récupère un numéro de séquence par défaut.
					return of(lsLastSeq);
				}).pipe(
					tap((psLastSeq: string) => (lsLastSeq = psLastSeq)),
					mergeMap(() => this.getChangesFromServer(poDatabase, lsLastSeq)),
					tap((paResponses: PouchDB.Core.ChangesResponseChange<IStoreDocument>[]) => {
						paResponses.forEach((poResponse: PouchDB.Core.ChangesResponseChange<IStoreDocument>) => {
							lsLastSeq = poResponse.seq as string;
							if (poResponse.doc) this.raiseRemoteChange(poResponse, poDatabase);
						});
					}),
					tapError((poError: any) =>
						console.error(
							`${Store.C_LOG_ID}Error while getting remote changes for database ${poDatabase.id} ended.`,
							poError
						)
					),
					finalize(() => {
						console.debug(`${Store.C_LOG_ID}Remote changes get for database ${poDatabase.id} ended.`);
						loQueuer.exec();
					})
				);
			},
			keepOnlyLastPending: true,
			minimumGapMs: Store.C_REMOTE_CHANGES_INTERVAL_MS
		});

		return loQueuer;
	}

	private raiseRemoteChange(poResponse: PouchDB.Core.ChangesResponseChange<IStoreDocument>, poDatabase: Database) {
		this.moRemoteChangesSubject.next({
			document: poResponse.doc,
			databaseId: poDatabase.id,
			changeType: this.getChangeTypeFromChange(poResponse.doc, poResponse.deleted),
			key: poResponse.id
		});
	}

	/** Initialise une base de données au comportement "replicateOnStart" (réplication après initialisation de la base uniquement).
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param poLoader Indicateur de chargement.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private initDatabaseReplicateOnStart(
		poDatabaseConfig: IDatabaseConfig,
		poLoader: Loader,
		psLogin: string,
		psPassword: string
	): Observable<Database> {
		return defer(() => this.createLocalAndRemoteDatabaseAsync(poDatabaseConfig, psLogin, psPassword)).pipe(
			mergeMap((poDatabase: Database) => this.prepareDatabase(poDatabase)),
			mergeMap((poDatabase: Database) => {
				return poDatabase.isLocalInstanceNew().pipe(
					mergeMap((pbIsDatabaseNew: boolean) => {
						return this.isvcNetwork.asyncIsNetworkReliable().pipe(
							map((pbHasNetwork: boolean) => {
								if (pbIsDatabaseNew && !pbHasNetwork) {
									// Il faut internet pour le premier lancement.
									console.error(
										`${Store.C_LOG_ID}Une connexion réseau est nécessaire lors de la première initialisation.`
									);
									throw new FirstInitFailedError(Store.C_NETWORK_NEEDED_FOR_FIRST_LAUNCH);
								}
							})
						);
					}),
					mergeMap(() => this.canReplicationBeDeferred(poDatabase)),
					mergeMap((pbCanReplcationBeDeferred: boolean) => {
						if (pbCanReplcationBeDeferred) {
							// Si la réplication peut être asynchrone, on lance un événement.
							console.debug(
								`${Store.C_LOG_ID}Base de données "${poDatabaseConfig.id}" initialisée mais sa réplication sera différée.`
							);
							this.raiseDatabaseReplicationEvent(poDatabaseConfig.id, psLogin, psPassword);
							return of(poDatabase);
						} else {
							// Sinon, on réplique la base tout de suite.
							console.debug(
								`${Store.C_LOG_ID}Base de données "${poDatabaseConfig.id}" initialisée, réplication non différée.`
							);
							this.logInitializingDatabase(poDatabaseConfig);
							return this.installDump(poDatabase, poLoader, () =>
								this.replicateDatabaseReplicateOnStart(poDatabaseConfig, psLogin, psPassword, poLoader)
							);
						}
					}),
					catchError((poError) =>
						poError.status === 401 // Appareil non autorisé.
							? throwError(() => new DeviceNotAuthorizedError())
							: this.onReplicateDatabaseReplicateOnStartFailed(poDatabase, poError)
					),
					finalize(() => poDatabase.removeRemoteInstance())
				);
			})
		);
	}

	/**
	 * @param poDatabase Base de données dont il faut installer le dump.
	 * @param poDatabaseConfig Configuration de la base de données à installer.
	 * @param poLoader Loader pour afficher l'avancement à l'utilisateur.
	 * @param errorFallback Gestion de l'erreur.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private installDump(
		poDatabase: Database,
		poLoader: Loader,
		errorFallback: () => Observable<Database>
	): Observable<Database> {
		return defer(() => {
			if (this.isvcPlatform.isMobileApp) return this.loadPrebuild$(poDatabase, poLoader);
			else return throwError(() => "Dump supporté sur application mobile uniquement."); // TODO En attente de gestion propre browser (download et unzip)
		}).pipe(
			// Si le téléchargement du dump n'a pas fonctionné, on lance une réplication classique.
			catchError((poError: any) => {
				console.warn(
					`${Store.C_LOG_ID}Erreur lors du téléchargement du dump de la base de données ${poDatabase.id}. Lancement du comportement en cas d'erreur.`,
					poError
				);
				return errorFallback();
			})
		);
	}

	/** Réplique une base de données "replicateOnStart" qui a déjà été initialisé.
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 * @param poLoader Indicateur de chargement.
	 */
	private replicateDatabaseReplicateOnStart(
		poDatabaseConfig: IDatabaseConfig,
		psLogin: string,
		psPassword: string,
		poLoader?: Loader
	): Observable<Database> {
		const lsBaseText: string = poLoader?.text;
		return this.fillLocalDatabase(
			poDatabaseConfig.id,
			psLogin,
			psPassword,
			poLoader
				? (poEvent: ISynchronizationEvent) =>
				(poLoader.text = `${lsBaseText}</br>Réception des données depuis le serveur : ${this.getProgressPercentageString(
					poEvent
				)}`)
				: undefined
		).pipe(
			mapTo(this.getDatabaseById(poDatabaseConfig.id)),
			tap(
				() => { },
				() => { },
				() => {
					if (poLoader) poLoader.text = lsBaseText;
				}
			)
		);
	}

	/** Gère le cas d'erreur d'un appareil non autorisé. Affiche un bouton pour envoyer un mail afin de se faire débloquer. */
	private handleUnauthorizedDevice(): Observable<void> {
		this.isvcLoading.dismissAll(); // L'UI de chargement ne doit pas être devant les boutons.

		return from(this.isvcMail.canSendMail()).pipe(
			// Si l'appareil peut envoyer des mails, on propose à l'utilisateur de le faire avec un bouton ; sinon on retourne l'erreur.
			mergeMap((pbCanSendMail: boolean) =>
				pbCanSendMail ? this.askUnlockDeviceMessage() : this.displaySendMailMessage()
			),
			mergeMap((pbUnlockStatus: IUiResponse<boolean>) => {
				if (pbUnlockStatus.response)
					// Si l'utilisateur clique sur le bouton de déverrouillage.
					return this.openUnlockDeviceModal();
				else {
					// L'utilisateur a cliqué sur le bouton "Réessayer".
					this.isvcLoading.present("Redémarrage ...");
					ApplicationService.reloadApp();
					return EMPTY;
				}
			}),
			mergeMap((pbResult: boolean) =>
				pbResult ? this.displayUnlockDeviceWaitingMessage() : this.displaySendMailMessage()
			)
		);
	}

	/** Affiche une modale demandant à l'utilisateur son nom et son prénom. */
	private openUnlockDeviceModal(): Observable<boolean> {
		const loModalOptions: ModalOptions = {
			component: UnlockDeviceModalComponent,
			backdropDismiss: false,
			showBackdrop: false,
			componentProps: { params: {} }
		};

		return from(this.ioModalCtrl.create(loModalOptions)).pipe(
			tap((poModal: HTMLIonModalElement) => poModal.present()),
			mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
			mergeMap((poValue: OverlayEventDetail<IUnlockDeviceResult>) =>
				poValue.data.cancel
					? of(false)
					: this.isvcDevicesSecurity.enrollDevice(poValue.data.firstname, poValue.data.lastname).pipe(mapTo(true))
			)
		);
	}

	/** Affichage un message d'erreur indiquant que l'appareil n'est pas autorisé.
	 * L'utilisateur peut cliquer sur un bouton pour déverrouiller l'appareil. Dans ce cas, la valeur de retour sera `true`.
	 * @returns `response.true` si l'utilisateur veut déverrouiller l'appareil, sinon `false`.
	 */
	private askUnlockDeviceMessage(): Observable<IUiResponse<boolean>> {
		this.isvcLoading.dismissAll();

		return this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				header: "Appareil non autorisé",
				message: "L'appareil n'est pas autorisé.",
				backdropDismiss: false,
				buttons: [
					{
						text: "Faire une demande d'autorisation",
						handler: () => {
							console.debug("CFG.S::Demande d'autorisation.");
							return UiMessageService.getTruthyResponse();
						}
					} as AlertButton,
					{
						text: "Réessayer",
						handler: () => UiMessageService.getFalsyResponse()
					} as AlertButton
				]
			})
		);
	}

	/** Affichage d'une modale à l'utilisateur en cas d'erreur lors d'un mail de déblocage par mail (ou annulation). */
	private displaySendMailMessage(): Observable<void> {
		return this.displayWaitingMessage(
			"Appareil non autorisé",
			`Votre appareil doit être autorisé pour accéder à ${ConfigData.appInfo.appName}. Contacter l'adresse <b>${ConfigData.appInfo.supportEmail}</b> pour autoriser votre appareil.`
		);
	}

	/** Affichage un message demandant à l'utilisateur d'attendre que son appareil soit autorisé avant d'accéder à l'application. */
	private displayUnlockDeviceWaitingMessage(): Observable<void> {
		return this.displayWaitingMessage(
			"Appareil en attente d'autorisation",
			`Vous recevrez un mail quand votre appareil sera autorisé à accéder à ${ConfigData.appInfo.appName}.`
		);
	}

	/** Affiche un message à l'utilisateur où il ne peut que quitter ou redémarrer l'application. */
	private displayWaitingMessage(psTitle: string, psMessage: string): Observable<void> {
		return this.isvcUiMessage
			.showAsyncMessage(
				new ShowMessageParamsPopup({
					header: psTitle,
					message: psMessage,
					backdropDismiss: false,
					buttons: [
						{ text: "Quitter", handler: () => ApplicationService.exitApp() } as AlertButton,
						{
							text: "Redémarrer",
							handler: () => {
								this.isvcLoading.present("Redémarrage ...");
								ApplicationService.reloadApp();
							}
						} as AlertButton
					]
				})
			)
			.pipe(mapTo(undefined));
	}

	/** Gère l'échec de réplication d'une base de données en mode "replicateOnStart".
	 * @param poDatabaseConfig Configuration de la base de données tombée en échec de réplication.
	 * @param poError Erreur survenue lors de la réplication.
	 */
	private onReplicateDatabaseReplicateOnStartFailed(poDatabase: Database, poError: any): Observable<Database> {
		return this.moDatabaseById
			.get(poDatabase.id)
			.isLocalInstanceEmpty()
			.pipe(
				mergeMap((pbIsEmpty: boolean) => {
					if (pbIsEmpty)
						// La base est vide => premier lancement de l'application, le chargement de la base de données a échoué.
						return this.throwDatabaseReplicationFailedError(poDatabase, poError);
					else {
						// Si la base n'est pas vide, on demande un chargement asynchrone.
						this.raiseDatabaseReplicationEvent(poDatabase.id);

						return this.canReplicationBeDeferred(this.moDatabaseById.get(poDatabase.id)).pipe(
							mergeMap((pbCanBeDeferred: boolean) => {
								if (!pbCanBeDeferred) {
									console.error(
										`${Store.C_LOG_ID}Base de données "${poDatabase.id}" initialisée vide, alors qu'elle doit être initialisée au démarrage.`
									);
									return throwError(() => new FirstInitFailedError(Store.C_NETWORK_NEEDED_FOR_FIRST_LAUNCH));
								} else {
									console.warn(
										`${Store.C_LOG_ID}Base de données ${poDatabase.id} non initialisée, réplication de la base différée.`
									);
									return of(poDatabase);
								}
							})
						);
					}
				})
			);
	}

	/** Lève une erreur de première initialisation.
	 * @param poDatabaseConfig Configuration de la base de données qui n'a pas pu s'initialiser.
	 * @param poError Erreur survenue.
	 */
	private throwDatabaseReplicationFailedError(poDatabaseConfig: IDatabaseConfig, poError: any): Observable<never> {
		console.error(
			`${Store.C_LOG_ID}Réplication de la base de données "${poDatabaseConfig.id}" échouée, mais nécessaire pour poursuivre :`,
			poError
		);

		this.raiseStoreEvent(this.createInitDatabaseEvent(poDatabaseConfig.id, EStoreEventStatus.failed));

		return throwError(() =>
			this.isFailedToFetchError(poError)
				? new NoDatabaseInternetConnectionError(poDatabaseConfig.id)
				: new FirstInitFailedError()
		);
	}

	/** Retourne `true` s'il s'agit d'une erreur Pouch "Failed to fetch" (pas internet à priori), `false` sinon.
	 * @param poError Erreur à déterminer s'il s'agit d'une erreur pouchDb "failed to fetch".
	 */
	private isFailedToFetchError(poError: any): boolean {
		return (poError as IPouchDBFailedToFetchError).message === "Failed to fetch";
	}

	/** Initialise les bases de données.
	 * @param paDatabaseConfigs Liste des configs des bases de données à initialiser.
	 */
	private initDatabases(paDatabaseConfigs: Array<IDatabaseConfig>, poLoader: Loader): Observable<Database[]> {
		if (ArrayHelper.hasElements(paDatabaseConfigs)) {
			const lsBaseText = "Initialisation de la base de données";
			return from(paDatabaseConfigs).pipe(
				concatMap((poDatabaseConfig: IDatabaseConfig, pnIndex: number) => {
					// Gestion de l'initialisation des bases.
					poLoader.text = `${lsBaseText} ${pnIndex + 1}/${paDatabaseConfigs.length}`;
					return this.initDatabase(poDatabaseConfig, poLoader).pipe(
						catchError((poError) => {
							console.error(`${Store.C_LOG_ID}${poDatabaseConfig.id} database initialization failed:`, poError);
							return throwError(() =>
								this.isFailedToFetchError(poError)
									? new NoDatabaseInternetConnectionError(poDatabaseConfig.id)
									: poError
							);
						})
					);
				}),
				toArray()
			);
		} // S'il n'y a pas de bdd à init alors on renvoie en succès.
		else return of([]);
	}

	/** Retourne `true` si la réplication de la base passée en paramètre peut être asynchrone.
	 * @param poDatabase Base de données à vérifier si elle peut être répliquée en différée.
	 */
	private canReplicationBeDeferred(poDatabase: Database): Observable<boolean> {
		return poDatabase.isLocalInstanceNew().pipe(
			map((pbDatabaseNew: boolean) => {
				// Si la base de données n'a jamais été initialisé, sa réplication ne peut pas être différée.
				if (pbDatabaseNew) return false;
				// La base de données est de type `offLineFirst` ou `replicateOnStart`. Seules les bases de ces types peuvent être différées.
				else
					return poDatabase.syncType === ESyncType.offlineFirst || poDatabase.syncType === ESyncType.replicateOnStart;
			})
		);
	}

	/** @implements */
	public initDynamicDatabases(): Observable<Array<Database>> {
		let loLoader: Loader;
		console.debug(`${Store.C_LOG_ID}Initializing dynamic databases ...`);

		return defer(() => {
			this.raiseStoreEvent(this.createPreparingInitDatabaseEvent()); // Lève un évènement pour permettre à d'autres composants d'agir sur les configuration avant l'initialisation.
			return from(this.isvcLoading.create("Initialisation des bases de données ...")); // Initialise les base de données de l'appli.
		}).pipe(
			tap((poLoader: Loader) => (loLoader = poLoader)),
			mergeMap((poLoader: Loader) => poLoader.present()),
			mergeMap((_) => this.initDatabases(ConfigData.databases, loLoader)),
			tap(
				(paDatabases: Database[]) => {
					loLoader.dismiss();
					this.raiseStoreEvent(this.createInitSuccessEvent(EStoreEventType.Init, paDatabases));
				},
				(_) => {
					loLoader?.dismiss();
					this.raiseStoreEvent(this.createInitErrorEvent(EStoreEventType.Init, ConfigData.databases));
				}
			),
			retryWhen((poError$: Observable<any>) => this.initDynamicDatabasesRetryStrategy(poError$)),
			catchError((poError) => this.initDynamicDatabasesError(poError))
		);
	}

	/** Initialise l'écoute et la gestion des changements locaux sur une base de données. */
	private initDatabaseLocalStatus(poDatabase: Database): void {
		this.moLocalChangesSubject
			.asObservable()
			.pipe(
				map((poChangeEvent: IChangeEvent<IStoreDocument>) => {
					return poChangeEvent.databaseId === poDatabase.id ? poDatabase : undefined;
				}),
				startWith(poDatabase),
				filter((poValue?: Database) => !!poValue && poValue.hasLocalInstance()),
				switchMap((_) => {
					if (poDatabase instanceof ChangeTrackedDatabase)
						return this.isvcChangeTracker
							.trackingStatus$(poDatabase.id)
							.pipe(tap((peTrackingStatus: ETrackingStatus) => poDatabase.setTrackingStatus(peTrackingStatus)));
					else
						return poDatabase
							.getLastSeqFromInstance("local")
							.pipe(tap((pnLastSeq: number) => (poDatabase.localLastSeq = pnLastSeq)));
				}),
				takeUntil(poDatabase.canReplicate$.pipe(filter((pbCanReplicate: boolean) => !pbCanReplicate)))
			)
			.subscribe();
	}

	private initDynamicDatabasesError(poError: any): Observable<never> {
		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				header: "Erreur",
				message:
					typeof poError === "string"
						? poError
						: "Une erreur lors de l'initialisation des bases de données est survenue. Impossible de continuer.",
				backdropDismiss: false,
				buttons: [
					{
						text: "Redémarrer",
						handler: () => {
							ApplicationService.reloadApp();
						}
					}
				]
			})
		);
		console.error(`${Store.C_LOG_ID}Dynamic databases initialization failed : `, poError);
		return throwError(() => poError);
	}

	/** Stratégie de réessaie en cas d'erreur dans la fonction `initDynamicDatabases`.\
	 * __Stratégie__: Si l'erreur est de type `FirstInitFailedError` (première initialisation) et qu'il n'y a pas de réseau,
	 * alors on affiche à l'utilisateur un bouton pour réessaie, s'il accepte, alors la fonction retourne `true`.
	 * sinon `false`.
	 */
	private initDynamicDatabasesRetryStrategy(poError$: Observable<any>): Observable<boolean> {
		return poError$.pipe(
			mergeMap((poError: any): Observable<boolean> => {
				if (poError instanceof FirstInitFailedError) return this.retryStrategyForFirstInitFailedError(poError);
				else if (poError instanceof NoDatabaseInternetConnectionError)
					return this.retryStrategyForNoDatabaseInternetConnectionError(poError);
				else return throwError(() => poError);
			})
		);
	}

	/** Retourne l'erreur `FirstInitFailedError` telle quelle si on a internet (erreur inattendue), affiche une popup d'échec d'initialisation sinon
	 * puis retourne l'action à réaliser (`true` pour réessayer, `false` pour quitter).
	 * @param poError Erreur lors d'une première initialisation.
	 */
	private retryStrategyForFirstInitFailedError(poError: FirstInitFailedError): Observable<boolean> {
		let loManageError$: Observable<IUiResponse<boolean>>;

		if (poError.message === Store.C_NETWORK_NEEDED_FOR_FIRST_LAUNCH)
			loManageError$ = this.showInitFailedBecauseOfNoInternetPopup(poError);
		else {
			loManageError$ = this.isvcNetwork.asyncIsNetworkReliable().pipe(
				mergeMap((pbHasNetwork: boolean): Observable<IUiResponse<boolean>> => {
					if (pbHasNetwork)
						// Erreur de première initialisation avec internet -> inattendue.
						return throwError(() => poError);
					// S'il n'y a pas internet, on l'indique à l'utilisateur.
					else return this.showInitFailedBecauseOfNoInternetPopup(poError);
				})
			);
		}

		return loManageError$.pipe(map((poRetry: IUiResponse<boolean>): boolean => poRetry.response));
	}

	/** Retourne `true` si la réplication peut être différée, affiche une popup d'échec d'initialisation sinon puis retourne l'action à réaliser
	 * (`true` pour réessayer, `false` pour quitter).
	 * @param poError Erreur de panne internet d'une base de données.
	 */
	private retryStrategyForNoDatabaseInternetConnectionError(
		poError: NoDatabaseInternetConnectionError
	): Observable<boolean> {
		return this.canReplicationBeDeferred(this.getDatabaseById(poError.databaseId)).pipe(
			mergeMap((pbCanBeDeferred: boolean) =>
				pbCanBeDeferred // Si la réplication peut être différée, on passe à la suite, sinon on montre la popup d'erreur.
					? of({ response: true } as IUiResponse<boolean>)
					: this.showInitFailedBecauseOfNoInternetPopup(poError)
			),
			map((poRetry: IUiResponse<boolean>): boolean => poRetry.response)
		);
	}

	/** Affiche une popup d'erreur de panne internet en cas d'échec d'initialisation d'une base de données et retourne le choix de l'utilisateur (réessayer ou quitter).
	 * @param poErrorData Erreur ou message d'erreur survenu.
	 */
	private showInitFailedBecauseOfNoInternetPopup(poErrorData: Error): Observable<IUiResponse<boolean>> {
		console.warn(`${Store.C_LOG_ID}L'initialisation des données a échoué à cause d'une panne internet.`);

		return this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				message: poErrorData.message,
				header: "Erreur de connexion",
				backdropDismiss: false,
				buttons: [
					{ text: "Réessayer", handler: () => UiMessageService.getTruthyResponse() },
					{
						text: "Quitter",
						handler: () => {
							ApplicationService.exitApp();
							return UiMessageService.getFalsyResponse();
						}
					}
				]
			})
		);
	}

	public initLocalDatabase(): Observable<Database> {
		console.debug(`${Store.C_LOG_ID}Initializing local database ...`);
		let loLoader: Loader;

		return from(this.isvcLoading.create("Initialisation du stockage local.")).pipe(
			mergeMap((poLoader: Loader) => poLoader.present()),
			tap((poLoader: Loader) => (loLoader = poLoader)),
			mergeMap(() => {
				return this.initDatabase(
					{
						id: ConfigData.appInfo.applicationDatabaseId,
						roles: [EDatabaseRole.applicationStorage],
						syncType: ESyncType.none,
						revs_limit: 1
					},
					loLoader
				); // Initialisation de la base "appStorage".
			}),
			tap(
				() => this.isvcFlag.setFlagValue(EStoreFlag.DBLocalInitialized, true),
				(poError: any) =>
					console.error(
						`${Store.C_LOG_ID}${ConfigData.appInfo.applicationDatabaseId} database initialization failed:`,
						poError
					)
			),
			finalize(() => loLoader?.dismiss())
		);
	}

	/** @implements */
	public initStartupDatabases(): Observable<Database[]> {
		console.debug(`${Store.C_LOG_ID}Initializing startup databases ...`);
		const loCredentials: ICredentials = ConfigData.authentication.appCredentials;
		const lsConfigDatabaseId: string = ConfigData.environment.coreRoleAppConfig;
		let loLoader: Loader;

		return from(this.isvcLoading.create("Initialisation de la base de données de configuration.")).pipe(
			mergeMap((poLoader: Loader) => poLoader.present()),
			tap((poLoader: Loader) => (loLoader = poLoader)),
			mergeMap(() =>
				// Initialisation de la base "config" (afin de récupérer les autres bdd).
				this.initDatabase(
					{
						id: lsConfigDatabaseId,
						roles: [EDatabaseRole.config],
						syncType: ESyncType.replicateOnStart,
						checkDatabaseMetaInstance: true,
						revs_limit: 1
					},
					loLoader,
					loCredentials.login,
					loCredentials.password
				)
			),
			catchError((poError) => {
				this.raiseStoreEvent(this.createInitErrorEvent(EStoreEventType.InitStartup, null));
				console.error(`${Store.C_LOG_ID}${lsConfigDatabaseId} database initialization failed:`, poError);
				return throwError(() => poError);
			}),
			map((poConfigDatabase: Database) => {
				this.raiseStoreEvent(this.createInitSuccessEvent(EStoreEventType.InitStartup, null));
				return [poConfigDatabase];
			}),
			finalize(() => loLoader?.dismiss())
		);
	}

	/** Récupère le document databaseMeta en ligne (réseau internet disponible).
	 * @param poDatabase Base de données qui contient le document databaseMeta à récupérer.
	 */
	private getInitDatabaseMetaOnline(poDatabase: Database): Observable<IDatabaseMeta> {
		// Tous les chemins ne retournent pas d'erreur sauf le dernier mergeMap car l'opérateur finalize() qui ferme les bases de données temporaires
		// intervient trop tard.
		return from(
			poDatabase.getRemoteInstance()?.get(this.C_DATABASE_META) ??
			throwError(() => "La base de données distante n'est pas initialisée.")
		).pipe(
			catchError((poError) => this.onGetDatabaseMetaFromServerError(poDatabase.id, poError)),
			mergeMap((poDatabaseMetaResult: IDatabaseMeta) => this.saveDatabaseMetaLocal(poDatabase, poDatabaseMetaResult))
		);
	}

	/** @implements */
	public getDatabaseMeta(psDatabaseId: string = ConfigData.environment.coreRoleAppConfig): Observable<IDatabaseMeta> {
		return this.getLocal(this.C_DATABASE_META, psDatabaseId);
	}

	/** Gère l'erreur de récupération du databaseMeta depuis le serveur : tente de le récupérer en local s'il existe ou fait transiter une erreur.
	 * @param psConfigDatabaseId Identifiant de la base de données de config.
	 * @param poError Erreur survenue lors de la récupération du document databaseMeta.
	 */
	private onGetDatabaseMetaFromServerError(psConfigDatabaseId: string, poError: any): Observable<never> {
		if (poError.status === 404)
			// Erreur "not_found" ; document non existant, erreur grave.
			return throwError(
				() =>
					`Le document de métadonnées de la base '${psConfigDatabaseId}' n'existe pas sur le serveur.\nVeuillez contacter le support technique.`
			);
		else return this.handlePouchDbError(poError);
	}

	private handlePouchDbError(poError: any): Observable<never> {
		if (poError instanceof InnerReplicateError && poError.error.status === 401) {
			if (poError.error.subStatusCode === 20)
				// Erreur l'appareil n'est pas autorisé.
				return throwError(() => new DeviceNotAuthorizedError());
			// Token invalide
			else return throwError(() => new TokenError());
		} else return throwError(() => poError);
	}

	private handleStoreError(poError: any): Observable<never> {
		this.isvcLoading.dismissAll(); // Fermeture de tous les loaders possibles.;

		if (poError instanceof DeviceNotAuthorizedError) return this.handleUnauthorizedDevice().pipe(mergeMap(() => EMPTY));
		else if (poError instanceof TokenError) {
			this.handleTokenError(poError);
			return EMPTY;
		}

		return throwError(() => poError);
	}

	private handleTokenError(poError: TokenError): void {
		console.error(`${Store.C_LOG_ID}Token error`, poError);

		// Gestion multi-popups car si n bases de données se répliquent en parallèle, on a autant de popups qui s'affichent.
		if (!this.mbIsExpiredTokenPopupShowed) {
			// Si on ne montre pas déjà une popup, on l'affiche.
			this.mbIsExpiredTokenPopupShowed = true;

			this.showExpiredTokenPopupAsync()
				.then((poResponse: IUiResponse<boolean>) =>
					poResponse.response ? this.ioRouter.navigate(["invalidate-session"]) : false
				)
				.finally(() => (this.mbIsExpiredTokenPopupShowed = false));
		}
	}

	private showExpiredTokenPopupAsync(): Promise<IUiResponse<boolean>> {
		const laButtons: AlertButton[] = [
			{
				text: "Annuler",
				role: UiMessageService.C_CANCEL_ROLE,
				handler: () => UiMessageService.getFalsyResponse()
			},
			{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse() }
		];

		return this.isvcUiMessage.showPopupMessageAsync(
			new ShowMessageParamsPopup({
				header: "Session expirée.",
				message: "Votre session est expirée. Voulez-vous vous reconnecter ?",
				buttons: laButtons
			})
		);
	}

	/** Enregistre le document databaseMeta en local si besoin (ou fait trnasiter l'erreur si la récupération du document a échoué).
	 * @param poDatabase Base de données.
	 * @param poDatabaseMeta Document databaseMeta récupéré depuis le serveur, peut-être un string si une erreur est survenue.
	 */
	private saveDatabaseMetaLocal(poDatabase: Database, poDatabaseMeta: IDatabaseMeta): Observable<IDatabaseMeta> {
		// On a un document récupéré depuis le serveur, il faut vérifier s'il existe déjà localement ou non.
		return this.getDatabaseMeta(poDatabase.id).pipe(
			mergeMap((poGetResult?: IDatabaseMeta) => {
				if (poGetResult)
					// Document présent en local, pas besoin de l'enregistrer à nouveau.
					return this.compareDatabaseMeta(poGetResult, poDatabaseMeta, poDatabase.id);
				// Document non présent localement, il faut l'enregistrer.
				else
					return this.putDatabaseMeta(poDatabaseMeta, poDatabase.id).pipe(
						tap((poDbMeta: IDatabaseMeta) => (poDatabase.meta = poDbMeta))
					);
			})
		);
	}

	/** Compare la databaseMeta du mobile avec celle du serveur afin de vérifier si l'app peut se lancer ou non.
	 * @param poMobileDatabaseMeta Objet databaseMeta provenant du mobile.
	 * @param poServerDatabaseMeta Objet databaseMeta provenant du serveur.
	 * @param psConfigDatabaseId Identifiant de la base de données de config.
	 */
	private compareDatabaseMeta(
		poMobileDatabaseMeta: IDatabaseMeta,
		poServerDatabaseMeta: IDatabaseMeta,
		psConfigDatabaseId: string
	): Observable<IDatabaseMeta> {
		const lsMessageBegin = "Les données présentes sur cet appareil ne sont pas issues";
		const lsMessageEnd = "Veuillez contacter le support technique.";
		let loCompare$: Observable<IDatabaseMeta>;

		if (poMobileDatabaseMeta.originDomainId !== ConfigData.environment.cloud_url)
			// Domaines différents : l'app ne pointe plus sur le même environnement.
			loCompare$ = throwError(
				() => `${lsMessageBegin} du serveur ${ConfigData.environment.cloud_url}. ${lsMessageEnd}`
			);
		else if (poMobileDatabaseMeta.originInstanceId !== (poServerDatabaseMeta as IDatabaseMeta).instanceId)
			// Instances différentes : divergence de version.
			loCompare$ = throwError(
				() =>
					`${lsMessageBegin} de l'instance de données ${psConfigDatabaseId}/${poMobileDatabaseMeta.originInstanceId}. ${lsMessageEnd}`
			);
		else loCompare$ = of(poMobileDatabaseMeta);

		return loCompare$;
	}

	/** Retourne `true` si la version du databaseMeta locale est identique à celle sur le serveur, `false` sinon.
	 * @param poDatabase Base de données à vérifier le databaseMeta.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si la connexion internet est rompue lors de la vérification.
	 */
	public checkDatabaseMetaInstance(poDatabase: Database): Observable<boolean> {
		if (!poDatabase.hasDatabaseMeta) return of(true);

		return this.getDatabaseMeta(poDatabase.id).pipe(
			mergeMap((poLocalDoc: IDatabaseMeta) => {
				if (!poLocalDoc)
					// Si pas de document local, on initialise les databaseMeta avec les données qui sont sur le serveur.
					return this.getInitDatabaseMetaOnline(poDatabase).pipe(mapTo(true));
				return this.innerCheckDatabaseMetaInstance$(poDatabase, poLocalDoc);
			}),
			catchError((poError: any) => this.handleStoreError(poError)),
			catchError((poError) => {
				if (this.isFailedToFetchError(poError))
					return throwError(
						() => new NoDatabaseInternetConnectionError(poDatabase.id, "Une coupure réseau est intervenue.")
					);
				else {
					console.error(
						`${Store.C_LOG_ID}Erreur vérification databaseMeta bdd "${poDatabase.id}" local-serveur`,
						poError
					);
					return of(false);
				}
			})
		);
	}

	/** Retourne `true` si la version du databaseMeta locale est identique à celle sur le serveur, `false` sinon.
	 * @param poDatabase Base de données à vérifier le databaseMeta.
	 */
	private innerCheckDatabaseMetaInstance$(poDatabase: Database, poLocalDoc: IDatabaseMeta): Observable<boolean> {
		const lbHasRemoteInstance: boolean = poDatabase.hasRemoteInstance();
		return defer(async () => {
			if (!lbHasRemoteInstance) {
				// Si on n'a pas de base distante, on la crée temporairement.
				const loAppCredentials: ICredentials | undefined = ConfigData.environment.appCredentials;
				const loConfig: IConfigInitPouchDB = await this.getDatabaseConfigAsync(
					poDatabase,
					undefined,
					loAppCredentials?.login,
					loAppCredentials?.password
				);
				poDatabase.createRemoteInstance(loConfig.remotePouchConfig.name, loConfig.remotePouchConfig);
			}
			return poDatabase.getRemoteInstance().get(this.C_DATABASE_META);
		}).pipe(
			catchError((poError: any) => (poError?.reason === "missing" ? of(undefined) : throwError(() => poError))),
			map((poServerDoc: IDatabaseMeta | undefined) => this.compareDatabaseMetaInstance(poServerDoc, poLocalDoc)),
			catchError((poError) => {
				if (poError.status === 404) {
					console.warn(`${Store.C_LOG_ID}DatabaseMeta for database '${poDatabase.id}' not on server.`, poError);
					return of(false);
				}
				return this.handlePouchDbError(poError);
			}),
			tap(
				(_) => {
					if (!lbHasRemoteInstance)
						// Si on n'avait pas de base distante, on la supprime.
						poDatabase.removeRemoteInstance();
				},
				(poError) =>
					console.error(
						`${Store.C_LOG_ID}Error getting databaseMeta from server for database '${poDatabase.id}'`,
						poError
					)
			)
		);
	}

	private compareDatabaseMetaInstance(poServerDoc: IDatabaseMeta, poLocalDoc: IDatabaseMeta): boolean {
		// Si l'identifiant de l'instance d'origine en local (originInstanceId) est égal à l'identifiant d'instance du serveur alors mêmes versions.
		return poServerDoc?.instanceId === poLocalDoc.originInstanceId;
	}

	/** Enregistre le document databaseMeta reçu du serveur sur la base de données locale.
	 * @param poServerDatabaseMeta Objet databaseMeta provenant du serveur.
	 * @param psConfigDatabaseId Identifiant de la base de données de config.
	 */
	private putDatabaseMeta(poServerDatabaseMeta: IDatabaseMeta, psConfigDatabaseId: string): Observable<IDatabaseMeta> {
		const loMobileDatabaseMeta: IDatabaseMeta = {
			_id: poServerDatabaseMeta._id,
			_rev: undefined,
			databaseId: psConfigDatabaseId,
			instanceId: GuidHelper.newGuid(),
			originDatabaseId: poServerDatabaseMeta.databaseId,
			originInstanceId: poServerDatabaseMeta.instanceId,
			originDomainId: ConfigData.environment.cloud_url
		};

		return this.putLocal(loMobileDatabaseMeta, psConfigDatabaseId).pipe(map((_) => loMobileDatabaseMeta));
	}

	/** @implements */
	public isInitializedDatabase(psDatabaseId: string): boolean {
		return this.moDatabaseById.has(psDatabaseId);
	}

	private raiseStoreEvent(poEvent: IStoreEvent): void {
		this.moEventSubject.next(poEvent);
	}

	/** Créé un événement pour répliquer la base de données.
	 * Pour éviter des problèmes avec le service de réseau qui n'émet pas un événement tout de suite, causant l'événement de réplication, de lever un autre événement de réplication,
	 * l'événement ne sera pas levé instantanément.
	 */
	private raiseDatabaseReplicationEvent(psDatabaseId: string, psLogin?: string, psPassword?: string): void {
		of(undefined)
			.pipe(
				// Attente de 0.2 secondes, sinon tourne en boucle en cas de coupure réseau, car l'événement de réplication bloque la création de l'événement du Network.
				delay(200),
				tap(() => {
					this.raiseStoreEvent(
						this.createInitDatabaseEvent(
							psDatabaseId,
							EStoreEventStatus.needReplication,
							EStoreEventType.Init,
							psLogin,
							psPassword
						)
					);
				})
			)
			.subscribe();
	}

	/** @implements */
	public prepareWorkspaceFiltersDatabases(paDatabasesIds: string[]): Array<string> {
		if (!ArrayHelper.hasElements(paDatabasesIds)) return [];

		const laDatabasesIds: Array<string> = [];

		if (UserData.workspaceFilters) {
			paDatabasesIds.forEach((psDatabaseId: string) => {
				const lnStartPatternIndex: number = psDatabaseId.indexOf(PatternsHelper.C_START_PATTERN);

				if (lnStartPatternIndex >= 0) {
					const lnEndPatternIndex: number = psDatabaseId.indexOf(PatternsHelper.C_END_PATTERN);
					const lsPattern: string = psDatabaseId.substring(lnStartPatternIndex + 2, lnEndPatternIndex);

					if (lsPattern.indexOf("*user.workspaceFilters") >= 0) {
						UserData.workspaceFilters.forEach((poItem: WorkspaceFilter) => {
							// ajout de l'id avec application du patern :
							// début de l'id jusqu'à "{{" + id du workspace + reste de l'id après "}}" (+2 pour ne pas prendre le contenu des accolades).
							laDatabasesIds.push(
								`${psDatabaseId.substring(0, lnStartPatternIndex)}${poItem.workspaceId}${psDatabaseId.substring(
									lnEndPatternIndex + 2
								)}`
							);
						});
					}
				} else laDatabasesIds.push(psDatabaseId);
			});
		}

		return ArrayHelper.hasElements(laDatabasesIds) ? laDatabasesIds : paDatabasesIds;
	}

	/** @implements */
	public put<T extends IStoreDocument>(
		poDocument: T,
		psDatabaseId?: string,
		pbCheckRevisionBeforePut: boolean = false
	): Observable<IStoreDataResponse> {
		if (ObjectHelper.isNullOrEmpty(poDocument)) return throwError(() => "Impossible de sauvegarder un document vide.");

		if (typeof poDocument === "string")
			// Au cas où on aurait un string et pas un objet, anormal (héritage v1).
			poDocument = JSON.parse(poDocument as any as string);

		const loCacheData: ICacheData = StoreHelper.getDocumentCacheData(poDocument);
		const lsDatabaseId: string = loCacheData && loCacheData.databaseId ? loCacheData.databaseId : psDatabaseId;
		let loPut$: Observable<IStoreDataResponse | never>;

		// Si la base de données est indiquée dans la cacheData et qu'elle est différente de la base de données passée en paramètre alors pas normal.
		if (
			loCacheData &&
			!StringHelper.isBlank(loCacheData.databaseId) &&
			!StringHelper.isBlank(psDatabaseId) &&
			loCacheData.databaseId !== psDatabaseId
		)
			loPut$ = throwError(
				() => "L'id de base de données est passé en paramètre alors que la data a déjà une base de données."
			);
		else if (this.isInitializedDatabase(lsDatabaseId))
			loPut$ = this.continuePut(poDocument, lsDatabaseId, pbCheckRevisionBeforePut);
		else {
			loPut$ = this.askAndWaitDatabaseId$(poDocument).pipe(
				mergeMap((psId: string) => {
					if (this.isInitializedDatabase(psId)) return this.continuePut(poDocument, psId, pbCheckRevisionBeforePut);
					else
						return throwError(
							() =>
								`Erreur lors de l'enregistrement du document '${poDocument._id}', base de données "${psId}" non initialisée.`
						);
				})
			);
		}

		return loPut$;
	}

	private onPouchDbSaveError<T extends IStoreDocument>(
		poData: T | T[],
		psDatabaseId: string,
		poError: PouchDB.Core.Error
	): Observable<never> {
		if (poError?.name === Store.C_POUCH_CONFLICT_ERROR_NAME) {
			const laConflictDocs: T[] = poData instanceof Array ? poData : [poData];
			const laConflictDocIds: string[] = laConflictDocs.map((poDoc: T) => poDoc._id);

			return this.get({ databaseId: psDatabaseId, viewParams: { keys: laConflictDocIds, include_docs: true } }).pipe(
				catchError((poGetError: any) => {
					console.error(
						`${Store.C_LOG_ID}Can not get saved documents [${laConflictDocIds.join(
							", "
						)}] in conflicts into database '${psDatabaseId}'`,
						poGetError
					);
					return of([]);
				}),
				mergeMap((paSavedDocs: T[]) => throwError(() => new ConflictError(laConflictDocs, paSavedDocs, psDatabaseId))),
				tap({ complete: () => this.openConflictAlert(poData) }) // Dans tous les cas on affiche la popup de conflits.
			);
		} else return throwError(() => poError);
	}

	private openConflictAlert(poData: IStoreDocument | IStoreDocument[]): void {
		const laDocuments: IStoreDocument[] = coerceArray(poData);

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				header: "Conflit",
				message: `${laDocuments.length > 1 ? "Les données" : "La donnée"} que vous tentez de modifier ${laDocuments.length > 1 ? "ont été modifiées" : "a été modifiée"
					} par un autre utilisateur de l'application.`
			})
		);
	}

	/** Continue l'enregistrement en base de données.
	 * @param poDocument Document à enregistrer en base de données.
	 * @param psDatabaseId Id de la base de données sur laquelle faire la requête, optionnel.
	 * @param pbCheckRevision Indique si on doit vérifier la révision du document avant de l'enregistrer en base (pour éviter des conflits) ou non, `false` par défaut.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private continuePut<T extends IStoreDocument>(
		poDocument: T,
		psDatabaseId: string,
		pbCheckRevision: boolean
	): Observable<IStoreDataResponse> {
		return pbCheckRevision
			? this.putWithCheckRevision(poDocument, psDatabaseId)
			: this.putDocumentIntoDatabase(psDatabaseId, poDocument);
	}

	/** Vérifie la révision du document avant de l'enregistrer pour ne pas avoir de conflit.
	 * @param poDocument Document à enregistrer en base de données.
	 * @param psDatabaseId Id de la base de données sur laquelle faire la requête.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private putWithCheckRevision<T extends IStoreDocument>(
		poDocument: T,
		psDatabaseId: string
	): Observable<IStoreDataResponse> {
		const loDataSource: IDataSource<T> = {
			databaseId: psDatabaseId,
			viewParams: { key: poDocument._id }
		};

		return this.getOne(loDataSource, false).pipe(
			tap((poResult: T) => (poResult ? (poDocument._rev = poResult._rev) : undefined)),
			mergeMap((_) => this.putDocumentIntoDatabase(psDatabaseId, poDocument))
		);
	}

	/** Enregistre le document dans la base de données spécifiée.
	 * @param psDatabaseId Identifiant de la base de données sur laquelle faire la requête.
	 * @param poDocument Document à enregistrer en base de données.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private putDocumentIntoDatabase<T extends IStoreDocument>(
		psDatabaseId: string,
		poDocument: T
	): Observable<IStoreDataResponse> {
		return defer(() => this.trackIfNeeded(this.getDatabaseById(psDatabaseId), poDocument)).pipe(
			mergeMap(() => this.getDatabaseInstance(psDatabaseId)),
			mergeMap((poDatabase: PouchDB.Database) => this.putDocument(poDocument, poDatabase, psDatabaseId))
		);
	}

	/** Envoi le résultat d'enregistrement sur la base de données aux abonnés en faisant une réplication des données si nécessaire.
	 * @param poResult Résultat de l'enregistrement sur la base de données.
	 * @param psDatabaseId Identifiant de la base de données sur laquelle on requête.
	 */
	private onDocumentUpdated(
		poResult: PouchDB.Core.Response,
		psDatabaseId: string,
		psAction: string
	): IStoreDataResponse {
		console.debug(`${Store.C_LOG_ID}${psAction} Document '${poResult.id}' in database '${psDatabaseId}'.`);

		if (
			!IdHelper.hasPrefixId(poResult.id, EPrefix.local) &&
			this.getDatabaseById(psDatabaseId).syncType === ESyncType.offlineFirst
		)
			this.raiseStoreEvent(this.createOfflineFirstSyncEvent(psDatabaseId));

		(poResult as IStoreDataResponse).databaseId = psDatabaseId;

		return poResult;
	}

	/** Envoi le tableau de résultats des enregistrements sur la base de données aux abonnés en faisant une réplication des données si nécessaire.
	 * @param paResults Tableau de résultats des enregistrements sur la base de données.
	 * @param paDocuments Tableau des documents enregistrés en base de données.
	 * @param peChangeType Type de changement effectué (`delete` | `update`).
	 */
	private onDocumentsUpdated<T extends IStoreDocument>(
		paResults: IStoreDataResponse[],
		paDocuments: T[],
		peChangeType: EChangeType
	): IStoreDataResponse[] {
		const laDatabaseIds: string[] = [];
		const loDocumentsById: Map<string, T> = ArrayHelper.groupByUnique(paDocuments, (poDoc: T) => poDoc._id);

		try {
			this.logUpdatedDocuments(paResults, peChangeType, (poResponse: IStoreDataResponse) =>
				laDatabaseIds.push(poResponse.databaseId)
			);
		} catch (poError) {
			console.error(`${Store.C_LOG_ID}Updated documents logging failed.`, poError);
		}

		const loDocumentsByDatabaseId = new Map<string, T[]>();
		paResults.forEach((poResponse: IStoreDataResponse) => {
			const loDoc: T | undefined = loDocumentsById.get(poResponse.id);
			if (loDoc) {
				let laDocuments: T[] | undefined = loDocumentsByDatabaseId.get(poResponse.databaseId);

				if (!laDocuments) loDocumentsByDatabaseId.set(poResponse.databaseId, [loDoc]);
				else laDocuments.push(loDoc);
			}
		});

		loDocumentsByDatabaseId.forEach((paDocs: T[], psDatabaseId: string) => {
			// Levée des événements de synchronisation des bases de données pour celles qui sont 'offlineFirst'.
			if (this.getDatabaseById(psDatabaseId).syncType === ESyncType.offlineFirst)
				// Si la base est 'offlineFirst' il faut la synchroniser.
				this.raiseStoreEvent(this.createOfflineFirstSyncEvent(psDatabaseId)); // On envoie l'événement de synchronisation des données.

			this.raiseChangeEvent(paDocs, psDatabaseId, peChangeType);
		});

		return paResults;
	}

	private logUpdatedDocuments(
		paResults: IStoreDataResponse[],
		peChangeType: EChangeType,
		pfForEachItemCallback: (poResponse: IStoreDataResponse) => void
	): void {
		const laDocumentsIds: string[] = [];
		const laDocumentsIdsErrors: string[] = [];

		paResults.forEach((poResponse: IStoreDataResponse) => {
			pfForEachItemCallback(poResponse);

			try {
				if (!poResponse.ok) laDocumentsIdsErrors.push(poResponse.id);
				else laDocumentsIds.push(poResponse.id);
			} catch (poError) {
				console.error(`${Store.C_LOG_ID}Error while preparing data for mulitple documents update logging.`);
			}
		});

		// On affiche les logs en transformant le tableau de logs en une unique chaîne de caractères en séparant chaque élément par un retour à la ligne.
		console.debug(`${Store.C_LOG_ID}${peChangeType}:`, laDocumentsIds);

		if (ArrayHelper.hasElements(laDocumentsIdsErrors)) console.error(`${Store.C_LOG_ID}Error:`, laDocumentsIdsErrors);
	}

	/** Crée un événement de synchronisation des données du store pour les bases de données offlineFirst.
	 * @param psDatabaseId Identifiant de la base de données sur laquelle synchroniser les données.
	 */
	private createOfflineFirstSyncEvent(psDatabaseId: string): IStoreEvent {
		return {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				databaseId: psDatabaseId,
				storeEventType: EStoreEventType.Synchro,
				status: EStoreEventStatus.required
			}
		};
	}

	/** Synchronise des documents sur la base locale si il y en a une.
	 * @param psDatabaseId
	 * @param paDocuments
	 */
	public async syncDocumentsToLocal(psDatabaseId: string, paDocuments: IStoreDocument[]): Promise<void> {
		const loDatabase: Database = this.getDatabaseById(psDatabaseId);
		const laUpdatedDocs: IStoreDocument[] = [];
		const laCreatedDocs: IStoreDocument[] = [];

		if (loDatabase.hasLocalInstance() && ArrayHelper.hasElements(paDocuments)) {
			const loLocalDocumentById: Map<string, IStoreDocument> = ArrayHelper.groupByUnique(
				await this.get({
					databaseId: psDatabaseId,
					viewParams: { keys: paDocuments.map((poDocument: IStoreDocument) => poDocument._id) }
				}).toPromise(),
				(poDocument: IStoreDocument) => poDocument._id
			);

			paDocuments.forEach((poDocument: IStoreDocument) => {
				const loLocalDoc: IStoreDocument | undefined = loLocalDocumentById.get(poDocument._id);

				if (loLocalDoc?._rev !== poDocument._rev) {
					const loDocument: IStoreDocument = this.getSerializableDoc(poDocument);
					StoreHelper.deleteDocumentCacheData(loDocument);
					if (loLocalDoc) laUpdatedDocs.push(loDocument);
					else laCreatedDocs.push(loDocument);
				}
			});

			await loDatabase
				.getLocalInstance()
				.bulkDocs(ArrayHelper.appendArrays(laUpdatedDocs, laCreatedDocs), { new_edits: false });
		} else {
			paDocuments.forEach((poDoc: IStoreDocument) => {
				if (StoreDocumentHelper.isNew(poDoc)) laCreatedDocs.push(poDoc);
				else laUpdatedDocs.push(poDoc);
			});
		}

		console.debug(`${Store.C_LOG_ID}Documents updated from server.`, laUpdatedDocs, laCreatedDocs);
		this.raiseChangeEvent(laUpdatedDocs, psDatabaseId, EChangeType.update);
		this.raiseChangeEvent(laCreatedDocs, psDatabaseId, EChangeType.create);
	}

	/** @implements */
	public putMultipleDocuments<T extends IStoreDocument>(
		paDocuments: T[],
		psDatabaseId: string = "",
		pbCheckRevision?: boolean,
		pbSilent?: boolean
	): Observable<IStoreDataResponse[]> {
		const loPerformanceManager = new PerformanceManager().markStart();

		return from(paDocuments).pipe(
			// On groupe tous les documents par identifiant de base de données.
			groupBy((poDocument: T) =>
				!StringHelper.isBlank(psDatabaseId) ? psDatabaseId : StoreHelper.getDocumentCacheData(poDocument).databaseId
			),
			mergeMap((poGroup$: GroupedObservable<string, T>) => {
				return poGroup$.pipe(
					toArray(),
					map((paResults: T[]) => {
						return { databaseId: poGroup$.key, documents: paResults } as IGroupedDocuments<T>;
					})
				);
			}),
			mergeMap((poGroupedDocuments: IGroupedDocuments<T>) =>
				this.checkBeforePutMultipleDocuments(poGroupedDocuments, pbCheckRevision)
			),
			mergeMap((poGroupedDocuments: IGroupedDocuments<T>) =>
				from(
					this.trackIfNeeded(this.getDatabaseById(poGroupedDocuments.databaseId), poGroupedDocuments.documents)
				).pipe(mapTo(poGroupedDocuments))
			),
			mergeMap((poGroupedDocuments: IGroupedDocuments<T>) =>
				this.bulkDocs(poGroupedDocuments.databaseId, poGroupedDocuments.documents, undefined, pbSilent)
			),
			map((paResponses: IStoreDataResponse[]) => this.onDocumentsUpdated(paResponses, paDocuments, EChangeType.update)),
			reduce(
				(paAccumulatedResponses: IStoreDataResponse[], paCurrentResponses: IStoreDataResponse[]) =>
					paAccumulatedResponses.concat(paCurrentResponses),
				[]
			),
			finalize(() =>
				console.debug(`${Store.C_LOG_ID}PutMultiple ended in ${loPerformanceManager.markEnd().measure()}ms.`)
			)
		);
	}

	/** Vérifie les documents avant de les enregistrer en base de données et les retourne groupés comme ils le sont déjà.
	 * @param poGroupedDocuments Documents à enregistrer regroupés par base de données.
	 * @param pbCheckRevision Indique si on doit vérifier la dernière révision de chaque document, `false` par défaut.
	 */
	private checkBeforePutMultipleDocuments<T extends IStoreDocument>(
		poGroupedDocuments: IGroupedDocuments<T>,
		pbCheckRevision?: boolean
	): Observable<IGroupedDocuments<T>> {
		let loCheckedDocuments$: Observable<IGroupedDocuments<T> | never>;

		// Pour chaque clé de base de données, on enregistre les documents associés en récupérant si besoin la dernière révision de chacun pour éviter des conflits.
		if (!this.isInitializedDatabase(poGroupedDocuments.databaseId))
			loCheckedDocuments$ = throwError(
				() => `Erreur lors du put du document, base de données "${poGroupedDocuments.databaseId}" inexistante.`
			);
		else if (!pbCheckRevision) loCheckedDocuments$ = of(poGroupedDocuments);
		else {
			const loDataSource: IDataSource<T> = {
				databaseId: poGroupedDocuments.databaseId,
				viewParams: { include_docs: true, keys: poGroupedDocuments.documents.map((poDocument: T) => poDocument._id) }
			};
			loCheckedDocuments$ = this.get(loDataSource).pipe(
				tap((paResults: T[]) => ArrayHelper.applyLastRevisions(poGroupedDocuments.documents, paResults)),
				mapTo(poGroupedDocuments)
			);
		}

		return loCheckedDocuments$;
	}

	/** Sauvegarde les documents sur la base en utilisant une requête bulk.
	 * @param psDatabaseId Id de la base de données à requêter.
	 * @param paDocumentsToSave Documents à sauvegarder.
	 * @param pbNewEdits Indique si l'on doit forcer la révision (utilisé pour initialisation base depuis le dump). Si `false` on force, sinon on ne fait rien.
	 * @param pbSilent Indique si l'on doit afficher la popup de conflit.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	public bulkDocs<T extends IStoreDocument>(
		psDatabaseId: string,
		paDocumentsToSave: T[],
		pbNewEdits?: boolean,
		pbSilent?: boolean
	): Observable<IStoreDataResponse[]> {
		return defer(() => paDocumentsToSave).pipe(
			map((poDocument: T) => {
				const loDocument: T = this.getSerializableDoc(poDocument);
				StoreHelper.deleteDocumentCacheData(loDocument);
				return loDocument;
			}),
			toArray(),
			mergeMap((paDocuments: T[]) =>
				this.getDatabaseInstance(psDatabaseId).pipe(
					mergeMap((poDatabase: PouchDB.Database) =>
						poDatabase.bulkDocs(paDocuments, pbNewEdits === false ? { new_edits: pbNewEdits } : undefined)
					)
				)
			),
			tap((paResults: Array<IStoreDataResponse>) => {
				paResults.forEach((poResult: IStoreDataResponse) => (poResult.databaseId = psDatabaseId));
				const laResultsById: Map<string, IStoreDataResponse[]> = ArrayHelper.groupBy(
					paResults,
					(poResult: IStoreDataResponse) => poResult.id
				);

				paDocumentsToSave.forEach((poDocument: T) => {
					StoreHelper.updateDocumentCacheData(poDocument, { databaseId: psDatabaseId, dirty: false });
					const loResponse: IStoreDataResponse = ArrayHelper.getFirstElement(laResultsById.get(poDocument._id));
					if (loResponse?.ok) poDocument._rev = loResponse.rev;
				});

				// Gestion de l'erreur de sauvegarde (pour un ou plusieurs doc, sans crash).
				const laErrorResponses: IStoreDataResponse[] = paResults.filter(
					(poResponse: IStoreDataResponse) => poResponse.name === Store.C_POUCH_CONFLICT_ERROR_NAME
				);
				const laDocumentsOnError: T[] = ArrayHelper.intersection(
					paDocumentsToSave,
					laErrorResponses,
					(poDocument: T, poResponse: IStoreDataResponse) => poDocument._id === poResponse.id
				);

				if (ArrayHelper.hasElements(laDocumentsOnError) && !pbSilent) {
					console.error(
						`${Store.C_LOG_ID}Erreurs lors de la mise à jour de masse des documents.`,
						laDocumentsOnError.map((poDoc: T) => poDoc._id)
					);
					this.openConflictAlert(laDocumentsOnError);
				}
			})
		);
	}

	/** Réplique les données de la base Pouch source sur la base cible.
	 * @param poDatabase Base de données.
	 * @param psReplicationLabel Libellé pour sorties Console.
	 * @param poSourceInstance Adapteur PouchDB sur la base de données source.
	 * @param poTargetInstance Adapteur PouchDB sur la base de données cible.
	 * @param poReplicateOptions Objet représentant les options de réplication.
	 * @param pfOnProgress Callback appelée lors de l'avancement de la réplication.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si la connexion internet est rompue pour la réplication.
	 */
	private replicate(
		poDatabase: Database,
		psReplicationLabel: string,
		poSourceInstance: PouchDB.Database,
		poTargetInstance: PouchDB.Database,
		poReplicateOptions?: IStoreReplicationOptions,
		pfOnProgress?: IOnProgressFunction
	): Observable<IStoreReplicationResponse> {
		if (!poDatabase.canReplicate)
			// Si on ne peux pas répliquer on arrête tout de suite.
			return EMPTY;

		const loParams: IReplicateDatabaseParams = this.createReplicateDatabaseParams(
			poDatabase,
			psReplicationLabel,
			poSourceInstance,
			poTargetInstance,
			poReplicateOptions,
			pfOnProgress
		);
		const loPerfManager = new PerformanceManager().markStart();
		let loReplicationResult: IReplicateResult | undefined;
		let loError: any | undefined;

		return defer(() => {
			console.debug(
				`${Store.C_LOG_ID}Replication (${loParams.replicationLabel}) of database '${loParams.sourceInstance.name}' to database '${loParams.targetInstance.name}' begun`,
				{ since: loParams.replicateOptions.since, lastLocalSeq: loParams.database.localLastSeq }
			);
			return this.moUserActionsCounterSubject.asObservable();
		}).pipe(
			distinctUntilChanged(),
			takeUntil(loParams.replicationEndSubject.asObservable()),
			//! Le `switchMap` permet de fermer les réplications si une action utilisateur arrive.
			switchMap((pnInProgressActions: number) => this.replicateIfPossible$(loParams, pnInProgressActions)),
			tap(
				(poResponse?: IReplicateResult) => {
					loParams.replicateOptions.since = poResponse?.last_seq ?? loParams.replicateOptions.since;
					loReplicationResult = this.mergeReplicateResults(loReplicationResult, poResponse);
				},
				(poError: NoDatabaseInternetConnectionError | InnerReplicateError | any) => {
					if (poError instanceof InnerReplicateError)
						loReplicationResult = this.mergeReplicateResults(loReplicationResult, poError.replicateResult);
				}
			),
			takeUntil(loParams.database.canReplicate$.pipe(filter((pbCanReplicate: boolean) => !pbCanReplicate))),
			catchError((poError) => this.handlePouchDbError(poError)),
			catchError((poError: any) => this.handleStoreError(poError)),
			tapError((poError) => (loError = poError)),
			finalize(() => {
				loParams.replicationEndSubject.complete();
				this.logReplicateResult(loParams, loPerfManager.markEnd().measure(), loReplicationResult, loError);
			})
		);
	}

	private createReplicateDatabaseParams(
		poDatabase: Database,
		psReplicationLabel: string,
		poSourceInstance: PouchDB.Database,
		poTargetInstance: PouchDB.Database,
		poReplicateOptions?: IStoreReplicationOptions,
		pfOnProgress?: IOnProgressFunction
	): IReplicateDatabaseParams {
		return {
			database: poDatabase,
			replicationEndSubject: new Subject<void>(),
			replicationLabel: psReplicationLabel,
			sourceInstance: poSourceInstance,
			targetInstance: poTargetInstance,
			timersMs: [],
			replicateOptions: poReplicateOptions ?? {},
			onProgress: pfOnProgress
		};
	}

	/**
	 * @param poParams
	 * @param pnInProgressActions
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si la connexion internet est rompue lors de la réplication ou de la vérification de l'instance du databaseMeta.
	 * - `InnerReplicateError` si une erreur est survenue lors de la réplication.
	 * - l'erreur en paramètre par défaut.
	 */
	private replicateIfPossible$(
		poParams: IReplicateDatabaseParams,
		pnInProgressActions: number
	): Observable<IReplicateResult | undefined> {
		if (pnInProgressActions === 0) {
			const loPerfManager = new PerformanceManager().markStart();

			return this.getSequenceBeforeReplicate(
				poParams.database,
				StoreHelper.isRemoteDatabase(poParams.sourceInstance.name),
				poParams.replicateOptions
			).pipe(
				switchMap((poSeqBeforeReplication: string | number | undefined) => {
					//! Laisser le `switchMap` !
					return this.replicateDatabase(poParams).pipe(
						tap(
							//! Pas de `finalize` pour éviter l'inversion des logs entre cette méthode et l'appelante.
							(poResponse?: IReplicateResult) => {
								if (poResponse) poResponse.seqBefore = poSeqBeforeReplication;

								poParams.timersMs.push(loPerfManager.markEnd().measure()); // On ajoute le temps de réplication passé dans le tableau des temps.
							},
							(_) => poParams.timersMs.push(loPerfManager.markEnd().measure()), // On ajoute le temps de réplication passé dans le tableau des temps.
							() => loPerfManager.clear()
						)
					);
				})
			);
		} else return EMPTY; // Ne clôture pas le flux global de la méthode `replicate()`.
	}

	private mergeReplicateResults(
		poPrevious?: IReplicateResult,
		poCurrent?: IReplicateResult
	): IReplicateResult | undefined {
		if (poPrevious) {
			if (poCurrent) {
				// Si les deux paramètres sont définis, il faut fusionner.
				poPrevious.doc_write_failures = NumberHelper.addTwoNumbers(
					poPrevious.doc_write_failures,
					poCurrent.doc_write_failures
				);
				poPrevious.docs = ArrayHelper.appendArrays(poPrevious.docs, poCurrent.docs);
				poPrevious.docs_read = NumberHelper.addTwoNumbers(poPrevious.docs_read, poCurrent.docs_read);
				poPrevious.docs_written = NumberHelper.addTwoNumbers(poPrevious.docs_written, poCurrent.docs_written);
				poPrevious.errors = ArrayHelper.appendArrays(poPrevious.errors, poCurrent.errors);
				// Les modifications de dates de fin, statut, pile d'appel, le champs "ok", sont déterminés par les nouveaux arrivants.
				poPrevious.end_time = poCurrent.end_time; // Les nouveaux résultats ont une date de fin plus récente.
				poPrevious.last_seq = poCurrent.last_seq;
				poPrevious.ok = poPrevious && poCurrent.ok;
				poPrevious.status = poCurrent.status;
				poPrevious.stack = poCurrent.stack;
				poPrevious.seqBefore = poCurrent.seqBefore;
				// On ignore la date de début car les nouveaux résultats ont une date plus récente ; le mode de réplicatio nest toujours le même.
			}

			return poPrevious; // On retourne la fusion.
		} else if (poCurrent)
			// Si on a que le second paramètre de défini, on le retourne directement.
			return poCurrent;
		// Si aucun paramètre défini, on ne retourne rien.
		else return undefined;
	}

	private getSequenceBeforeReplicate(
		poDatabase: Database,
		pbIsFromRemote: boolean,
		poReplicateOptions?: IStoreReplicationOptions
	): Observable<string | number | undefined> {
		if (poReplicateOptions?.since) return of(poReplicateOptions?.since);
		else return pbIsFromRemote ? poDatabase.getLastSeqFromInstance("server") : poDatabase.localLastSeq$.pipe(take(1));
	}

	private logReplicateResult(
		poParams: IReplicateDatabaseParams,
		pnGlobalTimerMs: number,
		poResult?: IReplicateResult,
		poError?: InnerReplicateError | any
	): void {
		// Si on a une erreur ou une réussite avec au moins un document répliqué, on doit loguer.
		if (poError || ArrayHelper.hasElements(poResult?.docs)) {
			let lsReplicateMode: string;
			let lsReplicateState: string;
			let lnReplicatedDocs: number;

			if (poError) {
				lsReplicateMode = this.getReplicator(
					poParams.database.localToServerReplicationMode,
					StoreHelper.isRemoteDatabase(poParams.sourceInstance.name)
				).name;
				lsReplicateState = "failed";
				lnReplicatedDocs = (poError as InnerReplicateError).replicatedDocs?.length ?? 0;
			} else {
				lsReplicateMode = poResult.replicationMode;
				lsReplicateState = "succeeded";
				lnReplicatedDocs = poResult.docs.length;
			}

			this.isvcLogger.action(
				Store.C_LOG_ID,
				`Database replication '${poParams.replicationLabel}' from '${poParams.sourceInstance.name}' to '${poParams.targetInstance.name
				}' with replicate mode '${lsReplicateMode}' ${lsReplicateState}. ${lnReplicatedDocs} synchronized documents in ${NumberHelper.reduceNumbers(
					poParams.timersMs,
					0
				).toFixed(2)}ms (total duration of ${pnGlobalTimerMs.toFixed(2)}ms).`,
				ELogActionId.storeReplicate,
				this.getReplicateLogData(poParams.database, poParams.sourceInstance, poParams.targetInstance, poResult),
				poError
			);
		}
	}

	private getReplicateLogData(
		poDatabase: Database,
		poSourceInstance: PouchDB.Database,
		poTargetInstance: PouchDB.Database,
		poResult?: IReplicateResult
	): IReplicateLogActionData {
		const loData: IReplicateLogActionData = {
			databaseId: poDatabase.id,
			nbDocReplicated: poResult?.docs?.length ?? 0,
			source: poSourceInstance.name,
			target: poTargetInstance.name,
			duration: poResult?.durationMs ?? 0
		};

		if (poResult?.docs) {
			if (loData.nbDocReplicated < C_MAX_REPLICATED_DOCS_NB)
				loData.docs = poResult.docs.map(
					(poDoc: IStoreDocument): IReplicatedDoc => ({ _id: poDoc._id, _rev: poDoc._rev })
				);
			else
				loData.docs = StoreHelper.getNineDocs(poResult.docs).map(
					(poDoc: IStoreDocument): IReplicatedDoc => ({ _id: poDoc._id, _rev: poDoc._rev })
				);
		}

		typeof poResult?.seqBefore === "number"
			? (loData.localSeqBeforeReplication = poResult.seqBefore)
			: (loData.serverSeqBeforeReplication = poResult?.seqBefore);

		return loData;
	}

	/**
	 * @param poParams
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si la connexion internet est rompue lors de la réplication ou de la vérification de l'instance du databaseMeta.
	 * - `InnerReplicateError` si une erreur est survenue lors de la réplication.
	 * - l'erreur en paramètre par défaut.
	 */
	private replicateDatabase(poParams: IReplicateDatabaseParams): Observable<IReplicateResult | undefined> {
		return this.checkDatabaseMetaInstance(poParams.database).pipe(
			mergeMap((pbAreDbMetaOk: boolean) => {
				if (pbAreDbMetaOk) {
					return this.inner_replicate(
						poParams.sourceInstance,
						poParams.targetInstance,
						poParams.database,
						poParams.replicationLabel,
						poParams.replicateOptions,
						poParams.onProgress
					).pipe(
						tap(
							() => {
								if (!poParams.replicateOptions?.live) poParams.replicationEndSubject.next(undefined);
							},
							(_) => {
								if (!poParams.replicateOptions?.live) poParams.replicationEndSubject.next(undefined);
							}
						)
					);
				} else {
					this.isvcLoading.dismissAll();
					return this.resetDatabaseAndReloadApp(poParams.database);
				}
			})
		);
	}

	/**
	 * @param poSourceInstance
	 * @param poTargetInstance
	 * @param poDatabase
	 * @param psReplicationLabel
	 * @param poReplicateOptions
	 * @param pfOnProgress
	 * @throws
	 * - `InnerReplicateError` si une erreur est survenue lors de la réplication.
	 * - l'erreur en paramètre par défaut.
	 */
	private inner_replicate(
		poSourceInstance: PouchDB.Database<{}>,
		poTargetInstance: PouchDB.Database<{}>,
		poDatabase: Database,
		psReplicationLabel: string,
		poReplicateOptions?: IStoreReplicationOptions,
		pfOnProgress?: IOnProgressFunction
	): Observable<IReplicateResult> {
		const lbIsSyncFromRemote: boolean = StoreHelper.isRemoteDatabase(poSourceInstance.name);
		const lbIsSyncToRemote: boolean = StoreHelper.isRemoteDatabase(poTargetInstance.name);
		const loReplicatorParams: IStoreReplicatorParams = this.createReplicatorParams(
			poDatabase,
			poSourceInstance,
			poTargetInstance,
			lbIsSyncFromRemote,
			lbIsSyncToRemote,
			psReplicationLabel,
			poReplicateOptions
		);
		const loPerfManager = new PerformanceManager().markStart();
		let loResult: IReplicateResult = this.createDefaultReplicationResult(loReplicatorParams);

		return this.execReplicate$(loReplicatorParams, pfOnProgress).pipe(
			mergeMap((poResult: IExecReplicateResult) => this.execAfterReplicateAsync(poResult.result, loReplicatorParams)),
			catchError((poError) => {
				loResult.durationMs = loPerfManager.markEnd().measure();
				loResult.end_time = new Date().toISOString();
				return this.inner_replicateError$(poError, loReplicatorParams, loResult);
			}),
			defaultIfEmpty(loResult),
			map((poResponse: IStoreReplicationResponse): IReplicateResult => {
				if (!poResponse.end_time) poResponse.end_time = new Date().toISOString();

				return (loResult = { ...poResponse, durationMs: loPerfManager.markEnd().measure() });
			}),
			finalize(() => {
				if (loReplicatorParams.isSyncFromRemote) loReplicatorParams.database.synchronizationFromServerEvent = undefined;
				else if (loReplicatorParams.isSyncToRemote)
					loReplicatorParams.database.synchronizationToServerEvent = undefined;
			})
		);
	}

	private createReplicatorParams(
		poDatabase: Database,
		poSourceInstance: PouchDB.Database<{}>,
		poTargetInstance: PouchDB.Database<{}>,
		pbIsSyncFromRemote: boolean,
		pbIsSyncToRemote: boolean,
		psReplicationLabel: string,
		poReplicateOptions?: IStoreReplicationOptions
	): IStoreReplicatorParams {
		return {
			database: poDatabase,
			sourceInstance: poSourceInstance,
			targetInstance: poTargetInstance,
			isSyncFromRemote: pbIsSyncFromRemote,
			isSyncToRemote: pbIsSyncToRemote,
			replicationLabel: psReplicationLabel,
			replicateOptions: poReplicateOptions
		};
	}

	private createDefaultReplicationResult(poReplicatorParams: IStoreReplicatorParams): IReplicateResult {
		return {
			doc_write_failures: 0,
			docs_read: 0,
			docs_written: 0,
			docs: [],
			errors: [],
			ok: true,
			replicationMode: this.getReplicator(
				poReplicatorParams.database.localToServerReplicationMode,
				poReplicatorParams.isSyncFromRemote
			).name,
			start_time: new Date().toISOString(),
			status: EStoreReplicationResponseStatus.default,
			last_seq: 0,
			durationMs: 0
		};
	}

	/**
	 * @param poReplicatorParams
	 * @param pfOnProgress
	 */
	private execReplicate$(
		poReplicatorParams: IStoreReplicatorParams,
		pfOnProgress?: IOnProgressFunction
	): Observable<IExecReplicateResult> {
		let loCancelablePrepareReplicationPromise: PCancelable<void> | undefined;
		let loCancelableReplicatePromise: PCancelable<IStoreReplicationResponse> | undefined;

		return defer(
			() => (loCancelablePrepareReplicationPromise = this.prepareReplicationAsync(poReplicatorParams, pfOnProgress))
		).pipe(
			mergeMap(() => {
				const loReplicator: ReplicatorBase = this.getReplicator(
					poReplicatorParams.database.localToServerReplicationMode,
					poReplicatorParams.isSyncFromRemote
				);

				if (poReplicatorParams.replicateOptions?.live) {
					return loReplicator.replicateLive$(poReplicatorParams).pipe(concatMap((poResponse: IStoreReplicationResponse) =>
						this.onReplicateLiveProgressAsync(poReplicatorParams, poResponse)
					));
				}
				else {
					return loCancelableReplicatePromise = loReplicator.replicateAsync(
						poReplicatorParams,
						(poEvent: ISynchronizationEvent, poResponse?: IStoreReplicationResponse) =>
							this.replicationOnProgressAsync(poReplicatorParams.database, poEvent, poReplicatorParams.sourceInstance, poReplicatorParams.targetInstance, poResponse, pfOnProgress)
					);
				}
			}),
			map((poResponse: IStoreReplicationResponse): IExecReplicateResult => {
				return { result: poResponse };
			}),
			finalize(() => {
				loCancelablePrepareReplicationPromise?.cancel();
				loCancelableReplicatePromise?.cancel();
			})
		);
	}

	private async onReplicateLiveProgressAsync(
		poReplicatorParams: IStoreReplicatorParams,
		poResponse?: IStoreReplicationResponse<IStoreDocument>
	): Promise<IStoreReplicationResponse<IStoreDocument>> {
		// On gère le déclenchement et la fin de lot ici, car sur une synchro live on n'a pas de fin, donc on gère au niveau du lot.
		// Début de synchro
		this.setSynchronizationEvent(
			poReplicatorParams.database,
			poReplicatorParams.sourceInstance,
			poReplicatorParams.targetInstance,
			{ total: NaN, loaded: NaN }
		);

		if (poResponse) {
			await firstValueFrom(this.markSynchro(
				poReplicatorParams.database,
				poReplicatorParams.sourceInstance.name,
				poReplicatorParams.targetInstance.name,
				poResponse
			));
		}

		// Fin de synchro
		this.setSynchronizationEvent(
			poReplicatorParams.database,
			poReplicatorParams.sourceInstance,
			poReplicatorParams.targetInstance
		);

		return poResponse;
	}

	private prepareReplicationAsync(poParams: IStoreReplicatorParams, pfOnProgress: IOnProgressFunction): PCancelable<void> {
		let loSourceCancelable: PCancelable<void>;
		return (loSourceCancelable = new PCancelable(async (pfResolve, pfReject, onCancel) => {
			try {
				let loCancelablePromise: PCancelable<IStoreReplicationResponse<IStoreDocument>>;

				onCancel(() => loCancelablePromise?.cancel());

				if (poParams.isSyncFromRemote) {
					poParams.database.isSynchroFromServerOnError = false;
					poParams.database.synchronizationFromServerEvent = undefined;
				} else if (poParams.isSyncToRemote) {
					poParams.database.isSynchroToServerOnError = false;
					poParams.database.synchronizationToServerEvent = undefined;

					const leLastLocalToServerReplicationMode: ELocalToServerReplicationMode =
						await this.getLastLocalToServerReplicationModeAsync(poParams.database.id);

					if (
						leLastLocalToServerReplicationMode !== poParams.database.localToServerReplicationMode &&
						!loSourceCancelable.isCanceled
					) {
						const loLastReplicator: ReplicatorBase = this.getReplicator(
							leLastLocalToServerReplicationMode,
							poParams.isSyncFromRemote
						);
						// On clone les options de réplications pour ne pas conserver les modifs que peut faire la méthode de réplication.
						const loReplicateOptionsClone: IStoreReplicationOptions | undefined = poParams.replicateOptions
							? { ...poParams.replicateOptions }
							: undefined;

						loCancelablePromise = loLastReplicator.replicateAsync(
							poParams,
							(poEvent: ISynchronizationEvent, poResponse?: IStoreReplicationResponse) =>
								this.replicationOnProgressAsync(
									poParams.database,
									poEvent,
									poParams.sourceInstance,
									poParams.targetInstance,
									poResponse,
									pfOnProgress
								)
						);
						await loCancelablePromise;

						poParams.replicateOptions = loReplicateOptionsClone; // On remet les options de réplication comme avant pour ne pas avoir d'effet de bord.
					}
				}

				pfResolve();
			} catch (poError) {
				pfReject(poError);
			}
		}));
	}

	private async execAfterReplicateAsync(
		poResponse: IStoreReplicationResponse,
		poReplicatorParams: IStoreReplicatorParams
	): Promise<IStoreReplicationResponse> {
		await this.markSynchro(
			poReplicatorParams.database,
			poReplicatorParams.sourceInstance.name,
			poReplicatorParams.targetInstance.name,
			poResponse
		).toPromise();

		if (poReplicatorParams.isSyncFromRemote)
			await poReplicatorParams.database.markLocalInstanceAsFirstReplicatedAsync();

		this.innerReplicate_sendEvent(
			poResponse,
			EStoreEventStatus.successed,
			poReplicatorParams.replicationLabel,
			poReplicatorParams.database.id
		);

		if (poReplicatorParams.isSyncFromRemote)
			// On garde uniquement les changements des réplications depuis le serveur.
			this.onReplicationChange(poResponse.docs, poReplicatorParams.database.id);

		if (poReplicatorParams.isSyncToRemote)
			await this.saveLastLocalToServerReplicationModeAsync(
				poReplicatorParams.database.id,
				poReplicatorParams.database.localToServerReplicationMode
			);

		return poResponse;
	}

	/**
	 * @param poError Erreur survenue.
	 * @param poReplicatorParams Paramètres du réplicateur.
	 * @param poReplicateResult Objet résultat de la réplication qui est tombée en erreur.
	 * @throws
	 * - `InnerReplicateError` si une erreur est survenue lors de la réplication.
	 * - l'erreur en paramètre par défaut.
	 * @returns `EMPTY` si l'erreur est de type `CancelError`.
	 */
	private inner_replicateError$(
		poError: any,
		poReplicatorParams: IStoreReplicatorParams,
		poReplicateResult: IReplicateResult
	): Observable<never> {
		if (poError instanceof CancelError) return EMPTY;
		else {
			if (poReplicatorParams.isSyncFromRemote) poReplicatorParams.database.isSynchroFromServerOnError = true;
			else if (poReplicatorParams.isSyncToRemote) poReplicatorParams.database.isSynchroToServerOnError = true;

			this.innerReplicate_sendEvent(
				poError,
				EStoreEventStatus.failed,
				poReplicatorParams.replicationLabel,
				poReplicatorParams.database.id
			);

			if (poError instanceof ReplicatorError) {
				let lsReplicatorName: string;

				try {
					lsReplicatorName = this.getReplicator(
						poReplicatorParams.database.localToServerReplicationMode,
						poReplicatorParams.isSyncFromRemote
					).name;
				} catch {
					lsReplicatorName = "unknown";
				}

				return throwError(() => new InnerReplicateError(poReplicateResult, lsReplicatorName, poError));
			} else return throwError(() => poError);
		}
	}

	private async replicationOnProgressAsync(
		poDatabase: Database,
		poEvent: ISynchronizationEvent,
		poSourceInstance: PouchDB.Database<{}>,
		poTargetInstance: PouchDB.Database<{}>,
		poResponse: IStoreReplicationResponse<IStoreDocument>,
		pfOnProgress: IOnProgressFunction
	): Promise<void> {
		this.setSynchronizationEvent(poDatabase, poSourceInstance, poTargetInstance, poEvent);

		if (poResponse)
			await this.markSynchro(poDatabase, poSourceInstance.name, poTargetInstance.name, poResponse).toPromise();

		if (pfOnProgress) await pfOnProgress(poEvent, poResponse);
	}

	private setSynchronizationEvent(
		poDatabase: Database,
		poSource: PouchDB.Database<any>,
		poTarget: PouchDB.Database<any>,
		poISyncronizationEvent?: ISynchronizationEvent
	): void {
		if (StoreHelper.isRemoteDatabase(poSource.name))
			poDatabase.synchronizationFromServerEvent = poISyncronizationEvent;
		else if (StoreHelper.isRemoteDatabase(poTarget.name))
			poDatabase.synchronizationToServerEvent = poISyncronizationEvent;
	}

	private getReplicator(
		peLocalToServerReplicationMode: ELocalToServerReplicationMode,
		pbIsSyncFromRemote: boolean
	): ReplicatorBase {
		if (pbIsSyncFromRemote) return this.isvcClassicReplicator;

		switch (peLocalToServerReplicationMode) {
			case ELocalToServerReplicationMode.classic:
				return this.isvcClassicReplicator;
			case ELocalToServerReplicationMode.changeTracking:
				return this.isvcChangeTrackingReplicator;
			default:
				throw new Error(`Cannot get the correct replicator for the mode '${peLocalToServerReplicationMode}'.`);
		}
	}

	private async getLastLocalToServerReplicationModeAsync(psDatabaseId: string): Promise<ELocalToServerReplicationMode> {
		return (
			(await this.getLastLocalToServerReplicationModeDocumentAsync(psDatabaseId))?.lastLocalToServerReplicationMode ??
			ELocalToServerReplicationMode.classic
		);
	}

	private getLastLocalToServerReplicationModeDocumentAsync(
		psDatabaseId: string
	): Promise<ILastLocalToServerReplicationModeDocument | undefined> {
		return this.getLocal<ILastLocalToServerReplicationModeDocument>(
			this.getLastLocalToServerReplicationModeDocumentId(psDatabaseId),
			psDatabaseId
		).toPromise();
	}

	private getLastLocalToServerReplicationModeDocumentId(psDatabaseId: string): string {
		return `${EPrefix.local}${EPrefix.lastLocalToServerReplicationMode}${psDatabaseId}`;
	}

	private async saveLastLocalToServerReplicationModeAsync(
		psDatabaseId: string,
		peLastLocalToServerReplicator: ELocalToServerReplicationMode
	): Promise<void> {
		let loDoc: ILastLocalToServerReplicationModeDocument | undefined =
			await this.getLastLocalToServerReplicationModeDocumentAsync(psDatabaseId);

		if (loDoc && loDoc.lastLocalToServerReplicationMode !== peLastLocalToServerReplicator)
			// Si le mode de réplication est différent, il faut modifier le document.
			loDoc.lastLocalToServerReplicationMode = peLastLocalToServerReplicator;
		else if (!loDoc)
			// Si le document n'existe pas, on le crée.
			loDoc = {
				_id: this.getLastLocalToServerReplicationModeDocumentId(psDatabaseId),
				lastLocalToServerReplicationMode: peLastLocalToServerReplicator
			};
		// Si le document est identique, pas besoin de l'enregistrer ensuite.
		else loDoc = undefined;

		if (loDoc) await this.putLocal<ILastLocalToServerReplicationModeDocument>(loDoc, psDatabaseId).toPromise();
	}

	/** Permet de marquer la date d'une synchronisation en base.
	 * @param poDatabase
	 * @param psSourceName
	 * @param psTargetName
	 * @param poReplicationResponse
	 * @returns
	 */
	@Queue<Store, Parameters<Store["markSynchro"]>, ReturnType<Store["markSynchro"]>>({
		idBuilder: (poDatabase: Database) => poDatabase.id
	})
	public markSynchro(
		poDatabase: Database,
		psSourceName: string,
		psTargetName: string,
		poReplicationResponse: IStoreReplicationResponse
	): Observable<IStoreDataResponse> {
		const lbIsSyncFromRemote: boolean = StoreHelper.isRemoteDatabase(psSourceName);
		const lbIsSyncToRemote: boolean = StoreHelper.isRemoteDatabase(psTargetName);

		if (lbIsSyncFromRemote || lbIsSyncToRemote) {
			return defer(() => {
				console.debug(`${Store.C_LOG_ID}Saving ${poDatabase.id}'s last sync date...`);
				return this.getSyncMarkerAsync(poDatabase.id);
			}).pipe(
				mergeMap((poSyncMarker: IDatabaseSyncMarker) => {
					this.updateSyncMarkerBeforeSaving(poSyncMarker, poReplicationResponse, lbIsSyncFromRemote, lbIsSyncToRemote);

					poDatabase.syncMarker = poSyncMarker;

					return this.saveSyncMarker(poSyncMarker);
				}),
				tapError((poError) =>
					console.error(`${Store.C_LOG_ID}Error while saving ${poDatabase.id}'s last sync date.`, poError)
				)
			);
		}
		return throwError(() => new Error("La synchronisation doit être soit serveur>local soit local>server."));
	}

	private updateSyncMarkerBeforeSaving(
		poSyncMarker: IDatabaseSyncMarker,
		poReplicationResponse: IStoreReplicationResponse,
		lbIsSyncFromRemote: boolean,
		lbIsSyncToRemote: boolean
	): void {
		let ldSyncEndDate = new Date(poReplicationResponse.end_time);

		if (!DateHelper.isDate(ldSyncEndDate)) ldSyncEndDate = new Date();

		if (lbIsSyncFromRemote) {
			poSyncMarker.fromServer = ldSyncEndDate;
			const lsLastSeq: string = poReplicationResponse.last_seq.toString();
			if (StoreHelper.getSequenceNumber(poSyncMarker.remoteSequenceNumber) <= StoreHelper.getSequenceNumber(lsLastSeq))
				poSyncMarker.remoteSequenceNumber = lsLastSeq;
			else {
				this.logSequenceNumber("Remote", poSyncMarker.databaseId, lsLastSeq, poSyncMarker.remoteSequenceNumber);
			}
		} else if (lbIsSyncToRemote && typeof poReplicationResponse.last_seq === "number") {
			poSyncMarker.toServer = ldSyncEndDate;
			const lnLastSeq: number = poReplicationResponse.last_seq;
			if (StoreHelper.getSequenceNumber(poSyncMarker.localSequenceNumber) <= StoreHelper.getSequenceNumber(lnLastSeq))
				poSyncMarker.localSequenceNumber = lnLastSeq;
			else {
				this.logSequenceNumber("Local", poSyncMarker.databaseId, lnLastSeq, poSyncMarker.localSequenceNumber);
			}
		}
	}

	private logSequenceNumber(
		psDatabaseLocation: string,
		psDatabaseId: string,
		poLastSeq: number | string,
		poPreviousSeq: number | string
	): void {
		console.error(
			`${Store.C_LOG_ID}${psDatabaseLocation} sequence number ${poLastSeq} \
for database ${psDatabaseId} not valid for update. \
Previous value was ${poPreviousSeq}.`
		);
	}

	private saveSyncMarker(poSyncMarker: IDatabaseSyncMarker): Observable<IStoreDataResponse> {
		return this.putLocal(poSyncMarker, poSyncMarker.databaseId).pipe(
			tap(
				() => console.debug(`${Store.C_LOG_ID}${poSyncMarker.databaseId}'s last sync date saved.`, poSyncMarker),
				(poError) => console.error(`${Store.C_LOG_ID}${poSyncMarker.databaseId}'s last sync date save failed.`, poError)
			)
		);
	}

	private getSyncMarkerAsync(psDatabaseId: string): Promise<IDatabaseSyncMarker> {
		const lsSyncMarkerId: string = IdHelper.buildId(EPrefix.sync, psDatabaseId);

		return this.getLocal<IDatabaseSyncMarker>(lsSyncMarkerId, psDatabaseId)
			.pipe(
				map((poSyncMarker: IDatabaseSyncMarker) => poSyncMarker ?? { _id: lsSyncMarkerId, databaseId: psDatabaseId })
			)
			.toPromise();
	}

	/** Récupère les marqueurs de synchronisation des bases de données. */
	public getSyncMarkers(): Observable<IDatabaseSyncMarker[]> {
		return combineLatest(
			MapHelper.valuesToArray(this.moDatabaseById)
				.filter((poDatabase: Database) => poDatabase.hasLocalInstance())
				.map((poDatabase: Database) => this.getSyncMarkerAsync(poDatabase.id))
		).pipe(
			map((paSyncMarkers: IDatabaseSyncMarker[]) =>
				paSyncMarkers.filter(
					(poSyncMarker: IDatabaseSyncMarker) => !!poSyncMarker && !StringHelper.isBlank(poSyncMarker._rev)
				)
			)
		);
	}

	private innerReplicate_sendEvent(
		poResponseOrError: IStoreReplicationResponse | any,
		peStatus: EStoreEventStatus,
		psReplicationLabel: string,
		psDatabaseId: string
	): void {
		const loEvent: IStoreEvent = {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				databaseId: psDatabaseId,
				storeEventType: EStoreEventType.Synchro,
				status: peStatus
			}
		};

		if (peStatus === EStoreEventStatus.successed) {
			const loResponse: IStoreReplicationResponse = poResponseOrError;

			if (!ArrayHelper.hasElements(loResponse.docs)) loResponse.docs = [];

			loEvent.data.result = poResponseOrError;
			console.debug(`${Store.C_LOG_ID}${psReplicationLabel} replication completed for database ${psDatabaseId}.`); // TODO À revoir, source de saturation RAM/mémoire , loResponse.docs);
		}

		this.raiseStoreEvent(loEvent);
	}

	/** @implements */
	public replicateDocumentIntoDatabases<T extends IStoreDocument>(
		psDatabaseSourceId: string,
		poDocument: T,
		paDatabaseTargetIds: string[]
	): Observable<boolean> {
		const loSource: Database = this.moDatabaseById.get(psDatabaseSourceId);
		let loReplication$: Observable<boolean | never>;

		if (loSource) {
			const loCacheData: ICacheData = StoreHelper.deleteDocumentCacheData(poDocument);

			loReplication$ = from(loSource.defaultDatabase.get(poDocument._id, { revs: true })).pipe(
				catchError((poError) => this.onReplicateDocumentIntoDatabasesFail(poDocument, loCacheData, poError)),
				mergeMap((poGetDocumentResult: PouchDB.Core.IdMeta & PouchDB.Core.GetMeta) =>
					from(paDatabaseTargetIds).pipe(
						mergeMap((psId: string) =>
							this.execReplicateDocumentIntoDatabases(psId, poDocument, poGetDocumentResult, loCacheData)
						)
					)
				)
			);
		} else loReplication$ = throwError(() => `Unknown source database "${psDatabaseSourceId}".`);

		return loReplication$;
	}

	/** Exécute la réplication du document dans la base de données cible.
	 * @param psTargetId Identifiant de la base de données où répliquer le document.
	 * @param poOriginalDocument Document original à répliquer.
	 * @param poDocumentToReplicate Document contenant les révisions (historique) qu'il faut répliquer.
	 * @param poCacheData Objet cacheData du document à répliquer.
	 */
	private execReplicateDocumentIntoDatabases<T extends IStoreDocument>(
		psTargetId: string,
		poOriginalDocument: T,
		poDocumentToReplicate: PouchDB.Core.IdMeta & PouchDB.Core.GetMeta,
		poCacheData: ICacheData
	): Observable<boolean> {
		const loTarget: Database = this.moDatabaseById.get(psTargetId);
		let loReplication$: Observable<boolean>;

		if (loTarget) {
			loReplication$ = defer(() => {
				console.debug(`${Store.C_LOG_ID}Replicating document into database ${psTargetId}.`);
				return from(loTarget.defaultDatabase.bulkDocs([poDocumentToReplicate], { new_edits: false }));
			}).pipe(
				catchError((poError) => this.onReplicateDocumentIntoDatabasesFail(poDocumentToReplicate, poCacheData, poError)),
				last(),
				tap((_) => {
					this.onDocumentUpdated(
						{ id: poDocumentToReplicate._id, rev: poDocumentToReplicate._rev, ok: true },
						psTargetId,
						"REPLICATE"
					);
					StoreHelper.updateDocumentCacheData(poOriginalDocument, poCacheData);
				}),
				mapTo(true)
			);
		} else
			loReplication$ = this.onReplicateDocumentIntoDatabasesFail(
				poDocumentToReplicate,
				poCacheData,
				`Unknown target database "${psTargetId}".`
			);

		return loReplication$;
	}

	/** La réplication du document a échoué, on restaure la cacheData du document avant de lever une erreur.
	 * @param poDocument Document à répliquer.
	 * @param poCacheData Objet cacheData du document à répliquer.
	 * @param poError Erreur survenue lors de la réplication.
	 */
	private onReplicateDocumentIntoDatabasesFail<T extends IStoreDocument>(
		poDocument: T,
		poCacheData: ICacheData,
		poError: any
	): Observable<never> {
		StoreHelper.updateDocumentCacheData(poDocument, poCacheData);

		return throwError(() => poError);
	}

	/** @implements */
	public replicateToLocal(
		psDatabaseId: string,
		poReplicateOptions: IStoreReplicationOptions = {},
		psLogin?: string,
		psPassword?: string,
		pfOnProgress?: IOnProgressFunction
	): Observable<IStoreReplicationToLocalResponse> {
		let lbHasToRemoveRemoteInstance = false;
		const loDatabase: Database = this.getDatabaseById(psDatabaseId);

		return defer(async () => {
			if (!loDatabase.hasRemoteInstance()) {
				const loConfig: IConfigInitPouchDB = await this.getDatabaseConfigAsync(loDatabase, null, psLogin, psPassword);
				// Création temporaire de la base distante pour l'initialisation.
				loDatabase.createRemoteInstance(loConfig.remotePouchConfig.name, loConfig.remotePouchConfig);
				lbHasToRemoveRemoteInstance = true;
			}
		}).pipe(
			mergeMap(() => loDatabase.isLocalInstanceNew()),
			tap((pbIsNew: boolean) => {
				if (pbIsNew && !poReplicateOptions.selector && !poReplicateOptions.filter)
					poReplicateOptions.view = this.C_DELETED_FILTER_NAME;
			}),
			mergeMap((pbIsNew: boolean) => this.innerReplicateToLocal(pbIsNew, loDatabase, poReplicateOptions, pfOnProgress)),
			catchError((poError: any) => this.onReplicateToLocalError(psDatabaseId, poError)),
			finalize(() => {
				if (lbHasToRemoveRemoteInstance) loDatabase.removeRemoteInstance(); // Fermeture et suppression de la base distante temporaire.
			})
		);
	}

	/** Gère l'erreur en retournant un résultat dans le cas où c'est un problème de réseau insuffisant, ou relève l'erreur si la connexion internet est normale.
	 * @param psDatabaseId Identifiant de la base de données où s'est produit l'erreur.
	 * @param poError Erreur survenue.
	 */
	private onReplicateToLocalError(psDatabaseId: string, poError?: any): Observable<never> {
		return this.isvcNetwork
			.asyncIsNetworkReliable() // Si on a un réseau suffisant, alors c'est une erreur sinon juste erreur de manque de réseau.
			.pipe(
				mergeMap((pbHasNetwork: boolean) => {
					const lsErrorMessage = `${Store.C_LOG_ID}Error replicating database '${psDatabaseId}' from server to local.`;

					if (pbHasNetwork) console.error(lsErrorMessage, poError);
					else console.warn(lsErrorMessage, poError);

					return throwError(() => poError);
				})
			);
	}

	private innerReplicateToLocal(
		pbIsNew: boolean,
		poDatabase: Database,
		poReplicateOptions: IStoreReplicationOptions,
		pfOnProgress?: IOnProgressFunction
	): Observable<IStoreReplicationToLocalResponse> {
		// On vérifie si il y a déjà eu une synchro depuis le serveur.
		return defer(() => this.getSyncMarkerAsync(poDatabase.id))
			.pipe(
				tap((poSyncMarker: IDatabaseSyncMarker) => {
					if (poDatabase.syncType !== ESyncType.manual && !StringHelper.isBlank(poSyncMarker.remoteSequenceNumber) && StringHelper.isBlank(poReplicateOptions.since))
						poReplicateOptions.since = poSyncMarker.remoteSequenceNumber;
				}),
				mergeMap(() => this.replicate(poDatabase, "serverToLocal", poDatabase.getRemoteInstance(), poDatabase.getLocalInstance(), poReplicateOptions, pfOnProgress)),
				concatMap((poReplicationResponse: IStoreReplicationToLocalResponse) => {
					// Dans le cas d'une réplication live, on part du principe que l'initialisation des numéros de séquences
					// est géré en externe, car on aura plusieurs émissions d'évènement avec pbIsNew à true.
					if (pbIsNew && !poReplicateOptions.live) {
						return poDatabase.getLastSeqFromInstance("local").pipe(
							// Sert juste à imposer le numéro de séquence, ne réplique rien.
							mergeMap((pnLastSeq: number) => this.replicateToServer(poDatabase.id, { since: pnLastSeq })),
							mergeMap((_) => poDatabase.getLastSeqFromInstance("server")),
							// Sert juste à imposer le numéro de séquence, ne réplique rien.
							mergeMap((psLastSeq: string) =>
								this.replicate(poDatabase, "fixLocalSeq", poDatabase.getRemoteInstance(), poDatabase.getLocalInstance(), {
									since: psLastSeq
								})
							),
							map(() => poReplicationResponse)
						);
					}
					return of(poReplicationResponse);
				})
			);
	}

	/** @implements */
	public replicateToServer(
		psDatabaseId: string,
		poReplicateOptions?: IStoreReplicationOptions,
		pfOnProgress?: IOnProgressFunction
	): Observable<IStoreReplicationToServerResponse> {
		const loDatabase: Database = this.getDatabaseById(psDatabaseId);

		if (!poReplicateOptions) poReplicateOptions = {};

		return defer(() => this.getSyncMarkerAsync(loDatabase.id)).pipe(
			tap((poSyncMarker: IDatabaseSyncMarker) => {
				if (this.hasToSetLocalSequenceNumber(loDatabase, poSyncMarker, poReplicateOptions))
					poReplicateOptions.since = poSyncMarker.localSequenceNumber;
			}),
			mergeMap((_) =>
				this.replicate(
					loDatabase,
					"localToServer",
					loDatabase.getLocalInstance(),
					loDatabase.getRemoteInstance(),
					poReplicateOptions,
					pfOnProgress
				)
			),
			map((poReplicationResponse: IStoreReplicationToServerResponse) => poReplicationResponse)
		);
	}

	private hasToSetLocalSequenceNumber(
		loDatabase: Database,
		poSyncMarker: IDatabaseSyncMarker,
		poReplicateOptions: IStoreReplicationOptions
	): boolean {
		return (
			loDatabase.syncType !== ESyncType.manual &&
			!NumberHelper.isValidStrictPositive(poSyncMarker.localSequenceNumber) &&
			!NumberHelper.isValidStrictPositive(poReplicateOptions.since)
		);
	}

	/** Permet de sauvegarder les données.
	 * @param poDocument Données à sauvegarder.
	 * @param poSelectedDatabase Base de données sélectionnée.
	 * @param psDatabaseId Identifiant de la base à requêter.
	 */
	private putDocument<T extends IStoreDocument>(
		poDocument: T,
		poSelectedDatabase: PouchDB.Database,
		psDatabaseId: string
	): Observable<IStoreDataResponse> {
		return defer(() => {
			const loDocumentCopy: T = this.getSerializableDoc(poDocument);
			StoreHelper.deleteDocumentCacheData(loDocumentCopy);
			loDocumentCopy._conflicts = undefined;
			const loDocumentCacheData: ICacheData = StoreHelper.getDocumentCacheData(poDocument);

			if (loDocumentCacheData && !StringHelper.isBlank(loDocumentCacheData.databaseId)) {
				// Si document déjà en base.
				return this.innerPutDocument_put(
					poDocument,
					loDocumentCopy,
					poSelectedDatabase,
					psDatabaseId,
					loDocumentCacheData
				);
			} else return this.innerPutDocument_post(poDocument, loDocumentCopy, poSelectedDatabase, psDatabaseId);
		}).pipe(catchError((poError: any) => this.onPouchDbSaveError(poDocument, psDatabaseId, poError)));
	}

	private getSerializableDoc<T extends IStoreDocument>(poDoc: T): T {
		// On force le passage d'une instance possible en objet js pur (il peut y avoir des objets contenant des intances de classes), puis on le copie.
		return ModelResolver.toPlain(poDoc, true);
	}

	private innerPutDocument_put<T extends IStoreDocument>(
		poOriginalDocument: T,
		poDocumentCopy: T,
		poSelectedDatabase: PouchDB.Database,
		psDatabaseId: string,
		poDocumentCacheData: ICacheData
	): Observable<IStoreDataResponse> {
		return from(poSelectedDatabase.put(poDocumentCopy)).pipe(
			tap(
				(_) => this.raiseChangeEvent(poOriginalDocument, psDatabaseId, EChangeType.update),
				(poError) =>
					console.error(
						`${Store.C_LOG_ID}Put document in database "${psDatabaseId}" failed `,
						poOriginalDocument,
						` Error : `,
						poError
					)
			),
			map((poResult: PouchDB.Core.Response) => {
				poOriginalDocument._rev = poResult.rev;
				StoreHelper.updateDocumentCacheData(poOriginalDocument, { ...poDocumentCacheData, dirty: false });
				return this.onDocumentUpdated(poResult, psDatabaseId, "PUT");
			})
		);
	}

	private innerPutDocument_post<T extends IStoreDocument>(
		poOriginalDocument: T,
		poDocumentCopy: T,
		poSelectedDatabase: PouchDB.Database,
		psDatabaseId: string
	): Observable<IStoreDataResponse> {
		return from(poSelectedDatabase.post(poDocumentCopy)).pipe(
			tap(
				(_) => this.raiseChangeEvent(poOriginalDocument, psDatabaseId, EChangeType.create),
				(poError) =>
					console.error(
						`${Store.C_LOG_ID}Post document in database "${psDatabaseId}" failed '`,
						poOriginalDocument,
						"' : ",
						poError
					)
			),
			map((poResult: PouchDB.Core.Response) => {
				poOriginalDocument._id = poResult.id;
				poOriginalDocument._rev = poResult.rev;
				// On crée le cacheData indiquant la base de données source du document.
				StoreHelper.updateDocumentCacheData(poOriginalDocument, { databaseId: psDatabaseId, dirty: false });
				return this.onDocumentUpdated(poResult, psDatabaseId, "POST");
			})
		);
	}

	private raiseChangeEvent<T extends IStoreDocument>(
		poDocument: T,
		psDatabaseId: string,
		peChangeType: EChangeType
	): void;
	private raiseChangeEvent<T extends IStoreDocument>(
		paDocuments: T[],
		psDatabaseId: string,
		peChangeType: EChangeType
	): void;
	private raiseChangeEvent<T extends IStoreDocument>(
		poData: T | T[],
		psDatabaseId: string,
		peChangeType: EChangeType
	): void {
		if (poData instanceof Array)
			poData.forEach((poDocument: T) => this.raiseChangeEvent(poDocument, psDatabaseId, peChangeType));
		else {
			this.moLocalChangesSubject.next({
				document: poData,
				databaseId: psDatabaseId,
				changeType: peChangeType,
				key: poData._id
			});
		}
	}

	/** Permet de sélectionner une base en fonction de son type de synchro.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro 'remote' ou 'onlineFirst'.
	 */
	private getDatabaseInstance(psDatabaseId: string): Observable<PouchDB.Database> {
		const loDatabase: Database = this.getDatabaseById(psDatabaseId);

		// Si mode de synchro différent de 'remote' et 'onlineFirst'.
		if (loDatabase.syncType !== ESyncType.remote && loDatabase.syncType !== ESyncType.onlineFirst)
			return of(loDatabase.getLocalInstance());
		else {
			return this.isvcNetwork.asyncIsNetworkReliable().pipe(
				map((pbHasNetwork: boolean) => {
					if (pbHasNetwork) return loDatabase.getRemoteInstance();
					else
						throw new NoDatabaseInternetConnectionError(
							psDatabaseId,
							`Erreur d'envoi des données sur la base ${psDatabaseId} : pas internet, et le mode de synchro l'exige.`
						);
				})
			);
		}
	}

	private onReplicationChange(paDocs: IStoreDocument[], psDatabaseId: string): void {
		ArrayHelper.groupBy(paDocs, (poDoc: IStoreDocument) => {
			if (poDoc._deleted) return EChangeType.delete;
			else if (StoreDocumentHelper.isNew(poDoc)) return EChangeType.create;
			else return EChangeType.update;
		}).forEach((paGroupedDocs: IStoreDocument[], peChangeType: EChangeType) => {
			paGroupedDocs.forEach((poDoc: IStoreDocument) => {
				this.moRemoteChangesSubject.next({
					changeType: peChangeType,
					document: poDoc,
					databaseId: psDatabaseId,
					key: poDoc._id
				});
			});
		});
	}

	/** @implements */
	public subscribe(pfNext: Function, pfError?: Function, pfComplete?: Function): void {
		this.moEventSubject.asObservable().subscribe(
			(poResult: IStoreEvent) => pfNext(poResult),
			(poError) => pfError(poError),
			() => pfComplete()
		);
	}

	/** Regroupe les actions à exécuter lorsque le résultat est récupéré.
	 * @param paResults Liste des résultats de la requête.
	 * @param poDataSource Paramètres de la requête.
	 * @param psDatabaseId Identifiant de la base de données requêtée.
	 */
	private onGetResult<T extends IStoreDocument>(
		paResults: T[],
		poDataSource: IDataSource<T>,
		psDatabaseId: string
	): T[] {
		const lbHasToTruncate: boolean = ArrayHelper.hasElements(poDataSource.fields);

		if (poDataSource.viewParams.keys) {
			// NB : Dans le cas d'une vue on élimine en même temps les documents vides et les documents à filtrer pour ne parcourir qu'une seule fois les résultats.
			paResults = paResults.filter(
				(poDocument: T) =>
					// Le document n'est pas vide ET (aucun filtrage n'est demandé OU ALORS le document n'est pas filtré).
					!!poDocument && (!poDataSource.filter || poDataSource.filter(poDocument))
			);
		} else if (poDataSource.filter)
			// Applique un filtre aux résultats.
			paResults = paResults.filter(poDataSource.filter);

		// Ajoute l'information sur la provenance de l'entité.
		paResults.forEach((poDocument: T) => {
			if (lbHasToTruncate) this.truncateResult(poDocument, poDataSource);

			this.updateDocumentDatabaseIdCacheData<T>(poDocument, psDatabaseId);
		});

		return paResults;
	}

	private updateDocumentDatabaseIdCacheData<T extends IStoreDocument>(poDocument: T, psDatabaseId: string): T {
		StoreHelper.updateDocumentCacheData(poDocument, { databaseId: psDatabaseId });

		return poDocument;
	}

	/** Tronque une partie du document pour ne garder que ce qui nous interresse.
	 * @param poDocument Document à tronquer.
	 * @param poDataSource Paramètres de la requête.
	 */
	private truncateResult<T extends IStoreDocument>(poDocument: T, poDataSource: IDataSource<T>): void {
		for (const lsKey in poDocument) {
			// '!= undefined' permet d'accepter les valeurs booléennes négatives et la valeur 0, et n'accepte pas les valeurs 'null'.
			if (poDocument[lsKey] !== undefined && poDocument[lsKey] !== null && !poDataSource.fields.includes(lsKey))
				delete poDocument[lsKey];
		}
	}

	/** Retourne le chemin d'un document.
	 * @param poDocument Document dont il faut récupérer le chemin.
	 * @param psDefaultDatabaseId Identifiant de la base de données (optionnel).
	 */
	public static getDocumentPath<T extends IStoreDocument>(poDocument: T, psDefaultDatabaseId: string = null): string {
		return StoreHelper.getDocumentPathFromIdDatabaseId(
			poDocument._id,
			StoreHelper.getDatabaseIdFromCacheData(poDocument, psDefaultDatabaseId)
		);
	}

	/** Retourne l'identifiant de base de données d'un chemin de document.
	 * @param psDocumentPath Chemin du document.
	 */
	public static getDatabaseIdFromDocumentPath(psDocumentPath: string): string {
		return StringHelper.isBlank(psDocumentPath) || !psDocumentPath.includes("/")
			? ""
			: ArrayHelper.getFirstElement(psDocumentPath.split("/"));
	}

	/** Retourne l'identifiant du document depuis son chemin.
	 * @param psDocumentPath Chemin du document.
	 */
	public static getDocumentIdFromPath(psDocumentPath: string): string {
		return StringHelper.isBlank(psDocumentPath) ? "" : ArrayHelper.getLastElement(psDocumentPath.split("/"));
	}

	/** @implements */
	public deleteMultipleDocuments(paDocumentsIds: string[], psDatabaseId: string): Observable<boolean>;
	/** @implements */
	public deleteMultipleDocuments<T extends IStoreDocument>(
		paDocuments: T[],
		psDatabaseId?: string
	): Observable<boolean>;
	public deleteMultipleDocuments<T extends IStoreDocument>(
		paDocuments: T[] | string[],
		psDatabaseId: string = undefined
	): Observable<boolean> {
		let loDelete$: Observable<boolean>;

		if (!ArrayHelper.hasElements(paDocuments as string[])) loDelete$ = of(true);
		else loDelete$ = this.innerDeleteMultipleDocuments(paDocuments, psDatabaseId);

		return loDelete$;
	}

	/**
	 * @param paDocuments Tableau des documents à supprimer.
	 * @param psDatabaseId Identifiant de la base de données où supprimer les documents.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private innerDeleteMultipleDocuments<T extends IStoreDocument>(
		paDocuments: T[] | string[],
		psDatabaseId: string
	): Observable<boolean> {
		let laDocuments$: Observable<T[]>;
		let laDocuments: T[];

		if (typeof ArrayHelper.getFirstElement(paDocuments as string[]) === "string")
			laDocuments$ = this.getBeforeDeleteMultipleStringDocuments(paDocuments as string[], psDatabaseId);
		else laDocuments$ = of(paDocuments as T[]);

		return laDocuments$.pipe(
			mergeMap((paResultDocuments: T[]) => (laDocuments = paResultDocuments)),
			tap((poDocument: T) => (poDocument._deleted = true)),
			groupBy((poDocument: T) =>
				StringHelper.isBlank(psDatabaseId) ? StoreHelper.getDatabaseIdFromCacheData(poDocument) : psDatabaseId
			),
			mergeMap((poGroupedObservable: GroupedObservable<string, T>) => {
				return poGroupedObservable.pipe(
					toArray(),
					map((paResultDocuments: T[]) => {
						return { databaseId: poGroupedObservable.key, documents: paResultDocuments } as IGroupedDocuments<T>;
					})
				);
			}),
			mergeMap((poGroupedDocuments: IGroupedDocuments<T>) =>
				from(
					this.trackIfNeeded(this.getDatabaseById(poGroupedDocuments.databaseId), poGroupedDocuments.documents)
				).pipe(mapTo(poGroupedDocuments))
			),
			mergeMap((poGroup: IGroupedDocuments<T>) => this.bulkDocs(poGroup.databaseId, poGroup.documents)),
			map((paResponses: IStoreDataResponse[]) => this.onDocumentsUpdated(paResponses, laDocuments, EChangeType.delete)),
			reduce(
				(paAccumulatedResponses: IStoreDataResponse[], paCurrentResponses: IStoreDataResponse[]) =>
					paAccumulatedResponses.concat(paCurrentResponses),
				[]
			),
			map((paResults: Array<IStoreDataResponse>) => paResults.every((poResult: IStoreDataResponse) => poResult.ok))
		);
	}

	private getBeforeDeleteMultipleStringDocuments<T extends IStoreDocument>(
		paDocuments: string[],
		psDatabaseId: string
	): Observable<T[]> {
		if (StringHelper.isBlank(psDatabaseId))
			return throwError(() => "Documents à supprimer par identifiants mais la base de données n'est pas renseignée.");
		else return this.get({ databaseId: psDatabaseId, viewParams: { keys: paDocuments as string[] } });
	}

	/** @implements */
	public getLocal<T extends IStoreDocument>(
		psDocId: string,
		psDatabaseId: string = this.getLocalDbId()
	): Observable<T> {
		const loDatabase: Database = this.moDatabaseById.get(psDatabaseId);
		const lsDocId: string = IdHelper.buildId(EPrefix.local, psDocId);
		let loResult$: Observable<T | never>;

		if (loDatabase) {
			// On ne lève pas d'erreur car l'erreur vient du fait que nous n'avons pas trouvé de résultat.
			loResult$ = defer(() =>
				loDatabase.defaultDatabase
					.get(lsDocId)
					.then((poResult: PouchDB.Core.IdMeta & PouchDB.Core.GetMeta) =>
						this.updateDocumentDatabaseIdCacheData(poResult, psDatabaseId)
					)
					.catch((_) => undefined)
			);
		} else {
			const lsErrorMessage = `Erreur lors de la récupération du document local : la base de donnée ${psDatabaseId} n'existe pas.`;
			console.error(`${Store.C_LOG_ID}${lsErrorMessage}`);
			loResult$ = throwError(() => lsErrorMessage);
		}

		return loResult$;
	}

	/** Récupère l'identifiant de la base locale (non répliquée) de l'application. */
	private getLocalDbId(): string {
		return ArrayHelper.getFirstElement(this.getDatabasesIdsByRole(EDatabaseRole.applicationStorage));
	}

	/** Effectue une requête `put` ou `post` par PouchDB en rendant le document non réplicable..
	 * @param poDocument Donnée à enregistrer.
	 * @param psDatabaseId Identifiant de la base de données sur laquelle faire la requête, optionnel. `ApplicationStorage` par défaut.
	 * @param pbCheckRevisionBeforePut Indique si on doit vérifier ou non la révision du document (pour éviter des conflits), `false` par défaut.
	 */
	public putLocal<T extends IStoreDocument>(
		poDocument: T,
		psDatabaseId: string = this.getLocalDbId(),
		pbCheckRevisionBeforePut?: boolean
	): Observable<IStoreDataResponse> {
		poDocument._id = this.addLocalPrefix(poDocument._id);

		return this.put(poDocument, psDatabaseId, pbCheckRevisionBeforePut).pipe(
			catchError((poError) => throwError(() => `Erreur lors de la sauvegarde du document local : ${poError}`))
		);
	}

	/** @implements */
	public deleteLocal(
		poDocument: IStoreDocument,
		psDatabaseId: string = this.getLocalDbId()
	): Observable<IStoreDataResponse> {
		poDocument._id = this.addLocalPrefix(poDocument._id);

		return this.delete(poDocument, psDatabaseId).pipe(
			catchError((poError) => throwError(() => `Erreur lors de la suppression du document local : ${poError}`))
		);
	}

	/** Ajoute le prefix _local/ à une chaine de caractère et la retourne
	 * @param psId Identifiant sur lequel nous devons ajouter le prefix `_local/`.
	 */
	private addLocalPrefix(psId: string): string {
		return !StringHelper.isBlank(psId) && !psId.startsWith(EPrefix.local)
			? IdHelper.buildId(EPrefix.local, psId)
			: psId;
	}

	/** @implements */
	public getDatabaseIdsByFragmentId(psFragmentId: string): Array<string> {
		const laDatabases: Database[] = MapHelper.valuesToArray(this.moDatabaseById).filter(
			(poDatabase: Database) => poDatabase.id.indexOf(psFragmentId) >= 0
		);

		if (laDatabases.length < 1)
			throw new Error(`Impossible de trouver les bases de données comportant "${psFragmentId}" dans leur identifiant.`);
		else return laDatabases.map((poDatabase: Database) => poDatabase.id);
	}

	/** @implements */
	public getDatabaseIdByFragmentIdAndRole(psFragmentId: string, peRole: EDatabaseRole): string {
		const loDatabase: Database = MapHelper.valuesToArray(this.moDatabaseById).find(
			(poDatabase: Database) => poDatabase.id.indexOf(psFragmentId) >= 0 && poDatabase.hasRole(peRole)
		);

		if (loDatabase) return loDatabase.id;
		else
			throw new Error(
				`Impossible de trouver la base de données comportant "${psFragmentId}" dans son identifiant et ayant le rôle "${peRole}".`
			);
	}

	/** Active le debug pouchDB. */
	public static enablePouchDBDebug(): void {
		PouchDB.debug.enable("*");
	}

	/** Désactive le debug pouchDB. */
	public static disablePouchDBDebug(): void {
		PouchDB.debug.disable();
	}

	/** Récupère le nom d'un document de vue compilé à partir d'un nom de vue en remplaçant si nécessaire les '/' par des '-' pour éviter des problèmes sur pouchDB.
	 * @param psViewName Nom de la vue qu'on veut changer en nom de document.
	 */
	private getCompiledViewDocumentNameFromViewName(psViewName: string): string {
		return psViewName.replace(/\//g, "-");
	}

	/** @implements */
	public isViewCompiled(psViewName: string): boolean {
		if (!this.isRequestByView(psViewName)) return true;
		else return localStorage.getItem(this.getCompiledViewDocumentNameFromViewName(psViewName)) !== null;
	}

	/** Crée un document afin de signaler qu'une vue a été compilée.
	 * @param psViewName Nom de la vue dont il faut créer le document associé.
	 */
	private createCompiledViewDoc(psViewName: string): void {
		if (!this.isViewCompiled(psViewName)) {
			const lsViewDocName: string = this.getCompiledViewDocumentNameFromViewName(psViewName);
			try {
				localStorage.setItem(lsViewDocName, "");
			} catch (poError) {
				console.error(`${Store.C_LOG_ID}Error when creating view doc '${lsViewDocName}'`, poError);
			}
		}
	}

	/** Réinitialise une base de données d'espace de travail en supprimant les données actuelles puis recharge l'app.
	 * @param psDatabaseId Identifiant de la base de données qui a été réinitialisée.
	 */
	private resetDatabaseAndReloadApp(psDatabaseId: string): Observable<undefined>;
	/** Réinitialise une base de données d'espace de travail en supprimant les données actuelles puis recharge l'app.
	 * @param poDatabase Instance de la base de données qui a été réinitialisée.
	 */
	private resetDatabaseAndReloadApp(poDatabase: Database): Observable<undefined>;
	private resetDatabaseAndReloadApp(poDatabaseData: string | Database): Observable<undefined> {
		let loDatabase: Database | undefined;

		try {
			loDatabase = poDatabaseData instanceof Database ? poDatabaseData : this.getDatabaseById(poDatabaseData);
		} catch (poError) {
			// eslint-disable-next-line no-empty
		} // Même en cas d'erreur de récupération de la base, il faut continuer le processus.

		return this.showResetWorkspacePopup().pipe(
			mergeMap((poUiResponse: IUiResponse<boolean>) => {
				if (loDatabase) loDatabase.canReplicate = false;

				if (poUiResponse.response && loDatabase) {
					return loDatabase.destroyLocalInstance().pipe(
						tap((_) => ApplicationService.reloadApp()),
						mapTo(undefined)
					);
				} else return of(undefined);
			})
		);
	}

	private showResetWorkspacePopup(): Observable<IUiResponse<boolean>> {
		const laButtons: AlertButton[] = [];
		let lsMessage = "L'espace de travail a été réinitialisé";

		// Affichage du bouton de reset des workspaces si on est pas en beta ni en prod.
		if (ConfigData.environment.id !== EEnvironmentId.beta && ConfigData.environment.id !== EEnvironmentId.prod) {
			laButtons.push({
				text: "Supprimer",
				handler: () => UiMessageService.getTruthyResponse(),
				role: UiMessageService.C_CANCEL_ROLE
			});
			lsMessage += `, vous devez supprimer vos données pour pouvoir synchroniser votre espace de travail.
Toutes les données présentes sur votre appareil seront perdues.`;
		} else lsMessage += ".";

		laButtons.push({ text: "Continuer", handler: () => UiMessageService.getFalsyResponse() });

		return this.isvcUiMessage
			.showAsyncMessage<boolean>(
				new ShowMessageParamsPopup({
					header: "L'espace de travail a été réinitialisé",
					backdropDismiss: false,
					message: lsMessage,
					buttons: laButtons
				})
			)
			.pipe(catchError((_) => of({ response: false } as IUiResponse<boolean>)));
	}

	/** Met à jour le compteur d'actions utilisateur et émet la modification.
	 * @param pnNumber Nombre d'action à ajouter.
	 */
	private appendUserActionsCounter(pnNumber: number): void {
		if (Store.C_CANCEL_REPLICATION_ON_USER_ACTION)
			//! Permet de désactiver la mise en pause des réplications en attendant l'annulation du bulkDocs du changeTrackingReplicator.
			this.moUserActionsCounterSubject.next(this.moUserActionsCounterSubject.value + pnNumber);
	}

	private async trackIfNeeded(poDatabase: Database, poData: IStoreDocument | IStoreDocument[]): Promise<void> {
		if (this.hasToTrack(poDatabase)) {
			if (poData instanceof Array) {
				const laItems: ICreateChangeTrackerItem[] = [];

				poData.forEach((poDoc: IStoreDocument) => {
					if (!poDoc._id.startsWith(EPrefix.local)) laItems.push({ id: poDoc._id, rev: poDoc._rev });
				});
				await this.isvcChangeTracker.trackMultipleAsync(poDatabase.id, laItems);
			} else if (!poData._id.startsWith(EPrefix.local))
				await this.isvcChangeTracker.trackAsync(poDatabase.id, { id: poData._id, rev: poData._rev });
		}
	}

	private hasToTrack(poDatabase: Database): boolean {
		return (
			poDatabase.hasLocalInstance() &&
			poDatabase.hasRemoteInstance() &&
			poDatabase.localToServerReplicationMode === ELocalToServerReplicationMode.changeTracking
		);
	}

	//#region External documents

	/** Rempli les champs marqués avec le décorateur `@External`.
	 * @param paDocuments
	 * @param poOriginalDataSource
	 */
	public fillExternalDocuments$<T extends IStoreDocument>(
		paDocuments: T[],
		poOriginalDataSource: IDataSource<T>
	): Observable<T[]> {
		const loResult: IExternalDataSourceIndexationResult<T> = this.indexExternalDataSources<T>(paDocuments);
		const laGetObservables: Observable<any>[] = [];

		loResult.observablesByExternalDataSource.forEach(
			(paObservables: Observable<IDataSourceData>[], poExternalDataSource: IExternalDataSource<any>) => {
				let loPromiseTimer: Promise<number>;
				laGetObservables.push(
					combineLatest(paObservables).pipe(
						bufferUntil(() => {
							// On impose un écart minimum de 500ms pour éviter les refresh en boucle.
							if (!loPromiseTimer) {
								loPromiseTimer = timer(500).toPromise();
								return true;
							}

							return loPromiseTimer.finally(() => (loPromiseTimer = undefined));
						}),
						map((paBufferedValues: IDataSourceData[][]) => ArrayHelper.getLastElement(paBufferedValues)), // On peut ignorer les résultats intermédiaires car ils sont retournés à chaque fois
						filter((paDataSourcesData?: IDataSourceData[]) => !!paDataSourcesData),
						map((paDataSourcesData: IDataSourceData[]) =>
							this.prepareDataSourcesData(
								paDataSourcesData,
								poOriginalDataSource as IDataSourceRemoteChanges,
								poExternalDataSource
							)
						),
						switchMap((poDataSourcesData: IDataSourcesData) =>
							this.getExternalDocuments$<T>(poDataSourcesData, loResult.documentsById, poExternalDataSource)
						)
					)
				);
			}
		);
		return combineLatest(laGetObservables).pipe(
			tapError((poError: any) => console.error(`${Store.C_LOG_ID}Error while filling external documents.`, poError)),
			defaultIfEmpty(paDocuments),
			mapTo(paDocuments)
		);
	}

	private indexExternalDataSources<T extends IStoreDocument>(paDocuments: T[]): IExternalDataSourceIndexationResult<T> {
		const loPerfManager = new PerformanceManager().markStart();
		const loDocumentsById = new Map<string, T>();
		// On va indexer les dataSources par ExternalDataSource pour savoir à quelle propriété rattacher les données.
		const loObservablesByExternalDataSource = new Map<IExternalDataSource<any>, Observable<IDataSourceData>[]>();

		paDocuments.forEach((poDocument: T) => {
			// Pour chaque doc
			loDocumentsById.set(poDocument._id, poDocument);
			ExternalDataSourcesCache.get(poDocument).forEach((poExternalDataSource: IExternalDataSource<any>) => {
				// On request les external datasources
				let laObservables: Observable<IDataSourceData>[] | undefined =
					loObservablesByExternalDataSource.get(poExternalDataSource);

				if (!laObservables) loObservablesByExternalDataSource.set(poExternalDataSource, (laObservables = []));

				laObservables.push(this.prepareGetDocumentExternalDataSourceObservable$<T>(poDocument, poExternalDataSource));
			});
		});

		console.debug(
			`${Store.C_LOG_ID}External datasource observables prepared in ${loPerfManager.markEnd().measure()}ms.`,
			loObservablesByExternalDataSource
		);
		return {
			observablesByExternalDataSource: loObservablesByExternalDataSource,
			documentsById: loDocumentsById
		};
	}

	private getExternalDocuments$<T extends IStoreDocument>(
		poDataSourcesData: IDataSourcesData,
		poDocumentsById: Map<string, T>,
		poExternalDataSource: IExternalDataSource<any>
	): Observable<IStoreDocument[]> {
		// On requête toutes les données en une fois.
		return this.get(poDataSourcesData.requestableDataSource).pipe(
			tap((paResults: IStoreDocument[]) =>
				// Pour chaque dataSource unitaire
				poDataSourcesData.dataSourceViewParamsByDocId.forEach(
					(poViewParams: IDataSourceViewParams, psDocId: string) => {
						const loDocument: T | undefined = poDocumentsById.get(psDocId); // On récupère le doc d'origine correspondant

						if (loDocument) {
							const loField: any = loDocument[poExternalDataSource.fieldName];
							// Puis on filtre dans les résultats pour ne récupérer que ceux qui correspondent à la datasource du document d'origine
							const laDocuments: IStoreDocument[] = paResults.filter((poResult: IStoreDocument) =>
								this.documentMatchesViewParams(poResult._id, poViewParams)
							);
							if (loField instanceof ObservableProperty) loField.value = laDocuments;
							else loDocument[poExternalDataSource.fieldName] = laDocuments;
						}
					}
				)
			)
		);
	}

	private prepareGetDocumentExternalDataSourceObservable$<T extends IStoreDocument>(
		poDocument: T,
		poExternalDataSourceData: IExternalDataSource<any>
	): Observable<IDataSourceData> {
		return defer(() => this.getExternalDataSourceViewParams$(poExternalDataSourceData, poDocument)).pipe(
			map((poDataSource: IDataSourceViewParams) => {
				const loDataSourceData: IDataSourceData = {
					dataSourceViewParams: poDataSource,
					documentId: poDocument._id
				};

				return loDataSourceData;
			})
		);
	}

	private getExternalDataSourceViewParams$(
		poExternalDataSource: IExternalDataSource<any>,
		poDocument: IStoreDocument
	): Observable<IDataSourceViewParams> {
		if (poExternalDataSource.viewParams) return of(poExternalDataSource.viewParams(poDocument));
		else if (poExternalDataSource.viewParams$) return poExternalDataSource.viewParams$(poDocument);
		else if (poExternalDataSource.viewParamsAsync) return defer(() => poExternalDataSource.viewParamsAsync(poDocument));
		else return throwError(() => new OsappError("View params need to be specified"));
	}

	private prepareDataSourcesData(
		paDataSourcesData: IDataSourceData[],
		poOriginalDataSource: IDataSourceRemoteChanges,
		poExternalDataSource: IExternalDataSource<any>
	): IDataSourcesData {
		const loViewParams: IDataSourceViewParams = {};
		const loDataSourceViewParamsByDocId = new Map<string, IDataSourceViewParams>();

		const loPerfManager = new PerformanceManager().markStart();
		// On fusionne les viewParams de toutes le datasources unitaires pour ne faire qu'une seule requête par la suite.
		paDataSourcesData.forEach((poDataSourceData: IDataSourceData) => {
			loDataSourceViewParamsByDocId.set(poDataSourceData.documentId, poDataSourceData.dataSourceViewParams);

			this.fillViewParamsFromDataSource(loViewParams, poDataSourceData);
		});
		console.debug(
			`${Store.C_LOG_ID}External viewparams prepared in ${loPerfManager.markEnd().measure()}ms.`,
			loViewParams
		);

		if (!StringHelper.isBlank(loViewParams.endkey?.toString())) loViewParams.endkey += Store.C_ANYTHING_CODE_ASCII;

		// On crée la datasource unique
		const loDataSource: IDataSourceRemoteChanges = {
			live: poOriginalDataSource.live,
			remoteChanges: poOriginalDataSource.remoteChanges,
			activePageManager: poOriginalDataSource.activePageManager,
			viewParams: loViewParams,
			baseClass: poExternalDataSource.baseClass,
			role: poExternalDataSource.role
		};

		return {
			dataSourceViewParamsByDocId: loDataSourceViewParamsByDocId,
			requestableDataSource: loDataSource
		};
	}

	private fillViewParamsFromDataSource(poViewParams: IDataSourceViewParams, poDataSourceData: IDataSourceData): void {
		poViewParams.startkey = this.prepareRangeKey(poDataSourceData.dataSourceViewParams.startkey, poViewParams.startkey);
		poViewParams.endkey = this.prepareRangeKey(poDataSourceData.dataSourceViewParams.endkey, poViewParams.endkey);

		if (poDataSourceData.dataSourceViewParams.keys)
			poViewParams.keys = [...(poViewParams.keys ?? []), ...poDataSourceData.dataSourceViewParams.keys];

		poViewParams.include_docs = poViewParams.include_docs || poDataSourceData.dataSourceViewParams.include_docs;
	}

	/** Recherche le début commun aux 2 clés.
	 * @param poDataSourceKey
	 * @param poViewParamsKey
	 * @returns
	 */
	private prepareRangeKey(
		poDataSourceKey?: string | number | string[],
		poViewParamsKey?: string | number | string[]
	): string | string[] | undefined {
		if (!ObjectHelper.isDefined(poViewParamsKey))
			return poDataSourceKey instanceof Array ? poDataSourceKey : this.keyToString(poDataSourceKey);
		else {
			if (poDataSourceKey instanceof Array && poViewParamsKey instanceof Array) {
				const laMatchingKeys: string[] = [];

				for (let lnIndex = 0; lnIndex < poDataSourceKey.length; ++lnIndex) {
					const lsMatchingSubstring: string | undefined = StringHelper.getMatchingSubstring(
						poViewParamsKey[lnIndex],
						poDataSourceKey[lnIndex]
					);

					if (StringHelper.isBlank(lsMatchingSubstring)) break;

					laMatchingKeys.push(lsMatchingSubstring);
				}

				return [];
			} else if (!(poDataSourceKey instanceof Array) && !(poViewParamsKey instanceof Array)) {
				const lsFilledKey: string = this.keyToString(poViewParamsKey);
				const lsDataSourceKey: string = this.keyToString(poDataSourceKey);
				if (!StringHelper.isBlank(lsFilledKey)) return StringHelper.getMatchingSubstring(lsFilledKey, lsDataSourceKey);
				return lsFilledKey;
			}
		}
		return undefined;
	}

	private keyToString(poKey?: string | number): string | undefined {
		return typeof poKey === "string" ? poKey : poKey?.toString();
	}

	public getServerToLocalReplicationTaskId(psDbId: string): string {
		return `${ETaskPrefix.dbSync}to_local_${psDbId}`;
	}

	public getLocalToServerReplicationTaskId(psDbId: string): string {
		return `${ETaskPrefix.dbSync}${psDbId}`;
	}

	//#endregion External documents

	//#region Synchro

	/** Force la synchronisation local-serveur et serveur-local d'une base de données.
	 * @param psDatabaseId Identifiant de la base de données dont il faut foricer la synchronisation.
	 * @param pnSince Depuis quel point de contrôle on synchronise les données, `0` par défaut.
	 * @throws
	 */
	public async forceSynchronizeAsync(psDatabaseId: string, pnSince: number = 0): Promise<void> {
		if (!this.isvcFlag.getFlagValue(ENetworkFlag.isOnlineReliable)) throw new NoOnlineReliableNetworkError();

		const loClone: Database = this.getForceSynchronizeDatabase(psDatabaseId);

		if (!loClone.hasLocalAndRemoteInstance()) this.showCanNotForceSynchronizePopup(loClone);
		else {
			const loLoader: Loader = await this.isvcLoading.create(
				"Synchronisation vers le serveur en cours<br/>Cette opération peut durer un certains temps"
			);
			await loLoader.present();
			const loReplicateLocalServerParams: IReplicateDatabaseParams = this.createReplicateLocalServerParams(
				loClone,
				pnSince
			);
			// On surcharge la méthode car les numéros de séquence étant inférieurs, ça générerait des logs d'erreur.
			const lfOriginalLogSequenceNumberFunction = this.logSequenceNumber;
			this.logSequenceNumber = (_, __, ___, ____) => { };

			this.isvcLogger.action(
				Store.C_LOG_ID,
				`Force synchronize for database '${loClone.id}' began`,
				ELogActionId.forceSyncBegin
			);

			await this.execForceSynchronizeAsync(loReplicateLocalServerParams).finally(() => {
				// Il faut remettre la logique initiale de la fonction à la fin de cette méthode.
				this.logSequenceNumber = lfOriginalLogSequenceNumberFunction;
				// On clôture le sujet pour éviter les fuites mémoires.
				loReplicateLocalServerParams.replicationEndSubject.complete();
				// On enlève le loader.
				loLoader.dismiss();
			});
		}
	}

	private getForceSynchronizeDatabase(psDatabaseId: string): Database {
		const loClone: Database = Database.clone(this.getDatabaseById(psDatabaseId));
		Object.defineProperty(loClone, "localToServerReplicationMode", {
			writable: false,
			value: ELocalToServerReplicationMode.classic
		});

		return loClone;
	}

	private showCanNotForceSynchronizePopup(poDatabase: Database): void {
		const lsMissingInstance: string = poDatabase.hasLocalInstance()
			? "l'instance distante"
			: poDatabase.hasRemoteInstance()
				? "l'instance locale"
				: "les instances locales et distantes";

		this.isvcUiMessage.showPopupMessage(
			new ShowMessageParamsPopup({
				header: "Impossible",
				message: `La base de données ne peut pas être synchronisée car il manque ${lsMissingInstance} !`
			})
		);
	}

	private createReplicateLocalServerParams(poDatabase: Database, pnSince: number): IReplicateDatabaseParams {
		return this.createReplicateDatabaseParams(
			poDatabase,
			"localServer",
			poDatabase.getLocalInstance(),
			poDatabase.getRemoteInstance(),
			{ since: pnSince }
		);
	}

	private execForceSynchronizeAsync(poReplicateLocalServerParams: IReplicateDatabaseParams): Promise<void> {
		const loPerfManager = new PerformanceManager().markStart();

		return this.replicateDatabase(poReplicateLocalServerParams)
			.pipe(takeUntil(poReplicateLocalServerParams.replicationEndSubject.asObservable()))
			.toPromise()
			.then((_) =>
				this.isvcLogger.action(
					Store.C_LOG_ID,
					`Force synchronize for database '${poReplicateLocalServerParams.database.id}' ended in ${loPerfManager
						.markEnd()
						.measure()}ms`,
					ELogActionId.forceSyncEnd
				)
			)
			.catch((poError) => {
				this.isvcLogger.action(
					Store.C_LOG_ID,
					`Force synchronize for database '${poReplicateLocalServerParams.database.id}' error after ${loPerfManager
						.markEnd()
						.measure()}ms`,
					ELogActionId.forceSyncError,
					undefined,
					poError
				);
				throw poError;
			});
	}

	//#endregion Synchro

	//#endregion
}
