import { Injectable, OnDestroy } from '@angular/core';
import { SqlWrapper } from '@calaosoft/osapp-common/sqlite/classes/sql-wrapper';
import { PlatformService } from '../../../services/platform.service';
import { SqlAdapter } from '../models/SqlAdapter';
import { SqlDatabaseProvider } from '../models/SqlDatabaseProvider';

@Injectable()
export class SqlService extends SqlWrapper implements OnDestroy {

	//#region FIELDS

	protected static override readonly C_LOG_ID: string = "SQL.S::";

	//#endregion FIELDS

	//#region METHODS

	constructor(
		psvcProvider: SqlDatabaseProvider,
		psvcAdapter: SqlAdapter<any>,
		psvcPlatform: PlatformService
	) {
		super(psvcProvider, psvcAdapter, psvcPlatform.isMobileApp);
	}

	public ngOnDestroy(): void {
		this.moInitializedSubject.complete();
	}

	//#endregion METHODS

}