import { ChangeDetectionStrategy, Component } from '@angular/core';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Database } from '../../../../model/store/Database';
import { BackgroundTaskService } from '../../../../services/backgroundTask.service';
import { TaskBase } from '../../../../services/backgroundTask/TaskBase';
import { PlatformService } from '../../../../services/platform.service';
import { ModalComponentBase } from '../../../modal';
import { DatabaseSynchroService } from '../../services/database-synchro.service';

@Component({
	selector: 'calao-databases-sync-status-modal-detail-view',
	templateUrl: './databases-sync-status-modal-detail-view.component.html',
	styleUrls: ['./databases-sync-status-modal-detail-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatabasesSyncStatusModalDetailViewComponent extends ModalComponentBase<void> {

	//#region PROPERTIES

	public readonly tasks$: Observable<TaskBase[]> = this.getSyncTasks$().pipe(secure(this));

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcDatabaseSynchro: DatabaseSynchroService,
		private readonly isvcBackgroundTask: BackgroundTaskService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	private getSyncTasks$(): Observable<TaskBase[]> {
		const laDatabases: Database[] = this.isvcDatabaseSynchro.getGroupingConfigsDatabases();
		const laTaskIds: string[] = this.isvcDatabaseSynchro.getSyncTaskIds(laDatabases);

		return this.isvcBackgroundTask.getTasksByIds$(laTaskIds);
	}

	//#endregion METHODS

}
