import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { Observable, Subscription, combineLatest, fromEvent } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { LinkInfo } from '../../model/link/LinkInfo';
import { IPopoverItemParams } from '../../model/popover/IPopoverItemParams';
import { ApplicationService } from '../../services/application.service';
import { ConversationService } from '../../services/conversation.service';
import { EntityLinkService } from '../../services/entityLink.service';
import { GlobalDataService } from '../../services/global-data.service';
import { NotificationService } from '../../services/notification.service';
import { PlatformService } from '../../services/platform.service';
import { PopoverService } from '../../services/popover.service';
import { CanGoBackService } from '../routing/services/can-go-back.service';
import { PageManagerService } from '../routing/services/pageManager.service';
import { IHeaderParams } from './model/IHeaderParams';

/** Template de header standard. Ce header est composé du bouton back, du bouton menu, et d'un titre.
 * Ce template peut être utilisé grâce à la directive `HeaderDirective` ou bien avec le selecteur `osapp-header`.
 * Il est possible d'ajouter du contenu à la balise qui sera affiché dans le header ionic.
 */
@Component({
	selector: "osapp-header",
	templateUrl: './header.component.html',
	styleUrls: ["./header-template.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends ComponentBase implements IHeaderParams, OnDestroy {

	//#region FIELDS

	/** Abonnement du bouton natif 'back'. */
	private readonly moBackButtonSubscription: Subscription;

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly homeRouteUrl: string = `/${ApplicationService.C_HOME_ROUTE_URL}`;

	private msTitle = "";
	/** @implements */
	public get title(): string { return this.msTitle; }
	@Input() public set title(psNewValue: string | undefined | null) {
		if (this.msTitle !== psNewValue) {
			this.msTitle = psNewValue;
			this.detectChanges();
		}
	}

	private mbHasHomeButton = true;
	/** @implements */
	public get hasHomeButton(): boolean { return this.mbHasHomeButton; }
	@Input() public set hasHomeButton(pbNewValue: boolean | string) {
		const lbNewValue: boolean = coerceBooleanProperty(pbNewValue);
		if (this.mbHasHomeButton !== lbNewValue) {
			this.mbHasHomeButton = lbNewValue;
			this.detectChanges();
		}
	}

	private mbHasBackButton = true;
	/** @implements */
	public get hasBackButton(): boolean { return this.mbHasBackButton; }
	@Input() public set hasBackButton(pbNewValue: boolean | string) {
		const lbNewValue: boolean = coerceBooleanProperty(pbNewValue);
		if (this.mbHasBackButton !== lbNewValue) {
			this.mbHasBackButton = lbNewValue;
			this.detectChanges();
		}
	}

	private mbHasSyncButton = false;
	/** @implements */
	public get hasSyncButton(): boolean { return this.mbHasSyncButton; }
	@Input() public set hasSyncButton(pbNewValue: boolean | string) {
		const lbNewValue: boolean = coerceBooleanProperty(pbNewValue);
		if (this.mbHasSyncButton !== lbNewValue) {
			this.mbHasSyncButton = lbNewValue;
			this.detectChanges();
		}
	}

	private msTitleColor: string;
	/** @implements */
	public get titleColor(): string { return this.msTitleColor; }
	@Input() public set titleColor(psNewValue: string) {
		if (this.msTitleColor !== psNewValue) {
			this.msTitleColor = psNewValue;
			this.detectChanges();
		}
	}

	/** @implements */
	@Input() public hasMenuButton: boolean;
	@ObserveProperty<HeaderComponent>({ sourcePropertyKey: "hasMenuButton", transformer: (pbNewValue: boolean) => coerceBooleanProperty(pbNewValue) })
	public readonly observableHasMenuButton = new ObservableProperty<boolean>(true);

	private mfCustomBackButtonAction: () => void;
	/** @implements */
	public get customBackButtonAction(): () => void { return this.mfCustomBackButtonAction; }
	@Input() public set customBackButtonAction(pfCustomAction: () => void) {
		this.mfCustomBackButtonAction = pfCustomAction;
		this.detectChanges();
	}

	private readonly moPopoverItems = new ObservableArray<IPopoverItemParams>();
	/** @implements */
	public get popoverItems(): ObservableArray<IPopoverItemParams> { return this.moPopoverItems; }
	@Input() public set popoverItems(poData: ReadonlyArray<IPopoverItemParams> | ObservableArray<IPopoverItemParams>) {
		if (poData instanceof ObservableArray)
			this.moPopoverItems.resetSubscription(poData.changes$);
		else
			this.moPopoverItems.resetArray(poData);
	}

	/** Url par défaut. */
	@Input() public defaultHref: string;
	@ObserveProperty<HeaderComponent>({ sourcePropertyKey: "defaultHref", transformer: (psNewDefaultHref?: string) => psNewDefaultHref ?? ApplicationService.C_HOME_ROUTE_URL })
	public readonly observableDefaultHref = new ObservableProperty<string>();

	/** @implements */
	@Input() public menuButtonDisappearAtWidthPx: number;
	@ObserveProperty<HeaderComponent>({ sourcePropertyKey: "menuButtonDisappearAtWidthPx", transformer: (poNewValue: any) => coerceNumberProperty(poNewValue) })
	public readonly observablemenuButtonDisappearAtWidthPx = new ObservableProperty<number>();

	private mbasConversationsButton: boolean;
	/** @implements */
	public get hasConversationsButton(): boolean { return this.mbasConversationsButton; }
	@Input() public set hasConversationsButton(pbNewValue: boolean | string) {
		this.mbasConversationsButton = coerceBooleanProperty(pbNewValue);
	}
	@ObserveProperty<HeaderComponent>({ sourcePropertyKey: "hasConversationsButton", transformer: (pbNewValue: boolean) => coerceBooleanProperty(pbNewValue) })
	public readonly observableHasConversationsButton = new ObservableProperty<boolean>();

	/** Doc propriété. */
	@Input() public conversationsButtonDisappearAtWidthPx: number;
	@ObserveProperty<HeaderComponent>({ sourcePropertyKey: "conversationsButtonDisappearAtWidthPx" })
	public readonly observableconversationsButtonDisappearAtWidthPx = new ObservableProperty<number>();

	public readonly hasConversationsButton$: Observable<boolean> = this.getHasConversationButton$();

	public readonly hasMenuButton$: Observable<boolean> = this.getHasMenuButton$();

	public canGoBack$: Observable<boolean> = this.observableDefaultHref.value$.pipe(
		startWith(""),
		switchMap((psDefaultHref: string) => this.isvcCanGoBack.canGoBack$(psDefaultHref)),
		secure(this)
	);

	/** Flux qui indique s'il y a des options de présentes. */
	public readonly hasPopoverItems$: Observable<boolean> = this.moPopoverItems.changes$.pipe(map((paItems: IPopoverItemParams[]) => paItems.length > 0));

	public readonly nbOfUnreadConv$: Observable<number> =
		this.isvcGlobalData.getData(ConversationService.C_NB_OF_UNREAD_CONV_DATA_KEY).pipe(secure(this));

	/** `true` si le bouton pour accéder à la page des notifications doit être affiché, sinon `false`. Est à `false` par défaut. */
	@Input() public hasNotificationsButton?: boolean;
	@ObserveProperty<HeaderComponent>({ sourcePropertyKey: "hasNotificationsButton" })
	public readonly observableHasNotificationsButton = new ObservableProperty<boolean>(false);

	public readonly nbOfNotif$: Observable<number> =
		this.isvcGlobalData.getData(NotificationService.C_NB_OF_NOTIF_DATA_KEY).pipe(secure(this));

	public readonly linkInfo$: Observable<LinkInfo> = this.isvcEntityLink.observableLinkInfo.value$.pipe(secure(this));

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPageManager: PageManagerService,
		private readonly isvcCanGoBack: CanGoBackService,
		private readonly ioRouter: Router,
		private readonly isvcPopover: PopoverService,
		private readonly isvcGlobalData: GlobalDataService,
		private readonly isvcEntityLink: EntityLinkService,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcPlatform: PlatformService
	) {
		super(poChangeDetectorRef);
		this.moBackButtonSubscription = psvcPlatform.getBackButtonSubscription(() => this.goBack());
	}

	public goHome(): void {
		this.isvcPageManager.goHome(this.homeRouteUrl);
	}

	public goBack(): void {
		if (this.customBackButtonAction)
			this.customBackButtonAction();
		else
			this.isvcPageManager.goBack();
	}

	/** Ouvre le menu contextuel de la page de prélèvement.
	 * @param poEvent Événement de la souris lors du clic.
	 */
	public async openOptions(poEvent: MouseEvent): Promise<void> {
		await this.isvcPopover.showPopoverAsync(this.moPopoverItems, poEvent)
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moBackButtonSubscription.unsubscribe();
	}

	public routeToConversations(): void {
		this.ioRouter.navigate(["conversations"]);
	}

	public routeToNotifications(): void {
		this.ioRouter.navigate(["notifications"]);
	}

	private getHasMenuButton$(): Observable<boolean> {
		return combineLatest([
			this.observableHasMenuButton.value$,
			combineLatest([fromEvent(window, "resize").pipe(startWith({}), map(() => window.innerWidth)), this.observablemenuButtonDisappearAtWidthPx.value$]).pipe(
				map(([pnWindowWith, pnMaxWidth]: [number, number]) => pnWindowWith < pnMaxWidth),
				startWith(true)
			)
		]).pipe(
			map(([pbHasMenuButton, pbCanDisplayMenuButton]: [boolean, boolean]) => pbHasMenuButton && pbCanDisplayMenuButton),
			takeUntil(this.destroyed$)
		);
	}

	private getHasConversationButton$(): Observable<boolean> {
		return combineLatest([
			this.observableHasConversationsButton.value$,
			combineLatest([fromEvent(window, "resize").pipe(startWith({}), map(() => window.innerWidth)), this.observableconversationsButtonDisappearAtWidthPx.value$]).pipe(
				map(([pnWindowWith, pnMaxWidth]: [number, number]) => pnWindowWith < pnMaxWidth),
				startWith(true)
			)
		]).pipe(
			map(([pbHasConversationsButton, pbCanDisplayConversationsButton]: [boolean, boolean]) => pbHasConversationsButton && pbCanDisplayConversationsButton),
			takeUntil(this.destroyed$)
		);
	}


	//#endregion

}