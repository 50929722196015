import { Type } from "@angular/core";
import { afterSubscribe } from "@calaosoft/osapp-common/rxjs/operators/after-subscribe";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { IdHelper } from "@calaosoft/osapp-common/utils/helpers/idHelper";
import { EPrefix } from "@calaosoft/osapp-common/utils/models/EPrefix";
import { Observable, of, throwError } from "rxjs";
import { filter, mergeMap, take } from "rxjs/operators";
import { IApplicationEvent } from "../../../model/application/IApplicationEvent";
import { TaskBase } from "../../../services/backgroundTask/TaskBase";
import { InjectorService } from "../../../services/injector.service";
import { EventsService } from "../../events/events.service";
import { EventParticipantEventBase } from "../models/event-participant-event-base";
import { IEventParticipantEventData } from "../models/ievent-participant-event-data";
import { IEventParticipantEventEndedData } from "../models/ievent-participant-event-ended-data";
import { IInvitationParams } from "../models/iinvitation-params";

export abstract class EventParticipantTaskBase extends TaskBase<IInvitationParams> {

	//#region FIELDS

	private readonly msvcEvents: EventsService = InjectorService.instance.get(EventsService);

	protected abstract startEventType: Type<EventParticipantEventBase<IEventParticipantEventData>>;
	protected abstract endEventType: Type<EventParticipantEventBase<IEventParticipantEventEndedData>>;

	//#endregion

	//#region METHODS

	protected override execTask$(): Observable<EventParticipantEventBase<IEventParticipantEventEndedData>> {
		return this.msvcEvents.events$.pipe(
			afterSubscribe(() => this.msvcEvents.raiseEvent(this.prepareStartEvent({
				params: this.descriptor.params,
				eventId: this.getEventId(),
				occurrenceDate: this.getEventOccurrenceDate()
			}))),
			filter((poEvent: IApplicationEvent) =>
				poEvent instanceof this.endEventType && this.descriptor.id === poEvent.data.id
			),
			take(1),
			mergeMap((poEvent: EventParticipantEventBase<IEventParticipantEventEndedData>) =>
				poEvent.data.error ? throwError(() => poEvent.data.error) : of(poEvent)
			)
		);
	}

	private prepareStartEvent(
		...paParams: ConstructorParameters<typeof EventParticipantEventBase<IEventParticipantEventData>>
	): EventParticipantEventBase<IEventParticipantEventData> {
		return new this.startEventType(...paParams);
	}

	private getEventId(): string {
		return IdHelper.extractIdWithPrefix(this.descriptor.id, EPrefix.event);
	}

	private getEventOccurrenceDate(): Date | undefined {
		return ArrayHelper.getFirstElement(IdHelper.extractDatesFromId(this.descriptor._id));
	}

	//#endregion

}