import { Injectable } from '@angular/core';
import { EPlatform } from '@calaosoft/osapp-common/applications/models/EPlatform';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';

@Injectable({ providedIn: "root" })
export class PlatformService {

	//#region FIELDS

	private mnBackButtonPriority = 9999;

	private static readonly C_LOG_ID = "PLATFORM.S::";

	//#endregion

	//#region PROPERTIES

	private msUuid: string;
	/** Identifiant unique de l'appareil. */
	public get uuid(): string {
		return this.msUuid;
	}

	private msModel: string;
	/** Modèle de l'appareil qui fait tourner l'app. */
	public get model(): string {
		return this.msModel;
	}

	private mePlatform: EPlatform;
	/** Plateforme de l'appareil qui fait tourner l'app. */
	public get platform(): EPlatform {
		if (this.mePlatform)
			return this.mePlatform;

		let lsCapPlatform: string = Capacitor.getPlatform();

		if (lsCapPlatform === "web") // Retrocompat pour que les platform capacitor correspondent avec les anciennes de cordova
			lsCapPlatform = EPlatform.browser;

		return this.mePlatform = StringHelper.isBlank(lsCapPlatform) ? EPlatform.browser : lsCapPlatform as EPlatform;
	}

	private mbIsMobileApp: boolean;
	/** Indique si l'on est sur une application mobile native.
	 * @returns `true` si application android ou ios.
	 * @returns `false` si site web (ouvert sur mobile ou pc).
	 */
	public get isMobileApp(): boolean {
		if (typeof this.mbIsMobileApp === "boolean")
			return this.mbIsMobileApp;
		else
			return this.mbIsMobileApp = Capacitor.isNativePlatform();
	}

	/** `true` si l'appareil est sous Android, `false` sinon. */
	public get isAndroid(): boolean { return this.ioPlatform.is("android"); }

	/** `true` si l'appareil est sous iOS, `false` sinon. */
	public get isIOS(): boolean { return this.ioPlatform.is("ios"); }

	private mbIsVirtual: boolean;
	/** Retourne `true` si on est sur un device virtuel, `false` sinon. */
	public get isVirtual(): boolean {
		return this.mbIsVirtual;
	}

	/** Identifiant du wiget. */
	private msWidgetId: string;
	/** Récupère l'identifiant du widget de l'application, `fr.calaosoft.appName` par exemple. Retourne une chaîne vide si l'identifiant n'est pas disponible. */
	public get widgetId(): string {
		return this.msWidgetId;
	}

	private msAppVersion: string;
	/** Récupère le numéro de version de l'application. */
	public get appVersion(): string {
		return this.msAppVersion;
	}

	private mbIsMobile: boolean;
	public get isMobile(): boolean {
		if (this.mbIsMobile === undefined)
			this.mbIsMobile = this.ioPlatform.is("mobile") || this.ioPlatform.is("mobileweb");

		return this.mbIsMobile;
	}

	/** Notifie les abonnés que l'application passe en arrière plan. */
	public get onPause$(): Observable<void> { return this.ioPlatform.pause.asObservable(); }

	/** Notifie les abonnés que l'application est de nouveau active. */
	public get onResume$(): Observable<void> { return this.ioPlatform.resume.asObservable(); }

	/** Attend que la plateforme soit prête avant de retourner une valeur. */
	public get readyAsync(): Promise<string> {
		return this.ioPlatform.ready();
	}

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion de la plateforme. */
		private readonly ioPlatform: Platform
	) { }

	public async initPlatformInfo(): Promise<void> {
		try {
			const loDeviceInfo: DeviceInfo = await Device.getInfo();

			this.mbIsVirtual = loDeviceInfo.isVirtual;
			this.mbIsMobile = loDeviceInfo.operatingSystem === "android" || loDeviceInfo.operatingSystem === "ios";
			this.msModel = (this.isMobileApp || this.isVirtual) ? loDeviceInfo.model : this.detectBrowser();
		}
		catch (poError) {
			console.warn(`${PlatformService.C_LOG_ID}Error Device.getInfo`, poError);
		}
		try {
			this.msUuid = (await Device.getId()).identifier;
		}
		catch (poError) {
			console.warn(`${PlatformService.C_LOG_ID}Error Device.getId`, poError);
		}

		await this.initAppInfo();
	}

	private async initAppInfo(): Promise<void> {
		try {
			const loAppInfo: AppInfo = await App.getInfo();

			this.msWidgetId = loAppInfo.id;
			this.msAppVersion = loAppInfo.version;
		}
		catch (poError) {
			console.warn(`${PlatformService.C_LOG_ID}Error App.getInfo`, poError);
		}
	}

	/** Détecte le navigateur internet utilisé. */
	private detectBrowser(): string {
		let lsPlatform: string;

		if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document["documentMode"]))
			lsPlatform = "IE";

		else if (navigator.appVersion.indexOf("Edge") > -1)
			lsPlatform = "Edge";

		else if (navigator.userAgent.toLowerCase().indexOf("op") > -1)
			lsPlatform = "Opera";

		else if (navigator.userAgent.indexOf("Chrome") !== -1)
			lsPlatform = "Chrome";

		else if (navigator.userAgent.indexOf("Firefox") !== -1)
			lsPlatform = "FireFox";

		else if (navigator.userAgent.indexOf("Safari") !== -1)
			lsPlatform = "Safari";

		else
			lsPlatform = "other";

		return lsPlatform;
	}

	/** Retourne l'abonnement aux événements de clic sur le bouton physique `back` et gère leur priorité.
	 * ### Penser à se désabonner à la destruciton du composant qui veut s'abonner.
	 * @param pfAction Action à réaliser lors d'un clic sur le bouton physique `back` de l'appareil.
	 */
	public getBackButtonSubscription(pfAction: () => void): Subscription {
		return this.ioPlatform.backButton.subscribeWithPriority(
			++this.mnBackButtonPriority,
			pfAction
		);
	}

	public getDeviceInfoAsync(): Promise<DeviceInfo> {
		return Device.getInfo();
	}

	//#endregion

}