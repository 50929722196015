import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { IGroupFilterValues } from '../../model/igroup-filter-values';

@Component({
	selector: 'calao-groups-filter-bar',
	templateUrl: './groups-filter-bar.component.html',
	styleUrls: ['./groups-filter-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsFilterBarComponent {

	//#region FIELDS

	/** Événement lors du changement des filtres. */
	@Output("onGroupFilterValuesChange") private readonly moFilterValuesChangeEvent = new EventEmitter<IGroupFilterValues>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Valeur des filtres. */
	@Input() public filterValues?: IGroupFilterValues;
	@ObserveProperty<GroupsFilterBarComponent>({ sourcePropertyKey: "filterValues" })
	public readonly observableFilterValues = new ObservableProperty<IGroupFilterValues>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor() { }

	public onFilterValuesChange(poFilterValues: IGroupFilterValues): void {
		this.moFilterValuesChangeEvent.emit(this.observableFilterValues.value = poFilterValues);
	}

	//#endregion METHODS

}
