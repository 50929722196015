import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { ISearchOptions } from '../../../../model/search/ISearchOptions';
import { ISite } from '../../models/isite';
import { SitesService } from '../../services/sites.service';

@Component({
	selector: 'calao-sites-list',
	templateUrl: './sites-list.component.html',
	styleUrls: ['./sites-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SitesListComponent extends ComponentBase {

	//#region PROPERTIES

	private maSites: ISite[];
	public get sites(): ISite[] { return this.maSites; }
	@Input() public set sites(paSites: ISite[]) {
		if (!ArrayHelper.areArraysFromDatabaseEqual(paSites, this.maSites)) {
			this.maSites = paSites;
			this.filteredSites = paSites;
		}
	}

	@Input() public searchAttributes: string[] = ["name"];
	@Input() public showSearchBar: boolean = true;
	public filteredSites: ISite[];

	/** Valeur recherchée dans l'input de recherche. */
	public searchValue: string;
	public searchOptions: ISearchOptions<ISite> = {
		hasPreFillData: true,
		searchboxPlaceholder: "Rechercher un site"
	}

	//#endregion

	//#region METHODS

	constructor(
		private isvcSites: SitesService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public editSite(poSite: ISite): void {
		this.isvcSites.routeToSite(poSite);
	}

	public onFilteredSitesChanged(paSites: ISite[]): void {
		this.filteredSites = paSites;
		this.detectChanges();
	}

	public getSiteAvatar(poSite: ISite): IAvatar {
		return SitesService.createSiteAvatar(poSite);
	}

	//#endregion

}
