import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { EMessageAttachmentType } from "./emessage-attachment-type";
import { IMessageAiSummaryAttachment } from "./imessage-ai-summary-attachment";
import { IMessageAttachment } from "./imessage-attachment";
import { MessageAttachment } from "./message-attachment";

const C_TYPE = EMessageAttachmentType.aiSummary;

@ModelMatch((poData: IMessageAttachment) => poData.type === C_TYPE, MessageAttachment)
export class MessageAiSummaryAttachment extends MessageAttachment implements IMessageAiSummaryAttachment {

	//#region PROPERTIES

	public summaryText!: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: Omit<IMessageAiSummaryAttachment, "type">) {
		super({ ...(poData ?? {}), type: C_TYPE });
	}

	//#endregion METHODS

}
