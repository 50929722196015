import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';

export class UnzipError extends Error {

	constructor(psMessage?: string) {
		super(`Erreur lors du dézippage.${StringHelper.isBlank(psMessage) ? "" : ` ${psMessage}`}`);
		// Nécessaire pour que le 'instanceof UnzipError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, UnzipError);
	}

}