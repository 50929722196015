import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';

export class NotSupportedPlatformError extends Error {

	constructor() {
		super("Not supported platform");
		// Nécessaire pour que le 'instanceof NotSupportedPlatformError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, NotSupportedPlatformError);
	}

}