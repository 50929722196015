import { IStoreReplicationResponse } from '@calaosoft/osapp-common/store/models/IStoreReplicationResponse';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ENetworkFlag } from '../../model/application/ENetworkFlag';
import { IStoreTaskParams } from '../../model/backgroundTask/taskParams/IStoreTaskParams';
import { ISynchronizationEvent } from '../../modules/store/model/isynchronization-event';
import { StoreTask } from './StoreTask';
import { TaskDescriptor } from './TaskDescriptor';

export class DbToLocalTask<T extends IStoreTaskParams> extends StoreTask<T> {

	//#region FIELDS

	private msDbId: string;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.msDbId = this.descriptor.params.dbId;
	}

	protected override execTask$(): Observable<IStoreReplicationResponse> {
		return this.msvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(
				mergeMap(_ => this.msvcStore.replicateToLocal(
					this.msDbId,
					undefined,
					undefined,
					undefined,
					(poEvent: ISynchronizationEvent) => this.moObservableProgress.value = this.getProgress(poEvent)
				))
			);
	}

	//#endregion
}