import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Input, Output, QueryList, forwardRef } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DestroyableComponentBase } from '../../../../utils/components/destroyable-component-base';
import { ColumnListHeaderColumnComponent } from '../column-list-header-column/column-list-header-column.component';
import { ColumnListRowColumnComponent } from '../column-list-row-column/column-list-row-column.component';

@Component({
	selector: 'calao-column-list-row',
	templateUrl: './column-list-row.component.html',
	styleUrls: ['./column-list-row.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnListRowComponent<T> extends DestroyableComponentBase implements AfterContentInit {

	//#region FIELDS

	/** Événement lors du clic sur le détail. */
	@Output("onDetailClicked") private readonly moDetailClickedEvent = new EventEmitter<MouseEvent>();
	// forwardRef car problème de dépendance circulaire
	@ContentChildren(forwardRef(() => ColumnListRowColumnComponent<T>), { descendants: true })
	private moColumns: QueryList<ColumnListHeaderColumnComponent<T>>;

	//#endregion FIELDS

	//#region PROPERTIES

	/** Index. */
	@Input() public index?: number;
	@ObserveProperty<ColumnListRowComponent<T>>({ sourcePropertyKey: "index" })
	public readonly observableIndex = new ObservableProperty<number>();

	/** Indique si on doit afficher l'icône de détail de l'item. */
	@Input() public detail?: boolean | string | null;
	@ObserveProperty<ColumnListRowComponent<T>>({
		sourcePropertyKey: "detail",
		transformer: (poNewValue: any) => coerceBooleanProperty(poNewValue)
	})
	public readonly observableDetail = new ObservableProperty<boolean>(false);

	/** Taille d'un item. */
	@Input() public itemSize?: number | null;
	@ObserveProperty<ColumnListRowComponent<T>>({ sourcePropertyKey: "itemSize" })
	public readonly observableItemSize = new ObservableProperty<number>(70);

	public odd$: Observable<boolean> = this.observableIndex.value$.pipe(
		map((pnIndex: number) => pnIndex % 2 === 0),
		secure(this)
	);

	/** Nombre de colonnes de la ligne. */
	public readonly observableColumnsNumber = new ObservableProperty<number>();

	//#endregion PROPERTIES

	//#region METHODS

	public onDetailClicked(poEvent: MouseEvent): void {
		poEvent.stopPropagation();
		this.moDetailClickedEvent.emit(poEvent);
	}

	public ngAfterContentInit(): void {
		this.observableColumnsNumber.bind(
			this.moColumns.changes.pipe(
				startWith({}),
				map(() => this.moColumns.length)
			),
			this
		);
	}

	//#endregion METHODS

}
