import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';

export class TokenError extends Error {

	constructor() {
		super("Token invalide.");
		// Nécessaire pour que le 'instanceof TokenErrorError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, TokenError);
	}

}