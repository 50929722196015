import { Injectable } from '@angular/core';
import { Zip } from '@awesome-cordova-plugins/zip/ngx';
import { PerformanceManager } from '@calaosoft/osapp-common/performance/PerformanceManager';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { AsyncZipOptions, FlateError, zip } from 'fflate';
import { PlatformService } from '../../../services/platform.service';
import { FilesystemService } from '../../filesystem/services/filesystem.service';
import { UnzipError } from '../errors/unzip-error';
import { IZipOptions } from '../models/izip-options';

@Injectable()
export class ZipService {

	//#region FIELDS

	private static readonly C_LOG_ID = "ZIP.S::";
	private static readonly C_ZIP_EXTENSION = ".zip";

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioZip: Zip,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcFilesystem: FilesystemService
	) { }

	//todo : notion de fichier parce qu'on voudra peut-être compresser plusieurs fichiers voire un répertoire plus tard.
	/** Compresse un fichier.
	 * @param poOptions Options de compression.\
	 * Par défaut, utilisation d'un niveau de compression à `1` (suffisant).
	 */
	public async zipFileAsync(poOptions: IZipOptions): Promise<void> {
		const loPerfManager = new PerformanceManager().markStart();

		try {
			const loFile: Blob = await this.isvcFilesystem.getFileAsync(
				poOptions.sourceFilePath,
				poOptions.sourceRootDirectory
			);
			const loZippedData: Uint8Array = await this.getZipFileAsync(
				poOptions,
				new Uint8Array(await loFile.arrayBuffer())
			);
			await this.isvcFilesystem.createFileAsync(
				this.getZipDestinationPath(poOptions),
				loZippedData.buffer,
				poOptions.destinationRootDirectory,
				poOptions.overwrite
			);
			console.debug(`${ZipService.C_LOG_ID}Unzip file from '${poOptions.sourceFilePath}' to '${poOptions.destinationFolderPath}/${poOptions.fileName}' succeeded in ${loPerfManager.markEnd().measure()}ms.`);
		}
		catch (poError) {
			console.error(`${ZipService.C_LOG_ID}Unzip file from '${poOptions.sourceFilePath}' to '${poOptions.destinationFolderPath}/${poOptions.fileName}' failed :`, poError);
			throw poError;
		}
	}

	private getZipFileAsync(poOptions: IZipOptions, poFileToZip: Uint8Array): Promise<Uint8Array> {
		return new Promise((pfResolve, pfReject) => {
			zip(
				{ [poOptions.fileName]: poFileToZip },
				this.getZipOptions(poOptions),
				(poError: FlateError | null, poResult: Uint8Array) => poError ? pfReject(poError) : pfResolve(poResult)
			);
		});
	}

	private getZipOptions(poOptions: IZipOptions): AsyncZipOptions {
		const loOptions: AsyncZipOptions = {};

		if (NumberHelper.isValid(poOptions.compressionLevel)) {
			// La lib est typée en nombre compris entre 0 et 9 inclus ou 'undefined'.
			if (poOptions.compressionLevel < 0)
				loOptions.level = 0;
			else if (poOptions.compressionLevel > 9)
				loOptions.level = 9;
			else
				loOptions.level = poOptions.compressionLevel as 1;
		}
		else // 1 par défaut, compression la plus légère avec le meilleur ratio tailleAprèsCompression-tempsDeCompression.
			loOptions.level = 1;

		return loOptions;
	}

	private getZipDestinationPath(poOptions: IZipOptions): string {
		return `${poOptions.destinationFolderPath}${poOptions.destinationFolderPath.endsWith("/") ? "" : "/"}${poOptions.fileName}${poOptions.fileName.endsWith(ZipService.C_ZIP_EXTENSION) ? "" : ZipService.C_ZIP_EXTENSION}`;
	}

	/** Permet d'extraire des fichiers d'un zip.
	 * @param psSource Localisation du zip.
	 * @param psDestinationFolder Dossier cible.
	 * @param pfOnProgress Callback appelée lors de l'avancement de l'extraction.
	 */
	public async extractAsync(psSource: string, psDestinationFolder: string, pfOnProgress?: (poEvent: ProgressEvent) => void): Promise<void> {
		if (this.isvcPlatform.isMobileApp) {
			const loPerformanceManager = new PerformanceManager().markStart();

			await this.extractFromMobileDevice(psSource, psDestinationFolder, pfOnProgress);

			console.debug(`${ZipService.C_LOG_ID}Unzip executed in ${loPerformanceManager.markEnd().measure()}ms.`);
		}
		else
			throw new UnzipError("Navigateur non supporté.");
	}

	private async extractFromMobileDevice(psSource: string, psDestination: string, pfOnProgress?: (poEvent: ProgressEvent) => void): Promise<void> {
		try {
			const lnResult: number = await new Promise((pfResolve, pfReject) => {
				try {
					this.ioZip.unzip(
						psSource,
						psDestination,
						(pnResult: number) => pfResolve(pnResult),
						pfOnProgress
					);
				}
				catch (poError) {
					pfReject(poError);
				}
			});
			if (lnResult !== 0) {
				console.error(`${ZipService.C_LOG_ID}Unzip error on mobile.`, lnResult);
				throw new UnzipError;
			}
		} catch (poError) {
			console.error(`${ZipService.C_LOG_ID}Error when unzip on mobile :`, poError);
			throw poError;
		}
	}

	//#endregion

}