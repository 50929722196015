import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DateHelper } from "@calaosoft/osapp-common/dates/helpers/dateHelper";
import { ObservableArray } from "@calaosoft/osapp-common/observable/models/observable-array";
import { ObservableProperty } from "@calaosoft/osapp-common/observable/models/observable-property";
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from "@calaosoft/osapp-common/utils/helpers/arrayHelper";
import { ESortOrder } from "@calaosoft/osapp-common/utils/models/ESortOrder";
import { UserHelper } from "@calaosoft/osapp/helpers/user.helper";
import { UserData } from "@calaosoft/osapp/model/application/UserData";
import { FieldBase } from "@calaosoft/osapp/model/forms/FieldBase";
import { ActivePageManager } from "@calaosoft/osapp/model/navigation/ActivePageManager";
import { BaseEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence";
import { ECalendarView } from "@calaosoft/osapp/modules/calendar/models/ecalendar-view";
import { HasPermissions } from "@calaosoft/osapp/modules/permissions/decorators/has-permissions.decorator";
import { PermissionsService } from "@calaosoft/osapp/modules/permissions/services/permissions.service";
import { FormsService } from "@calaosoft/osapp/services/forms.service";
import { Observable, combineLatest, defaultIfEmpty, map, switchMap, tap } from "rxjs";
import { IAgendaPageQueryParams } from "../../app/tab-bar/agenda/models/iagenda-page-query-params";
import { ETradeEventType } from "../trade-events/models/etrade-event-type";
import { ReminderOccurrence } from "../trade-events/models/reminder-occurrence";
import { TaskOccurrence } from "../trade-events/models/task-occurrence";
import { TradeEventsService } from "../trade-events/services/trade-events.service";

@Component({
	selector: "calao-activity-summary-field",
	templateUrl: "./activity-summary-field.component.html",
	styleUrls: ["./activity-summary-field.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivitySummaryFieldComponent extends FieldBase implements OnInit {

	//#region FIELDS

	private readonly moObservableActivitiessCount = new ObservableProperty<number>(0);

	/** Date actuelle. */
	private readonly observableTodayDate = new ObservableProperty<Date>().bind(
		DateHelper.getDays$(),
		this
	);

	private readonly moActivePageManager = new ActivePageManager(this, this.isvcRouter);

	private readonly maParticipantIds: string[] = UserData.current?._id ? [UserHelper.getUserContactId()] : [];

	//#endregion FIELDS

	//#region PROPERTIES

	@HasPermissions({ permission: "read" })
	public get canReadEvents(): boolean { return true; }

	public readonly canDisplayActivities$: Observable<boolean> = this.moObservableActivitiessCount.value$.pipe(
		map((pnCounter: number) => this.canReadEvents && pnCounter > 0),
		secure(this)
	);

	/** Tableau de toutes les prochaines. */
	public readonly observableActivities = new ObservableArray<BaseEventOccurrence | TaskOccurrence | ReminderOccurrence>();

	/** `true si en cours de chargement, sinon `false`. */
	public readonly observableIsLoading = new ObservableProperty<boolean>(true);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcRouter: Router,
		public readonly isvcPermissions: PermissionsService,
		private readonly isvcTradeEvents: TradeEventsService,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.getPendingActivities$().pipe(
			tap((paActivities: BaseEventOccurrence[]) => {
				this.moObservableActivitiessCount.value = paActivities.length;
				this.observableActivities.resetArray(paActivities);
				this.observableIsLoading.value = false;
			}),
			secure(this)
		).subscribe();
	}

	private getPendingActivities$(): Observable<BaseEventOccurrence[]> {
		return this.getActivities$().pipe(
			switchMap((paOccurrences: BaseEventOccurrence[]) => this.filterAndSortActivities$(paOccurrences)),
		);
	}

	private getActivities$(): Observable<BaseEventOccurrence[]> {
		return combineLatest([
			this.getTasks$(),
			this.getEvents$(),
			this.getReminders$()
		]).pipe(
			map(([paTasks, paEvents, paReminders]: [TaskOccurrence[], BaseEventOccurrence[], ReminderOccurrence[]]) => ([] as BaseEventOccurrence[]).concat(paTasks).concat(paEvents).concat(paReminders)),
			defaultIfEmpty([])
		);
	}

	private getTasks$(): Observable<TaskOccurrence[]> {
		return this.observableTodayDate.value$.pipe(
			switchMap((pdTodayDate: Date) => {
				return this.isvcTradeEvents.getNextTasksOccurrences$(
					this.moActivePageManager,
					{
						participantIds: this.maParticipantIds,
						dateRange: { to: DateHelper.fillDay(pdTodayDate) }
					}
				);
			})
		);
	}

	private getEvents$(): Observable<BaseEventOccurrence[]> {
		return this.isvcTradeEvents.getTodaysEventOccurrences$(
			{
				types: [ETradeEventType.standard],
				participantIds: this.maParticipantIds
			},
			this.moActivePageManager
		);
	}

	private getReminders$(): Observable<ReminderOccurrence[]> {
		return this.observableTodayDate.value$.pipe(
			switchMap((pdTodayDate: Date) => {
				return this.isvcTradeEvents.getPendingRemindersOccurrences$(
					this.moActivePageManager,
					{
						dateRange: { to: DateHelper.fillDay(pdTodayDate) },
						participantIds: this.maParticipantIds
					}
				);
			})
		);
	}

	/** Retourne les 3 premières occurrences ordonnées par startDate qui ne sont pas en retard.
	 * @param paOccurrences Tableau des occurrences à filtrer et à ordonner.
	 */
	private filterAndSortActivities$(paOccurrences: BaseEventOccurrence[]): Observable<BaseEventOccurrence[]> {
		return combineLatest(paOccurrences.map((poOccurrence: BaseEventOccurrence) =>
			poOccurrence.isLate$.pipe(
				map((pbIsLate: boolean) => pbIsLate ? undefined : poOccurrence)
			)
		)).pipe(
			map((paFilteredOccurrences: (BaseEventOccurrence | undefined)[]) =>
				ArrayHelper.getSection(
					ArrayHelper.dynamicSort(
						ArrayHelper.getValidValues(paFilteredOccurrences),
						"startDate",
						ESortOrder.ascending
					),
					0,
					2
				)
			),
			defaultIfEmpty([])
		);
	}


	public isTaskOccurrence(poEvent: BaseEventOccurrence): poEvent is TaskOccurrence {
		return poEvent.eventType === ETradeEventType.task && poEvent instanceof TaskOccurrence;
	}

	public isEventOccurrence(poEvent: BaseEventOccurrence): poEvent is BaseEventOccurrence {
		return poEvent.eventType === ETradeEventType.standard && poEvent instanceof BaseEventOccurrence;
	}

	public isReminderOccurrence(poEvent: BaseEventOccurrence): poEvent is ReminderOccurrence {
		return poEvent.eventType === ETradeEventType.reminder && poEvent instanceof ReminderOccurrence;
	}

	public navigateToDayViewAsync(): Promise<boolean> {
		const loAgendaQueryParams: IAgendaPageQueryParams = { view: ECalendarView.List, currentDay: true };
		return this.isvcRouter.navigate(["agenda"], { queryParams: loAgendaQueryParams });
	}
	//#endregion
}