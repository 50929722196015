import { Expose } from '@calaosoft/osapp-common/class-transformer';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';

/** Permet de donner une valeur par défaut à un champs.
 * @param pfDefaultValue
 */
export function DefaultValue<T>(pfDefaultValue: () => T): PropertyDecorator {
	return function (poTarget: Object, psPropertyKey: string) {
		Expose()(poTarget, psPropertyKey); // Par défaut expose la valeur car on la transforme en getter/setter
		const lsAccessorName = `#${psPropertyKey}`;

		const loDescriptor: PropertyDescriptor | undefined = Object.getOwnPropertyDescriptor(poTarget, psPropertyKey);
		const lfOriginalGet = loDescriptor?.get;
		const lfGet = function () {
			lfOriginalGet?.apply(this);

			if (!ObjectHelper.isDefined(this[lsAccessorName]))
				this[lsAccessorName] = pfDefaultValue();

			return this[lsAccessorName];
		};
		const lfOriginalSet = loDescriptor?.set;
		const lfSet = function (poNewVal: any) {
			this[lsAccessorName] = poNewVal;
			lfOriginalSet?.apply(this, [poNewVal]);
		};

		// On surcharge la propriété avec les méthodes get et set custom si ce n'était pas déjà une property, sinon le descriptor fera la surcharge.
		Object.defineProperty(poTarget, psPropertyKey, { get: lfGet, set: lfSet, configurable: true });
	};
};
