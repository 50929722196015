import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { Observable, defer, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IGroup } from '../../../../model/contacts/IGroup';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { IPopoverItemParams } from '../../../../model/popover/IPopoverItemParams';
import { GroupsService } from '../../../../services/groups.service';
import { PopoverService } from '../../../../services/popover.service';
import { EPermissionScopes } from '../../../permissions/models/epermission-scopes';
import { PermissionsService } from '../../../permissions/services/permissions.service';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';

@Component({
	selector: 'calao-group-item',
	templateUrl: './group-item.component.html',
	styleUrls: ['./group-item.component.scss'],
})
export class GroupItemComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	/** Événement lors d'un clic sur un item d'un groupe. */
	@Output("onGroupClicked") private readonly moOnGroupClicked = new EventEmitter<IGroup>();
	/** Événement lors d'un clic sur le bouton supprimer d'un item d'un groupe. */
	@Output("onDeleteGroupClicked") private readonly moOnDeleteGroupClickedEmitter = new EventEmitter<IGroup>();
	/** Événement lors d'un clic sur le bouton editer d'un item d'un groupe. */
	@Output("onEditGroupClicked") private readonly moOnEditGroupClickedEmitter = new EventEmitter<IGroup>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Contact. */
	@Input() public item?: IGroup;
	@ObserveProperty<GroupItemComponent>({ sourcePropertyKey: "item" })
	public readonly observableItem = new ObservableProperty<IGroup>();

	/** Libellé des membres. */
	@Input() public membersLabel?: string;
	@ObserveProperty<GroupItemComponent>({ sourcePropertyKey: "membersLabel" })
	public readonly observableMembersLabel = new ObservableProperty<string>("");

	public readonly observablePopOverItemsParams = new ObservableArray<IPopoverItemParams>([]);

	public get canDeleteGroups(): boolean {
		return this.isvcPermissions.evaluatePermission(EPermissionScopes.groups, "delete");
	}

	public get canEditGroups(): boolean {
		return this.isvcPermissions.evaluatePermission(EPermissionScopes.groups, "edit");
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPermissions: PermissionsService,
		private readonly isvcPopover: PopoverService
	) {
		super();
	}

	public onGroupClicked(): void {
		return this.moOnGroupClicked.emit(this.observableItem.value);
	}

	public onEditGroupClicked$(): Observable<void> {
		return of(this.moOnEditGroupClickedEmitter.emit(this.observableItem.value));
	}

	public onDeleteGroupClicked$(): Observable<void> {
		return of(this.moOnDeleteGroupClickedEmitter.emit(this.observableItem.value));
	}

	public ngOnInit(): void {
		this.observableItem.value$.pipe(
			tap(() => {
				const loEditPopOverParams: IPopoverItemParams = {
					action: () => this.onEditGroupClicked$(),
					id: "edit",
					color: "primary",
					icon: "create",
					title: "Éditer"
				};
				const loDeletePopOverParams: IPopoverItemParams = {
					action: () => this.onDeleteGroupClicked$(),
					id: "delete",
					color: "danger",
					icon: "trash",
					title: "Supprimer définitivement"
				};

				const laPopOverItemsParams: IPopoverItemParams[] = [];
				let lbHasEditPopOverParams = false;
				let lbDeletePopOverParams = false;
				laPopOverItemsParams.forEach((poPopOverParam: IPopoverItemParams) => {
					if (!lbHasEditPopOverParams)
						lbHasEditPopOverParams = poPopOverParam.id === loEditPopOverParams.id;
					if (!lbDeletePopOverParams)
						lbDeletePopOverParams = poPopOverParam.id === loDeletePopOverParams.id;
				});

				if (!lbHasEditPopOverParams && this.canEditGroups)
					laPopOverItemsParams.push(loEditPopOverParams);
				if (!lbDeletePopOverParams && this.canDeleteGroups)
					laPopOverItemsParams.push(loDeletePopOverParams);

				this.observablePopOverItemsParams.resetArray([...laPopOverItemsParams]);
			}),
			secure(this)
		).subscribe();
	}

	public async presentPopOverAsync(poEvent: MouseEvent): Promise<void> {
		this.presentPopOver$(poEvent).subscribe();
	}

	@Queue<GroupItemComponent, Parameters<GroupItemComponent["presentPopOver$"]>, ReturnType<GroupItemComponent["presentPopOver$"]>>({
		excludePendings: true
	})
	private presentPopOver$(poEvent: MouseEvent): Observable<HTMLIonPopoverElement> {
		return defer(() => this.isvcPopover.showPopoverAsync(this.observablePopOverItemsParams, poEvent));
	}

	/** Construit un avatar à partir d'une image. */
	public getGroupAvatar(poGroup: IGroup): IAvatar {
		return GroupsService.createGroupAvatar(poGroup);
	}

	//#endregion

}