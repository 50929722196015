import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IAppointment } from '../../model/booking/IAppointment';
import { IAppointmentDTO } from '../../model/booking/IAppointmentDTO';
import { IAvailability } from '../../model/booking/IAvailability';
import { ICustomer } from '../../model/booking/ICustomer';
import { IManager } from '../../model/booking/IManager';
import { IPrice } from '../../model/booking/IPrice';
import { IPurchase } from '../../model/booking/IPurchase';
import { IRequest } from '../../model/booking/IRequest';
import { IResource } from '../../model/booking/IResource';
import { IReview } from '../../model/booking/IReview';
import { IContact } from '../../model/contacts/IContact';
import { IGetAppointmentsParams } from './IGetAppointmentsParams';
import { IGetReviewsOptions } from './IGetReviewsOptions';
import { AppointmentHelper } from './appointment/appointment-helper';

/** Client d'appel de l'API booking.
 * Extrait du service booking pour limiter les dépendances circulaires.
 */
export class BookingApiClient {

	//#region METHODS

	constructor(
		private moHttpClient: HttpClient,
	) { }

	/** Met à jour un customer.
	 * @param poCustomer l'objet Customer à mettre à jour.
	 */
	public postCustomer(poCustomer: ICustomer): Observable<ICustomer> {
		return this.moHttpClient.post(`${this.getContactsApiPath()}/${poCustomer._id}`, poCustomer, { headers: ConfigData.booking?.apiHeaders })
			.pipe(
				mergeMap(_ => this.getCustomer(poCustomer._id))
			);
	}

	/** Retourne la liste de toutes les ressources. */
	public getResources(pbIsActive?: boolean): Observable<IResource[]> {
		return this.getContactsByType<IResource>("resource", pbIsActive);
	}

	/** Retourne la liste de tous les clients.
	 * @param psManagerId L'identifiant du manager pour afficher toutes les données.
	*/
	public getCustomers(psManagerId?: string): Observable<ICustomer[]> {
		return this.getContactsByType<ICustomer>("customer", undefined, psManagerId);
	}

	/** Retourne la liste de tous les managers. */
	public getManagers(pbIsActive?: boolean): Observable<IManager[]> {
		return this.getContactsByType<IManager>("manager", pbIsActive);
	}

	private getContactsByType<T extends IContact = IContact>(psType: string, pbIsActive?: boolean, psManagerId?: string): Observable<T[]> {
		let lsUrl = `${this.getContactsApiPath()}?type=${psType}${pbIsActive === undefined ? "" : `&active=${pbIsActive}`}`;
		if (psManagerId) {
			lsUrl = this.addRequestParam(lsUrl, 'managerId', psManagerId);
		}
		return this.moHttpClient.get<T[]>(lsUrl, { headers: ConfigData.booking?.apiHeaders });
	}

	private getContactsApiPath() {
		return `${this.getApiPath()}/contacts`;
	}

	private getPurchasesApiPath() {
		return `${this.getApiPath()}/purchases`;
	}

	private getReviewsApiPath() {
		return `${this.getApiPath()}/reviews`;
	}

	private getApiPath() {
		return `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_suffix}apps/${ConfigData.appInfo.appId}`;
	}

	/** Retourne une ressource.
	 * @param psResourceId Identifiant de la ressource.
	 * @param pbRequestFullName Indique si l'on doit récupérer le nom et le prénom.
	 */
	public getResource(psResourceId: string, pbRequestFullName = true): Observable<IResource> {
		return this.moHttpClient.get<IResource>(`${this.getContactsApiPath()}/${psResourceId}`, { headers: ConfigData.booking?.apiHeaders })
			.pipe(
				map((poResource: IResource) => ({ ...poResource, lastName: pbRequestFullName ? poResource.lastName : undefined })) // TODO TB : Modifier l'API pour ne pas retourner le nom des clairvoyant dans le cas d'une requête par un client.
			);
	}

	/**
	 * Retourne des disponibiltés precises.
	 * @param psResourceId id d'un contact de type resource
	 */
	public getAvailability(psResourceId: string): Observable<IAvailability> {
		return this.moHttpClient.get<IAvailability>(`${this.getContactsApiPath()}/${psResourceId}/availabilities`, { headers: ConfigData.booking?.apiHeaders });
	}

	/**
	 * Met à jour des disponibiltés precises.
	 * @param psResourceId id d'un contact de type resource.
	 * @param poAvailability les disponibiltés à mettre à jour.
	 */
	public updateAvailability(psResourceId: string, poAvailability: IAvailability) {
		return this.moHttpClient.post(`${this.getContactsApiPath()}/${psResourceId}/availabilities/${poAvailability._id}`,
			poAvailability,
			{ headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" }
		);
	}

	/** Retourne un client précis.
	 * @param psCustomerId L'identifiant du client à retourner.
	 * @param psManagerId L'identifiant du manager pour afficher toutes les données.
	 */
	public getCustomer(psCustomerId: string, psManagerId?: string): Observable<ICustomer> {
		let lsUrl = `${this.getContactsApiPath()}/${psCustomerId}`;
		if (psManagerId) {
			lsUrl = this.addRequestParam(lsUrl, 'managerId', psManagerId);
		}
		return this.moHttpClient.get<ICustomer>(lsUrl, { headers: ConfigData.booking?.apiHeaders });
	}

	/**
	 * Simule un appel d'API retournant des consultations filtré par l'id d'une resource si spécifié.
	 * @param psContactId L'identifiant du contact pour lequel on veut récupérer les rdv.
	 * @param poParams Paramètre d'url
	**/
	public getResourceAppointments(psContactId: string, poParams: IGetAppointmentsParams = {}): Observable<IAppointment[]> {
		let lsUrl = `${this.getContactsApiPath()}/${psContactId}/appointments`;

		for (const lsKey in poParams) {
			lsUrl = this.addRequestParam(lsUrl, lsKey, poParams[lsKey]);
		}

		return this.moHttpClient.get<IAppointment[]>(lsUrl, { headers: ConfigData.booking?.apiHeaders });
	}

	private addRequestParam(psUrl: string, psParamName: string, poParamValue?: any): string {
		if (poParamValue !== undefined && poParamValue !== null)
			psUrl = `${psUrl}${psUrl.includes("?") ? "&" : "?"}${psParamName}=${poParamValue}`;
		return psUrl;
	}

	/** Simule un appel d'API retournant la consultation suivant l'id demandé.
	 * @param psAppointmentId Identifiant de la consultation.
	 */
	public getAppointment(psAppointmentId: string): Observable<IAppointment> {
		return this.moHttpClient.get<IAppointment>(`${this.getAppointmentApiPath()}/${psAppointmentId}`, { headers: ConfigData.booking?.apiHeaders });
	}

	/**
	 * Créé les rendez-vous pour une ressource donnée.
	 * @param psManagerId id du Manager.
	 * @param psResourceId id de la Ressource.
	 * @param pbavailability si true utilise les disponibilités.
	 * @param startDate la date de début format ISO.
	 * @param endDate la date de fin format ISO.
	 */
	public createAppointments(psManagerId: string, psResourceId: string, pbavailability?: boolean, psStartDate?: string, psEndDate?: string) {
		let lsUrl = `${this.getContactsApiPath()}/${psManagerId}/appointments`;
		lsUrl = this.addRequestParam(lsUrl, 'resourceId', psResourceId);
		lsUrl = pbavailability ? this.addRequestParam(lsUrl, 'availability', pbavailability) : lsUrl;
		lsUrl = psStartDate ? this.addRequestParam(lsUrl, 'startDate', psStartDate) : lsUrl;
		lsUrl = psEndDate ? this.addRequestParam(lsUrl, 'endDate', psEndDate) : lsUrl;

		return this.moHttpClient.post(lsUrl, null, { headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" });
	}

	/**
	 * Retoune une date au format ISO à laquelle on peut commencer de créer des rendez-vous.
	 * @param psManagerId l'id du Manager.
	 * @param psResourceId l'id de la Ressource.
	 */
	public getLastAppointmentDate(psManagerId: string, psResourceId?: string): Observable<string> {
		let lsUrl = `${this.getContactsApiPath()}/${psManagerId}/lastAppointmentDate`;
		if (psResourceId) {
			lsUrl = this.addRequestParam(lsUrl, 'resourceId', psResourceId);
		};
		return this.moHttpClient.get<string>(lsUrl, { headers: ConfigData.booking?.apiHeaders });
	}

	private getAppointmentApiPath() {
		return `${this.getApiPath()}/appointments`;
	}

	/** Créé un contact.
	 * @param poContact l'objet Contact à créer.
	 */
	public postNewCustomer(poContact: ICustomer): Promise<ICustomer> {
		return this.moHttpClient.post<ICustomer>(`${this.getContactsApiPath()}`, poContact, { headers: ConfigData.booking?.apiHeaders }).toPromise();
	}

	public saveAppointmentPurchase(poAppointment: IAppointment, poPurchase: IPurchase): Observable<any> {
		return this.moHttpClient.post(`${this.getContactsApiPath()}/${poPurchase.customers[0]._id}/appointments/${poAppointment._id}/book`,
			poPurchase,
			{ headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" });
	}

	public prebookAppointment(poAppointment: IAppointment, poCustomer: ICustomer): Observable<HttpResponse<any>> {
		return this.moHttpClient.post(`${this.getContactsApiPath()}/${poCustomer._id}/appointments/${poAppointment._id}/prebook`, null, { headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" });
	}

	/**
	 * Retourne un ticket d'achat.
	 * @param psPurchaseId l'id du ticket d'achat recherché.
	 */
	public getPurchase(psPurchaseId: string): Observable<IPurchase> {
		return this.moHttpClient.get<IPurchase>(`${this.getPurchasesApiPath()}/${psPurchaseId}`, { headers: ConfigData.booking?.apiHeaders });
	}

	public startAppointment(psAppointmentId: string): Observable<HttpResponse<any>> {
		return this.moHttpClient.post(`${this.getAppointmentApiPath()}/${psAppointmentId}/start`, null, { headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" });
	}

	public finishAppointment(psAppointmentId: string): Observable<HttpResponse<any>> {
		return this.moHttpClient.post(`${this.getAppointmentApiPath()}/${psAppointmentId}/finish`, null, { headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" });
	}

	public updateAppointment(poAppointment: IAppointment) {
		return this.moHttpClient.post(`${this.getAppointmentApiPath()}/${poAppointment._id}`,
			poAppointment,
			{ headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" });
	}

	public requestCustomerPhone(poAppointment: IAppointmentDTO, psReason: string): Observable<HttpResponse<any>> {
		let lsUrl = `${this.getContactsApiPath()}/${AppointmentHelper.extractResourceId(poAppointment)}/appointments/${poAppointment._id}/request/phone`;
		lsUrl = this.addRequestParam(lsUrl, 'customerId', ArrayHelper.getFirstElement(poAppointment.participantIds));
		return this.moHttpClient.post(
			lsUrl,
			{ reason: psReason },
			{ headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" }
		);
	}

	public requestAppointmentCancellation(psManagerId: string, poAppointment: IAppointmentDTO, psReason: string): Observable<HttpResponse<any>> {
		let lsUrl = `${this.getContactsApiPath()}/${psManagerId}/appointments/${poAppointment._id}/request/appointmentCancellation`;
		lsUrl = this.addRequestParam(lsUrl, 'customerId', ArrayHelper.getFirstElement(poAppointment.participantIds));
		return this.moHttpClient.post(
			lsUrl,
			{ reason: psReason },
			{ headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" }
		);
	}

	public getRequests(psContactId: string, psType: string, pnLimit?: number, psStartDate?: string): Observable<IRequest[]> {
		let lsUrl = `${this.getContactsApiPath()}/${psContactId}/requests/${psType}`;

		if (pnLimit) {
			lsUrl = this.addRequestParam(lsUrl, 'limit', pnLimit);
		}
		if (psStartDate) {
			lsUrl = this.addRequestParam(lsUrl, 'startDate', psStartDate);
		}

		return this.moHttpClient.get<IRequest[]>(lsUrl, { headers: ConfigData.booking?.apiHeaders });
	}

	/**
	 * Réaffecte une autre consultation.
	 * @param psCurrentAppointmentId id de la consultation déjà réservé.
	 * @param psNewAppointmentId id de la consultation à réserver.
	 */
	public reassignAppointment(psCurrentAppointmentId: string, psNewAppointmentId: string): Observable<HttpResponse<any>> {
		return this.moHttpClient.post(
			`${this.getAppointmentApiPath()}/${psCurrentAppointmentId}/reassign?newAppointmentId=${psNewAppointmentId}`,
			null,
			{ headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" }
		);
	}

	/**
	 * Annule une consultation.
	 * @param psAppointmentId id de la consultation à annuler.
	 */
	public cancelAppointment(psAppointmentId: string): Observable<HttpResponse<any>> {
		return this.moHttpClient.post(
			`${this.getAppointmentApiPath()}/${psAppointmentId}/cancel`,
			null,
			{ headers: ConfigData.booking?.apiHeaders, responseType: "json", observe: "response" }
		);
	}

	/**
	 * Retourne les consultations de toutes les resources.
	 * @param poParams Paramètre d'url
	**/
	public getResourcesAppointments(poParams: IGetAppointmentsParams = {}): Observable<IAppointment[]> {
		let lsUrl = `${this.getContactsApiPath()}/resources/appointments`;

		for (const lsKey in poParams) {
			lsUrl = this.addRequestParam(lsUrl, lsKey, poParams[lsKey]);
		}

		return this.moHttpClient.get<IAppointment[]>(lsUrl, { headers: ConfigData.booking?.apiHeaders });
	}

	/**
	 * Retourne la liste des prix pour un rendez-vous.
	 */
	public getAppointmentsPrices() {
		const lsUrl = `${this.getAppointmentApiPath()}/prices`;

		return this.moHttpClient.get<IPrice[]>(lsUrl, { headers: ConfigData.booking?.apiHeaders });
	}

	/**
	 * Retourne des avis filtrés par les options.
	 * @param poOptions Les options de filtrage.
	**/
	public getReviews(poOptions: IGetReviewsOptions = {}): Observable<IReview[]> {
		let lsUrl = `${this.getReviewsApiPath()}`;

		for (const lsKey in poOptions) {
			lsUrl = this.addRequestParam(lsUrl, lsKey, poOptions[lsKey]);
		}

		return this.moHttpClient.get<IReview[]>(lsUrl, { headers: ConfigData.booking?.apiHeaders });
	}

	public publishReview(psManagerId: string, psReviewId: string): Observable<IReview> {
		let lsUrl = `${this.getReviewsApiPath()}/${psReviewId}/publish`;
		lsUrl = this.addRequestParam(lsUrl, 'managerId', psManagerId);

		return this.moHttpClient.post<IReview>(lsUrl, null, { headers: ConfigData.booking?.apiHeaders });
	}

	public refuseReview(psManagerId: string, psReviewId: string): Observable<IReview> {
		let lsUrl = `${this.getReviewsApiPath()}/${psReviewId}/refuse`;
		lsUrl = this.addRequestParam(lsUrl, 'managerId', psManagerId);

		return this.moHttpClient.post<IReview>(lsUrl, null, { headers: ConfigData.booking?.apiHeaders });
	}

	public createReview(poReview: IReview): Observable<IReview> {
		const lsUrl = `${this.getReviewsApiPath()}`;

		return this.moHttpClient.post<IReview>(lsUrl, poReview, { headers: ConfigData.booking?.apiHeaders });
	}

	//#endregion

}