import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ModalController } from '@ionic/angular';
import { tap } from 'rxjs';
import { PlatformService } from '../../../../services/platform.service';
import { ModalComponentBase } from '../../../modal/model/ModalComponentBase';
import { Document } from '../../models/document';
import { EExplorerMode } from '../../models/eexplorer-mode';

@Component({
	selector: 'calao-doc-picker',
	templateUrl: './doc-picker.component.html',
	styleUrls: ['./doc-picker.component.scss'],
})
export class DocPickerComponent extends ModalComponentBase<Document> {

	//#region PROPERTIES

	/** Chemin du dossier parent sur lequel limiter la récupération des documents. */
	@Input() public rootPath?: string | null;
	@ObserveProperty<DocPickerComponent>({ sourcePropertyKey: "rootPath" })
	public readonly observableRootPath = new ObservableProperty<string | undefined>("");

	public readonly observableCurrentPath = new ObservableProperty<string | undefined>("");
	public readonly mode: EExplorerMode = EExplorerMode.picker;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observableRootPath.value$.pipe(
			tap((psRootPath: string) => this.observableCurrentPath.value = psRootPath),
			secure(this)
		).subscribe();
	}

	public onDocumentPicked(poDocument: Document): void {
		this.close(poDocument);
	}

	public onPathChanged(psPath: string): void {
		this.observableCurrentPath.value = psPath;
	}

	//#endregion METHODS

}
