import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DateHelper } from "@calaosoft/osapp-common/dates/helpers/dateHelper";
import { ObservableProperty } from "@calaosoft/osapp-common/observable/models/observable-property";
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { UserHelper } from "@calaosoft/osapp/helpers/user.helper";
import { UserData } from "@calaosoft/osapp/model/application/UserData";
import { FieldBase } from "@calaosoft/osapp/model/forms/FieldBase";
import { ActivePageManager } from "@calaosoft/osapp/model/navigation/ActivePageManager";
import { BaseEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence";
import { FormsService } from "@calaosoft/osapp/services/forms.service";
import { combineLatest, switchMap, tap } from "rxjs";
import { ETradeEventType } from "../trade-events/models/etrade-event-type";
import { ReminderOccurrence } from "../trade-events/models/reminder-occurrence";
import { TaskOccurrence } from "../trade-events/models/task-occurrence";
import { TradeEventsService } from "../trade-events/services/trade-events.service";
import { IActivityTileFieldParams } from './models/iactivity-tile-field-params';

@Component({
	selector: 'calao-activity-tile-field',
	templateUrl: './activity-tile-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityTileFieldComponent extends FieldBase implements OnInit {

	//#region FIELDS

	private static C_LOG_ID = "ATIL.C::";

	private readonly moActivePageManager = new ActivePageManager(this, this.isvcRouter);

	private readonly maParticipants = UserData.current?._id ? [UserHelper.getUserContactId()] : [];

	/** Date actuelle. */
	private readonly observableTodayDate = new ObservableProperty<Date>().bind(
		DateHelper.getDays$(),
		this
	);

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly observableCount = new ObservableProperty<number>(0);

	public params: IActivityTileFieldParams = {};

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcTradeEvents: TradeEventsService,
		private readonly isvcRouter: Router,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		if (this.props.data)
			this.params = this.props.data;

		switch (this.params.type) {
			case ETradeEventType.task:
				this.initTaskCounter();
				break;
			case ETradeEventType.reminder:
				this.initReminderCounter();
				break;
			case ETradeEventType.standard:
				this.initEventCounter();
				break;

			default:
				console.error(`${ActivityTileFieldComponent.C_LOG_ID} impossible de définir le type de la tuile.`);
				break;
		}
	}

	public navigateToActivityAsync(): Promise<boolean> {
		return this.isvcRouter.navigate(["activity"]);
	}

	public getIcon(): string {
		if (this.params.icon)
			return this.params.icon;

		switch (this.params.type) {
			case ETradeEventType.task:
				return "task";
			case ETradeEventType.reminder:
				return "calendar-number";
			case ETradeEventType.standard:
				return "star";
			default:
				return "";
		}
	}

	public getContent(): string | undefined {
		if (this.params.content)
			return this.params.content;

		switch (this.params.type) {
			case ETradeEventType.task:
				return "Tâches";
			case ETradeEventType.reminder:
				return "Rappels";
			case ETradeEventType.standard:
				return "Évènements";
			default:
				return "";
		}
	}

	public getIconColor(): string | undefined {
		if (this.params.iconColor)
			return this.params.iconColor;

		switch (this.params.type) {
			case ETradeEventType.task:
				return "var(--ion-color-task)";
			case ETradeEventType.reminder:
				return "var(--ion-color-primary)";
			case ETradeEventType.standard:
				return "var(--ion-color-event)";
			default:
				return "var(--ion-color-primary)";
		}
	}

	private initTaskCounter(): void {
		this.observableTodayDate.value$.pipe(
			switchMap((pdTodayDate: Date) =>
				combineLatest([
					this.isvcTradeEvents.getNextTasksOccurrences$(
						this.moActivePageManager,
						{
							participantIds: this.maParticipants,
							dateRange: { to: DateHelper.fillDay(pdTodayDate) }
						}
					),
					this.isvcTradeEvents.getUndatedOccurrences$(
						this.moActivePageManager,
						{ participantIds: this.maParticipants }
					)
				])
			),
			tap(([paNextTasksOccurrences, paUndatedOccurrences]: [TaskOccurrence[], TaskOccurrence[]]) => {
				this.observableCount.value = paNextTasksOccurrences.length + paUndatedOccurrences.length;
			}),
			secure(this)
		).subscribe();
	}

	private initReminderCounter(): void {
		this.observableTodayDate.value$.pipe(
			switchMap((pdTodayDate: Date) =>
				this.isvcTradeEvents.getPendingRemindersOccurrences$(
					this.moActivePageManager,
					{
						dateRange: { to: DateHelper.fillDay(pdTodayDate) },
						participantIds: this.maParticipants
					}
				)
			),
			tap((paReminders: ReminderOccurrence[]) => this.observableCount.value = paReminders.length),
			secure(this)
		).subscribe();
	}

	private initEventCounter(): void {
		this.isvcTradeEvents.getTodaysEventOccurrences$({
			types: [ETradeEventType.standard],
			participantIds: this.maParticipants
		}, this.moActivePageManager).pipe(
			tap((paOccurrences: BaseEventOccurrence[]) => this.observableCount.value = paOccurrences.length),
			secure(this)
		).subscribe();
	}

	//#endregion
}