import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { ICoordinates } from "@calaosoft/osapp/model/navigation/ICoordinates";
import { ColorHelper } from "@calaosoft/osapp/modules/colors/helper/color.helper";
import { Contact } from "@calaosoft/osapp/modules/contacts/models/contact";
import { GeolocationHelper } from "@calaosoft/osapp/modules/geolocation/helpers/geolocation.helper";
import { IMapOptions } from "@calaosoft/osapp/modules/maps/models/imap-options";
import { IPoiOptions } from "@calaosoft/osapp/modules/maps/models/ipoi-options";
import { IPolygonOptions } from "@calaosoft/osapp/modules/maps/models/ipolygon-options";
import { IStartMap } from "@calaosoft/osapp/modules/maps/models/istart-map";
import { DestroyableComponentBase } from "@calaosoft/osapp/modules/utils/components/destroyable-component-base";
import { combineLatest } from "rxjs";
import { concatMap, filter, map, tap } from "rxjs/operators";
import { Business } from "../../../../businesses/model/business";
import { EIconUrl } from "../../models/eicons-url";
import { Sector } from "../../models/sector";

export interface IFromSector {
	sector: Sector;
	from: EFromSectorGroup;
};

export enum EFromSectorGroup {
	one = "one",
	two = "two"
};

const C_BLACK_COLOR = "#000000";

@Component({
	selector: "sectors-group-comparison",
	templateUrl: "./sector-comparison-model.component.html",
	styleUrls: ["./sector-comparison-model.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorComparisonModelComponent extends DestroyableComponentBase {

	//#region FIELDS

	private mnMaxDistance: number | undefined;
	private maRelatedSectors: IFromSector[][] = [];
	private maUnrelatedSectors: IFromSector[] = [];

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly columnNames: string[] = ["nom", "CA cumulé", "CA moyen", "nombre business"];
	private readonly mnNumberOfColumns = this.columnNames.length;

	public mapOptionsFirst: IMapOptions | undefined;
	public mapOptionsSecond: IMapOptions | undefined;

	@Input() public titleFirstGroup: string | undefined;
	@ObserveProperty<SectorComparisonModelComponent>({ sourcePropertyKey: "titleFirstGroup" })
	public readonly observableTitleFirstGroup = new ObservableProperty<string>();

	@Input() public sectorsFirstGroup: Sector[] | undefined;
	@ObserveProperty<SectorComparisonModelComponent>({ sourcePropertyKey: "sectorsFirstGroup" })
	private readonly observableSectorFirstGroup = new ObservableProperty<Sector[]>();


	@Input() public titleSecondGroup: string | undefined;
	@ObserveProperty<SectorComparisonModelComponent>({ sourcePropertyKey: "titleSecondGroup" })
	public readonly observableTitleSecondGroup = new ObservableProperty<string>();

	@Input() public sectorsSecondGroup: Sector[] | undefined;
	@ObserveProperty<SectorComparisonModelComponent>({ sourcePropertyKey: "sectorsSecondGroup" })
	private readonly observableSectorSecondGroup = new ObservableProperty<Sector[]>();

	private maColors: string[] = [];
	public sctrzFrontRow: string[] = [];
	public relatedSectors: string[][] = [];
	public unrelatedSectors: string[][] = [];

	//#endregion PROPERTIES

	//#region METHODS
	constructor() {
		super();
		this.mapOptionsFirst = undefined;
		this.mapOptionsSecond = undefined;


		combineLatest([
			this.observableSectorFirstGroup.value$.pipe(
				filter((paSectors: Sector[] | undefined) => !!paSectors?.length)),
			this.observableSectorSecondGroup.value$.pipe(
				filter((paSectors: Sector[] | undefined) => !!paSectors?.length))
		]).pipe(
			tap(([paSectors1, paSectors2]: [Sector[], Sector[]]) => {
				this.mnMaxDistance = this.getMaxDistBetweenBusiness(paSectors1, paSectors2);
				this.prepareFrontSectorArray(paSectors1, paSectors2);
				this.setFrontArray();
			}
			),
			concatMap(_ => {
				return combineLatest([
					this.observableTitleSecondGroup.value$.pipe(
						filter((psSectorTitle: string | undefined) => !!psSectorTitle)),
					this.observableSectorSecondGroup.value$.pipe(
						filter((paSectors: Sector[] | undefined) => !!paSectors?.length))
				])
					.pipe(
						tap(() => this.maColors = ColorHelper.generateDistinctHexColors(this.observableSectorFirstGroup.value!.length)),
						tap(() =>
							this.mapOptionsFirst = this.setMapOptions(EFromSectorGroup.one)
						),
						tap(() =>
							this.mapOptionsSecond = this.setMapOptions(EFromSectorGroup.two)
						),
						map(([psGroupSectorTitle, paSectors]: [string, Sector[]]) =>
							this.setFirstGroup(psGroupSectorTitle, paSectors)
						),
						concatMap(__ => combineLatest([
							this.observableTitleFirstGroup.value$.pipe(
								filter((psTitle: string | undefined) => !!psTitle)),
							this.observableSectorFirstGroup.value$.pipe(
								filter((paSectors: Sector[] | undefined) => !!paSectors?.length))
						])
							.pipe(
								map(([psTitle, paSectors]: [string, Sector[]]) =>
									this.setSecondGroup(psTitle, paSectors)
								)
							)
						));
			}),
			secure(this)
		).subscribe();


	}

	private setFrontArray(): void {
		this.maRelatedSectors.forEach((paFrontSector: IFromSector[], pnIndex: number) => {
			paFrontSector.forEach((poFrontSector: IFromSector) => {
				if (poFrontSector.from === EFromSectorGroup.one)
					this.relatedSectors.push([poFrontSector.sector.title,
					poFrontSector.sector.getSectorTotalCA().toString(),
					poFrontSector.sector.getSectorAverageCA().toString(),
					poFrontSector.sector.getBusinessNumber().toString()]);
				else {
					this.relatedSectors[pnIndex].push(poFrontSector.sector.title,
						poFrontSector.sector.getSectorTotalCA().toString(),
						poFrontSector.sector.getSectorAverageCA().toString(),
						poFrontSector.sector.getBusinessNumber().toString());
				}
			});
		});

		this.maUnrelatedSectors.forEach((paFrontSector: IFromSector, pnIndex: number) => {
			if (paFrontSector.from === EFromSectorGroup.one) {
				this.unrelatedSectors.push([paFrontSector.sector.title,
				paFrontSector.sector.getSectorTotalCA().toString(),
				paFrontSector.sector.getSectorAverageCA().toString(),
				paFrontSector.sector.getBusinessNumber().toString()]);
				this.unrelatedSectors[pnIndex].push(...new Array(this.mnNumberOfColumns).fill(""));
			}
			else {
				this.unrelatedSectors.push(new Array(this.mnNumberOfColumns).fill(""));
				this.unrelatedSectors[pnIndex].push(paFrontSector.sector.title,
					paFrontSector.sector.getSectorTotalCA().toString(),
					paFrontSector.sector.getSectorAverageCA().toString(),
					paFrontSector.sector.getBusinessNumber().toString());
			}
		});
	}

	private prepareFrontSectorArray(paSectors1: Sector[], paSectors2: Sector[]): void {
		paSectors1.forEach((poSect1: Sector) => {
			paSectors2.forEach((poSect2: Sector) => {
				if (this.areSectorsRelated(poSect1, poSect2) && !this.isSectorAlreadyRelated(poSect1, EFromSectorGroup.one) && !this.isSectorAlreadyRelated(poSect2, EFromSectorGroup.two)) {
					this.maRelatedSectors.push([{ sector: poSect1, from: EFromSectorGroup.one }, { sector: poSect2, from: EFromSectorGroup.two }]);
				}
			});
		});

		this.fillUnrelatedSectorArray(paSectors1, EFromSectorGroup.one);
		this.fillUnrelatedSectorArray(paSectors2, EFromSectorGroup.two);
	}

	private fillUnrelatedSectorArray(paSectors: Sector[], pnfrom: EFromSectorGroup): void {
		paSectors.forEach((poSect: Sector) => {
			if (!this.isSectorAlreadyRelated(poSect, pnfrom))
				this.maUnrelatedSectors.push({ sector: poSect, from: pnfrom });
		});
	}

	private isSectorAlreadyRelated(poSect: Sector, pnfrom: EFromSectorGroup): boolean {
		for (const paFromSects of this.maRelatedSectors) {
			if (!!paFromSects.find((poFromSect: IFromSector) => poFromSect.sector._id === poSect._id && pnfrom === poFromSect.from))
				return true;
		}
		return false;
	}

	/** Donne la distance maximale entre deux points, mais uniquement sur l'axe x ou l'axe y.
	 * @param paSectors1
	 * @param paSectors2
	 */
	private getMaxDistBetweenBusiness(paSectors1: Sector[], paSectors2: Sector[]): number {
		let lnMaxDist = 0;
		for (const loSect1 of paSectors1) {
			for (const loSect2 of paSectors2) {
				if (loSect1.linkedBusinesses && loSect2.linkedBusinesses) {
					const laSectorsPOI: ICoordinates[] = [
						...loSect1.linkedBusinesses
							.filter((poBiz: Business) => poBiz.longitude && poBiz.latitude)
							.map((poBiz: Business) => { return { longitude: poBiz.longitude!, latitude: poBiz.latitude! }; }),
						...loSect2.linkedBusinesses
							.filter((poBiz: Business) => poBiz.longitude && poBiz.latitude)
							.map((poBiz: Business) => { return { longitude: poBiz.longitude!, latitude: poBiz.latitude! }; })
					];

					const lnMaxDistance: number = Math.max(this.getMaxPoiDistLongitude(laSectorsPOI), this.getMaxPoiDistLatitude(laSectorsPOI));
					lnMaxDist = lnMaxDist > lnMaxDistance ? lnMaxDist : lnMaxDistance;
				}
			}
		}
		return lnMaxDist;
	}

	private setMapOptions(from: EFromSectorGroup): IMapOptions {
		const laPolygons: IPolygonOptions[] = [];
		const laPois: IPoiOptions[] = [];
		let lnComp = 0;

		this.maRelatedSectors.forEach((paSectors: IFromSector[]) => {
			paSectors.forEach((poSect: IFromSector) => {
				if (poSect.sector.canDisplayMap() && poSect.from === from) {
					laPolygons.push({
						options: {
							fillColor: this.maColors[lnComp],
							color: C_BLACK_COLOR,
							weight: 0.7,
							fillOpacity: 0.2
						},
						coordinates: poSect.sector.getSectorZoneCoordinates()
					});
					lnComp++;

					this.addBusinessPoi(poSect.sector, laPois);

					this.addContactPoi(poSect.sector, laPois);
				};
			});
		});

		this.maUnrelatedSectors.forEach((poSect: IFromSector) => {
			if (poSect.sector.canDisplayMap() && poSect.from === from) {
				laPolygons.push({
					options: {
						fillColor: this.maColors[lnComp],
						color: C_BLACK_COLOR,
						weight: 0.7,
						fillOpacity: 0.2
					},
					coordinates: poSect.sector.getSectorZoneCoordinates()
				});
				lnComp++;

				this.addBusinessPoi(poSect.sector, laPois);

				this.addContactPoi(poSect.sector, laPois);
			};
		});

		const loStartMapOptions: IStartMap | undefined = GeolocationHelper.getStartMapOptions(laPois.map((loPoi: IPoiOptions) => loPoi.coordinates));

		return {
			pois: laPois,
			polygons: laPolygons,
			buttons: [],
			start: loStartMapOptions ?? { center: { longitude: 3, latitude: 46 }, zoom: 5 }
		};
	}

	private addContactPoi(poSect: Sector, laPois: IPoiOptions[]): void {
		if (poSect.linkedContacts) {
			poSect.linkedContacts.forEach((poCont: Contact) => {
				if (NumberHelper.isValid(poCont.latitude) && NumberHelper.isValid(poCont.longitude))
					laPois.push({
						coordinates: { longitude: poCont.longitude, latitude: poCont.latitude },
						icon: {
							iconUrl: EIconUrl.contact,
							iconSizePx: { width: 15, height: 15 }
						}
					});
			});
		}
	}

	private addBusinessPoi(poSect: Sector, laPois: IPoiOptions[]): void {
		if (poSect.linkedBusinesses) {
			poSect.linkedBusinesses.forEach((poBiz: Business) => {
				if (NumberHelper.isValid(poBiz.latitude) && NumberHelper.isValid(poBiz.longitude))
					laPois.push({
						coordinates: { longitude: poBiz.longitude, latitude: poBiz.latitude },
						icon: {
							iconUrl: EIconUrl.business,
							iconSizePx: { width: 15, height: 15 }
						}
					});
			});
		}
	}

	private setSecondGroup(psTitle: string, paSectors: Sector[]): void {
		if (this.sctrzFrontRow?.length === this.mnNumberOfColumns)
			this.sctrzFrontRow?.push(psTitle,
				this.getSectorsGroupCA(paSectors).toLocaleString(),
				this.getSectorsGroupAverageCA(paSectors).toLocaleString(),
				this.getSectorsGroupTotalBusinesses(paSectors).toLocaleString());
	}

	private setFirstGroup(psTitle: string, paSectors: Sector[]): void {
		this.sctrzFrontRow = [psTitle,
			this.getSectorsGroupCA(paSectors).toLocaleString(),
			this.getSectorsGroupAverageCA(paSectors).toLocaleString(),
			this.getSectorsGroupTotalBusinesses(paSectors).toLocaleString()];
	}

	private getSectorsGroupAverageCA(paSectors: Sector[]): number {
		const lnTotalBiz: number = this.getSectorsGroupTotalBusinesses(paSectors);
		if (!lnTotalBiz)
			return 0;

		const lnTotalCA: number = this.getSectorsGroupCA(paSectors);
		return Math.round(lnTotalCA / lnTotalBiz);
	}

	private getSectorsGroupCA(paSectors: Sector[]): number {
		let lnResult = 0;
		paSectors.forEach((poSector: Sector) => {
			lnResult += poSector.getSectorTotalCA();
		});

		return Math.round(lnResult);
	}

	private getSectorsGroupTotalBusinesses(paSectors: Sector[]): number {
		let lnResult = 0;
		paSectors.forEach((poSector: Sector) => {
			lnResult += poSector.getBusinessNumber();
		});

		return Math.round(lnResult);
	}

	private areSectorsRelated(poSect1: Sector, poSect2: Sector): boolean {
		if (!poSect1.linkedBusinesses || !poSect2.linkedBusinesses) {
			return false;
		}

		const loS1Center: ICoordinates | undefined = poSect1.getPolygonCentroid();
		const loS2Center: ICoordinates | undefined = poSect2.getPolygonCentroid();

		const lbAreRelatedByCenterToCenterDistance: boolean = loS1Center && loS2Center && this.mnMaxDistance ?
			GeolocationHelper.calculateDistanceUsingCoordinatesKm(loS1Center, loS2Center) < this.mnMaxDistance * 0.2 :
			false;

		const lbAreRelatedBySameReferent: boolean = this.areReferentsIdentical(poSect1, poSect2);

		const lnBusinessInCommonPercent = this.businessInCommonPercent(poSect1, poSect2);

		const lbAreRelatedByCommonBusiness50Percent: boolean = lnBusinessInCommonPercent > 50;
		const lbAreRelatedByCommonBusiness80Percent: boolean = lnBusinessInCommonPercent > 80;

		return (lbAreRelatedByCenterToCenterDistance && lbAreRelatedBySameReferent) ||
			(lbAreRelatedByCommonBusiness50Percent && lbAreRelatedBySameReferent) ||
			(lbAreRelatedByCenterToCenterDistance && lbAreRelatedByCommonBusiness50Percent) ||
			lbAreRelatedByCommonBusiness80Percent;
	}

	private getMaxPoiDistLongitude(paPoi: ICoordinates[]): number {
		return GeolocationHelper.getMaxDistanceLongitudeKm(paPoi);
	}

	private getMaxPoiDistLatitude(paPoi: ICoordinates[]): number {
		return GeolocationHelper.getMaxDistanceLongitudeKm(paPoi);
	}

	private businessInCommonPercent(poSect1: Sector, poSect2: Sector): number {
		let lnNumberOfBusinessInCommon = 0;
		if (!poSect1.linkedBusinesses?.length || !poSect2.linkedBusinesses?.length)
			return 0;
		poSect1.linkedBusinesses.forEach((poBiz: Business) => {
			if (poSect2.linkedBusinesses?.find((poBiz2: Business) => poBiz2._id === poBiz._id))
				lnNumberOfBusinessInCommon++;
		});

		return Math.round(lnNumberOfBusinessInCommon / Math.max(poSect1.linkedBusinesses.length, poSect2.linkedBusinesses.length) * 100);
	}

	private areReferentsIdentical(poSect1: Sector, poSect2: Sector): boolean {
		return !poSect1.pointOfContactIds || !poSect2.pointOfContactIds ? false : poSect1.pointOfContactIds === poSect2.pointOfContactIds;
	}

	//#endregion METHODS
}