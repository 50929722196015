import { customAlphabet } from 'nanoid';
import { alphanumeric, numbers, uppercase } from 'nanoid-dictionary';
import { IGuidOptions } from '../../utils/models/IGuidOptions';
import { IGuidBuilder } from "./iguid-builder";

export class ShortGuidBuilder implements IGuidBuilder {

	//#region FIELDS

	private static readonly C_REGEX_STRING = "[0-9a-zA-Z]{21}";
	private static readonly C_IS_VALID_REGEXP = new RegExp(`^${ShortGuidBuilder.C_REGEX_STRING}$`, "i");

	//#endregion FIELDS

	//#region METHODS

	/** @implements
	 * @returns Guid de 21 caractères.
	*/
	public build(poOptions: IGuidOptions = {}): string {
		if (poOptions.upperCase)
			return customAlphabet(uppercase + numbers)();

		return customAlphabet(alphanumeric)();
	}

	/** @implements */
	public isValid(psGuid: string): boolean {
		return ShortGuidBuilder.C_IS_VALID_REGEXP.test(psGuid);
	}

	//#endregion METHODS

}