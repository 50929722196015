import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { DestroyableComponentBase } from '../../../../../utils/components/destroyable-component-base';

export interface IEntityPickerFilterValues {
	name?: string
}

@Component({
	selector: 'calao-entity-picker-filter-bar-component',
	templateUrl: './entity-picker-filter-bar-component.component.html',
	styleUrls: ['./entity-picker-filter-bar-component.component.scss'],
})
export class EntityPickerFilterBarComponentComponent<T extends IEntityPickerFilterValues> extends DestroyableComponentBase {

	//#region FIELDS

	/** Événement lors du changement des filtres. */
	@Output("onFilterValuesChange") private readonly moFilterValuesChangeEvent = new EventEmitter<T>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Valeur des filtres. */
	@Input() public filterValues?: IEntityPickerFilterValues | null;
	@ObserveProperty<EntityPickerFilterBarComponentComponent<T>>({ sourcePropertyKey: "filterValues" })
	public readonly observableFilterValues = new ObservableProperty<IEntityPickerFilterValues>();

	@Input() public searchInputPlaceholder?: string | null;
	@ObserveProperty<EntityPickerFilterBarComponentComponent<T>>({ sourcePropertyKey: "searchInputPlaceholder" })
	public readonly observableSearchInputPlaceholder = new ObservableProperty<string>("Rechercher");

	//#endregion PROPERTIES

	//#region METHODS

	constructor() {
		super();
	}

	public onFilterValuesChange(poFilterValues: T): void {
		this.moFilterValuesChangeEvent.emit(this.observableFilterValues.value = poFilterValues);
	}

	//#endregion METHODS

}
