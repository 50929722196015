import { Injectable } from '@angular/core';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { IDataSource } from '@calaosoft/osapp-common/store/models/IDataSource';
import { EDatabaseRole } from '@calaosoft/osapp-common/store/models/edatabase-role';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DestroyableServiceBase } from '../modules/services/models/destroyable-service-base';
import { ConsentObject } from './interfaces/consent-object';
import { ConsentVersion } from './interfaces/consent-version';
import { EConsent } from './interfaces/econsent';
import { IConsent } from './interfaces/iconsent';
import { SecurityService } from './security.service';
import { Store } from './store.service';

@Injectable({ providedIn: "root" })
/** Service de gestion du consentement à l'utilisation de service tiers */
export class ConsentService extends DestroyableServiceBase {

	//#region FIELDS

	private get databaseId(): string {
		const lsDatabaseId: string = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.userContext, false));
		if (StringHelper.isBlank(lsDatabaseId))
			console.log(`CONS.S::Aucune base de rôle ${EDatabaseRole.userContext}.`);
		return lsDatabaseId;
	}

	private get documentId(): string {
		return `${EPrefix.consent}${this.isvcSecurity.userId}`;
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcSecurity: SecurityService,
	) {
		super();
	}

	/** Recupere un observable de boolean de consentement à l'utilisation de donnée par un service d'IA */
	public get$(psType: EConsent): Observable<boolean> {
		const loDataSource: IDataSource = {
			databaseId: this.databaseId,
			viewParams: {
				key: this.documentId,
				include_docs: true
			},
			live: true
		};

		return this.isvcStore.getOne<IConsent>(loDataSource, false).pipe(
			map((poResult: IConsent) =>
				poResult &&
				poResult[psType] &&
				DateHelper.isDate(poResult[psType].date) &&
				NumberHelper.isValid(poResult[psType].version) &&
				NumberHelper.isValid(ConsentVersion[psType]) &&
				+poResult[psType].version >= ConsentVersion[psType]
			)
		);
	}

	/** Enregistrer la date de consentement à l'utilisation de l'IA */
	public async consentAsync(psType: EConsent): Promise<void> {
		const loDataSource: IDataSource = {
			databaseId: this.databaseId,
			viewParams: {
				key: this.documentId,
				include_docs: true
			},
			live: false
		};

		let loResult = await this.isvcStore.getOne<IConsent>(loDataSource, false).toPromise();

		if (!loResult) {
			loResult = {
				_id: this.documentId
			};
		}

		loResult[psType] = new ConsentObject(new Date(), ConsentVersion[psType]);
		this.isvcStore.put(loResult, this.databaseId).toPromise();
	}

	//#endregion

}