import { Injectable, OnDestroy } from "@angular/core";
import { Destroyable } from "@calaosoft/osapp-common/destroyable/classes/destroyable";

@Injectable()
export class DestroyableServiceBase extends Destroyable implements OnDestroy {


	//#region METHODS

	public ngOnDestroy(): void {
		this.destroy();
	}

	//#endregion METHODS

}