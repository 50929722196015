import { Pipe, PipeTransform } from '@angular/core';
import { Contact, ContactAddress, ContactField, ContactName, IContactAddress, IContactField } from '@awesome-cordova-plugins/contacts/ngx';
import { GuidHelper } from "@calaosoft/osapp-common/guid/helpers/guidHelper";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { ContactHelper } from '../helpers/contactHelper';
import { EGender } from '../model/EGender';
import { IContact } from '../model/contacts/IContact';

interface AddressPartsIndicators {
	streetFilled: boolean;
	zipCodeFilled: boolean;
	cityFilled: boolean;
}

@Pipe({ name: 'icontact' })
export class IContactPipe implements PipeTransform {

	//#region METHODS

	constructor() { }

	/** Transforme un objet contact ionic en IContact.
	 * @param poIonicContact Contact ionic à transformer en IContact.
	 */
	public transform(poIonicContact: Contact): IContact {
		const loNewContact: IContact = {
			_id: GuidHelper.newGuid(),
			firstName: poIonicContact.name.givenName?.trim(),
			lastName: poIonicContact.name.familyName?.trim(),
			birthDate: poIonicContact.birthday
		};

		if (ArrayHelper.hasElements(poIonicContact.emails))
			loNewContact.email = ArrayHelper.getFirstElement(poIonicContact.emails).value;

		if (ArrayHelper.hasElements(poIonicContact.phoneNumbers))
			loNewContact.phone = ArrayHelper.getFirstElement(poIonicContact.phoneNumbers).value.replace(/[^0-9+]/g, "");

		this.setHonorificPrefix(poIonicContact, loNewContact);
		this.setMainAddress(poIonicContact, loNewContact);
		// this.setPhoto(poIonicContact, loNewContact); //TODO #2579 - Enregistrer l'avatar du contact venant du répertoire lors de son importation dans un nouveau contact

		return loNewContact;
	}

	private setHonorificPrefix(poIonicContact: Contact, poNewContact: IContact): void {
		if (!StringHelper.isBlank(poIonicContact.name.honorificPrefix)) {
			const lsHonorificPrefix: string = poIonicContact.name.honorificPrefix.toLowerCase();

			if (lsHonorificPrefix.startsWith("m.") || lsHonorificPrefix.startsWith("mr") || lsHonorificPrefix.startsWith("monsieur"))
				poNewContact.gender = EGender.Man;
			else
				poNewContact.gender = EGender.Woman;
		}
	}

	private setMainAddress(poIonicContact: Contact, poNewContact: IContact): void {
		if (ArrayHelper.hasElements(poIonicContact.addresses)) {
			const loMainAddress: IContactAddress = ArrayHelper.getFirstElement(poIonicContact.addresses);
			const loIndicators: AddressPartsIndicators = {
				streetFilled: false,
				zipCodeFilled: false,
				cityFilled: false
			};

			if (!StringHelper.isBlank(loMainAddress.streetAddress)) {
				poNewContact.street = loMainAddress.streetAddress.trim();
				loIndicators.streetFilled = true;
			}
			if (!StringHelper.isBlank(loMainAddress.postalCode)) {
				poNewContact.zipCode = loMainAddress.postalCode.trim();
				loIndicators.zipCodeFilled = true;
			}
			if (!StringHelper.isBlank(loMainAddress.locality)) {
				poNewContact.city = loMainAddress.locality.trim();
				loIndicators.cityFilled = true;
			}

			if (Object.values(loIndicators).some((pbIsFilled: boolean) => !pbIsFilled)) // Si au moins un champ d'adresse n'est pas renseigné.
				this.innerSetMainAddress(loMainAddress, poNewContact, loIndicators);
		}
	}

	private innerSetMainAddress(poContactAddress: IContactAddress, poNewContact: IContact, poIndicators: AddressPartsIndicators): void {
		if (!StringHelper.isBlank(poContactAddress.formatted)) {
			const lsFormattedAddress: string = poContactAddress.formatted.trim();
			const lnZipCodeLength = 5;
			// On récupère l'index où le code postal de l'adresse formattée se trouve (en se basant sur le fait qu'un code postal est composé de 5 chiffres).
			const lnStartZipCodeIndex: number = lsFormattedAddress.search(/[0-9]{5}/);

			// Si on a un code postal, on peut construire une adresse sous la forme "rue codePostal ville".
			if (lnStartZipCodeIndex > -1) {
				// On prend en compte une possible adresse qui sépare les champs par une virgule.
				const lsDirtyStreet: string = lsFormattedAddress.substring(0, lnStartZipCodeIndex).trim();
				// S'il y a une virgule à la fin de la rue, on la supprime, sinon on prend la rue telle quelle.
				const lsStreet: string = lsDirtyStreet.endsWith(',') ? lsDirtyStreet.substring(0, lsDirtyStreet.length - 1) : lsDirtyStreet;
				// On prend le code postal.
				const lsZipCode: string = lsFormattedAddress.substring(lnStartZipCodeIndex, lnStartZipCodeIndex + lnZipCodeLength).trim();
				// On prend la ville en supprimant si besoin une virgule entre le code postal et la ville.
				const lsCity: string = lsFormattedAddress.substr(lnStartZipCodeIndex + lnZipCodeLength).replace(',', '')
					.trim();

				// On affecte les différents champs s'ils ne sont pas renseignés et que les nouvelles valeurs obtenues sont valides.
				this.innerSetMainAddress_street(lsStreet, lsFormattedAddress, poNewContact, poIndicators);
				this.innerSetMainAddress_zipCode(lsZipCode, poNewContact, poIndicators);
				this.innerSetMainAddress_city(lsCity, poNewContact, poIndicators);
			}

			// Si tous les champs ne sont pas renseignés, on met l'adresse complète dans la rue pour qu'elle soit tout de même disponible.
			if (Object.values(poIndicators).every((pbIsFilled: boolean) => !pbIsFilled))
				poNewContact.street = lsFormattedAddress;
		}
	}

	private innerSetMainAddress_street(psStreet: string, psFormattedAddress: string, poNewContact: IContact, poIndicators: AddressPartsIndicators): void {
		// Il faut modifier le champ "rue" du nouveau contact s'il n'est pas renseigné ou s'il est égal à l'adresse formatée.
		if ((!poIndicators.streetFilled && !StringHelper.isBlank(psStreet)) || psFormattedAddress === poNewContact.street) {
			poNewContact.street = psStreet;
			poIndicators.streetFilled = true;
		}
	}

	private innerSetMainAddress_zipCode(psZipCode: string, poNewContact: IContact, poIndicators: AddressPartsIndicators): void {
		if (!poIndicators.zipCodeFilled && !StringHelper.isBlank(psZipCode)) {
			poNewContact.zipCode = psZipCode;
			poIndicators.zipCodeFilled = true;
		}
	}

	private innerSetMainAddress_city(psCity: string, poNewContact: IContact, poIndicators: AddressPartsIndicators): void {
		if (!poIndicators.cityFilled && !StringHelper.isBlank(psCity)) {
			poNewContact.city = psCity;
			poIndicators.cityFilled = true;
		}
	}

	private setPhoto(poIonicContact: Contact, poNewContact: IContact): void {
		if (ArrayHelper.hasElements(poIonicContact.photos)) {
			const loContactField: IContactField = ArrayHelper.getFirstElement(poIonicContact.photos);

			poNewContact.picture = {
				mimeType: loContactField.type,
				size: 0,
				url: loContactField.value
			};
		}
	}

	//#endregion

}

@Pipe({ name: 'contact' })
export class ContactPipe implements PipeTransform {

	//#region METHODS

	constructor() { }

	/** Transforme un IContact en objet contact ionic.
	 * @param poContact IContact à transformer en contact ionic.
	 */
	public transform(poContact: IContact): Contact {
		const loIonicContact = new Contact();

		loIonicContact.id = poContact._id;

		if (poContact.birthDate)
			loIonicContact.birthday = poContact.birthDate;

		this.setName(poContact, loIonicContact);
		this.setAddresses(poContact, loIonicContact);
		this.setEmails(poContact, loIonicContact);
		this.setPhoneNumbers(poContact, loIonicContact);
		// this.setPhotos(poContact, loIonicContact); // todo : tester le fonctionnement d'export d'un avatar.

		return loIonicContact;
	}

	private setName(poContact: IContact, poNewIonicContact: Contact): void {
		poNewIonicContact.name = new ContactName(
			ContactHelper.getCompleteFormattedName(poContact),
			poContact.lastName,
			poContact.firstName,
			"",
			(NumberHelper.isValid(poContact.gender) && poContact.gender === EGender.Man) ? "M." : "Mme"
		);
	}

	private setAddresses(poContact: IContact, poNewIonicContact: Contact): void {
		poNewIonicContact.addresses = [
			new ContactAddress(
				false,
				"",
				`${poContact.street}, ${poContact.zipCode}, ${poContact.city}`,
				poContact.street,
				poContact.city,
				"",
				poContact.zipCode
			)
		];
	}

	private setEmails(poContact: IContact, poNewIonicContact: Contact): void {
		poNewIonicContact.emails = [
			new ContactField("", poContact.email)
		];
	}

	private setPhoneNumbers(poContact: IContact, poNewIonicContact: Contact): void {
		poNewIonicContact.phoneNumbers = [
			new ContactField("", poContact.phone)
		];
	}

	private setPhotos(poContact: IContact, poNewIonicContact: Contact): void {
		poNewIonicContact.photos = [
			new ContactField("", poContact.picture ? poContact.picture.url : undefined)
		];
	}

	//#endregion

}