import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ObservableProperty } from "@calaosoft/osapp-common/observable/models/observable-property";
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { UserHelper } from "@calaosoft/osapp/helpers/user.helper";
import { FieldBase } from "@calaosoft/osapp/model/forms/FieldBase";
import { ITileFieldParams } from "@calaosoft/osapp/modules/forms/form/components/models/itile-field-params";
import { FormsService } from "@calaosoft/osapp/services/forms.service";
import { tap } from "rxjs";
import { OpportunitiesService } from "../opportunities/services/opportunities.service";

@Component({
	selector: 'calao-opportunity-tile-field',
	templateUrl: './opportunity-tile-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityTileFieldComponent extends FieldBase implements OnInit {

	//#region PROPERTIES

	public params: ITileFieldParams = {};

	public readonly observablePendingOpportunitiesCount = new ObservableProperty<number>(0);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcOpportunities: OpportunitiesService,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		if (this.props.data)
			this.params = this.props.data;

		this.isvcOpportunities.getPendingOpportunitiesCount$(UserHelper.getUserContactId(), true).pipe(
			tap((pnPendingOpportunitiesCount: number) => this.observablePendingOpportunitiesCount.value = pnPendingOpportunitiesCount),
			secure(this)
		).subscribe();
	}

	public navigateToOpportunitiesAsync(): Promise<boolean> {
		return this.isvcOpportunities.navigateToOpportunitiesAsync();
	}

	//#endregion
}