import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Zip } from '@awesome-cordova-plugins/zip/ngx';
import { ZipService } from './services/zip.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [ZipService, Zip]
})
export class ZipModule { }