import { ObjectHelper } from "../../../utils/helpers/objectHelper";

export class ReplaceObservableArrayError extends Error {

	constructor(pnIndex: number, pnArrayLength: number) {
		super(`Can not replace element at index ${pnIndex} because the array has a length of ${pnArrayLength}`);
		// Nécessaire pour que le 'instanceof ReplaceObservableArrayError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, ReplaceObservableArrayError);
	}

}