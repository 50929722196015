import { Injectable } from '@angular/core';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { EUTCAccuracy } from '@calaosoft/osapp-common/dates/models/eutc-accuracy.enum';
import { ELogActionId } from '@calaosoft/osapp-common/logging/models/ELogActionId';
import { PerformanceManager } from '@calaosoft/osapp-common/performance/PerformanceManager';
import { ELocalToServerReplicationMode } from '@calaosoft/osapp-common/store/models/elocal-to-server-replication-mode.enum';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { Directory, FileInfo } from '@capacitor/filesystem';
import { Database } from '../../../model/store/Database';
import { FilesystemCreateError } from '../../filesystem/models/errors/filesystem-create-error';
import { FilesystemErrorBase } from '../../filesystem/models/errors/filesystem-error-base';
import { FilesystemListDirectoryEntriesError } from '../../filesystem/models/errors/filesystem-list-directory-entries-error';
import { FilesystemService } from '../../filesystem/services/filesystem.service';
import { LoggerService } from '../../logger/services/logger.service';
import { LocalDatabaseProviderService } from '../../sqlite/services/providers/local-database-provider.service';
import { IBackupLogActionData } from '../models/ibackup-log-action-data';
import { IBackupOptions } from '../models/ibackup-options';
import { IBackupsResult } from '../models/ibackups-result';

interface IDatabaseFile {
	readonly database: Database;
	readonly files: FileInfo[];
}
interface IRemovedBackupsResult {
	readonly fileName: string;
	readonly success: number;
	readonly failed: number;
}
interface INotRemovedBackupsResult {
	readonly success: 0;
	readonly failed: 0;
}

@Injectable()
export class BackupsService {

	//#region FIELDS

	private static readonly C_LOG_ID = "BCK.S::";
	private static readonly C_FOLDER_NAME = "Backups";
	private static readonly C_TMP_EXTENSION = ".tmp";
	private static readonly C_ROOT_DIRECTORY = Directory.External;
	private static readonly C_UTC_ACCURACY = EUTCAccuracy.milliseconds;

	//#endregion FIELDS

	//#region METHODS

	public constructor(
		private readonly isvcFilesystem: FilesystemService,
		private readonly isvcLogger: LoggerService,
		private readonly isvcLocalDatabaseProvider: LocalDatabaseProviderService
	) {
		// On crée le répertoire pour les backups à l'initialisation du service
		// pour ne pas avoir d'erreur de répertoire qui n'existe pas plus tard.
		this.initFolderAsync();
	}

	private initFolderAsync(): Promise<void> {
		return this.isvcFilesystem.createDirectoryAsync(
			BackupsService.C_FOLDER_NAME,
			BackupsService.C_ROOT_DIRECTORY
		)
			.catch((poError: FilesystemCreateError) =>
				console.error(`${BackupsService.C_LOG_ID}Can not create backup folder because : `, poError.error)
			);
	}

	/** Crée une sauvegarde de la base de données passée en paramètre.
	 * @param poDatabase Base de données à sauvegarder.
	 * @param poOptions Options de sauvegarde de la base de données, `forceBackup: false` par défaut.
	 */
	public backupDatabaseAsync(poDatabase: Database, poOptions?: IBackupOptions): Promise<IBackupsResult[]> {
		return this.backupDatabasesAsync([poDatabase], poOptions);
	}

	/** Crée une sauvegarde des bases de données passées en paramètres.
	 * @param paDatabases Tableau des bases de données à sauvegarder.
	 * @param poOptions Options de sauvegarde des bases de données, `forceBackup: false` par défaut.
	 */
	public async backupDatabasesAsync(paDatabases: Database[], poOptions?: IBackupOptions): Promise<IBackupsResult[]> {
		this.logStartBackup(paDatabases);

		const loPerfManager = new PerformanceManager().markStart();
		const loOptions: IBackupOptions = this.getBackupDatabasesOptions(poOptions);
		const laBackupsResults: IBackupsResult[] = [];
		const laEligibleDatabases: Database[] =
			paDatabases.filter((poDatabase: Database) => this.canBackupFromDatabaseConfig(poDatabase, loOptions));

		if (ArrayHelper.hasElements(laEligibleDatabases)) {
			laEligibleDatabases.push(
				... await this.execBackupDatabasesAsync(laEligibleDatabases, loOptions)
			);
		}

		this.logEndBackup(paDatabases, laBackupsResults, loPerfManager.markEnd().measure());

		return laBackupsResults;
	}

	private getBackupDatabasesOptions(poOptions?: IBackupOptions): IBackupOptions {
		return poOptions ? poOptions : { forceBackup: false };
	}

	private canBackupFromDatabaseConfig(poDatabase: Database, poOptions: IBackupOptions): boolean {
		// Base de données peut être sauvegardée si les options forcent la sauvegarde ou si la config de la base le permet.
		return !!poOptions.forceBackup || NumberHelper.isValidStrictPositive(poDatabase.optimization?.maxBackups);
	}

	private async execBackupDatabasesAsync(paDatabases: Database[], poOptions: IBackupOptions): Promise<IBackupsResult[]> {
		const laBackupsResults: IBackupsResult[] = [];

		const laCanBackupDatabaseFiles: IDatabaseFile[] =
			await this.getDatabaseFilesToBackupAsync(paDatabases, poOptions);
		const loExistingBackupFilesByPartialName: Map<string, FileInfo[]> =
			await this.getExistingBackupFilesByPartialNameAsync();

		for (let lnIndex = 0; lnIndex < laCanBackupDatabaseFiles.length; ++lnIndex) {
			const loDatabaseFile: IDatabaseFile = laCanBackupDatabaseFiles[lnIndex];

			for (let lnFilesIndex = 0; lnFilesIndex < loDatabaseFile.files.length; ++lnFilesIndex) {
				const loFile: FileInfo = loDatabaseFile.files[lnFilesIndex];

				const loResult: IBackupsResult = await this.backupFileAsync(
					loFile,
					this.getDatabaseBackupsLimit(loDatabaseFile.database),
					loExistingBackupFilesByPartialName.get(loFile.name)
				);

				laBackupsResults.push(loResult);
			}
		}

		return laBackupsResults;
	}

	private async getDatabaseFilesToBackupAsync(paDatabases: Database[], poOptions: IBackupOptions): Promise<IDatabaseFile[]> {
		const loGetFilesPerfManager = new PerformanceManager().markStart();

		try {
			const laFiles: FileInfo[] = await this.isvcFilesystem.getFilesInfoAsync(
				this.isvcLocalDatabaseProvider.mobileAppDatabasesPath,
				LocalDatabaseProviderService.mobileAppDatabasesDirectory
			);
			const laResults: IDatabaseFile[] = [];

			paDatabases.forEach((poDatabase: Database) => {
				const loDatabaseFile: IDatabaseFile = {
					database: poDatabase,
					files: laFiles.filter((poFile: FileInfo) => this.canBackupDatabase(poDatabase, poFile, poOptions))
				};

				if (ArrayHelper.hasElements(loDatabaseFile.files))
					laResults.push(loDatabaseFile);
			});

			return laResults;
		}
		catch (poError) {
			console.error(`${BackupsService.C_LOG_ID}Error to get eligible backup database files :`, (poError as FilesystemListDirectoryEntriesError).error);
			return [];
		}
		finally {
			console.debug(`${BackupsService.C_LOG_ID}Get database files to backup in databases folder in ${loGetFilesPerfManager.markEnd().measure()}ms`);
		}
	}

	private canBackupDatabase(poDatabase: Database, poItem: FileInfo, poOptions: IBackupOptions): boolean {
		if (this.canBackupFromDatabaseConfig(poDatabase, poOptions)) {
			if (poItem.name.includes(poDatabase.id))
				return true; // Même nom et on peut sauvegarder la base de données.
			else if (poDatabase.localToServerReplicationMode === ELocalToServerReplicationMode.changeTracking) {
				// Pour le changeTracking, il y a des fichiers supplémentaires avec des tirets et pas des underscores,
				// on remplace donc les tirets par des underscores pour la comparaison des noms.
				return poItem.name.replace(/\-/g, "_").includes(poDatabase.id);
			}
		}

		return false;
	}

	private getExistingBackupFilesByPartialNameAsync(): Promise<Map<string, FileInfo[]>> {
		const loGetFilesPerfManager = new PerformanceManager().markStart();

		return this.isvcFilesystem.getFilesInfoAsync(
			BackupsService.C_FOLDER_NAME,
			BackupsService.C_ROOT_DIRECTORY
		)
			.then((paFiles: FileInfo[]) => {
				const loMapResult = new Map<string, FileInfo[]>();

				paFiles.sort((poFileA: FileInfo, poFileB: FileInfo) => // Tri du plus vieux au plus récent.
					poFileA.name.toLowerCase().localeCompare(poFileB.name.toLowerCase())
				)
					.forEach((poFile: FileInfo) => {
						const lsPartialBackupName: string = this.getNameWithoutUTCPrefix(poFile);
						loMapResult.get(lsPartialBackupName)?.push(poFile) ?? loMapResult.set(lsPartialBackupName, [poFile]);
					});

				return loMapResult;
			})
			.catch((poError: FilesystemListDirectoryEntriesError) => {
				console.error(`${BackupsService.C_LOG_ID}Error to get existing backup files :`, poError.error);
				return new Map();
			})
			.finally(() =>
				console.debug(`${BackupsService.C_LOG_ID}Get existing backup files in ${loGetFilesPerfManager.markEnd().measure()}ms`)
			);
	}

	/** Récupère le nom d'un backup sans le préfixe de date.
	 * @param poFile Fichier dont il faut récupérer le nom sans le préfixe de la date.
	 * @example "20250123104259741-mon-backup.toto" -> "mon-backup.toto"
	 */
	private getNameWithoutUTCPrefix(poFile: FileInfo): string {
		// +1 car le préfixe de date est suivi d'un tiret qu'on ne veut pas.
		return poFile.name.substring(BackupsService.C_UTC_ACCURACY + 1);
	}

	private getDatabaseBackupsLimit(poDatabase: Database): number {
		return NumberHelper.isValidStrictPositive(poDatabase.optimization?.maxBackups) ?
			poDatabase.optimization.maxBackups : 1;
	}

	/** Crée la sauvegarde d'un fichier.
	 * @param poFile Fichier dont il faut créer la sauvegarde.
	 * @param poDatabase Base de données associée au fichier à sauvegarder.
	 * @param paExistingBackups Tableau des sauvegardes pré-existantes pour ce fichier.
	 */
	private async backupFileAsync(poFile: FileInfo, pnBackupsLimit: number, paExistingBackups: FileInfo[] | undefined)
		: Promise<IBackupsResult> {

		try {
			await this.createBackupAsync(poFile);

			const loRemoveResult: IRemovedBackupsResult | INotRemovedBackupsResult =
				await this.cleanBackupsAfterCreatedOneAsync(pnBackupsLimit, paExistingBackups);

			return {
				name: poFile.name,
				createdSuccessCount: 1,
				createdFailedCount: 0,
				removedSuccessCount: loRemoveResult.success,
				removedFailedCount: loRemoveResult.failed
			};
		}
		catch (poError) {
			console.error(`${BackupsService.C_LOG_ID}Can not create backup for file '${poFile.uri}' :`, (poError as FilesystemErrorBase).error);

			return {
				name: poFile.name,
				createdSuccessCount: 1,
				createdFailedCount: 0,
				removedSuccessCount: 0,
				removedFailedCount: 0
			};
		}
	}

	/**
	 * @param poFile Fichier dont il faut créer une sauvegarde.
	 * @throws `FilesystemGetFileUriError` ou `FilesystemRemoveError` ou `FilesystemCopyError` ou `FilesystemRenameError` en cas d'erreur.
	 */
	private async createBackupAsync(poFile: FileInfo): Promise<void> {
		const lsTmpDestinationPath: string = this.createTmpDestinationPath(poFile);

		// On crée d'abord une copie du fichier à sauvegarder, avec une extension temporaire.
		await this.isvcFilesystem.copyAsync(
			{ value: poFile.uri },
			{ value: await this.isvcFilesystem.getFileUriAsync(lsTmpDestinationPath, BackupsService.C_ROOT_DIRECTORY) }
		);

		// On renomme ensuite la copie en supprimant l'extension temporaire.
		await this.isvcFilesystem.renameAsync(
			lsTmpDestinationPath,
			lsTmpDestinationPath.replace(BackupsService.C_TMP_EXTENSION, ""),
			BackupsService.C_ROOT_DIRECTORY
		);
	}

	/** Crée le chemin d'un backup temporaire à partir d'un fichier, au format `{nomDossierBackup}/{nomBackup}{extensionTmp}`.
	 * @param poFile Fichier dont il faut créer le nom temporaire du backup.
	 */
	private createTmpDestinationPath(poFile: FileInfo): string {
		return `${BackupsService.C_FOLDER_NAME}/${this.createDestinationFileName(poFile)}${BackupsService.C_TMP_EXTENSION}`;
	}

	/** Crée le nom d'un backup à partir d'un fichier, au format `{dateUTC}-{nomFichier}`.
	 * @param poFile Fichier dont il faut créer le nom du backup.
	 */
	private createDestinationFileName(poFile: FileInfo): string {
		return `${DateHelper.toUTCString(new Date(), BackupsService.C_UTC_ACCURACY)}-${poFile.name}`;
	}

	private cleanBackupsAfterCreatedOneAsync(pnBackupsLimit: number, paBackups?: FileInfo[])
		: Promise<IRemovedBackupsResult | INotRemovedBackupsResult> {

		if (!ArrayHelper.hasElements(paBackups))
			return Promise.resolve({ failed: 0, success: 0 });
		else // Si pas de config de backups, on supprime ceux qu'on a trouvés.
			return this.removeBackupsAboveLimitAsync(paBackups, pnBackupsLimit);
	}

	/** Supprime les fichiers de backups tant que la limite est atteinte.
	 * @param paBackups Tableau des fichiers de backups à supprimer si nécessaire.
	 * @param pnMaxBackups Nombre maximum de backups autorisé.
	 */
	private async removeBackupsAboveLimitAsync(paBackups: FileInfo[], pnMaxBackups: number)
		: Promise<IRemovedBackupsResult | INotRemovedBackupsResult> {

		if (paBackups.length < pnMaxBackups)
			return { failed: 0, success: 0 };

		else {
			const lsFileName: string = this.getNameWithoutUTCPrefix(paBackups[0]);
			let lnSuccessCount = 0;
			let lnFailedCount = 0;

			// Si on a autant voire plus de backups que le nombre limite configuré, on supprime.
			while (paBackups.length >= pnMaxBackups) {
				try {
					await this.isvcFilesystem.removeFileAsync(paBackups[0].uri);
					++lnSuccessCount;
				}
				catch (poError) {
					console.error(`${BackupsService.C_LOG_ID}Can not remove backup '${paBackups[0].uri}' :`, (poError as FilesystemErrorBase).error);
					++lnFailedCount;
				}
				finally {
					paBackups.shift();
				}
			}

			return { success: lnSuccessCount, failed: lnFailedCount, fileName: lsFileName };
		}
	}

	private logStartBackup(paDatabases: Database[]): void {
		this.isvcLogger.action(
			BackupsService.C_LOG_ID,
			`Backup for databases [${paDatabases.map((poDatabase: Database) => poDatabase.id).join(", ")}] starts`,
			ELogActionId.backupStart
		);
	}

	private logEndBackup(paDatabases: Database[], paBackupsResults: IBackupsResult[], pnDurationMs: number): void {
		const loActionData: IBackupLogActionData = {
			durationMs: pnDurationMs,
			results: paBackupsResults
		};

		this.isvcLogger.action(
			BackupsService.C_LOG_ID,
			`Backup for databases [${paDatabases.map((poDatabase: Database) => poDatabase.id).join(", ")}] ended`,
			ELogActionId.backupEnd,
			loActionData
		);
	}

	//#endregion METHODS

}