import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ObservableArray } from '@calaosoft/osapp-common/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { Queue } from '@calaosoft/osapp-common/queue/decorators/queue.decorator';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { ESortOrder } from '@calaosoft/osapp-common/utils/models/ESortOrder';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ENotificationChannel } from '../../../../../calendar-events/models/enotification-channel';
import { ENotificationType } from '../../../../../calendar-events/models/enotification-type';
import { IEventNotification } from '../../../../../calendar-events/models/ievent-notification';
import { IEventSortableNotification } from '../../../../../calendar-events/models/ievent-sortable-notification';
import { IRelativeNotificationDate } from '../../../../../calendar-events/models/irelative-notification-date';
import { EModalSize } from '../../../../../modal/model/EModalSize';
import { ModalService } from '../../../../../modal/services/modal.service';
import { IDeletableTag } from '../../../../../tags/models/ideletable-tag';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineListField } from '../inline-list-field-layout/models/iinline-list-field';
import { IInlineListFieldLayoutParams } from '../inline-list-field-layout/models/iinline-list-field-layout-params';
import { NotificationAddModalComponent } from './components/notification-add-modal/notification-add-modal.component';
import { ECustomNotificationDateUnit } from './models/ecustom-notification-date-unit';
import { ETranslatedNotificationDate } from './models/etranslated-notification-date';

interface EventNotificationFieldParams extends IInlineListField { }

interface INotificationTag extends IDeletableTag {
	value: IEventNotification;
}

@Component({
	selector: 'calao-event-notification-field',
	templateUrl: './event-notification-field.component.html',
	styleUrls: ['./event-notification-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventNotificationFieldComponent extends FieldBase<Array<IEventNotification>> implements OnInit, IInlineListField {

	//#region FIELDS

	private readonly moDefaultReminder: IEventNotification = {
		channel: ENotificationChannel.localNotification,
		type: ENotificationType.relative,
		before: {
			minutes: 15
		}
	};

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly observableNotifications = new ObservableArray<IEventNotification>([]);
	public readonly observableNotificationTags = new ObservableArray<INotificationTag>([]);
	/** `true` pour afficher le template readOnly, sinon `false`. */
	public readonly observableIsReadOnly = new ObservableProperty<boolean>();
	/** Libellé à afficher en mode readOnly. */
	public readonly observableReadonlyLabel = new ObservableProperty<string>();

	/** Style de layout à afficher. */
	public layout: "inline";
	/** Paramètres d'affichage à passer au layout. */
	public layoutParams: IInlineListFieldLayoutParams;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcModal: ModalService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observableIsReadOnly.value = !!this.props.readonly;

		const loParams: EventNotificationFieldParams = this.props.params ?? {};
		this.layout = loParams.layout;
		this.layoutParams = loParams.layoutParams;

		this.observableFieldValue.value$.pipe(
			tap((paNotifications: IEventNotification[]) => {
				if (this.observableIsReadOnly.value && ArrayHelper.hasElements(paNotifications)) {
					this.observableReadonlyLabel.value = this.getReadonlyLabel(paNotifications);
				}
				else {
					this.observableNotifications.resetArray(paNotifications);
					this.observableNotificationTags.resetArray(this.getNotificationTagsFromNotifications(paNotifications))
				}
			}),
			secure(this)
		).subscribe();

		this.fieldValue = [...(this.fieldValue ?? [this.moDefaultReminder])];
	}

	private getReadonlyLabel(paNotifications: IEventNotification[]): string {
		const laSortedNotifications: IEventNotification[] = this.sortNotifications(paNotifications);
		const loFirstNotification: IEventNotification = ArrayHelper.getFirstElement(laSortedNotifications);
		const lnMoreCount: number = laSortedNotifications.length - 1;
		const lsMoreLabel: string = laSortedNotifications.length > 1 ? `, +${lnMoreCount} autre${lnMoreCount > 1 ? "s" : ""}` : "";
		return `${this.getButtonlabel(loFirstNotification.before)}${lsMoreLabel}`;
	}

	private sortNotifications(paNotifications: IEventNotification[]): IEventNotification[] {
		const laEventSortableNotification: IEventSortableNotification[] = [...paNotifications].map((poEventNotification: IEventNotification) => {
			return {
				...poEventNotification,
				sortValue: this.getSortValue(poEventNotification)
			} as IEventSortableNotification;
		});

		return ArrayHelper.dynamicSort(laEventSortableNotification, "sortValue", ESortOrder.ascending);
	}

	private getSortValue(poEventNotification: IEventNotification): number {
		let lnSortValue: number;
		Object.entries(poEventNotification.before).forEach(([psKey, pnValue]: [string, number]) => {
			if (psKey === "minutes")
				lnSortValue = pnValue;
			else if (psKey === "hours")
				lnSortValue = pnValue * 60;
			else if (psKey == "days")
				lnSortValue = pnValue * 1440;
		});
		return lnSortValue;
	}

	public openReminderAddModal(): void {
		this.openReminderAddModal$().subscribe();
	}

	@Queue<EventNotificationFieldComponent, Parameters<EventNotificationFieldComponent["openReminderAddModal$"]>, ReturnType<EventNotificationFieldComponent["openReminderAddModal$"]>>({
		excludePendings: true
	})
	private openReminderAddModal$(): Observable<any> {
		return this.isvcModal.open({ component: NotificationAddModalComponent, cssClass: "transparent" }, EModalSize.medium).pipe(
			filter((poReminderDuration?: IRelativeNotificationDate) => !!poReminderDuration),
			tap((poReminderDuration?: IRelativeNotificationDate) => {

				const loNotification: IEventNotification = {
					channel: ENotificationChannel.localNotification,
					type: ENotificationType.relative,
					before: poReminderDuration
				};

				if (!this.fieldValue?.some((poNotification: IEventNotification) => ObjectHelper.areEqual(poNotification, loNotification)))
					this.fieldValue = [...this.fieldValue, loNotification];
			})
		);
	}

	public getButtonlabel(poReminderDuration: IRelativeNotificationDate): string {
		let lsLabel: string;
		Object.entries(poReminderDuration).forEach(([psKey, pnValue]: [string, number]) => {
			if (psKey === ECustomNotificationDateUnit.weeks) {
				psKey = ECustomNotificationDateUnit.days;
				pnValue = pnValue / 7;
			}
			lsLabel = `${pnValue} ${ETranslatedNotificationDate[psKey]}${pnValue > 1 ? "s" : ""} avant`;
		});
		return lsLabel;
	}

	public removeNotification(poNotification: IEventNotification): void {
		ArrayHelper.removeElementByFinder(
			this.observableFieldValue.value,
			(poEventNotification: IEventNotification) => ObjectHelper.areEqual(poNotification.before, poEventNotification.before)
		);
		this.observableFieldValue.value = [...this.observableFieldValue.value];
	}

	public removeNotificationTag(poTag: IDeletableTag): void {
		this.removeNotification((poTag as INotificationTag).value);
	}

	private getNotificationTagsFromNotifications(paNotifications: IEventNotification[] = []): INotificationTag[] {
		return paNotifications.map((poNotification: IEventNotification, pnIndex: number) => {
			return {
				id: pnIndex.toString(),
				label: this.getButtonlabel(poNotification.before),
				deletable: true,
				value: poNotification
			};
		})
	}

	//#endregion METHODS

}
