import { StringHelper } from "../../utils/helpers/stringHelper";

/** Permet de mettre à disposition des méthodes pour aider à manipuler des données Sqlite. */
export abstract class SqlHelper {

	//#region METHODS

	private constructor() { }

	/** Retourne une chaîne de caractères assainie (que des caractères alphanumériques, '-', '_').
	 * @param psName Chaîne de caractères à assainir (nom de table ou clé).
	 */
	public static sanitize(psName: string): string {
		if (StringHelper.isBlank(psName))
			return "";
		else // On supprime tous les caractères qui ne sont pas alphanumériques, '-' et '_'.
			return psName.replace(/[^a-zA-Z0-9_\-]*/gi, "");
	}

	//#endregion

}