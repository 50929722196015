import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { Entity } from '@calaosoft/osapp-common/entities/models/entity';
import { ELogActionId } from '@calaosoft/osapp-common/logging/models/ELogActionId';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { MapHelper } from '@calaosoft/osapp-common/utils/helpers/mapHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EMPTY, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../../services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '../../../services/uiMessage.service';
import { DmsService } from '../../dms/services/dms.service';
import { LogAction } from '../../logger/decorators/log-action.decorator';
import { ILogActionHandler } from '../../logger/models/ILogActionHandler';
import { LogActionHandler } from '../../logger/models/log-action-handler';
import { LoggerService } from '../../logger/services/logger.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { DmsDocument } from '../models/dms-document';
import { DocExplorerConfig } from '../models/doc-explorer-config';
import { Document } from '../models/document';
import { Folder } from '../models/folder';
import { DocExplorerDocumentsService } from './doc-explorer-documents.service';

@Injectable({
	providedIn: 'root'
})
export class DocExplorerService extends DestroyableServiceBase implements ILogActionHandler {

	//#region FIELDS

	private static readonly C_LOG_ID = "DOC.EXPLR.S::";

	private static readonly C_DEFAULT_ROOT_PATH = "";

	//#endregion FIELDS

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId: string = DocExplorerService.C_LOG_ID;
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioRouter: Router,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService
	) {
		super();
	}

	//#region EXPLORER

	private buildFolders(psPath?: string, psRootPath?: string, poConfig?: DocExplorerConfig): Folder[] {
		const laFolders: Folder[] = [];
		const lbHasRootPath = !StringHelper.isBlank(psRootPath);

		if (psPath !== DocExplorerService.C_DEFAULT_ROOT_PATH) {
			const laRootPathParts: string[] = psRootPath?.split(DmsService.C_PATH_SEPARATOR) ?? [];
			const laPathParts: string[] = psPath?.split(DmsService.C_PATH_SEPARATOR) ?? [];

			for (let lnIndex = 0; lnIndex < laPathParts.length; ++lnIndex) {
				const lsPath: string = laPathParts.slice(0, lnIndex + 1).join(DmsService.C_PATH_SEPARATOR);
				if (!lbHasRootPath || lnIndex + 1 >= laRootPathParts.length) {
					const loFolder: Folder | undefined = this.isvcDocExplorerDocuments.createFolder(
						poConfig?.getFolderConfig(lsPath),
						lsPath,
						[],
						new Map
					);

					if (loFolder)
						laFolders.push(loFolder);
				}
			}
		}

		if (!lbHasRootPath) {
			const loFolder: Folder | undefined = this.isvcDocExplorerDocuments.createFolder(
				poConfig?.getFolderConfig(DocExplorerService.C_DEFAULT_ROOT_PATH),
				DocExplorerService.C_DEFAULT_ROOT_PATH,
				[],
				new Map
			)

			if (loFolder)
				laFolders.unshift(loFolder);
		}

		return laFolders;
	}

	private async fillFoldersWithEntitiesAsync(paFolders: Folder[]): Promise<Folder[]> {
		const laEntityIds: string[] = paFolders.map(
			(poFolder: Folder) => this.isvcDocExplorerDocuments.extractEntityIdsFromFolder(poFolder)
		).flat();

		const laEntities: Entity[] = await this.isvcDocExplorerDocuments.getEntitiesAsync(laEntityIds);
		const loEntitiesById: Map<string, Entity> = ArrayHelper.groupByUnique(laEntities, (poEntity: Entity) => poEntity._id);

		return paFolders.map(
			(poFolder: Folder) => this.isvcDocExplorerDocuments.resolveFolderPropertiesPattern(poFolder, loEntitiesById)
		);
	}

	public getNavigationTree$(psPath?: string, psRootPath?: string): Observable<Folder[]> {
		return this.isvcDocExplorerDocuments.getConfig$().pipe(
			map((poConfig?: DocExplorerConfig) => this.buildFolders(psPath, psRootPath, poConfig)),
			switchMap((paFolders: Folder[]) => this.fillFoldersWithEntitiesAsync(paFolders))
		);
	}

	public fillDocumentsNavigationTrees$(paDocuments: Document[], psRootPath?: string): Observable<void> {
		const loNavigationTreeByDocId = new Map<string, Folder[]>();

		return this.isvcDocExplorerDocuments.getConfig$().pipe(
			tap((poConfig?: DocExplorerConfig) => {
				paDocuments.forEach((poDocument: Document) => {
					const psPath = poDocument.paths[0];
					const laFolders = this.buildFolders(psPath, psRootPath, poConfig);
					loNavigationTreeByDocId.set(poDocument._id, laFolders);
				});
			}),
			switchMap(async () => {
				const laFilledFolders: Folder[] = await this.fillFoldersWithEntitiesAsync(MapHelper.valuesToArray(loNavigationTreeByDocId).flat());

				const loFilledFoldersById: Map<string, Folder> = ArrayHelper.groupByUnique(laFilledFolders, (poFolder: Folder) => poFolder.path);
				paDocuments.forEach((poDocument: Document) => {
					const laNavigationTree: Folder[] = loNavigationTreeByDocId.get(poDocument._id) ?? [];
					poDocument.observableNavigationTree.resetArray(ArrayHelper.getValidValues(laNavigationTree.map(
						(poFolder: Folder) => loFilledFoldersById.get(poFolder.path)
					)));
				});

				return;
			})
		);
	}

	//#endregion EXPLORER

	//#region DOCUMENTS

	/** Place un fichier à la corbeille.
	 * @param psGuid Guid du fichier à supprimer.
	 */
	public moveToTrash$(poDocument: Document, psPath: string): Observable<boolean> {
		this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "trash", true);

		return this.innerMoveToTrash$(poDocument, psPath).pipe(
			tap((poDeletedDocument?: Document) => this.isvcUiMessage.showMessage(new ShowMessageParamsToast({
				duration: 4000,
				message: poDeletedDocument ? `${poDeletedDocument.name} mis à la corbeille.` : "Erreur lors de la suppression du document.",
				buttons: poDeletedDocument ? [
					{
						text: "Voir la corbeille",
						handler: () => this.ioRouter.navigate(["documents"], { queryParams: { path: ArrayHelper.getFirstElement(poDeletedDocument.paths) } })
					},
					{
						text: "Restaurer",
						handler: () => this.innerRestore$(poDeletedDocument, poDeletedDocument.paths[0]).pipe(map((poRestoredDocument: Document) => !!poRestoredDocument)).toPromise()
					}
				] : undefined
			}))),
			map((poDeletedDocument?: Document) => !!poDeletedDocument)
		);
	}

	@LogAction<Parameters<DocExplorerService["innerMoveToTrash$"]>, ReturnType<DocExplorerService["innerMoveToTrash$"]>>({
		actionId: ELogActionId.explorerDocMoveToTrash,
		successMessage: (_, poDocument: Document) => `Document '${poDocument._id}' placé dans la corbeille.`,
		errorMessage: (_, poDocument: Document) => `Le placement du document '${poDocument._id}' dans la corbeille a échoué.`,
		dataBuilder: (_, __, poDocument: Document) => { return { id: poDocument._id }; }
	})
	private innerMoveToTrash$(poDocument: Document, psPath: string): Observable<Document | undefined> {
		const pbAlreadyDeleted: boolean = poDocument.paths.every((psDocumentPath: string, pnIndex: number) => {
			if (psDocumentPath.startsWith("trash"))
				return true;
			else {
				poDocument.paths[pnIndex] = `trash${DmsService.C_PATH_SEPARATOR}${psDocumentPath}`;
				poDocument.archiveDate = new Date();
				poDocument.restoreDate = undefined;
				return false;
			}
		});
		if (pbAlreadyDeleted)
			console.error(`${DocExplorerService.C_LOG_ID}Document '${poDocument._id}' already in trash.`);

		if (poDocument instanceof DmsDocument) {
			if (!!ConfigData.environment.dms.shareDocumentMeta) {
				return this.isvcUiMessage.showAsyncMessage(
					new ShowMessageParamsPopup({
						header: "Suppression",
						message: `Êtes-vous sûr de vouloir placer ${poDocument.name} dans la corbeille ?`,
						buttons: [
							{ text: "Annuler", handler: UiMessageService.getFalsyResponse },
							{ text: "Supprimer", handler: UiMessageService.getTruthyResponse }
						]
					})
				).pipe(
					mergeMap((poResponse: IUiResponse<boolean>) => {
						if (poResponse.response)
							return this.isvcDocExplorerDocuments.moveToTrashDmsDocument$(poDocument);
						return EMPTY;
					})
				);
			}
			return EMPTY;
		}
		else {
			return this.isvcDocExplorerDocuments.moveToTrashFormDocument$(psPath, poDocument);
		}
	}

	/** Restaure un fichier archivé.
	 * @param psGuid Guid du fichier à restaurer.
	 */
	public restore$(poDocument: Document, psPath: string): Observable<boolean> {
		this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "trash", true);
		return this.innerRestore$(poDocument, psPath).pipe(
			tap((poRestoredDocument?: Document) => this.isvcUiMessage.showMessage(new ShowMessageParamsToast({
				duration: 4000,
				message: poRestoredDocument ? `${poRestoredDocument.name} restauré.` : "Erreur lors de la restauration du document.",
				buttons: poRestoredDocument ? [{
					text: "Voir le dossier",
					handler: () => this.ioRouter.navigate(["documents"], { queryParams: { path: ArrayHelper.getFirstElement(poRestoredDocument.paths) } })
				}] : undefined
			}))),
			map((poRestoredDocument?: Document) => !!poRestoredDocument)
		);
	}

	@LogAction<Parameters<DocExplorerService["innerRestore$"]>, ReturnType<DocExplorerService["innerRestore$"]>>({
		actionId: ELogActionId.explorerDocRestore,
		successMessage: (_, poDocument: Document) => `Document '${poDocument._id}' restored.`,
		errorMessage: (_, poDocument: Document) => `Restoration of document '${poDocument._id}' failed.`,
		dataBuilder: (_, __, poDocument: Document) => { return { id: poDocument._id }; }
	})
	private innerRestore$(poDocument: Document, psPath: string): Observable<Document | undefined> {
		const pbAlreadyRestored: boolean = poDocument.paths.every((psDocumentPath: string, pnIndex: number) => {
			if (!psDocumentPath.startsWith("trash") && !psDocumentPath.startsWith("archives"))
				return true;
			else {
				const laPathParts: string[] = psDocumentPath.split(DmsService.C_PATH_SEPARATOR);
				laPathParts.shift(); // Supprime la partie 'trash'
				poDocument.paths[pnIndex] = laPathParts.join(DmsService.C_PATH_SEPARATOR);
				poDocument.archiveDate = undefined;
				poDocument.restoreDate = new Date();
				return false;
			}
		});

		if (pbAlreadyRestored)
			console.error(`${DocExplorerService.C_LOG_ID}Document '${poDocument._id}' already restored.`);

		if (poDocument instanceof DmsDocument) {
			if (!!ConfigData.environment.dms.shareDocumentMeta) {
				return this.isvcUiMessage.showAsyncMessage(
					new ShowMessageParamsPopup({
						header: "Restauration",
						message: `Êtes-vous sûr de vouloir restaurer ${poDocument.name} ?`,
						buttons: [
							{ text: "Annuler", handler: UiMessageService.getFalsyResponse },
							{ text: "Restaurer", handler: UiMessageService.getTruthyResponse }
						]
					})
				).pipe(
					mergeMap((poResponse: IUiResponse<boolean>) => {
						if (poResponse.response)
							return this.isvcDocExplorerDocuments.restoreDmsDocument$(poDocument);

						return EMPTY;
					})
				);
			}
			return EMPTY;
		}
		else {
			return this.isvcDocExplorerDocuments.restoreFormDocument$(psPath, poDocument);
		}
	}

	/** Restaure un fichier archivé.
	 * @param psGuid Guid du fichier à restaurer.
	 */
	public delete$(poDocument: Document, psPath: string): Observable<boolean> {
		this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "delete", true);
		return this.innerDelete$(poDocument, psPath);
	}

	@LogAction<Parameters<DocExplorerService["innerDelete$"]>, ReturnType<DocExplorerService["innerDelete$"]>>({
		actionId: ELogActionId.explorerDocDestroy,
		successMessage: (_, poDocument: Document) => `Document '${poDocument._id}' destroyed.`,
		errorMessage: (_, poDocument: Document) => `Destruction of document '${poDocument._id}' failed.`,
		dataBuilder: (_, __, poDocument: Document) => { return { id: poDocument._id }; }
	})
	private innerDelete$(poDocument: Document, psPath: string): Observable<boolean> {
		if (poDocument._deleted)
			console.error(`${DocExplorerService.C_LOG_ID}Document '${poDocument._id}' already deleted.`);

		if (poDocument instanceof DmsDocument) {
			if (!!ConfigData.environment.dms.shareDocumentMeta) {
				return this.isvcUiMessage.showAsyncMessage(
					new ShowMessageParamsPopup({
						header: "Suppression",
						message: `Êtes-vous sûr de vouloir supprimer ${poDocument.name} ?<br/>Attention : le document sera définitivement supprimé !`,
						buttons: [
							{ text: "Annuler", handler: UiMessageService.getFalsyResponse },
							{ text: "Supprimer", handler: UiMessageService.getTruthyResponse }
						]
					})
				).pipe(
					mergeMap((poResponse: IUiResponse<boolean>) => {
						if (poResponse.response)
							return this.isvcDocExplorerDocuments.deleteDmsDocument$(poDocument);
						return of(false);
					})
				);
			}
			else
				return of(true);
		}
		else
			return this.isvcDocExplorerDocuments.deleteFormDocument$(psPath, poDocument);
	}

	//#endregion DOCUMENTS

	//#endregion METHODS

}
