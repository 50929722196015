import { ChangeDetectionStrategy, Component, Input, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { secure } from "@calaosoft/osapp-common/rxjs/operators/secure";
import { tap } from 'rxjs/operators';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { ELayoutType } from '../../models/elayout-type';
import { ILayoutParams } from '../../models/ilayout-params';
import { LayoutBase } from '../../models/layout-base';
import { StackLayoutComponent } from '../stack-layout/stack-layout.component';
import { TabLayoutComponent } from '../tab-layout/tab-layout.component';

@Component({
	selector: 'calao-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent extends DestroyableComponentBase {

	//#region FIELDS

	@ViewChild("container", { read: ViewContainerRef, static: true }) private moContainer?: ViewContainerRef;

	//#endregion

	//#region PROPERTIES

	/** Doc propriété. */
	@Input() public params?: ILayoutParams;
	@ObserveProperty<LayoutComponent>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<ILayoutParams>();

	//#endregion

	//#region METHODS

	constructor() {
		super();
		this.observableParams.value$.pipe(
			tap((poParams?: ILayoutParams) => {
				if (this.moContainer) {
					this.moContainer.clear();
					if (poParams)
						this.moContainer.createComponent(this.getLayoutType(poParams)).instance.params = poParams;
				}
			}),
			secure(this)
		).subscribe();
	}

	private getLayoutType(poParams: ILayoutParams): Type<LayoutBase> {
		switch (poParams.type) {
			case ELayoutType.tabs:
				return TabLayoutComponent;
			case ELayoutType.stack:
				return StackLayoutComponent;
			default:
				throw new Error(`Layout ${poParams.id} with type ${poParams.type} unknown.`);
		}
	}

	//#endregion

}
