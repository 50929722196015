import { coerceArray } from "@angular/cdk/coercion";
import { Exclude, Expose } from "@calaosoft/osapp-common/class-transformer";
import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { UserHelper } from "@calaosoft/osapp/helpers/user.helper";
import { BaseEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence";
import { IEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/ievent-occurrence";
import { Observable, combineLatest, defer } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { EReminderStatus } from "./ereminder-status";
import { ETradeEventType } from "./etrade-event-type";
import { Reminder } from "./reminder";

@ModelMatch((poOccurrence: IEventOccurrence) => poOccurrence.eventType === ETradeEventType.reminder, BaseEventOccurrence)
export class ReminderOccurrence extends BaseEventOccurrence {

	//#region PROPERTIES

	public get done(): boolean {
		return this.status === EReminderStatus.done;
	}
	@Exclude()
	public done$: Observable<boolean> = this.observableStatus.value$.pipe(map(() => this.done));

	public override get sortDate(): Date | undefined {
		if (this.done && this.statusChangeDate)
			return this.statusChangeDate;

		return this.startDate;
	}

	public override status?: EReminderStatus;
	public important?: boolean;

	@Expose()
	public override get participantIds(): string[] {
		const lsParticipantId: string | undefined = ArrayHelper.getLastElement(this.maParticipantIds);
		return StringHelper.isBlank(lsParticipantId) ? [] : coerceArray(lsParticipantId);
	}

	public override set participantIds(paParticipantsIds: string[]) {
		this.maParticipantIds = paParticipantsIds;
	}

	@Exclude()
	public readonly canBeEnded$: Observable<boolean> = this.getCanBeEnded$();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poReminder?: Reminder, pdStartDate?: Date) {
		super(poReminder, pdStartDate);
		this.important = poReminder?.important;
	}

	private getCanBeEnded$(): Observable<boolean> {
		return this.observableParticipantIds.changes$.pipe(
			map(() => this.hasSomeParticipants([UserHelper.getUserContactId()])),
			distinctUntilChanged()
		);
	}

	protected override getCanBeEdited$(): Observable<boolean> {
		return defer(() => combineLatest([this.done$, super.getCanBeEdited$()])).pipe(
			map(([pbIsDone, pbCanBeEdited]: [boolean, boolean]) => !pbIsDone && pbCanBeEdited)
		);
	}

	//#endregion METHODS
}