import { Component, Input } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { ModalController } from '@ionic/angular';
import { IGalleryFile } from '../../../../model/gallery/IGalleryFile';
import { ModalComponentBase } from '../../../../modules/modal/model/ModalComponentBase';
import { PlatformService } from '../../../../services/platform.service';
import { EGalleryDisplayMode } from '../../models/EGalleryDisplayMode';

@Component({
	selector: 'calao-gallery-add-modal',
	templateUrl: './gallery-add-modal.component.html',
	styleUrls: ['./gallery-add-modal.component.scss'],
})
export class GalleryAddModalComponent extends ModalComponentBase<IGalleryFile> {

	//#region PROPERTIES

	@Input() public title: string;
	@Input() public defaultOrdoDescription: string;
	@Input() public allowCustomDescription: boolean;
	@Input() public multiple: boolean;

	public files: IGalleryFile[] = [];
	/** Mode de sélection. */
	public galleryDisplayMode = EGalleryDisplayMode;

	//#endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public onAddFile(paFiles: IGalleryFile[]): void {
		this.close(ArrayHelper.getFirstElement(paFiles));
	}

	//#endregion

}