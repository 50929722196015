import { Injectable } from '@angular/core';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { tapAll } from "@calaosoft/osapp-common/rxjs/operators/tap-all";
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { Directory } from '@capacitor/filesystem';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { combineLatest, defer, EMPTY, EmptyError, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, last, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { OsappImageComponent } from '../components/image/osapp-image.component';
import { FileHelper } from '../helpers/fileHelper';
import { IGalleryFile } from '../model/gallery/IGalleryFile';
import { DmsFile } from '../modules/dms/model/DmsFile';
import { IDmsData } from '../modules/dms/model/IDmsData';
import { DmsService } from '../modules/dms/services/dms.service';
import { FilesystemService } from '../modules/filesystem/services/filesystem.service';
import { Loader } from '../modules/loading/Loader';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from './interfaces/ShowMessageParamsToast';
import { LoadingService } from './loading.service';
import { PlatformService } from './platform.service';
import { UiMessageService } from './uiMessage.service';

@Injectable()
export class GalleryService {

	//#region FIELDS

	private static readonly C_GALLERY_MODAL_CSS_CLASS = "gallery-modal";
	private static readonly C_LOG_ID = "GLR.S::";

	//#endregion

	//#region METHODS

	constructor(
		private isvcDms: DmsService,
		private ioFileOpener: FileOpener,
		private ioModalCtrl: ModalController,
		private isvcPlatform: PlatformService,
		private isvcUiMessage: UiMessageService,
		private readonly isvcFilesystem: FilesystemService,
		private readonly isvcLoading: LoadingService,
	) { }

	public openFile(poFile: IGalleryFile, pbIsFileOpening?: boolean): Observable<any> {
		// Si le fichier est valide et possède un chemin OU si le fichier est valide et qu'on n'est pas sur mobile (pas besoin de chemin en webapp).
		if (poFile.file &&
			(!StringHelper.isBlank(poFile.file.Path) || (StringHelper.isBlank(poFile.file.Path) && !this.isvcPlatform.isMobileApp))) {

			return this.innerOpenFile(poFile.file, poFile.name, pbIsFileOpening);
		}

		// Si le fichier n'est pas nouveau et n'est pas en cours de chargement et possède un GUID, on demande le téléchargement du fichier pour l'ouvrir.
		else if (!poFile.isNew && !poFile.isLoading && poFile.guid) {
			let loLoader: Loader;
			return defer(() => {
				poFile.isLoading = true;
				return this.isvcLoading.create("Chargement en cours...");
			})
				.pipe(
					tap((poLoader: Loader) => loLoader = poLoader),
					mergeMap((poLoader: Loader) => poLoader.present()),
					mergeMap(_ => this.isvcDms.get(poFile.guid)), // Récupère méta dans bdd locale, le télécharge si besoin.
					catchError(poError => { this.onOpenError(poError, poFile.name); return EMPTY; }),
					tap(_ => poFile.isAvailable = true),
					mergeMap((poResult: IDmsData) => this.innerOpenFile(poResult.file, poFile.name, pbIsFileOpening)),
					tapAll(() => {
						poFile.isLoading = false;
						loLoader?.dismiss();
					}),
					finalize(() => {
						poFile.isLoading = false;
						loLoader?.dismiss();
					})
				);
		}

		else if (poFile.isLoading)
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Le fichier est en cours de chargement." }));

		return EMPTY;
	}
	/** Enregistre dans le DMS, des fichiers provenant du composant `GalleryComponent`.
	 * @param paFiles Tableau des fichiers de la galerie à enregistrer.
	 */
	public save$(paFiles: IGalleryFile[], paPreviousFiles?: IGalleryFile[]): Observable<boolean> {
		const laFilesToDelete: IGalleryFile[] = [];

		if (ArrayHelper.hasElements(paPreviousFiles)) {
			const loGalleryFileByGuid = new Map<string, IGalleryFile>(paFiles.map((poGalleryFile: IGalleryFile) => [poGalleryFile.guid, poGalleryFile]));
			laFilesToDelete.push(...paPreviousFiles.filter((poGalleryFile: IGalleryFile) => !loGalleryFileByGuid.has(poGalleryFile.guid)));
		}

		return combineLatest([this.saveFiles$(paFiles), this.removeFiles$(laFilesToDelete)]).pipe(mapTo(true));
	}

	/** Enregistre les fichiers dans le dms.
	 * @param paFiles Tableau des fichiers de la galerie à enregistrer.
	 */
	private saveFiles$(paFiles: IGalleryFile[]): Observable<boolean> {
		return from(Array.from(paFiles))
			.pipe(
				filter((poFile: IGalleryFile) => poFile.isNew),
				mergeMap((poFile: IGalleryFile) => this.isvcDms.save(poFile.file, poFile.file.createDmsMeta(poFile.guid, undefined, poFile.subType))),
				last(),
				mapTo(true),
				catchError(poError => poError instanceof EmptyError ? of(true) : throwError(() => poError))
			);
	}

	/** Supprime les fichiers et les méta associés.
	 * @param paFilesToDelete Tableau des fichiers de la galerie à supprimer.
	*/
	private removeFiles$(paFilesToDelete: IGalleryFile[]): Observable<boolean> {
		return from(paFilesToDelete)
			.pipe(
				mergeMap((poFile: IGalleryFile) => this.isvcDms.delete(poFile.guid)),
				last(),
				mapTo(true),
				catchError(poError => {
					if (!(poError instanceof EmptyError))
						console.warn(`${GalleryService.C_LOG_ID}Error while deleting documents.`, poError); // Ne doit pas bloquer la sauvegarde dans le cas d'une erreur de suppression d'image sur le DMS
					return of(true);
				}),
			);
	}

	/** Ouvre le fichier souhaité.
	 * @param poFile Fichier à ouvrir.
	 * @param psName Nom du fichier à ouvrir.
	 */
	private innerOpenFile(poFile: DmsFile, psName: string, pbIsFileOpening?: boolean): Observable<true> {
		let loOpenFile$: Observable<true | never>;

		if (!poFile) // Si le fichier n'existe pas.
			loOpenFile$ = this.onOpenError(`Fichier "${psName}" inexistant`, psName);

		else if (this.isvcPlatform.isMobileApp && !StringHelper.isBlank(poFile.Path)) { // Si on est sur mobile.
			pbIsFileOpening = true;

			loOpenFile$ = defer(() => StringHelper.isBlank(poFile.Path) ? poFile.Path$.pipe(take(1)) : of(poFile.Path))
				.pipe(
					mergeMap(async (psPath: string) => await this.isvcFilesystem.getFileUriAsync(psPath, Directory.External)),
					mergeMap((psPath: string) => this.ioFileOpener.open(psPath, poFile.MimeType)),
					catchError(poError => this.onOpenError(poError, psName)),
					finalize(() => pbIsFileOpening = false),
					mapTo(true)
				);
		}

		else // Si on est sur webapp.
			loOpenFile$ = this.innerOpenFile_browser(poFile);

		return loOpenFile$;
	}

	/** Ouvre le fichier souhaité en mode browser.
 * @param poFile Fichier à ouvrir.
 */
	private innerOpenFile_browser(poFile: DmsFile): Observable<true> {
		let loOpenFile$: Observable<true | never>;

		if (!StringHelper.isBlank(poFile.MimeType) && poFile.MimeType.indexOf("image") >= 0) { // Si on veut ouvrir une image, aucun soucis.
			const loModalOptions: ModalOptions = {
				component: OsappImageComponent,
				componentProps: { src: poFile.File },
				cssClass: GalleryService.C_GALLERY_MODAL_CSS_CLASS
			};

			loOpenFile$ = defer(() => this.ioModalCtrl.create(loModalOptions))
				.pipe(
					mergeMap((poModal: HTMLIonModalElement) => {
						const lfOnModalClick: () => void = () => {
							poModal.dismiss();
							poModal.removeEventListener("click", lfOnModalClick);
						};

						return poModal.present().then(() => poModal.addEventListener("click", lfOnModalClick));
					}),
					mapTo(true)
				) as Observable<true>;
		}
		else { // Sinon, on télécharge le document.
			FileHelper.downloadBlob(poFile.File as Blob, poFile.Name);
			loOpenFile$ = EMPTY;
		}

		return loOpenFile$;
	}

	/** Affiche une popup pour indiquer un problème lors de l'ouverture d'un nouveau fichier dans la galerie en mode édition.
	 * @param poOpenError Objet correspondant à l'erreur survenue lors de l'ouverture du fichier.
	 * @param psName Nom du fichier qui n'a pas pu être ouvert.
	 */
	private onOpenError(poOpenError: any, psName: string): Observable<never> {
		console.error("GLR.S::", poOpenError || "Erreur ouverture fichier ajouté dans gallerie en mode édition (pas encore sauvegardé).");

		this.isvcUiMessage.showMessage(this.getOnOpenErrorPopupParams(poOpenError, psName));

		return EMPTY;
	}

	private getOnOpenErrorPopupParams(poOpenError: any, psName: string): ShowMessageParamsPopup {
		const loParams = new ShowMessageParamsPopup();

		if (!poOpenError) { // Erreur undefined = fichier ajouté pendant modif.
			loParams.message = "Veuillez enregistrer avant de pouvoir visualiser le fichier.";
			loParams.header = "Erreur de téléchargement";
		}
		else {
			const lsMessageTitle = "Erreur d'ouverture";

			if (poOpenError.status === 9) {
				loParams.message = "Aucune application disponible permettant d’ouvrir ce type de fichier.";
				loParams.header = lsMessageTitle;
			}
			else if (poOpenError.code === "extension") {
				loParams.message = poOpenError.meta;
				loParams.header = lsMessageTitle;
			}
			else {
				loParams.message = `Le fichier "${psName}" n'est pas disponible pour le moment.`;
				loParams.header = lsMessageTitle;
			}
		}

		return loParams;
	}

	//#endregion

}
