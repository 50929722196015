import { Component, Input, OnInit } from '@angular/core';
import { GuidHelper } from "@calaosoft/osapp-common/guid/helpers/guidHelper";
import { IBarElement } from '../../../model/barElement/IBarElement';
import { IDynHostComponent } from '../../dynHost/IDynHost.component';

@Component({
	selector: "osapp-fab-button",
	styleUrls: ['fabButton.component.scss'],
	templateUrl: './fabButton.component.html'
})
export class FabButtonComponent implements IDynHostComponent, OnInit {

	//#region PROPERTIES

	public readonly instanceId: string = GuidHelper.newGuid();

	@Input() public params: IBarElement;

	public isDisable = false;

	//#endregion

	//#region METHODS

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		if (this.params.options && this.params.options.isDisable) {
			this.params.onTap = () => { };
			this.isDisable = this.params.options.isDisable;
		}
	}

	//#endregion
}