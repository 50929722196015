import { Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';

@Component({
	selector: 'calao-event-occurences-counter',
	templateUrl: './event-occurences-counter.component.html',
	styleUrls: ['./event-occurences-counter.component.scss'],
})
export class EventOccurencesCounterComponent {

	/** Icône d'une tâche. */
	@Input() public taskIcon?: string;
	@ObserveProperty<EventOccurencesCounterComponent>({ sourcePropertyKey: "taskIcon" })
	public readonly observableTaskIcon = new ObservableProperty<string>("task");

	/** Compteur d'occurrences de tâches. */
	@Input() public taskCount?: number;
	@ObserveProperty<EventOccurencesCounterComponent>({ sourcePropertyKey: "taskCount" })
	public readonly observableTaskCount = new ObservableProperty<number>();

	/** Icône d'un évènement. */
	@Input() public eventIcon?: string;
	@ObserveProperty<EventOccurencesCounterComponent>({ sourcePropertyKey: "eventIcon" })
	public readonly observableEventIcon = new ObservableProperty<string>("star");

	/** Compteur d'occurrences d'évènements. */
	@Input() public eventCount?: number;
	@ObserveProperty<EventOccurencesCounterComponent>({ sourcePropertyKey: "eventCount" })
	public readonly observableEventCount = new ObservableProperty<number>();

	constructor() { }

}
