import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { Observable, of } from 'rxjs';
import { InjectorService } from '../injector.service';
import { NotificationService } from '../notification.service';
import { TaskBase } from './TaskBase';
import { TaskDescriptor } from './TaskDescriptor';

/** Tâche qui permet d'initialiser le service des notifications.
 * La tâche doit s'exécuter après que la platforme et la configuration soit ready.
 */
export class NotifInitTask extends TaskBase {

	//#region FIELDS

	protected msvcNotification: NotificationService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor) {
		super(poDescriptor);

		this.msvcNotification = InjectorService.instance.get<NotificationService>(NotificationService);
	}

	public execTask$(): Observable<any> {
		// S'il y a une configuration OneSignal dans l'application, alors on appelle la fonction d'initialisation du service.
		if (!ConfigData || !ConfigData.oneSignal) {
			console.warn("NOTIFINIT.T::Pas de configuration pour OneSignal.");
			return of(undefined);
		} else {
			return this.msvcNotification.initPushNotifications(ConfigData.oneSignal);
		}
	}

	//#endregion
}