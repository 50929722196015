import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { EDomainName } from './domain/EDomainName';

/**  Définition de la classe PageInfo utilisé par exemple pour changer de page. */
export class PageInfo {

	//#region FIELDS

	private static readonly C_DEFAULT_PAGE_TITLE = "page";

	//#endregion

	//#region PROPERTIES

	/** Nom du composant utilisé par la page comme indiqué par le page manager. */
	public componentName: string;
	/** Titre de la page suivante. */
	public title: string;
	/** Id du template utilisé par la page de destination. */
	public templateId: string;
	/** Nom du template utilisé par le page de destination. */
	public templateName: string;
	/** Booléen indiquant si la page destination devra posséder le bouton menu. */
	public hasMenuButton: boolean;
	/** Booléen indiquant si la page destination devra posséder le bouton home. */
	public hasHomeButton: boolean;
	/** Booléen indiquant si la page destination devra posséder le bouton setting. */
	public hasSettingButton: boolean;
	/** Booléen indiquant si la page destination devra posséder le bouton back*/
	public hasBackButton: boolean;
	/** Booléen indiquant si la page destination devra posséder le bouton montrant l'état de la synchronisation. */
	public hasSyncButton: boolean;
	/** Booléen indiquant si la page destination devra être une page modale. */
	public isModal: boolean;
	/** Id CSS du ion-content. */
	public cssId: string;
	/** Paramètres à passer à la page de destination. */
	public params: any;
	/** Indique si le composant fonctionne seul (true) ou à besoin d'un Component-Page pour vivre (false). */
	public isStandalone: boolean;
	/** Identifiant du domaine de la page, "common" par défaut. */
	public domainId: string;
	/** Indique si la croix pour fermer la modale doit être visible. */
	public isClosable: boolean;
	/** Indique si une page fait parti d'un tunnel de pages temporaires en vue de supprimer la page lorsque le tunnel est terminé. */
	public isWizard: boolean;
	/** Chemin de l'url. */
	public path: string;
	/** Route par défaut. */
	public defaultHref?: string;
	/** Taille à partir de laquelle le bouton menu va disparaître. */
	public menuButtonDisappearAtWidthPx?: number;
	/** Présence du bouton conversations. */
	public hasConversationsButton?: boolean;
	/** Présence du bouton notifications. */
	public hasNotificationsButton?: boolean;
	/** Largeur à laquelle le bouton conversations disparaît, en px. */
	public conversationsButtonDisappearAtWidthPx?: number;
	public tabBarStyle?: boolean;

	//#endregion

	//#region METHODS

	constructor(poProperties: {
		componentName?: string, title?: string, templateId?: string, templateName?: string, hasMenuButton?: boolean, hasHomeButton?: boolean, isWizard?: boolean,
		hasSettingButton?: boolean, isModal?: boolean, params?: any, isStandalone?: boolean, domainId?: string, isClosable?: boolean, path?: string,
		hasBackButton?: boolean, hasSyncButton?: boolean, cssId?: string, defaultHref?: string, menuButtonDisappearAtWidthPx?: number, hasConversationsButton?: boolean,
		conversationsButtonDisappearAtWidthPx?: number, hasNotificationsButton?: boolean,
		tabBarStyle?: boolean
	}) {

		this.componentName = poProperties.componentName;
		this.params = poProperties.params;

		this.tabBarStyle = poProperties.tabBarStyle ? poProperties.tabBarStyle : false;
		this.hasMenuButton = poProperties.hasMenuButton ? poProperties.hasMenuButton : false;
		this.hasSettingButton = poProperties.hasSettingButton ? poProperties.hasSettingButton : false;
		this.isModal = poProperties.isModal ? poProperties.isModal : false;
		this.isStandalone = poProperties.isStandalone ? poProperties.isStandalone : false;
		this.isWizard = poProperties.isWizard ? poProperties.isWizard : false;

		this.hasHomeButton = poProperties.hasHomeButton === undefined ? true : poProperties.hasHomeButton;
		this.hasBackButton = poProperties.hasBackButton === undefined ? true : poProperties.hasBackButton;
		this.hasSyncButton = poProperties.hasSyncButton === undefined ? false : poProperties.hasSyncButton;
		this.isClosable = poProperties.isClosable === undefined ? true : poProperties.isClosable;
		this.cssId = poProperties.cssId ?? "";

		this.title = StringHelper.isBlank(poProperties.title) ? PageInfo.C_DEFAULT_PAGE_TITLE : poProperties.title;
		this.templateId = StringHelper.isBlank(poProperties.templateId) ? "default" : poProperties.templateId;
		this.templateName = StringHelper.isBlank(poProperties.templateName) ? "default template name" : poProperties.templateName;
		this.domainId = StringHelper.isBlank(poProperties.domainId) ? EDomainName.common : poProperties.domainId;
		this.defaultHref = poProperties.defaultHref;
		this.conversationsButtonDisappearAtWidthPx = poProperties.conversationsButtonDisappearAtWidthPx;
		this.hasConversationsButton = poProperties.hasConversationsButton;
		this.hasNotificationsButton = poProperties.hasNotificationsButton;
		this.menuButtonDisappearAtWidthPx = poProperties.menuButtonDisappearAtWidthPx;

		//TODO : le path ne devrait pas être donné par le pageInfo, mais par un service de résolution de path
		// Exemple /customer/:customerId/report/:reportId devrait être remplacé par /customer/1000/report/abcde1234
		this.path = StringHelper.isBlank(poProperties.path) ? this.buildPath() : poProperties.path;
	}

	/** Crée un chemin pour l'url et le retourne.	*/
	private buildPath(): string {
		let idPath: string;

		if (this.title === PageInfo.C_DEFAULT_PAGE_TITLE) {

			if (this.params && this.params.model && this.params.model._id) {
				// Génération d'un titre en se basant sur l'_id du model.
				console.warn("PI.M:: Attention: pas de titre par défaut pour ce composant Génération automatique d'un titre avec l'attribut _id du model.");
				this.title = this.params.model._id;
			}
			else {
				// Si deux composants sans titre consécutifs doivent s'afficher, l'url sera le même et le second composant ne sera pas visible.
				console.warn("PI.M:: Attention: pas de titre par défaut pour ce composant.");
			}
		}

		if (this.params && this.params.parentEntity) {
			idPath = `${this.params.parentEntity.id}`;

			if (this.params.model && this.params.model.type) {
				idPath += `/${this.params.model.type.toLowerCase()}`;

				if (this.params.model._id) {
					idPath += `/${this.params.model._id}`;
				}
			}

		}

		if (this.componentName === undefined) {
			console.error("PI.M:: Impossible de générer un url pour le composant. Il lui faut un 'componentName'.");
			return undefined;
		}
		else {
			let lsPath = `${this.replaceSlashInUrl(this.componentName, "_")}${idPath ? `/${idPath}` : ''}`;

			if (this.params && !StringHelper.isBlank(this.params.formDefinitionId))
				lsPath += `/${this.replaceSlashInUrl(this.params.formDefinitionId, "_")}`;

			return lsPath;
		}
	}

	public hasDefaultTitle(): boolean {
		return this.title === PageInfo.C_DEFAULT_PAGE_TITLE || (this.params && this.params.model && this.params.model._id && this.title === this.params.model._id);
	}

	/** Remplace les "/" d'une URL par la valeur.
	 * @param psString String que l'on veut modifier.
	 * @param psReplaceValue Valeur par laquelle on remplace les "/".
	 */
	private replaceSlashInUrl(psString: string, psReplaceValue: string): string {
		return psString.replace(/\//g, psReplaceValue);
	}

	//#endregion
}