import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { IIndexedObject } from '@calaosoft/osapp-common/utils/models/IIndexedObject';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { EModalSize } from '../../../modal';
import { FilterBarMainContentComponent } from '../filter-bar-main-content/filter-bar-main-content.component';

@Component({
	selector: 'calao-filter-bar-modal-content',
	templateUrl: './filter-bar-modal-content.component.html',
	styleUrls: ['./filter-bar-modal-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarModalContentComponent extends FilterBarMainContentComponent implements OnDestroy {

	//#region FIELDS

	private readonly moValidateSubject = new Subject<IIndexedObject>();

	//#endregion

	//#region PROPERTIES

	public readonly observableIsModalOpen = new ObservableProperty<boolean>(false);

	public readonly observableNbTmpResults = new ObservableProperty<number>(0);

	public get validate$(): Observable<IIndexedObject> {
		return this.moValidateSubject.asObservable();
	}

	/** Texte du bouton d'annulation. */
	@Input() public cancelText?: string;
	@ObserveProperty<FilterBarModalContentComponent>({ sourcePropertyKey: "cancelText" })
	public readonly observableCancelText = new ObservableProperty<string>("Tout effacer");

	/** Texte du bouton de validation. */
	@Input() public validateText?: string;
	@ObserveProperty<FilterBarModalContentComponent>({ sourcePropertyKey: "validateText" })
	public readonly observableValidateText = new ObservableProperty<string>("Afficher les résultats");

	/** Titre de la modale de filtrage. */
	@Input() public title?: string;
	@ObserveProperty<FilterBarModalContentComponent>({ sourcePropertyKey: "title" })
	public readonly observableTitle = new ObservableProperty<string>("Recherche");

	/** Doc propriété. */
	@Input() public modalSize?: EModalSize;
	@ObserveProperty<FilterBarModalContentComponent>({ sourcePropertyKey: "modalSize" })
	public readonly observableModalSize = new ObservableProperty<EModalSize>();

	public validateDisplayedText$: Observable<string> = combineLatest([this.observableValidateText.value$, this.observableNbTmpResults.value$]).pipe(
		map(([psValidateText, pnNbTmpResults]: [string, number]) => `${psValidateText} ${NumberHelper.isValid(pnNbTmpResults) ? `(${pnNbTmpResults})` : ""}`),
		takeUntil(this.destroyed$),
		shareReplay(1)
	);

	/** `true` si le filtrage peut être validé, sinon `false`. */
	@Input() public canValidate?: boolean | string;
	@ObserveProperty<FilterBarModalContentComponent>({
		sourcePropertyKey: "canValidate",
		transformer: (poValue: any) => coerceBooleanProperty(poValue)
	})
	public readonly observableCanValidate = new ObservableProperty<boolean>();

	//#endregion

	//#region METHODS

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moValidateSubject.complete();
	}

	public present(poFilterValues: IIndexedObject<ObservableProperty>): void {
		this.observableFilterValues.value = poFilterValues;
		this.observableIsModalOpen.value = true;
	}

	public close(): void {
		this.observableIsModalOpen.value = false;
	}

	public reset(): void {
		const loResettedData: IIndexedObject = {};

		Object.keys(this.observableFilterValues.value).forEach((psKey: string) => loResettedData[psKey] = undefined);
		this.validate(loResettedData);
		this.observableCanValidate.value = true;
	}

	public validate(poValues: IIndexedObject): void {
		this.moValidateSubject.next(poValues);
		this.close();
	}

	//#endregion

}
