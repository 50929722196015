import { Exclude, Expose } from "@calaosoft/osapp-common/class-transformer";
import { ObserveProperty } from '@calaosoft/osapp-common/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { DmsFile } from "../../modules/dms/model/DmsFile";
import { IGalleryFile } from "./IGalleryFile";

@Exclude({ toPlainOnly: true })
export class GalleryFile implements IGalleryFile {

	//#region PROPERTIES

	/** @implements */
	public isAvailable?: boolean;
	@ObserveProperty<GalleryFile>({ sourcePropertyKey: "isAvailable", excludeSource: true }) public readonly observableIsAvailable = new ObservableProperty<boolean>();

	/** @implements */
	public isNew?: boolean;
	@ObserveProperty<GalleryFile>({ sourcePropertyKey: "isNew", excludeSource: true }) public readonly observableIsNew = new ObservableProperty<boolean>();

	/** @implements */
	public isLoading?: boolean;
	@ObserveProperty<GalleryFile>({ sourcePropertyKey: "isLoading", excludeSource: true }) public readonly observableIsLoading = new ObservableProperty<boolean>();

	/** @implements */
	public isUploaded?: boolean;
	@ObserveProperty<GalleryFile>({ sourcePropertyKey: "isUploaded", excludeSource: true }) public readonly observableIsUploaded = new ObservableProperty<boolean>();

	/** @implements */
	public progress?: number;
	@ObserveProperty<GalleryFile>({ sourcePropertyKey: "progress", excludeSource: true }) public readonly observableProgress = new ObservableProperty<number>();

	/** @implements */
	public file?: DmsFile;
	/** @implements */
	public description?: string;
	/** @implements */
	public type?: string;
	/** @implements */
	public subType?: string;

	/** @implements */
	@Expose() public name: string;
	/** @implements */
	@Expose() public guid?: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poFile?: IGalleryFile) {
		if (poFile)
			ObjectHelper.assign(this, poFile);
	}

	/** Retourne `true` si l'instance en paramètre est égale à l'instance courante, `false` sinon.
	 * @param poValue Instance à comparer.
	 */
	public equals(poValue?: GalleryFile): boolean {
		return poValue === this || this.hasSameValues(poValue);
	}

	private hasSameValues(poValue?: GalleryFile): boolean {
		return ObjectHelper.areEqual(poValue, this);
	}

	//#endregion METHODS

}