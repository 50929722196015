import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { Capacitor } from '@capacitor/core';
import { SqlAdapter } from './models/SqlAdapter';
import { SqlDatabaseProvider } from './models/SqlDatabaseProvider';
import { BrowserDatabaseProviderService } from './services/providers/browser-database-provider.service';
import { LocalDatabaseProviderService } from './services/providers/local-database-provider.service';
import { SqlService } from './services/sql.service';
import { SqliteAdapterService } from './services/sqlite-adapter.service';
import { SqljsAdapterService } from './services/sqljs-adapter.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [
		SQLite,
		SqlService,
		LocalDatabaseProviderService,
		{ provide: SqlAdapter, useClass: ["android", "ios"].includes(Capacitor.getPlatform()) ? SqliteAdapterService : SqljsAdapterService },
		{ provide: SqlDatabaseProvider, useClass: ["android", "ios"].includes(Capacitor.getPlatform()) ? LocalDatabaseProviderService : BrowserDatabaseProviderService }
	],
})
export class SqlModule { }
