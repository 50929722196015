import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ConfigData } from "@calaosoft/osapp-common/config/models/ConfigData";
import { GuidHelper } from "@calaosoft/osapp-common/guid/helpers/guidHelper";
import { Geolocation, Position } from "@capacitor/geolocation";
import { GoogleMap, Marker } from "@capacitor/google-maps";
import {
	GoogleMapConfig,
	MarkerClickCallbackData,
} from "@capacitor/google-maps/dist/typings/definitions";
import { PlatformService } from "../../../services/platform.service";
import { Waypoint } from "./model/waypoint";


@Component({
	selector: "calao-google-map-points",
	templateUrl: "./google-map-points.component.html",
	styleUrls: ["./google-map-points.component.scss"],
})
export class GoogleMapPointsComponent implements OnInit {
	@Input() points: Waypoint[];

	private moCustomIconsUrl?: { url: string; index: number; color: string }[];


	public get customIconsUrl(): { url: string; index: number; color: string }[] {
		return this.moCustomIconsUrl;
	}

	@Input() public set customIconsUrl(
		paCustomIcons: { url: string; index: number; color: string }[]
	) {
		if (paCustomIcons !== this.moCustomIconsUrl) {
			this.moCustomIconsUrl = paCustomIcons;
		}
	}

	@ViewChild("map", { static: true }) mapRef: any;

	public mapName = GuidHelper.newGuid();
	public apiKey = this.isvcPlatform.isIOS && this.isvcPlatform.isMobileApp ? ConfigData.environment.GOOGLE_MAPS_IOS_API_KEY : ConfigData.environment.GOOGLE_MAPS_BROWSER_API_KEY;
	public map: GoogleMap;
	public sizeIcon = { height: 18, width: 18 };
	public upMarkers: Marker[] = [];

	@ViewChild("canvasEl") canvasElement: ElementRef;
	public context: CanvasRenderingContext2D;

	constructor(private readonly isvcPlatform: PlatformService,
	) { }

	async ngOnInit() {
		let loCurrentPos: Position = await Geolocation.getCurrentPosition();

		await this.initMaps();
		await this.setCurrentPositionMaker(loCurrentPos);

		this.addMarkers();

		await this.initMarkerClick();

		// Partie pour l'affichage des lignes avec Capacitor 5
		// this.initLines();
	}

	private addMarkers() {
		this.points.forEach(async (poStep, pnStepIndex) => {
			let loMarker = {
				title:
					pnStepIndex === 0 || pnStepIndex === this.points.length - 1
						? `${pnStepIndex}_rouge`
						: `${pnStepIndex}_vert`,
				iconAnchor: { x: 10, y: 20 },

				iconUrl:
					pnStepIndex === 0 || pnStepIndex === this.points.length - 1
						? this.moCustomIconsUrl.find(
							(poIcon) =>
								poIcon.index === pnStepIndex && poIcon.color === "rouge"
						).url
						: this.moCustomIconsUrl.find(
							(poIcon) =>
								poIcon.index === pnStepIndex && poIcon.color === "vert"
						).url,
				iconSize: this.sizeIcon,
				coordinate: {
					lat: poStep.point.lat,
					lng: poStep.point.lon,
				},
			};

			await this.map.addMarker(loMarker);
			this.upMarkers.push(loMarker);
		});
	}

	private async initMarkerClick() {
		let lfInverseColor = (psColor: string) =>
			psColor === "vert" ? "rouge" : "vert";

		let lfOnMarkerClick = (poMarkerClicked: MarkerClickCallbackData) => {
			// Pas de suprresion de "vous êtes ici"
			if (poMarkerClicked.title != "CurrentPos") {
				this.map.removeMarker(poMarkerClicked.markerId);

				let loMarker = {
					title: `${poMarkerClicked.title.split("_")[0]}_${lfInverseColor(
						poMarkerClicked.title.split("_")[1]
					)}`,
					iconAnchor: { x: 10, y: 20 },
					iconUrl: this.moCustomIconsUrl.find(
						(poIcon) =>
							poIcon.index === +poMarkerClicked.title.split("_")[0] &&
							poIcon.color ===
							lfInverseColor(poMarkerClicked.title.split("_")[1])
					).url,
					iconSize: this.sizeIcon,
					coordinate: {
						lat: poMarkerClicked.latitude,
						lng: poMarkerClicked.longitude,
					},
				};

				// sécurité : augmente la robustesse des markers
				this.upMarkers = this.upMarkers.filter(
					(poMarker) => poMarker.title !== poMarkerClicked.title
				);
				this.upMarkers.push(loMarker);
				let stIndex: string[] = [];
				this.upMarkers = this.upMarkers.filter((poMarker) => {
					if (stIndex.includes(poMarker.title)) {
						return false;
					} else {
						stIndex.push(poMarker.title);
						return true;
					}
				});
				this.map.addMarkers(this.upMarkers);
			}
		};

		// Handle marker click
		await this.map.setOnMarkerClickListener(lfOnMarkerClick);
	}

	private async initMaps() {
		this.map = await GoogleMap.create({
			id: this.mapName,
			element: this.mapRef.nativeElement,
			apiKey: this.apiKey,
			config: this.configMap(),
		});

		await this.map.enableClustering(1000);
	}

	private async setCurrentPositionMaker(poCurrentPos: Position) {
		let lsIconUrl: string = `assets/img/current_position_logo.png`;
		await this.map.addMarker({
			title: "CurrentPos",
			iconAnchor: { x: 50, y: 40 },
			iconUrl: lsIconUrl,
			iconSize: {
				height: this.sizeIcon.height * 2,
				width: this.sizeIcon.width * 5,
			},
			coordinate: {
				lat: poCurrentPos.coords.latitude,
				lng: poCurrentPos.coords.longitude,
			},
		});
	}

	private getZoom = (
		pnMaxDst: number,
		pnZoomLv: number = 6,
		pnCurrDist: number = 512
	) =>
		pnMaxDst / 1000 <= pnCurrDist && pnMaxDst / 1000 >= pnCurrDist / 2
			? pnZoomLv
			: this.getZoom(pnMaxDst, pnZoomLv + 1, pnCurrDist / 2);

	private getCenter = (pnLen: number, pnSumLat: number, pnSumLen: number) => {
		return { lat: pnSumLat / pnLen, lng: pnSumLen / pnLen };
	};

	private configMap: () => GoogleMapConfig = () => {
		let lnMaxDst: number = 0;
		let lnSumLon: number = 0;
		let lnSumLat: number = 0;

		this.points.forEach((poStep1) => {
			lnSumLat += poStep1.point.lat;
			lnSumLon += poStep1.point.lon;
			this.points.forEach((poStep2) =>
				lnMaxDst < this.getDistance(poStep1.point, poStep2.point)
					? (lnMaxDst = this.getDistance(poStep1.point, poStep2.point))
					: {}
			);
		});

		let lnCenterCoords = this.getCenter(this.points.length, lnSumLat, lnSumLon);
		let lnZoom = this.getZoom(lnMaxDst);
		return {
			center: lnCenterCoords,
			zoom: lnZoom,
			disableDefaultUI: true,
		};
	};

	// Donne la distance entre deux points à partir des coordonnés
	private getDistance = (
		poFrom: { lon: number; lat: number },
		poTo: { lon: number; lat: number }
	) => {
		const C_EARTH_RADIUS = 6378137;
		const lfToRad = (lnValue: number) => (lnValue * Math.PI) / 180;
		const lfRobustAcos = (lnValue: number) =>
			lnValue > 1 ? 1 : lnValue < -1 ? -1 : lnValue;

		return (
			Math.acos(
				lfRobustAcos(
					Math.sin(lfToRad(poTo.lat)) * Math.sin(lfToRad(poFrom.lat)) +
					Math.cos(lfToRad(poTo.lat)) *
					Math.cos(lfToRad(poFrom.lat)) *
					Math.cos(lfToRad(poFrom.lon) - lfToRad(poTo.lon))
				)
			) * C_EARTH_RADIUS
		);
	};

	// TODO : Cette fonction permet de rajouter les lignes dès le passage à capacitor 5.0.0  ~ /!\ android
	// private initLines() {
	// 	let loTourWayPoints: google.maps.LatLng[] = [];
	// 	// Point de passage de la tournée pour le tracé des flèches
	// 	this.tour.forEach(async (poStep) => {
	// 		loTourWayPoints.push(
	// 			new google.maps.LatLng({
	// 				lat: poStep.waypoint.lat,
	// 				lng: poStep.waypoint.lon,
	// 			})
	// 		);
	// 	});

	// 	let loLineSymbol = {
	// 		path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
	// 		strokeColor: "#461a1a",
	// 	};

	// 	let loLigne = {
	// 		icons: [
	// 			{
	// 				icon: loLineSymbol,
	// 				offset: "50%",
	// 			},
	// 			{
	// 				icon: loLineSymbol,
	// 				offset: "100%",
	// 			},
	// 			{
	// 				icon: loLineSymbol,
	// 				offset: "25%",
	// 			},
	// 			{
	// 				icon: loLineSymbol,
	// 				offset: "75%",
	// 			},
	// 		],
	// 		path: loTourWayPoints,
	// 		strokeColor: "#e14444",
	// 		strokeWeight: 4,
	// 		geodesic: true,
	// 	};
	// doit être rajouter dès le passage à capacitor 5 et @capacitor/googleMaps@5.3.0
	// Pour avoir les lignes
	// await this.newMapL.addPolylines([ligne]);
	// }
}
