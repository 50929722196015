import { Injectable, OnDestroy } from '@angular/core';
import { Indexor } from '@calaosoft/osapp-common/indexor/classes/indexor';
import { SqlService } from '../../../sqlite/services/sql.service';

@Injectable()
export class MobileIndexorService extends Indexor implements OnDestroy {

	//#region METHODS

	constructor(
		psvcSql: SqlService
	) {
		super(psvcSql);
	}

	public ngOnDestroy(): void {
		this.destroy();
	}

	//#endregion METHODS

}
