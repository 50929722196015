import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp-common/utils/helpers/stringHelper';
import { AlertButton, AlertInput, AlertOptions, AnimationBuilder, IonicSafeString, Mode } from '@ionic/core';

export class ShowMessageParamsPopup implements AlertOptions {

	//#region PROPERTIES

	public header: string;
	public subHeader: string;
	public message: string | IonicSafeString;
	public cssClass: string | string[];
	public inputs: AlertInput[];
	/** Les boutons du tableau sont placés de la gauche vers la droite (ou du haut vers le bas si trois boutons ou plus). */
	public buttons: Array<AlertButton | string>;
	/** Si `true` l'utilisateur peut cliquer en dehors de la pop-up pour partir. */
	public backdropDismiss: boolean;
	public translucent?: boolean;
	public animated?: boolean;
	public mode?: Mode;
	public keyboardClose?: boolean;
	public id?: string;
	public enterAnimation?: AnimationBuilder;
	public leaveAnimation?: AnimationBuilder;

	//#endregion

	//#region METHODS

	constructor(poOptions: AlertOptions = {}) {
		ObjectHelper.initInstanceOf(this, ShowMessageParamsPopup);

		// Propriétés définis par défaut si non renseignées.
		this.header = StringHelper.isBlank(poOptions.header) ? "" : poOptions.header;
		this.subHeader = StringHelper.isBlank(poOptions.header) ? "" : poOptions.subHeader;
		this.inputs = poOptions.inputs || [];
		this.buttons = poOptions.buttons || [];
		this.backdropDismiss = !!(poOptions.backdropDismiss ?? true);

		if (typeof poOptions.message === "string")
			this.message = poOptions.message;
		else
			this.message = poOptions.message || "";

		if (typeof poOptions.cssClass === "string")
			this.cssClass = poOptions.cssClass;
		else
			this.cssClass = poOptions.cssClass || "";

		// Propriétés optionnelles.
		this.translucent = poOptions.translucent;
		this.animated = poOptions.animated;
		this.mode = poOptions.mode;
		this.keyboardClose = poOptions.keyboardClose;
		this.id = poOptions.id;
		this.enterAnimation = poOptions.enterAnimation;
		this.leaveAnimation = poOptions.leaveAnimation;
	}

	//#endregion

}
