import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { IDatabaseSyncStatus } from '../../model/IDatabaseSyncStatus';
import { DatabaseSynchroService } from '../../services/database-synchro.service';

@Component({
	selector: 'calao-databases-sync-status-list',
	templateUrl: './databases-sync-status-list.component.html',
	styleUrls: ['./databases-sync-status-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatabasesSyncStatusListComponent extends ComponentBase {

	//#region PROPERTIES

	private maDatabaseSyncStatuses: IDatabaseSyncStatus[];
	public get databaseSyncStatuses(): IDatabaseSyncStatus[] { return this.maDatabaseSyncStatuses; }
	@Input() public set databaseSyncStatuses(paDatabaseSyncStatuses: IDatabaseSyncStatus[]) {
		if (!this.isDatabasesSyncStatusEqual(paDatabaseSyncStatuses)) {
			this.maDatabaseSyncStatuses = paDatabaseSyncStatuses;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	private isDatabasesSyncStatusEqual(paDatabaseSyncStatuses: IDatabaseSyncStatus[]): boolean {
		return ArrayHelper.areArraysEqual(
			paDatabaseSyncStatuses,
			this.maDatabaseSyncStatuses,
			(poItemA: IDatabaseSyncStatus, poItemB: IDatabaseSyncStatus) => DatabaseSynchroService.areDatabasesStatusEquals(poItemA, poItemB)
		);
	}

	//#endregion

}