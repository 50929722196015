import { ObjectHelper } from "../../utils/helpers/objectHelper";
import { IStoreDocument } from "./istore-document";

export class StoreDocument implements IStoreDocument {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];

	//#endregion

	//#region METHODS

	constructor(poData?: IStoreDocument) {
		if (poData)
			ObjectHelper.assign(this, poData);
	}

	//#endregion METHODS

}
