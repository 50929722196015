import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { IdHelper } from '@calaosoft/osapp-common/utils/helpers/idHelper';
import { EPrefix } from '@calaosoft/osapp-common/utils/models/EPrefix';
import { take } from 'rxjs/operators';
import { ContactHelper } from '../../../../../helpers/contactHelper';
import { IContact } from '../../../../../model/contacts/IContact';
import { ContactsService } from '../../../../../services/contacts.service';
import { FieldBase } from '../../../models/FieldBase';
import { FormsService } from '../../../services/forms.service';

@Component({
	selector: 'calao-contact-name-field',
	templateUrl: './contact-name-field.component.html',
	styleUrls: ['./contact-name-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactNameFieldComponent extends FieldBase<string> implements OnInit {

	//#region PROPERTIES

	/** Libellé du nom et prénom du contact. */
	public readonly observableContactNameLabel = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(private readonly isvcContact: ContactsService, psvcForms: FormsService) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.isvcContact.getContact(ContactHelper.addPrefix(IdHelper.extractIdWithoutPrefix(this.fieldValue, EPrefix.user)))
			.pipe(take(1)).toPromise()
			.then((poContact?: IContact) =>
				this.observableContactNameLabel.value = poContact ? `${poContact.lastName} ${poContact.firstName}` : ""
			);
	}

	//#endregion METHODS

}