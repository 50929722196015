import { ModelMatch } from "@calaosoft/osapp-common/utils/decorators/model-match.decorator";
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { DayRepetition } from './day-repetition';
import { IHoursMinutesRepetition } from './ihours-minutes-repetition';
import { IHoursMinutesRepetitionParams } from './ihours-minutes-repetition-params';

export const C_HOURS_MINUTES_REPETITION_TYPE = "hours-minutes";

@ModelMatch(
	(poData: IHoursMinutesRepetition) => poData.type === C_HOURS_MINUTES_REPETITION_TYPE || (
		NumberHelper.isValidPositive(poData.hours) && NumberHelper.isValidPositive(poData.minutes)
	),
	DayRepetition
)
export class HoursMinutesRepetition extends DayRepetition implements IHoursMinutesRepetition {

	//#region PROPERTIES

	public override readonly type: string = C_HOURS_MINUTES_REPETITION_TYPE;
	public hours: number;
	public minutes: number;

	//#endregion

	//#region METHODS

	constructor(poData?: IHoursMinutesRepetitionParams) {
		super();

		if (poData) {
			this.hours = poData.hours;
			this.minutes = poData.minutes;
		}
	}

	//#endregion

}
