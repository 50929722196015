import { Component, OnInit } from '@angular/core';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { IRange } from '@calaosoft/osapp-common/utils/models/irange';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IStatRatioIndicatorComponentParams } from '../models/istat-ratio-indicator-component-params';

@Component({
	selector: 'calao-stat-ratio-indicator',
	templateUrl: './stat-ratio-indicator.component.html',
	styleUrls: ['./stat-ratio-indicator.component.scss'],
})
export class StatRatioIndicatorComponent extends FieldBase implements OnInit {

	//#region PROPERTIES

	/** Configuration du composant. */
	public readonly obsProps = new ObservableProperty<IStatRatioIndicatorComponentParams>();
	/** Pourcentage de réalisation. */
	public readonly obsCompletionPercentage = new ObservableProperty<number>()

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poForms: FormsService) {
		super(poForms)
	}

	public override ngOnInit() {
		super.ngOnInit();
		this.obsProps.value = this.props.data as IStatRatioIndicatorComponentParams;
		this.obsCompletionPercentage.value = this.model[this.fieldKey].value;
	}


	public getProgressCircleStyle(): string {
		return `--doneVar: ${this.obsCompletionPercentage.value}%; --progressColor: var(--indicator-${this.getColor()}-color); --backgroundColor: var(--indicator-${this.getColor()}-bg-color);`;
	}

	private getColor(): string {
		let lsColor: string;

		if (this.obsProps.value.colorByRange) {
			Object.entries(this.obsProps.value.colorByRange).forEach(([psKey, poRange]: [string, IRange<number>]) => {
				if (NumberHelper.isInRange(this.obsCompletionPercentage.value, poRange))
					lsColor = psKey;
			})
		}
		else
			lsColor = "default";

		return lsColor;
	}

	//#endregion METHODS

}
