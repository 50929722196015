import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, Optional, ViewRef } from '@angular/core';
import { GuidHelper } from "@calaosoft/osapp-common/guid/helpers/guidHelper";
import { PerformanceManager } from '@calaosoft/osapp-common/performance/PerformanceManager';
import { Queuer } from '@calaosoft/osapp-common/queue/models/queuer';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { Observable, merge, of } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ICanWaitToRoute } from '../modules/routing/models/ican-wait-to-route';
import { RoutingAwaiter } from '../modules/routing/models/routing-awaiter';
import { DestroyableComponentBase } from '../modules/utils/components/destroyable-component-base';

@Component({ template: "" })
export abstract class ComponentBase extends DestroyableComponentBase implements ICanWaitToRoute, AfterViewInit, OnDestroy {

	//#region FIELDS

	private static readonly C_COMPONENT_BASE_LOG_ID = "COMP.BASE.C::";

	protected static readonly C_DELAY_BETWEEN_DETECT_CHANGES_MS = 100;

	private readonly moDetectChangesQueue = new Queuer({
		thingToQueue: () => of(this.internalDetectChanges()),
		minimumGapMs: ComponentBase.C_DELAY_BETWEEN_DETECT_CHANGES_MS,
		keepOnlyLastPending: true
	});

	/** Identifiant de template donné par le pageInfo de la construction du composant. */
	protected readonly templateId?: string;

	/** Liste des files d'attentes du composant. Elles seront clôturées dans l'ordre à la sortie du composant. */
	private maQueuers?: ReadonlyArray<Queuer<any, any>>;
	private mbDetectChangesPending = false;

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly routingAwaiter = new RoutingAwaiter;

	//#endregion

	//#region METHODS

	constructor(@Optional() private readonly ioChangeDetectorRef?: ChangeDetectorRef) {
		super();
		this.msInstanceId = GuidHelper.newGuid();
		console.debug(`${ComponentBase.C_COMPONENT_BASE_LOG_ID}CREATE ${this.constructor.name} with id ${this.msInstanceId}.`);
	}

	public ngAfterViewInit(): void {
		this.moDetectChangesQueue.start().pipe(takeUntil(this.destroyed$)).subscribe();

		if (this.mbDetectChangesPending)
			this.detectChanges();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();

		console.debug(`${ComponentBase.C_COMPONENT_BASE_LOG_ID}DESTROY ${this.constructor.name} with id ${this.msInstanceId}.`);

		if (this.ioChangeDetectorRef)
			this.ioChangeDetectorRef?.detach();

		this.moDetectChangesQueue.end();
	}

	/** Affecte les files d'attentes au composant.
	 * @param paQueuers
	 */
	public setQueuers(paQueuers: Queuer<any, any>[]): void {
		if (ArrayHelper.hasElements(this.maQueuers))
			console.warn(`${ComponentBase.C_COMPONENT_BASE_LOG_ID}queues already initialized.`);
		else {
			if (ArrayHelper.hasElements(paQueuers)) {
				this.maQueuers = paQueuers;
				this.initQueues();

				this.routingAwaiter.navigationStart$
					.pipe( // Quand une navigation démarre, on termine les files.
						tap(() => ArrayHelper.getFirstElement(this.maQueuers)!.end()), // On clôture la première file.
						takeUntil(this.destroyed$)
					)
					.subscribe();
			}
		}
	}

	private initQueues(): void {
		merge(...this.getQueuersExecutions())
			.pipe(
				takeUntil(this.destroyed$),
				tap({
					complete: () => {
						this.routingAwaiter.continueRouting(); // Quand les x files sont terminées, on peut continuer la navigation.
						if (!this.destroyed) // Si le composant n'est pas détruit, on doit se préparer à un retour sur la page.
							this.initQueues();
						else // Sinon, on s'assure de terminer toutes les files pour éviter des fuites mémoires en terminant la première, les autres se terminent en cascade.
							ArrayHelper.getFirstElement(this.maQueuers)?.end();
					}
				})
			)
			.subscribe();
	}

	private getQueuersExecutions(): Array<Observable<any>> {
		return this.maQueuers?.map((poQueuer: Queuer<any>, pnIndex: number, paArray: Queuer<any>[]) => {
			return poQueuer.start().pipe(
				tap({
					complete: () => {
						const loNextQueuer: Queuer<any> = paArray[pnIndex + 1]; // Quand une file est terminée, on clôture la suivante.
						if (loNextQueuer)
							loNextQueuer.end();
					}
				})
			);
		}) ?? [];
	}

	/** Rafraîchie la vue pour la mettre à jour. */
	public detectChanges(): void {
		this.moDetectChangesQueue.exec();
		this.mbDetectChangesPending = true;
	}

	private internalDetectChanges(): void {
		// Si la vue n'est pas détruite, on peut la mettre à jour.
		if (this.ioChangeDetectorRef && !(this.ioChangeDetectorRef as ViewRef).destroyed) {
			const loPerformance = new PerformanceManager().markStart();
			this.ioChangeDetectorRef.detectChanges();
			console.debug(`${ComponentBase.C_COMPONENT_BASE_LOG_ID}DetectChanges duration (ms) : ${loPerformance.markEnd().measure()} ; class:`, this.constructor.name);
		}
		else if (!this.ioChangeDetectorRef)
			console.warn(`${ComponentBase.C_COMPONENT_BASE_LOG_ID}Cannot detect changes without changeDetector injected.`);
	}

	/** Marque la vue comme `à rafraîchir` pour Angular. */
	protected markChanges(): void {
		if (this.ioChangeDetectorRef)
			this.ioChangeDetectorRef.markForCheck();
	}

	//#endregion

}