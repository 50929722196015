import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UserData } from '../../../../../../model/application/UserData';
import { IActionButtonFieldMeetingParams } from '../../../../../../model/forms/actionButtonFields/IActionButtonFielMeetingParams';
import { PermissionsService } from '../../../../../../modules/permissions/services/permissions.service';
import { ConversationService } from '../../../../../../services/conversation.service';
import { IOpenMeetingConversationParams } from '../../../../../../services/interfaces/iopen-meeting-conversation-params';
import { HasPermissions } from '../../../../../permissions/decorators/has-permissions.decorator';
import { EPermissionScopes } from '../../../../../permissions/models/epermission-scopes';
import { FormsService } from '../../../../services/forms.service';
import { ActionButtonFieldBaseComponent } from './actionButtonFieldBase.component';

@Component({
	selector: 'calao-meeting-action-button-field',
	templateUrl: './actionButtonFieldMeeting.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonFieldMeetingComponent extends ActionButtonFieldBaseComponent<IActionButtonFieldMeetingParams> {

	//#region PROPERTIES

	@HasPermissions({ permission: "call", permissionScopes: EPermissionScopes.conversations })
	public get canCall(): boolean { return true; }

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		public readonly isvcPermissions: PermissionsService,
		private readonly isvcConversation: ConversationService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef,
	) {
		super(psvcForms, poChangeDetectorRef);
		/** On stocke cet état dans window pour y avoir accès dans les expressions du formDescriptor. */
		(window as any).userId = UserData.current.name;
	}

	/** Ne fait rien (l'action est gérée par le composant interne). */
	public override action(): void { }

	public onClick(): void {
		const loParams: IOpenMeetingConversationParams = {
			entity: this.params.specParams.event,
			convId: this.params.specParams.convId,
			title: this.params.specParams.title,
			participants: this.params.specParams.participants
		};

		this.isvcConversation.createOrJoinLinkedMeetingConversation(loParams).subscribe();
	}

	//#endregion

}