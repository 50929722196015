import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ETaskPrefix } from '@calaosoft/osapp-common/background-tasks/models/ETaskPrefix';
import { ConfigData } from '@calaosoft/osapp-common/config/models/ConfigData';
import { DateHelper } from '@calaosoft/osapp-common/dates/helpers/dateHelper';
import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import { ObjectHelper } from '@calaosoft/osapp-common/utils/helpers/objectHelper';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { UserHelper } from '../../../helpers/user.helper';
import { ENetworkFlag } from '../../../model/application/ENetworkFlag';
import { IApplicationEvent } from '../../../model/application/IApplicationEvent';
import { EStoreFlag } from '../../../model/store/EStoreFlag';
import { BackgroundTaskService } from '../../../services/backgroundTask.service';
import { TaskDescriptor } from '../../../services/backgroundTask/TaskDescriptor';
import { FlagService } from '../../../services/flag.service';
import { WorkspaceService } from '../../../services/workspace.service';
import { AuthenticatedRequestOptionBuilder } from '../../api/models/authenticated-request-option-builder';
import { EventsService } from '../../events/events.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { BaseEvent } from '../models/base-event';
import { BaseEventOccurrence } from '../models/base-event-occurrence';
import { ECalendarInvitationFlag } from '../models/ecalendar-invitation-flag';
import { EInvitationChannel } from '../models/einvitation-channel';
import { EInvitationTargetType } from '../models/einvitation-target-type';
import { EventParticipantAddEndEvent } from '../models/event-participant-add-end-event';
import { EventParticipantAddEvent } from '../models/event-participant-add-event';
import { EventParticipantRemoveEndEvent } from '../models/event-participant-remove-end-event';
import { EventParticipantRemoveEvent } from '../models/event-participant-remove-event';
import { EventParticipantUpdateEndEvent } from '../models/event-participant-update-end-event';
import { EventParticipantUpdateEvent } from '../models/event-participant-update-event';
import { IInvitationParams } from '../models/iinvitation-params';

@Injectable({ providedIn: "root" })
export class CalendarEventsInvitationService extends DestroyableServiceBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "CAL.EVT.INV.S::";

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioHttp: HttpClient,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcEvents: EventsService,
		private readonly isvcBackground: BackgroundTaskService,
		private readonly isvcFlag: FlagService
	) {
		super();
	}

	public init(): void {
		this.isvcEvents.events$.pipe(
			mergeMap(async (poEvent: IApplicationEvent) => {
				try {
					if (poEvent instanceof EventParticipantAddEvent)
						await this.processEventInvitationAsync(poEvent);
					else if (poEvent instanceof EventParticipantRemoveEvent)
						await this.processEventRemoveAsync(poEvent);
					else if (poEvent instanceof EventParticipantUpdateEvent)
						await this.processEventUpdateAsync(poEvent);
				}
				catch (poError) {
					console.error(`${CalendarEventsInvitationService.C_LOG_ID}Error while processing invitation.`, poEvent, poError);
				}
			}),
			takeUntil(this.destroyed$)
		).subscribe();

		this.isvcFlag.setFlagValue(ECalendarInvitationFlag.calendarInvitationServiceInitialized, true);
	}

	private async processEventInvitationAsync(poEvent: EventParticipantAddEvent): Promise<void> {
		try {
			await this.innerInviteParticipants(poEvent.data.eventId, poEvent.data.params);
			this.isvcEvents.raiseEvent(new EventParticipantAddEndEvent({
				id: this.getTaskId(poEvent.data.eventId, ETaskPrefix.eventParticipantInvitation, poEvent.data.occurrenceDate)
			}));
		}
		catch (poError) {
			this.isvcEvents.raiseEvent(new EventParticipantAddEndEvent({
				error: poError,
				id: this.getTaskId(poEvent.data.eventId, ETaskPrefix.eventParticipantInvitation, poEvent.data.occurrenceDate)
			}));
			throw poError;
		}
	}

	private async processEventRemoveAsync(poEvent: EventParticipantRemoveEvent): Promise<void> {
		try {
			await this.innerRemoveParticipants(poEvent.data.eventId, poEvent.data.params);
			this.isvcEvents.raiseEvent(new EventParticipantRemoveEndEvent({
				id: this.getTaskId(poEvent.data.eventId, ETaskPrefix.eventParticipantRemove, poEvent.data.occurrenceDate)
			}));
		}
		catch (poError) {
			this.isvcEvents.raiseEvent(new EventParticipantRemoveEndEvent({
				error: poError,
				id: this.getTaskId(poEvent.data.eventId, ETaskPrefix.eventParticipantRemove, poEvent.data.occurrenceDate)
			}));
			throw poError;
		}
	}

	private async processEventUpdateAsync(poEvent: EventParticipantUpdateEvent): Promise<void> {
		try {
			await this.innerUpdate(poEvent.data.eventId, poEvent.data.params);
			this.isvcEvents.raiseEvent(new EventParticipantUpdateEndEvent({
				id: this.getTaskId(poEvent.data.eventId, ETaskPrefix.eventParticipantUpdate, poEvent.data.occurrenceDate)
			}));
		}
		catch (poError) {
			this.isvcEvents.raiseEvent(new EventParticipantUpdateEndEvent({
				error: poError,
				id: this.getTaskId(poEvent.data.eventId, ETaskPrefix.eventParticipantUpdate, poEvent.data.occurrenceDate)
			}));
			throw poError;
		}
	}

	public updateEventOccurrenceInvitations(poModel: BaseEventOccurrence, poSourceModel: BaseEventOccurrence): void {
		const laSourceParticipants: string[] = [...poSourceModel?.participantIds];
		const laParticipants: string[] = [...poModel.participantIds];
		const lsUserContactId: string = UserHelper.getUserContactId();
		ArrayHelper.removeElement(laSourceParticipants, lsUserContactId);
		ArrayHelper.removeElement(laParticipants, lsUserContactId);
		const laRemovedParticipantIds: string[] = ArrayHelper.getDifferences(laSourceParticipants, laParticipants);
		const laAddedParticipantIds: string[] = ArrayHelper.getDifferences(laParticipants, laSourceParticipants);

		const loBaseInvitationParams: IInvitationParams = this.createInvitationParamsFromOccurrences(poModel, poSourceModel);

		if (ArrayHelper.hasElements(laAddedParticipantIds))
			this.inviteParticipants(poModel.eventId, { ...loBaseInvitationParams, participants: laAddedParticipantIds }, poModel.startDate);

		if (ArrayHelper.hasElements(laRemovedParticipantIds))
			this.removeParticipants(poModel.eventId, { ...loBaseInvitationParams, participants: laRemovedParticipantIds }, poModel.startDate);

		const laDifferences: string[] = ArrayHelper.getDifferences(laParticipants, [...laAddedParticipantIds, ...laRemovedParticipantIds]);
		if (ArrayHelper.hasElements(laDifferences) && this.hasInvitationParamsChanged(loBaseInvitationParams))
			this.update(poModel.eventId, { ...loBaseInvitationParams, participants: laDifferences }, poModel.startDate);
	}

	private getEventOccurrenceInvitationTaskId(psEventId: string, pdOccurrenceDate: Date, peTaskPrefix: ETaskPrefix): string {
		return `${peTaskPrefix}${psEventId}_${DateHelper.toUTCString(pdOccurrenceDate)}`;
	}

	private getTaskId(psEventId: string, peTaskPrefix: ETaskPrefix, pdOccurrenceDate?: Date): string {
		return pdOccurrenceDate ?
			this.getEventOccurrenceInvitationTaskId(psEventId, pdOccurrenceDate, peTaskPrefix) :
			this.getEventInvitationTaskId(psEventId, peTaskPrefix);
	}

	private createInvitationParamsFromOccurrences(poModel: BaseEventOccurrence, poSourceModel?: BaseEventOccurrence): IInvitationParams {
		return {
			authorId: UserHelper.getUserId(),
			title: poModel.title,
			notes: poModel.notes,
			startDate: poModel.startDate,
			endDate: poModel.observableEndDate.value,
			channels: [EInvitationChannel.mail, EInvitationChannel.push],
			eventRev: poModel.event._rev,
			eventSubType: poModel.eventSubtype,
			eventType: poModel.eventType,
			participants: poModel.participantIds,
			place: poModel.place,
			targetType: EInvitationTargetType.occurrence,
			appRoute: poModel.event.getRoute(),
			oldInfos: {
				eventSubType: poSourceModel?.eventSubtype,
				eventRev: poSourceModel?.event._rev,
				startDate: poSourceModel?.startDate,
				endDate: poSourceModel?.observableEndDate.value,
				place: poSourceModel?.place,
				notes: poSourceModel?.notes,
				title: poSourceModel?.title
			}
		};
	}

	private hasInvitationParamsChanged(poBaseInvitationParams: IInvitationParams): boolean {
		if (!poBaseInvitationParams.oldInfos)
			return false;

		return Object.keys(poBaseInvitationParams.oldInfos).some(
			(psKey: string) => !ObjectHelper.areEqual(poBaseInvitationParams[psKey], poBaseInvitationParams.oldInfos[psKey])
		);
	}

	public updateEventInvitations(poModel: BaseEvent, poSourceModel?: BaseEvent): void {
		const laSourceParticipants: string[] = [...(poSourceModel?.participantIds ?? [])];
		const laParticipants: string[] = [...poModel.participantIds];
		const lsUserContactId: string = UserHelper.getUserContactId();
		ArrayHelper.removeElement(laSourceParticipants, lsUserContactId);
		ArrayHelper.removeElement(laParticipants, lsUserContactId);
		const laRemovedParticipantIds: string[] = ArrayHelper.getDifferences(laSourceParticipants, laParticipants);
		const laAddedParticipantIds: string[] = ArrayHelper.getDifferences(laParticipants, laSourceParticipants);
		const loBaseInvitationParams: IInvitationParams = this.createInvitationParamsFromEvents(poModel, poSourceModel);

		if (poSourceModel) {
			const laOldExceptions: BaseEventOccurrence[] = poSourceModel.generateExceptionalOccurrences();

			laOldExceptions.forEach((poOldException: BaseEventOccurrence) => {
				const loException: BaseEventOccurrence | undefined = ArrayHelper.getFirstElement(
					poModel.generateOccurrences({ from: poOldException.startDate, to: poOldException.startDate }, 1, poSourceModel._rev)
				);

				if (loException) // On ne gère pas le cas de disparition de l'exception (changement de récurrence) volontairement
					this.updateEventOccurrenceInvitations(loException, poOldException);
			});
		}

		if (ArrayHelper.hasElements(laAddedParticipantIds))
			this.inviteParticipants(poModel._id, { ...loBaseInvitationParams, participants: laAddedParticipantIds });

		if (ArrayHelper.hasElements(laRemovedParticipantIds))
			this.removeParticipants(poModel._id, { ...loBaseInvitationParams, participants: laRemovedParticipantIds });

		const laDifferences: string[] = ArrayHelper.getDifferences(laParticipants, [...laAddedParticipantIds, ...laRemovedParticipantIds]);
		if (ArrayHelper.hasElements(laDifferences) && this.hasInvitationParamsChanged(loBaseInvitationParams))
			this.update(poModel._id, { ...loBaseInvitationParams, participants: laDifferences });
	}

	private getEventInvitationTaskId(psEventId: string, peTaskPrefix: ETaskPrefix): string {
		return `${peTaskPrefix}${psEventId}`;
	}

	public deleteEventInvitation(poModel: BaseEvent): void {
		const laExceptions: BaseEventOccurrence[] = poModel.generateExceptionalOccurrences();
		const lsUserContactId: string = UserHelper.getUserContactId();

		laExceptions.forEach((poException: BaseEventOccurrence) => {
			ArrayHelper.removeElement(poException.participantIds, lsUserContactId);
			this.removeParticipants(poException.eventId, this.createInvitationParamsFromOccurrences(poException), poException.startDate);
		});

		ArrayHelper.removeElement(poModel.participantIds, lsUserContactId);

		this.removeParticipants(poModel._id, this.createInvitationParamsFromEvents(poModel));
	}

	private createInvitationParamsFromEvents(poModel: BaseEvent, poSourceModel?: BaseEvent): IInvitationParams {
		return {
			authorId: UserHelper.getUserId(),
			title: poModel.title,
			notes: poModel.notes,
			startDate: poModel.startDate,
			endDate: poModel.endDate,
			planificationDescription: poModel.getPlanificationDescription(),
			channels: [EInvitationChannel.mail, EInvitationChannel.push],
			eventRev: poModel._rev,
			eventSubType: poModel.eventSubtype,
			eventType: poModel.eventType,
			participants: poModel.participantIds,
			place: poModel.place,
			targetType: ArrayHelper.hasElements(poModel.recurrences) ? EInvitationTargetType.recurrentEvent : EInvitationTargetType.singleEvent,
			appRoute: poModel.getRoute(),
			oldInfos: {
				eventSubType: poSourceModel?.eventSubtype,
				eventRev: poSourceModel?._rev,
				planificationDescription: poSourceModel?.getPlanificationDescription(),
				startDate: poSourceModel?.startDate,
				endDate: poSourceModel?.endDate,
				place: poSourceModel?.place,
				notes: poSourceModel?.notes,
				title: poSourceModel?.title
			}
		};
	}

	private inviteParticipants(psEventId: string, poInvitationParams: IInvitationParams, pdOccurrenceDate?: Date): void {
		this.addTask(
			this.getTaskId(psEventId, ETaskPrefix.eventParticipantInvitation, pdOccurrenceDate),
			"event_participant_invitation",
			"EventParticipantAddTask",
			poInvitationParams
		);
	}

	private async innerInviteParticipants(psEventId: string, poInvitationParams: IInvitationParams): Promise<void> {
		this.prepareInvitationParams(poInvitationParams);

		await this.ioHttp.post(`${this.getBaseUrl(psEventId)}invite`, poInvitationParams, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).toPromise();
	}

	private prepareInvitationParams(poInvitationParams: IInvitationParams) {
		if (ObjectHelper.isEmpty(poInvitationParams.oldInfos))
			poInvitationParams.oldInfos = undefined;
	}

	private removeParticipants(psEventId: string, poInvitationParams: IInvitationParams, pdOccurrenceDate?: Date): void {
		this.addTask(
			this.getTaskId(psEventId, ETaskPrefix.eventParticipantRemove, pdOccurrenceDate),
			"event_participant_remove",
			"EventParticipantRemoveTask",
			poInvitationParams
		);
	}

	private async innerRemoveParticipants(psEventId: string, poInvitationParams: IInvitationParams): Promise<void> {
		this.prepareInvitationParams(poInvitationParams);

		await this.ioHttp.delete(`${this.getBaseUrl(psEventId)}cancel`, { ...AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions(), body: poInvitationParams }).toPromise();
	}

	private update(psEventId: string, poInvitationParams: IInvitationParams, pdOccurrenceDate?: Date): void {
		this.addTask(
			this.getTaskId(psEventId, ETaskPrefix.eventParticipantUpdate, pdOccurrenceDate),
			"event_update",
			"EventParticipantUpdateTask",
			poInvitationParams
		);
	}

	private addTask(psTaskId: string, psTaskName: string, psTaskType: string, poInvitationParams: IInvitationParams): void {
		if (ArrayHelper.hasElements(poInvitationParams.participants)) {
			this.isvcBackground.addTask(new TaskDescriptor({
				id: psTaskId,
				name: psTaskName,
				taskType: psTaskType,
				params: poInvitationParams,
				enableTaskPersistance: true,
				intervalRepetition: undefined,
				execAfter: [
					{ key: ENetworkFlag.isOnlineReliable, value: true },
					{ key: EStoreFlag.DBInitialized, value: true },
					{ key: ECalendarInvitationFlag.calendarInvitationServiceInitialized, value: true }
				]
			}));
		}
	}

	private async innerUpdate(psEventId: string, poInvitationParams: IInvitationParams): Promise<void> {
		this.prepareInvitationParams(poInvitationParams);

		await this.ioHttp.put(`${this.getBaseUrl(psEventId)}update`, poInvitationParams, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).toPromise();
	}

	private getBaseUrl(psEventId: string): string {
		return `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${this.isvcWorkspace.getCurrentWorkspaceId()}/entities/events/${psEventId}/invitations/`;
	}

	//#endregion

}
