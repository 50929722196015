import { Inject, Injectable, Optional } from "@angular/core";
import { ELogActionId } from '@calaosoft/osapp-common/logging/models/ELogActionId';
import { SqlDatabaseProviderBase } from "@calaosoft/osapp-common/sqlite/classes/sql-database-provider-base";
import { LoggerService } from "../../logger/services/logger.service";
import { SqlRemoteProvider } from "../services/providers/sql-remote-provider.service";
import { SqlAdapter } from "./SqlAdapter";

/** Prépare l'utilisation d'une base de données SQL. Mets à disposition les bases  */
@Injectable()
export abstract class SqlDatabaseProvider extends SqlDatabaseProviderBase {

	//#region METHODS

	public constructor(
		psvcAdapter: SqlAdapter<any>,
		protected readonly isvcLogger: LoggerService,
		@Optional() @Inject(SqlRemoteProvider) paRemoteProviders?: SqlRemoteProvider[]
	) {
		super(psvcAdapter, paRemoteProviders);
	}

	protected override logAction(psMessage: string, peLogActionId: ELogActionId, poError?: any): void {
		this.isvcLogger.action(SqlDatabaseProvider.C_LOG_ID, psMessage, peLogActionId, undefined, poError);
	}

	//#endregion METHODS

}