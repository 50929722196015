import { ObjectHelper } from "@calaosoft/osapp-common/utils/helpers/objectHelper";
import { FilesystemErrorBase } from "./filesystem-error-base";

export class FilesystemCopyError extends FilesystemErrorBase {

	constructor(poError: any, psSourceUri: string, psDestinationUri: string) {
		super(poError, `Can not copy from '${psSourceUri}' to '${psDestinationUri}'`);
		// Nécessaire pour que le 'instanceof FilesystemCopyError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemCopyError);
	}

}