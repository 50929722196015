import { Component, OnInit } from '@angular/core';
import { ObservableProperty } from '@calaosoft/osapp-common/observable/models/observable-property';
import { NumberHelper } from '@calaosoft/osapp-common/utils/helpers/numberHelper';
import { IRange } from '@calaosoft/osapp-common/utils/models/irange';
import { PatternResolverService } from '../../../../../../services/pattern-resolver.service';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { EStatAchievementIndicatorComponentMode } from '../models/estat-achievement-indicator-component-mode';
import { IStatAchievementIndicatorComponentParams } from '../models/istat-achievement-indicator-component-params';

@Component({
	selector: 'calao-stat-achievement-indicator',
	templateUrl: './stat-achievement-indicator.component.html',
	styleUrls: ['./stat-achievement-indicator.component.scss'],
})
export class StatAchievementIndicatorComponent extends FieldBase implements OnInit {

	//#region PROPERTIES

	/** Configuration du composant. */
	public readonly obsProps = new ObservableProperty<IStatAchievementIndicatorComponentParams>();
	/** Libellé sous le titre. */
	public readonly obsLabel = new ObservableProperty<string>();
	/** Mode d'affichage de la statistique. (barre de progression, cercle, seuil) */
	public EMode = EStatAchievementIndicatorComponentMode;
	/** Pourcentage de réalisation. */
	public readonly obsCompletionPercentage = new ObservableProperty<number>();

	//#endregion PROPERTIES

	//#region METHODS

	public constructor(
		private readonly isvcPatternResolver: PatternResolverService,
		poForms: FormsService,
	) {
		super(poForms);
	}

	public override ngOnInit() {
		super.ngOnInit();
		this.obsProps.value = this.props.data as IStatAchievementIndicatorComponentParams;
		this.obsCompletionPercentage.value = this.getCompletionPercentage();
		this.obsLabel.value = this.getLabel();
	}

	private getCompletionPercentage(): number | undefined {
		return this.model[this.fieldKey].objective ? Math.round((this.model[this.fieldKey].value / this.model[this.fieldKey].objective) * 100) : undefined;
	}

	public getProgressBarBackgroundStyle(): string {
		return `background-color: var(--indicator-${this.getColor()}-bg-color);`;
	}

	public getProgressBarStyle(): string {
		const lnWidth: number = NumberHelper.isValidStrictPositive(this.obsCompletionPercentage.value) ? this.obsCompletionPercentage.value : 0;
		return `background-color: var(--indicator-${this.getColor()}-color);width: ${lnWidth}%;`;
	}

	public getProgressCircleStyle(): string {
		const lnWidth: number = NumberHelper.isValidStrictPositive(this.obsCompletionPercentage.value) ? this.obsCompletionPercentage.value : 0;
		return `--doneVar: ${lnWidth}%; --progressColor: var(--indicator-${this.getColor()}-color); --backgroundColor: var(--indicator-${this.getColor()}-bg-color);`;
	}

	public getCeilingBarStyle(): string {
		const lsColor: string = this.model[this.fieldKey].value < this.model[this.fieldKey].objective ? "success" : "danger";
		return `background-color: var(--indicator-${lsColor}-color)`;
	}

	private getColor(): string {
		let lsColor: string;

		if (this.obsCompletionPercentage.value) {
			if (this.obsProps.value.colorByRange) {
				Object.entries(this.obsProps.value.colorByRange).forEach(([psKey, poRange]: [string, IRange<number>]) => {
					if (NumberHelper.isInRange(this.obsCompletionPercentage.value, poRange))
						lsColor = psKey;
				})
			}
			else
				lsColor = "default";
		}
		else
			lsColor = "unset";

		return lsColor;
	}

	private getLabel(): string {
		if (!this.obsProps.value.mode)
			return this.isvcPatternResolver.resolveContextualPattern(this.obsProps.value.label, this.model[this.fieldKey])
		else
			return NumberHelper.isValid(this.model[this.fieldKey].objective) ? this.isvcPatternResolver.resolveContextualPattern(this.obsProps.value.label, this.model[this.fieldKey]) : this.model[this.fieldKey].value;
	}

	//#endregion METHODS

}
