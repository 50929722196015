import { ArrayHelper } from '@calaosoft/osapp-common/utils/helpers/arrayHelper';
import PCancelable from "p-cancelable";

export class CancelablePromiseTracker {

	//#region FIELDS

	private readonly maPromises: PCancelable<any>[] = [];

	//#endregion

	//#region PROPERTIES



	//#endregion

	//#region METHODS

	public track<T>(poPromise: PCancelable<T>): PCancelable<T> {
		this.maPromises.push(poPromise);

		poPromise.then(() => { ArrayHelper.removeElement(this.maPromises, poPromise); }).catch(() => { ArrayHelper.removeElement(this.maPromises, poPromise); }); // Then et catch pour éviter le Unhandled Promise rejection

		return poPromise;
	}

	public cancelAll(psReason?: string): void {
		this.maPromises.forEach((poPromise: PCancelable<any>) => poPromise.cancel(psReason)); // Tableau vidé par le `then/catch` de la méthode `track()`.
	}

	//#endregion

}
